import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EtlPlan } from '../models/etl-plan';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class EtlPlanService {
    private url: string = environment.apiEndpoint + '/EtlPlan';

    constructor(private http: HttpClient) {
    }

    getEtlPlanById(id: number): Observable<EtlPlan> {
        return this.http.get<EtlPlan>(this.url + '/' + id);
    }

    getAllPublishedEtlPlans(): Observable<EtlPlan[]> {
        return this.http.get<EtlPlan[]>(this.url + '/getAllPublishedEtlPlans');
    }

    getAllEtlPlans(): Observable<EtlPlan[]> {
        return this.http.get<EtlPlan[]>(this.url + '/getAllEtlPlans');
    }

    insertEtlPlan(etlPlan: EtlPlan): Observable<EtlPlan> {
        return this.http.post<EtlPlan>(this.url, etlPlan);
    }

    updateEtlPlan(etlPlan: EtlPlan): Observable<EtlPlan> {
        return this.http.put<EtlPlan>(this.url, etlPlan);
    }

    deleteEtlPlan(id: number): Observable<CoreResponse<any>> {
        return this.http.delete<CoreResponse<any>>(this.url + '/' + id);
    }

}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IntegrationSource } from '../models/integration-source';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class IntegrationSourceService {
    private baseUrl = environment.apiEndpoint + '/integrationSource/';

    constructor(private http: HttpClient) {
    }

    getAllIntegrationSources(fat: boolean = false): Observable<IntegrationSource[]> {
        return this.http.get<IntegrationSource[]>(this.baseUrl + fat);
    }

    getIntegrationSourceById(sourceId: string, fat: boolean = false): Observable<IntegrationSource> {
        return this.http.get<IntegrationSource>(this.baseUrl + sourceId + '/' + fat);
    }

    getDataColumnNamesBySourceId(sourceId: number): Observable<string[]> {
        return this.http.get<string[]>(this.baseUrl + 'columns/' + sourceId);
    }

    getEligibleDatesBySourceId(sourceId: number): Observable<string[]> {
        return this.http.get<string[]>(this.baseUrl + 'dates/' + sourceId);
    }

    getSampleDataBySourceId(sourceId: number): Observable<CoreResponse<any>> {
        return this.http.get<any>(this.baseUrl + 'sampledata/' + sourceId);
    }

    updateIntegrationSource(integrationSource: IntegrationSource): Observable<IntegrationSource> {
        return this.http.put<IntegrationSource>(this.baseUrl, integrationSource);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccountFactor } from '../models/account-factor';
import { AccountFactorBatchChanges } from '../models/account-factor-batch-changes';
import { AccountFactorBatchChangesResults } from '../models/account-factor-batch-changes-results';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class AccountFactorService {
    importURL: string;
    private accountFactorURL = environment.apiEndpoint + '/accountfactor/';

    constructor(private http: HttpClient) {
        this.importURL = `${this.accountFactorURL}import`;
    }

    getMultipleAccountFactors(sellerIds: number[]): Observable<AccountFactor[]> {
        return this.http.post<AccountFactor[]>(this.accountFactorURL + 'getmultiple', sellerIds);
    }

    insertAccountFactor(accountFactor: AccountFactor): Observable<AccountFactor> {
        return this.http.post<AccountFactor>(this.accountFactorURL + 'insert', accountFactor);
    }

    updateAccountFactor(accountFactor: AccountFactor) {
        return this.http.put<AccountFactor>(this.accountFactorURL + 'update', accountFactor);
    }

    batchUpdateAccountFactors(changes: AccountFactorBatchChanges): Observable<CoreResponse<AccountFactorBatchChangesResults>> {
        return this.http.post<CoreResponse<AccountFactorBatchChangesResults>>(this.accountFactorURL + 'batchchanges', changes);
    }

    deleteAccountFactor(accountFactor: AccountFactor): Observable<number> {
        return this.http.post<number>(this.accountFactorURL + 'delete', accountFactor);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class BuildDiffService {
    private baseUrl = environment.apiEndpoint + '/build-diff';

    constructor(private http: HttpClient) {
    }

    getBuildDiff() {
        return this.http.get<CoreResponse<any>>(this.baseUrl);
    }

    importBuildDiffChanges(buildDiffSelection: any) {
        return this.http.post<CoreResponse<any>>(this.baseUrl, buildDiffSelection);
    }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, NgModule, Output, EventEmitter } from '@angular/core';
import { DxNumberBoxModule } from 'devextreme-angular';
import { BaseNumericInputProperties } from '../../models/core-numeric-input-properties';

@Component({
    selector: 'app-core-numeric-input',
    templateUrl: './core-numeric-input.component.html',
    styleUrls: ['./core-numeric-input.component.scss']
})
export class CoreNumericInputComponent implements OnInit {

    @Input() props: BaseNumericInputProperties<CoreNumericInputComponent>;
    @Input() value: number;
    @Output() valueChange = new EventEmitter<number>();

    constructor() { }

    ngOnInit() {
        if(!(this.value >= this.props.minValue && this.value <= this.props.maxValue)){
            this.value = this.props.minValue;
            this.handleValueChange(null);
        }
    }

    // TODO: Not sure if this callback is actually used, but two-way binding is used for other types.
    numericOnChange(event: any) {
        const properties = {componentThis: this, event};
        if (this.props.isOnChangedUsed) {
            this.props.inputOnChangeCallBack(properties);
        }
    }

    handleValueChange(event: any) {
        this.valueChange.emit(this.value);
    }
}

@NgModule({
imports: [
    DxNumberBoxModule,
    CommonModule
],
declarations: [CoreNumericInputComponent],
exports: [CoreNumericInputComponent]
})
export class CoreNumericInputModule { }

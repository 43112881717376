import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DashboardContext } from '../models/contexts/dashboard-context';
import { CoreResponse } from '../models/core-response';
import { Dashboard } from '../models/dashboard';
import { DashboardContentResponse } from '../models/dashboard-content-response';
import { DashboardSellerPublish } from '../models/dashboard-seller-publish';
import { UpdatePublishRequest } from '../models/update-publish-request';

@Injectable()
export class DashboardService {
    devexDashboardURL = environment.apiEndpoint + '/dashboard';
    private dashboardURL = environment.apiEndpoint + '/dashboard/';

    constructor(private http: HttpClient) {
    }

    getDashboard(id: number): Observable<Dashboard> {
        return this.http.get<Dashboard>(this.dashboardURL + id, { headers: { 'Access-Control-Allow-Origin': '*' } });
    }

    getDashboards(): Observable<Dashboard[]> {
        return this.http.get<Dashboard[]>(this.dashboardURL);
    }

    getDashboardsBySeller(sellerID: number, beginDate: Date, endDate: Date): Observable<Dashboard[]> {
        return this.http.post<Dashboard[]>(this.dashboardURL + 'byseller',
        {
            sellerID,
            beginDate,
            endDate
        });
    }

    getDashboardArray(context: DashboardContext): Observable<DashboardContentResponse> {
        return this.http.post<DashboardContentResponse>(this.dashboardURL + 'object', context);
    }

    getPreviewDashboardArray(context: DashboardContext): Observable<DashboardContentResponse> {
        return this.http.post<DashboardContentResponse>(this.dashboardURL + 'preview', context);
    }

    getDashboardId(dashboardArray: Uint8Array): Observable<number> {
        return this.http.post<number>(this.dashboardURL + 'dashboardId', dashboardArray);
    }

    getDashboardMapping(): Observable<{id: number, name: string}[]> {
        return this.http.get<{id: number, name: string}[]>(this.dashboardURL + 'mapping');
    }

    getDashboardMappingBySeller(sellerID: number, beginDate: Date, endDate: Date): Observable<any[]> {
        return this.http.post<any[]>(this.dashboardURL + 'mapping', {sellerID, beginDate, endDate});
    }

    getDashboardSellerPublishByDashboardId(dashboardId: number) {
        return this.http.get<DashboardSellerPublish[]>(this.dashboardURL + 'publishedDashboardsByDashboardId/' + dashboardId);
    }

    postDashboardConfig(dashboardConfigId: number, dashboardConfigJSON: any): Observable<any> {
        // this calls the devexpress dashboard controller, not our DashboardController.cs
        return this.http.post<any>(this.dashboardURL + 'dashboards/' + dashboardConfigId, dashboardConfigJSON);
    }

    insertDashboard(dashboard: Dashboard): Observable<Dashboard> {
        return this.http.post<Dashboard>(this.dashboardURL + 'insert', dashboard);
    }

    cloneDashboard(sourceId: number, name: string): Observable<Dashboard> {
        return this.http.post<Dashboard>(this.dashboardURL + 'clone' + `?sourceId=${sourceId}&name=${encodeURIComponent(name)}`, {});
    }

    updateDashboard(dashboard: Dashboard): Observable<number> {
        return this.http.put<number>(this.dashboardURL + 'update', dashboard);
    }

    renameDashboard(dashboardId: number, newName: string): Observable<number> {
        return this.http.put<number>(this.dashboardURL + `rename?dashboardId=${dashboardId}&newName=${newName}`, null);
    }

    updateShowInactiveSegments(showInactiveSegments: boolean): Observable<number> {
        return this.http.put<number>(this.dashboardURL + 'showInactiveSegments/' + showInactiveSegments.toString(), null);
    }

    updateAttributes(dashboardId: number, attributes: string): Observable<CoreResponse<number>> {
        return this.http.put<CoreResponse<number>>(this.dashboardURL + `attributes?dashboardId=${dashboardId}&attributes=${attributes}`, null);
    }

    updateDashboardPublished(publishInfo: UpdatePublishRequest){
        return this.http.post<CoreResponse<number>>(this.dashboardURL + 'updateDashboardPublished', publishInfo);
    }

    deleteDashboard(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.dashboardURL + id.toString());
    }

    cancelDashboard(id: string): Observable<CoreResponse<number>> {
        return this.http.get<CoreResponse<number>>(this.dashboardURL + 'cancel/' + id.toString());
    }
}

import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, from } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { CoreBotInteraction } from '../models/core-bot-interaction';
import { PageUrl } from '../models/page-url';
import { CoreBotInteractionLog } from '../models/core-bot-interaction-log';
import { SellerService } from './seller.service';
import { CoreBotInteractionTriggerType, CoreFeature, EnumCoreBotInteraction, EnumSettingClassId, EnumUserGroup } from '../constants/enums';
import { SettingService } from './setting.service';
import { CoreBotAuditInteraction } from '../models/core-bot-audit-interaction';
import { PermissionService } from './permission.service';
import { filter, first } from 'rxjs/operators';
import { PeriodService } from './period.service';

@Injectable({
    providedIn: 'root'
})

export class CoreBotInteractionService {
    private url: string = environment.apiEndpoint + '/CoreBotInteraction';
    private corebotInteraction = new BehaviorSubject<CoreBotInteraction>(null);
    private animationState = new BehaviorSubject<boolean>(false);
    private animationClosed = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient,
        private sellerService: SellerService,
        private permissionService: PermissionService,
        private periodService: PeriodService,
        private settingService: SettingService) {
    }

    getRelevantCoreBotInteraction(page: PageUrl): Observable<CoreResponse<CoreBotInteraction>> {
        return this.http.post<CoreResponse<CoreBotInteraction>>(this.url + '/page', page);
    }

    getCoreBotInteraction(id: number): Observable<CoreResponse<CoreBotInteraction>>{
        return this.http.get<CoreResponse<CoreBotInteraction>>(this.url + '/' + id);
    }

    getAllAuditInteractions(): Observable<CoreResponse<CoreBotAuditInteraction>>{
        return this.http.get<CoreResponse<CoreBotAuditInteraction>>(this.url + '/auditInteraction');
    }

    insertAuditInteraction(interaction: CoreBotAuditInteraction): Observable<CoreResponse<number>>{
        return this.http.post<CoreResponse<number>>(this.url + '/auditInteraction', interaction);
    }

    insertCoreBotInteractionLog(log: CoreBotInteractionLog): Observable<CoreResponse<number>>{
        return this.http.post<CoreResponse<number>>(this.url + '/log', log);
    }

    runAuditInteractionsByTriggerType(triggerType: number, periodId: number): Observable<CoreResponse<CoreBotAuditInteraction>>{
        return this.http.post<CoreResponse<CoreBotAuditInteraction>>(this.url + '/auditInteraction/run/' + triggerType + '/' + periodId, {});
    }

    updateAuditInteraction(interaction: CoreBotAuditInteraction): Observable<CoreResponse<number>>{
        return this.http.put<CoreResponse<number>>(this.url + '/auditInteraction', interaction);
    }

    deleteAuditInteraction(interactionId: number): Observable<CoreResponse<number>>{
        return this.http.delete<CoreResponse<number>>(this.url + '/auditInteraction/' + interactionId);
    }

    getCurrentCoreBotInteraction(): Observable<CoreBotInteraction> {
        return this.corebotInteraction.asObservable();
    }

    setCurrentCoreBotInteraction(interaction: CoreBotInteraction): void {
        this.corebotInteraction.next(interaction);
    }

    getAnimationState(): Observable<boolean> {
        return this.animationState.asObservable();
    }

    playAnimation() {
        this.animationState.next(true);
    }

    animationComplete() {
        this.animationState.next(false);
    }

    startAnimationCooldown() {
        this.animationClosed.next(true);
        setTimeout(() => {
            this.animationClosed.next(false);
        }, 15000);
    }

    startInteraction(id: number){
        const doesUserHaveBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());

        if (doesUserHaveBotAccess === true){
            forkJoin([
                this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting),
                this.getCoreBotInteraction(id),
                this.sellerService.getMe()
            ]).subscribe(([isUsingCoreBot, interaction, me]) => {
                if (isUsingCoreBot &&
                    ((doesUserHaveBotAccess &&
                        me.userGroupId !== EnumUserGroup.Implementer) ||
                    (me.userGroupId === EnumUserGroup.Implementer &&
                        interaction.result.showImplementer === true)
                    )){
                    if (this.animationState.value === false && this.animationClosed.value === false){
                        this.playAnimation();
                        this.setCurrentCoreBotInteraction(interaction.result);
                    }
                }
            });
        }
    }

    checkForCoreBotInteractions(url: string){
        const page: PageUrl = {page: url};
        const doesUserHaveBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());

        if (doesUserHaveBotAccess === true){
            forkJoin([
                this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting),
                this.getRelevantCoreBotInteraction(page),
                this.sellerService.getMe()
            ]).subscribe(([isUsingCoreBot, interaction, me]) => {
                if (interaction.result &&
                    isUsingCoreBot === true &&
                    ((me.userGroupId === EnumUserGroup.Implementer &&
                            interaction.result.showImplementer === true) ||
                    (doesUserHaveBotAccess &&
                            me.userGroupId !== EnumUserGroup.Implementer)
                    )){
                    if (this.animationState.value === false && this.animationClosed.value === false){
                        this.playAnimation();
                        this.setCurrentCoreBotInteraction(interaction.result);
                    }
                }
            });
        }
    }

    runAuditInteractions(triggerType: number){
        forkJoin([
            this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting),
            this.settingService.getBoolSetting(EnumSettingClassId.AuditInteractionsEnabled),
            this.periodService.getBoundPeriods().pipe(),
            this.sellerService.getMe()
        ]).subscribe(([isUsingCoreBot, isInteractionsEnabled, periods, me]) => {
            let periodId;
            from(periods)
                .pipe(filter(period => Date.parse(period.beginDate.toString()) === Date.parse(localStorage.getItem('beginDate'))),
                first()).subscribe(res => {
                periodId = res.id;
            });
            const doesUserHaveBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());
            if (isInteractionsEnabled && isUsingCoreBot === true && (doesUserHaveBotAccess && me.userGroupId !== EnumUserGroup.Implementer)){
                this.runAuditInteractionsByTriggerType(triggerType, periodId).subscribe(results => {
                    if (results.results.length > 0 && this.animationState.value === false && this.animationClosed.value === false){
                        const result = results.results[0];
                        let customInteractionId: number;
                        switch (result.triggerTypeId){
                            case CoreBotInteractionTriggerType.RunCycleStart:
                                customInteractionId = EnumCoreBotInteraction.CustomRunCycleStart;
                                break;
                            case CoreBotInteractionTriggerType.Login:
                                customInteractionId = EnumCoreBotInteraction.CustomLogin;
                                break;
                            case CoreBotInteractionTriggerType.ReportGeneration:
                                customInteractionId = EnumCoreBotInteraction.CustomReportGeneration;
                                break;
                            case CoreBotInteractionTriggerType.DashboardGeneration:
                                customInteractionId = EnumCoreBotInteraction.CustomDashboardGeneration;
                                break;
                            default:
                                customInteractionId = 0;
                        }

                        this.getCoreBotInteraction(customInteractionId).subscribe(res => {
                            const interaction = res.result;
                            interaction.botPrompt = result.botPrompt;
                            interaction.message = result.message;
                            this.playAnimation();
                            this.setCurrentCoreBotInteraction(interaction);
                        });
                    }
                });
            }
        });
    }
}

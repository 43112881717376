import { ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DashboardViewerComponent } from '../../shared/components/dashboard-viewer/dashboard-viewer.component';
import { ReportViewerComponent } from '../../shared/components/report-viewer/report-viewer.component';
import { AnalyticViewerComponent } from '../../shared/components/analytic-viewer/analytic-viewer.component';
import { CoreBotInteractionTriggerType, EnumBucketClass, EnumBucketType, EnumSettingClassId, EnumSingletonClassId, TenantStatus } from '../../shared/constants/enums';
import { Bucket } from '../../shared/models/bucket';
import { BucketService } from '../../shared/services/bucket.service';
import { SingletonService } from '../../shared/services/singleton.service';
import { SellerService } from '../../shared/services/seller.service';
import { AppInfoService } from '../../shared/services/app-info.service';
import { AnalyticContext } from '../../shared/models/contexts/analytic-context';
import { AnalyticService } from '../../shared/services/analytic.service';
import { IgCache } from '../../shared/models/ig-cache';
import { AuthService } from '../../shared/services/auth.service';
import { MessageService } from '../../shared/services/message.service';
import { Message } from '../../shared/models/message';
import { EnumUserGroup } from 'src/app/shared/constants/enums';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from } from 'rxjs';
import { Singleton } from 'src/app/shared/models/singleton';
import { DatePipe } from '@angular/common';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { EmailService } from 'src/app/shared/services/email.service';
import { isThisISOWeek } from 'date-fns';
import { EmailContext } from 'src/app/shared/models/contexts/email-context';
import { HelperService } from 'src/app/shared/services/helper.service';
import { CoreBotInteractionService } from 'src/app/shared/services/core-bot-interaction-service';

const noPdfToolbar: string = '#toolbar=0&navpanes=0&scrollbar=0&view=fitH';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [BucketService]
})

export class HomeComponent implements OnInit, AfterViewInit {

    @ViewChild(ReportViewerComponent, { static: false }) reportViewer: ReportViewerComponent;
    @ViewChild(DashboardViewerComponent, { static: false }) dashboardViewer: DashboardViewerComponent;
    @ViewChild(AnalyticViewerComponent, { static: false }) analyticViewer: AnalyticViewerComponent;

    showDashboard: boolean = false;
    isPopupVisible: boolean = false;
    isEndingMessageVisible: boolean = false;
    isTrialCountdownPopupVisible: boolean = false;
    isTrialExpiredPopupVisible: boolean = false;
    trialDaysRemaining: number;
    isDEFCON3PopupVisible: boolean = false;
    isDEFCON2PopupVisible: boolean = false;
    isDEFCON1PopupVisible: boolean = false;
    isCustomerSurveyPopupVisible: boolean = false;
    isCoreBotPopupVisible: boolean = false;
    deactivationClause: string = '';
    singletons: Singleton[];
    showReport: boolean = false;
    showAnalytic: boolean = false;
    useDefaultBucket: boolean = false;
    defaultBucket: Bucket;
    analyticContext: AnalyticContext;
    loadingData: boolean;
    igCacheRows: IgCache[];
    approverName: string;
    approverCompany: string;
    approverEmail: string;
    approverPosition: string;
    doc: SafeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/documents/CoreAgreement.pdf' + noPdfToolbar);
    dataSource: any;
    tenantName: string;
    userLogin: string;

    welcomeMessage: Message;
    welcomeMessageDraft: Message = new Message();
    welcomeHtmlTrusted: SafeHtml;
    isEditMessagePopupVisible: boolean = false;
    isAdmin: boolean = false;

    constructor(public appInfo: AppInfoService,
        private changeDetector: ChangeDetectorRef,
        private bucketService: BucketService,
        private analyticService: AnalyticService,
        private messageService: MessageService,
        private authService: AuthService,
        private sellerService: SellerService,
        private domSanitizer: DomSanitizer,
        private toast: ToastrService,
        private datePipe: DatePipe,
        private singletonService: SingletonService,
        private permissionService: PermissionService,
        private settingService: SettingService,
        private emailService: EmailService,
        private helperService: HelperService,
        private interactionService: CoreBotInteractionService) {
        this.loadingData = true;
    }

    ngOnInit() {
        [this.tenantName, this.userLogin] = this.authService.getUserFromToken().split('\\');
        this.appInfo.getTenant(this.tenantName).then(tenant => {
            forkJoin([this.bucketService.useDefaultBucket(),
                this.sellerService.getHasAdminView(),
            ]).subscribe(([bucketSetting, hasAdminView]) => {
                this.useDefaultBucket = bucketSetting;
                this.isAdmin = hasAdminView;

                if ((tenant).status === TenantStatus.ThirtyDayTrial && tenant.statusDate !== null) {
                    const trialEndDate = new Date(tenant.statusDate);
                    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
                    this.trialDaysRemaining = Math.max(this.helperService.getDateDifferenceInDays(currentDate, trialEndDate), 0);
                    if (this.trialDaysRemaining > 0) {
                        this.isTrialCountdownPopupVisible = true;
                    } else {
                        this.isTrialExpiredPopupVisible = true;
                    }
                } else if ((tenant).status === TenantStatus.DEFCON3 && this.permissionService.getUserGroupId() === EnumUserGroup.Administrator) {
                    this.isDEFCON3PopupVisible = true;
                    if (tenant.statusDate !== null) {
                        const deactivationDate = new Date(new Date().setDate(new Date(tenant.statusDate).getDate() + 1));
                        this.deactivationClause = ` and are scheduled for deactivation on ${String.format('{0:d}', deactivationDate)}`;
                    }
                } else if (tenant.status === TenantStatus.DEFCON2 && this.permissionService.getUserGroupId() === EnumUserGroup.Administrator) {
                    this.isDEFCON2PopupVisible = true;
                } else if (tenant.status === TenantStatus.DEFCON1) {
                    this.isDEFCON1PopupVisible = true;
                    setTimeout(() => {
                        this.authService.logOut();
                    }, 10000);
                }

                if (bucketSetting) {
                    this.bucketService.getBuckets().subscribe(buckets => {
                        const defaultBucket: Bucket = buckets.find(bucket => bucket.bucketClassId === EnumBucketClass.DefaultHome);
                        this.defaultBucket = defaultBucket;

                        if (defaultBucket) {
                            if (defaultBucket.type === EnumBucketType.Dashboard) {
                                this.showDashboard = true;
                                this.changeDetector.detectChanges();
                                this.dashboardViewer.generateDashboard(defaultBucket.itemId);
                            } else if (defaultBucket.type === EnumBucketType.Report) {
                                this.showReport = true;
                                this.changeDetector.detectChanges();
                                this.reportViewer.generateReport(defaultBucket.itemId);
                            } else if (defaultBucket.type === EnumBucketType.Analytic) {
                                this.showAnalytic = true;
                                this.changeDetector.detectChanges();
                                this.analyticViewer.generateAnalytic(defaultBucket.itemId);
                            }
                        }
                    });
                }
            });

            this.messageService.getMessages().subscribe(messages => {
                this.welcomeMessage = messages[0];
                Object.assign(this.welcomeMessageDraft, this.welcomeMessage);
                this.welcomeHtmlTrusted = this.domSanitizer.bypassSecurityTrustHtml(this.welcomeMessage.body);
            });

            this.singletonService.getAllSingletons().subscribe(result => {
                this.singletons = result;
                this.isPopupVisible = this.singletons.find(x => x.singletonClassId === EnumSingletonClassId.KickoutTime).value === null
                    && this.permissionService.getUserGroupId() === EnumUserGroup.Administrator;
            });
        });
    }

    ngAfterViewInit(): void {
        forkJoin([
            this.settingService.getBoolSetting(EnumSettingClassId.ShowTenantSurveyLink),
            this.settingService.getBoolSetting(EnumSettingClassId.ShowUserSurveyLink)
        ]).subscribe(([showTenantSurveyLink, showUserSurveyLink]) => {
            if (showTenantSurveyLink && showUserSurveyLink) {
                this.isCustomerSurveyPopupVisible = true;
            }
        });

        forkJoin([
            this.sellerService.getHasAdminView(),
            this.settingService.getBoolSetting(EnumSettingClassId.ShowTenantCoreBotPopup),
            this.settingService.getBoolSetting(EnumSettingClassId.ShowUserCoreBotPopup)
        ]).subscribe(([isAdmin, showTenantCoreBotPopup, showUserCoreBotPopup]) => {
            if (isAdmin && showTenantCoreBotPopup && showUserCoreBotPopup) {
                this.isCoreBotPopupVisible = true;
            }
        });
        this.interactionService.runAuditInteractions(CoreBotInteractionTriggerType.Login);
    }

    submitAgreement(e) {
        const result = e.validationGroup.validate();
        if (result.isValid === true) {
            const kickOffTime = this.singletons.find(x => x.singletonClassId === EnumSingletonClassId.KickoutTime);
            kickOffTime.value = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss');

            const caSigner = this.singletons.find(x => x.singletonClassId === EnumSingletonClassId.CaSigner);
            caSigner.value = this.approverName;

            const caCompany = this.singletons.find(x => x.singletonClassId === EnumSingletonClassId.CaCompany);
            caCompany.value = this.approverCompany;

            const caEmail = this.singletons.find(x => x.singletonClassId === EnumSingletonClassId.CaEmail);
            caEmail.value = this.approverEmail;

            const caPosition = this.singletons.find(x => x.singletonClassId === EnumSingletonClassId.CaPosition);
            caPosition.value = this.approverPosition;

            forkJoin([
                this.singletonService.updateSingleton(caSigner),
                this.singletonService.updateSingleton(caCompany),
                this.singletonService.updateSingleton(kickOffTime),
                this.singletonService.updateSingleton(caEmail),
                this.singletonService.updateSingleton(caPosition)])
            .subscribe(() => this.isPopupVisible = false);
        }
    }

    onEditMessageClick = () => this.isEditMessagePopupVisible = true;

    onCancelMessageClick = () => this.isEditMessagePopupVisible = false;

    onSaveMessageClick = () => {
        this.welcomeMessageDraft.body = document.getElementsByClassName('dx-htmleditor-content')[0].innerHTML;
        this.messageService.updateMessage(this.welcomeMessageDraft).subscribe(result => {
            Object.assign(this.welcomeMessage, this.welcomeMessageDraft);
            this.welcomeHtmlTrusted = this.domSanitizer.bypassSecurityTrustHtml(this.welcomeMessage.body);
            this.toast.success('Welcome message updated');
        }, error => {
            this.toast.error('Welcome message update failed');
        });
        this.isEditMessagePopupVisible = false;
    };

    refreshViewer() {
        if (this.defaultBucket?.type === EnumBucketType.Dashboard) {
            this.dashboardViewer.generateDashboard(this.defaultBucket.itemId);
        } else if (this.defaultBucket?.type === EnumBucketType.Report) {
            this.reportViewer.generateReport(this.defaultBucket.itemId);
        } else if (this.defaultBucket?.type === EnumBucketType.Analytic) {
            this.analyticViewer.generateAnalytic(this.defaultBucket.itemId);
        }
    }

    closeCustomerSurveyPopup(): void {
        this.isCustomerSurveyPopupVisible = false;
    }

    onClickSurveyLink(): void {
        this.closeCustomerSurveyPopup();

        forkJoin([
            this.sellerService.getSellerWithSubordinateSetting(this.userLogin),
            this.permissionService.getPermissions()
        ]).subscribe(([seller, userGroups]) => {
            const sellerUserGroupName = userGroups.find(x => x.id === seller.userGroupId)?.name;
            const to = 'sarah.calvin@corecommissions.com';
            const subject = 'Customer Review link clicked';
            const body = `
                Customer Review link has been clicked.<br />
                <br />
                Company: <b>${this.tenantName}</b><br />
                User Name: <b>${seller.name}</b><br />
                User Login: <b>${this.userLogin}</b><br />
                User Email Address: <b>${seller.emailAddress}</b><br />
                User Permission Level: <b>${sellerUserGroupName}</b><br />`;

            this.emailService.sendInternalEmail({ to, subject, body }).subscribe(result => {
                if (!this.helperService.isNullOrEmpty(result)) {
                    console.log('An error has occurred while attempting to send email');
                }
            });
        });
    }

    onClickNotInterested(): void {
        this.closeCustomerSurveyPopup();
        this.settingService.upsertUserSettingsByClassIdBulk([{ id: EnumSettingClassId.ShowUserSurveyLink, value: 'False' }]).subscribe(result => {
            if (result !== 1) {
                this.toast.error('An error has occurred while attempting to decline the customer survey popup');
            }
        });
    }

    closeCoreBotPopup(): void {
        this.isCoreBotPopupVisible = false;
    }

    onClickNotInterestedCoreBot(): void {
        this.closeCoreBotPopup();
        this.settingService.upsertUserSettingsByClassIdBulk([{ id: EnumSettingClassId.ShowUserCoreBotPopup, value: 'False' }]).subscribe(result => {
            if (result !== 1) {
                this.toast.error('An error has occurred while attempting to decline the Core Bot popup');
            }
        });
    }
}

<div class="nb-theme-default">
    <nb-chat [size]="size" #nebularChat>
        <div *nbCustomMessage="'corebot-response'; let coreBotText" >
            <div class="text-control button-custom-message-text core-bot-text" [innerHtml]="coreBotText.response"></div>
            <button id="{{'like' + coreBotText.id}}" nbButton ghost status="primary" class="like" (click)="showPopover($event)"><nb-icon icon="checkmark-outline" status="basic" pack="nebular-essentials"></nb-icon></button>
            <button id="{{'dislike' + coreBotText.id}}" nbButton ghost status="primary" class="dislike" (click)="showPopover($event)"><nb-icon icon="close-outline" status="basic" pack="nebular-essentials"></nb-icon></button>
            <dx-popover #likeFeedback
                id="{{'like-feedback-popover' + coreBotText.id}}"
                target="#{{'like' + coreBotText.id}}"
                showEvent="click"
                position="top"
                [hideOnOutsideClick]="true"
                (onHiding)="submitSimpleFeedback($event, coreBotText.id)"
                [width]="300"
            >
                <div *dxTemplate="let data = model; of: 'content'" class="feedback-controls">
                    What made this response great? Please provide any additional feedback:
                    <dx-text-box class="feedback-text" stylingMode="underlined" [(value)]="feedbackContent"></dx-text-box>
                    <div class="button-container">
                        <dx-button class="feedback-submit" text="Submit" (onClick)="submitFeedback($event, coreBotText.id)"></dx-button>
                    </div>
                </div>
            </dx-popover>
            <dx-popover #dislikeFeedback
                id="{{'dislike-feedback-popover' + coreBotText.id}}"
                target="#{{'dislike' + coreBotText.id}}"
                showEvent="click"
                position="top"
                [hideOnOutsideClick]="true"
                (onHiding)="submitSimpleFeedback($event, coreBotText.id)"
                [width]="300"
            >
                <div *dxTemplate="let data = model; of: 'content'" class="feedback-controls">
                    Why was this response undesirable? Please provide any additional feedback:
                    <dx-text-box class="feedback-text" stylingMode="underlined" [(value)]="feedbackContent"></dx-text-box>
                    <div class="button-container">
                        <dx-button class="feedback-submit" text="Submit" (onClick)="submitFeedback($event, coreBotText.id)"></dx-button>
                    </div>
                </div>
            </dx-popover>
            <dx-tooltip
                target="#{{'like' + coreBotText.id}}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="false"
                >              
                <div *dxTemplate="let data = data; of: 'content'"> 
                    This was a good response
                </div>
            </dx-tooltip>
            <dx-tooltip
                target="#{{'dislike' + coreBotText.id}}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="false"
                >              
                <div *dxTemplate="let data = data; of: 'content'"> 
                    This was a poor response
                </div>
            </dx-tooltip>
        </div>
        <div *nbCustomMessage="'querybot-response'; let tableData">
            <dx-data-grid [dataSource]="tableData.data" (onExporting)="onExporting($event, tableData.id)" [columnWidth]="150">
                <dxo-export [enabled]="true"></dxo-export>
                <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                <dxo-sorting mode="none"></dxo-sorting> 
                <dxo-paging pageSize="10"></dxo-paging>
            </dx-data-grid>
            <button id="{{'like' + tableData.id}}" nbButton ghost status="primary" class="like" (click)="showPopover($event)"><nb-icon icon="checkmark-outline" status="basic" pack="nebular-essentials"></nb-icon></button>
            <button id="{{'dislike' + tableData.id}}" nbButton ghost status="primary" class="dislike" (click)="showPopover($event)"><nb-icon icon="close-outline" status="basic" pack="nebular-essentials"></nb-icon></button>
            <dx-popover #likeFeedback
                id="{{'like-feedback-popover' + tableData.id}}"
                target="#{{'like' + tableData.id}}"
                showEvent="click"
                position="top"
                hideOnOutsideClick="true"
                (onHiding)="submitSimpleFeedback($event, tableData.id)"
                [width]="300"
            >
                <div *dxTemplate="let data = model; of: 'content'" class="feedback-controls">
                    What made this response great? Please provide any additional feedback:
                    <dx-text-box class="feedback-text" stylingMode="underlined" [(value)]="feedbackContent"></dx-text-box>
                    <div class="button-container">
                        <dx-button class="feedback-submit" text="Submit" (onClick)="submitFeedback($event, tableData.id)"></dx-button>
                    </div>
                </div>
            </dx-popover>
            <dx-popover #dislikeFeedback
                id="{{'dislike-feedback-popover' + tableData.id}}"
                target="#{{'dislike' + tableData.id}}"
                showEvent="click"
                position="top"
                hideOnOutsideClick="true"
                (onHiding)="submitSimpleFeedback($event, tableData.id)"
                [width]="300"
            >
                <div *dxTemplate="let data = model; of: 'content'" class="feedback-controls">
                    Why was this response undesirable? Please provide any additional feedback:
                    <dx-text-box class="feedback-text" stylingMode="underlined" [(value)]="feedbackContent"></dx-text-box>
                    <div class="button-container">
                        <dx-button class="feedback-submit" text="Submit" (onClick)="submitFeedback($event, tableData.id)"></dx-button>
                    </div>
                </div>
            </dx-popover>
            <dx-tooltip
                target="#{{'like' + tableData.id}}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="false"
                >              
                <div *dxTemplate="let data = data; of: 'content'"> 
                    This was a good response
                </div>
            </dx-tooltip>
            <dx-tooltip
                target="#{{'dislike' + tableData.id}}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="false"
                >              
                <div *dxTemplate="let data = data; of: 'content'"> 
                    This was a poor response
                </div>
            </dx-tooltip>
        </div>
        <div *nbCustomMessage="'confirmButton'; let buttonText">
            <button nbButton status="control" (click)="confirmBotAction($event)">
                {{buttonText.positive}}
            </button>
            <button nbButton class="redirect-no" status="control" (click)="denyBotAction($event)">
                {{buttonText.negative}}
            </button>
        </div>
        <div *nbCustomMessage="'redirectButton'; let buttonText">
            <button nbButton status="control" (click)="confirmUserRedirect($event)">
                {{buttonText.positive}}
            </button>
            <button nbButton class="redirect-no" status="control" (click)="confirmChatMessage($event)">
                {{buttonText.negative}}
            </button>
        </div>
        <div *nbCustomMessage="'auditbot-response'; let coreBotText" >
            <ng-container *ngFor="let response of coreBotText.response">
                <ng-container [ngSwitch]="response.type">
                    <ng-container *ngSwitchCase="0">
                        <ng-container *ngTemplateOutlet="plainTextTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <ng-container *ngTemplateOutlet="formulaTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        <ng-container *ngTemplateOutlet="joinTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="4">
                        <ng-container *ngTemplateOutlet="groupTemplate"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="5">
                        <ng-container *ngTemplateOutlet="unionTemplate"></ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #plainTextTemplate>
                    <p class="text-control button-custom-message-text core-bot-text">{{response.content}}</p>
                </ng-template>
                <ng-template #formulaTemplate>
                    <nb-card class="formula-card">
                        <nb-card-header>Formula</nb-card-header>
                        <nb-card-body>
                          {{response.content}}
                        </nb-card-body>
                      </nb-card>
                </ng-template>
                <ng-template #filterTemplate>
                    <app-bb-filter-builder class="bot-filter-builder"
                        [(filterBuilderObject)]="response.content"
                        [validDataColumns]="coreBotText.dataColumns"
                        [isJoin]="false">
                    </app-bb-filter-builder>
                </ng-template>
                <ng-template #joinTemplate>
                    <app-bb-filter-builder class="bot-filter-builder"
                        [(filterBuilderObject)]="response.content"
                        [validDataColumns]="coreBotText.dataColumns"
                        [auxDataColumns]="coreBotText.dataColumns"
                        [isJoin]="true">
                    </app-bb-filter-builder>
                </ng-template>
                <ng-template #groupTemplate>
                    <app-bb-field-grid
                        [isEditable]="false"
                        [datasource]="response.content">
                    </app-bb-field-grid>
                </ng-template>
                <ng-template #unionTemplate>
                    <app-bb-union-field-input
                        [isEditable]="false"
                        [datasource]="response.content">
                    </app-bb-union-field-input>
                </ng-template>
            </ng-container>
            <button id="{{'like' + coreBotText.id}}" nbButton ghost status="primary" class="like" (click)="showPopover($event)"><nb-icon icon="checkmark-outline" status="basic" pack="nebular-essentials"></nb-icon></button>
            <button id="{{'dislike' + coreBotText.id}}" nbButton ghost status="primary" class="dislike" (click)="showPopover($event)"><nb-icon icon="close-outline" status="basic" pack="nebular-essentials"></nb-icon></button>
            <dx-popover #likeFeedback
                id="{{'like-feedback-popover' + coreBotText.id}}"
                target="#{{'like' + coreBotText.id}}"
                showEvent="click"
                position="top"
                [hideOnOutsideClick]="true"
                (onHiding)="submitSimpleFeedback($event, coreBotText.id)"
                [width]="300"
            >
                <div *dxTemplate="let data = model; of: 'content'" class="feedback-controls">
                    What made this response great? Please provide any additional feedback:
                    <dx-text-box class="feedback-text" stylingMode="underlined" [(value)]="feedbackContent"></dx-text-box>
                    <div class="button-container">
                        <dx-button class="feedback-submit" text="Submit" (onClick)="submitFeedback($event, coreBotText.id)"></dx-button>
                    </div>
                </div>
            </dx-popover>
            <dx-popover #dislikeFeedback
                id="{{'dislike-feedback-popover' + coreBotText.id}}"
                target="#{{'dislike' + coreBotText.id}}"
                showEvent="click"
                position="top"
                [hideOnOutsideClick]="true"
                (onHiding)="submitSimpleFeedback($event, coreBotText.id)"
                [width]="300"
            >
                <div *dxTemplate="let data = model; of: 'content'" class="feedback-controls">
                    Why was this response undesirable? Please provide any additional feedback:
                    <dx-text-box class="feedback-text" stylingMode="underlined" [(value)]="feedbackContent"></dx-text-box>
                    <div class="button-container">
                        <dx-button class="feedback-submit" text="Submit" (onClick)="submitFeedback($event, coreBotText.id)"></dx-button>
                    </div>
                </div>
            </dx-popover>
            <dx-tooltip
                target="#{{'like' + coreBotText.id}}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="false"
                >              
                <div *dxTemplate="let data = data; of: 'content'"> 
                    This was a good response
                </div>
            </dx-tooltip>
            <dx-tooltip
                target="#{{'dislike' + coreBotText.id}}"
                showEvent="mouseenter"
                hideEvent="mouseleave"
                [hideOnOutsideClick]="false"
                >              
                <div *dxTemplate="let data = data; of: 'content'"> 
                    This was a poor response
                </div>
            </dx-tooltip>
        </div>
        <div class="rule-links" *nbCustomMessage="'auditbot-report-redirect'; let coreBotText">
            <p class="text-control button-custom-message-text core-bot-text audit-redirect-text"> {{coreBotText.text}} </p>
            <ng-container *ngFor="let rule of coreBotText.rules">
                <button class="rule-link-button" nbButton status="control" (click)="confirmRuleRedirect(rule.rule.Id)">
                    Audit "{{rule.rule.Name}}"
                </button>
            </ng-container>
            <button class="rule-link-button" nbButton status="control" (click)="confirmRuleRedirect('output')">
                Go to processed output
            </button>
            <button class="rule-link-button" nbButton status="control" (click)="denyBotAction($event)">
                Stay here
            </button>
        </div>
        <div class="setup-wizard-buttons" *nbCustomMessage="'setupbot-advance-message'; let coreBotText">
            <ng-container *ngFor="let nextStep of coreBotText.nextSteps">
                <div>
                    <button class="next-step-button" nbButton status="control" (click)="confirmNextStepRedirect(nextStep)">
                        {{nextStep}}
                    </button>
                </div>
            </ng-container> 
        </div>
         <div class="embedded-video" *nbCustomMessage="'embedded-video-message'; let coreBotText">
            <iframe 
                width="448" 
                height="252" 
                [src]="coreBotText.hyperlink"
                title="Video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerpolicy="strict-origin-when-cross-origin" 
                allowfullscreen>
            </iframe>
         </div>
        <nb-chat-message *ngFor="let msg of messages"
                        [type]="msg.type"
                        [message]="msg.text"
                        [reply]="msg.reply"
                        [sender]="msg.user.name"
                        [date]="msg.date"
                        [files]="msg.files"
                        [avatar]="msg.user.avatar"
                        [customMessageData]="msg.customMessageData">
        </nb-chat-message>
    </nb-chat>
</div>

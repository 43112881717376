import { CoreColumn } from './core-column';
import { PopupArguments } from './popup-arguments';
import { RequiredProperty } from './required-property';

export class DisplayDataArguments {
    requiredProperties: RequiredProperty[];
    functionEnum: number;
    popupArgs: PopupArguments;

    constructor(requiredProperties: RequiredProperty[],
        functionEnum: number,
        popupArgs: PopupArguments) {
        this.requiredProperties = requiredProperties;
        this.functionEnum = functionEnum;
        this.popupArgs = popupArgs;
    }
}

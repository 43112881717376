import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Permission } from '../models/permission';
import { HelperService } from './helper.service';
import { SellerService } from './seller.service';
import { PermissionClass } from '../models/permission-class';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {

    private url = environment.apiEndpoint + '/Permission';
    private userGroupId: number;
    private userGroupDict: any = {};
    private buildPermissionClasses: PermissionClass[];

    constructor(private http: HttpClient, private helperService: HelperService, private sellerService: SellerService) {
    }

    setUserGroupDict(toSetDict: Permission[]) {
        for (const userGroup of toSetDict) {
            this.userGroupDict[userGroup.id] = this.helperService.removeBracketsFromString(userGroup.permissionString, true);
        }
    }

    setUserGroupId(toSetId: number) {
        this.userGroupId = toSetId;
    }

    async initializePermissions() {
            await this.sellerService.getMe().toPromise().then(async (me) => {
            this.userGroupId = me.userGroupId;
            await this.getPermissions().toPromise().then(userGroups => {
                for (const userGroup of userGroups) {
                    this.userGroupDict[userGroup.id] = this.helperService.removeBracketsFromString(userGroup.permissionString, true);
                }
                this.getPermissionClasses().subscribe((permissionclasses) => {
                    this.buildPermissionClasses = permissionclasses;
                });
            });
        });
    }

    getPermissions(): Observable<Permission[]> {
        return this.http.get<Permission[]>(this.url + '/permissions');
    }

    fatGetPermissions(): Observable<Permission[]> {
        return this.http.get<Permission[]>(this.url + '/fatpermissions');
    }

    getPermission(permissionId: number): Observable<Permission> {
        return this.http.get<Permission>(this.url + '/permission/' + permissionId);
    }

    getPermissionClasses(): Observable<PermissionClass[]> {
        return this.http.get<PermissionClass[]>(this.url + '/classes');
    }

    insertPermission(permission: Permission): Observable<Permission> {
        return this.http.post<Permission>(this.url + '/insert', permission);
    }

    updatePermission(permission: Permission): Observable<number> {
        return this.http.put<number>(this.url + '/update', permission);
    }

    renamePermission(permissionId: number, newName: string): Observable<number> {
        return this.http.put<number>(this.url + `/rename?permissionId=${permissionId}&newname=${newName}`, null);
    }

    deletePermission(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.url + '/permission/' + id.toString());
    }

    getUserGroupDict(): any {
        return this.userGroupDict;
    }

    getUserGroupId(): number {
        return this.userGroupId;
    }

    getBuildPermissionClasses(): PermissionClass[] {
        return this.buildPermissionClasses;
    }

    getCurrentSellerUserGroupPermissionList(): string[] {
        return this.userGroupDict[this.userGroupId];
    }

    checkPermission(permissionIdToCheck: string, allowedPermissionList: string[]): boolean {
        return allowedPermissionList.includes(permissionIdToCheck);
    }

    checkCurrentUserPermission(permissionIdToCheck: string): boolean {
        return this.getCurrentSellerUserGroupPermissionList().includes(permissionIdToCheck);
    }

    getIsImplementer(): Observable<boolean> {
        return this.http.get<boolean>(this.url + '/isImplementer');
    }

}

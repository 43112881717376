import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { currentTheme, refreshTheme } from 'devextreme/viz/themes';
import { Subscription } from 'rxjs';
import { AnalyticViewerComponent } from '../../shared/components/analytic-viewer/analytic-viewer.component';
import { CoreFeature, EnumBucketClass, EnumBucketType, EnumSettingClassId, EnumUserGroup } from '../../shared/constants/enums';
import { Bucket } from '../../shared/models/bucket';
import { Analytic } from '../../shared/models/analytic';
import { BucketService } from '../../shared/services/bucket.service';
import { AnalyticService } from '../../shared/services/analytic.service';
import { SellerService } from '../../shared/services/seller.service';
import { AuthService } from '../../shared/services/auth.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { CoreDropdownComponent } from 'src/app/shared/components/core-dropdown/core-dropdown.component';
import { SettingService } from 'src/app/shared/services/setting.service';
import { Seller } from 'src/app/shared/models/seller';
import { TooltipComponent } from 'ag-grid-community/dist/lib/components/framework/componentTypes';
import { DxTooltipComponent } from 'devextreme-angular';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { SiteThemeService } from 'src/app/shared/services/site-theme.service';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    providers: [SellerService]
})

export class AnalyticsComponent implements OnInit, OnDestroy {
    @ViewChild(AnalyticViewerComponent) analyticViewer: AnalyticViewerComponent;
    @ViewChild(CoreDropdownComponent) dropdown: CoreDropdownComponent;
    @ViewChild('tooltip') tooltip: DxTooltipComponent;

    analyticId: number;
    analytic: Analytic;
    analytics: {id: number, name: string}[];
    sellerId: number;

    loadingVisible: boolean;
    useDefaultBucket = false;
    defaultBucket: Bucket;
    favoriteBucket: Bucket;
    isNavBucketFoldersPopupVisible: boolean = false;
    bucketFolderType: EnumBucketType = EnumBucketType.Analytic;
    checkBoxValue = false;
    pageDestroyed: boolean = false;

    canCreateAnalytics: boolean;
    canDeleteAnalytics: boolean;
    canEditAnaltyics: boolean;
    canEditAnalyticPermissions: boolean;
    isAttributesPopupVisible: boolean = false;
    analyticDropdownProps: CoreDropdownProperties;
    path = 'pages/analytic';
    publishedSellerIds: number[] = [];

    chats = [];
    sellerName: string = '';

    isAnalyticBotPopupVisible: boolean = false;
    isImplementer: boolean = false;
    isAnalyticBotDisabled: boolean = true;
    isAnalyticBotVisible: boolean = false;
    seller: Seller;
    userHasBotAccess: boolean = false;

    hasAdminView: boolean = false;
    favoriteButton: any = {
        id: 'favorite-button',
        icon: 'favorites',
        hint: 'Favorite/Unfavorite',
        onClick: () => {
            if (this.favoriteBucket) {
                this.bucketService.deleteFavoriteBucket(this.favoriteBucket).subscribe();
            } else {
                this.bucketService.addFavoriteBucket(this.analyticId, EnumBucketType.Analytic, this.sellerId).subscribe();
            }
        }
    };
    navFolderButton: any = {
        id: 'nav-folder-settings',
        icon: 'folder',
        hint: 'Nav Folder Settings',
        onClick: () => {
            this.isNavBucketFoldersPopupVisible = true;
        }
    };
    openBulkHomeDefaultPopup: boolean = false;
    readonly analyticBucketType = EnumBucketType.Analytic;

    constructor(private route: ActivatedRoute,
        private location: Location,
        private bucketService: BucketService,
        private authService: AuthService,
        private sellerService: SellerService,
        private analyticService: AnalyticService,
        private settingService: SettingService,
        private siteThemeService: SiteThemeService,
        private appElementsService: AppElementsService,
        private permissionService: PermissionService) {
    }

    ngOnInit() {
        this.canCreateAnalytics = this.permissionService.checkCurrentUserPermission(CoreFeature.CreateAnalytics.toString());
        this.canEditAnaltyics = this.permissionService.checkCurrentUserPermission(CoreFeature.EditAnalytics.toString());
        this.canDeleteAnalytics = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteAnalytics.toString());
        this.canEditAnalyticPermissions = this.permissionService.checkCurrentUserPermission(CoreFeature.AssignAnalyticsPermissions.toString());
        this.userHasBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());

        this.analyticDropdownProps = new CoreDropdownProperties()
            .createAll(true, 'id', 'name', !this.canEditAnaltyics, !this.canCreateAnalytics, !this.canEditAnaltyics,
                !this.canDeleteAnalytics, false, false, 250, 'analytic', 'isDefault', 'isDefault', 'isDefault')
            .setProp('searchEnabled', true);

        this.settingService.getBoolSetting(EnumSettingClassId.EnableNavBucketFolders).subscribe(navBucketFoldersEnabled => {
            if (navBucketFoldersEnabled) {
                this.analyticDropdownProps.dynamicButtons.push(this.navFolderButton);
            }
        });

        this.sellerId = this.authService.getSellerIdFromToken();
        this.getAnalytics();
        this.sellerService.getHasAdminView().subscribe(hasAdminView => {
            this.hasAdminView = hasAdminView;
            if (hasAdminView) {
                this.analyticDropdownProps.dynamicButtons.push(this.favoriteButton);
            } else {
                this.analyticDropdownProps.dropdownHidden = true;
            }
            this.route.paramMap.subscribe(params => {
                if (params.get('analyticID') || !hasAdminView) {
                    this.analyticId = parseInt(params.get('analyticID'), 10);
                    this.onSelect(this.analyticId, true);
                }
                if (!this.analyticId) {
                    // Load the default analytic
                    this.analyticService.getDefaultAnalytic().subscribe(analytic => {
                        this.analytic = analytic;
                        this.analyticId = analytic.id;
                        this.getDefaultBucket();
                        this.analyticViewer.generateAnalytic(this.analytic.id);
                        this.getPublishedSellerIds();
                    });
                } else {
                    this.getPublishedSellerIds();
                }

            });
        });
        this.bucketService.getFavoriteEvent().subscribe(() => {
            this.getFavoriteBucket();
        });
        this.sellerService.getSellerWithSubordinateSetting(this.authService.getUserFromToken()).subscribe(user => {
            this.seller = user;

            this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting).subscribe(isUsingCoreBotBeta => {
                if (this.seller.userGroupId === EnumUserGroup.Implementer
                        || this.authService.getUserFromToken().toLocaleLowerCase().includes('corehome')
                        || (isUsingCoreBotBeta === true && this.userHasBotAccess)){
                    this.isAnalyticBotVisible = true;
                    this.checkAnalyticBotDisabled();
                }
            });
        });

        this.setAnalyticsMode();
        const darkModeChanges: Subscription = this.appElementsService.getDarkModeChanges().subscribe(x => {
            if (!this.pageDestroyed) {
                this.setAnalyticsMode();
            } else {
                darkModeChanges.unsubscribe();
            }
        });
    }

    setAnalyticsMode(): void {
        const isDarkMode = this.siteThemeService.getIsDarkMode();
        const theme = isDarkMode ? 'material.blue.dark.compact' : 'material.blue.light.compact';
        currentTheme(theme);
        refreshTheme();
    }

    getAnalytics(): void {
        // Getting the analytics so that it can prevent user from renaming analytic to a name that already exists
        this.analyticService.getAnalytics().subscribe(analytics => {
            this.analytics = analytics.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    getDefaultBucket() {
        this.bucketService.useDefaultBucket().subscribe(b => {
            this.useDefaultBucket = b;
            if (this.useDefaultBucket) {
                this.bucketService.getDefaultBucket(this.analyticId, EnumBucketType.Analytic).subscribe(bucket => {
                    this.defaultBucket = bucket;
                    this.updateHomeDefaultButton(!!bucket);
                });
            }
        });
    }

    homeDefaultValueChanged() {
        const shouldEnable = !this.defaultBucket;
        if (shouldEnable) {
            const bucket: Bucket = {
                id: 0,
                bucket: 0,
                bucketClassId: EnumBucketClass.DefaultHome,
                itemId: this.analyticId,
                sellerId: 0,
                type: EnumBucketType.Analytic,
                sequenceNo: null,
                bucketClass: null,
                name: null
            };

            this.bucketService.addDefaultBucket(bucket).subscribe(defaultBucket => {
                this.defaultBucket = defaultBucket;
                this.updateHomeDefaultButton(shouldEnable);
            });
        } else {
            this.bucketService.deleteBucket(this.defaultBucket).subscribe(() =>{
                this.updateHomeDefaultButton(shouldEnable);
            });
            this.defaultBucket = null;
        }
    }

    onAnalyticLoaded(e): void {
        this.analytic = e;
    }

    onAnalyticCreated(): void {
        this.getAnalytics();
    }

    onRename(e): void {
        this.analyticViewer.onRename(e);
    }

    onSave(): void {
        this.analyticViewer.onSave(this.publishedSellerIds, false);
    }

    onSavePermissions(): void {
        this.analyticViewer.onSave(this.publishedSellerIds, true);
    }

    onAdd(e): void {
        this.analyticViewer.onAdd(e);
    }

    onDelete(): void {
        this.analyticViewer.onDelete();
    }

    expandAll(): void {
        this.analyticViewer.expandAll();
    }

    collapseAll(): void {
        this.analyticViewer.collapseAll();
    }

    refreshViewer() {
        this.analyticViewer.generateAnalytic(this.analyticId);
    }

    onSelect(id: any, initialCall: boolean = false) {
        this.analyticViewer.showChartLoadingPanel = this.analyticId !== id;
        this.analyticId = id;
        this.analyticViewer.generateAnalytic(this.analyticId, null, null, null, initialCall);
        this.getDefaultBucket();
        this.getFavoriteBucket();
        this.getPublishedSellerIds();
        this.location.go(this.path + (id ? '/' + id : ''));
        this.checkAnalyticBotDisabled();
    }

    getPublishedSellerIds(): void {
        this.analyticService.getAnalyticSellerPublishByAnalyticId(this.analyticId).subscribe(publishes => {
            this.publishedSellerIds = publishes.map(publish => publish.sellerId);
        });
    }

    getFavoriteBucket() {
        this.bucketService.getFavoriteBucket(this.analyticId, EnumBucketType.Analytic).subscribe(bucket => {
            this.favoriteBucket = bucket;
            this.dropdown.updateDynamicButton('favorite-button', !!this.favoriteBucket);
        });
    }

    onClickPermissions(): void {
        this.isAttributesPopupVisible = true;
    }

    onClickBulkHomeDefault(): void {
        this.openBulkHomeDefaultPopup = true;
    }

    updateHomeDefaultButton(state: boolean): void {
        const classList = document.getElementById('home-default-button').classList;
        if (state !== classList.contains('active')) {
            classList.toggle('active');
        }
    }

    showAnalyticBotPopup(e: any){
        this.isAnalyticBotPopupVisible = true;
    }

    onPopupStateChanged(e: any){
        this.isAnalyticBotPopupVisible = false;
    }

    checkAnalyticBotDisabled(){
        if (!this.analyticId || this.analyticId === this.analytics?.filter(x => x.name === '<Default Payments>')[0].id){
            this.isAnalyticBotDisabled = true;
            this.tooltip.showEvent = 'mouseenter';
        }
        else {
            this.isAnalyticBotDisabled = false;
            this.tooltip.showEvent = 'none';
        }
    }

    ngOnDestroy(): void {
        this.pageDestroyed = true;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { DatasourceMapping } from '../models/datasource-mapping';

@Injectable()
export class DatasourceMappingService {
    private baseUrl = environment.apiEndpoint + '/datasourceMapping/';

    constructor(private http: HttpClient) {
    }

    getAllDatasourceMappings(fat: boolean = false): Observable<DatasourceMapping[]> {
        return this.http.get<DatasourceMapping[]>(this.baseUrl + fat);
    }

    insertDatasourceMapping(mapping: DatasourceMapping): Observable<DatasourceMapping> {
        return this.http.post<DatasourceMapping>(this.baseUrl, mapping);
    }

    updateDatasourceMapping(mapping: DatasourceMapping, fat: boolean = false): Observable<number> {
        return this.http.put<number>(this.baseUrl + fat, mapping);
    }

    deleteDatasourceMapping(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + id);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { IntegrationMappingField } from '../models/integration-mapping-field';

@Injectable()
export class IntegrationMappingFieldService {
    private baseUrl = environment.apiEndpoint + '/integrationMappingField/';

    constructor(private http: HttpClient) {
    }

    getAllIntegrationMappingFields(): Observable<IntegrationMappingField[]>{
        return this.http.get<IntegrationMappingField[]>(this.baseUrl);
    }

    insertIntegrationMappingField(integrationMappingField: IntegrationMappingField): Observable<IntegrationMappingField> {
        return this.http.post<IntegrationMappingField>(this.baseUrl, integrationMappingField);
    }

    updateIntegrationMappingField(integrationMappingField: IntegrationMappingField): Observable<number> {
        return this.http.put<number>(this.baseUrl, integrationMappingField);
    }

    deleteIntegrationMappingField(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + id);
    }

    deleteIntegrationMappingFieldsBySource(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + 'source/' + id);
    }
}

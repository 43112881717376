<ng-content></ng-content>

<div class="menu-container">
    <dx-tree-view
        expandEvent="click"
        width="100%"
        [dataSource]="dataSource"
        (onInitialized)="onMenuInitialized($event)"
        (onItemClick)="onItemClick($event)"
        (onContentReady)="updateSelection($event)"
        (onSelectionChanged)="updateSelection($event)"
        [selectByClick]="!isPreview"
        selectionMode="single"
        keyExpr="path"
        noDataText="">
        <div *dxTemplate="let data of 'item'" style="position:relative;" class="item-id-{{data.itemId}} {{(data.bucketFolder ? 'never-selected' : '')}}">
            <div *ngIf="isPreview && data.isPreviewItem" class="preview-item"></div>
            <div *ngIf="data.topLevel; else menuItemElseBlock">
                <a *ngIf="data.path" href="{{data.path}}" (click)="leftClickLink($event)" class="linkable-menu-item" title="{{data.text}}"></a>
                <div title="{{data.text}}">
                    <dx-box class="padded-menu-item icon-and-label {{(data.bucketFolder ? 'bucket-folder' : '')}}">
                        <dxi-item [ratio]="0" [baseSize]="40">
                            <i class="dx-icon dx-icon-{{data.icon}}"></i>
                        </dxi-item>
                        <dxi-item [ratio]="1">
                            <span class="menu-item-text">{{data.text}}</span>
                        </dxi-item>
                    </dx-box>
                </div>
            </div>
            <ng-template #menuItemElseBlock style="border: none; box-shadow: none">
                <a
                    *ngIf="!isPreview"
                    href="{{data.path}}"
                    (click)="leftClickLink($event)"
                    class="linkable-menu-item"
                    [ngClass]="{'plus-menu-item': data.icon === 'plus'}"
                    title="{{data.text}}">
                </a>
                <dx-box class="padded-menu-item {{(data.bucketFolder ? 'bucket-folder-item' : '')}}">
                    <dxi-item [ratio]="0" [baseSize]="60">
                        <dx-button
                            stylingMode="text"
                            style="float: left; margin: auto;"
                            [hoverStateEnabled]="false"
                            [activeStateEnabled]="false"
                            [focusStateEnabled]="false"
                            [icon]="data.icon"
                            (mouseenter)="onFavoriteMouseEnter($event, data)"
                            (mouseleave)="onFavoriteMouseLeave($event, data)"
                            (onClick)="onFavoriteClick($event, data.path)">
                        </dx-button>
                    </dxi-item>
                    <dxi-item [ratio]="1" style="padding-top: 10px; padding-right: 10px;">
                        {{data.text}}
                    </dxi-item>
                </dx-box>
            </ng-template>
        </div>
    </dx-tree-view>
</div>

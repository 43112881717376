import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReportContext } from '../../models/contexts/report-context';
import { Period } from '../../models/period';
import { Seller } from '../../models/seller';
import { CoreDropdownPopupProperties } from '../../models/core-dropdown-popup-properties';
import { AttributeClass } from '../../models/attribute-class';
import { DistributionList } from '../../models/distribution-list';
import { AuthService } from '../../services/auth.service';
import { PeriodService } from '../../services/period.service';
import { ReportService } from '../../services/report.service';
import { SellerService } from '../../services/seller.service';
import { DistributionListService } from '../../services/distribution-list.service';
import { AccountAttributeClassService } from '../../services/account-attributeClass.service';
import { forkJoin, Subscription } from 'rxjs';
import { RecurrenceService } from '../../services/recurrence.service';
import { Recurrence } from '../../models/recurrence';
import { DxListComponent, DxScrollViewComponent, DxTreeListComponent } from 'devextreme-angular';
import { DxDataGridComponent } from 'devextreme-angular';
import { HelperService } from '../../services/helper.service';
import { CoreDropdownComponent } from '../../components/core-dropdown/core-dropdown.component';
import { CoreDropdownProperties } from '../../models/core-dropdown-properties';
import { SeriesService } from '../../services/series.service';
import { Series } from '../../models/series';
import { CoreFeature, coreResponseCodes, EnumBucketType, settingClassIds } from '../../constants/enums';
import { PermissionService } from '../../services/permission.service';
import { SettingService } from '../../services/setting.service';
import { CoreFormFieldProperties } from '../../models/core-form-field-properties';
import { CoreInputEditorType } from '../../constants/dev-extreme-enums';
import { DistributionListDefault } from '../../models/distribution-list-default';
import { AppElementsService } from '../../services/app-element.service';

@Component({
    selector: 'app-reporting-parameters',
    templateUrl: './reporting-parameters.component.html',
    styleUrls: ['./reporting-parameters.component.scss'],
    providers: [SellerService, ReportService, AccountAttributeClassService]
})
export class ReportingParametersComponent implements OnInit, OnDestroy {
    @ViewChild('scrollView', { static: false }) scrollView: DxScrollViewComponent;
    @ViewChild('accountTree', { static: false }) tree: DxTreeListComponent;
    @ViewChild('accountGrid', { static: false }) grid: DxDataGridComponent;
    @ViewChild('distributionListDropdown', { static: false }) distributionListDropdown: CoreDropdownComponent;

    @Output() generate = new EventEmitter<ReportContext>();
    @Output() applySelection = new EventEmitter<ReportContext>();
    @Output() sellerGridExpanded = new EventEmitter<boolean>();

    @Input() isPopupVisible: boolean = false;
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();

    @Input() seriesMode: string;
    @Input() wrapperId: string = '';

    saving: boolean = false;
    tierWidth: number = 500;
    flatWidth: number = 1200;
    width: number = this.tierWidth;

    username: string;
    sellerId: number;
    sellers: any[];
    allSellers: any[];
    managers: any[];
    parents: any[];
    sellersLoading = true;
    attributeClasses: AttributeClass[];
    attributeClassHeaderFilters: any[];
    prodSeriesId: number;
    series: Series[];
    selectedSeriesIds: number[] = null;
    recurrenceList: Recurrence[];
    selectedRecurrence: number;
    periodList: Period[];
    periodsInRecurrence: Period[];
    initialSub: Subscription = new Subscription();
    showRecurrence = false;
    selectedSellerIDs: number[];
    periodsBeginSorted: Period[];
    periodsEndSorted: Period[];
    context: ReportContext = new ReportContext();
    quickDateOptions = [{
            text: 'Current'
        }, {
            text: 'QTD'
        }, {
            text: 'YTD'
        }
    ];
    quickDateSelection: string[];
    distributionListsLoading = true;
    addLayoutPopupFormFieldProps: CoreFormFieldProperties[] = [
        new CoreFormFieldProperties().createAllWithCustomRules('Name', CoreInputEditorType.TextBox, true,
            [{ callbackFunction: this.validateDistributionListName, message: 'Cannot modify or overwrite global layouts', isReevaluated: true }], 2)
    ];
    editLayoutPopupFormFieldProps: CoreFormFieldProperties[] = [
        new CoreFormFieldProperties().createAllWithCustomRules('Name', CoreInputEditorType.TextBox, true,
            [{ callbackFunction: this.validateUpdatedDistributionListName, message: 'Layout name already exists', isReevaluated: true }], 2),
        new CoreFormFieldProperties().createAll('Global', CoreInputEditorType.CheckBox, false, 2)
    ];
    layoutPopupProps: CoreDropdownPopupProperties[] = [
        new CoreDropdownPopupProperties().createWithForm('delete', null, null, 200, null),
        new CoreDropdownPopupProperties().createWithForm('add', this.addLayoutPopupFormFieldProps, 2, 210, 320),
        new CoreDropdownPopupProperties().createWithForm('rename', this.editLayoutPopupFormFieldProps, 2, 260, 320)
            .addPopupTitleText('Edit Layout')
    ];
    distributionListId: number;
    distributionList: DistributionList;
    distributionLists: DistributionList[];
    hasGlobalDistributionList: boolean = false;
    treeState: string;
    applyDefaults: boolean = true;
    flatVisible: boolean = false;
    dropdownWidth: number;
    reportingDropdownProps: CoreDropdownProperties;
    dummyDistList: DistributionList = new DistributionList().createDummy();
    includeInactive: boolean = false;
    subscription: Subscription;
    hasAdminView: boolean = false;
    defaultTreeState: any;
    defaultGridState: any;
    isDateOnly: boolean = false;
    isAccountOnly: boolean = false;
    defaultDistributionListLoaded: boolean = false;
    isSelectAllByDefaultEnabled: boolean;
    persistDateAccountSelection: boolean = false;
    persistDateAccountSelectionKey: string = 'persistDateAccountSelection';
    inactiveSellerPlaceholder: any = {id: -1, name: 'PRIOR/INACTIVE', parentId: 0};
    pageDestroyed: boolean = false;

    constructor(private sellerService: SellerService,
        private periodService: PeriodService,
        private attributeClassService: AccountAttributeClassService,
        private authService: AuthService,
        private recurrenceService: RecurrenceService,
        private distributionListService: DistributionListService,
        private toast: ToastrService,
        private helperService: HelperService,
        private seriesService: SeriesService,
        private permissionService: PermissionService,
        private settingService: SettingService,
        private appElementsService: AppElementsService,
        private cd: ChangeDetectorRef) {

        this.selectAll = this.selectAll.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.collapseAll = this.collapseAll.bind(this);

        this.reportingDropdownProps = new CoreDropdownProperties()
            .createAll(true, 'id', 'name', true, false, true, false, false, false, 140, 'layout', null, null, 'isGlobal', true);
    }

    onShowing(): void {
        this.loadDefaultDistributionList();

        if (this.tree) {
            this.tree.selectedRowKeys = this.tree.selectedRowKeys.length === 0 ? this.selectedSellerIDs : this.tree.selectedRowKeys;
        }
    }

    ngOnInit() {
        this.username = this.authService.getUserFromToken();
        this.sellerId = this.authService.getSellerIdFromToken();

        this.applyDefaults = localStorage.getItem(`${this.username}.applyDefaultLayouts`)!== false.toString();

        this.persistDateAccountSelection = localStorage.getItem(this.persistDateAccountSelectionKey) === true.toString();

        this.subscription = this.periodService.getPeriodEvents().subscribe(() => {
            forkJoin([this.sellerService.getHasAdminView(),
                this.periodService.getPublished(),
                this.recurrenceService.GetAllRecurrences(),
                this.settingService.getBoolSetting(settingClassIds.InternalShowAllSubordinatesByDefault),
                this.settingService.getLongSetting(settingClassIds.InternalProductionSeries)
            ]).subscribe(([hasAdminView, periods, recurrences, isSelectAllByDefaultEnabled, prodSeriesId]) => {
                this.hasAdminView = hasAdminView;
                this.periodList = hasAdminView ? periods.filter(p => recurrences.some(r => r.duration && r.id === p.recurrenceId)) : periods.filter(x => x.isPublished);
                this.recurrenceList = [this.recurrenceService.defaultRecurrence, ...recurrences.filter(r => r.duration && this.periodList.some(p => p.recurrenceId === r.id))];
                this.showRecurrence = this.recurrenceList.length > 2;
                this.selectedRecurrence = this.recurrenceService.DEFAULT_ID;
                this.isSelectAllByDefaultEnabled = isSelectAllByDefaultEnabled;
                this.prodSeriesId = prodSeriesId;
                this.setInitialValues(this.sellerId);

                if (this.hasAdminView) {
                    // If an admin, allow user to set 'Global' property in addition to the name
                    const addLayoutProps = this.layoutPopupProps.find(x => x.buttonType === 'add');
                    addLayoutProps.coreFormFieldProperties.push(new CoreFormFieldProperties().createAll('Global', CoreInputEditorType.CheckBox, false, 2));
                    addLayoutProps.height = 260;

                    // If an admin, enable deleting distribution lists, including global ones
                    this.reportingDropdownProps.deleteDisabledProperty = null;

                    // If an admin, enable the edit icon button
                    this.reportingDropdownProps.renameHidden = false;
                }
                this.updateDistributionListValidationData();
            });
        });

        const distributionListChanges: Subscription = this.appElementsService.getDistributionListChanges().subscribe(uiViewId => {
            if (!this.pageDestroyed) {
                if (uiViewId) {
                    this.distributionListId = uiViewId;
                    if (this.defaultDistributionListLoaded) {
                        this.onSelectDistributionList(uiViewId);
                    }
                }
            } else {
                distributionListChanges.unsubscribe();
            }
        });

        this.seriesService.getAllSeries().subscribe(result => this.series = result.filter(x => !x.isDeleted));

        this.getDistributionLists();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.pageDestroyed = true;
    }

    onRecurrenceChange() {
        this.periodsInRecurrence = this.selectedRecurrence > 0
            ? this.periodList.filter(period => period.recurrenceId === this.selectedRecurrence)
            : this.periodList;

        this.getPeriodDateChoices();
        const sortedPeriodsDesc = this.periodService.sortPeriods(this.periodsInRecurrence);
        const getCurrentPeriod = this.periodService.getCurrent(sortedPeriodsDesc);
        this.context.beginDate = getCurrentPeriod.beginDate;
        this.context.endDate = getCurrentPeriod.endDate;
    }

    getPeriodDateChoices() {
        this.periodsBeginSorted = this.helperService.removeDuplicateValuesByMatchingCallBack<Period, Date>(Object.assign([], this.periodsInRecurrence), (period) => period.beginDate);
        this.periodsEndSorted = this.helperService.removeDuplicateValuesByMatchingCallBack<Period, Date>(Object.assign([], this.periodsInRecurrence), (period) => period.endDate);
        this.periodsBeginSorted.sort((a, b) => {
            if (a.beginDate.valueOf() < b.beginDate.valueOf()) {
                return -1;
            } else {
                return 1;
            }
        });

        this.periodsEndSorted.sort((a, b) => {
            if (a.endDate.valueOf() < b.endDate.valueOf()) {
                return -1;
            } else {
                return 1;
            }
        });
    }

    setInitialValues(sellerId: number) {
        this.sellers = [];
        this.quickDateSelection = [];

        this.selectedSellerIDs = this.selectedSellerIDs ?? [sellerId];
        this.periodsInRecurrence = this.selectedRecurrence > 0
            ? this.periodList.filter(period => period.recurrenceId === this.selectedRecurrence)
            : this.periodList;

        this.getPeriodDateChoices();

        let storedBeginDate = localStorage.getItem('beginDate');
        let storedEndDate = localStorage.getItem('endDate');
        let distListId: any = localStorage.getItem('distributionListId');
        distListId = (distListId === null || distListId === undefined ? this.dummyDistList.id : distListId);

        this.distributionListId = parseInt(distListId, 10);
        this.distributionListDropdown?.setSelectedValue(this.distributionListId);
        this.loadDefaultDistributionList();

        const sortedPeriodsDesc = this.periodService.sortPeriods(this.periodsInRecurrence);
        const getCurrentPeriod = this.periodService.getCurrent(sortedPeriodsDesc);

        if (storedBeginDate === null || storedEndDate === null) {
            storedBeginDate = getCurrentPeriod.beginDate.toString();
            storedEndDate = getCurrentPeriod.endDate.toString();
            localStorage.setItem('beginDate', storedBeginDate);
            localStorage.setItem('endDate', storedEndDate);
            this.context.beginDate = getCurrentPeriod.beginDate;
            this.context.endDate = getCurrentPeriod.endDate;
        } else {
            // Due to Period Date Typing Issue cannot explicitly set context dates to new Date(storedBeginDate)
            // The Periods Array which fills the options of the drop down are strings not dates so the values will never show up
            this.context.beginDate = this.periodsInRecurrence.find(({ beginDate }) => beginDate.toString() === storedBeginDate)?.beginDate;
            this.context.endDate = this.periodsInRecurrence.find(({ endDate }) => endDate.toString() === storedEndDate)?.endDate;
        }

        if (localStorage.getItem(`${this.username}.selectedSellers`) === null) {
            localStorage.setItem(`${this.username}.selectedSellers`, '[' + sellerId + ']');
        } else {
            this.context.selectedSellers = localStorage.getItem(`${this.username}.selectedSellers`);
            const myArray = this.context.selectedSellers.split(']');
            this.selectedSellerIDs = [];
            myArray.forEach(element => {
                this.selectedSellerIDs.push(parseInt(element.replace('[', ''), 10));
            });

            this.selectedSellerIDs.pop();

            if (this.selectedSellerIDs.filter(x => x === sellerId).length === 2) {
                const index = this.selectedSellerIDs.findIndex(x => x === sellerId);
                this.selectedSellerIDs.splice(index, 1);
            }
        }

        this.includeInactive = localStorage.getItem('distributionList_includeInactive') === true.toString();

        this.sellerService.getSubordinatesWithCurrentHistory(sellerId, true).subscribe(subs => {
            this.allSellers = subs;
            this.prepareDetailedColumns();

            subs.filter(x => x.id !== sellerId).forEach(x => {
                x.parentId = subs.some(seller => seller.id === x.histSellers[0].bossSellerId) ? x.histSellers[0].bossSellerId : x.histSellers[0].parentSellerId;
            });

            if(this.permissionService.checkCurrentUserPermission(CoreFeature.ViewAllAccounts.toString())){
                const currentSeller = subs.find(s => s.id === sellerId);
                const currentBossId = subs.find(seller => seller.id === currentSeller.histSellers[0].bossSellerId)?.id ?? currentSeller.histSellers[0].parentSellerId;
                if(subs.some(boss => boss.id === currentBossId)) {
                    subs.find(s => s.id === sellerId).parentId = currentBossId;
                }
            }

            this.setAttributeValues(subs);

            this.onIncludeInactiveToggle();
            this.sellersLoading = false;

            if (this.isSelectAllByDefaultEnabled && !sessionStorage.sellerSelectionOverridden) {
                this.overrideSavedSellerSelection();
            }

            this.attributeClassService.getAttributes().subscribe(attributeClasses => {
                this.attributeClasses = attributeClasses.filter(c => !c.isExcluded);

                this.attributeClassHeaderFilters = [];
                this.attributeClasses.filter(x => !x.isText).forEach(attributeClass => {
                    this.attributeClassHeaderFilters[attributeClass.id] = {
                        dataSource: attributeClass.attributes
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(a => ({ value: a.name, text: a.name}))
                    };
                });
            });
        });

        const sessionSeries = sessionStorage.getItem('selectedSeries');
        if (sessionSeries) {
            this.selectedSeriesIds = this.helperService.parseBracketedIdString(sessionSeries);
        } else {
            this.selectedSeriesIds = [this.prodSeriesId];
            sessionStorage.setItem('selectedSeries', '[' + this.prodSeriesId + ']');
        }
    }

    setAttributeValues(sellers: Seller[]) {
        sellers.forEach(seller => {
            if (seller.histSellers.length > 0) {
                seller.histSellers[0].attributes.forEach(attribute => {
                    const obj: { [k: string]: any } = {};
                    obj[attribute.attributeClass.name] = attribute.name;
                    Object.assign(seller, obj);
                });
            }
        });
    }

    buttonPressedEvent(context: ReportContext) {
        if (this.context.beginDate > this.context.endDate) {
            notify({
                message: 'The Start Date must be before the End Date.',
                type: 'error',
                displayTime: 3000,
                height: 75
            });
            return;
        }

        localStorage.setItem('recurrenceId', this.selectedRecurrence.toString());
        localStorage.setItem('beginDate', this.context.beginDate.toString());
        localStorage.setItem('endDate', this.context.endDate.toString());

        const sellerIDString = this.selectedSellerIDs.map(x => '[' + x + ']').join('');

        localStorage.setItem(`${this.username}.selectedSellers`, sellerIDString);
        localStorage.setItem('distributionListId', this.distributionListId.toString());

        if (this.selectedSeriesIds) {
            sessionStorage.setItem('selectedSeries', this.helperService.createBracketedIdString(this.selectedSeriesIds));
        } else {
            sessionStorage.removeItem('selectedSeries');
        }

        this.saving = true;
        this.closePopup();
        setTimeout(() => this.generate.emit(context));
    }

    closePopup(): void {
        this.isPopupVisible = false;
    }

    applyDefaultsValueChanged(event): void {
        localStorage.setItem(`${this.username}.applyDefaultLayouts`, event.value.toString());
    }

    switchValueChanged(event) {
        this.sellerGridExpanded.emit(event.value);
        this.setFlatVisible(event.value);
    }

    quickDateOnSelectionChanged(event) {
        if (event.addedItems.length === 0) {
            return;
        }

        const currentPeriod = this.periodService.getCurrent(this.periodsEndSorted);

        switch (event.addedItems[0].text) {
            case 'Current':
                this.context.beginDate = currentPeriod.beginDate;
                this.context.endDate = currentPeriod.endDate;
                break;
            case 'QTD':
                this.context.beginDate = this.periodService.getStartOfQuarter(new Date(currentPeriod.endDate), this.periodsBeginSorted);
                this.context.endDate = currentPeriod.endDate;
                break;
            case 'YTD':
                this.context.beginDate = this.periodService.getStartOfYear(new Date(currentPeriod.endDate), this.periodsBeginSorted);
                this.context.endDate = currentPeriod.endDate;
                break;
        }
    }

    dateOnValueChanged(event) {
        if (event.event != null) {
            this.quickDateSelection = [];
        }

        if (event.value === this.context.beginDate) {
            if (event.value > this.context.endDate) {
                this.context.endDate = this.periodsEndSorted.find(p => p.endDate > event.value).endDate;
            }
        } else if (event.value < this.context.beginDate) {
            this.context.beginDate = this.periodsBeginSorted.slice().reverse().find(p => p.beginDate < event.value).beginDate;
        }
    }

    sellerOnDoubleClickEvent(event) {
        const sellerArray = this.selectedSellerIDs;
        this.recursiveSelectSellers(!event.isSelected, event.data, sellerArray);
        this.tree.instance.selectRows(sellerArray , false);
    }

    recursiveSelectSellers(changeTo: boolean, currentSeller: Seller, sellerArray) {
        const index: number = sellerArray.indexOf(currentSeller.id);
        if (changeTo) {
            if (index === -1) {
                sellerArray.push(currentSeller.id);
            }
        } else {
            if (index !== -1) {
                sellerArray.splice(index, 1);
            }
        }

        for (const subordinate of this.sellers.filter(a => a.parentId === currentSeller.id)) {
            this.recursiveSelectSellers(changeTo, subordinate, sellerArray);
        }
    }

    selectAll() {
        if (this.selectedSellerIDs.length < this.sellers.length) {
            this.selectedSellerIDs = this.sellers.map(s => s.id);
        } else {
            this.selectedSellerIDs = [];
        }
    }

    onEditorPrepared(e) {
        if (e.parentType === 'headerRow') {
            e.cancel = true;
            e.editorElement.style.display = 'none';
          }
    }

    expandAll() {
        this.tree.instance.option('expandedRowKeys', []);
        this.tree.instance.option('autoExpandAll', true);
    }

    collapseAll() {
        this.tree.instance.option('expandedRowKeys', []);
        this.tree.instance.option('autoExpandAll', false);
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.push(
            {
                location: 'Before',
                widget: 'dxButton',
                options: {
                    icon: 'user',
                    text: 'ALL',
                    onClick: this.selectAll
                }
            },
            {
                location: 'Before',
                widget: 'dxButton',
                options: {
                    icon: 'expand',
                    text: 'Expand',
                    onClick: this.expandAll
                }
            },
            {
                location: 'Before',
                widget: 'dxButton',
                options: {
                    icon: 'collapse',
                    text: 'Collapse',
                    onClick: this.collapseAll
                }
            }
        );
    }

    loadDefaultDistributionList(): void {
        if (!this.defaultDistributionListLoaded && this.distributionLists && this.distributionLists.length > 0 && this.distributionListId
            && this.distributionLists.some(x => x.id === this.distributionListId) && this.tree) {
            this.onSelectDistributionList(this.distributionListId);
            this.defaultDistributionListLoaded = true;
        }
    }

    getDistributionLists(id?: number): void {
        this.distributionListService.getDistributionLists(this.sellerId).subscribe(distributionLists => {
            this.distributionLists = distributionLists;
            this.hasGlobalDistributionList = this.distributionLists.some(x => x.isGlobal);
            this.distributionLists.splice(0, 0, this.dummyDistList);
            if (id !== undefined && id !== 0) {
                this.distributionListId = id;
                this.distributionList = distributionLists.find(x => x.id === id);
            } else {
                this.loadDefaultDistributionList();
            }
            this.setDistributionListEditPopupDefaults();
            this.updateDistributionListValidationData();
            this.distributionListsLoading = false;
        });
    }

    insertDistributionList(newDistributionList: DistributionList) {
        this.distributionListService.insertDistributionList(newDistributionList).subscribe(distributionList => {
            this.toast.success(`Layout '${distributionList.name}' has been added successfully`);
            this.getDistributionLists(distributionList.id);
        }, error => {
            this.toast.error(`An error occurred while attempting to add layout '${newDistributionList.name}'`);
            this.distributionListsLoading = false;
        });
        this.distributionListDropdown.resetAddForm();
    }

    updateDistributionList(distributionList: DistributionList) {
        this.distributionListService.updateDistributionList(distributionList).subscribe(result => {
            this.toast.success(`Layout '${distributionList.name}' has been updated successfully`);
            this.getDistributionLists(distributionList.id);
        }, error => {
            this.toast.error(`An error occurred while attempting to update layout '${distributionList.name}'`);
            this.distributionListsLoading = false;
        });
    }

    deleteDistributionList(distributionList: DistributionList) {
        this.distributionListService.deleteDistributionList(distributionList.id).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                this.toast.success(`Layout '${distributionList.name}' has been deleted successfully`);
                this.getDistributionLists();
            } else {
                this.toast.error(`An error occurred while attempting to delete layout '${distributionList.name}'`);
                this.distributionListsLoading = false;
            }
        }, error => {
            this.toast.error(`An error occurred while attempting to delete layout '${distributionList.name}'`);
            this.distributionListsLoading = false;
        });
    }

    onSelectDistributionList(e): void {
        this.distributionListId = e;
        this.distributionList = this.distributionLists.filter(x => x.id === this.distributionListId)[0];
        if (this.distributionList.expanded) {
            setTimeout(() => this.grid?.instance.state(JSON.parse(this.distributionList.layoutString)));
            this.tree?.instance.state(this.defaultTreeState);
        } else {
            this.tree?.instance.state(JSON.parse(this.distributionList.layoutString));
        }

        if (this.dummyDistList.id === this.distributionList.id) {
            this.selectedSellerIDs = [this.sellerId];
        } else {
            this.selectedSellerIDs = JSON.parse(this.distributionList.layoutString).selectedRowKeys;
        }
        this.flatVisible = this.distributionList.expanded;
        this.includeInactive = this.distributionList.includeInactive;

        this.distributionListService.getDistributionListDefaults(this.distributionListId).subscribe(defaults => {
            const deletePopupProps = this.layoutPopupProps.find(x => x.buttonType === 'delete');
            let bodyMessage = '';
            if (defaults && defaults.length > 0) {
                bodyMessage = '<br/>This layout is currently set as the default layout for the following items:';
                bodyMessage += this.constructDefaultsListHtml(defaults);
            }
            deletePopupProps.height = defaults && defaults.length > 0 ? 220 + defaults.length * 18 : 200;
            deletePopupProps.bodyMessage = bodyMessage;
        });

        this.setDistributionListEditPopupDefaults();
        this.updateDistributionListValidationData();

        this.cd.detectChanges();
    }

    onAddDistributionList(e) {
        const name = e.component.Name;
        const isGlobal = this.hasAdminView ? e.component.Global === true : false;
        const existingLayout = this.distributionLists.find(x => x.name === name);
        if (!existingLayout) {
            this.distributionListsLoading = true;
            const layoutString = this.flatVisible ? JSON.stringify(this.grid.instance.state()) : JSON.stringify(this.tree.instance.state());
            const distributionList: DistributionList = new DistributionList(0, name, this.sellerId, layoutString, this.flatVisible, this.includeInactive, isGlobal);
            this.insertDistributionList(distributionList);
        } else {
            let message = `Are you sure you want to replace the layout '${name}'?`;
            if (existingLayout.isGlobal && !isGlobal) {
                message += ' <br/><br/><b>Warning</b>: This layout is currently a global layout, and you are trying to overwrite it as a non-global layout.';
                this.distributionListService.getDistributionListDefaults(existingLayout.id).subscribe(defaults => {
                    if (defaults && defaults.length > 0) {
                        message += '<br/>Doing so will remove it as the default layout from the following items:';
                        message += this.constructDefaultsListHtml(defaults);
                    }
                    this.confirmReplaceLayout(message, existingLayout.id, isGlobal);
                });
            } else {
                if (!existingLayout.isGlobal && isGlobal) {
                    message += ' <br/><br/><b>Warning</b>: This layout is currently a non-global layout, and you are trying to overwrite it as a global layout.';
                }
                this.confirmReplaceLayout(message, existingLayout.id, isGlobal);
            }
        }
    }

    onEditDistributionList(e): void {
        const distributionList = this.distributionLists.find(x => x.id === this.distributionListId);
        distributionList.name = e.component.Name;
        distributionList.userId = this.sellerId;
        distributionList.isGlobal = this.hasAdminView ? e.component.Global === true : false;
        distributionList.layoutString = this.flatVisible ? JSON.stringify(this.grid.instance.state()) : JSON.stringify(this.tree.instance.state());
        distributionList.expanded = this.flatVisible;
        distributionList.includeInactive = this.includeInactive;
        this.updateDistributionList(distributionList);
    }

    onSaveDistributionList(e, isGlobal: boolean = null) {
        this.distributionListsLoading = true;
        const distributionList = this.distributionLists.filter(x => x.id === e)[0];
        distributionList.userId = this.sellerId;
        distributionList.layoutString = this.flatVisible ? JSON.stringify(this.grid.instance.state()) : JSON.stringify(this.tree.instance.state());
        distributionList.expanded = this.flatVisible;
        distributionList.includeInactive = this.includeInactive;
        if (isGlobal !== null) {
            distributionList.isGlobal = isGlobal;
        }
        this.updateDistributionList(distributionList);
    }

    onDeleteDistributionList(e) {
        if (this.distributionList.id !== 0) {
            this.distributionListsLoading = true;
            this.deleteDistributionList(this.distributionList);
        } else {
            this.toast.error('Cannot delete the "<None>" layout.');
        }
    }

    validateDistributionListName(e): boolean {
        // Fetch the validation data we assigned to the CoreDropdown's props
        const validationData = this['props']['validationData'];
        return validationData.hasAdminView || !validationData.distributionLists?.some(x => x.name === e.value && x.isGlobal === true);
    }

    validateUpdatedDistributionListName(e: any): boolean {
        // Fetch the validation data we assigned to the CoreDropdown's props
        const validationData = this['props']['validationData'];
        return !validationData.distributionLists?.some(x => x.name === e.value && x.name !== validationData.distributionList.name);
    }

    confirmReplaceLayout(message, id, isGlobal): void {
        confirm(message, 'Replace layout?').then(dialogResult => {
            if (dialogResult) {
                this.onSaveDistributionList(id, isGlobal);
            }
        });
    }

    constructDefaultsListHtml(defaults: DistributionListDefault[]): string {
        let message = '<ul>';
        defaults.forEach(d => {
            message += `<li>${d.itemName.replace('<', '&lt;').replace('>', '&gt;')} <span class="italic-bucket-type">(${EnumBucketType[d.type]})</span></li>`;
        });
        message += '</ul>';

        return message;
    }

    setFlatVisible(value: boolean): void {
        this.flatVisible = value;
        this.reportingDropdownProps.width = (this.flatVisible ? 220 : 140);

        if (this.flatVisible) {
            this.tierWidth = this.width;
            this.width = this.flatWidth;
        } else {
            this.flatWidth = this.width;
            this.width = this.tierWidth;
        }

        this.onIncludeInactiveToggle();
    }

    onHidden(e) {
        this.isDateOnly = false;
        this.isAccountOnly = false;
        this.isPopupVisibleChange.emit(this.isPopupVisible);
    }

    onIncludeInactiveToggle() {
        this.sellers = this.allSellers.filter(x => x.isActive);

        if (this.includeInactive) {
            const inactiveSellers = this.allSellers.filter(x => !x.isActive);
            if (inactiveSellers.length) {
                if (!this.flatVisible) {
                    this.sellers.push(this.inactiveSellerPlaceholder);
                }
                inactiveSellers.forEach(x => {
                    x.parentId = this.inactiveSellerPlaceholder.id;
                    this.sellers.push(x);
                });
            }
        }

        localStorage.setItem('distributionList_includeInactive', this.includeInactive.toString());
    }

    addImportNameToSellerList(sellers: any) {
        sellers.forEach(seller => {
            seller['primaryImportName'] = seller.sellerImports[0]?.name;
        });
    }

    prepareDetailedColumns(): void {
        const histSellers = this.allSellers.map(s => s.histSellers[0]);
        this.managers = this.allSellers.filter(s => histSellers.some(hs => hs.bossSellerId === s.id));
        this.parents = this.allSellers.filter(s => histSellers.some(hs => hs.parentSellerId === s.id));
        this.addImportNameToSellerList(this.allSellers);
    }

    onTreeContentReady(e: any): void {
        if (!this.defaultTreeState) {
            this.defaultTreeState = e.component.state();
        }
    }

    onGridContentReady(e: any): void {
        if (!this.defaultGridState) {
            this.defaultGridState = e.component.state();
        }
    }

    onGridInitialized(e: any) {
        this.handleWheelScrolling();
    }

    openAsDateSelector() {
        this.isAccountOnly = false;
        this.isDateOnly = true;
        this.isPopupVisible = true;
        this.isPopupVisibleChange.emit(this.isPopupVisible);
    }

    openAsAccountSelector() {
        this.isDateOnly = false;
        this.isAccountOnly = true;
        this.isPopupVisible = true;
        this.isPopupVisibleChange.emit(this.isPopupVisible);
    }

    calculateSortValue(data: any): any {
        const column = this as any;
        const value = column.calculateCellValue(data);
        return column.lookup.calculateCellValue(value);
    }

    handleWheelScrolling(): void {
        // Workaround for weird scrolling behavior between the grid and the popup content
        //   If user is scrolling down (using the wheel) inside the grid after it has reached the bottom of the grid content,
        //   we scroll down towards the bottom of the popup content so that they can see the entire grid
        const gridScrollContainerSelector = '.account-section .dx-datagrid .dx-datagrid-rowsview .dx-scrollable-container';
        const gridScrollContentSelector = '.account-section .dx-datagrid .dx-datagrid-rowsview .dx-scrollable-content';
        setTimeout(() => {
            const doc = document as any;
            doc.querySelector(gridScrollContainerSelector)?.addEventListener('wheel', (e: any) => {
                if (e.wheelDeltaY < 0) {
                    const gridScrollContainerElement = doc.querySelector(gridScrollContainerSelector);
                    const gridScrollContentElement = doc.querySelector(gridScrollContentSelector);
                    if (Math.ceil(gridScrollContainerElement.scrollTop + gridScrollContainerElement.clientHeight) >= (gridScrollContentElement.clientHeight)) {
                        this.scrollView.instance.scrollBy({top: 50});
                    }
                }
            });
        }, 100);
    }

    overrideSavedSellerSelection(): void {
        if (!this.distributionListId) {
            this.selectedSellerIDs = this.sellers.map(x => x.id);
            localStorage.setItem(`${this.username}.selectedSellers`, this.selectedSellerIDs.map(x => '[' + x + ']').join(''));
        }
        sessionStorage.sellerSelectionOverridden = true;
    }

    onTogglePersistDateAccountSelection(): void {
        localStorage.setItem(this.persistDateAccountSelectionKey, this.persistDateAccountSelection.toString());
    }

    activeThenNameSort(rowData) {
        const column = this as any;
        if (rowData.id === -1) {
            return column.sortOrder === 'asc' ? 'zzz' : 'aaa';
        } else {
            return rowData.name;
        }
    }

    setDistributionListEditPopupDefaults(): void {
        const editDistributionListFormDefaults = {
            Name: '',
            Global: false
        };

        if (this.distributionList) {
            editDistributionListFormDefaults.Name = this.distributionList.name;
            editDistributionListFormDefaults.Global = this.distributionList.isGlobal;
        }

        this.layoutPopupProps.filter(x => x.buttonType === 'rename')[0].defaultFormValues = editDistributionListFormDefaults;
        if (this.distributionListDropdown) {
            this.distributionListDropdown.setRenameFormDefaults(editDistributionListFormDefaults);
        }
    }

    updateDistributionListValidationData(): void {
        this.reportingDropdownProps.validationData = { hasAdminView: this.hasAdminView, distributionLists: this.distributionLists, distributionList: this.distributionList };
    }
}

<dx-popup
    [height]="540"
    [width]="1000"
    [showTitle]="true"
    title="Account Hierarchy"
    [dragEnabled]="true"
    container=".dx-viewport"
    [resizeEnabled]="true"
    (onResize)="onResize($event)"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="popupVisible"
    [position]="{
        my: 'top center',
        at: 'top center', 
        of: 'window',
        offset: '0 60'
    }"
    [wrapperAttr]="{ class: 'hierarchy-popup' }">
    <dx-responsive-box>
        <dxi-row [ratio]="10"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>

        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>

        <dxi-item>
            <dxi-location [row]="0" [col]="0" [colspan]="2"></dxi-location>
            <core-tree-list #coreTreeList [dataSource]="displayHierarchyData" [columns]="treeListColumns" [props]="treeListProperties"></core-tree-list>
        </dxi-item>

        <dxi-item>
            <dxi-location [row]="1" [col]="0"></dxi-location>

            <dx-check-box
                [(value)]="viewActivePayeesOnly"
                text="View active payees only"
                (onValueChanged)="onViewActivePayeesOnlyValueChanged($event)"
                class="view-active-payees-checkbox">
            </dx-check-box>
        </dxi-item>

        <dxi-item class="account-hierarchy-buttons">
            <dxi-location [row]="1" [col]="1"></dxi-location>

            <dx-button text="Copy to Clipboard" icon="copy" (onClick)="copyToClipboard($event)"></dx-button>
            <dx-button text="Collapse All" icon="minus" (onClick)="collapseAll()"></dx-button>
            <dx-button text="Expand All" icon="plus" (onClick)="expandAll()"></dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>

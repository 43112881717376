<dx-popup class="core-bot-popup"
    [wrapperAttr]="{class:'core-bot-popup'}"
    [width]="525"
    [height]="515"
    [showTitle]="true"
    title="AI Analytic Generator"
    [shading]="true"
    [dragEnabled]="true"
    container=".dx-viewport"
    [showCloseButton]="true"
    [(visible)]="showPopup"
    (onHiding)="onHiding($event)"
    (onContentReady)="onContentReady($event)"
    (onShown)="scrollMessagesToBottom($event)">
        <dx-scroll-view width="100%" height="100%" useNative="true">
            <div class="analyticbot-disclaimer">
                <div id="analyticbot-disclaimer-title" class="disclaimer-message">
                    Click to View AI Technology Disclaimer
                </div>
                <dx-tooltip
                    target="#analyticbot-disclaimer-title"
                    showEvent="click"
                    [hideOnOutsideClick]="true"
                    width="300px"
                >
                    <div *dxTemplate="let data = data; of: 'content'"> AnalyticBot is an AI solution in testing. While CoreBot is very
                        powerful, it does not always produce accurate results. Core Commissions does not make any guarantee of
                        the accuracy of AnalyticBot's results. If you have any doubt about AnalyticBot's output, please reach out to the 
                        Core Commissions implementation team.
                    </div>
                </dx-tooltip>
            </div>
            <div class="nb-theme-default">
                <corebot-popup-content #chatContent
                    class="analyticbot-theme"
                    [messages]="messages" 
                    [sellerName]="sellerName"
                    size="small">
                </corebot-popup-content>
                <div class="analyticbot-chat-form" #analyticBotChat id="analyticBotChat">
                    <div class="analyticbot-message-row">
                        <input #analyticBotInput nbInput fullWidth
                        id="analyticBotInput"
                        class="analyticbot-input"
                        [(ngModel)]="message" 
                        (ngModelChange)="onModelChange($event)"
                        (keyup.enter)="submitText($event)"
                        resize="none"
                        autocomplete="off"
                        [placeholder]="samplePrompt"/>
                        <button
                            nbButton
                            #sendButton
                            (click)="submitText($event)"
                            class="send-button"
                            status="primary"
                        >
                            <nb-icon [icon]="buttonIcon" pack="nebular-essentials"></nb-icon>
                        </button> 
                    </div>
                </div>
            </div>
        </dx-scroll-view>
</dx-popup>

import * as Dashboard from 'devexpress-dashboard';
import * as Model from 'devexpress-dashboard/model';
import * as Designer from 'devexpress-dashboard/designer';

// Model
const dashStyleProperty: Model.CustomPropertyMetadata = {
    ownerType: Model.SimpleSeries,
    propertyName: 'DashStyleProperty',
    defaultValue: 'solid',
    valueType: 'string'
};

Model.registerCustomProperty(dashStyleProperty);

// Viewer
const onItemWidgetOptionsPrepared = (args) => {
    if (args.dashboardItem instanceof Model.ChartItem) {
        const seriesOptionArray = args.options['series'] || [];
        seriesOptionArray.forEach((seriesOption) => {
            if (seriesOption.type === 'line') {
                const series = args.chartContext.getDashboardItemSeries(seriesOption);
                if (series) {
                    const dashStyle = series.customProperties.getValue(dashStyleProperty.propertyName);
                    seriesOption.dashStyle = dashStyle;
                }
            }
        });
    }
};

// Designer
const onCustomizeSections = (args) => {
    const simpleSeries = args.dataItemContainer;
    if (simpleSeries instanceof Model.SimpleSeries
        && ['Line'].indexOf(simpleSeries.seriesType()) !== -1
    ) {
        args.addSection({
            title: 'Line Options (Custom)',
            items: [
                {
                    dataField: dashStyleProperty.propertyName,
                    editorType: 'dxSelectBox',
                    label: {
                        text: 'Dash style'
                    },
                    editorOptions: {
                        items: [
                            { value: 'dash', displayValue: 'Dashes' },
                            { value: 'dot', displayValue: 'Dots' },
                            { value: 'longDash', displayValue: 'Long Dashes' },
                            { value: 'solid', displayValue: 'Solid Line' },
                            { value: 'dashdot', displayValue: 'Dash-Dots' }
                        ],
                        displayExpr: 'displayValue',
                        valueExpr: 'value'
                    }
                }
            ]
        });
    }
};

// Event Subscription
export class ChartLineOptionsExtension {
    name = 'ChartLineOptions';

    constructor(private dashboardControl: Dashboard.DashboardControl) {
    }

    start() {
        const viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as Dashboard.ViewerApiExtension;
        if (viewerApiExtension) {
            viewerApiExtension.on('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
        }
        const bindingPanelExtension = this.dashboardControl.findExtension('item-binding-panel') as Designer.BindingPanelExtension;
        if (bindingPanelExtension) {
            bindingPanelExtension.on('customizeDataItemContainerSections', onCustomizeSections);
        }
    }
    stop() {
        const viewerApiExtension = this.dashboardControl.findExtension('viewer-api') as Dashboard.ViewerApiExtension;
        if (viewerApiExtension) {
            viewerApiExtension.off('itemWidgetOptionsPrepared', onItemWidgetOptionsPrepared);
        }
        const bindingPanelExtension = this.dashboardControl.findExtension('item-binding-panel') as Designer.BindingPanelExtension;
        if (bindingPanelExtension) {
            bindingPanelExtension.off('customizeDataItemContainerSections', onCustomizeSections);
        }
    }
}

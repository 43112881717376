import { CommonModule } from '@angular/common';
import { Component, NgModule, Output, EventEmitter, AfterViewInit, OnDestroy, Input, HostListener} from '@angular/core';
import { CoreComponentModule } from '../../../core-component.module';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { CoreBotInteraction } from 'src/app/shared/models/core-bot-interaction';
import { CoreBotInteractionService } from 'src/app/shared/services/core-bot-interaction-service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CoreBotInteractionLog } from 'src/app/shared/models/core-bot-interaction-log';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
    selector: 'app-corebot-widget',
    templateUrl: './corebot-widget.component.html',
    styleUrls: ['./corebot-widget.component.scss'],
    animations: [
        trigger('bubbleZoom', [
            state('in', style({height: '230px', width: '230px', right: '95px', fontSize: '12px'})),
            state('out', style({height: '0px', width: '0px', right: '98px', fontSize: '0px'})),
            transition('out => in', [
                animate('500ms ease-out', keyframes([
                    style({height: '230px', width: '230px', right: '95px', fontSize: '12px'})
                ]))
            ]),
            transition('in => out', [
                animate('500ms ease-in', keyframes([
                    style({height: '0px', width: '0px', right: '98px', fontSize: '0px'})
                ]))
            ])
        ]),
        trigger('textFade', [
            state('out', style({color: '#FFFFFF'})),
            state('in', style({color: '#000000'})),
            transition('in => out', [
                animate('500ms ease-in', keyframes([
                    style({color: '#FFFFFF'})
                ]))
            ])
        ]),
    ]
})

export class CoreBotWidgetComponent implements AfterViewInit, OnDestroy {
    @Output() widgetClicked: EventEmitter<string> = new EventEmitter<string>();
    @Input() userClicked: boolean = false;

    bubbleAnimationState: string = 'out';
    textState: string = 'in';
    firstSubscription: boolean = true;
    interaction: CoreBotInteraction = null;
    displayedMessage: string = '';
    showInteraction: boolean = false;
    isLoggedOut: boolean = false;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private coreBotInteractionService: CoreBotInteractionService,
        private authService: AuthService
    ){}

    ngAfterViewInit(){
        this.coreBotInteractionService.getCurrentCoreBotInteraction().pipe(takeUntil(this.unsubscribe$)).subscribe(interaction => {
            if (!this.firstSubscription){
                this.showInteraction = true;
                this.fadeOutText().then(done => {
                    this.interaction = interaction;
                    this.playBotInteraction().then(result => {
                        this.showInteraction = false;
                        const log: CoreBotInteractionLog = {
                            interactionId: this.interaction.id,
                            interactionDate: new Date(),
                            userEngaged: this.userClicked
                        };

                        this.authService.getLoginState().subscribe(loggedIn => {
                            if (loggedIn === true){
                                this.coreBotInteractionService.insertCoreBotInteractionLog(log).subscribe(res => {
                                    this.resetAnimationsState();
                                });
                            }
                            else {
                                this.resetAnimationsState();
                            }
                        });
                    });
                });
            }
            else {
                this.firstSubscription = false;
            }
        });
    }

    ngOnDestroy(){
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onWidgetClicked(e: any){
        this.widgetClicked.emit(this.interaction.botPrompt);
        this.userClicked = true;
        this.onCloseButtonClicked(e);
    }

    onCloseButtonClicked(e: any){
        this.closeAnimation().then(res => {
            this.coreBotInteractionService.animationComplete();
        });
    }

    playBotInteraction(): Promise<boolean>{
        return new Promise<boolean>((res) => {
            this.textState = 'in';
            this.displayInteraction().then(x => {
                setTimeout(() => {
                    this.hideInteraction().then(result => {
                        res(true);
                    });
                }, 5000);
            });
        });
    }

    displayInteraction(): Promise<boolean> {
        return new Promise((res) => {
            setTimeout(() => {
                this.bubbleAnimationState = 'in';
                setTimeout(() => {
                    this.typeText(this.interaction.message).then(x => {
                        setTimeout(() => {
                            this.textState = 'out';
                            setTimeout(() => {
                                this.displayedMessage = '';
                                this.textState = 'in';
                                this.typeText('Click me to chat with CoreBot').then(y => {
                                    res(true);
                                });
                            }, 1500);
                        }, 10000);
                    });
                }, 1000);
            }, 3000);
        });
    }

    hideInteraction(): Promise<boolean>{
        return new Promise<boolean>((res) => {
            this.textState = 'out';
            setTimeout(() => {
                this.displayedMessage = '';
                this.bubbleAnimationState = 'out';
                setTimeout(() => {
                    res(true);
                }, 1500);
            }, 1000);
        });
    }

    typeText(message: string): Promise<boolean> {
        return new Promise<boolean>(async (res) => {
            const characters = message.split('');
            let currentIndex = 0;

            setInterval(() => {
                if (currentIndex < characters.length) {
                    this.displayedMessage += characters[currentIndex];
                    currentIndex++;
                }
                else {
                    res(true);
                }
            }, 30);
        });
    }

    fadeOutText(): Promise<boolean> {
        return new Promise<boolean>(async (res) => {
            this.textState = 'out';
            setTimeout(() => {
                res(true);
            }, 550);
        });
    }

    closeAnimation(): Promise<boolean> {
        return new Promise<boolean>((res) => {
            this.fadeOutText().then(done => {
                this.displayedMessage = '';
                this.bubbleAnimationState = 'out';
                setTimeout(() => {
                    this.coreBotInteractionService.startAnimationCooldown();
                    res(true);
                }, 750);
            });
        });
    }

    resetAnimationsState(){
        this.userClicked = false;
        this.coreBotInteractionService.animationComplete();
    }
}

@NgModule({
    imports: [
        CommonModule,
        CoreComponentModule
    ],
    declarations: [CoreBotWidgetComponent],
    exports: [CoreBotWidgetComponent]
})
export class CoreBotWidgetModule{ }

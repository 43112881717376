import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { ImportField } from '../models/import-field';

@Injectable()
export class ImportFieldService {
    private baseUrl = environment.apiEndpoint + '/importField/';

    constructor(private http: HttpClient) {
    }

    insertImportField(importField: ImportField): Observable<ImportField> {
        return this.http.post<ImportField>(this.baseUrl, importField);
    }

    updateImportField(importField: ImportField): Observable<number> {
        return this.http.put<number>(this.baseUrl, importField);
    }

    deleteImportField(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + id);
    }
}

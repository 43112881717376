<ng-container
    *ngIf="
        sourceFieldDropDownDatasource &&
        sourceFieldDropDownDatasource.dataSource.length > 0 &&
        auxFieldDropDownDatasource &&
        auxFieldDropDownDatasource.dataSource.length > 0
    ">
    <dx-data-grid
        [(dataSource)]="internalDatasource"
        (onCellClick)="checkForChanges()"
        (onContentReady)="checkForChanges()"
        (onRowInserted)="emitValue()"
        (onRowUpdated)="emitValue()"
        (onRowRemoved)="emitValue()"
        (onRowValidating)="validateAlias($event)"
        (onEditCanceled)="revertChanges()"
        (onToolbarPreparing)="filterToolbarItems($event)">
        <dxi-column dataField="sourceName" caption="Copy To" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="sourceFieldDropDownDatasource['dataSource']"
                [displayExpr]="sourceFieldDropDownDatasource['displayExpr']"
                [valueExpr]="sourceFieldDropDownDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="auxName" caption="Copy From" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="auxFieldDropDownDatasource['dataSource']"
                [displayExpr]="auxFieldDropDownDatasource['displayExpr']"
                [valueExpr]="auxFieldDropDownDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column *ngIf="showAlias" dataField="aliasName">
            <dxi-validation-rule type="custom" [validationCallback]="aliasHasGearRuleNameCollision"> </dxi-validation-rule>
            <dxi-validation-rule type="custom" [validationCallback]="aliasHasIntraRuleGearNameCollision"> </dxi-validation-rule>
            <dxi-validation-rule type="custom" [validationCallback]="aliasHasReservedNameCollision"> </dxi-validation-rule>
            <dxi-validation-rule type="async" [validationCallback]="aliasHasFieldNameCollision"> </dxi-validation-rule>
            <dx-text-box stylingMode="underlined"></dx-text-box>
        </dxi-column>
        <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
    </dx-data-grid>
</ng-container>

<dx-popup #popup [showCloseButton]="true" visible="true" (onHidden)="onHidden($event)">
    <div class="core-popup-content-wrapper">
        <dx-scroll-view width="100%" height="100%">
            <div [innerHtml]="props.message"></div>
        </dx-scroll-view>
    </div>
    <div class="core-popup-button-wrapper">
        <dx-button #okButton [text]="okClickText" (onClick)="okClick()" [visible]="props.showOkButton" class="core-popup-button"></dx-button>
        <dx-button #cancelButton [text]="cancelClickText" [visible]="props.showCancelButton" (onClick)="cancelClick()" class="core-popup-button"></dx-button>
    </div>
</dx-popup>

<ng-container
    *ngIf="
        xactionNameDatasource && 
        xactionNameDatasource.dataSource.length > 0 && 
        headProcessDatasource && 
        headProcessDatasource.dataSource.length > 0
    ">
    <dx-data-grid
        [(dataSource)]="internalDatasource"
        [allowColumnResizing]="true"
        [columnMinWidth]="40"
        (onContentReady)="checkForChanges()"
        (onSaved)="onSaved($event)"
        (onRowInserted)="emitValue()"
        (onRowUpdated)="emitValue()"
        (onRowRemoved)="emitValue()"
        (onToolbarPreparing)="filterToolbarItems($event)">
        <dxi-column dataField="xactionName" caption="Copy To Xaction">
            <dxo-lookup
                [dataSource]="xactionNameDatasource['dataSource']"
                [displayExpr]="xactionNameDatasource['displayExpr']"
                [valueExpr]="xactionNameDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="columnName" caption="Copy From Rule Output">
            <dxo-lookup
                [dataSource]="headProcessDatasource['dataSource']"
                [displayExpr]="headProcessDatasource['displayExpr']"
                [valueExpr]="headProcessDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column type="buttons" [width]="40"></dxi-column>
        <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
    </dx-data-grid>
</ng-container>

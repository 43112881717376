import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PineconeRequest } from '../models/pinecone-request';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})
export class PineconeService {
    private pineconeUrl = environment.apiEndpoint + '/pinecone';

    constructor(private http: HttpClient) { }

    uploadPineconeEmbedding(request: PineconeRequest): Observable<CoreResponse<number>>{
        return this.http.post<CoreResponse<number>>(this.pineconeUrl, request);
    }

    updatePineconeEmbedding(request: PineconeRequest): Observable<CoreResponse<number>>{
        return this.http.post<CoreResponse<number>>(this.pineconeUrl + '/update', request);
    }

    deletePineconeEmbedding(request: PineconeRequest): Observable<CoreResponse<number>>{
        return this.http.post<CoreResponse<number>>(this.pineconeUrl + '/delete', request);
    }
}

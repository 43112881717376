export class CorePopupProperties {
    width: string;
    height: string;
    showTitle: boolean;
    title: string;
    dragEnabled: boolean;
    resizeEnabled: boolean;
    closeOnOutsideClick: boolean;
    visible: boolean;
    uploadUrl: string;
    maxHeight: string;
    showCloseButton: boolean;
    isReadOnlyAfterFileUpload: boolean = true;

    createMessageOnly(width: string, height: string, showTitle: boolean, title: string, visible: boolean = false,
        dragEnabled: boolean = true, resizeEnabled: boolean = true, closeOnOutsideClick: boolean = false, maxHeight: string = null, showCloseButton: boolean = true) {
        this.width = width;
        this.height = height;
        this.showTitle = showTitle;
        this.title = title;
        this.visible = visible;
        this.dragEnabled = dragEnabled;
        this.resizeEnabled = resizeEnabled;
        this.closeOnOutsideClick = closeOnOutsideClick;
        this.maxHeight = maxHeight;
        this.showCloseButton = showCloseButton;

        return this;
    }
}

export class Recurrence {
    id: number;
    name: string;
    dateUomId: number;
    nextBeginDate: Date;
    allowedUserGroups: string;
    duration: number;

    constructor(id: number, name: string, dateUomId: number, nextBeginDate: Date, allowUserGroups: string, duration: number) {
        this.id = id;
        this.name = name;
        this.dateUomId = dateUomId;
        this.nextBeginDate = nextBeginDate;
        this.allowedUserGroups = allowUserGroups;
        this.duration = duration;
    }

    createDummy(): Recurrence {
        this.id = 0;
        this.name = '<None>';
        this.dateUomId = undefined;
        this.nextBeginDate = undefined;
        this.allowedUserGroups = undefined;
        this.duration = undefined;

        return this;
    }
}

import { ReportLayout } from '../report-layout';

export class ReportContext {
    token: string;
    id: number;
    reportConfigId: string;
    beginDate: Date;
    endDate: Date;
    selectedSellers: string;
    seriesId?: number;
    isDesigner: boolean;
    reportLayout: ReportLayout;
}

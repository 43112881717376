import { NgModule } from '@angular/core';
import { ProcessLogViewerRoutingModule } from './process-log-viewer-routing.module';
import { ProcessLogViewerComponent } from './process-log-viewer.component';
import { DxButtonModule, DxDateBoxModule } from 'devextreme-angular';

import { CoreComponentModule } from 'src/app/shared/components/core-component.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CoreComponentModule,
        ProcessLogViewerRoutingModule,
        DxDateBoxModule,
        DxButtonModule,
        CommonModule
    ],
    declarations: [ProcessLogViewerComponent],
    exports: [ProcessLogViewerComponent]
})
export class ProcessLogViewerModule { }

/* eslint-disable id-blacklist */
export enum EnumBucketClass {
    Favorites = 1,
    DefaultHome = 2,
    ReportRootFolder = 3,
    DashboardRootFolder = 4,
    AnalyticRootFolder = 5
}

export enum EnumBucketType {
    Report = 1,
    Dashboard = 2,
    Analytic = 3
}

export enum EnumSettingClassId {
    PasswordPolicyEnabled = 19,
    PasswordStrengthMask = 20,
    PasswordStrengthDescription = 21,
    WorkFlowEnabled = 53,
    WhatIfEnabled = 55,
    SalesTabEnabled = 56,
    CallCenterEnabled = 57,
    AnalyticsTabEnabled = 58,
    DashboardTabEnabled = 59,
    ReportsTabEnabled = 60,
    HomePageTabEnabled = 61,
    CachedReportSourceEnabled = 62,
    ProcessingLayout = 63,
    ApplicationBlockWaitTime = 64,
    AllowDuplicateAccountDisplayNames = 66,
    ShowAllSubordinatesByDefault = 67,
    DisputeManagementTabEnabled = 68,
    WebEmailReportButton = 69,
    AllowUserToSelectDefaultPage = 70,
    UseNewMailClient = 71,
    HomeAnalyticTimeType = 72,
    HomeAnalyticRecurrenceID = 73,
    HomeAnalyticPeriodCount = 74,
    HomeAnalyticEnabled = 75,
    ShowCompanyIcon = 76,
    ShowCompanyLabel = 77,
    AccountAttributesPageEnabled = 82,
    AccountManagementPageEnabled = 83,
    FieldsPageEnabled = 84,
    ProcessingPageEnabled = 85,
    ProcessLogViewerPageEnabled = 86,
    TagGroupsPageEnabled = 87,
    EmailPageEnabled = 88,
    PlansPageEnabled = 89,
    AnalyticsPageEnabled = 90,
    SystemPageEnabled = 91,
    EtlPageEnabled = 93,
    CoreBotBetaTesting = 100,
    ShowTenantSurveyLink = 101,
    ShowUserSurveyLink = 102,
    ETLExplanationPopupDismissDate = 103,
    DropboxLinkURL = 104,
    EnableBITheme = 105,
    EmailAutomateTestEnabled = 106,
    ShowTenantCoreBotPopup = 107,
    ShowUserCoreBotPopup = 108,
    AuditInteractionsEnabled = 109,
    EnableNavBucketFolders = 110,
    BuildComparisonDatabase = 111,
    EnableAIRules = 112
}

export enum EnumUserGroup {
    Everybody = 1,
    Administrator = 2,
    Implementer = 3,
    Processor = 4,
}

export enum EnumSingletonClassId {
    Seller = 1,
    Qty = 2,
    Date = 3,
    Text = 4,
    Tag = 5,
    Groupable1 = 6,
    Groupable2 = 7,
    KickoutTime = 8,
    RegKey = 9,
    CaSigner = 10,
    CaCompany = 11,
    CaEmail = 12,
    CaPosition = 13
}

export enum EnumSetupObjectType {
    Table = 1,
    Calculation = 2,
    UserDefined = 3,
    Rule = 4,
    Assign = 5,
    Aggregate = 6,
    TieredRate = 7,
    Hierarchy = 8,
    Outputs = 9,
    Other = 10
}

export enum EnumContainerType {
    Plan = 1,
    ProductionRule = 2,
    Group = 3,
    SetupRule = 4,
    AIRule = 5
}

export enum EnumFormulaBuilderDataSet {
    Fields = 1,
    PlansSegments = 2,
    BuildingBlocks = 3,
    SetupGears = 4,
    SegmentGear = 5,
    CondFormats = 6,
    AIGears = 7
}

export enum CoreFeature {
    // Home
    ViewConfigureTileGroup = 1,
    ViewRunCycleTileGroup,
    ViewOutputsTileGroup,
    ViewSupportTileGroup,
    // Account management
    AccessAccounts,
    AddAccounts,
    EditAccounts,
    DeleteAccounts,
    EditAccountSecurity,
    EditUserGroups,
    AddAccountHistory,
    EditAccountHistory,
    DeleteAccountHistory,
    AddAccountFactors,
    EditAccountFactors,
    DeleteAccountFactors,
    ViewAccountHierarchy,
    // Account attributes
    AccessAccountAttributes,
    EditAccountAttributes,
    AddAccountAttributeEntries,
    DeleteAccountAttributeEntries,
    // Plans and Segments
    AccessSegments,
    AddSegments,
    EditSegments,
    DeactivateSegments,
    DeleteSegments,
    // Fields
    AccessFields,
    AddFields,
    EditFields,
    DeleteFields,
    // Tag Groups
    AccessTagGroups,
    AddTagGroupLists,
    AddTagGroupEntries,
    EditTagGroupLists,
    EditTagGroupEntries,
    DeleteTagGroupLists,
    DeleteTagGroupEntries,
    // System Settings
    AccessSystemSettings,
    AccessUserGroups,
    EditSystemSettings,
    EditInternalSettings,
    // ETL
    AccessEtl,
    AddEtlPlans,
    EditEtlPlans,
    DeleteEtlPlans,
    AddEtlSteps,
    EditEtlSteps,
    DeleteEtlSteps,
    // Processing
    AccessProcessing,
    ImportRecords,
    ViewImportedRecords,
    AddImportedRecords,
    DeleteImportedRecords,
    EditImportedRecords,
    RunCycle,
    ViewProcessedRecords,
    AddProcessedRecords,
    EditProcessedRecords,
    DeleteProcessedRecords,
    UpdateAnalytics,
    // Tasks
    AccessTasks,
    AddTasks,
    EditTasks,
    DeleteTasks,
    // File Management
    OpenSpreadsheets,
    OpenPdfDocuments,
    ManageFiles,
    // Email
    AccessEmail,
    CreateEmailLayouts,
    DeleteEmailLayouts,
    EditEmailLayouts,
    // Reports
    AccessReports,
    CreateReports,
    DeleteReports,
    EditReports,
    AssignReportPermissions,
    // Analytics
    AccessAnalytics,
    CreateAnalytics,
    DeleteAnalytics,
    EditAnalytics,
    AssignAnalyticsPermissions,
    // Dashboards
    AccessDashboards,
    // Report Designer
    AccessReportDesigner,
    CreateReportLayout,
    DeleteReportLayout,
    EditReportLayout,
    // Dashboard designer
    AccessDashboardDesigner,
    CreateDashboards,
    DeleteDashboards,
    EditDashboards,
    AssignDashboardPermissions,
    // Support
    AccessHelp,
    AccessForum,
    CreateTroubleTicket,
    ViewGettingStarted,
    ViewServicesAgreement,
    // Miscellaneous features
    AccessQuickNav,
    CreateQuickNavItem,
    ViewLogs,
    ImpersonateAllUsers,
    ApproveContract,
    CreateLayouts,
    DeleteLayouts,
    EditLayouts,
    ViewAllAccounts,
    ExportReports,
    ExportDashboards,
    // Periods
    CreatePeriod,
    DeletePeriod,
    LockPeriod,
    PublishPeriod,
    // Misc continued
    ImpersonateNonAdmins,
    ImpersonateSubordinates,
    ProcessViewablePlans,
    UseAdminCoreBotTools,
    UseEverybodyCoreBotTools,
    ViewBuildDiffChanges,
    ImportBuildDiffChanges,
    ViewAuditButton
}

export enum DependencyType {
    Main = 'main',
    Aux = 'aux',
}

export enum NavigationInitiatorCode {
    System,
    Browse,
    Diagram
}

export enum PeriodTypes {
    Standard = '.',
    Mtd = 'M',
    Qtd = 'Q',
    Ttd = 'Tri',
    Std = 'Sem',
    Ytd = 'Y',
    PriorMtd = 'L',
    PriorQtd = 'P',
    PriorTtd = 'PTri',
    PriorStd = 'PSem',
    PriorYtd = 'X',
    PriorYearMtd = 'N',
    PriorYearQtd = 'W',
    Rolling = 'R',
    Forward = 'F',
    Static = 'S'
}

export enum CoreImportColumnType {
    Unknown = 0,
    FreeText = 1,
    Date = 2,
    Seller = 3,
    AttributeClass = 4,
    Variable = 5
    // add more as they are needed
}

export enum PermissionSettingType {
    PermissionClass = 1,
    Recurrence = 2,
    Datasource = 3,
    Plan = 4,
    ETLPlan = 5
}

export enum BbObjectSourceCategory {
    Gear = 'Gears in This Rule',
    Gearbox = 'Gearboxes in This Rule',
    Datasource = 'Datasources',
    System = 'Key Tables',
    Rule = 'Other Rules'
}

export enum dateUoms {
    Day = 1,
    Month = 2,
    AllPeriods = 3
}

export enum processingDataViewerFunctions {
    Imported = 1,
    Processed = 2
}

export enum agGridSecondaryColumnType {
    Aggregate = 1,
    Text = 2,
    Number = 3,
    SellerId = 4,
    RuleId = 5,
    TagId = 6,
    SeriesId = 7,
    PeriodId = 8,
    ProductGroupId = 9,
    ProductId = 10,
    CustomerGroupId = 11,
    CustomerId = 12,
    Date = 13,
    Boolean = 14,
    SellerImportId = 15,
    DatasourceId = 16
}

export enum singletonClassIds {
    Seller = 1,
    Qty = 2,
    Date = 3,
    Text = 4,
    Tag = 5,
    Product = 6,
    Customer = 7,
    EmailTest = 14
}

export enum settingClassIds {
    EmailReportFormat = 4,
    EmailBccSender = 5,
    EmailSmtpServerHost = 6,
    EmailSmtpServerPort = 7,
    EmailSmtpServerUseSsl = 8,
    EmailSmtpServerUser = 9,
    EmailSmtpServerPassword = 10,
    EmailSmtpSenderAddress = 11,
    EmailSmtpSenderDisplayName = 12,
    EmailSmtpServerTimeout = 13,
    EmailSmtpSenderDisplayAddress = 41,
    InternalProductionSeries = 26,
    InternalMRUSeries = 44,
    InternalMRUFromPeriod = 45,
    InternalProcessingLayout = 63,
    InternalShowAllSubordinatesByDefault = 67,
    InternalLimitReportDashboardExportsByAttribute = 78,
    MicrosoftOAuthClient = 96,
    InternalBuildComparisonDatabase = 111,
}

export enum fileUploadTypeIds {
    ImportXactionAppend = 1,
    ImportXactionReplace = 2,
    ClientLogo = 3,
    ImportSpreadsheetTableData = 4,
    ImportAccountHistory = 5,
    ImportAccountFactors = 6,
    ImportAccountImportNames = 7
}

export enum coreResponseCodes {
    Success = 1,
    SuccessWithTruncated = 2,
    CanceledByUser = 3,
    StaleDependencies = 4,
    InvalidRequest = 5,
    RequiresConfirmation = 6,
    NoDataFound = 7,
    Unauthorized = 96,
    DirtyFileHeaders = 97,
    DirtyData = 98,
    Error = 99
}

export enum coreComponentInfo {
    DuplicatedRow = 'duplicatedRow'
}

export enum processLogRecordTypes {
    Started = 1,
    Entry = 2,
    Error = 3,
    Finished = 4,
    SingleRecordLog = 5,
    ManuallyTerminated = 6,
    LevelsUpdated = 7
}

export enum reservedEmailLayoutIds {
    Report = 1,
    Dashboard = 2,
    Credentials = 3
}

export enum uiViewContextCodes {
    ProcessingLayout = 10,
    EditXactionsWeb = 14,
    EditCalculationsWeb = 15,
    EditXactionsWebNew = 16,
    EditCalculationsWebNew = 17,
    AccountManagementSellerGrid = 18
}

export enum uiViewSystemCodes {
    ActivePayeesLayout = 1
}

export enum setupGearTypeIds {
    Table = 1,
    Calculation = 2,
    Placeholder = 3
}

export enum containerTypeIds {
    Plan = 1,
    ProductionRule = 2,
    Group = 3,
    SetupRule = 4,
    AIRule = 5
}

export enum disputeClassIds {
    AdminFields = 1,
    GridFields = 2,
    InputFields = 3,
    FilterFields = 4,
    ResolveFields = 5,
}

export enum integrationClassIds{
    CostGuard = 0,
    QuickBooks = 1,
    MicrosoftDynamics = 2,
    Salesforce = 3,
    SageIntacct = 4,
    Zywave = 5,
    Paychex = 6,
    Freshsales = 7,
    NetSuite = 8,
    UltiPro = 9,
    Bullhorn = 10,
    MicrosoftEmail = 11
}

export enum ProcessingFieldType {
    Unknown = 0,
    Long = 1,
    Double = 2,
    DateTime = 3,
    // eslint-disable-next-line id-blacklist
    String = 4,
    // eslint-disable-next-line id-blacklist
    Boolean = 5
}

export enum TenantStatus {
    Inactive,
    Active,
    DEFCON3,
    DEFCON2,
    DEFCON1,
    ThirtyDayTrial
}

export enum DisputeSectionOrder {
    Form = '1',
    Approval = '2',
    Filter = '3',
    Grid = '4'
}

export enum ChatBotUIType {
    PlainText,
    Formula,
    Filter,
    Join,
    Group,
    Union
}

export enum EmailTestResult {
    Pass = 'p',
    Fail = 'f',
    Dismissed = 'd'
}

export enum EnumCoreBotInteraction {
    DASelectorEmptyReport = 2,
    DASelectorEmptyDashboard = 3,
    EmptyPeriod = 6,
    CustomRunCycleStart = 23,
    CustomLogin = 20,
    CustomReportGeneration = 21,
    CustomDashboardGeneration = 22,
    StaleProcessedOutput = 25,
}

export enum CoreBotInteractionTriggerType {
    RunCycleStart = 4,
    Login = 5,
    ReportGeneration = 6,
    DashboardGeneration = 7
}

export enum RDVRequestsEnum {
	ROWS,
	NUMROWS,
	FILTER
}

export enum CoreSettingType {
    Text = 1,
    WholeNumber = 2,
    Boolean = 3,
    Password = 4,
    Timeout = 5,
    ReportFileFormat = 6,
    SMTPPort = 7,
    Skin = 8,
    Timespan = 9,
    Recurrence = 10
}

export enum ItemChange {
    Added,
    Updated,
    Deleted
}

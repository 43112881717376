import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreBotRequest } from '../models/core-bot-request';
import { CoreBotResponse } from '../models/core-bot-response';

@Injectable({
    providedIn: 'root'
})

export class CoreBotService {
    private url: string = environment.apiEndpoint + '/CoreBot';

    constructor(private http: HttpClient) {
    }

    getModelCompletion(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/', request);
    }

    getQueryBotResponse(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/QueryBotQuery', request);
    }

    getQuestionType(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/QuestionType', request);
    }

    getRuleBotQuestionType(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/RuleBotQuestionType', request);
    }

    getAuditBotResponse(request: CoreBotRequest, periodId: number, seriesId: number): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/AuditBotResponse/' + periodId + '/' + seriesId, request);
    }

    getUpstreamAuditBotResponse(request: CoreBotRequest, periodId: number, seriesId: number, processId: string): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/AuditBotResponse/' + periodId + '/' + seriesId + '/' + processId, request);
    }

    getQueryBotExplainer(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/QueryExplainer', request);
    }

    getReportAuditResponse(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/ReportAudit/', request);
    }

    buildRuleBotRule(request: CoreBotRequest): Observable<CoreBotResponse>{
        return this.http.post<CoreBotResponse>(this.url + '/RuleBotRule', request);
    }

    buildAnalyticBotAnalytic(request: CoreBotRequest): Observable<CoreBotResponse> {
        return this.http.post<CoreBotResponse>(this.url + '/AnalyticBotAnalytic', request);
    }
}

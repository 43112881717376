import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxResponsiveBoxModule,
    DxCheckBoxModule
} from 'devextreme-angular';
import { HierarchyComponent } from './hierarchy.component';
import { CoreComponentModule } from '../core-component.module';

@NgModule({
    imports: [
        CommonModule,
        CoreComponentModule,
        DxButtonModule,
        DxTextBoxModule,
        DxPopupModule,
        DxResponsiveBoxModule,
        DxCheckBoxModule,
        CoreComponentModule
    ],
    declarations: [HierarchyComponent],
    exports: [HierarchyComponent]
})
export class HierarchyModule { }

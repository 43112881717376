import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { GridProps } from '../../models/core-data-grid-properties';
import { CoreColumn } from '../../models/core-column';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { EditModes } from '../../constants/dev-extreme-enums';

@Component({
    selector: 'core-value-list',
    templateUrl: './core-value-list.component.html',
    styleUrls: ['./core-value-list.component.scss']
})

export class CoreValueListComponent implements OnInit {
    @Input() dataSource!: any[];
    @Input() keyExpr: string;
    @Input() valueExpr: string;
    @Input() deleteEnabledExpr: string;
    @Input() dataType: string;

    // Non-required inputs
    @Input() parentTemplate: any;

    @Output() dataSourceChange = new EventEmitter<any[]>();

    columns: CoreColumn[] = [];
    props: GridProps = new GridProps();

    constructor() { }

    ngOnInit() {

        this.columns.push(new CoreColumn(this.keyExpr, 'Key', false, null, null, false));
        const valueColumn = new CoreColumn(this.valueExpr, 'Value', true, this.dataType, null, true);
        valueColumn.validationRules = [];
        this.columns.push(valueColumn);

        this.props = new GridProps(null, null, false, true, false, false, false, false);
        this.props.isSearchEnabled = false;
        this.props.showColumnHeaders = false;
        this.props.isAddingAllowed = true;
        this.props.isUpdatingAllowed = true;
        this.props.isDeletingAllowed = true;
        this.props.editMode = EditModes.Cell;
        this.props.deleteEnabledColumn = this.deleteEnabledExpr;
        this.props.addOnRowInsertedFunction(this, this.onInserted);
        this.props.addOnRowUpdatedFunction(this, this.onUpdated);
        this.props.addOnRowRemovedFunction(this, this.onRemoved);
    }

    onInserted(e: CoreEventArguments): void {
        const insertedItem = this.dataSource.find(x => x[this.valueExpr] === e.event.data[this.valueExpr]);
        insertedItem[this.keyExpr] = 0;
        if (this.parentTemplate && this.parentTemplate.setValue) {
            this.parentTemplate.setValue(this.dataSource);
        }
        this.dataSourceChange.emit(this.dataSource);
    }

    onUpdated(e: CoreEventArguments): void {
        if (this.parentTemplate && this.parentTemplate.setValue) {
            this.parentTemplate.setValue(this.dataSource);
        }
        this.dataSourceChange.emit(this.dataSource);
    }

    onRemoved(e: CoreEventArguments): void {
        if (this.parentTemplate && this.parentTemplate.setValue) {
            this.parentTemplate.setValue(this.dataSource);
        }
        this.dataSourceChange.emit(this.dataSource);
    }
}

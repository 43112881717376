import {
    Component, OnInit, AfterViewChecked, ElementRef, OnDestroy, ViewEncapsulation, Input, NgModule,
    ViewChild, Output, EventEmitter,
    AfterViewInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxPivotGridComponent, DxChartComponent, DxFileManagerModule, DxFileManagerComponent } from 'devextreme-angular';
import { AzureService } from '../../services/azure-service/azure-service.service';
import { environment } from 'src/environments/environment';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { CoreComponentModule } from '../core-component.module';
import { FileProcessingRoutingModule } from 'src/app/pages/file-processing/file-processing-routing.module';

@Component({
    selector: 'app-bb-file-manager',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './bb-file-manager.component.html',
    styleUrls: ['./bb-file-manager.component.scss']
})

export class CoreFileManagerComponent implements AfterViewInit {
    @ViewChild('fileManager') fileManager: DxFileManagerComponent;
    @Input() value: string;
    @Input() azureContainer: string;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>(true);
    @Output() onFileManagerLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    hostUrl: string = environment.apiEndpoint;
    fileSystemProvider: CustomFileSystemProvider;
    requests: any[];
    azureEndpointUrl: string = environment.apiEndpoint + '/AzureDataStorage/file-manager-azure-access';

    constructor(
        private azureService: AzureService
    ) {
        this.requests = [];
    }

    ngAfterViewInit(){
        this.setCurrentPath('');
    }

    onRequestExecuted = ({ method, urlPath, queryString, selectedPeriodId }: { method: string, urlPath: string, queryString: string, selectedPeriodId: number}): void => {
        const request = { method, urlPath, queryString, selectedPeriodId };
        this.requests.unshift(request);
    };

    onItemSelected(e: any){
        console.log(this.value);
        console.log(e);
        this.valueChange.emit(e.item.key);
    }

    onLoaded(e: any){
        this.onFileManagerLoaded.emit(true);
    }

    setCurrentPath(e: any){
        const dirName = this.value.split('/').slice(0, -1).join('/');
        this.fileManager.currentPath = dirName;
        this.fileManager.focusedItemKey = this.value;
    }

    reloadFilesFromAzure(container: string){
        this.fileSystemProvider = this.azureService.getAzureFileSystemProvider(this.azureEndpointUrl, container, this.onRequestExecuted);
    }
}

@NgModule({
    imports: [
        CommonModule,
        FileProcessingRoutingModule,
        DxFileManagerModule,
        CoreComponentModule
    ],
    declarations: [CoreFileManagerComponent],
    exports: [CoreFileManagerComponent]
})
export class BbFileManagerModule{ }

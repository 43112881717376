import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VarDirective } from './directives/ng-var.directive';
import { FilterPipe } from './pipes/filter.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FilterPipe,
        VarDirective
    ],
    exports: [
        FilterPipe,
        VarDirective
    ]
  })

  export class SharedModule { }

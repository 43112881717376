import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, ViewChild, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { DxPopoverComponent, DxTextBoxComponent, DxToolbarComponent } from 'devextreme-angular';
import { CoreBotRequest } from '../../../models/core-bot-request';
import { NbButton, NbChatComponent } from '@nebular/theme';
import * as events from 'devextreme/events';
import { SellerService } from '../../../services/seller.service';
import { CoreBotService } from '../../../services/core-bot.service';
import { ToastrService } from 'ngx-toastr';
import { CoreBotPopupContentComponent } from '../corebot-shared/popup-content/corebot-popup-content.component';
import { Analytic } from 'src/app/shared/models/analytic';
import { AnalyticViewerComponent } from '../../analytic-viewer/analytic-viewer.component';
import { environment } from 'src/environments/environment';
import { BasicChatMessage } from 'src/app/shared/models/chat-messages/basic-chat-message';
import { UserMessage } from 'src/app/shared/models/chat-messages/user-message';
import { BotTypingMessage } from 'src/app/shared/models/chat-messages/bot-typing-message';
import { AnalyticBotMessage } from 'src/app/shared/models/chat-messages/analyticbot-message';

@Component({
    selector: 'app-analyticbot-chat',
    templateUrl: './analyticbot-chat.component.html',
    styleUrls: ['./analyticbot-chat.component.scss']
})
export class AnalyticBotChatComponent implements OnInit, AfterViewInit {
    @ViewChild('nebularChat', {static: false}) nebularChat: NbChatComponent;
    @ViewChild('analyticBotInput') analyticBotInput: ElementRef;
    @ViewChild('sendButton') sendButton: NbButton;
    @ViewChild('chatContent') chatContent: CoreBotPopupContentComponent;

    @Input() buttonIcon: string = 'paper-plane-outline';
    @Input() message: string = '';
    @Input() analytic: Analytic;
    @Input() analyticViewer: AnalyticViewerComponent;
    @Output() onInputChange = new EventEmitter<string>();

    @Input() showPopup: boolean = false;
    @Output() showPopupChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    messages: any[] = [
        new BasicChatMessage(
            'Need help creating an analytic? Try entering in a description of what you\'re looking for, like "Show me account totals ' +
                'broken down by product."',
            'AnalyticBot',
            '../../../../assets/images/analyticBot.png'
        )
    ];
    sellerName: string = '';
    request: CoreBotRequest;
    isUsingCoreBotBeta: boolean = false;
    samplePrompt: string = '';

    constructor(
        private sellerService: SellerService,
        private coreBotService: CoreBotService,
        private toast: ToastrService
    ){}

    ngOnInit(): void {
        this.sellerService.getMe().subscribe(seller => {
            this.sellerName = seller.name;
        });
        this.samplePrompt = this.getSamplePrompt();
    }

    ngAfterViewInit(): void {
        if (localStorage.getItem('CoreBotQuestion') !== null){
            setTimeout(() => {
                this.showPopup = true;
                const e: any = '';
                this.message = localStorage.getItem('CoreBotQuestion');
                this.submitText(e);
                localStorage.removeItem('CoreBotQuestion');
            }, 3500);
        }
    }

    scrollMessagesToBottom(e: any){
        this.chatContent.nebularChat.scrollListBottom();
        this.analyticBotInput.nativeElement.focus();
    }

    onModelChange(value: string): void {
        this.onInputChange.emit(value);
    }

    disableAnalyticBotFields(){
        this.sendButton.disabled = true;
        this.analyticBotInput.nativeElement.disabled = true;
    }

    enableAnalyticBotFields(){
        this.sendButton.disabled = false;
        this.analyticBotInput.nativeElement.disabled = false;
    }

    onHiding(e: any){
        this.showPopup = false;
        this.showPopupChanged.emit(this.showPopup);
    }

    submitText(e: any){
        if (this.message.length > 0){
            const request: CoreBotRequest = {
                prompt: this.message,
                additionalInfo: this.analytic.id.toString(),
                environment: environment.production ? 'production' : 'test'
            };

            this.messages.push(new UserMessage(this.sellerName, this.message));
            this.message = '';
            this.samplePrompt = 'Enter your prompt...';
            this.disableAnalyticBotFields();

            setTimeout(() => {
                this.messages.push(new BotTypingMessage());
            }, 500);

            this.coreBotService.buildAnalyticBotAnalytic(request).subscribe(response => {
                let responseMessage: string = this.getAnalyticBotResponse();
                this.enableAnalyticBotFields();

                setTimeout(() => {
                    this.messages.pop();
                    if (response.response.includes('An error')){
                        responseMessage = response.response;
                    }
                    else {
                        this.analyticViewer.displayAnalytic(false, response.response);
                        this.toast.success('Analytic successfully generated');
                    }

                    this.messages.push(new AnalyticBotMessage(response, responseMessage, 'AnalyticBot', '../../../../assets/images/analyticBot.png'));
                }, 500);
            });
        }
    }

    getAnalyticBotResponse(): string {
        const rand: number = Math.floor(Math.random() * 3);
        const responses = [
            'One analytic for you, coming right up!',
            'I\'ve successfully generated the chart you requested',
            'Done! The chart you requested has been generated'
        ];
        return responses[rand];
    }

    onContentReady(e){
        events.on(e.component.content(), 'dxmousewheel', null, (ev) => {
            ev.stopPropagation();
        });
    }

    getSamplePrompt(): string{
        const rand: number = Math.floor(Math.random() * 3);
        const prompts = [
            'Show me account data compared with position',
            'Compare account data accross all time',
            'Show me invoice data broken down by account'
        ];
        return prompts[rand];
    }
}

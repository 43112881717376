import { CoreColumn } from './core-column';
import { GridProps } from './core-data-grid-properties';
import { DevExpressDropdownOptions } from './dev-express-dropdown-options';

export class CoreColumnTemplateProps {
    columns: CoreColumn[];
    gridProps: GridProps;
    templateOptions: any;
    isClosedOnSelection: boolean = true;

    createForTableDropDown(columns: CoreColumn[], gridProps: GridProps, templateOptions: DevExpressDropdownOptions): CoreColumnTemplateProps {
        this.columns = columns;
        this.gridProps = gridProps;
        this.templateOptions = templateOptions;
        this.gridProps.isFocusedRowEnabled = true;

        return this;
    }
}

import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { value } from 'devexpress-dashboard/model/index.metadata';
import { field } from 'devexpress-reporting/scopes/reporting-designer-controls-pivotGrid-metadata';
import { DxFilterBuilderComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { GearPropertyValue, DataColumn, DataColumnType, ProcessDataColumn } from '../../models/building-blocks';
import { BuildingBlocksService } from '../../services/building-blocks.service';
import { FieldService } from '../../services/field.service';
import { HelperService } from '../../services/helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';

@Component({
	selector: 'app-bb-filter-builder',
	providers: [ {provide: SaveableBbProperty, useExisting: BbFilterBuilderComponent }],
	templateUrl: './bb-filter-builder.component.html',
	styleUrls: ['./bb-filter-builder.component.scss']
})
export class BbFilterBuilderComponent extends SaveableBbProperty implements OnInit, OnChanges {
	@Input() filterBuilderObject: any[];
	@Input() validDataColumns: any;
	@Input() auxDataColumns: any;
	@Input() isJoin: boolean = true;
	@Output() onFilterStringUpdate: EventEmitter<string> = new EventEmitter();
	@Output() filterBuilderObjectChange: EventEmitter<any[]> = new EventEmitter();
	@ViewChild('filterBuilder') filterBuilder: DxFilterBuilderComponent;

	filterBuilderObjectBuffer: any[];
	validColumnsSubject = new BehaviorSubject<any>(null);
	auxColumnsSubject = new BehaviorSubject<any>(null);
	brokenFields: string[];
	brokenFieldMessage: string = 'The filter contains field(s) that are no longer available. The field(s) must be removed before the filter can be processed.';
	brokenFieldsShown: boolean = false;

	allProps = { fields: [], value: [] };
	allDataColumns: any[];
	allAuxDataColumns: any[];

	columnCompareTypes: string[] = ['Quantity', 'Date', 'Text', 'Gears', 'AccountFactors'];
	comparedColumnsByType: any[] = [];
	auxComparedColumnsByType: any[] = [];
	textColumnValue: string = '';
	hoveredElement: any = null;
	copiedElement: any = null;
	textSpecificOperations = [' = ', 'LIKE', 'NOT LIKE'];

	customOperations = [
		{
			name: 'in',
			caption: 'in',
			icon: 'check',
			editorTemplate: 'tagBoxTemplate',
			customizeText: (fieldInfo) => {
				const lookup = fieldInfo.field.lookup;
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource.some(x => x['items']) ?
					[].concat(...(lookup.dataSource.map(group => group['items']))) :
					lookup.dataSource;
				return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
			}
		},
		{
			name: 'not in',
			caption: 'not in',
			icon: 'close',
			editorTemplate: 'tagBoxTemplate',
			customizeText: (fieldInfo) => {
				const lookup = fieldInfo.field.lookup;
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource.some(x => x['items']) ?
					[].concat(...(lookup.dataSource.map(group => group['items']))) :
					lookup.dataSource;
				return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
			}
		},
		{
			name: 'is null',
			caption: 'Is null',
			icon: 'isblank',
			hasValue: false,
		},
		{
			name: 'is not null',
			caption: 'Is not null',
			icon: 'isnotblank',
			hasValue: false,
		},
		{
			name: 'between',
			caption: 'Is between'
		},
		{
			name: '=',
			caption: 'Equals',
			icon: 'equal',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: '= ',
			caption: 'Equals column',
			icon: 'equal',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField, true);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: '<> ',
			caption: 'Does not equal column',
			icon: 'notequal',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField, true);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
			}
		},
		{
			name: '<',
			caption: 'Is less than',
			icon: 'less',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: '>',
			caption: 'Is greater than',
			icon: 'greater',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: '<=',
			caption: 'Is less than or equal to',
			icon: 'lessorequal',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: '>=',
			caption: 'Is greater than or equal to',
			icon: 'greaterorequal',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: '<>',
			caption: 'Does not equal',
			icon: 'notequal',
			editorTemplate: 'columnTemplate',
			customizeText: (fieldInfo) => {
				const lookup = this.getDatasourceForEditorText(fieldInfo.field.dataField);
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource;
				const matchingColumn = dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value);
				return matchingColumn ? matchingColumn[lookup.displayExpr] : fieldInfo.value;
			}
		},
		{
			name: ' <> ',
			caption: 'Does not equal string',
			icon: 'notequal',
			editorTemplate: 'stringTemplate'
		},
		{
			name: ' = ',
			caption: 'Equals string',
			icon: 'equal',
			editorTemplate: 'stringTemplate'
		},
		{
			name: 'LIKE',
			caption: 'Like',
			icon: 'contains',
			editorTemplate: 'stringTemplate'
		},
		{
			name: 'NOT LIKE',
			caption: 'Not Like',
			icon: 'doesnotcontain',
			editorTemplate: 'stringTemplate'
		}
	];

	isValid: boolean = true;

	constructor(private bbHelper: BuildingBlockHelperService,
		private bbService: BuildingBlocksService,
		private helperService: HelperService,
		private toast: ToastrService,
		private cd: ChangeDetectorRef) {
			super();
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if(event.ctrlKey){
			if(event.key === 'c'){
				this.copyValue();
			}
			if(event.key === 'v'){
				this.pasteValue();
			}
		}
	}

	ngOnInit() {
		combineLatest([this.validColumnsSubject,
			this.auxColumnsSubject,
			this.bbHelper.getDataColumns().pipe(filter(x => x !== null && x.length > 0))]).subscribe(([validDataColumns, auxDataColumns, allDataColumns]) => {
			let isBotFilter: boolean = false;
			if (validDataColumns){
				isBotFilter = validDataColumns.every(x => x.hasOwnProperty('systemName'));
				if (isBotFilter){
					this.allDataColumns = allDataColumns?.filter(col => validDataColumns.some(vdc => vdc.systemName === col.systemName));
				}
				else {
					this.allDataColumns = allDataColumns?.filter(col => validDataColumns.some(vdc => vdc.refName === col.systemName));
				}
			}

			if(auxDataColumns){
				if (isBotFilter){
					this.allAuxDataColumns = allDataColumns?.filter(col => auxDataColumns.some(vdc => vdc.systemName === col.systemName));
					this.columnCompareTypes.forEach(element => {
						this.auxComparedColumnsByType[element] = this.bbHelper.getLookupByDatatype(element);
						this.auxComparedColumnsByType[element].dataSource = this.auxComparedColumnsByType[element].dataSource.filter(
							column => auxDataColumns?.some(vdc => vdc.systemName.includes(column.refName)));
					});
				}
				else {
					this.allAuxDataColumns = allDataColumns?.filter(col => auxDataColumns.some(vdc => vdc.refName === col.systemName));
					this.columnCompareTypes.forEach(element => {
						this.auxComparedColumnsByType[element] = this.bbHelper.getLookupByDatatype(element);
						this.auxComparedColumnsByType[element].dataSource = this.auxComparedColumnsByType[element].dataSource.filter(
							column => auxDataColumns?.some(vdc => vdc.refName.includes(column.refName)));
					});
				}
			}

			if (validDataColumns){
				if (isBotFilter) {
					this.columnCompareTypes.forEach(element => {
						this.comparedColumnsByType[element] = this.bbHelper.getLookupByDatatype(element);
						this.comparedColumnsByType[element].dataSource = this.comparedColumnsByType[element].dataSource.filter(
							column => validDataColumns?.some(vdc => vdc.systemName.includes(column.refName)));
					});
				} else {
					this.columnCompareTypes.forEach(element => {
						this.comparedColumnsByType[element] = this.bbHelper.getLookupByDatatype(element);
						this.comparedColumnsByType[element].dataSource = this.comparedColumnsByType[element].dataSource.filter(
							column => validDataColumns?.some(vdc => vdc.refName.includes(column.refName)));
					});
				}
			}

			this.prepFilterBuilder();
		});

		window.onclick = () => {
            const selectedFilterRule = document.querySelector('.dx-filterbuilder-text.dx-filterbuilder-item-field.dx-state-active') as HTMLElement;
            if (selectedFilterRule) {
                const filterDropdown = document.querySelector('.dx-filterbuilder-overlay .dx-treeview') as HTMLElement;
                const selectedFilterRuleRect = selectedFilterRule.getBoundingClientRect();
                const filterDropdownRect = filterDropdown.getBoundingClientRect();
                if (selectedFilterRuleRect.top < filterDropdownRect.top) {
                    const spaceBelow = window.innerHeight - selectedFilterRuleRect.bottom - 30;
                    filterDropdown.style.maxHeight = spaceBelow + 'px';
                }
            }
        };
	}

	ngOnChanges(changes){
		if (changes.validDataColumns?.currentValue?.dataSource?.length > 0 && (changes.auxDataColumns?.currentValue?.dataSource?.length > 0)){
			this.validColumnsSubject.next(changes.validDataColumns.currentValue.dataSource);
			this.auxColumnsSubject.next(changes.auxDataColumns.currentValue.dataSource);
		}
		else if (changes.validDataColumns?.currentValue?.dataSource?.length > 0) {
			this.validColumnsSubject.next(changes.validDataColumns.currentValue.dataSource);
		} else if (changes.auxDataColumns?.currentValue?.dataSource?.length > 0) {
			this.auxColumnsSubject.next(changes.auxDataColumns.currentValue.dataSource);
		}
		if (changes.filterBuilderObject?.firstChange){
			this.filterBuilderObjectBuffer = this.filterBuilderObject;
			this.filterBuilderObject = null;
		}
		this.prepFilterBuilder();
		this.addBrokenFields();
	}

	prepFilterBuilder() {
		if((!this.isJoin || this.auxDataColumns) && this.validDataColumns && this.allDataColumns){
			const mainSrcCols = this.getFields(this.allDataColumns);
			if(this.isJoin){
				mainSrcCols.fields.forEach(f => {
					f.dataField = 'MainSource.' + f.dataField;
				});
				const auxSrcCols = this.getFields(this.allAuxDataColumns);
				auxSrcCols.fields.forEach(f => {
					f.dataField = 'AuxSource.' + f.dataField;
				});
				const allPropsFields = mainSrcCols.fields.concat(auxSrcCols.fields);
				this.allProps = { fields: allPropsFields, value: []};
			} else {
				this.allProps = mainSrcCols;
			}
			if (this.filterBuilderObject === null && this.filterBuilderObjectBuffer){
				this.filterBuilderObject = this.filterBuilderObjectBuffer;
				this.filterBuilderObjectBuffer = null;
			}
			this.addBrokenFields();
		}
	}

	getFields(dataColumns: DataColumn[]): any {
		const colProps = { fields: [], value: [] };
		colProps.fields.push(
			...dataColumns
				.filter(x => [DataColumnType.TagField, DataColumnType.TagGroup].includes(x.type))
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'Tag.' + dataColumn.systemName,
					dataType: 'number',
					filterOperations: (dataColumn.type === DataColumnType.TagGroup ? ['in', 'not in', 'is null', 'is not null'] : ['=', '<>', 'in', 'not in', 'is null', 'is not null', '= '])
						.concat(this.textSpecificOperations),
					lookup: this.bbHelper.getLookupByDatatype(dataColumn.datatype, this.isFieldGrouped('Tag.' + dataColumn.systemName)),
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x =>
					[DataColumnType.QuantityField, DataColumnType.CalculatedField, DataColumnType.RecordMetaField, DataColumnType.RuleIntroduced].includes(x.type)
					|| ([DataColumnType.CoreInternalField, DataColumnType.GearIntroduced].includes(x.type) && ['decimal', 'number'].includes(x.datatype)))
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'Quantity.' + ((dataColumn.type === DataColumnType.GearIntroduced && ['decimal', 'number'].includes(dataColumn.datatype)) ? 'Gear.' : 'Transaction.')
						+ dataColumn.systemName,
					dataType: 'decimal',
					filterOperations: ['=', '>', '<', '>=', '<=', '<>', 'between', 'is null', 'is not null'],
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x => x.type === DataColumnType.DateField || (x.type === DataColumnType.GearIntroduced && x.datatype === 'datetime'))
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'Date.' + ((dataColumn.type === DataColumnType.GearIntroduced && dataColumn.datatype === 'datetime') ? 'Gear.' : 'Transaction.') + dataColumn.systemName,
					dataType: 'date',
					editorOptions: {dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss', displayFormat: 'MM/dd/yyyy'},
					filterOperations: ['=', '>', '<', '>=', '<=', '<>', 'between', 'is null', 'is not null'],
					customizeText: (fieldInfo) => this.formatOnlyDateComparisons(fieldInfo)
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x => [DataColumnType.AccountAttribute].includes(x.type))
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'Attribute.' + dataColumn.systemName,
					dataType: 'string',
					filterOperations: ['=', '= ', '<>', 'in', 'not in', 'is null', 'is not null'].concat(this.textSpecificOperations),
					lookup: this.bbHelper.getLookupByDatatype(dataColumn.datatype),
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x => x.type === DataColumnType.SellerField)
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'RoleType.' + dataColumn.systemName,
					dataType: 'string',
					filterOperations: ['=', '= ', '<>', 'in', 'not in', 'is null', 'is not null'].concat(this.textSpecificOperations),
					lookup: this.bbHelper.getLookupByDatatype(dataColumn.datatype),
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x => x.type === DataColumnType.TextField || ([DataColumnType.CoreInternalField, DataColumnType.GearIntroduced].includes(x.type) && x.datatype === 'string'))
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'Text.' + ((dataColumn.type === DataColumnType.GearIntroduced && dataColumn.datatype === 'string') ? 'Gear.' : 'Transaction.') + dataColumn.systemName,
					dataType: 'string',
					filterOperations: ['= ', ' = ', '<> ', ' <> ', 'LIKE', 'NOT LIKE', 'is null', 'is not null'],
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x => x.type === DataColumnType.AccountFactors)
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'AccountFactors.' + dataColumn.systemName,
					dataType: 'number',
					filterOperations: ['=', '>', '<', '>=', '<=', '<>', 'is null', 'is not null']
				}))
		);

		colProps.fields.push(
			...dataColumns
				.filter(x => !colProps.fields.some(f =>  x.systemName === f.dataField.split('.').at(-1)))
				.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
				.map(dataColumn => ({
					caption: dataColumn.friendlyName,
					dataField: 'EverythingElse.' + dataColumn.systemName,
					dataType: 'number',
					filterOperations: ['=', '>', '<', '>=', '<=', '<>', 'is null', 'is not null'],
				}))
		);
		return colProps;
	}

	addBrokenFields(){
		this.brokenFields = [];
		this.gatherFieldsFromFilterObject(this.filterBuilderObject)
		.filter(x => x !== null && !this.allProps.fields.some(f =>  x === f.dataField) && x.includes('_')).forEach(obj => {
			this.allProps.fields.push({
				caption: `Broken Field (${obj})`,
				dataField: obj,
				dataType: 'number',
				filterOperations: ['=', '>', '<', '>=', '<=', '<>', '= ', ' = ', '<> ', ' <> ', 'LIKE', 'NOT LIKE', 'is null', 'is not null'],
			});
			this.brokenFields.push(obj);
		});
		if(this.brokenFields.length > 0 && !this.brokenFieldsShown){
			this.toast.error(this.brokenFieldMessage);
			this.brokenFieldsShown = true;
		}
	}

	gatherFieldsFromFilterObject(obj){
		let returnArray = [];
		if(obj && typeof(obj) === 'object'){
			if(typeof(obj[0]) === 'string'){
				return [obj[0]];
			} else {
				(obj as Array<any>).forEach(x => {
					returnArray = returnArray.concat(this.gatherFieldsFromFilterObject(x));
				});
			}
		}
		return returnArray;
	}

	generateSqlString() {
		this.filterBuilderObjectChange.emit(this.filterBuilderObject);
	}

	beginQtyNullableSql(qty: number): string {
		return '' + (qty ?? '-100000000000');
	}

	endQtyNullableSql(qty: number): string {
		return '' + (qty ?? '99999999999');
	}

	beginDateNullableSql(date: Date): string {
		return 'CONVERT(datetime, \'' + formatDate(date ?? '1753-01-01', 'yyyy-MM-dd', 'en-US') + 'T00:00:00.000\', 126)';
	}

	endDateNullableSql(date: Date): string {
		return 'CONVERT(datetime, \'' + formatDate(date ?? '9999-12-31', 'yyyy-MM-dd', 'en-US') + 'T23:59:59.990\', 126)';
	}

	isFieldGrouped(condition: any){
		return ['Tag.product_id', 'Tag.customer_id', 'Tag.product_name', 'Tag.customer_name'].some(suffix => condition.endsWith(suffix));
	}

	setTextColumnValue(condition, colValue){
		condition.setValue(colValue);
	}

	formatOnlyDateComparisons(fieldInfo: any){
		if (this.validDataColumns['dataSource'].some(col => col.refName === fieldInfo.value)){
			return fieldInfo.value;
		} else {
			if(!fieldInfo.value){
				return '?';
			}
			const dateOnly = fieldInfo.value.replaceAll('\'', '');
			if(isNaN(Date.parse(dateOnly))){
				return;
			}
			return this.helperService.formatDate(dateOnly);
		}
	}

	customColumn(e: any, condition: any){
		e.customItem = e.text;
		condition.text = e.customItem;
		condition.value = e.customItem;
		condition.setValue(e.customItem);
	}

	retainCustomValue(newValue: any, condition: any){
		if(newValue){
			condition.value = newValue;
			condition.setValue(newValue);
		}
	}

	getDatasourceForEditorTemplate(condition: any){
		return this.getDatasourceForEditorText(condition.field.dataField, ['= ', '<> '].includes(condition.filterOperation));
	}

	getDatasourceForEditorText(fieldName: string, usesColumns: boolean = false){
		const originalField = this.allProps.fields.find(f => f.dataField === fieldName);
		const splitField = fieldName.split('.');
		const isJoin = splitField[0].includes('Source');
		let fieldType = isJoin ? splitField[1] : splitField[0];
		if(originalField.lookup && !usesColumns){
			return originalField.lookup;
		} else {
			if(!this.columnCompareTypes.includes(fieldType)){
				fieldType = 'Text';
			}
			return (splitField[0] === 'MainSource' ? this.auxComparedColumnsByType : this.comparedColumnsByType)[fieldType];
		}
	}

	hasCustomValues(condition: any){
		const splitField = condition.field.dataField?.split('.');
		return ['Quantity', 'Date', 'AccountFactors', 'MainSource', 'AuxSource'].includes(splitField[0]);
	}

	handleEnterKey(e: any){
		e.component.blur();
	}

	bindFilterBuilderElements(e: any){
		const filterBuilderElements = this.filterBuilder['element']?.nativeElement.querySelectorAll('#property-panel-filter-builder div.dx-filterbuilder-group-item');
		filterBuilderElements.forEach(element => {
			element['title'] = 'Ctrl+c to copy, Ctrl+v on a group title to paste';
			element['onmouseover'] = this.onFilterBuilderElementHover;
			element['onmouseout'] = this.onFilterBuilderElementHoverOut;
			const filterObject = this.getContentFromElement(this.traverseUpToGroup(element));
			if(typeof(filterObject[0]) === 'string' && this.brokenFields?.includes(filterObject[0])){
				const subElement = element.querySelectorAll('div.dx-filterbuilder-item-field')[0];
				subElement['className'] = subElement['className'] + ' broken-field';
			}
		});

		const betweenOperators = this.filterBuilder['element']?.nativeElement.querySelectorAll('.dx-filterbuilder-range-start');
		if(betweenOperators.length > 0){
			const entireConditionWidth = betweenOperators[0].parentElement.parentElement.parentElement.scrollWidth;
			const editorWidth = betweenOperators[0].parentElement.parentElement.scrollWidth;
			const endOfBetweenEditorDistance = entireConditionWidth - editorWidth;
			this.filterBuilder['element'].nativeElement.parentElement.parentElement.scrollLeft = endOfBetweenEditorDistance;
		}
	}

	onFilterBuilderElementHover = (e: any) => {
		this.hoveredElement = e.target;
	};

	onFilterBuilderElementHoverOut =  (e: any) => {
		if(this.hoveredElement === e.target){
			this.hoveredElement = null;
		}
	};

	copyValue() {
		if(this.hoveredElement){
			if(this.copiedElement){
				const group = this.traverseUpToGroup(this.copiedElement);
				group.className = group.className.replace('copied-border ', '');
			}
			this.copiedElement = this.hoveredElement;
			this.bbHelper.setCopiedFilter(this.getContentFromElement(this.hoveredElement));
			this.traverseUpToGroup(this.hoveredElement).className = 'copied-border ' + this.traverseUpToGroup(this.hoveredElement).className;
			this.toast.success('Filter condition copied.');
		}
	}

	pasteValue() {
		const parentGroup = this.traverseUpToGroup(this.hoveredElement);
		if(!parentGroup){
			return;
		}

		const groupType: string = parentGroup.innerText.split('\n')[0];
		if(!['And', 'Or'].includes(groupType)){
			return;
		}

		const path = this.getPathToElement(this.hoveredElement);
		let highestElement = this.hoveredElement;
		let isParentFound = false;
		while(!isParentFound){
			const parentElement = this.traverseUpToGroup(highestElement.parentElement);
			if(parentElement){
				highestElement = parentElement;
			} else {
				isParentFound = true;
			}
		}
		this.filterBuilderObject = this.getContentFromElement(highestElement);
		let selectedFilterGroupArray = this.filterBuilderObject;
		const copiedFilter = this.bbHelper.getCopiedFilter();
		while(selectedFilterGroupArray && path.length > 0){
			const node = path.pop();
			const index = node.index * 2;
			if(index > selectedFilterGroupArray.length){
				selectedFilterGroupArray.push(selectedFilterGroupArray[1] ?? node.condition);
				let bottom = copiedFilter;
				if(path.length > 0){
					path.reverse().forEach(subNode => {
						bottom = [bottom, subNode.condition];
					});
				}
				selectedFilterGroupArray.push([bottom, node.condition]);
				selectedFilterGroupArray = null;
				break;
			} else {
				selectedFilterGroupArray = selectedFilterGroupArray[index];
			}
		}
		if(selectedFilterGroupArray){
			if(selectedFilterGroupArray[selectedFilterGroupArray.length - 1] !== groupType.toLocaleLowerCase()){
				selectedFilterGroupArray.push(groupType.toLocaleLowerCase());
			}

			selectedFilterGroupArray.push(this.bbHelper.getCopiedFilter());
			if(selectedFilterGroupArray.length === 2){
				selectedFilterGroupArray.reverse();
			}
		} else if(!this.filterBuilderObject) {
			this.filterBuilderObject = this.bbHelper.getCopiedFilter();
		}

		this.filterBuilderObject = this.helperService.deepCopyTwoPointO(this.filterBuilderObject);
		this.cd.detectChanges();
		this.toast.success('Filter condition pasted.');
	}

	isElementInCondition(className: string){
		return className.includes('field') || className.includes('operation') || className.includes('value');
	}

	convertOperationWordToSymbol(name: string){
		return this.filterBuilder.customOperations.find(operation => operation.caption === name).name;
	}

	getPathToElement(element: any): {index: number, condition: string}[] {
		const path = [];
		let currentNode = this.traverseUpToGroup(element);
		while(this.traverseUpToGroup(currentNode.parentElement))
		{
			path.push({
				index: [...currentNode.parentElement.children].indexOf(currentNode),
				condition: currentNode.innerText.split('\n')[0].toLocaleLowerCase()
			});
			currentNode = this.traverseUpToGroup(currentNode.parentElement);
		}
		return path;
	}

	traverseUpToGroup(leafElement: any){
		if(!leafElement){
			return null;
		}
		if(leafElement.className.endsWith('dx-filterbuilder-group')){
			return leafElement;
		} else if(leafElement.className.includes('dx-widget')) {
			return null;
		} else {
			return this.traverseUpToGroup(leafElement.parentElement);
		}
	}

	getContentFromElement(element: any): any {
		const parentGroup = this.traverseUpToGroup(element);
		if(!parentGroup){
			return;
		}
		if([...parentGroup.children].some(child => child.className.includes('content'))){
			const operation = parentGroup.firstChild.innerText.toLocaleLowerCase();
			const mappedChildren = [...parentGroup.lastChild.children].map(child => this.getContentFromElement(child));
			const arrayWithAndsOrs = [];
			if(mappedChildren.length === 0){
				arrayWithAndsOrs.push(operation);
			} else {
				mappedChildren.forEach(child => {
					arrayWithAndsOrs.push(child);
					arrayWithAndsOrs.push(operation);
				});
				if(!(mappedChildren[0]?.length === 1) && arrayWithAndsOrs.length > 2){
					arrayWithAndsOrs.pop();
				}
			}
			return arrayWithAndsOrs;
		} else {
			return this.getConditionFromGroupSubItems(parentGroup.firstChild);
		}
	}

	getConditionFromGroupSubItems(parentItem: any){
		const childList = [...parentItem.children];
		const rawField = childList.find(child => child.className.includes('field')).innerText;
		const rawOperation = childList.find(child => child.className.includes('operation')).innerText;
		const rawValue = childList.find(child => child.className.includes('value'))?.innerText;
		let splitFilterName: string[] = rawField.split('.');
		if(['Main Source', 'Aux Source'].includes(splitFilterName[0])){
			splitFilterName = splitFilterName.slice(1);
		}
		let friendlyFieldName: string = splitFilterName.slice(1).join('.');
		if(['Quantity', 'Date', 'Text'].includes(splitFilterName[0])){
			friendlyFieldName = splitFilterName.slice(2).join('.');
		}

		const convertedField = this.filterBuilder.fields.find(filterField => filterField.caption === friendlyFieldName);

		const convertedOperation = this.convertOperationWordToSymbol(rawOperation);

		let convertedValue = rawValue;
		if(convertedValue){
			if(convertedOperation === 'between'){
				convertedValue = convertedValue.split('–');
			} else if(['in', 'not in'].includes(convertedOperation) && convertedValue.includes('|')){
				convertedValue = convertedValue.split('|').map(splitVal => {
					if(convertedField.lookup){
						return this.inverseLookup(convertedField.lookup, splitVal);
					}
				});
			} else {
				const possibleValue = this.inverseLookup(convertedField.lookup ?? this.validDataColumns, convertedValue);
				convertedValue = possibleValue ?? convertedValue;
			}

			return [convertedField.dataField, convertedOperation, convertedValue];
		} else {
			return [convertedField.dataField, convertedOperation];
		}
	}

	inverseLookup(lookup: any, val: string){
		const lookupResult = lookup.dataSource.find(result => result[lookup.displayExpr] === val);
		return lookupResult ? lookupResult[lookup.valueExpr] : null;
	}

	saveInternalData(): Promise<void> {
		this.filterBuilderObject = this.trimHangingConditionals(this.filterBuilderObject ?? []);
		return Promise.resolve();
	}

	trimHangingConditionals(filterObj: any[]): any {
		if(typeof(filterObj) !== 'object'){
			return filterObj;
		}
		if(['and', 'or'].includes(filterObj[filterObj.length -1])){
			return this.trimHangingConditionals(filterObj[0]);
		} else {
			filterObj = filterObj.map((item) => this.trimHangingConditionals(item));
			let index = 0;
			while(index < filterObj.length - 1){
				if(['and', 'or'].includes(filterObj[index])){
					filterObj = filterObj.splice(index - 1, 2);
				} else {
					index += 2;
				}
			}
			if(['and', 'or'].includes(filterObj[filterObj.length -1])){
				return this.trimHangingConditionals(filterObj[0]);
			}
			return filterObj;
		}
	}
}

import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';

export class CoreEditorOptions {
    valueExpr: string | ((val) => string);
    grouped: boolean;
    displayExpr: string | ((val: any) => string);
    searchEnabled: boolean;
    groupTemplate: string;
    buttons: any[];
    items: any[];
    dataSource: DataSource;
    readOnly: boolean;
    disabled: boolean;
    value: any;
    showClearButton: boolean = false;
    onValueChanged: (e: any) => void;
    onChange: (e: any) => void;
    onFocusIn: (e: any) => void;
    valueChangeEvent: string;
    displayFormat: any;
    type: string;

    textBox(onValueChanged?: (e) => void, buttons?: any[], readOnly?: boolean, valueChangeEvent: string = 'change') {
        this.value = '';
        this.buttons = buttons;
        this.onValueChanged = onValueChanged;
        this.readOnly = readOnly;
        this.valueChangeEvent = valueChangeEvent;
        return this;
    }

    selectBox(
        items: any[],
        onValueChanged?: (e) => void,
        buttons?: any[],
        displayExpr?: string | ((val: any) => string),
        valueExpr?: string | ((val) => string) | ((val) => any),
        readOnly?: boolean,
        value?: any
    ) {
        this.items = items;
        this.buttons = buttons;
        this.valueExpr = valueExpr;
        this.displayExpr = displayExpr;
        this.onValueChanged = onValueChanged;
        this.readOnly = readOnly;
        this.value = value;
        this.searchEnabled = true;
        return this;
    }

    groupedSelectBox(
        valueExpr: string,
        displayExpr: string,
        groupId: string,
        uniqueKey: string,
        dataItems: any[],
        buttons?: any[],
        groupTemplate?: string
    ) {
        this.displayExpr = displayExpr;
        this.valueExpr = valueExpr;
        this.grouped = true;
        this.buttons = buttons;
        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: dataItems,
                key: uniqueKey,
            }),
            group: groupId,
        });
        if (groupTemplate) {
            this.groupTemplate = groupTemplate;
        }
        return this;
    }

    enableSearch(searchEnabled: boolean) {
        this.searchEnabled = searchEnabled;
        return this;
    }

    dateBox(showClearButton: boolean = false, onValueChanged?: (e) => void, onChange?: (e) => void, type: string = 'date', displayFormat: any = null) {
        this.value = new Date(new Date().toDateString());
        this.onValueChanged = onValueChanged;
        this.onChange = onChange;
        this.readOnly = false;
        this.showClearButton = showClearButton;
        this.type = type;
        this.displayFormat = displayFormat;
        return this;
    }

    addOnFocusIn(onFocusIn: (e: any) => void): CoreEditorOptions {
        this.onFocusIn = onFocusIn;
        return this;
    }
}

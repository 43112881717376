import { Component, OnInit, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { DxSelectBoxComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'app-core-custom-editor-select',
  templateUrl: './core-custom-editor-select.component.html',
  styleUrls: ['./core-custom-editor-select.component.scss']
})
export class CoreCustomEditorSelectComponent implements AgEditorComponent {
  @ViewChild('input', { static: false }) input: DxSelectBoxComponent;

  cellEditorParams: ICellEditorParams;
  editorInitialized: boolean = false;
  contentInitialized: boolean = false;

  value: string;
  values: any[];
  data: any;

  constructor() { }

  getValue() {
    this.input.instance.blur();
    return this.value;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  agInit(params: ICellEditorParams): void {
    this.cellEditorParams = params;

    this.values = params['values'];
    this.values = this.values.sort((a, b) => a.name.localeCompare(b.name));
    this.values.forEach(value => {
      if (value.name === params.value) {
        this.value = value.id;
        return;
      }
    });

    // For large datasets, we use paging to improve dropdown loading speeds
    if (this.values.length > 500) {
      this.data = new DataSource({
        store: new ArrayStore({
          data: this.values,
          key: 'id',
        }),
        paginate: true,
        pageSize: 10
      });
    } else {
      this.data = this.values;
    }

    // This is in a setTimeout so that the initial value gets set before setting this to true
    setTimeout(() => {
      this.editorInitialized = true;
    }, 10);
  }

  onContentReady(e): void {
    if (!this.contentInitialized) {
      this.contentInitialized = true;
      setTimeout(() => {
        e.component.focus();
        e.component.open();
        const doc = document as any;
        const inputElement = doc.querySelector('app-core-custom-editor-select .dx-texteditor-input-container input.dx-texteditor-input');
        inputElement.select();
      }, 50);
    }
  }

  getSelectedDisplayValue(): string {
    return this.values?.find(x => x.id === this.value)?.name;
  }
}

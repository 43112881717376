<ng-template #fbTemplate let-props>
    <dx-filter-builder
        #filterBuilder
        [fields]="props.fields"
        [(value)]="props.value"
        [groupOperations]="['and']"
        [groupOperationDescriptions]="props.groupDescription"
        [customOperations]="customOperations"
        width="auto"
        maxGroupLevel="0"
        (onValueChanged)="removeConflictingOps($event)">
        <dx-tag-box
            *dxTemplate="let condition of 'tagBoxTemplate'"
            [value]="condition.value"
            [dataSource]="condition.field.lookup.dataSource"
            [maxDisplayedTags]="9"
            (onValueChanged)="condition.setValue($event.value)"
            [valueExpr]="condition.field.lookup.valueExpr"
            [displayExpr]="condition.field.lookup.displayExpr"
            [grouped]="isFieldGrouped(condition)"
            width="auto"
            showSelectionControls="true"
            [searchEnabled]="true"
            stylingMode="underlined">
        </dx-tag-box>
    </dx-filter-builder>
</ng-template>

<ng-container [ngSwitch]="propertyValue.property.friendlyName">
    <ng-container *ngSwitchCase="'Criteria Filter'">
        <ng-template *ngTemplateOutlet="fbTemplate; context: {$implicit: idProps}"></ng-template>
        <ng-template *ngTemplateOutlet="fbTemplate; context: {$implicit: qtyProps}"></ng-template>
        <ng-template *ngTemplateOutlet="fbTemplate; context: {$implicit: dateProps}"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'Account Filter'">
        <ng-template *ngTemplateOutlet="fbTemplate; context: {$implicit: acctProps}"></ng-template>
    </ng-container>
</ng-container>

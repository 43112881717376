import { CoreEventArguments } from './core-event-arguments';
import { RequiredProperty } from './required-property';

export class DevExpressDMLFunctions {
    dataChangesFunction: (e: CoreEventArguments) => void;
    isAddingAllowed: boolean = true;
    isUpdatingAllowed: boolean = true;
    isDeletingAllowed: boolean = true;
    requiredProperties: RequiredProperty[];

    constructor(dataChangesFunction: (e: CoreEventArguments) => void, parentThis: any, requiredProperties: RequiredProperty[],
        isAddingAllowed: boolean = true, isUpdatingAllowed: boolean = true, isDeletingAllowed: boolean = true) {
        this.dataChangesFunction = dataChangesFunction;
        this.requiredProperties = requiredProperties;
        this.isAddingAllowed = isAddingAllowed;
        this.isUpdatingAllowed = isUpdatingAllowed;
        this.isDeletingAllowed = isDeletingAllowed;

        if (dataChangesFunction && parent) {
            this.dataChangesFunction = this.dataChangesFunction.bind(parentThis);
        }
    }
}

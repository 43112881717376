import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DxButtonModule, DxTextBoxModule, DxTextAreaModule, DxPopupModule, DxResponsiveBoxModule,
    DxTreeViewModule, DxTooltipModule, DxDataGridModule, DxScrollViewModule
} from 'devextreme-angular';
import { FormulaBuilderPopupComponent } from './formula-builder-popup.component';
import { CoreComponentModule } from '../core-component.module';
import { CoreMultiLineStringInputModule } from '../core-multiline-string-input/core-multiline-string-input.component';
import { CoreStringInputModule } from '../core-string-input/core-string-input.component';

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxPopupModule,
    DxResponsiveBoxModule,
    DxTreeViewModule,
    DxTooltipModule,
    DxDataGridModule,
    DxScrollViewModule,
    CoreMultiLineStringInputModule,
    CoreStringInputModule,
  ],
    declarations: [FormulaBuilderPopupComponent],
    exports: [FormulaBuilderPopupComponent]
})
export class FormulaBuilderPopupModule { }

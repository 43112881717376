import { AccountFactorBatchChanges } from 'src/app/shared/models/account-factor-batch-changes';
import { HistSellerBatchChanges } from 'src/app/shared/models/hist-seller-batch-changes';
import { Seller } from 'src/app/shared/models/seller';
import { SellerImport } from 'src/app/shared/models/seller-import';

export class SellerBatchChangesContext {
    seller: Seller;
    sellerImports: SellerImport[];

    constructor(seller: Seller, sellerImports: SellerImport[]) {

        this.seller = seller;
        this.sellerImports = sellerImports;
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    DxBoxModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDrawerModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxFileUploaderModule,
    DxFormModule,
    DxListModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxResponsiveBoxModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxToolbarModule,
    DxTooltipModule,
    DxTreeListModule,
    DxValidatorModule,
    DxFilterBuilderModule,
    DxTagBoxModule,
    DxSwitchModule,
    DxButtonGroupModule,
    DxContextMenuModule,
    DxTextAreaModule,
    DxTabPanelModule
} from 'devextreme-angular';
import { SharedModule } from '../shared.module';
import { CoreGridComponent } from './core-data-grid/core-data-grid.component';
import { CoreDropdownComponent } from './core-dropdown/core-dropdown.component';
import { CorePopupComponent } from './core-popup/core-popup.component';
import { CoreTableDropDownComponent } from './core-table-drop-down/core-table-drop-down.component';
import { CoreTableSelectCellEditorComponent } from './core-table-select-cell-editor/core-table-select-cell-editor.component';
import { CoreValueListComponent } from './core-value-list/core-value-list.component';
import { CoreTreeListComponent } from './core-tree-list/core-tree-list.component';
import { CoreUtilityDrawerComponent } from './core-utility-drawer/core-utility-drawer.component';
import { CoreTagBoxComponent } from './core-tag-box/core-tag-box.component';
import { EmailResultsModule } from './email-results/email-results.component';
import { EmailSettingsModule } from './email-settings/email-settings.component';
import { CoreSimplePopupComponent } from './core-simple-popup/core-simple-popup.component';
import { CoreFormComponent } from './core-form/core-form.component';
import { CoreImportComponent } from './core-import/core-import.component';
import { SegmentFilterBuilderComponent } from './segment-filter-builder/segment-filter-builder.component';
import { BbPeriodRangeInputComponent } from './bb-period-range-input/bb-period-range-input.component';
import { ApplyToComponent } from './apply-to/apply-to.component';
import { BbAssignFieldInputComponent } from './bb-assign-field-input/bb-assign-field-input.component';
import { PeriodDropdownComponent } from './period-dropdown/period-dropdown.component';
import { TraceOutputComponent } from './trace-output/trace-output.component';
import { AgGridModule } from 'ag-grid-angular';
import { BbFieldGridComponent } from './bb-field-grid/bb-field-grid.component';
import { ReportingParametersComponent } from './reporting-parameters/reporting-parameters.component';
import { BbFilterBuilderComponent } from './bb-filter-builder/bb-filter-builder.component';
import { AttributePermissionsComponent } from './attribute-permissions/attribute-permissions.component';
import { BbUpdateXactionFieldInputComponent } from './bb-update-xaction-field-input/bb-update-xaction-field-input.component';
import { BbXactionMappingComponent } from './bb-xaction-mapping/bb-xaction-mapping.component';
import { BbUnionFieldInputComponent } from './bb-union-field-input/bb-union-field-input.component';
import { FormulaBuilderPopupModule } from './formula-builder-popup/formula-builder-popup.module';
import { BbSavedColumnsComponent } from './bb-saved-columns/bb-saved-columns.component';
import { BbUserDependenciesComponent } from './bb-user-dependencies/bb-user-dependencies.component';
import { ProcessLogGridComponent } from './process-log-grid/process-log-grid.component';
import { SideNavigationMenuModule } from './side-navigation-menu/side-navigation-menu.component';
import { NavBucketFoldersComponent } from './nav-bucket-folders/nav-bucket-folders.component';
import { NbChatModule, NbStatusService, NbFocusMonitor, NbLayoutModule, NbThemeService, NbThemeModule, NbButtonModule, NbIconModule } from '@nebular/theme';

@NgModule({
    imports: [
        CommonModule,
        DxBoxModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxDrawerModule,
        DxDropDownBoxModule,
        DxDropDownButtonModule,
        DxFileUploaderModule,
        DxFormModule,
        DxListModule,
        DxLoadPanelModule,
        DxLookupModule,
        DxNumberBoxModule,
        DxPopupModule,
        DxRadioGroupModule,
        DxResponsiveBoxModule,
        DxScrollViewModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxToolbarModule,
        DxTooltipModule,
        DxTreeListModule,
        DxValidatorModule,
        DxFilterBuilderModule,
        DxTagBoxModule,
        DxSwitchModule,
        DxButtonGroupModule,
        DxContextMenuModule,
        DxTabPanelModule,
        SharedModule,
        AgGridModule,
        NbChatModule.forRoot(),
        NbLayoutModule,
        NbThemeModule.forRoot(),
        NbButtonModule,
        NbIconModule,
        DxTooltipModule,
        SideNavigationMenuModule
    ],
    declarations: [
        CoreDropdownComponent,
        CoreGridComponent,
        CoreTreeListComponent,
        CorePopupComponent,
        CoreTableDropDownComponent,
        CoreTableSelectCellEditorComponent,
        CoreValueListComponent,
        CoreUtilityDrawerComponent,
        CoreSimplePopupComponent,
        CoreFormComponent,
        CoreImportComponent,
        CoreTagBoxComponent,
        SegmentFilterBuilderComponent,
        BbPeriodRangeInputComponent,
        ApplyToComponent,
        BbAssignFieldInputComponent,
        PeriodDropdownComponent,
        TraceOutputComponent,
        BbFieldGridComponent,
        ReportingParametersComponent,
        BbFilterBuilderComponent,
        AttributePermissionsComponent,
        BbUpdateXactionFieldInputComponent,
        BbXactionMappingComponent,
        BbUnionFieldInputComponent,
        BbSavedColumnsComponent,
        BbUserDependenciesComponent,
        ProcessLogGridComponent,
        NavBucketFoldersComponent,
    ],
    exports: [
        CoreDropdownComponent,
        CoreGridComponent,
        CoreTreeListComponent,
        CorePopupComponent,
        CoreTableDropDownComponent,
        CoreTableSelectCellEditorComponent,
        CoreValueListComponent,
        CoreUtilityDrawerComponent,
        CoreTagBoxComponent,
        EmailResultsModule,
        EmailSettingsModule,
        SegmentFilterBuilderComponent,
        CoreFormComponent,
        CoreImportComponent,
        BbPeriodRangeInputComponent,
        ApplyToComponent,
        BbAssignFieldInputComponent,
        PeriodDropdownComponent,
        TraceOutputComponent,
        BbFieldGridComponent,
        ReportingParametersComponent,
        BbFilterBuilderComponent,
        AttributePermissionsComponent,
        BbUpdateXactionFieldInputComponent,
        BbXactionMappingComponent,
        BbUnionFieldInputComponent,
        FormulaBuilderPopupModule,
        BbSavedColumnsComponent,
        BbUserDependenciesComponent,
        ProcessLogGridComponent,
        NavBucketFoldersComponent
    ],
    providers: [
        NbStatusService,
        NbFocusMonitor
    ]
})
export class CoreComponentModule { }

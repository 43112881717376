import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SideNavOuterToolbarComponent } from '../../layouts/side-nav-outer-toolbar/side-nav-outer-toolbar.component';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { ScreenService } from './screen.service';

@Injectable()
export class AppElementsService {
    headerElement: HTMLElement;
    headerComponent: HeaderComponent;
    sideNavOuterToolbar: SideNavOuterToolbarComponent;

    private headerElementHeightSource: BehaviorSubject<number> = new BehaviorSubject(null);
    private sideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private coreTableSelectValueChanged: BehaviorSubject<string> = new BehaviorSubject(null);
    private darkModeChanged: BehaviorSubject<boolean> = new BehaviorSubject(null);
    private distributionListChanged: BehaviorSubject<number> = new BehaviorSubject(null);

    constructor(private screen: ScreenService) {

    }

    getHeaderElementHeightChanges(): Observable<number> {
        return this.headerElementHeightSource.asObservable();
    }

    getSideNavOpenChanges(): Observable<boolean> {
        return this.sideNavOpen.asObservable();
    }

    getCoreTableSelectValueChangedChanges(): Observable<string> {
        return this.coreTableSelectValueChanged.asObservable();
    }

    getDarkModeChanges(): Observable<boolean> {
        return this.darkModeChanged.asObservable();
    }

    getDistributionListChanges(): Observable<number> {
        return this.distributionListChanged.asObservable();
    }

    provideHeaderElement(headerElement: HTMLElement) {
        this.headerElement = headerElement;
        const height = this.sideNavOuterToolbar.hasShaderBeenEnabled && this.screen.sizes['screen-large']
            ? 0 : this.headerElement.clientHeight;

        this.headerElementHeightSource.next(height);
    }

    provideSideNavOpenState(isOpen: boolean) {
        this.sideNavOpen.next(isOpen);
    }

    provideCoreTableSelectValueChanged(value: string) {
        this.coreTableSelectValueChanged.next(value);
    }

    provideDarkModeState(isDarkMode: boolean) {
        this.darkModeChanged.next(isDarkMode);
    }

    provideDistributionListChanged(uiViewId: number) {
        this.distributionListChanged.next(uiViewId);
    }
}

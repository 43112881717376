import { ChatBotUIObject } from '../chatbot-ui-object';
import { CoreBotResponse } from '../core-bot-response';
import { ChatMessage } from './chat-message';

export class AuditBotMessage extends ChatMessage {
    customMessageData: any;

    constructor(response: CoreBotResponse, uiComponents: ChatBotUIObject[], dataColumns: any){
        super();
        this.type = 'auditbot-response';
        this.customMessageData = {response: uiComponents, id: response.logId, dataColumns};
        this.user = {
            name: 'AuditBot',
            avatar: '../../../assets/images/AuditBotIcon.png'
        };
    }
}

import { Component, OnInit, Input, Output, ViewChild, EventEmitter, AfterViewInit, HostListener} from '@angular/core';
import { DxDropDownBoxComponent, DxTextBoxComponent } from 'devextreme-angular';
import { CoreDynamicCellEditorProps } from '../../models/core-dynamic-cell-editor-props';
import { HelperService } from '../../services/helper.service';
import { CoreGridComponent } from '../core-data-grid/core-data-grid.component';
import Validator from 'devextreme/ui/validator';
import { AppElementsService } from '../../services/app-element.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'core-table-select-cell-editor',
    templateUrl: './core-table-select-cell-editor.component.html',
    styleUrls: ['./core-table-select-cell-editor.component.scss']
})

export class CoreTableSelectCellEditorComponent implements OnInit, AfterViewInit {
    @Input() editorProps: CoreDynamicCellEditorProps;
    @Input() cellInfo: any;
    @Output() onValueChanged = new EventEmitter<any>();

    @ViewChild('dropDownBox', { static: false }) dropDownBox: DxDropDownBoxComponent;
    @ViewChild('textBox', { static: false }) textBox: DxTextBoxComponent;
    @ViewChild('coreDataGrid', { static: false }) coreDataGrid: CoreGridComponent;

    subscription: Subscription;

    constructor(private helperService: HelperService,
        private appElementsService: AppElementsService) {

    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        // If there is a non-default validation group assigned, then we set it programmatically here
        //   - since bracketed attribute value bindings cannot be used for validationGroup in the *.html file
        if (this.editorProps.validationGroup && this.editorProps.validationGroup !== 'forms') {
            const dropdownElement = this.dropDownBox.instance.element();
            const validator = Validator.getInstance(dropdownElement) as Validator;
            validator.option('validationGroup', this.editorProps.validationGroup);
        }

        if (this.editorProps?.mockCoreColumn?.columnTemplateProps?.gridProps?.isTableSelectCellEditorPopup) {
            // Optimizes keyboard navigation - this allows user to select a value from the grid popup using the ENTER key
            this.subscription = this.appElementsService.getCoreTableSelectValueChangedChanges().subscribe(value => {
                if (value === '{ESCAPE}') {
                    this.appElementsService.provideCoreTableSelectValueChanged(null);
                    setTimeout(() => {
                        this.textBox.instance.focus();
                    }, 100);
                } else if (value !== null && this.cellInfo.value !== value) {
                    this.updateGrid(value);
                    this.onValueChanged.emit({ value, closeEditor: true });
                    this.dropDownBox.opened = false;
                    this.appElementsService.provideCoreTableSelectValueChanged(null);
                }
            });
        }
    }

    onDropdownValueChanged(e: any, cellInfo: any, editorProps: CoreDynamicCellEditorProps): void {
        if (!this.helperService.isNullOrEmpty(e.previousValue) && (e.value === null || e.value === '')) {
            // This handles the clear button being clicked
            this.updateGrid(e.value);
            this.onValueChanged.emit({ value: e.value });
        }
    }

    onDropdownOpened(): void {
        setTimeout(() => {
            this.textBox.instance.focus();
        });

        setTimeout(() => {
            this.removeTabIndexFromGridHeader();
        }, 200);
    }

    onDropdownClosed(): void {
        const doc = document as any;
        const inputElement = doc.querySelector('.core-table-select-textbox input.dx-texteditor-input');
        // No matter how many times user clicks the textbox, we want the dropdown to be open
        if (inputElement === document.activeElement) {
            this.dropDownBox.opened = true;
        }
    }

    onTextBoxValueChanged(e: any): void {
        this.updateGrid(e.value);
    }

    onKeyUp(e: any): void {
        if (!this.dropDownBox.opened) {
            this.dropDownBox.opened = true;
        }

        setTimeout(() => {
            this.removeTabIndexFromGridHeader();
        }, 100);
    }

    updateGrid(searchText: string): void {
        if (this.coreDataGrid?.grid) {
            const state = this.coreDataGrid.grid.instance.state();
            state.searchText = searchText;
            this.coreDataGrid.grid.instance.state(state);
        }
    }

    onEnterKey(e: any): void {
        const filteredData = this.coreDataGrid.grid.instance.getDataSource().items();
        if (filteredData.length === 1) {
            const value = filteredData[0][this.editorProps.mockCoreColumn.dataField];
            this.onValueChanged.emit({ value, closeEditor: true });
            this.dropDownBox.opened = false;
        }
    }

    hasValue() {
        if(this.cellInfo?.value === undefined || this.cellInfo?.value === null || this.cellInfo?.value === ''){
            return false;
        } else {
            return true;
        }
    }

    removeTabIndexFromGridHeader(): void {
        if (this.editorProps?.mockCoreColumn?.columnTemplateProps?.gridProps?.isTableSelectCellEditorPopup) {
            const doc = document as any;
            const headerFilterElements = doc.querySelectorAll('#dynamicCellDropDownGridPropsId .dx-datagrid-headers [tabIndex]');
            headerFilterElements.forEach(element => {
                element.removeAttribute('tabIndex');
            });
        }
    }

    isValidMethod = () => this.hasValue() || !this.editorProps.isRequired;
}

import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DxPopupComponent, DxScrollViewComponent, DxSelectBoxComponent } from 'devextreme-angular';
import { on } from 'devextreme/events';
import { Observable, of } from 'rxjs';
import { CoreInputEditorType, RowSelectionModes } from '../../constants/dev-extreme-enums';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CorePopupProperties } from '../../models/core-popup-properties';
import { CorePopupStep } from '../../models/core-popup-step';
import { FileProcessingService } from '../../services/file-processing.service';
import { CoreGridComponent } from '../core-data-grid/core-data-grid.component';
import { CoreDropdownComponent } from '../core-dropdown/core-dropdown.component';


@Component({
    selector: 'core-popup',
    templateUrl: './core-popup.component.html',
    styleUrls: ['./core-popup.component.scss']
})
export class CorePopupComponent implements OnChanges {
    @ViewChild('corePopupComponent', {static: false}) corePopupComponent: DxPopupComponent;
    @ViewChild('corePopupSelectBox', {static: false}) corePopupSelectBox: DxSelectBoxComponent;
    @ViewChild('popupScrollView', {static: false}) set setPopupScrollView(popupScrollView: DxScrollViewComponent) {
        if (popupScrollView) {
            this.popupScrollView = popupScrollView;
        }
    }
    @ViewChild('popupGrid', { static: false }) set setPopupGrid(popupGrid: CoreGridComponent) {
        if (popupGrid && this.steps && this.steps[this.stepIndex] && this.steps[this.stepIndex].dataGrid) {
            this.popupGrid = popupGrid;
            this.setGridHeight();
        } else {
            this.popupGrid = undefined;
        }
    }

    @ViewChild('popupCoreDropDown', {static: false}) set setPopupCoreDropDown(popupCoreDropdown: CoreDropdownComponent) {
        if (popupCoreDropdown && this.steps && this.steps[this.stepIndex] && this.steps[this.stepIndex].coreDropdown) {
            this.popupCoreDropdown = popupCoreDropdown;
        } else {
            this.popupCoreDropdown = undefined;
        }
    }

    @Input() props: CorePopupProperties;
    @Input() steps: CorePopupStep[];
    @Output() stepsChange = new EventEmitter<CorePopupStep[]>();
    @Output() onClosing = new EventEmitter();

    popupGrid: CoreGridComponent;
    popupCoreDropdown: CoreDropdownComponent;
    popupScrollView: DxScrollViewComponent;

    stepIndex: number = 0;
    value: any[] = [];
    requestHeaders: any = {};
    isReadOnly: boolean = false;
    htmlTemplateEditorType = CoreInputEditorType;
    autoScrollDelay: number = 0;
    partiallySelectedListItemClass: string = 'dx-list-item-partially-selected';

    cancelClicked: Observable<boolean>;
    constructor(private fileProcessingService: FileProcessingService) {

    }
    get submitButtonOptions(): any {
        return {
            text: this.steps[this.stepIndex].okClickText,
            useSubmitBehavior: true,
            validationGroup: 'formData',
            elementAttr: {class:'core-popup-form-button dx-button-default'},
            disabled: !this.steps[this.stepIndex].isOkClickEnabled
        };
    }

    get cancelButtonOptions(): any {
        return {
            text: this.steps[this.stepIndex].cancelClickText,
            onClick: this.cancelClick,
            elementAttr: {class:'core-popup-form-button'}
        };
    }
    ngOnChanges() {
        // handles adding headers to file upload Post request when first step contains the uploader
        this.checkIfStepRequiresHeaders();
    }

    okClick(event: any) {
        this.cancelClicked = of(false);
        if (this.steps[this.stepIndex].isOkClickFunction) {
            this.steps[this.stepIndex].okClickFunction(this.steps[this.stepIndex]);
        }

        if (this.steps[this.stepIndex].uploadProps && this.steps[this.stepIndex].uploadProps.visible
            && this.steps[this.stepIndex].uploadProps.okClickPassesFile) {
            this.steps[this.stepIndex].uploadProps.okClickFunctionFile(event, this.steps[this.stepIndex]);
        }

        if (!this.closePopup((this.steps.length - 1 === this.stepIndex))) {
            this.adjustStepIndex();
        }
    }

    cancelClick = (event: any) => {
        const currStep = this.steps[this.stepIndex];
        this.cancelClicked = of(true);
        if (this.steps[this.stepIndex].isCancelClickFunction) {
            this.steps[this.stepIndex].cancelClickFunction(this.steps[this.stepIndex], this);
        }

        if (!this.closePopup(!currStep.doesCancelProceed && !currStep.backNavigationEnabled)) {
            this.adjustStepIndex(false);
        }
    };

    onHiding(event: any) {
        this.closePopup(true);
    }

    onResizeEnd(event: any) {
        this.setGridHeight();
    }

    adjustStepIndex(isOkClick: boolean = true) {
        if (this.stepIndex < (this.steps.length - 1) && (isOkClick || this.steps[this.stepIndex].doesCancelProceed)) {
            this.stepIndex ++;
        } else if (!isOkClick && this.stepIndex > 0 && this.steps[this.stepIndex].backNavigationEnabled) {
            this.stepIndex --;
        }

        this.checkIfStepRequiresHeaders();
    }

    setGridHeight() {
        if (this.popupGrid) {
            const gridElement = document.getElementById(this.popupGrid.props.gridId);
            const contentWrapper = document.getElementById('corePopupContentWrapperId');
            const newHeight = contentWrapper.clientHeight - 10;
            gridElement.style.maxHeight = newHeight + 'px';
            this.popupGrid.repaint();
        }
    }

    onScroll(e) {
        this.autoScrollDelay = e.reachedBottom ? 0 : 30000;
    }

    setScrollHeightToBottom() {
        if (!this.autoScrollDelay) {
            const scroller = this.popupScrollView.instance;
            const totalHeight = scroller?.scrollHeight();
            scroller?.scrollTo(totalHeight);
        } else {
            setTimeout(() => this.autoScrollDelay = 0, this.autoScrollDelay);
        }
    }

    closePopup(performClose: boolean): boolean {
        if (performClose) {
            this.props.visible = false;
            this.stepIndex = 0;
            this.value = [];
            this.isReadOnly = false;
            this.changePopupPurpose('');
            this.onClosing.emit();
        }

        return !this.props.visible;
    }

    changeStepValueByIndex(newMessage: string, index: number, fileTypeId: number = 0) {
        if (index < this.steps.length) {
            this.steps[index].message = newMessage;
            this.steps[index].uploadProps.fileTypeId = fileTypeId;
        }
    }

    changePopupSize(newHeight: string, newWidth: string) {
        if (newHeight !== null) {
            this.props.height = newHeight;
        }

        if (newWidth !== null) {
            this.props.width = newWidth;
        }
    }

    areUploadsDisabled(e: any) {
        if (this.props.isReadOnlyAfterFileUpload) {
            if (e.component._files.some(file => file.isValid())) {
                this.isReadOnly = true;
            }
        }
    }

    checkIfStepRequiresHeaders() {
        if (this.steps && this.steps[this.stepIndex] && this.steps[this.stepIndex].uploadProps
            && this.steps[this.stepIndex].uploadProps.visible) {
            this.requestHeaders = this.fileProcessingService.getFileImportHeaders(this.steps[this.stepIndex].uploadProps.fileTypeId);
        }
    }

    onfileUploadCompleted(e: any) {
        const fileId = e.request.getResponseHeader('file-guid');
        this.steps[this.stepIndex].uploadProps.importedFileIds.push(fileId);
    }

    changeStepOkButtonText(newText: string, stepIndex: number) {
        this.steps[stepIndex].okClickText = newText;
    }

    selectBoxOptionChanged(event: any, index: number) {
        if (event && event.value !== null && event.value !== undefined && this.steps[this.stepIndex].itemsProps[index]
            && event.value[this.steps[this.stepIndex].itemsProps[index].valueExpr] !== null
            && event.value[this.steps[this.stepIndex].itemsProps[index].valueExpr] !== undefined) {

            const newValue = event.value[this.steps[this.stepIndex].itemsProps[index].valueExpr];

            if (this.steps[this.stepIndex].itemsProps[index].selectedValue !== newValue) {
                this.steps[this.stepIndex].itemsProps[index].selectedValue = newValue;
            }

            if (this.steps[this.stepIndex].itemsProps[index].onSelectionChangedFunction) {
                this.steps[this.stepIndex].itemsProps[index].onSelectionChangedFunction(new CoreEventArguments(event, null, this), index);
            }
        }
    }

    listSelectionChanged(event: any, index: number): void {
        if (this.steps[this.stepIndex].itemsProps[index].onSelectionChangedFunction) {
            this.steps[this.stepIndex].itemsProps[index].onSelectionChangedFunction(new CoreEventArguments(event, null, this), index);
        }

        if (event.addedItems?.length || event.removedItems?.length) {
            this.updateListItemCheckboxes();
        }
    }

    editorBoxOptionChanged(event: any, index: number): void {
        if (event && event.value !== null && event.value !== undefined && this.steps[this.stepIndex].itemsProps[index]) {
            const newValue = event.value;

            if (this.steps[this.stepIndex].itemsProps[index].selectedValue !== newValue) {
                this.steps[this.stepIndex].itemsProps[index].selectedValue = newValue;
            }

            if (this.steps[this.stepIndex].itemsProps[index].onSelectionChangedFunction) {
                this.steps[this.stepIndex].itemsProps[index].onSelectionChangedFunction(new CoreEventArguments(event, null, this), index);
            }
        }
    }

    // prevents list items from checking and unchecking when the text is clicked and mode is multiple
    onListItemContentReady(e: any, index: number): void {
        if (this.steps[this.stepIndex].itemsProps[index].selectionMode === RowSelectionModes.Multiple) {

            this.updateListItemCheckboxes();

            const itemContent = e.element.querySelectorAll('.dx-item-content');
            on(itemContent, 'dxclick',
                (event, extraParameters): void => {
                    if (this.steps[this.stepIndex].itemsProps[index].isShadingRetainedList) {
                        const listElements = e.element.querySelectorAll('.dx-item-content') as NodeList;
                        listElements.forEach(node => {
                            if (node['innerHTML'] === event.currentTarget.innerHTML) {
                                node['classList'].add('core-state-focused');
                            } else {
                                node['classList'].remove('core-state-focused');
                            }
                        });
                    }

                    if (this.steps[this.stepIndex].itemsProps[index].areTextClicksIgnoredForMultiSelectLists) {
                        event.stopPropagation();
                        const itemProp = this.steps[this.stepIndex].itemsProps[index];
                        const clickedItem = itemProp.dataset.find(x => x[itemProp.displayExpr] === event.currentTarget.innerHTML);
                        this.listSelectionChanged({ type: 'noSelectionChange', item: clickedItem}, index);
                    }
                }
            );
        }
    }

    updateListItemCheckboxes(): void {
        this.steps[this.stepIndex].itemsProps.forEach(props => {
            if (props.partialSelectionEnabled) {
                const selectedItemTextValues = [];
                props.partiallySelectedItemKeys.forEach(key => {
                    const textValue = props.dataset.find(x => x.id === key)?.friendlyName;
                    if (textValue) {
                        selectedItemTextValues.push(textValue);
                    }
                });

                const doc = document as any;
                const listElements = doc.querySelectorAll(String.format('#{0} .dx-item-content', props.uniqueId)) as NodeList;
                listElements.forEach(node => {
                    if (selectedItemTextValues.includes(node['textContent'])) {
                        if (!node.parentElement['classList'].contains(this.partiallySelectedListItemClass)) {
                            node.parentElement['classList'].add(this.partiallySelectedListItemClass);
                        }
                    } else {
                        if (node.parentElement['classList'].contains(this.partiallySelectedListItemClass)) {
                            node.parentElement['classList'].remove(this.partiallySelectedListItemClass);
                        }
                    }
                });
            }
        });
    }

    okClickDisabled(): boolean {
        if (this.popupGrid && this.popupGrid.grid && this.popupGrid.grid.instance && this.popupGrid.grid.instance.hasEditData) {
            return this.popupGrid.grid.instance.hasEditData();
        } else if (this.steps[this.stepIndex].uploadProps && this.steps[this.stepIndex].uploadProps.importedFileIds) {
           return this.steps[this.stepIndex].uploadProps && this.steps[this.stepIndex].uploadProps.importedFileIds.length === 0;
        }

        return false;
    }

    cancelClickDisabled(): boolean {
        if (this.steps[this.stepIndex].cancelDisabled) {
            return true;
        }

        if (this.steps[this.stepIndex].uploadProps && this.steps[this.stepIndex].uploadProps.importedFileIds) {
            return this.steps[this.stepIndex].uploadProps && this.steps[this.stepIndex].uploadProps.importedFileIds.length > 0;
        }
    }

    changePopupPurpose(title: string, height?: string, width?: string): void {
        this.props.title = title;
        this.steps = [];
        this.stepsChange.emit(this.steps);
        if(height || width) {
            this.changePopupSize(height, width);
        }
    }

    onAddFormSubmit(event: any) {
        this.okClick(event);
        // prevents page refresh after form submission
        return false;
    }
}

import { CoreInputEditorType, RowSelectionModes } from '../constants/dev-extreme-enums';
import { CoreColumn } from './core-column';
import { GridProps } from './core-data-grid-properties';
import { CoreEventArguments } from './core-event-arguments';
import { CoreFormFieldProperties } from './core-form-field-properties';
import { DevExpressDropdownOptions } from './dev-express-dropdown-options';

export class CoreDynamicInputProperties extends CoreFormFieldProperties {
    uniqueId: string = '';
    dropDownOptions: DevExpressDropdownOptions;
    dropDownGridColumns: CoreColumn[];
    dropDownGridProps: GridProps;
    isSingleLineInput: boolean;
    type: string;
    format: any;
    leftPad: string;
    height: string | number;
    areSelectionControlsShown: boolean = true;
    selectionMode: RowSelectionModes;
    selectedItemKeys: any[];
    partiallySelectedItemKeys: any[];
    partialSelectionEnabled: boolean = false;
    areTextClicksIgnoredForMultiSelectLists: boolean = true;
    isShadingRetainedList: boolean = true;
    onSelectionChangedFunction: (e: CoreEventArguments, index?: number) => void;

    constructor(label: string, isRequired: boolean, dataset: any, displayExpr: string, valueExpr: string, selectedValue?: any,
        isSingleLineInput: boolean = true) {

        super();
        this.createAll(label, null, isRequired, null, dataset, displayExpr, valueExpr, selectedValue);
        this.isSingleLineInput = isSingleLineInput;
    }

    convertToTextBox(selectedValue?: any, parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): void {
        this.selectedValue = selectedValue;
        this.createForTextBox(parentThis, onSelectionChangedFunction);
    }

    createForTextBox(parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): CoreDynamicInputProperties {
        this.editorType = CoreInputEditorType.TextBox;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);

        return this;
    }

    convertToNumberBox(format: string, selectedValue?: any, parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): void {
        this.format = format;
        this.selectedValue = selectedValue;
        this.createForNumberBox(parentThis, onSelectionChangedFunction);
    }

    createForNumberBox(parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): CoreDynamicInputProperties {
        this.editorType = CoreInputEditorType.NumberBox;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);

        return this;
    }

    convertToDateBox(format: any, isDateTime: boolean, selectedValue?: any, parentThis?: any,
        onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): void {

        this.type = isDateTime ? 'datetime' : 'date';

        this.format = format;
        this.selectedValue = selectedValue;
        this.createForDateBox(parentThis, onSelectionChangedFunction);
    }

    createForDateBox(parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): CoreDynamicInputProperties {
        this.editorType = CoreInputEditorType.DateBox;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);

        return this;
    }

    convertToSelectBox(dataset: any, displayExpr: string, valueExpr: string, selectedValue?: any, parentThis?: any,
        onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): void {

        this.dataset = dataset;
        this.displayExpr = displayExpr;
        this.valueExpr = valueExpr;
        this.selectedValue = selectedValue;
        this.createForSelectBox(parentThis, onSelectionChangedFunction);
    }

    createForSelectBox(parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): CoreDynamicInputProperties {
        this.editorType = CoreInputEditorType.SelectBox;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);

        return this;
    }

    convertToList(dataset: any[], displayExpr: string, valueExpr: string, height: string | number, showSelectionControls: boolean,
        selectionMode: RowSelectionModes, selectedItems?: any[], parentThis?: any,
        onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void,
        areTextClicksIgnoredForMultiSelectLists: boolean = true, isShadingRetainedList: boolean = true): void {

        this.dataset = dataset;
        this.displayExpr = displayExpr;
        this.valueExpr = valueExpr;
        this.createForList(height, showSelectionControls, selectionMode, selectedItems, parentThis, onSelectionChangedFunction, areTextClicksIgnoredForMultiSelectLists);
    }

    createForList(height: string | number, showSelectionControls: boolean, selectionMode: RowSelectionModes, selectedItemKeys?: any[],
        parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void,
        areTextClicksIgnoredForMultiSelectLists: boolean = true, isShadingRetainedList: boolean = true,
        partiallySelectedItemKeys?: any[], partialSelectionEnabled: boolean = false): CoreDynamicInputProperties {

        this.editorType = CoreInputEditorType.List;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);
        this.height = height;
        this.areSelectionControlsShown = showSelectionControls;
        this.selectionMode = selectionMode;
        this.selectedItemKeys = selectedItemKeys ? selectedItemKeys : [];
        this.partiallySelectedItemKeys = partiallySelectedItemKeys ? partiallySelectedItemKeys : [];
        this.partialSelectionEnabled = partialSelectionEnabled;
        this.areTextClicksIgnoredForMultiSelectLists = areTextClicksIgnoredForMultiSelectLists;
        this.isShadingRetainedList = isShadingRetainedList;

        return this;
    }

    convertToLookup(dataset: any, displayExpr: string, valueExpr: string, selectedValue?: any, parentThis?: any,
        onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): void {

        this.dataset = dataset;
        this.displayExpr = displayExpr;
        this.valueExpr = valueExpr;
        this.selectedValue = selectedValue;
        this.createForSelectBox(parentThis, onSelectionChangedFunction);
    }

    createForLookup(parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): CoreDynamicInputProperties {
        this.editorType = CoreInputEditorType.Lookup;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);

        return this;
    }

    convertToTableDropDown(gridColumns: CoreColumn[], gridHeight: string | number | (() => string | number), gridWidth: number,
        dataset: any, displayExpr: string, valueExpr: string, selectedValue?: any, parentThis?: any,
        onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void): void {

        this.dataset = dataset;
        this.displayExpr = displayExpr;
        this.valueExpr = valueExpr;
        this.selectedValue = selectedValue;
        this.createForTableDropDown(gridColumns, gridHeight, gridWidth, parentThis, onSelectionChangedFunction);
    }

    createForTableDropDown(gridColumns: CoreColumn[], gridHeight: string | number | (() => string | number), gridWidth: number,
        parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void, selectedValue?: any): CoreDynamicInputProperties {

        this.editorType = CoreInputEditorType.CoreTableDropDown;
        this.addOnSelectionChangedFunction(parentThis, onSelectionChangedFunction);
        this.dropDownGridProps = new GridProps('dynamicCellDropDownGridPropsId', null, false, true, false, false).setToSingleRowSelection();
        this.dropDownGridProps.keyColumn = this.valueExpr;
        this.dropDownGridProps.height = gridHeight;
        this.dropDownOptions = new DevExpressDropdownOptions(gridWidth);
        this.dropDownGridColumns = gridColumns;
        this.selectedValue = selectedValue;

        return this;
    }

    addOnSelectionChangedFunction(parentThis: any, onSelectionChangedFunction: (e: CoreEventArguments, index?: number) => void): CoreDynamicInputProperties {
        if (parentThis && onSelectionChangedFunction) {
            this.onSelectionChangedFunction = onSelectionChangedFunction.bind(parentThis);
        }

        return this;
    }

    addUniqueId(uniqueId: string): CoreDynamicInputProperties {
        this.uniqueId = uniqueId;
        return this;
    }

    setLeftPadding(leftPadding: string): void {
        this.leftPad = leftPadding;
    }

    private createAllFormFields(label: string, editorType: CoreInputEditorType, isRequired: boolean, colSpan?: number, dataset?: any,
        displayExpr?: string, valueExpr?: string, selectedValue?: any): CoreDynamicInputProperties {

        this.createAll(label, editorType, isRequired, colSpan, dataset, displayExpr, valueExpr, selectedValue);

        return this;
    }
}

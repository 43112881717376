export class BaseInputProperties<T> {
    readOnly: boolean;
    disabled: boolean;

    isOnChangedUsed: boolean;
    inputOnChangeCallBack: (properties: { componentThis: T, event: any }) => void;

    constructor(readOnly: boolean = false, disabled: boolean = false) {
        this.readOnly = readOnly;
        this.disabled = disabled;
    }
}

import { CoreLongBase } from './core-long-base';
import { BossSeller } from './boss-seller';
import { ParentSeller } from './parent-seller';
import { Seller } from './seller';
import { HistSellerAttribute } from './hist-seller-attribute';
import { Attribute } from './attribute';

export class HistSeller extends CoreLongBase {
    sellerId: number;
    beginDate: Date;
    endDate: Date;
    sellerTypeIdDepricated: number;
    jobTitleIdDepricated: number;
    bossSellerId: number;
    sellerGroupIdDepricated: number;
    regionIdDepricated: number;
    parentSellerId: number;
    bossSeller: BossSeller;
    parentSeller: ParentSeller;
    seller: Seller;
    attributes: Attribute[];
    // calculations: Calculation[] = [];
    histSellerAttributes: HistSellerAttribute[] = [];
    histSellerAttributesAuto: HistSellerAttribute[];

    static copy(originalHistSeller): HistSeller {
        const copiedHistSeller = new HistSeller();
        copiedHistSeller.id = originalHistSeller.id;
        copiedHistSeller.sellerId = originalHistSeller.sellerId;
        copiedHistSeller.beginDate = originalHistSeller.beginDate;
        copiedHistSeller.endDate = originalHistSeller.endDate;
        copiedHistSeller.sellerTypeIdDepricated = originalHistSeller.sellerTypeIdDepricated;
        copiedHistSeller.jobTitleIdDepricated = originalHistSeller.jobTitleIdDepricated;
        copiedHistSeller.bossSellerId = originalHistSeller.bossSellerId;
        copiedHistSeller.sellerGroupIdDepricated = originalHistSeller.sellerGroupIdDepricated;
        copiedHistSeller.regionIdDepricated = originalHistSeller.regionIdDepricated;
        copiedHistSeller.parentSellerId = originalHistSeller.parentSellerId;
        copiedHistSeller.bossSeller = originalHistSeller.bossSeller;
        copiedHistSeller.parentSeller = originalHistSeller.parentSeller;
        copiedHistSeller.seller = originalHistSeller.seller;
        if (originalHistSeller.attributes) {
            copiedHistSeller.attributes = [...originalHistSeller.attributes];
        }
        if (originalHistSeller.histSellerAttributes) {
            copiedHistSeller.histSellerAttributes = [...originalHistSeller.histSellerAttributes];
        }
        if (originalHistSeller.histSellerAttributesAuto) {
        copiedHistSeller.histSellerAttributes = [...originalHistSeller.histSellerAttributesAuto];
        }

        return copiedHistSeller;
    }

    // TODO:  REMOVE THE FOLLOWING
    // region: number;
    // seller_group: number;
    // seller_type: number;
    // job_title: number;
}

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Component, Injectable, NgModule } from '@angular/core';
import { DxPopupModule } from 'devextreme-angular';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'ag-grid-text-cell',
    template: `<span class="text-wrapper"> {{formattedCellContent}} </span>
            <button class="view-doc-button" type="button" (click)="onClick($event)">View</button>
            <div class="box-shadow"></div>
        <dx-popup #documentViewer [showCloseButton]="true" [(visible)]="isDocumentViewerVisible" [height]="600" [width]="800" title="Document Viewer">
        <div id="document-contents">
        <span [innerHTML]="formattedTextContent"></span>
        </div>
        </dx-popup>`
})

export class AgGridTextCellComponent implements ICellRendererAngularComp {
    params: any;
    content: string;
    formattedCellContent: string;
    formattedTextContent: SafeHtml;
    isDocumentViewerVisible: boolean = false;

    constructor(private domSanitizer: DomSanitizer
    ){
    }

    agInit(params: ICellRendererParams<any, any, any>): void {
        this.params = params;
        this.formatContent();
    }
    refresh(params: ICellRendererParams<any, any, any>): boolean {
        if (params.value !== this.params.value){
            this.params = params;
            this.formatContent();
        }
        return true;
    }

    onClick($event){
        this.isDocumentViewerVisible = true;
    }

    formatContent(): void {
        this.formattedCellContent = this.formatCellContent(this.params.value);
        this.formattedTextContent = this.formatTextContent(this.params.value);
    }

    formatTextContent(content: string){
        if (content){
            const formattedContent = content.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br>');
            return this.domSanitizer.bypassSecurityTrustHtml(formattedContent);
        }
    }

    formatCellContent(content: string){
        if (content){
            const formattedContent = content.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '');
            return formattedContent;
        }
    }
}

@NgModule({
    imports: [
        DxPopupModule
    ],
    declarations: [AgGridTextCellComponent],
    exports: [AgGridTextCellComponent]
})
export class AgGridTextCellModule {}

export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: 'home',
        disabled: false,
        topLevel: true,
        bucketFolder: false,
        parent: Object,
        items: [],
        sortOrder: 0
    }
];

<ng-container>
    <dx-data-grid
        [(dataSource)]="gridDatasource"
        [disabled]="!(columnsDatasource && columnsDatasource.dataSource.length > 0)"
        [allowColumnResizing]="true"
        [columnMinWidth]="40"
        (onSaving)="recordEdits($event)"
        (onRowValidating)="rowValidating($event)"
        (onContentReady)="checkForChanges()"
        (onToolbarPreparing)="filterToolbarItems($event)"
        (onInitNewRow)="setDefaultPaymentValue($event)">
        <dxi-column dataField="columnName" caption="Internal Name" sortOrder="asc" [setCellValue]="setFormatByColumnReference" [calculateSortValue]="sortSavedColumns" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="columnsDatasource['dataSource']"
                [displayExpr]="columnsDatasource['displayExpr']"
                [valueExpr]="columnsDatasource['valueExpr']">
            </dxo-lookup>
            <dxi-validation-rule type="custom" [validationCallback]="detectDuplicates" [reevaluate]="true"></dxi-validation-rule>
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="friendlyName" caption="External Name" dataType="string"></dxi-column>
        <dxi-column dataField="isPayment" caption="Is Payment" dataType="boolean" width="90px"></dxi-column>
        <dxi-column dataField="format" caption="Format" dataType="string" width="70px"></dxi-column>
        <dxi-column type="buttons" [width]="40"></dxi-column>
        <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
    </dx-data-grid>
    <div style="margin-top: 10px;" *ngIf="!(columnsDatasource && columnsDatasource.dataSource.length > 0)"><i>Rule has no head process, saved columns are uneditable, and the rule cannot be processed.</i></div>
</ng-container>

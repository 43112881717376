import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomPage } from '../models/custom-page';
import { CustomPageAttribute } from 'src/app/shared/models/custom-page-attribute';
import { NavMenuItem } from '../models/nav-menu-item';

@Injectable()
export class CustomPageService {
    private url = environment.apiEndpoint + '/custompage/';

    constructor(private http: HttpClient) { }

    getCustomPageAttributeMatrix(): Observable<CustomPageAttribute[]> {
        return this.http.get<CustomPageAttribute[]>(this.url + 'attributes');
    }

    setCustomPageAttributeMatrix(attributeMatrix: CustomPageAttribute[], customPageId: number): Observable<any> {
        return this.http.put<CustomPageAttribute[]>(this.url + 'attributes/' + customPageId, attributeMatrix);
    }

    upsertCustomPage(customPage: CustomPage){
        return this.http.put<number>(this.url, customPage);
    }

    deleteCustomPageById(customPageId: number){
        return this.http.get<any>(this.url + 'delete/' + customPageId);
    }

    getAllCustomPages(): Observable<CustomPage[]> {
        return this.http.get<CustomPage[]>(this.url);
    }

    getCustomPageNavItems(): Observable<any[]> {
        let customPageNavItems: Observable<any[]>;
        const customPageItemsCached: any[] = JSON.parse(sessionStorage.getItem('customNav'));

        if (customPageItemsCached) {
            customPageNavItems = of(customPageItemsCached);
        } else {
            customPageNavItems = this.http.get<CustomPage[]>(this.url).pipe(map(this.convertToNavAndSave));
        }
        return customPageNavItems.pipe(map(this.setParentRefs));
    }

    private convertToNavAndSave(customPageItems: CustomPage[]): NavMenuItem[] {
        const navItems = [];

        for (const item of customPageItems.filter(x => x.isParent)) {
            const navItem = new NavMenuItem( item.name, item.urlPath, item.iconName ? item.iconName : '', null, !item.isActive);

            navItem['id'] = item.id;
            navItems.push(navItem);
        }

        for (const item of customPageItems.filter(x => !x.isParent)) {
            const navItem = new NavMenuItem( item.name, item.urlPath, item.iconName ? item.iconName : '', null, !item.isActive);

            navItem['id'] = item.id;
            navItem.topLevel = !item.parentCustomPageId;

            const container = navItem.topLevel ? navItems : navItems.find(x => x.id === item.parentCustomPageId).items;
            if (container) {
                container.push(navItem);
            }
        }

        sessionStorage.setItem('customNav', JSON.stringify(navItems));
        return navItems;
    }

    private setParentRefs(navMenuItems) {
        navMenuItems.filter(x => x.items).forEach(parent => parent.items.forEach(child => child.parent = parent));
        return navMenuItems;
    }
}

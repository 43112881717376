import { AnimationBuilder } from '@angular/animations';
import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-community';
import { confirm } from 'devextreme/ui/dialog';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
	selector: 'app-core-filter-builder-tool-panel',
	templateUrl: './core-filter-builder-tool-panel.component.html',
	styleUrls: ['./core-filter-builder-tool-panel.component.scss']
})
export class CoreFilterBuilderToolPanelComponent implements IToolPanelAngularComp {
	gridApi: GridApi;
	columnApi: ColumnApi;
	filterBuilderValueChanged: (value, fromLayoutChange) => any;
	datasourceId: number;
	functionId: number;
	isProcessedOutput: boolean;
	masterMapping: any = {};
	columnGroupMapping: any = {};
	filterBuilderValue: any[] = [];
	filterBuilderFields: any[];
	popupVisible: boolean = false;
	placeholderFieldCaption: string = 'Select a field...';

	customOperations: any[] = [
		{
			name: 'in',
			caption: 'in',
			icon: 'check',
			editorTemplate: 'tagBoxTemplate',
			customizeText: (fieldInfo) => {
				const lookup = fieldInfo.field.lookup;
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource.staticItems.some(x => x['items']) ?
					[].concat(...(lookup.dataSource.staticItems.map(group => group['items']))) :
					lookup.dataSource.staticItems;
				return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
			}
		},
		{
			name: 'not in',
			caption: 'not in',
			icon: 'close',
			editorTemplate: 'tagBoxTemplate',
			customizeText: (fieldInfo) => {
				const lookup = fieldInfo.field.lookup;
				if(!lookup){
					return fieldInfo.value;
				}
				const dataSource = lookup.dataSource.staticItems.some(x => x['items']) ?
					[].concat(...(lookup.dataSource.staticItems.map(group => group['items']))) :
					lookup.dataSource.staticItems;
				return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
			}
		}
	];

	constructor(private helperService: HelperService,
		private toast: ToastrService) {}

	async agInit(params): Promise<void> {
		this.gridApi = params.api;
		this.columnApi = params.columnApi;
		this.filterBuilderValueChanged = params.filterBuilderValueChanged;
		this.datasourceId = params.datasourceId;
		this.functionId = params.functionId;
		this.isProcessedOutput = params.isProcessedOutput;
	}

	createFields(): void {
		const columnDefs = this.gridApi.getColumnDefs();
		this.filterBuilderFields = [];
		columnDefs.forEach(colDef => {
			const colId = colDef['colId'];
			if (colId && colId !== '0' && !colId.startsWith('ag-Grid-AutoColumn-')) {
				const dataType = this.getDataType(colDef);
				this.filterBuilderFields.push({
					caption: colDef.headerName,
					dataField: this.columnGroupMapping[colId],
					dataType,
					filterOperations: this.getFilterOperations(colDef, dataType),
					lookup: this.getLookupObject(colDef)
				});
			}
		});

		// Sort the fields alphabetically
		this.filterBuilderFields = this.filterBuilderFields.sort((a, b) => a.caption.localeCompare(b.caption));

		// Add placeholder field - if we don't add placeholder, first field is selected by default
		this.filterBuilderFields.unshift({
			caption: this.placeholderFieldCaption,
			dataField: 'PLACEHOLDER'
		});
	}

	getDataType(colDef: ColDef): string {
		let dataType = 'string';

		if (colDef.type.includes('qtyField')) {
			dataType = 'number';
		} else if (colDef.type.includes('dateField')) {
			dataType = 'date';
		} else if (colDef.type.includes('checkbox')) {
			dataType = 'boolean';
		}

		return dataType;
	}

	getFilterOperations(colDef: ColDef, dataType: string): string[] {
		let filterOperations = [];

		if (this.isLookupField(colDef)) {
			filterOperations = [ '=', '<>', 'in', 'not in', 'isblank', 'isnotblank' ];
		} else {
			switch (dataType) {
				case 'string':
					filterOperations = [ 'contains', 'notcontains', 'startswith', 'endswith', '=', '<>', 'isblank', 'isnotblank' ];
					break;
				case 'number':
				case 'date':
				case 'datetime':
					filterOperations = [ '=', '<>', '<', '>', '<=', '>=', 'between', 'isblank', 'isnotblank' ];
					break;
				case 'boolean':
					filterOperations = [ '=', '<>' ];
					break;
			}
		}

		return filterOperations;
	}

	isLookupField(colDef: ColDef): boolean {
		return (colDef.type.includes('sellerLookup') || colDef.type.includes('sellerImportLookup') || colDef.type.includes('ruleLookup')
			|| colDef.type.includes('tagLookup') || colDef.type.includes('datasourceLookup') || colDef.type.includes('datasourceMappingLookup')
			|| colDef.type.includes('seriesLookup') || colDef.type.includes('periodLookup') || colDef.type.includes('productGroupLookup')
			|| colDef.type.includes('productLookup') || colDef.type.includes('customerGroupLookup') || colDef.type.includes('customerLookup'));
	}

	getLookupObject(colDef: ColDef): any {
		// Get mapping
		let mapping = [];
		if (colDef.type.includes('sellerLookup')) {
			mapping = this.masterMapping['seller'];
		} else if (colDef.type.includes('sellerImportLookup')) {
			mapping = this.masterMapping['sellerImport'];
		} else if (colDef.type.includes('ruleLookup')) {
			mapping = this.masterMapping['rule'];
		} else if (colDef.type.includes('tagLookup')) {
			mapping = this.masterMapping[colDef.field];
		} else if (colDef.type.includes('datasourceLookup')) {
			mapping = this.masterMapping['datasource'];
		} else if (colDef.type.includes('datasourceMappingLookup')) {
			mapping = this.masterMapping['datasourceMapping'];
		} else if (colDef.type.includes('seriesLookup')) {
			mapping = this.masterMapping['series'];
		} else if (colDef.type.includes('periodLookup')) {
			mapping = this.masterMapping['period'];
		} else if (colDef.type.includes('productGroupLookup')) {
			mapping = this.masterMapping['productGroup'];
		} else if (colDef.type.includes('productLookup')) {
			mapping = this.masterMapping['product'];
		} else if (colDef.type.includes('customerGroupLookup')) {
			mapping = this.masterMapping['customerGroup'];
		} else if (colDef.type.includes('customerLookup')) {
			mapping = this.masterMapping['customer'];
		} else {
			// If not a lookup field, set the 'lookup' property as undefined
			return undefined;
		}

		// Gather data items from mapping
		let dataItems = [];
		Object.keys(mapping).forEach(id => {
			dataItems.push({
				id,
				name: mapping[id]
			});
		});

		// Sort the items by name
		dataItems = dataItems.sort((a, b) => a.name.localeCompare(b.name));

		// Construct data source
		const dataSource = this.helperService.createDatasourceFromStaticArray(dataItems, 'id');

		// Construct lookup object for filter builder
		const lookupObject = {
			allowClearing: false,
			dataSource,
			valueExpr: 'id',
			displayExpr: 'name'
		};
		return lookupObject;
	}

	onValueChanged(): void {
		this.filterBuilderValueChanged(this.filterBuilderValue, false);
	}

	closePopup(): void {
		this.popupVisible = false;
	}

	onContentReady(e: any): void {
		// Tweaking the way the fields display - we don't want to include the field group name (ex. we want 'Primary Agent', not 'Seller Fields.Primary Agent')
		//   We also want to set a special class for conditions that still have the placeholder field selected, so that we can hide the operation and value
		const filterBuilderItems = e.element.getElementsByClassName('dx-filterbuilder-item-field');
		if (filterBuilderItems.length > 0) {
			Array.prototype.forEach.call(filterBuilderItems, (el) => {
				let str = el.innerHTML;
				const placeholderClass = 'placeholder-condition';
				if (str === this.placeholderFieldCaption) {
					el.parentElement.classList.add(placeholderClass);
				} else if (str.includes('.')) {
					str = str.substring(str.indexOf('.') + 1);
					el.parentElement.classList.remove(placeholderClass);
				}
				el.innerHTML = str;
			});
		}
	}

	openFilterBuilderPopup(): void {
		this.popupVisible = true;
	}
}

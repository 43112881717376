import { Component, OnInit } from '@angular/core';
import { CoreFeature } from 'src/app/shared/constants/enums';
import { CoreColumn } from 'src/app/shared/models/core-column';
import { GridProps } from 'src/app/shared/models/core-data-grid-properties';
import { DateRange } from 'src/app/shared/models/date-range';
import { ProcessLogForAdminPopup } from 'src/app/shared/models/process-log-for-admin-popup';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { ProcessLogService } from 'src/app/shared/services/process-log.service';
import { HelperService } from '../../shared/services/helper.service';
@Component({
    selector: 'app-process-log-viewer',
    templateUrl: './process-log-viewer.component.html',
    styleUrls: ['./process-log-viewer.component.scss']
})

export class ProcessLogViewerComponent implements OnInit {
    startDate: Date = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
    endDate: Date = new Date((new Date()).setHours(23, 59, 55));
    dateRange: DateRange;

    permissionViewProcessLog: boolean;

    constructor(private helperService: HelperService,
        private processLogService: ProcessLogService,
        private permissionService: PermissionService) {
    }

    ngOnInit() {
        this.permissionViewProcessLog = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewLogs.toString());
        this.applyDateFilters();
    }

    applyDateFilters(): void {
        this.dateRange = new DateRange(this.helperService.dateToUTCISOString(this.startDate), this.helperService.dateToUTCISOString(this.endDate));
    }
}

import { ChatMessage } from './chat-message';

export class AuditBotReportRedirect extends ChatMessage {
    customMessageData: any;
    text: string;

    constructor(message: string, rules: any){
        super();
        this.type = 'auditbot-report-redirect';
        this.text = message;
        this.customMessageData = {rules, text: message};
        this.user = {
            name: 'AuditBot',
            avatar: '../../assets/images/AuditBotIcon.png'
        };
    }
}

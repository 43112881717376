<dx-select-box
    [dataSource]="redirTypes.dataSource"
    [displayExpr]="redirTypes.displayExpr"
    [valueExpr]="redirTypes.valueExpr"
    stylingMode="underlined"
    [(value)]="propertyValue.value.code">
</dx-select-box>
<br />
<ng-container [ngSwitch]="propertyValue.value.code">
    <ng-container *ngSwitchCase="'D'"></ng-container>
    <div *ngSwitchCase="'S'">
        <dx-data-grid class="core-component" height="200px" [dataSource]="propertyValue.value.jobTitle" (onInitNewRow)="onInitNewRow($event)">
            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-editing mode="cell" allowAdding="true" allowUpdating="true" allowDeleting="true"></dxo-editing>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxi-column dataField="dirCode" caption="Direction">
                <dxo-lookup
                    [dataSource]="[
                        { dirCode: 'U', name: 'Roll up to' },
                        { dirCode: 'D', name: 'Push down to' }
                    ]"
                    valueExpr="dirCode"
                    displayExpr="name">
                </dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="jobTitleId" caption="Position">
                <dxo-lookup [dataSource]="positions.dataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="ratio" caption="Split" dataType="number" format="percent">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
        </dx-data-grid>
    </div>
    <ng-container *ngSwitchCase="'T'"></ng-container>
    <dx-select-box
        *ngSwitchCase="'R'"
        [dataSource]="qtyFields.dataSource"
        [displayExpr]="qtyFields.displayExpr"
        [valueExpr]="qtyFields.valueExpr"
        stylingMode="underlined"
        [(value)]="propertyValue.value.field">
    </dx-select-box>
    <div *ngSwitchDefault>
        <dx-data-grid
            class="core-component"
            height="200px"
            [dataSource]="propertyValue.value.seller"
            (onInitNewRow)="onInitNewRow($event)"
            (onToolbarPreparing)="onAccountsToolbarPreparing($event)">
            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-editing mode="cell" allowAdding="true" allowUpdating="true" allowDeleting="true" [confirmDelete]="false"></dxo-editing>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxi-column dataField="sellerId" caption="Name">
                <dxo-lookup [dataSource]="accounts.dataSource" valueExpr="id" displayExpr="name"></dxo-lookup>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column dataField="ratio" caption="Split" dataType="number" format="percent">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-column>
        </dx-data-grid>
    </div>
</ng-container>

<ng-container *ngIf="sourceFieldDropdown?.dataSource.length && auxFieldDropdown?.dataSource.length">
    <dx-data-grid
        [(dataSource)]="internalDatasource"
        (onCellClick)="checkForChanges()"
        (onContentReady)="checkForChanges()"
        (onRowInserted)="emitValue()"
        (onRowUpdated)="emitValue()"
        (onRowRemoved)="emitValue()"
        (onToolbarPreparing)="filterToolbarItems($event)">
        <dxi-column dataField="sourceName" caption="Primary Source" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="sourceFieldDropdown['dataSource']"
                [displayExpr]="sourceFieldDropdown['displayExpr']"
                [valueExpr]="sourceFieldDropdown['valueExpr']"
                allowClearing="true">
            </dxo-lookup>
            <dxi-validation-rule
                type="custom"
                [validationCallback]="checkColumnsValid"
                message="A source or aux column must be selected.">
            </dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="auxName" caption="Aux Source" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="auxFieldDropdown['dataSource']"
                [displayExpr]="auxFieldDropdown['displayExpr']"
                [valueExpr]="auxFieldDropdown['valueExpr']"
                allowClearing="true">
            </dxo-lookup>
            <dxi-validation-rule
                type="custom"
                [validationCallback]="checkColumnsValid"
                message="A source or aux column must be selected.">
            </dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="alias"></dxi-column>
        <dxo-editing mode="batch" [allowUpdating]="isEditable" [allowDeleting]="isEditable" [allowAdding]="isEditable"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
    </dx-data-grid>
</ng-container>

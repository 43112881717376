import { CoreLongBase } from './core-long-base';
import { Seller } from './seller';
import { Attribute } from './attribute';

export class AccountFactor extends CoreLongBase {
    sellerId: number;
    variableId: number;
    beginDate: Date;
    endDate: Date;
    value: number;
    seller: Seller;
    variable: Attribute;

    static copy(originalAccountFactor): AccountFactor {
        const copiedAccountFactor = new AccountFactor();
        copiedAccountFactor.id = originalAccountFactor.id;
        copiedAccountFactor.sellerId = originalAccountFactor.sellerId;
        copiedAccountFactor.variableId = originalAccountFactor.variableId;
        copiedAccountFactor.beginDate = originalAccountFactor.beginDate;
        copiedAccountFactor.endDate = originalAccountFactor.endDate;
        copiedAccountFactor.value = originalAccountFactor.value;
        copiedAccountFactor.seller = originalAccountFactor.seller;
        copiedAccountFactor.variable = originalAccountFactor.variable;

        return copiedAccountFactor;
    }
}

<dx-drop-down-box
    #dropDownBox
    [dropDownOptions]="editorProps.mockCoreColumn.columnTemplateProps.templateOptions"
    [(value)]="cellInfo.value"
    [displayExpr]="editorProps.mockCoreColumn.columnType.lookupDisplayValue"
    [valueExpr]="editorProps.mockCoreColumn.columnType.lookupValue"
    [showClearButton]="!editorProps.isRequired"
    contentTemplate="contentTemplate"
    fieldTemplate="fieldTemplate"
    [showDropDownButton]="false"
    (onValueChanged)="onDropdownValueChanged($event, cellInfo, editorProps)"
    (onOpened)="onDropdownOpened()"
    (onClosed)="onDropdownClosed()">
    <div *dxTemplate="let e of 'contentTemplate'">
        <core-data-grid
            #coreDataGrid
            [dataSource]="editorProps.mockCoreColumn.columnType.lookupData"
            [columns]="editorProps.mockCoreColumn.columnTemplateProps.columns"
            [props]="editorProps.mockCoreColumn.columnTemplateProps.gridProps"
            [focusedRowKey]="cellInfo.value"
            [parentTemplate]="{
                cellInfo: cellInfo,
                parentTemplate: e,
                editorProps: editorProps
            }">
        </core-data-grid>
    </div>
    <div *dxTemplate="let e of 'fieldTemplate'">
        <dx-text-box
            #textBox
            class="core-table-select-textbox"
            [value]="cellInfo.value"
            valueChangeEvent="keyup"
            (onValueChanged)="onTextBoxValueChanged($event)"
            (onKeyUp)="onKeyUp($event)"
            (onEnterKey)="onEnterKey($event)">
        </dx-text-box>
    </div>
    <dx-validator validationGroup="forms">
        <dxi-validation-rule type="custom" [validationCallback]="isValidMethod" [reevaluate]="true"></dxi-validation-rule>
    </dx-validator>
</dx-drop-down-box>
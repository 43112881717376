import { dataSource } from 'devexpress-reporting/scopes/reporting-designer-controls-metadata';
import ArrayStore from 'devextreme/data/array_store';
import { CoreDataGridCellTemplates, CoreDataGridHeaderCellTemplates } from '../constants/dev-extreme-enums';
import { CoreColumn } from './core-column';

export class ColumnType {
    columnType?: string;
    columnTypeText?: string;
    clickFunction?: (eventArgs: any, e?: any) => void;
    itemClickFunction?: (eventArgs: any) => void;
    getDataFunction?: (column: CoreColumn, data: any) => any[];
    hintText?: string;
    lookupData?: any;
    lookupDisplayValue?: string;
    lookupValue?: string;
    allowClearing?: boolean = true;
    cellTemplateName: string;
    headerCellTemplateName: string;
    isTextFromRecord: boolean;
    recordTextProp: string;
    isCommandColumn: boolean = false;
    buttonIcon: string;
    buttonClass: string;
    buttonDisabled: boolean = false;
    defaultButtons: string[];
    noDataText: string;
    showIconDataField: string;
    icon: string;
    iconHoverText: string;
    isVisible: boolean | ((obj) => boolean) ;

    createButton(columnTypeText: string, clickFunction: (eventArgs: any) => void, parentThis: any, hintText?: string, buttonIcon?: string,
        buttonClass?: string, defaultButtons?: string[], isVisible?: boolean | ((obj) => boolean)): ColumnType {

        this.columnType = 'buttons';
        this.columnTypeText = columnTypeText;
        this.clickFunction = clickFunction.bind(parentThis);
        this.hintText = hintText;
        this.isCommandColumn = true;
        this.buttonIcon = buttonIcon;
        this.buttonClass = buttonClass;
        this.defaultButtons = defaultButtons;
        this.isVisible = isVisible;

        return this;
    }

    createDefaultButtons(defaultButtons?: string[]): ColumnType {
        this.columnType = 'buttons';
        this.columnTypeText = undefined;
        this.buttonIcon = undefined;
        this.isCommandColumn = true;
        this.defaultButtons = defaultButtons;
        return this;
    }

    createLookup(data: any, lookupValue: string, lookupDisplayValue: string, isPaginationUsed: boolean = false, allowClearing: boolean = true,
        keyColumnName: string = null, useRawDataset: boolean = false): ColumnType {

        this.columnType = 'lookup';
        this.lookupValue = lookupValue;
        this.lookupDisplayValue = lookupDisplayValue;
        this.allowClearing = allowClearing;

        if (useRawDataset) {
            this.lookupData = data;
        } else {
            this.lookupData = {
                store: new ArrayStore({
                    data,
                    key: keyColumnName
                })
            };
        }

        if (isPaginationUsed && data.length > 100) {
            this.lookupData.paginate = true;
            this.lookupData.pageSize = 100;
            this.lookupData.sort = lookupDisplayValue;
        }

        return this;
    }

    createDropDownButton(columnTypeText: string, hintText: string, isTextFromRecord: boolean, recordTextProp: string,
        lookupValue: string, lookupDisplayValue: string, parentThis: any, clickFunction: (eventArgs: any) => void,
        itemClickFunction: (eventArgs: any) => void, getDataFunction?: (column: CoreColumn, data: any) => any[], noDataText: string = 'No data to display'): ColumnType {

        this.cellTemplateName = CoreDataGridCellTemplates.DropDownButtonCellTemplate;
        this.columnTypeText = columnTypeText;
        this.hintText = hintText;
        this.isTextFromRecord = isTextFromRecord;
        this.recordTextProp = recordTextProp;
        this.lookupValue = lookupValue;
        this.lookupDisplayValue = lookupDisplayValue;
        this.clickFunction = clickFunction.bind(parentThis);
        this.itemClickFunction = itemClickFunction.bind(parentThis);
        this.getDataFunction = getDataFunction.bind(parentThis);
        this.noDataText = noDataText;

        return this;
    }

    createPlainButtonColumn(hintText: string, isTextFromPropertyofObj: boolean,
        objTextProp, lookupValue: string, lookupDisplayValue: string, parentThis: any, clickFunction: (eventArgs: any, e?: any) => void): ColumnType {

        this.cellTemplateName = CoreDataGridCellTemplates.PlainButtonCellTemplate;
        this.hintText = hintText;
        this.isTextFromRecord = isTextFromPropertyofObj;
        this.recordTextProp = objTextProp;
        this.lookupValue = lookupValue;
        this.lookupDisplayValue = lookupDisplayValue;
        this.clickFunction = clickFunction.bind(parentThis);

        return this;
    }

    createCustomButtonColumn(hintText: string, columnTypeText: string, buttonIcon: string, buttonClass: string, buttonDisabled: boolean, isTextFromPropertyofObj: boolean,
        objTextProp: string, lookupValue: string, lookupDisplayValue: string, parentThis: any, clickFunction: (eventArgs: any, e?: any) => void): ColumnType {

        this.cellTemplateName = CoreDataGridCellTemplates.CustomButtonCellTemplate;
        this.hintText = hintText;
        this.columnTypeText = columnTypeText;
        this.buttonIcon = buttonIcon;
        this.buttonClass = buttonClass;
        this.buttonDisabled = buttonDisabled;
        this.isTextFromRecord = isTextFromPropertyofObj;
        this.recordTextProp = objTextProp;
        this.lookupValue = lookupValue;
        this.lookupDisplayValue = lookupDisplayValue;
        this.clickFunction = clickFunction.bind(parentThis);

        return this;
    }

    createTextWithIconFlagTemplate(showIconDataField: string, icon: string, iconHoverText: string): ColumnType {
        this.cellTemplateName = CoreDataGridCellTemplates.TextWithIconFlagTemplate;
        this.showIconDataField = showIconDataField;
        this.icon = icon;
        this.iconHoverText = iconHoverText;

        return this;
    }

    createUndraggableHeaderCell(): ColumnType {
        this.headerCellTemplateName = CoreDataGridHeaderCellTemplates.UndraggableHeaderCellTemplate;

        return this;
    }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EmailContext } from '../models/contexts/email-context';
import { EmailLayout } from '../models/email-layout';
import { EmailPlaceholder } from '../models/email-placeholder';
import { SendEmailResult } from '../models/send-email-result';
import { EmailInternalContext } from '../models/contexts/email-internal-context';
import { map } from 'rxjs/operators';
import { reservedEmailLayoutIds } from '../constants/enums';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class EmailService {
    private emailURL = environment.apiEndpoint + '/email/';

    constructor(private http: HttpClient) {
    }

    getEmailLayouts(): Observable<EmailLayout[]> {
        return this.http.get<EmailLayout[]>(this.emailURL);
    }

    getEmailLayoutById(id: number): Observable<EmailLayout> {
        return this.http.get<EmailLayout>(this.emailURL + id.toString());
    }

    getEmailLayoutByName(name: string): Observable<EmailLayout> {
        return this.http.get<EmailLayout>(this.emailURL + 'get/' + name);
    }

    getEmailPlaceholders(): Observable<EmailPlaceholder[]> {
        return this.http.get<EmailPlaceholder[]>(this.emailURL + 'emailPlaceholders');
    }

    insertEmailLayout(emailLayout: EmailLayout): Observable<EmailLayout> {
        return this.http.post<EmailLayout>(this.emailURL + 'insert', emailLayout);
    }

    updateEmailLayout(emailLayout: EmailLayout): Observable<number> {
        return this.http.put<number>(this.emailURL + 'update', emailLayout);
    }

    deleteEmailLayout(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.emailURL + 'emailLayout/' + id.toString());
    }

    sendEmail(context: EmailContext): Observable<SendEmailResult[]> {
        return this.http.post<SendEmailResult[]>(this.emailURL + 'send', context);
    }

    sendInternalEmail(context: EmailInternalContext): Observable<string> {
        return this.http.post<string>(this.emailURL + 'sendInternal', context);
    }

    sendCredentials(context: EmailContext): Observable<string> {
        return this.http.post<string[]>(this.emailURL + 'sendCredentials', context).pipe(map(r => r.pop() || ''));
    }

    filterPlaceholders(placeholders: EmailPlaceholder[], emailLayout: EmailLayout): EmailPlaceholder[] {
        return placeholders.filter(x => !(x.valuePlaceholder === '[ACCOUNT_PASSWORD]' && emailLayout.id !== reservedEmailLayoutIds.Credentials) &&
            !(['[REPORT_NAME]', '[PERIOD_BEGIN_DATE]', '[PERIOD_END_DATE]'].includes(x.valuePlaceholder)
                && ![reservedEmailLayoutIds.Report, reservedEmailLayoutIds.Dashboard].includes(emailLayout.id)));
    }

    testEmailSetup(): Observable<string> {
        return this.http.get(this.emailURL + 'test', {responseType: 'text'});
    }
}

<div height="100%" class="file-processing-page">
    <core-popup #processingPopup [props]="processingPopupProps" [steps]="processingPopupSteps"></core-popup>
    <div class="period-info">
        <app-period-dropdown #periodDropdown
            [dropdownProps]="periodDropdownProps"
            [selectedPeriodId]="selectedPeriodId"
            (selectedPeriodIdChange)="onPeriodChanged($event)"
            [(selectedRecurrenceId)]="selectedRecurrenceId"
            [(selectedSeriesId)]="selectedSeriesId">
        </app-period-dropdown>
    </div>
    <div id="actionsMenu" class="processing-actions-menu">
        <div class="processing-actions-menu-top">

        </div>
        <div id="actionsMenuMiddle" class="processing-actions-menu-middle">
            <dx-button class="processing-action processing-action-large processing-action-bold run-cycle" 
                (onClick)="processAllAIRules($event)"
                text="Process All" 
                type="default" 
                stylingMode="contained">
            </dx-button>
        </div>
        <div class="processing-actions-menu-bottom">

        </div>
    </div>
    <div class="processing-content">
        <div class="document-viewer">
            <dx-tab-panel>
                <dxi-item title="Static Documents">
                    <dx-file-manager 
                        [fileSystemProvider]="userDefinedFileSystemProvider"
                        (onFileUploaded)="onFileUploaded($event)"
                        (onItemRenamed)="onItemUpdated($event)"
                        (onItemCopied)="onFileUploaded($event)"
                        (onItemDeleted)="onItemDeleted($event)"
                        (onItemMoved)="onItemUpdated($event)"
                        [allowedFileExtensions]="['.txt', '.pdf', '.docx', '.xlsx']"
                        rootFolderName="Documents">
                        <dxo-permissions
                            [create]="true"
                            [copy]="true"
                            [move]="true"
                            [delete]="true"
                            [rename]="true"
                            [upload]="true"
                            [download]="true">
                        </dxo-permissions>
                        <dxo-item-view [showParentFolder]="true">
                            <dxo-details>
                                <dxi-column dataField="thumbnail"></dxi-column>
                                <dxi-column dataField="name"></dxi-column>
                                <dxi-column dataField="dateModified"></dxi-column>
                                <dxi-column dataField="size"></dxi-column>

                            </dxo-details>
                        </dxo-item-view>
                    </dx-file-manager>
                </dxi-item>
                <dxi-item title="Period-Bound Files">
                    <div class="period-documents">
                        <dx-file-manager #periodFileManager
                            [fileSystemProvider]="periodBoundFileSystemProvider"
                            (onCurrentDirectoryChanged)="onCurrentDirectoryChanged($event)"
                            [allowedFileExtensions]="['.txt', '.pdf', '.docx', '.mp4', '.mp3', '.xlsx']"
                            selectionMode="single"
                            rootFolderName="Period-Specific Documents">
                            <dxo-permissions
                                [create]="false"
                                [copy]="false"
                                [move]="false"
                                [delete]="isDeleteEnabled"
                                [rename]="false"
                                [upload]="true"
                                [download]="true">
                            </dxo-permissions>
                            <dxo-item-view [showParentFolder]="true">
                                <dxo-details>
                                    <dxi-column dataField="thumbnail"></dxi-column>
                                    <dxi-column dataField="name"></dxi-column>
                                    <dxi-column dataField="dateModified"></dxi-column>
                                    <dxi-column dataField="size"></dxi-column>

                                </dxo-details>
                            </dxo-item-view>
                        </dx-file-manager>
                    </div>                
                </dxi-item>                    
            </dx-tab-panel>
        </div>
        <div class="processed-rules-wrapper">
            <core-tree-list
                #processedTreeList
                [dataSource]="filteredProcessedRecords"
                [columns]="processedColumns"
                [props]="processedTreeListProps">
            </core-tree-list>
        </div>
    </div>
</div>

import { BucketClass } from './bucket-class';

export class Bucket {
    id: number;
    bucket: number;
    bucketClassId: number;
    itemId: number;
    sellerId: number;
    type: number;
    sequenceNo: number;

    bucketClass: BucketClass;
    name: string;

    constructor(b: Partial<Bucket> = {}) {
        Object.assign(this, b);
    }
}

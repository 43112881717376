import { NgModule } from '@angular/core';
import { ProcessingRoutingModule } from './processing-routing.module';
import { ProcessingComponent } from './processing.component';
import { DxAccordionModule, DxSwitchModule, DxButtonModule, DxDropDownButtonModule } from 'devextreme-angular';
import { MainLayoutModule } from '../../layouts/main-layout/main-layout.component';
import { CoreComponentModule } from 'src/app/shared/components/core-component.module';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CoreComponentModule,
        ProcessingRoutingModule,
        MainLayoutModule,
        DxAccordionModule,
        DxSwitchModule,
        DxButtonModule,
        DxDropDownButtonModule,
        CommonModule
    ],
    declarations: [ProcessingComponent],
    exports: [ProcessingComponent]
})
export class ProcessingModule { }

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SellerService } from 'src/app/shared/services/seller.service';;
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { AzureService } from 'src/app/shared/services/azure-service/azure-service.service';
import { DxDataGridComponent, DxFileManagerComponent } from 'devextreme-angular';
import 'devextreme/ui/button';
import { ToastrService } from 'ngx-toastr';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { PeriodService } from 'src/app/shared/services/period.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import FileSystemItem from 'devextreme/file_management/file_system_item';
import { PeriodDropdownComponent } from 'src/app/shared/components/period-dropdown/period-dropdown.component';
import { TreeListProps } from 'src/app/shared/models/core-tree-list-properties';
import { CoreColumn } from 'src/app/shared/models/core-column';
import { ColumnType } from 'src/app/shared/models/core-column-type';
import { ProcessedTable } from 'src/app/shared/models/processing-processed-table';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { CoreFeature, coreResponseCodes, EnumContainerType, processingDataViewerFunctions, processLogRecordTypes } from 'src/app/shared/constants/enums';
import { Router } from '@angular/router';
import { ProcessingService } from 'src/app/shared/services/processing.service';
import { PopupArguments } from 'src/app/shared/models/popup-arguments';
import { RequiredProperty } from 'src/app/shared/models/required-property';
import { DisplayDataArguments } from 'src/app/shared/models/core-display-data-arguments';
import { toastrConstants } from 'src/app/shared/constants/constants';
import { GridProps } from 'src/app/shared/models/core-data-grid-properties';
import { ProcessingContext } from 'src/app/shared/models/contexts/processing-context';
import { ProcessRulesContext } from 'src/app/shared/models/contexts/process-rules-context';
import { CorePopupStep } from 'src/app/shared/models/core-popup-step';
import { CorePopupProperties } from 'src/app/shared/models/core-popup-properties';
import { CoreRequest } from 'src/app/shared/models/core-request';
import { BehaviorSubject } from 'rxjs';
import { PollValue } from 'src/app/shared/models/poll-value';
import { ProcessLogService } from 'src/app/shared/services/process-log.service';
import { CorePopupComponent } from 'src/app/shared/components/core-popup/core-popup.component';
import { CoreResponse } from 'src/app/shared/models/core-response';
import { PineconeService } from 'src/app/shared/services/pinecone-service';
import { PineconeRequest } from 'src/app/shared/models/pinecone-request';
import { Container } from 'src/app/shared/models/building-blocks';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { AIProcessedTable } from 'src/app/shared/models/processing-ai-processed-table';

@Component({
    selector: 'app-file-processing',
    templateUrl: './file-processing.component.html',
    styleUrls: ['./file-processing.component.scss'],
    providers: [AzureService]
})

export class FileProcessingComponent implements OnInit, OnDestroy {
    @ViewChild('periodFileManager', { static: false }) periodFileManager: DxFileManagerComponent;
    @ViewChild('processingPopup', { static: false }) processingPopup: CorePopupComponent;
    @ViewChild('periodDropdown') periodDropdown: PeriodDropdownComponent;
    @ViewChild('grid') dataGrid: DxDataGridComponent;

    hasPermissionRunCycle: boolean;
    hasPermissionImportRecords: boolean;
    hasPermissionViewProcessedRecords: boolean;
    hasPermissionProcessViewablePlans: boolean;

    hostUrl: string = environment.apiEndpoint;
    reportUrl: string;
    hasAdminView: boolean;
    aiRules: AIProcessedTable[] = [];

    toastConsts = toastrConstants;
    popupUrl: string = '/record-data-viewer';
    pageDestroyed: boolean = false;
    isDeleteEnabled: boolean = false;

    periodBoundFileSystemProvider: CustomFileSystemProvider;
    userDefinedFileSystemProvider: CustomFileSystemProvider;
    requests: any[];

    periodDropdownProps: CoreDropdownProperties[] = [];
    selectedPeriodId: number;
    selectedSeriesId: number;
    selectedRecurrenceId: number;
    isPeriodLocked: boolean;
    filteredProcessedRecords: AIProcessedTable[];
    processedRecords: AIProcessedTable[];

    processingContext: ProcessingContext;
    ruleProcessingContext: ProcessRulesContext;
    popupDefaultHeight: string = '275';
    popupDefaultWidth: string = '510';
    popupLogHeight: string = '350';
    popupLogWidth: string = '600';

    processedTreeListProps: TreeListProps = new TreeListProps('processedRecords', 'id', 'parentId', null, true, null, null, null, 'Processed Output')
    .setColumnResizingAllowed(true);

    processingPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly(this.popupDefaultWidth, this.popupDefaultHeight, true, 'Process AI Rule');
    processingPopupSteps: CorePopupStep[] = [];

    processedColumns: CoreColumn[] = [
        new CoreColumn('id', '', false),
        new CoreColumn('isDeleted', '', false),
        new CoreColumn('parentId', '', false),
        new CoreColumn('name', 'Plans/AI Rules', true, null, new ColumnType().createDropDownButton(null, 'Click to edit data', true, 'name',
            'id', 'name', this, this.processedTreeOpenViewEdit, this.processedTreeLayoutClick, this.getProcessedViewerLayouts, 'No layouts exist'))
            .sortAsc(),
        new CoreColumn('ruleType', 'Type', true),
        new CoreColumn('orderIndex', 'Level', true),
        new CoreColumn('numRecords', 'Count', true),
        new CoreColumn('processDate', 'Last Processed', true, 'datetime')
    ];

    constructor(
        private sellerService: SellerService,
        private azureService: AzureService,
        private periodService: PeriodService,
        private helperService: HelperService,
        private permissionService: PermissionService,
        private router: Router,
        private processingService: ProcessingService,
        private toast: ToastrService,
        private cd: ChangeDetectorRef,
        private processLogService: ProcessLogService,
        private pineconeService: PineconeService,
        private bbService: BuildingBlocksService
    )
    {
        this.requests = [];

        const endpointUrl = environment.apiEndpoint + '/AzureDataStorage/file-manager-azure-access';
        this.periodBoundFileSystemProvider = azureService.getAzureFileSystemProvider(endpointUrl, 'period-bound-documents', this.onRequestExecuted);
        this.userDefinedFileSystemProvider = azureService.getAzureFileSystemProvider(endpointUrl, 'user-defined-documents', this.onRequestExecuted);

        this.periodDropdownProps['series'] = new CoreDropdownProperties().createPeriodDropdownDefaults('name', false, false, false);
        this.periodDropdownProps['recurrence'] = new CoreDropdownProperties().createPeriodDropdownDefaults('name', false, false, false);
        this.periodDropdownProps['period'] = new CoreDropdownProperties().createPeriodDropdownDefaults('dateRange', false, false, false, 'Period');

        this.periodService.getPeriodLockedEvent().subscribe(() => {
            this.isPeriodLocked = true;
            alert(this.isPeriodLocked);
        });
    }

    ngOnInit(): void {
        this.sellerService.getHasAdminView().subscribe(result => this.hasAdminView = result);

        this.hasPermissionImportRecords = this.permissionService.checkCurrentUserPermission(CoreFeature.ImportRecords.toString());
        this.hasPermissionRunCycle = this.permissionService.checkCurrentUserPermission(CoreFeature.RunCycle.toString());
        this.hasPermissionViewProcessedRecords = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewProcessedRecords.toString());

        if (this.hasPermissionRunCycle || this.hasPermissionProcessViewablePlans) {
            const processedIndex = this.processedColumns.findIndex(x => x.columnHeader === 'Process');
            if (processedIndex === -1){
                this.processedColumns.push(
                    new CoreColumn('openBuildingBlock', 'Edit', true, null,
                        new ColumnType().createButton('', this.openInBuildingBlocksFunctions, this, 'Open In Building Blocks', 'comment', undefined, undefined,
                            (obj) => obj.row.data.parentId !== null && obj.row.data.parentId !== undefined))
                );
                this.processedColumns.push(
                    new CoreColumn('process', 'Process', true, null, new ColumnType().createButton('. . .', this.performRunCycleCheck, this, 'Process')));
            }

            this.bbService.getAllObjects();
        }
    }

    ngOnDestroy() {
        this.pageDestroyed = true;
    }

    onCurrentDirectoryChanged(e: any){
        const activePeriod = this.periodDropdown.periods.find(x => x.id === this.selectedPeriodId);
        const endDate = new Date(activePeriod.endDate);
        const beginDate = new Date(activePeriod.beginDate);
        const periodFolderName = `${(beginDate.getMonth() + 1).toString()}_${beginDate.getDate().toString()}_${beginDate.getFullYear().toString()}__`
            + `${(endDate.getMonth() + 1).toString()}_${endDate.getDate().toString()}_${endDate.getFullYear().toString()}`;

        if (e.directory.name === periodFolderName){
            this.periodFileManager.itemView.showParentFolder = false;
            this.isDeleteEnabled = false;
        }
        else {
            this.periodFileManager.itemView.showParentFolder = true;
            this.isDeleteEnabled = true;
        }
    }

    onRequestExecuted = ({ method, urlPath, queryString, selectedPeriodId }: { method: string, urlPath: string, queryString: string, selectedPeriodId: number}): void => {
        const request = { method, urlPath, queryString, selectedPeriodId };
        this.requests.unshift(request);
    };

    cancelProcessing() {
        this.processingService.cancelProcessing().subscribe(() => this.processingPopupSteps[0].cancelDisabled = true);
    }

    onPeriodChanged(e: any) {
        this.selectedPeriodId = e;
        this.updateFileManagerPath();
    }

    processedTreeOpenViewEdit(eventArgs: any, selectedValues: { ruleIds: string[], planIds: string[], datasourceIds: number[] } = null, uiViewId: number = null): void {
        if (this.hasPermissionViewProcessedRecords){
            this.processedTreeListProps.loadingVisible = true;
            let ruleIds: string[] = [];
            let planIds: string[] = [];
            let datasourceIds: number[] = [];

            if (selectedValues !== null) {
                ruleIds = selectedValues.ruleIds;
                planIds = selectedValues.planIds;
                datasourceIds = selectedValues.datasourceIds;
            } else if (eventArgs.event.row.data.parentId !== null) {
                ruleIds.push(eventArgs.event.row.data.id);
                planIds.push(eventArgs.event.row.data.parentId);
                datasourceIds.push(eventArgs.event.row.data.datasourceId);
            } else {
                planIds.push(eventArgs.event.row.data.id);
            }

            const popupArgs = new PopupArguments().createForImported([this.selectedPeriodId], this.selectedSeriesId, datasourceIds, this.selectedRecurrenceId);
            const requiredProperties = [new RequiredProperty('seriesId', this.selectedSeriesId), new RequiredProperty('periodId', this.selectedPeriodId)];
            if (uiViewId !== null) {
                requiredProperties.push(new RequiredProperty('uiViewId', uiViewId));
            }

            if (ruleIds?.length === 1) {
                popupArgs.shortTitle = this.processedRecords.find(x => x.id === ruleIds[0])?.name ?? '';
                popupArgs.title = 'Datasource Results for Rule: ' + popupArgs.shortTitle;
            } else if (planIds?.length === 1) {
                popupArgs.shortTitle = this.processedRecords.find(x => x.id === planIds[0])?.name ?? '';
                popupArgs.title = 'Plan: ' + popupArgs.shortTitle;
            } else {
                popupArgs.title = 'Processed Records';
                popupArgs.shortTitle = 'Processed Records';
            }

            const displayDataArguments = new DisplayDataArguments(requiredProperties, processingDataViewerFunctions.Imported, popupArgs);
            this.openViewEditPopup(displayDataArguments, this.processedTreeListProps);
        }
    }

    processedTreeLayoutClick(eventArgs: any): void {
    }

    getProcessedViewerLayouts(column: CoreColumn, data: any): any[] {
        return [null];
    }

    openInBuildingBlocksFunctions(eventArgs: any) {
        const row = eventArgs.event.row.data;

        if (row?.parentId) {
            this.router.navigate([`/building-blocks/${row.id}`]);
        }
    }

    performRunCycleCheck(event: any): void {
        if (this.periodLockCheck()) {
            return;
        }

        let planName: string = '';

        if (!this.helperService.isNullOrUndefined(event?.event?.row?.data['name'])) {
            planName = event.event.row.data['name'];
        }

        const planDescription: string = 'Run: ' + planName;
        this.processingContext = new ProcessingContext().createAll(this.selectedPeriodId, this.selectedSeriesId, planName);

        this.processingService.getWarningMessagesForPlanProcessing(this.processingContext).subscribe(messages => {
            this.changePopupPurpose(planDescription);
            this.processingPopupProps.visible = true;
            const warnings = messages;
            if (messages.length === 0) {
                warnings.push(`Are you sure you want to proceed with the execution of: "${planName}"
                in period ${this.periodDropdown.getSelectedPeriodDateRangeStr()}?`);
            }

            for (let i = 0; i < messages.length; i++) {
                if (i === (messages.length - 1)) {
                    this.ruleProcessingContext = new ProcessRulesContext(this.selectedPeriodId, this.selectedSeriesId, event.event.row.key, true);
                    this.processingPopupSteps.push(new CorePopupStep(messages[i], this.processFunctions, null, this, 'Run'));
                } else {
                    this.processingPopupSteps.push(new CorePopupStep(messages[i], null, null, this));
                }
            }
        });
    }

    periodLockCheck(): boolean {
        if (this.isPeriodLocked) {
            this.toast.warning('Period has been locked. Cannot make any changes to processed output.', 'Period Locked');
            return true;
        }
    }

    processFunctions(eventArgs: CorePopupStep) {
        if (this.periodLockCheck()) {
            return;
        }
        this.processedTreeListProps.loadingVisible = true;
        const request = new CoreRequest<ProcessRulesContext>(this.ruleProcessingContext, null);

        this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
            request.transactionId = newTransactionResponse.result;
            this.pollProcessLogByTransactionId('Process Log', request.transactionId, true);
            this.processingService.processRulesByRequest(request).subscribe(result => this.handleProcessRulesResult(result, request));
        });
    }

    handleProcessRulesResult = (result: CoreResponse<any>, request: CoreRequest<ProcessRulesContext> = null) => {
        this.processedTreeListProps.loadingVisible = false;
        if (result.responseCode === coreResponseCodes.Success) {
            this.toast.success('Plan/Rule successfully processsed.');
            this.populateProcessedData();
        } else if (result.responseCode === coreResponseCodes.CanceledByUser) {
            this.toast.warning('Processing cancelled.');
        }
        else {
            this.toast.error(this.toastConsts.planSegmentProcessingError);
        }
    };

    populateProcessedData(): void {
        if (this.hasPermissionViewProcessedRecords) {
            this.processingService.getAIProcessedTable(this.selectedSeriesId, this.selectedRecurrenceId, this.selectedPeriodId).subscribe(processedRecords => {
                this.processedRecords = processedRecords.results;
                this.aiRules = [];
                this.processedRecords.forEach(x => {
                    x['parentId'] = x['parentId'] ? x['parentId'] : null;
                    x['process'] = '...';
                    x.numRecords ??=  this.processedRecords.filter(y => y.parentId === x.id).map(y => y.numRecords).reduce((a,b) => a + b, 0);

                    if (x.containerType === EnumContainerType.AIRule){
                        this.aiRules.push(x);
                    }
                });
                this.filteredProcessedRecords = this.processedRecords;
            });
        }
    }

    openViewEditPopup(ddArgs: DisplayDataArguments, component: TreeListProps | GridProps) {
        try {
            this.helperService.openDisplayDataPopup(ddArgs, this.popupUrl);
        } catch (error) {
            this.toast.error(this.toastConsts.allowPopups);
        }

        component.loadingVisible = false;
    }

    pollProcessLogByTransactionId(popupTitle: string, transactionId: string, shouldCloseOnSuccess: boolean | (() => boolean) = false) {
        const poll = new BehaviorSubject(new PollValue<number>(0));
        this.changePopupPurpose(popupTitle);
        const logStep = new CorePopupStep('', null, this.cancelProcessing, this, 'Close Log', 'Cancel', false, true, false, false);
        logStep.isPreformatted = true;
        this.processingPopupProps.width = this.popupLogWidth;
        this.processingPopupProps.height = this.popupLogHeight;
        this.processingPopupSteps.push(logStep);
        this.processingPopupProps.visible = true;
        poll.subscribe(lastValue => {
            setTimeout(() => {
                this.processLogService.getProcessLogsByTransactionId(transactionId, poll.value.value).subscribe(logs => {
                    let continuePolling = true;
                    logs.results.forEach(log => {
                        logStep.message += log.log + '\n';
                        if (log.processLogRecordTypeId === processLogRecordTypes.Error || log.processLogRecordTypeId === processLogRecordTypes.Finished) {
                            continuePolling = false;

                            const shouldClose = (typeof shouldCloseOnSuccess === 'function') ? shouldCloseOnSuccess() : shouldCloseOnSuccess;
                            if (shouldClose && log.processLogRecordTypeId === processLogRecordTypes.Finished) {
                                this.processingPopupProps.visible = false;
                                this.cd.detectChanges();
                            }
                        }
                    });

                    if (continuePolling) {
                        let idToPollFrom = poll.value.value;
                        if (logs.results && logs.results.length > 0) {
                            idToPollFrom = logs.results.map(x => x.id).reduce((a, b) => Math.max(a, b));
                        }

                        poll.next(new PollValue(idToPollFrom));
                    }

                    setTimeout(() => {
                        this.processingPopup.setScrollHeightToBottom();
                    }, 100);
                });
            }, lastValue.getTimeout(1000));

            if (this.pageDestroyed) {
                poll.unsubscribe();
            }
        });
    }

    changePopupPurpose(title: string, height: string = this.popupDefaultHeight, width: string = this.popupDefaultWidth): void {
        this.processingPopupProps.title = title;
        this.processingPopupSteps = [];
        this.processingPopup.changePopupSize(height, width);
    }

    onSeriesChanged(e: any){
        this.selectedSeriesId = e.data.id;
        this.updateFileManagerPath();
    }

    onRecurrenceChanged(e: any){
        this.selectedRecurrenceId = e.data.id;
        this.updateFileManagerPath();
    }

    updateFileManagerPath(){
        this.periodService.getPeriodLockedEvent().subscribe(res => {
            this.isPeriodLocked = res;
            this.periodFileManager.disabled = this.isPeriodLocked === true ? true : false;
        });

        const activePeriod = this.periodDropdown.periods.find(x => x.id === this.selectedPeriodId);
        const activeSeries = this.periodDropdown.series.find(x => x.id === this.selectedSeriesId);
        const activeRecurrence = this.periodDropdown.recurrences.find(x => x.id === this.selectedRecurrenceId);
        const endDate = new Date(activePeriod.endDate);
        const beginDate = new Date(activePeriod.beginDate);
        const periodFolderName = `${(beginDate.getMonth() + 1).toString()}_${beginDate.getDate().toString()}_${beginDate.getFullYear().toString()}__`
            + `${(endDate.getMonth() + 1).toString()}_${endDate.getDate().toString()}_${endDate.getFullYear().toString()}`;
        const filePath = `${activeSeries.name}/${activeRecurrence.name}/${periodFolderName}`;
        const item = new FileSystemItem(filePath, true, [`${activeSeries.name}`,
                                                            `${activeSeries.name}/${activeRecurrence.name}`,
                                                            `${activeSeries.name}/${activeRecurrence.name}/${periodFolderName}`]);
        this.periodBoundFileSystemProvider.getItems(item).then(end => {
            this.periodFileManager.currentPath = filePath;
            this.periodFileManager.itemView.showParentFolder = false;
            this.isDeleteEnabled = false;
        });

        this.populateProcessedData();
    }

    onFileUploaded(e: any){
        let azureFileId = '';
        if (e.fileData){
            if (e.parentDirectory && e.parentDirectory.name !== ''){
                azureFileId = e.parentDirectory.path + '/' + e.fileData.name;
            }
            else {
                azureFileId = e.fileData.name;
            }
        }
        else if (e.itemPath) {
            azureFileId = e.itemPath;
        }

        const request: PineconeRequest = {
            azureContainerName: 'user-defined-documents',
            azureFileId
        };
        this.pineconeService.uploadPineconeEmbedding(request).subscribe(res => {
            this.toast.success('File embeddings updated');
        });
    }

    onItemDeleted(e: any){
        const request: PineconeRequest = {
            azureContainerName: 'user-defined-documents',
            azureFileId: e.item.path
        };
        this.pineconeService.deletePineconeEmbedding(request).subscribe(res => {
            this.toast.success('File embeddings deleted');
        });
    }

    onItemUpdated(e: any){
        const request: PineconeRequest = {
            azureContainerName: 'user-defined-documents',
            azureFileId: e.itemPath,
            oldAzureFileId: e.sourceItem.path
        };
        this.pineconeService.updatePineconeEmbedding(request).subscribe(res => {
            this.toast.success('File embeddings deleted');
        });
    }

    processAllAIRules(e: any){
        this.processLogService.getNewTransactionIdForProcessLog().subscribe(newTransactionResponse => {
            this.pollProcessLogByTransactionId('Process Log', newTransactionResponse.result, true);
            this.aiRules.forEach(async x => {
                const context: ProcessRulesContext = {

                    periodId: this.selectedPeriodId,
                    seriesId: this.selectedSeriesId,
                    targetId: x.id,
                    shouldCheckDependencies: false
                };
                const request = new CoreRequest(context, null, newTransactionResponse.result);
                await this.processingService.processRulesByRequest(request).subscribe(res => {
                });
            });
        });
    }
}

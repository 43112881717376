<dx-select-box
    #input
    [(value)]="value"
    [dataSource]="data"
    valueExpr="id"
    displayExpr="name"    
    [hint]="getSelectedDisplayValue()"
    [useItemTextAsTitle]="true"
    searchMode="contains"
    searchTimeout="200"
    searchExpr="name"
    minSearchLength="0"
    [searchEnabled]="true"
    (onContentReady)="onContentReady($event)"
    stylingMode="underlined">
    <div *dxTemplate="let data of 'dropDownButton'">
        <dx-button icon="spindown" class="dropdown-icon-button"></dx-button>
        <i class="dropdown-icon dx-icon dx-icon-spindown"></i>
    </div>
</dx-select-box>

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class LinkService {
    private setting = {
        element: {
            dynamicDownload: null as HTMLElement
        }
    };

    constructor(private authInfo: AuthService) {
    }

    private downloadFile(arg: {
        fileName: string,
        text: string
    }) {
        if (!this.setting.element.dynamicDownload) {
            this.setting.element.dynamicDownload = document.createElement('a');
        }
        const element = this.setting.element.dynamicDownload;
        const fileType = 'text/plain';
        element.setAttribute('href', `data:$(fileType);charset=utf-8,${encodeURIComponent(arg.text)}`);
        element.setAttribute('download', arg.fileName);

        element.dispatchEvent(new MouseEvent('click'));
    }
}

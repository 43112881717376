export class CoreValidationRule {
    type: string;
    message: string;
    pattern: string;
    max: any;
    comparisonTarget: any;
    isReevaluated: boolean = false;
    validationCallbackFunction: (e: any) => boolean;

    createRequired(message: string = null): CoreValidationRule {
        this.type = 'required';
        this.message = '';
        if (message !== null) {
            this.message = message;
        }

        return this;
    }

    createCustom(callbackFunction:  (e: any) => boolean, message: string, isReevaluated: boolean): CoreValidationRule {
        this.type = 'custom';
        this.validationCallbackFunction = callbackFunction;
        this.message = message;
        this.isReevaluated = isReevaluated;

        return this;
    }
}

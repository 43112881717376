import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DxButtonModule, DxTextBoxModule, DxTextAreaModule, DxPopupModule, DxResponsiveBoxModule,
    DxTreeViewModule, DxTooltipModule, DxDataGridModule, DxPopoverModule, DxScrollViewModule
} from 'devextreme-angular';
import { CoreComponentModule } from '../../core-component.module';
import { NbButtonModule, NbChatModule, NbIconModule, NbInputDirective, NbInputModule, NbLayoutModule, NbThemeModule } from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { RuleBotChatComponent } from './rulebot-chat.component';
import { DxiToolbarItemModule } from 'devextreme-angular/ui/nested';
import { CoreBotPopupContentModule } from '../corebot-shared/popup-content/corebot-popup-content.component';

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxPopupModule,
    DxTooltipModule,
    DxDataGridModule,
    CoreComponentModule,
    NbIconModule,
    NbButtonModule,
    NbInputModule,
    FormsModule,
    NbChatModule,
    DxPopoverModule,
    DxDataGridModule,
    DxScrollViewModule,
    DxiToolbarItemModule,
    CoreBotPopupContentModule,
    NbChatModule.forRoot(),
    NbLayoutModule,
    NbThemeModule.forRoot()
  ],
    declarations: [RuleBotChatComponent],
    exports: [RuleBotChatComponent]
})
export class RuleBotChatModule { }

// targetId can be for a plan, segment or bb rule
export class ProcessRulesContext {
    periodId: number;
    seriesId: number;
    targetId: string;
    shouldCheckDependencies: boolean;
    dependentIds?: string[];

    constructor(periodId: number, seriesId: number, targetId: string, shouldCheckDependencies = false) {
        this.periodId = periodId;
        this.seriesId = seriesId;
        this.targetId = targetId;
        this.shouldCheckDependencies = shouldCheckDependencies;
    }
}

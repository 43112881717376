import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CondFormat } from '../models/cond-format';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class CondFormatService {
    private baseUrl = environment.apiEndpoint + '/condformat';

    constructor(private http: HttpClient) {
    }

    getAllCondFormats(): Observable<CondFormat[]> {
        return this.http.get<CondFormat[]>(this.baseUrl);
    }

    insertCondFormat(condFormat: CondFormat): Observable<CondFormat> {
        return this.http.post<CondFormat>(this.baseUrl, condFormat);
    }

    updateCondFormat(condFormat: CondFormat): Observable<number> {
        return this.http.put<number>(this.baseUrl, condFormat);
    }

    deleteCondFormat(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/' + id);
    }

    getCondFormatsByDatasourceId(datasourceId: number): Observable<CondFormat[]> {
        return this.http.get<CondFormat[]>(`${this.baseUrl}/bydatasource/${datasourceId}`);
    }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService, TOKEN_NAME } from '../services/auth.service';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService){}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.append('Content-Type', 'application/json'),
    });

    const tokenString = localStorage.getItem(TOKEN_NAME); // you probably want to store it in localStorage or something

    if(tokenString !== null && req.url.indexOf('api/authenticate') === -1 && req.url.indexOf('/LogOutSSOUser') === -1 && req.url.indexOf('api/logout') === -1) {
      const token: any = jwt_decode(tokenString);
      if(token.exp < Math.floor((new Date()).getTime()) / 1000){
        this.authService.logOut();
        return;
      }
    }

    const req1 = req.clone({
      withCredentials: true
    });

    if (tokenString == null) {
      return next.handle(req1);
    }

    if (!tokenString) {
      return next.handle(req1);
    }

    const req2 = req1.clone({
      headers: req.headers.append('Authorization', `Bearer ${tokenString}`),
    });



    return next.handle(req2);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ReportLayout } from '../models/report-layout';
import { ReportLayoutContext } from '../models/contexts/report-layout-context';
import { ReportContext } from '../models/contexts/report-context';
import { ReportSegment } from '../../shared/models/report-segment';
import { CoreResponse } from '../models/core-response';
import { ReportSellerPublish } from '../models/report-seller-publish';
import { UpdatePublishRequest } from '../models/update-publish-request';

@Injectable()
export class ReportService {
    private reportURL = environment.apiEndpoint + '/report/';

    constructor(private http: HttpClient) {
    }

    getReportLayouts(): Observable<ReportLayout[]> {
        return this.http.get<ReportLayout[]>(this.reportURL);
    }

    getReportAsByteArray(context: ReportContext): Observable<CoreResponse<any>> {
        return this.http.post<CoreResponse<any>>(this.reportURL + 'object', context);
    }

    previewReport(context: ReportContext): Observable<any> {
        return this.http.post(this.reportURL + 'preview', context);
    }

    getReport(id: number): Observable<ReportLayout> {
        return this.http.get<ReportLayout>(this.reportURL + id);
    }

    getNewReportConfigId(): Observable<string> {
        return this.http.get<string>(this.reportURL + 'newReportUrl');
    }

    getReportsBySeller(sellerID: number, beginDate: Date, endDate: Date): Observable<ReportLayout[]> {
        return this.http.post<ReportLayout[]>(this.reportURL + 'byseller', { sellerID, beginDate, endDate });
    }

    getReportSellerPublishByReportParId(reportParId: number)
    {
        return this.http.get<ReportSellerPublish[]>(this.reportURL + 'publishedReportsByReportParId/' + reportParId);
    }

    postReportConfig(body: any): Observable<any> {
        const headers = new HttpHeaders({'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'});
        return this.http.post<any>(environment.apiEndpoint + '/DXXRD', body, { headers });
    }

    insertReportLayout(context: ReportLayoutContext): Observable<ReportLayout> {
        return this.http.post<ReportLayout>(this.reportURL + 'insert', context);
    }

    cloneReportLayout(sourceId: number, name: string): Observable<ReportLayout> {
        return this.http.post<ReportLayout>(this.reportURL + 'clone' + `?sourceId=${sourceId}&name=${encodeURIComponent(name)}`, {});
    }

    loadReport(reportContainer: any): Observable<number> {
        return this.http.post<number>(this.reportURL, reportContainer);
    }

    getReportMapping(): Observable<{id: number, name: string}[]> {
        return this.http.get<{id: number, name: string}[]>(this.reportURL + 'mapping');
    }

    getReportMappingBySeller(sellerID: number, beginDate: Date, endDate: Date): Observable<any[]> {
        return this.http.post<any[]>(this.reportURL + 'mapping', {sellerID, beginDate, endDate});
    }

    updateReportLayout(context: ReportLayoutContext): Observable<number> {
        return this.http.put<number>(this.reportURL + 'update', context);
    }

    renameReportLayout(reportLayoutId: number, newName: string): Observable<number> {
        return this.http.put<number>(this.reportURL + `rename?reportLayoutId=${reportLayoutId}&newName=${encodeURIComponent(newName)}`, null);
    }

    updateAttributes(reportId: number, attributes: string, publishSellerIds: number[]): Observable<CoreResponse<number>> {
        return this.http.put<CoreResponse<number>>(this.reportURL + `attributes?reportId=${reportId}&attributes=${attributes}`, null);
    }

    updateReportPublished(publishInfo: UpdatePublishRequest){
        return this.http.post<CoreResponse<number>>(this.reportURL + 'updateReportPublished', publishInfo);
    }

    deleteReportLayout(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.reportURL + id.toString());
    }

    getReportSegments(): Observable<ReportSegment[]> {
        return this.http.get<ReportSegment[]>(this.reportURL + 'segments');
    }
}

import { ChatMessage } from './chat-message';

export class RedirectButtonMessage extends ChatMessage {
    customMessageData: any;
    text: string;

    constructor(message: string){
        super();
        this.type = 'redirectButton';
        this.text = message;
        this.customMessageData = {positive:'Yes', negative: 'No'};
        this.user = {
            name: 'BossBot',
            avatar: '../../../assets/images/boss-bot.png'
        };
    }
}

import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CanDeactivateComponent } from 'src/app/shared/interfaces/can-deactivate-component';
import { DashboardViewerComponent } from '../../shared/components/dashboard-viewer/dashboard-viewer.component';
import { CoreFeature, EnumBucketType, EnumSettingClassId, EnumUserGroup, coreResponseCodes } from '../../shared/constants/enums';
import { Bucket } from '../../shared/models/bucket';
import { BucketService } from '../../shared/services/bucket.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { PermissionService } from '../../shared/services/permission.service';
import { SellerService } from '../../shared/services/seller.service';
import { AppElementsService } from '../../shared/services/app-element.service';
import { Dashboard } from 'src/app/shared/models/dashboard';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { CoreDropdownComponent } from 'src/app/shared/components/core-dropdown/core-dropdown.component';
import { CanDeactivateService } from 'src/app/shared/services/can-deactivate.service';
import { ToastrService } from 'ngx-toastr';
import { UpdatePublishRequest } from 'src/app/shared/models/update-publish-request';
import { SettingService } from 'src/app/shared/services/setting.service';

@Component({
    selector: 'app-dashboards',
    templateUrl: './dashboards.component.html',
    styleUrls: ['./dashboards.component.scss'],
    providers: [SellerService]
})
export class DashboardsComponent implements OnInit, CanDeactivateComponent {
    @ViewChild(DashboardViewerComponent) dashViewer: DashboardViewerComponent;
    @ViewChild(CoreDropdownComponent) dropdown: CoreDropdownComponent;

    dashboardID: number;
    pullDown: boolean = false;
    loadingVisible: boolean;
    newDashboard: boolean;
    defaultBucket: Bucket;
    favoriteBucket: Bucket;
    isNavBucketFoldersPopupVisible: boolean = false;
    bucketFolderType: EnumBucketType = EnumBucketType.Dashboard;
    checkBoxValue: boolean = false;
    // these next two properties are used for a leave confirmation message pop-up by CanDeactivateService
    canDeactivateMessage = 'Are you sure you want to leave Dashboard Designer? Unsaved changes will be lost.';
    canDeactivateMessageTitle = 'Dashboard Designer';

    hasAdminView = false;
    isImplementer = false;
    dashboards: {id: number, name: string}[] = [];
    sellerId: number;
    favoriteButton: any = {
        id: 'favorite-button',
        icon: 'favorites',
        hint: 'Favorite/Unfavorite',
        onClick: () => {
            if (this.favoriteBucket) {
                this.bucketService.deleteFavoriteBucket(this.favoriteBucket).subscribe();
            } else {
                this.bucketService.addFavoriteBucket(this.dashboardID, EnumBucketType.Dashboard, this.sellerId).subscribe();
            }
        }
    };
    navFolderButton: any = {
        id: 'nav-folder-settings',
        icon: 'folder',
        hint: 'Nav Folder Settings',
        onClick: () => {
            this.isNavBucketFoldersPopupVisible = true;
        }
    };
    publishedSellerIds: number[] = [];

    hasEditPermission: boolean;
    isAddNewButtonHidden: boolean;
    isDeleteButtonHidden: boolean;
    dropdownProps = new CoreDropdownProperties();
    path = 'pages/dashboard';

    attributePermissionString: string = '';
    isAttributesPopupVisible: boolean = false;
    isBulkHomeDefaultPopupVisible: boolean = false;
    hasPermissionEditPermission: boolean;
    isHomeDefaultEnabled: boolean;
    readonly dashboardBucketType = EnumBucketType.Dashboard;

    constructor(private route: ActivatedRoute,
        private location: Location,
        private dashboardService: DashboardService,
        private permissionService: PermissionService,
        private settingService: SettingService,
        private bucketService: BucketService,
        private canDeactivateService: CanDeactivateService,
        private appElementsService: AppElementsService,
        private router: Router,
        private authService: AuthService,
        private sellerService: SellerService,
        private toast: ToastrService,
        private changeDetector: ChangeDetectorRef) {

    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnload(event: Event) {
        if (this.dashViewer.hasUnsavedChanges()) {
            event.preventDefault();
            return (event.returnValue = true);
        }
    }

    ngOnInit() {
        this.hasEditPermission = this.permissionService.checkCurrentUserPermission(CoreFeature.AccessDashboardDesigner.toString()) &&
            this.permissionService.checkCurrentUserPermission(CoreFeature.EditDashboards.toString());
        this.hasPermissionEditPermission = this.permissionService.checkCurrentUserPermission(CoreFeature.AssignDashboardPermissions.toString());
        this.isAddNewButtonHidden = !this.permissionService.checkCurrentUserPermission(CoreFeature.CreateDashboards.toString());
        this.isDeleteButtonHidden = !this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteDashboards.toString());

        this.dropdownProps.createAll(true, 'id', 'name', false, this.isAddNewButtonHidden, this.hasEditPermission, this.isDeleteButtonHidden, false, false,
                 250, 'dashboard', 'dashboardID', 'dashboardID', 'dashboardID')
            .setProp('searchEnabled', true);
        this.dropdownProps.dynamicButtons.push(this.favoriteButton);

        this.settingService.getBoolSetting(EnumSettingClassId.EnableNavBucketFolders).subscribe(navBucketFoldersEnabled => {
            if (navBucketFoldersEnabled) {
                this.dropdownProps.dynamicButtons.push(this.navFolderButton);
            }
        });

        this.sellerService.getHasAdminView().subscribe(hasAdminView => {
            this.hasAdminView = hasAdminView;
            this.changeDetector.detectChanges();
            if (hasAdminView) {
                this.getDashboards();
            }
            this.route.paramMap.subscribe(params => {
                let dashboardId = params.get('dashboardID');
                if (!dashboardId) {
                    dashboardId = sessionStorage.getItem('selectedDashboard');
                }
                if (dashboardId === 'new') {
                    this.newDashboard = true;
                    this.dashViewer.createDashboard(params.get('title'));
                } else if (dashboardId || !this.hasAdminView) {
                    this.newDashboard = false;
                    this.dashboardID = parseInt(dashboardId, 10);
                    this.selectDashboard(this.dashboardID);
                } else {
                    this.dashViewer.generateDashboard(undefined);
                }

                if (this.dashboardID) {
                    this.dashboardService.getDashboardSellerPublishByDashboardId(this.dashboardID).subscribe(result => {
                        for (const publishedSeller of result) {
                            this.publishedSellerIds.push(publishedSeller.sellerId);
                        }
                    });
                }
            });
        });
        this.sellerService.getMe().subscribe(me => {
            this.sellerId = me.id;
            this.isImplementer = me.userGroupId === EnumUserGroup.Implementer;
        });
        this.bucketService.getFavoriteEvent().subscribe(() => {
            this.getFavoriteBucket();
        });

        this.bucketService.useDefaultBucket().subscribe(result => this.isHomeDefaultEnabled = result);
    }

    refreshViewer(): void {
        this.dashViewer.generateDashboard(this.dashboardID);
    }

    canDeactivate(): boolean {
        return !this.dashViewer.hasUnsavedChanges();
    }

    handleDeactivationResult(result: boolean): void {
        if (result) {
            // user selected to leave page and lose changes, so discard dashboard changes and avoid unnecessary devexpress pop-up
            this.dashViewer.discardDashboardChanges();

            // Check if a logout attempt triggered the popup - in which case we now want to log out
            if (this.appElementsService.headerComponent.awaitingLogout) {
                this.appElementsService.headerComponent.unsavedChanges = false;
                this.appElementsService.headerComponent.onLogout();
            }
        } else {
            // TODO: revisit this if the side nav item selection is rewritten
            // user selected to not lose unsaved changes, so reset selected side nav menu item
            let dashboardId: string;
            if (this.newDashboard) {
                dashboardId = 'new';
            } else {
                dashboardId = this.dashboardID.toString();
            }
            this.appElementsService.sideNavOuterToolbar.setActiveMenuItem(EnumBucketType.Dashboard, dashboardId);

            // if user has changed their mind on logging out, we no longer want to log out
            this.appElementsService.headerComponent.awaitingLogout = false;
        }
    }

    getFavoriteBucket() {
        if (!this.dashboardID) {
            this.favoriteBucket = null;
            this.dropdown?.updateDynamicButton('favorite-button', false);
        } else {
            this.bucketService.getFavoriteBucket(this.dashboardID, EnumBucketType.Dashboard).subscribe(bucket => {
                this.favoriteBucket = bucket;
                this.dropdown?.updateDynamicButton('favorite-button', !!this.favoriteBucket);
            });
        }
    }

    getDashboards() {
        this.dashboardService.getDashboardMapping().subscribe(dashboards => this.dashboards = dashboards.sort((a, b) => a.name.localeCompare(b.name)));
    }

    selectDashboard(id) {
        if (this.dashViewer.hasUnsavedChanges()) {
            const promise = this.canDeactivateService.canDeactivate(this.canDeactivateMessage, this.canDeactivateMessageTitle);
            promise.then(result => {
                this.handleDeactivationResult(result);
                if (result) {
                    this.exitDesigner();
                    this.loadSelectedDashboard(id);
                } else {
                    // If not navigating to new dashboard, make sure dropdown is still displaying the dashboard that is being edited
                    this.dropdown.setSelectedValue(this.dashboardID);
                }
            });
        } else {
            this.loadSelectedDashboard(id);
        }

    }

    loadSelectedDashboard(id): void {
        this.dashboardID = id;
        this.dashboardService.getDashboard(id).subscribe(dashboard => this.attributePermissionString = dashboard.attributes);
        this.dashViewer.generateDashboard(this.dashboardID);
        this.getFavoriteBucket();
        sessionStorage.setItem('selectedDashboard', id);
        this.location.go(this.path + (id ? '/' + id : ''));

        this.publishedSellerIds = [];
        this.dashboardService.getDashboardSellerPublishByDashboardId(this.dashboardID).subscribe(result => {
            for (const publishedSeller of result) {
                this.publishedSellerIds.push(publishedSeller.sellerId);
            }
        });
    }

    onAddNewDashboard = (e) => {
        const dashboardId = this.dashboardID ? this.dashboardID : 0;
        if (this.dashViewer.mode === this.dashViewer.MODE_VIEWER) {
            // Occurred in view mode - clone currently selected dashboard
            this.dashboardService.cloneDashboard(dashboardId, e).subscribe(insertedDashboard => {
                this.getDashboards();
                this.toast.success(`Dashboard '${insertedDashboard.name}' has been successfully created`);
                this.router.navigate([`/pages/dashboard/${insertedDashboard.id}`]);
            });
        } else if (this.dashViewer.mode === this.dashViewer.MODE_DESIGNER) {
            // Occurred in designer mode - save current state of dashboard and save it with new name
            this.dashViewer.dashboardId = 0;
            this.dashViewer.dashboardName = e;
            this.dashViewer.onSave();
        }
    };

    deleteDashboard = () => {
        this.dashboardID = null;
        this.dashViewer.deleteDashboard(true);
        this.getFavoriteBucket();
        this.location.go(this.path);
    };

    exitDesignerClick(): void {
        if (this.dashViewer.hasUnsavedChanges()) {
            const promise = this.canDeactivateService.canDeactivate(this.canDeactivateMessage, this.canDeactivateMessageTitle);
            promise.then(result => {
                this.handleDeactivationResult(result);
                if (result) {
                    this.exitDesigner();
                }
            });
        } else {
            this.exitDesigner();
        }
    }

    exitDesigner(reportSelectionChanged: boolean = false): void {
        this.loadingVisible = true;
        this.dashViewer.switchToViewer();
    }

    renameDashboard(newName): void {
        this.dashboardService.renameDashboard(this.dashboardID, newName).subscribe(result => {
            if (result > 0) {
                this.dropdown.repaint();
                this.dashViewer.generateDashboard(this.dashboardID);
                this.toast.success('Dashboard successfully renamed to \'' + newName + '\'');
            }
        }, error => {
            this.toast.error(error.error);
        });
    }

    onClickPermissions(): void {
        this.isAttributesPopupVisible = true;
    }

    onClickBulkHomeDefault() {
        this.isBulkHomeDefaultPopupVisible = true;
    }

    onAttributesChanged() {
        const publishRequest = new UpdatePublishRequest();
        publishRequest.resourceId = this.dashboardID;
        publishRequest.attributeString = this.attributePermissionString;
        publishRequest.sellerIds = this.publishedSellerIds;

        this.dashboardService.updateDashboardPublished(publishRequest).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Success) {
                this.toast.success('Dashboard attribute permissions successfully updated');
            } else {
                this.toast.error(response.message);
            }
        });
    }
}

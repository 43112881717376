import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth.guards';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { TenantGuard } from './shared/services/tenant.guards';
import { PermissionGuard } from './shared/services/permission.guards';
import { CoreFeature, CoreSettingType, EnumSettingClassId } from './shared/constants/enums';
import { AgreementGuard } from './shared/services/agreement-guard';
import { SettingGuard } from './shared/services/setting.guard';
import { Setting } from './shared/models/setting';

const routes: Routes = [
    {
        path: '',
        component: MainLayoutComponent,
        canActivate: [() => inject(AuthGuard).canActivate(), (next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next)],
        canActivateChild: [() => inject(AuthGuard).canActivateChild()],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'setup-rules',
                loadChildren: () => import('./pages/setup-objects/setup-rule.module').then(m => m.SetupRuleModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessSegments.toString() }
            },
            {
                path: 'plans',
                loadChildren: () => import('./pages/plans/plans.module').then(m => m.PlansModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessSegments.toString() }
            },
            {
                path: 'account-attributes',
                loadChildren: () => import('./pages/account-attributes/account-attributes.module').then(m => m.AccountAttributesModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessAccountAttributes.toString() }
            },
            {
                path: 'etl',
                loadChildren: () => import('./pages/etl/etl.module').then(m => m.EtlModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessEtl.toString() }
            },
            {
                path: 'altman',
                loadChildren: () => import('./pages/altman/altman.module').then(m => m.AltmanModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(TenantGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: {tenant: 'altman'}
            },
            {
                path: 'fields',
                loadChildren: () => import('./pages/fields/fields.module').then(m => m.FieldsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessFields.toString() }
            },
            {
                path: 'dispute-management',
                loadChildren: () => import('./pages/dispute-management/dispute-management.module').then(m => m.DisputeManagementModule),
                canActivate: [() => inject(AgreementGuard).canActivate()]
            },
            {
                path: 'custom/:disputeId',
                loadChildren: () => import('./pages/dispute-management/dispute-management.module').then(m => m.DisputeManagementModule),
                canActivate: [() => inject(AgreementGuard).canActivate()]
            },
            {
                path: 'pages/workflow',
                loadChildren: () => import('./pages/workflow/workflow.module').then(m => m.WorkflowModule),
                canActivate: [() => inject(AgreementGuard).canActivate()]
            },
            {
                path: 'pages/dashboard',
                loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessDashboards.toString() }
            },
            {
                path: 'pages/dashboard/:dashboardID',
                loadChildren: () => import('./pages/dashboards/dashboards.module').then(m => m.DashboardsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessDashboards.toString() }
            },
            {
                path: 'pages/report',
                loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessReports.toString() }
            },
            {
                path: 'pages/report/:reportID',
                loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessReports.toString() }
            },
            {
                path: 'pages/analytic',
                loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessAnalytics.toString() }
            },
            {
                path: 'pages/analytic/:analyticID',
                loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessAnalytics.toString() }
            },
            {
                path: 'home',
                loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
            },
            {
                path: 'account-management',
                loadChildren: () => import('./pages/account-management/account-management.module').then(m => m.AccountManagementModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessAccounts.toString() }
            },
            {
                path: 'process-log-viewer',
                loadChildren: () => import('./pages/process-log-viewer/process-log-viewer.module').then(m => m.ProcessLogViewerModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.ViewLogs.toString() }
            },
            {
                path: 'processing',
                loadChildren: () => import('./pages/processing/processing.module').then(m => m.ProcessingModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessProcessing.toString() }
            },
            {
                path: 'email',
                loadChildren: () => import('./pages/email/email.module').then(m => m.EmailModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessEmail.toString() }
            },
            {
                path: 'tag-groups',
                loadChildren: () => import('./pages/tag-groups/tag-groups.module').then(m => m.TagGroupsModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { permissionId: CoreFeature.AccessTagGroups.toString() }
            },
            {
                path: 'snippets',
                loadChildren: () => import('./pages/customer-history/customer-history.module').then(m => m.CustomerHistoryModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(TenantGuard).canActivate(next)],
                data: { tenant: 'corehome' }
            },
            {
                path: 'stanleymartin',
                loadChildren: () => import('./pages/stanley/stanley.module').then(m => m.StanleyModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(TenantGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { tenant: 'stanleymartin' }
            },
            {
                path: 'abc',
                loadChildren: () => import('./pages/abc/abc.module').then(m => m.ABCModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(TenantGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: {tenant: 'abc'}
            },
            {
                path: 'bingham',
                loadChildren: () => import('./pages/bingham/bingham.module').then(m => m.BinghamModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(TenantGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { tenant: 'bingham' }
            },
            {
                path: 'ecagency',
                loadChildren: () => import('./pages/ecagency/ecagency.module').then(m => m.ECAgencyModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(TenantGuard).canActivate(next), () => inject(AgreementGuard).canActivate()],
                data: { tenant: 'ecagency' }
            },
            {
                path: 'building-blocks',
                loadChildren: () => import('./pages/building-blocks/building-blocks.module').then(m => m.BuildingBlocksModule),
                canActivate: [() => inject(AgreementGuard).canActivate()]
            },
            {
                path: 'system',
                loadChildren: () => import('./pages/system/system.module').then(m => m.SystemModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next)],
                data: { permissionId: CoreFeature.AccessSystemSettings.toString() }
            },
            {
                path: 'build-diff',
                loadChildren: () => import('./pages/build-diff/build-diff.module').then(m => m.BuildDiffModule),
                canActivate: [() => inject(AgreementGuard).canActivate()]
            },
            {
                path: 'file-processing',
                loadChildren: () => import('./pages/file-processing/file-processing.module').then(m => m.FileProcessingModule),
                canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next), () => inject(AgreementGuard).canActivate(), (next: ActivatedRouteSnapshot) => inject(SettingGuard).canActivate(next)],
                data: { permissionId: CoreFeature.AccessEtl.toString(), settingId: EnumSettingClassId.EnableAIRules }
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'home'
            }
        ]
    },
    {
        path: 'record-data-viewer',
        loadChildren: () => import('./pages/record-data-viewer/record-data-viewer-routing.module').then(m => m.RecordDataViewerRoutingModule)
    },
    {
        path: 'login-form',
        loadChildren: () => import('./shared/components/login-form/login-form.module').then(m => m.LoginFormModule),
    },
    {
        path: 'forgot-username',
        loadChildren: () => import('./shared/components/forgot-username/forgot-username.module').then(m => m.ForgotUsernameModule),
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./shared/components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./shared/components/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    },
    {
        path: 'change-password',
        loadChildren: () => import('./shared/components/change-password/change-password.module').then(m => m.ChangePasswordModule)
    },
    {
        path: 'initiatesso',
        loadChildren: () => import('./SSO/initiatesso/initiatesso.module').then(m => m.InitiatessoModule)
    },
    {
        path: 'retrievesso',
        loadChildren: () => import('./SSO/retrievesso/retrievesso.module').then(m => m.RetrievessoModule)
    },
    {
        path: 'callback',
        loadChildren: () => import('./OAuth/callback/callback.module').then(m => m.CallbackModule)
    },
    {
        path: 'callback/:integrationID',
        loadChildren: () => import('./OAuth/callback/callback.module').then(m => m.CallbackModule)
    },
    {
        path: 'building-blocks-audit',
        loadChildren: () => import('./pages/building-blocks/building-blocks.module').then(m => m.BuildingBlocksModule),
        canActivate: [() => inject(AgreementGuard).canActivate()]
    },
    {
        path: 'chatbot',
        loadChildren: () => import('./pages/chatbot/chatbot-popout.module').then(m => m.ChatBotPopoutModule),
        canActivate: [(next: ActivatedRouteSnapshot) => inject(PermissionGuard).canActivate(next)],
        data: { permissionId: CoreFeature.UseEverybodyCoreBotTools.toString() }
    },
    {
        path: '**',
        redirectTo: 'home',
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
        HttpClientModule,
        CommonModule,
    ],
    providers: [AuthGuard, TenantGuard, PermissionGuard, AgreementGuard, SettingGuard],
    exports: [RouterModule]
})
export class AppRoutingModule { }

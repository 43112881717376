import { DevExpressDMLFunctions } from './dev-express-dml-functions';
import { DevExpressDataProperties } from './dev-express-data-properties';
import { RequiredProperty } from './required-property';
import { CoreEventArguments } from './core-event-arguments';
import { EditModes } from '../constants/dev-extreme-enums';
import { UiView } from './ui-view';
import { CoreDataGridChange } from './core-data-grid-change';
import { uiViewContextCodes } from '../constants/enums';

export class GridProps extends DevExpressDataProperties {
    gridId: string;
    isGridLabelVisible: boolean = false;
    gridLabel: string;
    editMode: string = EditModes.Cell;
    isAddingAllowed: boolean = false;
    isUpdatingAllowed: boolean = false;
    isDeletingAllowed: boolean = false;
    requiredProperties: RequiredProperty[];
    isColumnChooserEnabled: boolean = false;
    height: string | number | (() => string | number);
    isFocusedRowEnabled: boolean;
    isCommandColumnLeft: boolean = true;
    keyColumn: string;
    deleteEnabledColumn: string;
    isColumnGroupingEnabled: boolean;
    emptyPanelText: string = 'Group';
    noDataText: string = 'No data';
    uiViewId: number;
    uiViewContextCode: uiViewContextCodes;
    uiViewFilters: RequiredProperty[];
    uiViewAddProperties: RequiredProperty[];
    isStateSaveLocal: boolean = false;
    excludedStateProperties: string[] = [];
    isFilterPanelEnabled: boolean = false;
    toolBarItems: any[];
    footerToolbarItems: any[];
    displayLayoutsInFooter: boolean = false;
    hideSaveButton: boolean = false;
    hideBulkEditButton: boolean = false;
    gridChanges: CoreDataGridChange<any>[] = [];
    checkboxesDisabled: boolean = false;
    isServerSideDataProcessingEnabled: boolean = false;
    serverSideDataProcessingUrl: string;
    isTableSelectCellEditorPopup: boolean = false;
    dataChangesFunction: (e: CoreEventArguments) => void = null;
    modifyServerDataFunction: (data: any[]) => any[] = null;
    onContentReadyFunction: (e: CoreEventArguments) => void = null;
    onEditorPreparingFunction: (e: CoreEventArguments) => void = null;
    onSelectionChangedFunction: (e: CoreEventArguments) => void = null;
    onFocusedRowChangedFunction: (e: CoreEventArguments) => void = null;
    onOptionChangedFunction: (e: CoreEventArguments) => void = null;
    onRowValidatingFunction: (e: any) => void = null;
    onInitializedFunction: (e: any) => void = null;
    onInitNewRowFunction: (e: any) => void = null;
    onEditingStartFunction: (e: any) => void = null;
    onEditCanceledFunction: (e: any) => void = null;
    onRowPreparedFunction: (e: any) => void = null;
    onRowInsertingFunction: (e: any) => void = null;
    onRowInsertedFunction: (e: any) => void = null;
    onRowUpdatingFunction: (e: any) => void = null;
    onRowUpdatedFunction: (e: any) => void = null;
    onRowRemovingFunction: (e: any) => void = null;
    onRowRemovedFunction: (e: any) => void = null;
    onLayoutSaved: (uiView: UiView) => void = null;
    onLayoutAdded: (uiView: UiView) => void = null;
    onLayoutRenamed: (uiView: UiView) => void = null;
    onLayoutDeleted: (uiView: UiView) => void = null;
    saveGridState: (state: any) => any = null;
    loadGridState: (state: any) => void = null;

    constructor(uniqueGridId: string = 'coreDataGrid', gridLabel?: string, isColumnChooserEnabled?: boolean, isScrollingEnabled: boolean = false, isRowSelectionVisible: boolean = false,
        isColumnGroupingEnabled: boolean = true, isBulkEditEnabled: boolean = false, isDuplicateEnabled: boolean = false, toolBarItems?: any[], footerToolbarItems?: any[]) {
        super();
        this.gridId = uniqueGridId;
        this.gridLabel = gridLabel;
        this.isColumnChooserEnabled = isColumnChooserEnabled;
        this.isRowSelectionVisible = isRowSelectionVisible;
        this.isColumnGroupingEnabled = isColumnGroupingEnabled;
        this.isBulkEditEnabled = isBulkEditEnabled;
        this.isDuplicateEnabled = isDuplicateEnabled;
        this.toolBarItems = toolBarItems;
        this.footerToolbarItems = footerToolbarItems;

        if (isScrollingEnabled) {
            this.isScrollingEnabled = true;
            this.isPagingEnabled = false;
        }
    }

    setGridToScrollingWithDMLForPopup(label: string, isColumnChooserEnabled: boolean, scrollingMode: string,
        wordWrapEnabled: boolean, dmlFN?: DevExpressDMLFunctions, editMode?: string): GridProps {

        if (this.gridId === null || this.gridId === undefined) {
            this.gridId = 'coreDataGrid';
        }

        this.gridLabel = label;
        this.isColumnChooserEnabled = isColumnChooserEnabled;
        this.isScrollingEnabled = true;
        this.isPagingEnabled = false;
        this.isPageSizeSelectorVisible = false;
        this.isPageNavigationVisible = false;
        this.scrollingMode = scrollingMode;
        this.wordWrapEnabled = wordWrapEnabled;
        this.editMode = editMode ? editMode : EditModes.Cell;

        if (dmlFN && dmlFN.dataChangesFunction) {
            this.addDMLFunctions(dmlFN);
        }

        return this;
    }

    setGridToolbarButtons(isDuplicateEnabled: boolean = false, isBulkEditEnabled: boolean = false, isBulkDeleteEnabled: boolean = false,
        isLayoutsEnabled: boolean = false, isRowSelectionVisible: boolean = false): GridProps {

        this.isDuplicateEnabled = isDuplicateEnabled;
        this.isBulkEditEnabled = isBulkEditEnabled;
        this.isBulkDeleteEnabled = isBulkDeleteEnabled;
        this.isLayoutsEnabled = isLayoutsEnabled;
        this.isRowSelectionVisible = isRowSelectionVisible;

        return this;
    }

    setGridToScrollingWithViewForPopup(label: string, isColumnChooserEnabled: boolean, scrollingMode: string,
        wordWrapEnabled: boolean): GridProps {
        this.gridId = 'coreDataGrid';
        this.gridLabel = label;
        this.isColumnChooserEnabled = isColumnChooserEnabled;
        this.isScrollingEnabled = true;
        this.isPagingEnabled = false;
        this.isPageSizeSelectorVisible = false;
        this.isPageNavigationVisible = false;
        this.scrollingMode = scrollingMode;
        this.wordWrapEnabled = wordWrapEnabled;

        return this;
    }

    setUiView(uiViewId: number, contextCode: uiViewContextCodes, uiViewFilters: RequiredProperty[], uiViewAddProperties: RequiredProperty[],
        parentThis: any = null, onLayoutSaved: (uiView: UiView) => void = null, onLayoutAdded: (uiView: UiView) => void = null,
        onLayoutRenamed: (uiView: UiView) => void = null, onLayoutDeleted: (uiView: UiView) => void = null): GridProps {
        this.uiViewId = uiViewId;
        this.uiViewContextCode = contextCode;
        this.uiViewFilters = uiViewFilters;
        this.uiViewAddProperties = uiViewAddProperties;
        this.onLayoutSaved = onLayoutSaved;
        this.onLayoutAdded = onLayoutAdded;
        this.onLayoutRenamed = onLayoutRenamed;
        this.onLayoutDeleted = onLayoutDeleted;

        if (parentThis && onLayoutSaved) {
            this.onLayoutSaved = onLayoutSaved.bind(parentThis);
        }

        if (parentThis && onLayoutAdded) {
            this.onLayoutAdded = onLayoutAdded.bind(parentThis);
        }

        if (parentThis && onLayoutRenamed) {
            this.onLayoutRenamed = onLayoutRenamed.bind(parentThis);
        }

        if (parentThis && onLayoutDeleted) {
            this.onLayoutDeleted = onLayoutDeleted.bind(parentThis);
        }

        return this;
    }

    setGridStateFunctions(parentThis: any = null, saveGridState: (state: any) => any = null, loadGridState: (state: any) => any = null): GridProps {
        if (parentThis && saveGridState) {
            this.saveGridState = saveGridState.bind(parentThis);
        }
        if (parentThis && loadGridState) {
            this.loadGridState = loadGridState.bind(parentThis);
        }
        return this;
    }

    setToSingleRowSelection(): GridProps {
        this.enableSingleRowSelection();

        return this;
    }

    setKeyColumn(columnName: string): GridProps {
        this.keyColumn = columnName;

        return this;
    }

    disableDML(): void {
        this.isAddingAllowed = false;
        this.isDeletingAllowed = false;
        this.isUpdatingAllowed = false;
    }

    addOnContentReadyFunction(parentThis: any, onContentReadyFunction: (e: CoreEventArguments) => void): GridProps {
        this.onContentReadyFunction = onContentReadyFunction.bind(parentThis);

        return this;
    }

    addOnEditorPreparingFunction(parentThis: any, onEditorPreparingFunction: (e: CoreEventArguments) => void): GridProps {
        this.onEditorPreparingFunction = onEditorPreparingFunction.bind(parentThis);

        return this;
    }

    addOnSelectionChangedFunction(parentThis: any, onSelectionChangedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onSelectionChangedFunction = onSelectionChangedFunction.bind(parentThis);

        return this;
    }

    addOnFocusedRowChangedFunction(parentThis: any, onFocusedRowChangedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onFocusedRowChangedFunction = onFocusedRowChangedFunction.bind(parentThis);

        return this;
    }

    addOnOptionChangedFunction(parentThis: any, onOptionChangedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onOptionChangedFunction = onOptionChangedFunction.bind(parentThis);

        return this;
    }

    addOnEditCanceledFunction(parentThis: any, addOnEditCanceledFunction: (e: CoreEventArguments) => void): GridProps {
        this.onEditCanceledFunction = addOnEditCanceledFunction.bind(parentThis);

        return this;
    }

    // Check e.brokenRules for any cell validation issues. Row validation will overwrite these by default.
    addOnRowValidatingFunction(parentThis: any, onRowValidatingFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowValidatingFunction = onRowValidatingFunction.bind(parentThis);

        return this;
    }

    addOnInitializedFunction(parentThis: any, onInitializedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onInitializedFunction = onInitializedFunction.bind(parentThis);

        return this;
    }

    addOnInitNewRowFunction(parentThis: any, onInitNewRowFunction: (e: CoreEventArguments) => void): GridProps {
        this.onInitNewRowFunction = onInitNewRowFunction.bind(parentThis);

        return this;
    }

    addOnEditingStartFunction(parentThis: any, addOnEditingStartFunction: (e: CoreEventArguments) => void): GridProps {
        this.onEditingStartFunction = addOnEditingStartFunction.bind(parentThis);

        return this;
    }

    addOnRowPreparedFunction(parentThis: any, onRowPreparedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowPreparedFunction = onRowPreparedFunction.bind(parentThis);

        return this;
    }

    addOnRowInsertingFunction(parentThis: any, onRowInsertingFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowInsertingFunction = onRowInsertingFunction.bind(parentThis);

        return this;
    }

    addOnRowInsertedFunction(parentThis: any, onRowInsertedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowInsertedFunction = onRowInsertedFunction.bind(parentThis);

        return this;
    }

    addOnRowUpdatingFunction(parentThis: any, onRowUpdatingFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowUpdatingFunction = onRowUpdatingFunction.bind(parentThis);

        return this;
    }

    addOnRowUpdatedFunction(parentThis: any, onRowUpdatedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowUpdatedFunction = onRowUpdatedFunction.bind(parentThis);

        return this;
    }

    addOnRowRemovingFunction(parentThis: any, onRowRemovingFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowRemovingFunction = onRowRemovingFunction.bind(parentThis);

        return this;
    }

    addOnRowRemovedFunction(parentThis: any, onRowRemovedFunction: (e: CoreEventArguments) => void): GridProps {
        this.onRowRemovedFunction = onRowRemovedFunction.bind(parentThis);

        return this;
    }

    addDMLFunctions(dmlFN: DevExpressDMLFunctions): GridProps {
        this.dataChangesFunction = dmlFN.dataChangesFunction;
        this.isUpdatingAllowed = dmlFN.isUpdatingAllowed;
        this.isAddingAllowed = dmlFN.isAddingAllowed;
        this.isDeletingAllowed = dmlFN.isDeletingAllowed;
        this.requiredProperties = dmlFN.requiredProperties;

        return this;
    }

    addModifyServerDataFunction(parentThis: any, modifyServerDataFunction: (data: any[]) => any[]): GridProps {
        this.modifyServerDataFunction = modifyServerDataFunction.bind(parentThis);

        return this;
    }

    setEditMode(editMode: EditModes): GridProps {
        this.editMode = editMode;

        return this;
    }

    enableExport(isExportEnabled: boolean = true, exportFileName: string = null): GridProps {
        this.isExportEnabled = isExportEnabled;
        this.exportFileName = exportFileName;

        return this;
    }

    setStateSaveLocal(): GridProps {
        if (this.gridId) {
            this.isStateSaveLocal = true;
            this.isColumnResizingAllowed = false;
        }
        return this;
    }

    setSearchEnabled(isSearchEnabled: boolean): GridProps {
        this.isSearchEnabled = isSearchEnabled;
        return this;
    }

    setColumnResizingAllowed(isColumnResizingAllowed: boolean): GridProps {
        this.isColumnResizingAllowed = isColumnResizingAllowed;
        return this;
    }

    addIsTableSelectCellEditorPopup(isTableSelectCellEditorPopup: boolean): GridProps {
        this.isTableSelectCellEditorPopup = isTableSelectCellEditorPopup;
        return this;
    }

    setColumnAutoWidth(columnAutoWidth: boolean): GridProps {
        this.columnAutoWidth = columnAutoWidth;
        return this;
    }
}

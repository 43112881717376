import { containerTypeIds } from '../constants/enums';
import { FileSource } from './file-source';

export enum DataColumnUsageCode {
    Produced = 2,
    Consumed = 1,
    Ignored = 0
}

export enum GearCode {
    SegmentGearCode = '0001',
    AssignGearCode = '0002',
    PeriodFilterGearCode = '0003',
    AccountNormalizeGearCode = '0004',
    EarnDateGearCode = '0005',
    CounterGearCode = '0006',
    ContextGearCode = '0007',
    FormulaGearCode = '0008',
    FilterGearCode = '0009',
    JoinGearCode = '0010',
    ConsolidateGearCode = '0011',
    HierarchyGearCode = '0012',
    UnionGearCode = '0013',
    UpdateXactionGearCode = '0014',
    AIContextGearCode = '0015',
    AITextGenerationGearCode = '0016',
    AIScoreGearCode = '0017',
    AIFilterGearCode = '0018',
    AISummaryGearCode = '0019',
    AIFileRetrievalGearCode = '0020'
}

export enum DiagramGearMappings {
    'Core.PeriodGear.Gear' = GearCode.PeriodFilterGearCode,
    'Core.Assign.Gear' = GearCode.AssignGearCode,
    'Core.AccountNormalize.Gear' = GearCode.AccountNormalizeGearCode,
    'Core.EarnDate.Gear' = GearCode.EarnDateGearCode,
    'Core.Counter.Gear' = GearCode.CounterGearCode,
    'Core.Formula.Gear' = GearCode.FormulaGearCode,
    'Core.Filter.Gear' = GearCode.FilterGearCode,
    'Core.Join.Gear' = GearCode.JoinGearCode,
    'Core.Consolidate.Gear' = GearCode.ConsolidateGearCode,
    'Core.Datasource.Gear' = GearCode.ContextGearCode,
    'Core.Hierarchy.Gear' = GearCode.HierarchyGearCode,
    'Core.Union.Gear' = GearCode.UnionGearCode,
    'Core.Update.Gear' = GearCode.UpdateXactionGearCode,
    'Core.AIContext.AIGear' = GearCode.AIContextGearCode,
    'Core.TextGeneration.AIGear' = GearCode.AITextGenerationGearCode,
    'Core.Score.AIGear' = GearCode.AIScoreGearCode,
    'Core.AIFilter.AIGear' = GearCode.AIFilterGearCode,
    'Core.Summary.AIGear' = GearCode.AISummaryGearCode,
    'Core.FileRetrieval.AIGear' = GearCode.AIFileRetrievalGearCode
}

export enum GearMiniLabel {
    'P' = GearCode.PeriodFilterGearCode,
    'A' = GearCode.AssignGearCode,
    'AN' = GearCode.AccountNormalizeGearCode,
    'ED' = GearCode.EarnDateGearCode,
    'G' = GearCode.CounterGearCode,
    'D' = GearCode.ContextGearCode,
    'F' = GearCode.FormulaGearCode,
    'Fi' = GearCode.FilterGearCode,
    'J' = GearCode.JoinGearCode,
    'Gr' = GearCode.ConsolidateGearCode,
    'H' = GearCode.HierarchyGearCode,
    'U' = GearCode.UnionGearCode,
    'Up' = GearCode.UpdateXactionGearCode,
}

export enum DataColumnType {
    SellerField,
    AccountFactors,
    AccountAttribute,
    GearIntroduced,
    QuantityField,
    TextField,
    DateField,
    TagField,
    TagGroup,
    CalculatedField,
    CoreInternalField,
    RecordMetaField,
    RuleIntroduced,
}

export class DataColumn {
    systemName: string;
    friendlyName: string;
    datatype: string;
    formatString: string;
    type: DataColumnType;
}

export class ProcessDataColumn {
    private static readonly OverwrittenColumnSystemNamePrefix: string = 'overwritten_';

    systemName: string;
    isPrimaryKeyPart: boolean;
    expression: string;
    usageCode: DataColumnUsageCode;

    get isPhysical(): boolean {
        return this.isPrimaryKeyPart || this.usageCode === DataColumnUsageCode.Produced;
    }

    get isOverwritten(): boolean {
        return this.systemName.startsWith(ProcessDataColumn.OverwrittenColumnSystemNamePrefix);
    }
}

// NOTE: Prefixed with 'Bb' to avoid name collision with built-in 'Object'. -DH
export abstract class BbObject {
    constructor(public id: string, public name: string, public parentId: string, public description: string) {
    }

    abstract get objectTypeCode(): string;
    abstract applyChanges(changes: any): void;
}

// TODO: Get rid of this class. -DH // Actually get rid of Group class below and remove abstract here -JH
export abstract class Container extends BbObject {
    // HACK: Without a class member here, any BbObject would pass for a Container. -DH
    typeId: number;
    recurrenceId: number;
    lastModified: string;

    // TODO: create abstract method to construct JSON
    objectJson: string;
    locked: boolean;
    isActive: boolean;
    isSegment?: boolean;
    isPayment?: boolean;
    periodBeginId: number | null;
    periodEndId: number | null;
}

export class Group extends Container {
    constructor(id: string, name: string, parentId: string, description: string, typeId: number = 1, objectJson: string = '', recurrenceId: number = null,
        lastModified: string = '', locked: boolean = false, public headProcessId: string = null, isActive: boolean = true, periodBeginId: number | null = null, periodEndId: number | null = null) {

        super(id, name, parentId, description);
        this.typeId = typeId;
        this.recurrenceId = recurrenceId;
        this.lastModified = lastModified;
        this.objectJson = objectJson;
        this.locked = locked;
        this.headProcessId = headProcessId;
        this.isActive = isActive;
        this.periodBeginId = periodBeginId;
        this.periodEndId = periodEndId;
    }

    static getObjectTypeCode(): string {
        return 'Co';
    }

    get objectTypeCode(): string {
        return Group.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class SetupRule extends Container {
    constructor(id: string, name: string, parentId: string, description: string, typeId: number = 4, objectJson: string = '', recurrenceId: number = null,
        lastModified: string = '', locked: boolean = false, isActive: boolean = true, periodBeginId: number | null = null, periodEndId: number | null = null) {
        super(id, name, parentId, description);
        this.typeId = typeId;
        this.recurrenceId = recurrenceId;
        this.lastModified = lastModified;
        this.objectJson = objectJson;
        this.locked = locked;
        this.isActive = isActive;
        this.periodBeginId = periodBeginId;
        this.periodEndId = periodEndId;
    }

    static getObjectTypeCode(): string {
        return 'Co';
    }

    get objectTypeCode(): string {
        return SetupRule.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class DatasourceProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceType: string,
        public columnString: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Ds';
    }

    get objectTypeCode(): string {
        return DatasourceProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class TransformProcessAuxSourceProcess {
    ordinal: number;
    processId: string;
    joinCondition: string;
}

export class TransformProcessWrittenColumn {
    systemName: string;
    expression: string;
}

export class TransformProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public mainSourceProcessId: string,
        public auxSourceProcesses: TransformProcessAuxSourceProcess[],
        public writtenColumns: TransformProcessWrittenColumn[]) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Tr';
    }

    get objectTypeCode(): string {
        return TransformProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class FilterProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public filterString: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Fi';
    }

    get objectTypeCode(): string {
        return FilterProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class CalculateProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public displayFormulaString: string,
        public sqlFormulaString: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Ca';
    }

    get objectTypeCode(): string {
        return CalculateProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
        this.displayFormulaString = changes.displayFormulaString;
    }
}

export class AggregateProcessKeyColumn {
    systemName: string;
}

export class AggregateProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public keyColumns: AggregateProcessKeyColumn[],
        public inputValueColumnSystemName: string,
        public functionCode: string) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Ag';
    }

    get objectTypeCode(): string {
        return AggregateProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }

    get outputValueColumnSystemName(): string {
        return `${this.id}_value`;
    }
}

export class UnionProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string,
        public sourceProcessId: string,
        public auxProcessId: string,
        public unionAll: boolean
        ) {
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'Un';
    }

    get objectTypeCode(): string {
        return UnionProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void {
    }
}

export class AIProcess extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string, public sourceProcessId: string){
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'AI';
    }

    get objectTypeCode(): string {
        return AIProcess.getObjectTypeCode();
    }

    applyChanges(changes: any): void{
    }
}

export enum GearPropertyDatatype {
    // eslint-disable-next-line id-blacklist
    String,
    MultiLineString,
    NullableInt,
    SegmentFilterBuilder,
    FormulaBuilder,
    DropDown,
    PeriodSelector,
    ApplyTo,
    CheckBox,
    AssignFields,
    PositiveWholeNumber,
    DayOfMonthWholeNumber,
    MonthWholeNumber,
    PeriodDropdown,
    FieldGrid,
    ReadOnlyString,
    GroupedDropDown,
    FilterBuilder,
    SegmentFormulaBuilder,
    UpdateXactionFields,
    ReadOnlyInt,
    UnionFields,
    FileManager,
    FileSource,
    PromptBuilder,
    ChunkQty
}

export class GearProperty {
    constructor(
        readonly datatype: GearPropertyDatatype,
        readonly systemName: string,
        readonly friendlyName: string,
        readonly groupFriendlyName: string,
        readonly conditionalDisplayArg?: string
    ) {
    }
}

export class GearPropertyValue {
    constructor(
        readonly property: GearProperty,
        public value: any
    ) {
    }
}

export abstract class GearType {

    abstract getGearTypeCode(): string;
    abstract getProperties(): readonly GearProperty[];
    abstract applyChanges(changes: any): void;
    abstract createSameGearType(): GearType;

}

export abstract class AIGearType {
    abstract getGearTypeCode(): string;
}

export class Gear extends Container {
    constructor(id: string, name: string, parentId: string, description: string,
        private readonly gearType: GearType,
        public headProcessId: string,
        readonly propertyValues: GearPropertyValue[],
        isPayment: boolean,
        isActive: boolean
        ) {
        super(id, name, parentId, description);
        this.isPayment = isPayment;
        this.isActive = isActive;
    }

    static getObjectTypeCode(): string {
        return 'Bb';
    }

    applyChanges(changes: any): void {
        this.gearType.applyChanges(changes);

        this.name = changes.name;
        this.parentId = changes.parentId;
        this.description = changes.description;

        for (const property of this.gearType.getProperties()) {
            const index: number = this.propertyValues.findIndex(originalProperty => originalProperty.property.systemName === property.systemName);
            this.propertyValues[index] = changes.propertyValues[index];
        }
    }

    get objectTypeCode(): string {
        return Gear.getObjectTypeCode();
    }

    get gearTypeCode(): string {
        return this.gearType.getGearTypeCode();
    }
}

export class SegmentGearType extends GearType {

    static getObjectTypeCode(): string {
        return 'Sg';
    }

    getGearTypeCode(): string {
        return GearCode.SegmentGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Datasource', 'Source'),
            new GearProperty(GearPropertyDatatype.PeriodSelector, 'periodFilterString', 'Period Filter', 'Source'),
            new GearProperty(GearPropertyDatatype.SegmentFilterBuilder, 'criteriaFilterString', 'Criteria Filter', 'Data Filter'),
            new GearProperty(GearPropertyDatatype.DropDown, 'earnDateSystemName', 'Earn Date', 'Eligibility'),
            new GearProperty(GearPropertyDatatype.SegmentFilterBuilder, 'criteriaFilterString', 'Account Filter', 'Eligibility'),
            new GearProperty(GearPropertyDatatype.SegmentFormulaBuilder, 'displayFormulaString', 'Formula', 'Calculate'),
            new GearProperty(GearPropertyDatatype.NullableInt, 'precision', 'Precision', 'Calculate'),
            new GearProperty(GearPropertyDatatype.DropDown, 'runningSumDateFieldSystemName', 'Running Sum', 'Calculate'),
            new GearProperty(GearPropertyDatatype.ReadOnlyInt, 'isPayout', 'Payment', 'Calculate', 'false'),
            new GearProperty(GearPropertyDatatype.ReadOnlyInt, 'orderIndex', 'Level', 'Calculate'),
            new GearProperty(GearPropertyDatatype.ApplyTo, 'redir', 'Apply to', 'Output'),
        ];
    }

    createSameGearType(): GearType {
        return new SegmentGearType();
    }

    applyChanges(changes: any): void {
    }
}

export class CounterGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.CounterGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.FieldGrid, 'aggregateFields', 'Group By Me', null),
        ];
    }

    createSameGearType(): GearType {
        return new CounterGear();
    }

    applyChanges(changes: any): void {
    }
}

export class ConsolidateGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.ConsolidateGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
        ];
    }

    createSameGearType(): GearType {
        return new ConsolidateGear();
    }

    applyChanges(changes: any): void {
    }
}

export class HierarchyGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.HierarchyGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null)
        ];
    }

    createSameGearType(): GearType {
        return new HierarchyGear();
    }

    applyChanges(changes: any): void {
    }
}

export class EarnDateGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.EarnDateGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'earnDateSystemName', 'Earn Date', 'Eligibility'),
        ];
    }

    createSameGearType(): GearType {
        return new EarnDateGear();
    }

    applyChanges(changes: any): void {
    }
}

export class AccountNormalizeGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.AccountNormalizeGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
        ];
    }

    createSameGearType(): GearType {
        return new AccountNormalizeGear();
    }

    applyChanges(changes: any): void {
    }
}

export class AssignGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.AssignGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.DropDown, 'beginDateField', 'Begin Date Field', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'endDateField', 'End Date Field', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'activityDateField', 'Activity Date Field', null),
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Copy To', null),
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'auxProcessId', 'Copy From', null),
            new GearProperty(GearPropertyDatatype.CheckBox, 'includeNonMatchingRows', 'Include Non-Matching Rows', null),
            new GearProperty(GearPropertyDatatype.CheckBox, 'dataRowsArePersisted', 'Update Records', null),
            new GearProperty(GearPropertyDatatype.FilterBuilder, 'filterBuilderObject', 'Join Condition', null),
            new GearProperty(GearPropertyDatatype.AssignFields, 'sourceAndAuxFields', 'Assign Fields', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'joinHint', 'Join Hint', null, `isShowAdvancedView`),
        ];
    }

    createSameGearType(): GearType {
        return new AssignGear();
    }

    applyChanges(changes: any): void {
    }
}

export class FormulaGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.FormulaGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.FormulaBuilder, 'sqlFormulaString', 'Formula', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'datatype', 'Data Type', null),
            new GearProperty(GearPropertyDatatype.CheckBox, 'convertIntegersToFloat', 'Convert Ints', null, 'isShowAdvancedView'),
            new GearProperty(GearPropertyDatatype.String, 'format', 'Format String', null, `focusedGear.propertyValues.find(prop => prop.property.systemName === 'datatype').value !== 'string'`),
            new GearProperty(GearPropertyDatatype.ReadOnlyString, 'dependentIds', 'Dependent Ids', null, `isShowAdvancedView`)
        ];
    }

    createSameGearType(): GearType {
        return new FormulaGear();
    }

    applyChanges(changes: any): void {
    }
}

export class FilterGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.FilterGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.FilterBuilder, 'filterBuilderObject', 'Filter', null),
            new GearProperty(GearPropertyDatatype.ReadOnlyString, 'filterString', 'Filter String', null, `isShowAdvancedView`),
        ];
    }

    createSameGearType(): GearType {
        return new FilterGear();
    }

    applyChanges(changes: any): void {
    }
}

export class PeriodFilterGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.PeriodFilterGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.DropDown, 'dateRangeTypeCode', 'Filter Type', null),
            new GearProperty(GearPropertyDatatype.PositiveWholeNumber, 'previousPeriods', 'Previous Periods', null,
                `['S','R'].includes(focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new GearProperty(GearPropertyDatatype.CheckBox, 'excludeCurrentPeriod', 'Exclude Current Period', null,
                `focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'R'`),
            new GearProperty(GearPropertyDatatype.MonthWholeNumber, 'dateRangeMonthNo', 'Date Range Month Number', null,
                `['Q','Y','L','P','X','N','W'].includes(focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new GearProperty(GearPropertyDatatype.DayOfMonthWholeNumber, 'dateRangeDayNo', 'Date Range Date Number', null,
                `['M','Q','Y','L','P','X','N','W'].includes(focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new GearProperty(GearPropertyDatatype.PeriodDropdown, 'fromPeriodId', 'From period id', null,
                `focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'S'`),
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null)
        ];
    }

    createSameGearType(): GearType {
        return new PeriodFilterGear();
    }

    applyChanges(changes: any): void {
    }
}

export class ContextGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.ContextGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.DropDown, 'dateRangeTypeCode', 'Filter Type', null),
            new GearProperty(GearPropertyDatatype.PositiveWholeNumber, 'previousPeriods', 'Previous Periods', null,
                `['S','R'].includes(focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new GearProperty(GearPropertyDatatype.CheckBox, 'excludeCurrentPeriod', 'Exclude Current Period', null,
                `focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'R'`),
            new GearProperty(GearPropertyDatatype.MonthWholeNumber, 'dateRangeMonthNo', 'Date Range Month Number', null,
                `['Q','Y','L','P','X','N','W','Sem', 'PSem', 'Tri', 'PTri'].includes(focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new GearProperty(GearPropertyDatatype.DayOfMonthWholeNumber, 'dateRangeDayNo', 'Date Range Date Number', null,
                `['M','Q','Y','L','P','X','N','W', 'Sem', 'PSem', 'Tri', 'PTri'].includes(focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value)`),
            new GearProperty(GearPropertyDatatype.PeriodDropdown, 'fromPeriodId', 'From period id', null,
                `focusedGear.propertyValues.find(prop => prop.property.systemName === 'dateRangeTypeCode').value === 'S'`),
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.CheckBox, 'useAccountNormalize', 'Use Account Normalize', 'Advanced'),
            new GearProperty(GearPropertyDatatype.CheckBox, 'useEarnDate', 'Use Earn Date', 'Advanced'),
            new GearProperty(GearPropertyDatatype.CheckBox, 'simpleFilter', 'Use Simple Filter', 'Advanced', `isShowAdvancedView`),
            new GearProperty(GearPropertyDatatype.DropDown, 'earnDateSystemName', 'Earn Date', 'Eligibility'),
        ];
    }

    createSameGearType(): GearType {
        return new ContextGear();
    }

    applyChanges(changes: any): void {
    }
}

export class JoinGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.JoinGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Copy To', null),
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'auxProcessId', 'Copy From', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'joinType', 'Join Type', null),
            new GearProperty(GearPropertyDatatype.CheckBox, 'dataRowsArePersisted', 'Update Records', null),
            new GearProperty(GearPropertyDatatype.CheckBox, 'copySystemColumns', 'Copy System Columns', null, 'isShowAdvancedView'),
            new GearProperty(GearPropertyDatatype.FilterBuilder, 'filterBuilderObject', 'Join Condition', null,
                `focusedGear.propertyValues.find(prop => prop.property.systemName === 'joinType').value != 5`),
            new GearProperty(GearPropertyDatatype.AssignFields, 'sourceAndAuxFields', 'Joined/Aliased Fields', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'joinHint', 'Join Hint', null, 'isShowAdvancedView'),
        ];
    }

    createSameGearType(): GearType {
        return new JoinGear();
    }

    applyChanges(changes: any): void {
    }
}

export class UnionGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.UnionGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Primary Source', null),
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'auxProcessId', 'Aux Source', null),
            new GearProperty(GearPropertyDatatype.UnionFields, 'fieldMaps', 'Field Maps', null),
        ];
    }

    createSameGearType(): GearType {
        return new UnionGear();
    }

    applyChanges(changes: any): void {
    }
}

export class UpdateXactionGear extends GearType {
    getGearTypeCode(): string {
        return GearCode.UpdateXactionGearCode;
    }

    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Primary Source', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'datasourceId', 'Datasource', null),
            new GearProperty(GearPropertyDatatype.UpdateXactionFields, 'updateFields', 'Fields', null),
        ];
    }

    createSameGearType(): GearType {
        return new UpdateXactionGear();
    }

    applyChanges(changes: any): void {
    }
}

export class AIContextGear extends GearType {
    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'fileSource', 'File Source', null)
        ];
    }
    applyChanges(changes: any): void {
    }
    createSameGearType(): GearType {
        return new AIContextGear();
    }
    getGearTypeCode(){
        return GearCode.AIContextGearCode;
    }
}

export class AIFilterGear extends GearType {
    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.PromptBuilder, 'instructions', 'Filter Instructions', null)
        ];
    }

    applyChanges(changes: any): void {
    }

    createSameGearType(): GearType {
        return new AIFilterGear();
    }

    getGearTypeCode(){
        return GearCode.AIFilterGearCode;
    }
}

export class AIScoreGear extends GearType {
    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.PromptBuilder, 'instructions', 'Scoring Instructions', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'scoreType', 'Score Type',  null)
        ];
    }

    applyChanges(changes: any): void {
    }

    createSameGearType(): GearType {
        return new AIScoreGear();
    }

    getGearTypeCode(){
        return GearCode.AIScoreGearCode;
    }
}

export class AITextGenerationGear extends GearType {
    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.PromptBuilder, 'instructions', 'Prompt Builder', null)
        ];
    }

    applyChanges(changes: any): void {
    }

    createSameGearType(): GearType {
        return new AITextGenerationGear();
    }

    getGearTypeCode(){
        return GearCode.AITextGenerationGearCode;
    }
}

export class AIFileRetrievalGear extends GearType {
    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process', null),
            new GearProperty(GearPropertyDatatype.FileManager, 'blobId', 'File Source', null),
            new GearProperty(GearPropertyDatatype.DropDown, 'seedRef', 'Search Seed', null),
            new GearProperty(GearPropertyDatatype.ChunkQty, 'chunkQty', 'Number of Documents', null)
        ];
    }

    applyChanges(changes: any): void {
    }

    createSameGearType(): GearType {
        return new AIFileRetrievalGear();
    }

    getGearTypeCode(){
        return GearCode.AIFileRetrievalGearCode;
    }
}

export class AISummaryGear extends GearType {
    getProperties(): readonly GearProperty[] {
        return [
            new GearProperty(GearPropertyDatatype.GroupedDropDown, 'sourceProcessId', 'Source Process Id', null),
            new GearProperty(GearPropertyDatatype.PromptBuilder, 'seedInstructions', 'Seeding Instructions', null),
            new GearProperty(GearPropertyDatatype.MultiLineString, 'instructions', 'Summary Instructions', null),
            new GearProperty(GearPropertyDatatype.FieldGrid, 'aggregateFields', 'Columns to Group', null)
        ];
    }

    applyChanges(changes: any): void {
    }

    createSameGearType(): GearType {
        return new AISummaryGear();
    }

    getGearTypeCode(){
        return GearCode.AISummaryGearCode;
    }
}

export class FileSourceUIObj extends BbObject {
    constructor(id: string, name: string, parentId: string, description: string, public sourceProcessId: string){
        super(id, name, parentId, description);
    }

    static getObjectTypeCode(): string {
        return 'FS_UI';
    }

    get objectTypeCode(): string {
        return FileSourceUIObj.getObjectTypeCode();
    }

    applyChanges(changes: any): void{
    }
}

export class ContainerPlan {
    id: string;
    name: string;
    description: string;
    eligible: string;
    recurrence: string;
    lastModified: string;
    activeRules: ContainerRule[];
    allRules: ContainerRule[];
    showInactiveRules: boolean;
    locked: boolean;
    isActive: boolean;

    constructor(id: string, name: string, description: string, objectJson: string,
        recurrence: string, lastModified: string, locked: boolean, rules: ContainerRule[], isActive: boolean) {
        if (objectJson === undefined || objectJson === null || objectJson === '') {
            objectJson = '{}';
        }
        const displayProperties = JSON.parse(objectJson);

        this.id = id;
        this.name = name;
        this.description = description;
        this.eligible = displayProperties.eligible;
        this.recurrence = recurrence;
        this.lastModified = lastModified;
        this.allRules = rules;
        this.activeRules = rules.filter(x => x.isActive);
        this.showInactiveRules = false;
        this.locked = locked;
        this.isActive = isActive;
    }
}

export class ContainerRule {
    id: string;
    name: string;
    description: string;
    lastModified: string;
    type: string;
    status: string;
    dataSource: string;
    isPayment: boolean;
    isActive: boolean;
    locked: boolean;
    level: number;
    isSegment: boolean;
    periodBeginId: number;
    periodEndId: number | null;

    constructor(id: string, name: string, description: string, lastModified: string, typeId: number,
        locked: boolean, objectJson: string, level: number, isActive: boolean, periodBeginId: number, periodEndId: number, isSegment?: boolean, isPayment?: boolean) {
        if (objectJson === undefined || objectJson === null || objectJson === '') {
            objectJson = '{}';
        }
        const displayProperties = JSON.parse(objectJson);

        this.id = id;
        this.name = name;
        this.description = description;
        this.lastModified = lastModified;
        this.type = typeId === containerTypeIds.SetupRule ? 'Setup' : 'Production';
        this.status = displayProperties.status;
        this.dataSource = displayProperties.dataSource;
        this.isPayment = (isPayment !== undefined) ? isPayment : (displayProperties.isPayment !== undefined ? displayProperties.isPayment : false);
        this.isActive = isActive;
        this.locked = locked;
        this.level = level ?? displayProperties.level;
        this.isSegment = isSegment;
        this.periodBeginId = periodBeginId;
        this.periodEndId = periodEndId;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Calculation } from '../models/calculation';
import { CoreRequest } from '../models/core-request';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class CalculationService {
    private baseurl: string = environment.apiEndpoint;
    private calculationURL = this.baseurl + '/calculation/';

    constructor(private http: HttpClient) {
    }

    insertCalculations(calculations: Calculation[]) {
        return this.http.post<Calculation[]>(this.calculationURL + 'many', new CoreRequest<Calculation>(null, calculations));
    }

    updateCalculations(calculations: Calculation[]) {
        return this.http.put<number>(this.calculationURL + 'bulk', new CoreRequest<Calculation>(null, calculations));
    }

    deleteCalculations(ids: number[]) {
        return this.http.post<number>(this.calculationURL + 'deleteBulkByIds', ids);
    }

    deleteCalculationsBySetIds(ids: string[]) {
        return this.http.post<number>(this.calculationURL + 'deleteBulkBySetIds', ids);
    }

    deleteCalculationsByXactionIds(xactionIds: number[]) {
        return this.http.post<number>(this.calculationURL + 'deleteBulkByXactionIds', xactionIds);
    }

    getCalculationsCountByXactionIds(xactionIds: number[]) {
        return this.http.post<number>(this.calculationURL + 'getUnlockedByXactionIds', xactionIds);
    }

    deleteCalculationsByPeriod(seriesId: number, periodId: number) {
        return this.http.delete<CoreResponse<number>>(this.calculationURL + 'ByPeriod/' + seriesId + '/' + periodId);
    }

    getStaleRulesByPeriod(periodId: number) {
        return this.http.get<CoreResponse<string>>(this.calculationURL + `staleRules/${periodId}`);
    }
}

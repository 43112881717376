<div id="page" (window:resize)="onResize($event)">
    <core-popup #processingPopup [props]="processingPopupProps" [steps]="processingPopupSteps"></core-popup>
    <core-popup #decisionPopup [props]="decisionPopupProps" [steps]="decisionPopupSteps"></core-popup>
    <div class="data-display">
        <div *ngIf="apiCallsReturned" class="dropdowns-wrapper">
            <div class="working-in-content">
                <app-period-dropdown
                    #periodDropdown
                    [dropdownProps]="periodDropdownProps"
                    [selectedPeriodId]="selectedPeriodId"
                    (selectedPeriodIdChange)="onPeriodChanged($event)"
                    [(selectedRecurrenceId)]="selectedRecurrenceId"
                    [(selectedSeriesId)]="selectedSeriesId">
                </app-period-dropdown>
            </div>
            <div class="working-in-content working-in-content-right">
                <div class="dropdown-label">Layout</div>
                <core-dropdown
                    #layoutDropdown
                    class="working-in-content"
                    dropdownName="layout"
                    [(selectedValue)]="selectedUiViewId"
                    [dataSource]="filteredLayouts"
                    [popupProps]="layoutPopupProps"
                    [props]="layoutDropdownProps"
                    (selectionChanged)="onLayoutChanged($event)"
                    (onDelete)="deleteLayout($event)"
                    (onAdd)="addLayout($event)"
                    (onRename)="renameLayout($event)"
                    (onSave)="saveLayout($event)"
                    (onCheckUncheck)="onLayoutCheckBoxClick($event)">
                </core-dropdown>
            </div>
        </div>
        <div id="actionsMenu" class="processing-actions-menu">
            <div id="actionsMenuTop" class="processing-actions-menu-top">
                <dx-drop-down-button
                    text="View Imported"
                    hint="View Selected Imported"
                    class="processing-action"
                    [visible]="permissionViewImportedRecords"
                    [splitButton]="true"
                    keyExpr="datasourceId"
                    displayExpr="name"
                    [dataSource]="viewImportedItems"
                    noDataText="No layouts exist"
                    [dropDownOptions]="{ width: 'auto' }"
                    (onButtonClick)="viewAllImported($event)"
                    (onItemClick)="viewAllImported($event)">
                </dx-drop-down-button>
                <dx-button
                    text="Refresh Page"
                    hint="Refresh all page content"
                    class="processing-action"
                    [visible]="false"
                    (onClick)="refreshPage($event)">
                </dx-button>
                <dx-button
                    [text]="showHideInactiveMsg"
                    hint="Filters Imported and Processed grids adding or removing inactive segments and datasources"
                    class="processing-action"
                    (onClick)="ontoggleActiveSegmentsDatasourceClick($event)">
                </dx-button>
            </div>
            <div id="actionsMenuMiddle" class="processing-actions-menu-middle">
                <dx-drop-down-button
                    text="Run Cycle"
                    [splitButton]="true"
                    class="processing-action processing-action-large processing-action-bold run-cycle"
                    [visible]="permissionRunCycle"
                    keyExpr="id"
                    displayExpr="description"
                    [dataSource]="runCycleItems"
                    noDataText="No executable plans"
                    [dropDownOptions]="{ width: 'auto' }"
                    (onButtonClick)="performRunCycleCheck($event)"
                    (onItemClick)="performRunCycleCheck($event)">
                </dx-drop-down-button>
            </div>
            <div id="actionsMenuBottom" class="processing-actions-menu-bottom">
                <dx-button
                    text="Stale Output"
                    hint="Stale output for this period"
                    class="processing-action"
                    [class.orange-button]="!this.disableStaleRules"
                    [visible]="permissionDeleteProcessedRecords"
                    [disabled]="this.disableStaleRules"
                    (onClick)="openStaleOutputPopup()">
                </dx-button>
                <dx-button
                    text="Delete Processed"
                    hint="Delete all processed output for this period"
                    class="processing-action"
                    [visible]="permissionDeleteProcessedRecords"
                    [disabled]="isPeriodLocked"
                    (onClick)="openDeleteProcessedPopup()">
                </dx-button>
                <dx-drop-down-button
                    text="View Processed"
                    hint="View Selected Processed"
                    class="processing-action"
                    [visible]="permissionViewProcessedRecords"
                    [splitButton]="true"
                    keyExpr="datasourceId"
                    displayExpr="name"
                    [dataSource]="viewProcessedItems"
                    noDataText="No layouts exist"
                    [dropDownOptions]="{ width: 'auto' }"
                    (onButtonClick)="viewAllProcessed($event)"
                    (onItemClick)="viewAllProcessed($event)">
                </dx-drop-down-button>
                <dx-button
                    text="Update Analytics"
                    hint="Update the Analytics view"
                    class="processing-action processing-action-bold"
                    [visible]="permissionUpdateAnalytics"
                    (onClick)="updateAnalytics($event)">
                </dx-button>
            </div>
        </div>
        <div #accordionWrapper class="processing-grids">
            <dx-accordion
                #importedAccordion
                class="stacked-accordion"
                [collapsible]="true"
                [multiple]="true"
                [animationDuration]="100"
                [dataSource]="[{ name: 'Imported Records' }]"
                (onItemClick)="onAccordionClick($event)">
                <div *dxTemplate="let name of 'title'">
                    {{ name.name }}
                </div>
                <div *dxTemplate="let order of 'item'">
                    <core-data-grid
                        #importedRecordsGrid
                        [dataSource]="filteredImportedRecords"
                        [columns]="importedColumns"
                        [props]="importedGridProps">
                    </core-data-grid>
                </div>
            </dx-accordion>
            <dx-accordion
                #processedAccordion
                class="stacked-accordion"
                [collapsible]="true"
                [multiple]="true"
                [animationDuration]="100"
                [dataSource]="[{ name: 'Processed Records' }]"
                (onItemClick)="onAccordionClick($event)">
                <div *dxTemplate="let name of 'title'">
                    {{ name.name }}
                </div>
                <div *dxTemplate="let order of 'item'">
                    <core-tree-list
                        #processedTreeList
                        [dataSource]="filteredProcessedRecords"
                        [columns]="processedColumns"
                        [props]="processedTreeListProps">
                    </core-tree-list>
                </div>
            </dx-accordion>
        </div>
    </div>
</div>

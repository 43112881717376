export class BuildingBlocksSettings {
    public static debugMode: boolean = false;
    public static shareNodes: boolean = false;
    public static useTreeLayout: boolean = false;
    public static maxRecursionDepth: number = 100;

    public static formatFunctionNameAsResult(name: string) {
        return `[Result: ${name}]`;
    }
}

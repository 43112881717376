import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HelperService } from '../services/helper.service';
import { ImportedTable } from '../models/processing-imported-table';
import { ProcessedTable } from '../models/processing-processed-table';
import { ProcessingContext } from '../models/contexts/processing-context';
import { IGCacheRefresh } from '../models/ig-cache-refresh';
import { ProcessRulesContext } from '../models/contexts/process-rules-context';
import { CoreRequest } from '../models/core-request';
import { CoreResponse } from '../models/core-response';
import { AIProcessedTable } from '../models/processing-ai-processed-table';

@Injectable()
export class ProcessingService {
    private baseurl: string = environment.apiEndpoint;
    private processingURL = this.baseurl + '/processing';

    constructor(private http: HttpClient, private helperService: HelperService) {

    }

    getAuditRows(calcSetId: string): Observable<any[]> {
        return this.http.get<any[]>(this.processingURL + '/audit/' + calcSetId);
    }

    getImportedTable(seriesId: number, recurrenceId: number, periodId: number): Observable<ImportedTable[]> {
        const argArray: any[] = [seriesId, recurrenceId, periodId].filter(x => !this.helperService.isNullOrUndefined(x));
        return this.http.get<ImportedTable[]>(
            this.processingURL + '/importedTable' + this.helperService.delimitValues(argArray, '/'));
    }

    getProcessedTable(seriesId: number, recurrenceId: number, periodId: number): Observable<ProcessedTable[]> {
        const argArray: any[] = [seriesId, recurrenceId, periodId].filter(x => !this.helperService.isNullOrUndefined(x));
        return this.http.get<ProcessedTable[]>(
            this.processingURL + '/processedTable' + this.helperService.delimitValues(argArray, '/')
        );
    }

    getAIProcessedTable(seriesId: number, recurrenceId: number, periodId: number): Observable<CoreResponse<AIProcessedTable>>{
        const argArray: any[] = [seriesId, recurrenceId, periodId].filter(x => !this.helperService.isNullOrUndefined(x));
        return this.http.get<CoreResponse<AIProcessedTable>>(
            this.processingURL + '/AIProcessedTable' + this.helperService.delimitValues(argArray, '/')
        );
    }

    getProcessingInProgress(): Observable<boolean> {
        return this.http.get<boolean>(this.processingURL + '/isprocessing');
    }

    getWarningMessagesForPlanProcessing(plan: ProcessingContext): Observable<string[]> {
        const url = this.processingURL + '/getWarningMessagesForPlanProcessing';
        return this.http.post<string[]>(url, plan);
    }

    copyPlan(planId: number): Observable<boolean> {
        const url = this.processingURL + '/copyPlan/' + planId;
        return this.http.post<boolean>(url, {});
    }

    processPlan(request: CoreRequest<ProcessingContext>): Observable<boolean> {
        const url = this.processingURL;
        return this.http.post<boolean>(url, request);
    }

    processSteps(request: CoreRequest<ProcessingContext>): Observable<boolean> {
        const url = this.processingURL + '/processSteps';
        return this.http.post<boolean>(url, request);
    }

    updateIgCache(request: IGCacheRefresh) {
        const url = this.processingURL + '/updateIGCache';
        return this.http.post<boolean>(url, request);
    }

    processRulesByRequest(request: CoreRequest<ProcessRulesContext>): Observable<CoreResponse<any>> {
        return this.http.put<CoreResponse<any>>(this.processingURL, request);
    }

    cancelProcessing(): Observable<boolean> {
        return this.http.put<boolean>(this.processingURL + '/cancel', {});
    }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, ViewChild, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { DxPopoverComponent, DxTextBoxComponent, DxToolbarComponent } from 'devextreme-angular';
import { CoreBotRequest } from '../../../models/core-bot-request';
import { NbButton, NbChatComponent } from '@nebular/theme';
import * as events from 'devextreme/events';
import { SellerService } from '../../../services/seller.service';
import { CoreBotService } from '../../../services/core-bot.service';
import { AnalyticService } from '../../../services/analytic.service';
import { CoreBotPopupContentComponent } from '../corebot-shared/popup-content/corebot-popup-content.component';
import { environment } from 'src/environments/environment';
import { CoreFeature, coreResponseCodes } from 'src/app/shared/constants/enums';
import { BasicChatMessage } from 'src/app/shared/models/chat-messages/basic-chat-message';
import { UserMessage } from 'src/app/shared/models/chat-messages/user-message';
import { BotTypingMessage } from 'src/app/shared/models/chat-messages/bot-typing-message';
import { ConfirmButtomMessage } from 'src/app/shared/models/chat-messages/confirm-button-message';
import { RedirectButtonMessage } from 'src/app/shared/models/chat-messages/redirect-message-button';
import { ChatBotMessage } from 'src/app/shared/models/chat-messages/chatbot-message';
import { QueryBotMessage } from 'src/app/shared/models/chat-messages/querybot-message';
import { PermissionService } from 'src/app/shared/services/permission.service';

@Component({
    selector: 'app-chatbot-chat',
    templateUrl: './chatbot-chat.component.html',
    styleUrls: ['./chatbot-chat.component.scss']
})
export class ChatBotChatComponent implements OnInit, AfterViewInit {
    @ViewChild('nebularChat', {static: false}) nebularChat: NbChatComponent;
    @ViewChild('chatBotInput') chatBotInput: ElementRef;
    @ViewChild('sendButton') sendButton: NbButton;
    @ViewChild('newConversationButton') newConversationButton: DxToolbarComponent;
    @ViewChild('chatContent') chatContent: CoreBotPopupContentComponent;
    @ViewChildren('likeFeedback') likeFeedbackPopovers: DxPopoverComponent[];
    @ViewChildren('dislikeFeedback') dislikeFeedbackPopovers: DxPopoverComponent[];

    @Input() buttonIcon: string = 'paper-plane-outline';
    @Input() message: string = '';
    @Output() onInputChange = new EventEmitter<string>();

    @Input() showPopup: boolean = false;
    @Input() showToolbarItems: boolean = true;
    @Output() showPopupChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    coreBotConversationId: string = null;
    showLoadingIndicator: boolean = false;
    messages: any[] = [
        new BasicChatMessage(
            'Hello! I\'m CoreBot, your Core Commissions support assistant. How can I help today?',
            'CoreBot',
            '../../../../assets/images/AIBot.png'
        )
    ];
    sellerName: string = '';
    feedbackContent: string = '';
    isFeedbackPositive: boolean;
    extraFeedbackSubmitted: boolean;
    nonDefaultAnalytic: number;
    request: CoreBotRequest;
    bot: string;
    isUsingCoreBotBeta: boolean = false;
    isSendButtonDisabled: boolean = true;
    botOverride: boolean = false;
    samplePrompt: string = '';
    isSupportVisible: boolean = false;
    userHasEverybodyBotAccess: boolean = false;
    userHasAdminBotAccess: boolean = false;

    constructor(
        private sellerService: SellerService,
        private coreBotService: CoreBotService,
        private analyticService: AnalyticService,
        private permissionService: PermissionService
    )
    {
        this.clearCoreBotConversation = this.clearCoreBotConversation.bind(this);
        this.openCoreBotPopout = this.openCoreBotPopout.bind(this);
        this.showTicketWidget = this.showTicketWidget.bind(this);
    }

    ngOnInit(): void {
        this.sellerService.getMe().subscribe(seller => {
            this.sellerName = seller.name;
        });
        this.userHasEverybodyBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseEverybodyCoreBotTools.toString());
        this.userHasAdminBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());
        this.analyticService.getAnalytics().subscribe(analytics => {
            const nonDefaultAnalytic = analytics.find(x => x.isDefault === false);
            if (nonDefaultAnalytic){
                this.nonDefaultAnalytic = nonDefaultAnalytic.id;
            }
        });
        this.samplePrompt = this.getSamplePrompt();
    }

    ngAfterViewInit(): void {
        this.sendButton.disabled = true;
    }

    onContentReady(e){
        events.on(e.component.content(), 'dxmousewheel', null, (ev) => {
            ev.stopPropagation();
        });
    }

    sendMessageChatBot(e: any){
        if (this.message.length > 0){
            this.request = {
                prompt: this.message,
                conversationId: this.coreBotConversationId,
                environment: environment.production ? 'production' : 'test'
            };

            this.messages.push(new UserMessage(this.sellerName, this.message));
            this.message = '';
            this.samplePrompt = 'Chat with CoreBot...';
            this.disableChatBotFields();

            setTimeout(() => {
                this.messages.push(new BotTypingMessage());
            }, 400);

            this.coreBotService.getQuestionType(this.request).subscribe(async response => {
                this.coreBotConversationId = response.conversationId;
                this.request.conversationId = response.conversationId;

                await this.getChatResponse(response.response).then(result => {
                    this.enableChatBotFields();
                    if (this.botOverride === true){
                        this.botOverride = false;
                    }
                });
            });
        }
    }

    getChatResponse(bot: string){
        return new Promise<boolean>(async (res) =>{
            const overrideRegex = /^(ChatBot,|CoreBot,|QueryBot,)/i;
            const match = this.request.prompt.match(overrideRegex);
            if (match){
                this.botOverride = true;
                if(match[1].toLowerCase().replace(',', '').includes('querybot')){
                    bot = 'QueryBot';
                }
                else{
                    bot = 'ChatBot';
                }
            }

            if ((bot === ('AnalyticBot') || bot === ('RuleBot')) && this.userHasAdminBotAccess) {
                this.messages.pop();
                this.bot = bot;
                this.messages.push(new RedirectButtonMessage('It looks like another bot named \'' + bot + '\' might better be able to handle your request. ' +
                    'Would you like me to take you there?'));
                res(true);
            }
            else if (bot === 'QueryBot' && this.userHasAdminBotAccess) {
                const e: any = '';
                await this.getQueryBotResponse(e).then(x => {
                    res(true);
                });
            }
            else {
                this.coreBotService.getModelCompletion(this.request).subscribe(chatResponse =>{
                    this.messages.pop();
                    this.coreBotConversationId = chatResponse.conversationId;
                    this.messages.push(new ChatBotMessage(chatResponse));
                    this.checkWidgetVisibility();
                    res(true);
                });
            }
        });
    }

    getFollowUpResponse(e: any){
        const bot = this.messages[this.messages.length - 1].customMessageData.bot;
        this.messages.pop();

        setTimeout(() => {
            this.messages.push(new BotTypingMessage());
        }, 400);

        setTimeout(() => {
            this.getChatResponse(bot).then(x => {
                this.enableChatBotFields();
            });
        }, 400);
    }

    resetChat(e: any){
        this.messages.pop();
        this.enableChatBotFields();
    }

    scrollMessagesToBottom(e: any){
        this.chatContent.nebularChat.scrollListBottom();
        document.getElementById('chatBotInput').focus();
    }

    redirect(e: any){
        localStorage.setItem('CoreBotQuestion', this.request.prompt);
        setTimeout(() =>{
            window.location.href = this.chooseOtherBot(this.bot);
        }, 2500);
    }

    getChatBotResponse(e: any) {
        this.disableChatBotFields();
        this.messages.pop();
        setTimeout(() => {
            this.messages.push(new BotTypingMessage());
        }, 400);

        this.coreBotService.getModelCompletion(this.request).subscribe(chatResponse => {
            this.messages.pop();
            this.coreBotConversationId = chatResponse.conversationId;
            this.enableChatBotFields();
            setTimeout(() => {
                this.messages.push(new ChatBotMessage(chatResponse));
                this.checkWidgetVisibility();
            }, 450);
        });
    }

    getQueryBotResponse(e: any){
        return new Promise((res) => {
            this.coreBotService.getQueryBotResponse(this.request).subscribe(result => {
                this.messages.pop();
                this.coreBotConversationId = result.conversationId;
                setTimeout(() => {
                    if (result.responseCode === coreResponseCodes.Error || result.responseCode === coreResponseCodes.NoDataFound){
                        this.messages.push(new BasicChatMessage(result.response, 'QueryBot', '../../../../assets/images/query-bot.png'));
                        res(true);
                    }
                    else {
                        const responseTable = result.response.split('<<<>>>')[0];
                        const responseQuery = result.response.split('<<<>>>')[1];
                        const explainerRequest: CoreBotRequest = {
                            prompt: responseQuery,
                            environment: environment.production ? 'production' : 'test',
                            conversationId: this.coreBotConversationId
                        };
                        const table = JSON.parse(responseTable);
                        this.messages.push(new QueryBotMessage(result, table));
                        setTimeout(() => {
                            this.messages.push(new BotTypingMessage());
                            this.coreBotService.getQueryBotExplainer(explainerRequest).subscribe(explanation => {
                                this.messages.pop();
                                setTimeout(() => {
                                    this.messages.push(new ChatBotMessage(explanation,'QueryBot', '../../../../assets/images/query-bot.png'));
                                    this.checkWidgetVisibility();
                                    res(true);
                                }, 800);
                            });
                        }, 800);
                    }
                }, 400);
            }, error => {
                this.messages.pop();
                setTimeout(() => {
                    this.messages.push(new BasicChatMessage(
                        'An error occurred when I tried accessing my training data. Please try your prompt again.',
                        'QueryBot',
                        '../../../../assets/images/query-bot.png'
                    ));
                    this.enableChatBotFields();
                    res(true);
                }, 500);
            });
        });
    }

    chooseOtherBot(bot): string {
        const url = window.location.origin;
        if (bot === 'QueryBot'){
            return url + '/processing';
        }
        else if (bot === 'AnalyticBot'){
            const analyticsUrl: string = url + '/pages/analytic/';
            if (this.nonDefaultAnalytic){
                return analyticsUrl + this.nonDefaultAnalytic;
            }
            else {
                return analyticsUrl;
            }
        }
        else if (bot === 'RuleBot'){
            return url + '/plans/';
        }
    }

    onModelChange(value: string): void {
        if (value.length > 0){
            this.sendButton.disabled = false;
        }
        else {
            this.sendButton.disabled = true;
        }
        this.onInputChange.emit(value);
    }

    disableChatBotFields(){
        this.sendButton.disabled = true;
        this.chatBotInput.nativeElement.disabled = true;
        this.newConversationButton.disabled = true;
    }

    enableChatBotFields(){
        this.chatBotInput.nativeElement.disabled = false;
        this.newConversationButton.disabled = false;
    }

    clearCoreBotConversation(e: any){
        this.coreBotConversationId = null;
        this.messages = [new BasicChatMessage('How can I be of assistance?', 'CoreBot', '../../../assets/images/AIBot.png')];
    }

    onHiding(e: any){
        this.showPopup = false;
        this.showPopupChanged.emit(this.showPopup);
    }

    openCoreBotPopout(e: any){
        const openerParams = [
            'fullscreen=yes',
            'height=800',
            'width=615'
        ].join(',');

        this.onHiding(e);
        const newWindow = window.open('chatbot', null, openerParams);
        newWindow.moveTo(0, 0);
    }

    getSamplePrompt(): string{
        const rand: number = Math.floor(Math.random() * 10);
        let prompt = '';
        const adminPrompts = [
            'Which datasource has the most records?',
            'How do I get started building my own rule?',
            'Where do I go to set up SMTP settings to send out reports to agents?',
            'Who are the top 5 paid agents in 2024?',
            'Give me a list of all users in the system with their login names.',
            'How do I run the cycle? What steps should I take before processing?',
            'What would I use a custom page for in Core?',
            'If I need to set up an integration, who should I reach out to?',
            'Give me a list of each rule that has at least 1 row of output.',
            'Which agent was paid the most in the most recent period?'
        ];
        const everybodyPrompts = [
            'How do I view a report?',
            'Who should I reach out to if I have questions about using the system?',
            'If my dashboard is blank, what should I do?',
            'How do I set a report as a favorite?',
            'How do I search for a value in my report?',
            'How do I view a report for a year-to-date time frame?',
            'How can I make a dashboard full screen?',
            'How can I export a dashboard to excel?',
            'Where do I go to export a report to PDF?',
            'How do I set a dashboard as my home report?'
        ];

        if (this.userHasEverybodyBotAccess && this.userHasAdminBotAccess){
            prompt = adminPrompts[rand];
        }
        else {
            prompt = everybodyPrompts[rand];
        }

        return prompt;
    }

    showTicketWidget(){
        const body = document.getElementsByTagName('body')[0];
        const script1 = document.createElement('script');
        this.populateFreshdeskFields();
        script1.async = true;
        script1.innerHTML = 'FreshworksWidget(\'open\');';
        body.appendChild(script1);
    }

    populateFreshdeskFields() {
        this.sellerService.getMe().subscribe(seller => {
            const body = document.getElementsByTagName('body')[0];
            const script1 = document.createElement('script');
            script1.async = true;
            script1.innerHTML = 'FreshworksWidget(\'identify\', \'ticketForm\', {name: \'' + seller.name + '\',email: \'' + seller.emailAddress + '\',' +
             'subject: \'Core Commissions Issue (' + window.location.href + ') \',' +
             'description: \'Provide as much detail as possible when describing your issue. The more detail provided, the quicker we can address the problem.\'});';
            body.appendChild(script1);
            body.removeChild(script1);
        });
    }

    checkWidgetVisibility(){
        if (this.messages.length >= 6){
            this.isSupportVisible = true;
        }
    }
}

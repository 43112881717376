import {
    RowSelectionModes,
    ScrollingModes,
    RowRenderingModes,
    SelectAllModes,
    ShowCheckBoxesModes
} from '../constants/dev-extreme-enums';

export class DevExpressDataProperties {
    pageSize: number = 10;
    allowedPageSizes: number[] = [10, 25, 50, 100];
    isPageSizeSelectorVisible: boolean = true;
    isPageNavigationVisible: boolean = true;
    isPagingEnabled: boolean = true;
    isScrollingEnabled: boolean = false;
    scrollingMode: string = ScrollingModes.Virtual;
    rowRenderingMode: string = RowRenderingModes.Virtual;
    isRowSelectionVisible: boolean = false;
    isRowSelectionEnabled: boolean = false;
    rowSelectionSelectAllMode: string = SelectAllModes.AllPages;
    rowSelectionCheckBoxesMode: string = ShowCheckBoxesModes.OnClick;
    isRowSelectionSelectAllEnabled: boolean = true;
    rowSelectionMode: string = RowSelectionModes.Multiple;
    isBulkEditEnabled: boolean = false;
    isDuplicateEnabled: boolean = false;
    isBulkDeleteEnabled: boolean = false;
    isLayoutsEnabled: boolean = false;
    isExportEnabled: boolean = false;
    exportFileName: string;
    showBorders: boolean = true;
    showColumnLines: boolean = true;
    showColumnHeaders: boolean = true;
    columnAutoWidth: boolean = true;
    wordWrapEnabled: boolean = true;
    isColumnReorderAllowed: boolean = true;
    isColumnResizingAllowed: boolean = false;
    loadingVisible: boolean = false;
    isSearchEnabled: boolean = true;
    isColumnChooserSearchEnabled: boolean = false;
    remoteOperations: boolean = false;

    enableSingleRowSelection() {
        this.rowSelectionMode = RowSelectionModes.Single;
        this.isRowSelectionSelectAllEnabled = false;
        this.isRowSelectionEnabled = true;
    }

    disableSearch(isSearchDisabled: boolean) {
        this.isSearchEnabled = !isSearchDisabled;
    }

    enableColumnChooserSearch(isEnabled: boolean = true) {
        this.isColumnChooserSearchEnabled = isEnabled;
    }
}

import { CoreBotResponse } from '../core-bot-response';
import { ChatMessage } from './chat-message';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as marked from 'marked';

export class ChatBotMessage extends ChatMessage {
    customMessageData: any;

    constructor(response: CoreBotResponse, user: string = 'ChatBot',  avatar: string = '../../../assets/images/AIBot.png'){
        super();
        this.type = 'corebot-response';
        this.customMessageData = {response: this.parseBotMarkdown(response.response), id: response.logId};
        this.user = {
            name: user,
            avatar
        };
    }

    parseBotMarkdown(input: string){
        const renderer = new marked.Renderer();
        renderer.paragraph = (text) => `<p class="text-control button-custom-message-text core-bot-text">${text}</p>`;
        marked.use({ renderer });
        const htmlOutput = marked.parse(input);
        return htmlOutput;
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IntegrationClass, IntegrationValue } from '../models/integration';



@Injectable()
export class IntegrationValueService {
    private baseUrl = environment.apiEndpoint + '/integrationvalue';

    constructor(private http: HttpClient) {
    }

    getAllIntegrationValues(): Observable<IntegrationValue[]> {
        return this.http.get<IntegrationValue[]>(this.baseUrl);
    }

    getAllIntegrationValuesByClassId(classId: number): Observable<IntegrationValue[]> {
        return this.http.get<IntegrationValue[]>(`${this.baseUrl}/${classId}`);
    }

    updateIntegrationValue(integrationClass: IntegrationValue): Observable<IntegrationValue> {
        return this.http.put<IntegrationValue>(this.baseUrl, integrationClass);
    }

    clearIntegrationValuesbyClass(integrationClass: IntegrationClass): Observable<IntegrationClass> {
        return this.http.put<IntegrationClass>(`${this.baseUrl}/clear`, integrationClass);
    }
}

<div class="ag-overlay-loading-center">
    <dx-load-panel
        #loadPanel
        [wrapperAttr]="{ class: 'spinning-gears-load-panel' }"
        shadingColor="rgba(0,0,0,0.4)"
        [visible]="true"
        [indicatorSrc]="indicatorUrl"
        [showIndicator]="true"
        [showPane]="false"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>
</div>

<div class="user-panel">
    <div class="user-info">
        <div class="image-container">
            <div class="user-image"></div>
        </div>
    </div>
    <dx-context-menu
        *ngIf="menuMode === 'context'"
        [items]="menuItems"
        target=".user-button"
        width="170"
        [position]="{
            my: 'top right',
            at: 'bottom right'
        }"
        [visible]="showMenu"
        cssClass="user-menu"
        (onHidden)="onHidden($event)">
    </dx-context-menu>
    <dx-list *ngIf="menuMode === 'list'" class="dx-toolbar-menu-action" [items]="menuItems"></dx-list>
</div>

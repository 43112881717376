import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { Container } from '../../models/building-blocks';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-bb-user-dependencies',
    providers: [ {provide: SaveableBbProperty, useExisting: BbUserDependenciesComponent }],
    templateUrl: './bb-user-dependencies.component.html',
    styleUrls: ['./bb-user-dependencies.component.scss']
})
export class BbUserDependenciesComponent extends SaveableBbProperty implements OnInit, OnChanges {
    @Input() ruleId: string;
    @Input() datasource: any[];
    @Output() datasourceChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
    internalDatasource: any[];
    isValid: boolean = true;
    rules: Container[];
    ruleLookup: Container[];

    constructor(private bbHelper: BuildingBlockHelperService, private helper: HelperService) {
        super();
    }

    ngOnInit(): void {
        this.datasource ??= [];

        this.bbHelper.getObjectStoreRules().subscribe(rules => {
            this.rules = rules;
            this.getRuleLookup();
            this.internalDatasource = this.datasource.map(id => this.rules.find(x => x.id === id));
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.rules) {
            this.getRuleLookup();
        }
        this.internalDatasource = this.datasource.map(id => this.rules?.find(x => x.id === id));
        this.dataGrid?.instance.cancelEditData();
        this.dataGrid?.instance.refresh();
    }

    emitValue() {
        this.datasource = this.internalDatasource.map(rule => rule.id);
        this.datasourceChange.emit(this.datasource);
    }

    saveInternalData(): Promise<void> {
        return this.dataGrid?.instance.saveEditData();
    }

    checkForChanges() {
        if(this.dataGrid.instance.hasEditData()){
            this.bbHelper.setGridPropertyAsUnsaved('userDependencies');
        } else {
            this.bbHelper.removeGridPropertyFromUnsaved('userDependencies');
        }
    }

    filterToolbarItems(e) {
        e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
    }

    getRuleLookup() {
        const thisRule = this.rules.find(x => x.id === this.ruleId);
        this.ruleLookup = this.rules.filter(x => x.name !== thisRule.name).sort((a, b) => a.name.localeCompare(b.name)).map(x => this.helper.deepCopyTwoPointO(x));
        this.ruleLookup.forEach(rule => {
            const versions = this.ruleLookup.filter(x => x.name === rule.name).sort((a, b) => a.periodBeginId - b.periodEndId);
            if (versions.length > 1) {
                for (let i = 0; i < versions.length; i++) {
                    versions[i].name += ` (${i+1})`;
                }
            }
        });
    }
}

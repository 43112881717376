import { ChatMessage } from './chat-message';

export class UserMessage extends ChatMessage {
    text: string;
    reply: boolean;

    constructor(user: string, text: string){
        super();
        this.text = text;
        this.user = {
            name: user,
            avatar: '../../../assets/images/user.png'
        };
        this.reply = true;
    }
}

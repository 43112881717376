export enum CoreDataGridCellTemplates {
    DropDownButtonCellTemplate = 'dropDownButtonCellTemplate',
    PlainButtonCellTemplate = 'plainButtonCellTemplate',
    CustomButtonCellTemplate = 'customButtonCellTemplate',
    GroupCellTemplate = 'groupCellTemplate',
    TextWithIconFlagTemplate = 'textWithIconFlagTemplate'
}

export enum CoreDataGridHeaderCellTemplates {
    UndraggableHeaderCellTemplate = 'undraggableHeaderCellTemplate'
}

export enum CoreDataGridEditTemplates {
    DropDownTableTemplate = 'dropDownTableTemplate',
    ValueListTemplate = 'valueListTemplate',
    DynamicCellEditorTemplate = 'dynamicCellEditorTemplate',
}

export enum DynamicCellEditorTemplates {
    DropDownTableTemplate = 'dropDownTableTemplate',
    CoreTableSelectTemplate = 'coreTableSelectTemplate',
    DatetimeTemplate = 'datetimeTemplate',
    SelectBoxTemplate = 'selectBoxTemplate',
    LookupTemplate = 'lookupTemplate',
    NumberBoxTemplate = 'numberBoxTemplate',
    TextBoxTemplate = 'textBoxTemplate',
}

export enum CoreInputEditorType {
    BbPeriodFilter = 'BbPeriodFilter',
    Grid = 'Grid',
    FormulaBuilder = 'FormulaBuilder',
    CoreTableDropDown = 'coreTableDropDown',
    List = 'dxList',
    // below enums will work in the editorType property of a dxi-item
    Autocomplete = 'dxAutocomplete',
    Calendar = 'dxCalendar',
    CheckBox = 'dxCheckBox',
    ColorBox = 'dxColorBox',
    DateBox = 'dxDateBox',
    DropDownBox = 'dxDropDownBox',
    HtmlEditor = 'dxHtmlEditor',
    Lookup = 'dxLookup',
    NumberBox = 'dxNumberBox',
    RadioGroup = 'dxRadioGroup',
    RangeSlider = 'dxRangeSlider',
    SelectBox = 'dxSelectBox',
    Slider = 'dxSlider',
    Switch = 'dxSwitch',
    TagBox = 'dxTagBox',
    TextArea = 'dxTextArea',
    TextBox = 'dxTextBox',
}

export enum RowSelectionModes {
    Multiple = 'multiple',
    Single = 'single',
    None = 'none',
    All = 'all',
}

export enum ColumnSortDirections {
    Ascending = 'asc',
    Descending = 'desc',
}

export enum ScrollingModes {
    Standard = 'standard',
    Virtual = 'virtual',
    Infinite = 'infinite',
}

export enum RowRenderingModes {
    Standard = 'standard',
    Virtual = 'virtual'
}

export enum SelectAllModes {
    AllPages = 'allPages',
    Page = 'page',
}

export enum ShowCheckBoxesModes {
    Always = 'always',
    None = 'none',
    OnClick = 'onClick',
    OnLongTap = 'onLongTap',
}

export enum EditModes {
    Batch = 'batch',
    Cell = 'cell',
    Row = 'row',
    Form = 'form',
    Popup = 'popup',
}

export enum devExtremeDMLStrings {
    Insert = 'insert',
    Update = 'update',
    Remove = 'remove'
}

export enum ReportViewerToolbarActionIds {
    FirstPage = 'dxxrp-first-page',
    PrevPage = 'dxxrp-prev-page',
    Page = 'dxxrp-pagination',
    NextPage = 'dxxrp-next-page',
    LastPage = 'dxxrp-last-page',
    Multipage = 'dxxrp-multipage-toggle',
    ZoomOut = 'dxxrp-zoom-out',
    Zoom = 'dxxrp-zoom-selector',
    ZoomIn = 'dxxrp-zoom-in',
    highlightEditing = 'dxxrp-highlight-editing-fields',
    Print = 'dxxrp-print',
    PrintPage = 'dxxrp-print-page',
    Export = 'dxxrp-export-menu',
    Search = 'dxxrp-search',
    Design = 'dxxrp-design',
    Fullscreen = 'dxrd-fullscreen',
    Email = 'email',
    DateAccount = 'date-account',
    Home = 'home',
    CoreBot = 'core-bot'
}

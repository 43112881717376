import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { IntegrationClass, IntegrationValue } from '../models/integration';
import { Oauth } from '../models/oauth';

@Injectable()
export class LogoutService {
    private baseUrl = environment.apiEndpoint + '/logout';

    constructor(private http: HttpClient) {
    }

    logOut(): Observable<CoreResponse<string>> {
        return this.http.get<CoreResponse<string>>(this.baseUrl);
    }
}

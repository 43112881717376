import { Attribute } from './attribute';
import { AttributeClass } from './attribute-class';
import { SellerName } from './seller-name';

export class ImportCleaningSourceData {
    sellers: SellerName[];
    attributeClasses: AttributeClass[];
    // TODO: add more here as needed

    constructor(sellers: SellerName[] = [], attributeClasses: AttributeClass[] = []) {
        this.sellers = sellers;
        this.attributeClasses = attributeClasses;
    }
}

import { BaseInputProperties } from './core-base-input-properties';

export class BaseNumericInputProperties<T> extends BaseInputProperties<T> {
    maxValue: number;
    minValue: number;
    showSpinButton: boolean;
    format: string;
    step: number;

    constructor(maxValue: number = 1000000000,
        minValue = 0,
        showSpinButton = true,
        format = '$#,##0.00;($#,##0.00)',
        step = 1,
        readOnly?: boolean,
        disabled?: boolean) {

        super(readOnly, disabled);

        this.maxValue = maxValue;
        this.minValue = minValue;
        this.showSpinButton = showSpinButton;
        this.format = format;
        this.step = step;
    }

    addOnChange(parentThis: any, inputOnChangeCallBack: (properties: { componentThis: T, event: any }) => void) {
        this.inputOnChangeCallBack = inputOnChangeCallBack.bind(parentThis);
        this.isOnChangedUsed = true;
    }

}

export class NullableIntInputProperties<T> extends BaseNumericInputProperties<T> {

    constructor() {
        super();
        this.format = '';
    }
}

export class WholeNumberInputProperties<T> extends BaseNumericInputProperties<T> {

    constructor(maxValue: number = 10000, minValue: number = 1) {
        super(maxValue, minValue, true, '#0', 1);
    }
}

export class ChunkNumberInputProperties<T> extends BaseNumericInputProperties<T> {
    constructor(maxValue: number = 30, minValue: number = 1){
        super(maxValue, minValue, true, '#0', 1);
    }
}

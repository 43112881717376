import { Component } from '@angular/core';
import { Oauth} from '../../shared/models/oauth';
import { OauthService } from '../../shared/services/oauth.service';
import { ActivatedRoute } from '@angular/router';
import { IntegrationClass } from '../../shared/models/integration';


@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {

    constructor(
        private oauthService: OauthService,
        private route: ActivatedRoute) {

        const queryString = window.location.search;
        const entries = JSON.parse('{"' + decodeURI(queryString).replace('?', '').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        const urlValues = JSON.stringify(entries);
        const oauthObj = new Oauth();

        this.route.paramMap.subscribe(params => {
            const integrationName = params.get('integrationID');
            oauthObj.companyId = integrationName;
            oauthObj.parameters = urlValues;
            oauthObj.environment = localStorage.getItem('integrationEnvironment');
            oauthObj.integrationClass = localStorage.getItem('activeIntegration');
            this.setCompanyId(oauthObj, integrationName).subscribe(success => {
                if (success === 0) {
                    window.location.href = 'https://www.commportal.net/system';
                    alert('Connection rejected by user');
                    localStorage.removeItem('integrationEnvironment');
                    localStorage.removeItem('activeIntegration');
                }
                else {
                    window.location.href = 'https://www.commportal.net/system';
                    localStorage.removeItem('integrationEnvironment');
                    localStorage.removeItem('activeIntegration');
                // window.location.href = 'localhost:44378'; //uncomment for local testing -- may need to adjust params
                }
            });
        });
    }

    public setCompanyId(params: Oauth, integrationName: string) {
        return this.oauthService.updateCompanyId(params, integrationName);
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AnalyticContext } from '../models/contexts/analytic-context';
import { IgCache } from '../models/ig-cache';
import { Analytic } from '../models/analytic';
import { AnalyticField } from '../models/analytic-field';
import { CoreResponse } from '../models/core-response';
import { Period } from '../models/period';
import { AnalyticSellerPublish } from '../models/analytic-seller-publish';
import { UpdateAnalyticRequest } from '../models/update-analytic-request';

@Injectable()
export class AnalyticService {

    public analyticRequest = new Subject<any>();
    public analyticResponse = this.analyticRequest.pipe(switchMap((context: AnalyticContext) =>
        forkJoin([this.getAnalytic(context.id),
            this.getAnalyticData(context),
            this.getAnalyticFields()
        ])
    ));

    private analyticURL = environment.apiEndpoint + '/analytic/';

    constructor(private http: HttpClient) {

    }

    getAnalytic(id: number): Observable<Analytic> {
        return this.http.get<Analytic>(this.analyticURL + id.toString());
    }

    getAnalytics(): Observable<Analytic[]> {
        return this.http.get<Analytic[]>(this.analyticURL);
    }

    getDefaultAnalytic(): Observable<Analytic> {
        return this.http.get<Analytic>(this.analyticURL + 'default');
    }

    getAnalyticsBySeller(sellerId: number): Observable<Analytic[]> {
        return this.http.get<Analytic[]>(this.analyticURL + 'byseller/' + sellerId.toString());
    }

    getAnalyticFields(): Observable<AnalyticField[]> {
        return this.http.get<AnalyticField[]>(this.analyticURL + 'fields');
    }

    getAnalyticData(context: AnalyticContext): Observable<IgCache[]> {
        return this.http.post<IgCache[]>(this.analyticURL + 'data/', context);
    }

    getDirtyPeriodIds(context: AnalyticContext): Observable<number[]> {
        return this.http.post<number[]>(this.analyticURL + 'dirty', context);
    }

    getAnalyticMappingBySeller(sellerID: number, beginDate: Date, endDate: Date): Observable<{id: number, name: string}[]> {
        return this.http.post<{id: number, name: string}[]>(this.analyticURL + 'mapping', {sellerID, beginDate, endDate});
    }

    getAnalyticSellerPublishByAnalyticId(analyticId: number)
    {
        return this.http.get<AnalyticSellerPublish[]>(this.analyticURL + 'publishedAnalyticsByAnalyticId/' + analyticId);
    }

    insertAnalytic(analytic: Analytic): Observable<Analytic> {
        return this.http.post<Analytic>(this.analyticURL + 'insert', analytic);
    }

    updateAnalytic(updateAnalyticRequest: UpdateAnalyticRequest): Observable<number> {
        return this.http.put<number>(this.analyticURL + 'update', updateAnalyticRequest);
    }

    deleteAnalytic(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.analyticURL + 'analytic/' + id.toString());
    }

    setRefreshInProgress(dirtyPeriodIds: number[]): void {
        const refreshingIds = JSON.parse(localStorage.getItem('analyticRefreshInProgress')) ?? [];
        refreshingIds.push(...dirtyPeriodIds);
        localStorage.setItem('analyticRefreshInProgress', JSON.stringify(refreshingIds));
    }

    setRefreshCompletedPeriod(normalizedContext: AnalyticContext, normalizedPeriods: Period[]): void {
        const refreshedPeriodIds = normalizedPeriods
            .filter(p => (p.beginDate >= normalizedContext.beginDate && p.beginDate < normalizedContext.endDate)
                || (normalizedContext.beginDate >= p.beginDate && normalizedContext.beginDate < p.endDate))
            .map(p => p.id);

        const refreshInProgress = (JSON.parse(localStorage.getItem('analyticRefreshInProgress')) ?? []).filter(pid => !refreshedPeriodIds.includes(pid));
        localStorage.setItem('analyticRefreshInProgress', JSON.stringify(refreshInProgress));
    }

    setRefreshCompletedIds(normalizedPeriods: number[]): void {
        const refreshInProgress = (JSON.parse(localStorage.getItem('analyticRefreshInProgress')) ?? []).filter(pid => !normalizedPeriods.includes(pid));
        localStorage.setItem('analyticRefreshInProgress', JSON.stringify(refreshInProgress));
    }

    isRefreshInProgress(dirtyPeriodIds: number[]) {
        return JSON.parse(localStorage.getItem('analyticRefreshInProgress'))?.some(pid => dirtyPeriodIds.includes(pid));
    }
}

export class DashboardContext {
    token: string;
    id: number;
    // configId this is the DevExpress defined dashboard ID
    configId: number;
    mode: string;
    beginDate: Date;
    endDate: Date;
    selectedSellers: string;
    name: string;
    xml: string;
    priority: number;
    seriesIdList: string;
    recurrenceId: number;
    isPublic: boolean;
    includeZeroes: boolean;
    attributes: string;
    showInactiveSegments: boolean;
    viewableAccounts: string;
    isDesignMode: boolean;
}

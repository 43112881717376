import { CoreBotResponse } from '../core-bot-response';
import { ChatMessage } from './chat-message';

export class AnalyticBotMessage extends ChatMessage {
    customMessageData: any;

    constructor(response: CoreBotResponse, message: string, user: string = 'ChatBot', avatar: string = '../../../assets/images/AIBot.png'){
        super();
        this.type = 'corebot-response';
        this.customMessageData = {response: message, id: response.logId};
        this.user = {
            name: user,
            avatar
        };
    }
}

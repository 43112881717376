import { BaseInputProperties } from './core-base-input-properties';

export class BaseMultiLineStringInputProperties<T> extends BaseInputProperties<T> {
    maxLength: number;

    constructor(maxLength = 10000,
                readOnly?: boolean,
                disabled?: boolean) {

        super(readOnly, disabled);
        this.maxLength = maxLength;
    }
}

export class ReadOnlyMultiLineStringInputProperties<T> extends BaseMultiLineStringInputProperties<T> {
    constructor() {
        super();
        this.readOnly = true;
    }
}

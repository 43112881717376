import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, NgModule } from '@angular/core';
import { SendEmailResult } from '../../models/send-email-result';
import { DxResponsiveBoxModule, DxTextBoxModule, DxTextAreaModule, DxButtonModule,
    DxLoadPanelModule, DxPopupModule, DxDropDownButtonModule, DxDataGridModule, DxTooltipModule } from 'devextreme-angular';

@Component({
    selector: 'email-results',
    templateUrl: './email-results.component.html',
    styleUrls: ['./email-results.component.scss']
})

export class EmailResultsComponent implements OnInit {
    popupVisible: boolean = false;

    @Input()
    set results(results: SendEmailResult[]) {
        if (results && results.length) {
            this._results = results;
            this.popupVisible = true;
        }
    }
    public _results: SendEmailResult[] = [];

    constructor() {

    }

    ngOnInit() {

    }
}

@NgModule({
    imports: [
        CommonModule,
        DxResponsiveBoxModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxButtonModule,
        DxLoadPanelModule,
        DxPopupModule,
        DxDropDownButtonModule,
        DxDataGridModule,
        DxTooltipModule,
    ],
    declarations: [EmailResultsComponent],
    exports: [EmailResultsComponent]
})
export class EmailResultsModule { }

export class SavedColumn {
    id: number;
    ruleFriendlyName: string;
    isPayment: boolean;
    format: string;
    name: string;

    constructor(savedColumnId: number, name: string, ruleFriendlyName: string, isPayment: any, format: string = 'c') {
        this.id = savedColumnId;
        this.name = name;
        this.ruleFriendlyName = ruleFriendlyName;
        this.isPayment = isPayment;
        this.format = format;
    }
}

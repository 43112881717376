import { HelperService } from '../services/helper.service';

export class CoreRequest<T> {
    argument: T;
    arguments: T[];
    userLocalTime: string;
    transactionId: string;

    constructor(arg: T, args: T[], transactionId = null) {
        const helperService = new HelperService();

        this.argument = arg;
        this.arguments = args;
        this.userLocalTime = helperService.dateToUTCISOString(new Date());
        this.transactionId = transactionId;
    }

    addTransactionId(transactionId: string): CoreRequest<T> {
        this.transactionId = transactionId;

        return this;
    }
}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { DxTextBoxComponent } from 'devextreme-angular';
import { FormulaBuilderTreeItem } from '../../../shared/models/formula-builder-tree-item';
import { FormulaBuilderDataValue } from '../../../shared/models/formula-builder-data-value';
import { FormulaBuilderPopupComponent } from '../formula-builder-popup/formula-builder-popup.component';

@Component({
    selector: 'app-formula-builder',
    templateUrl: './formula-builder.component.html',
    styleUrls: ['./formula-builder.component.scss']
})
export class FormulaBuilderComponent {
    @Input() value: string;
    @Input() data: FormulaBuilderTreeItem[];
    // Id must be unique - it is used for element id attributes
    @Input() id: string;
    // If text is blank or not set, the button/link will visibly consist of only the icon
    @Input() text: string;
    @Input() icon: string;
    @Input() launchElement: string;
    @Input() onOkClick: () => any;
    @Input() popupTitle: string = 'Formula Builder';
    @Output() valueChange = new EventEmitter<string>();

    public newValue: string;
    public treeItems: FormulaBuilderTreeItem[];
    public popupVisible: boolean;
    public dataValues: FormulaBuilderDataValue[];
    public description: string;
    public formulaCursorPosition: number;
    private originalValue: string;

    launch(): void {
        this.popupVisible = true;
    }

    formulaValueChanged(newValue: string): void {
        this.value = newValue;
        this.valueChange.emit(newValue);
    }
}




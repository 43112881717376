import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Series } from '../models/series';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class SeriesService {
    private url: string = environment.apiEndpoint + '/Series';

    constructor(private http: HttpClient) {
    }

    getSeriesIdByName(name: string): Observable<number> {
        return this.http.get<number>(this.url + '/name/' + name);
    }

    getAllSeries(): Observable<Series[]> {
        return this.http.get<Series[]>(this.url);
    }

    insertSeries(series: Series): Observable<Series> {
        return this.http.post<Series>(this.url, series);
    }

    updateSeries(series: Series): Observable<number> {
        return this.http.put<number>(this.url, series);
    }

    deleteSeries(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.url + '/' + id);
    }
}


export class ObjectInput {
    name: string;
    objectId: string;
    type: string;

    constructor(name: string, objectId: string, type: string) {
        this.name = name;
        this.objectId = objectId;
        this.type = type;
    }
}

import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-initiatesso',
  templateUrl: './initiatesso.component.html',
  styleUrls: ['./initiatesso.component.scss']
})
export class InitiatessoComponent {

    constructor(private toast: ToastrService) {

      if (this.cookiesEnabled() === true) {
        window.location.href = environment.samlEndpoint + '/InitiateSingleSignOn?returnUrl=https://' + window.location.hostname.split('.')[0] + '.commportal.net/retrievesso';
        // window.location.href = environment.samlEndpoint + '/InitiateSingleSignOn?returnUrl=http://' + window.location.hostname.split('.')[0] + ':44378/retrievesso'; // Uncomment for local testing
      }
      else {
        this.toast.error('Your browser is using strict cookie blockers. To enable SSO, please allow all cookies for this site in order to continue.');
        setTimeout(() => {
          // window.location.href = 'http://' + window.location.hostname.split('.')[0] + ':44378/login-form'; // Uncomment for local testing
          window.location.href = 'https://' + window.location.hostname.split('.')[0] + '.commportal.net/login-form';
        }, 5000);
      }
    }

    cookiesEnabled(): boolean {
        document.cookie = 'testSamlCookie=1; SameSite=Lax';
        const areCookiesEnabled = document.cookie.indexOf('testSamlCookie') !== -1;
        document.cookie = 'testSamlCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        return areCookiesEnabled;
    }
}

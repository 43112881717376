import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomerFeedback } from '../models/customer-feedback';

@Injectable({
    providedIn: 'root'
})

export class CustomerFeedbackService {
    private url: string = environment.apiEndpoint + '/CustomerFeedback';

    constructor(private http: HttpClient) {
    }

    submitFeedback(feedback: CustomerFeedback): Observable<CustomerFeedback> {
        return this.http.post<CustomerFeedback>(this.url, feedback);
    }
}


import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { DxDateBoxComponent } from 'devextreme-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'app-core-custom-editor-date-box',
  templateUrl: './core-custom-editor-date-box.component.html',
  styleUrls: ['./core-custom-editor-date-box.component.scss']
})
export class CoreCustomEditorDateBoxComponent implements AgEditorComponent, AfterViewInit {

	@ViewChild('input', { static: false }) input: DxDateBoxComponent;
	value: any;
	editorValue: any;
	defaultValue: any;
	format: any = {
		parser: (e) => {
            const date = this.helperService.parseDate(e, null, true, true);
            return !isNaN(date.getTime()) ? date : this.helperService.defaultEmptyDate();
        },
		formatter: (date) => String.format('{0:g}', date)
	};

	constructor(private helperService: HelperService) { }

	getValue() {
		this.input.instance.blur();
		return this.value;
	}

	isCancelBeforeStart(): boolean {
		return false;
	}

	agInit(params: ICellEditorParams): void {
		if (params.value && this.helperService.parseDate(params.value, true, true).getTime() === this.helperService.defaultEmptyDate().getTime()) {
            this.value = '';
            this.editorValue = '';
        } else {
            this.value = params.value;
            this.editorValue = this.value;
        }
	}

	ngAfterViewInit(): void {
		const dateBoxElement = this.input.instance.element();
		const inputElement: HTMLInputElement = dateBoxElement.querySelector('input[type="text"]') as HTMLInputElement;
		const dateBoxButtonElement = dateBoxElement.querySelector('.dx-dropdowneditor-button');
		setTimeout(() => {
			inputElement.focus();
			inputElement.select();
			dateBoxButtonElement.classList.add('ag-custom-component-popup');
	  });
	}

	onOpened(): void {
        const doc = document as any;
        const dateboxElement = doc.querySelector('.dx-datebox-wrapper');
        dateboxElement.classList.add('ag-custom-component-popup');

		if (this.editorValue === undefined || this.editorValue === '') {
			this.defaultValue = `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()} 12:00:00 AM`;
			this.editorValue = this.defaultValue;
		}
    }

	onValueChanged(e): void {
		if (this.editorValue !== this.defaultValue) {
			this.value = this.editorValue;
		}
	}

	onClosed(e): void {
		if (this.value === undefined) {
			this.editorValue = this.value;
		}
	}
}

import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';

@Component({
	selector: 'app-core-insert-grid-tool-panel',
	templateUrl: './core-insert-grid-tool-panel.component.html',
	styleUrls: ['./core-insert-grid-tool-panel.component.scss']
})
export class CoreInsertGridToolPanelComponent implements IToolPanelAngularComp {
	constructor() {}

	async agInit(params): Promise<void> {}
}

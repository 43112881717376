import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UiView } from '../models/ui-view';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class UiViewService {
    private url: string = environment.apiEndpoint + '/UiView';
    private sourcePrefixMap = { datasourceId: 'd', ruleId: 'r', functionId: 'f', functionGroupId: 'fg' };

    constructor(private http: HttpClient) {
    }

    getUiViewById(id: number): Observable<UiView> {
        return this.http.get<UiView>(this.url + '/' + id);
    }

    getUiViewsByContextCode(contextCode: number): Observable<UiView[]> {
        return this.http.get<UiView[]>(this.url + '/getUiViewsByContextCode/' + contextCode);
    }

    getUiViewsByContextCodes(contextCodes: number[]): Observable<UiView[]> {
        return this.http.post<UiView[]>(this.url + '/getUiViewsByContextCodes/', contextCodes);
    }

    insertUiView(uiView: UiView): Observable<UiView> {
        return this.http.post<UiView>(this.url, uiView);
    }

    updateUiView(uiView: UiView): Observable<UiView> {
        return this.http.put<UiView>(this.url, uiView);
    }

    deleteUiView(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.url + '/' + id);
    }

    getDefaultRecordLayout(tenant: string, sourceType: string, sourceId: number) {
		const defaults = JSON.parse(localStorage.getItem('defaultRecordLayouts'));
        return defaults?.[tenant]?.[this.sourcePrefixMap[sourceType] + sourceId];
    }

    setDefaultRecordLayout(tenant: string, sourceType: string, sourceId: number, layoutId: number) {
		let defaults = JSON.parse(localStorage.getItem('defaultRecordLayouts'));
		defaults ??= {};
		defaults[tenant] ??= {};
		defaults[tenant][this.sourcePrefixMap[sourceType] + sourceId] = layoutId;
        localStorage.setItem('defaultRecordLayouts', JSON.stringify(defaults));
    }

    removeDefaultRecordLayout(tenant: string, sourceType: string, sourceId: number) {
        const defaults = JSON.parse(localStorage.getItem('defaultRecordLayouts'));
		delete defaults?.[tenant]?.[this.sourcePrefixMap[sourceType] + sourceId];
        localStorage.setItem('defaultRecordLayouts', JSON.stringify(defaults));
    }
}


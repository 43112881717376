import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Singleton } from '../models/singleton';

@Injectable({
    providedIn: 'root'
})

export class SingletonService {
    private url: string = environment.apiEndpoint + '/singleton';

    constructor(private http: HttpClient) {
    }

    getAllSingletons(): Observable<Singleton[]> {
        return this.http.get<Singleton[]>(this.url);
    }

    getSingletonById(id: number): Observable<Singleton>{
        return this.http.get<Singleton>(this.url + '/singletonsByClassId/' + id);
    }

    updateSingleton(singleton: Singleton): Observable<number>{
        return this.http.put<number>(this.url,singleton);
    }
}

export class BotTypingMessage {
    files: [any];
    type: string;
    user: string;

    constructor(){
        this.type = 'file';
        this.user = '';
        this.files = [
            {
                url: '../../../assets/images/chatbot-typing.gif',
                type: 'image/gif'
            }
        ];
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnApi, GridApi, ColDef, ValueFormatterParams } from 'ag-grid-community';
import { HelperService } from '../../services/helper.service';
import { ProcessingService } from '../../services/processing.service';


@Component({
    selector: 'app-trace-output',
    templateUrl: './trace-output.component.html',
    styleUrls: ['./trace-output.component.scss']
})
export class TraceOutputComponent implements OnChanges, OnInit {
    @Input() calcSetId: string;

    treeData: any[];
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    columnDefs: any[];
    defaultColDef: ColDef;
    autoGroupColumnDef: ColDef;
    groupDefaultExpanded;

    constructor(private processingService: ProcessingService,
        private helperService: HelperService) {
    }

    ngOnInit(){
        this.columnDefs = [
            {field: 'type'},
            {field: 'definition'},
            {field: 'description'},
            {
                field: 'value',
                type: 'numericColumn',
                valueFormatter: params => params.data.value.toFixed(4)
            }
        ];
        this.defaultColDef = {
            flex: 1,
            resizable: true
        };
        this.autoGroupColumnDef = {
          headerName: 'Name',
          minWidth: 300,
          cellRendererParams: { suppressCount: true },
          valueFormatter: this.removeIndex
        };
        this.groupDefaultExpanded = 0;
    }

    ngOnChanges(change) {
        if (!this.helperService.isNullOrUndefined(change.calcSetId.currentValue)) {
            this.processingService.getAuditRows(change.calcSetId.currentValue).subscribe(result => {
                this.treeData = result;
                this.gridApi.refreshCells();
                this.gridApi.redrawRows();
            });
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    getDataPath(data) {
        return data.path;
    }

    removeIndex(params: ValueFormatterParams): string {
        return params.value.substring(0, params.value.lastIndexOf('_'));
    }
}

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AgEditorComponent } from 'ag-grid-angular';
import { ICellEditorParams, IAfterGuiAttachedParams } from 'ag-grid-community';
import { DxNumberBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-core-custom-editor-qty',
  templateUrl: './core-custom-editor-qty.component.html',
  styleUrls: ['./core-custom-editor-qty.component.scss']
})
export class CoreCustomEditorQtyComponent implements AgEditorComponent, AfterViewInit {
  @ViewChild('input', { static: false }) input: DxNumberBoxComponent;
  value: number;
  constructor() { }
  getValue() {
    this.input.instance.blur();
    return this.value;
  }
  isCancelBeforeStart(): boolean {
    return false;
  }

  agInit(params: ICellEditorParams): void {
    this.value = params.value;
  }

  ngAfterViewInit(): void {
    const numberBoxElement = this.input.instance.element();
    const inputElement: HTMLInputElement = numberBoxElement.querySelector('input[type="text"]') as HTMLInputElement;
    setTimeout(() => {
      inputElement.focus();
      inputElement.select();
    });
  }

}

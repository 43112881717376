import { ChatMessage } from './chat-message';

export class BasicChatMessage extends ChatMessage {
    text: string;
    avatar: string;

    constructor(text: string, bot: string, avatar: string){
        super();
        this.text = text;
        this.user = {
            name: bot,
            avatar
        };
    }
}

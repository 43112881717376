import { Container } from 'src/app/shared/models/building-blocks';

export class Breadcrumb {
    constructor(public container: Container) {
    }

    get label(): string {
        return this.container.name;
    }
}

<ag-grid-angular
    #agGrid
    id="myGrid"
    class="ag-theme-alpine"
    [rowData]="treeData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [treeData]="true"
    [animateRows]="true"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [getDataPath]="getDataPath"
    (gridReady)="onGridReady($event)">
</ag-grid-angular>

import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DxLoadPanelComponent, DxResizableComponent } from 'devextreme-angular';
import { Gear, GearCode, GearType, Container } from 'src/app/shared/models/building-blocks';
import { BuildingBlocksService } from 'src/app/shared/services/building-blocks.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { FieldService } from '../../shared/services/field.service';
import { BuildingBlockHelperService } from './building-block-helper.service';
import { BbDiagramComponent } from './bb-diagram/bb-diagram.component';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { filter } from 'rxjs/operators';
import { DisplayDataArguments } from 'src/app/shared/models/core-display-data-arguments';
import { BbGridComponent } from './bb-grid/bb-grid.component';
import * as events from 'devextreme/events';
import { SellerService } from 'src/app/shared/services/seller.service';
import { SettingService } from 'src/app/shared/services/setting.service';
import { containerTypeIds, CoreFeature, EnumSettingClassId, EnumUserGroup } from 'src/app/shared/constants/enums';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { AuditGridFilterCriteria, AuditType } from 'src/app/shared/models/audit-grid-filter-criteria';

@Component({
    selector: 'app-building-blocks',
    templateUrl: './building-blocks.component.html',
    styleUrls: [
        './building-blocks.component.scss',
    ],
    providers: [
        FieldService
    ]
})
export class BuildingBlocksComponent implements OnInit, AfterViewInit {
    @ViewChild('diagram') diagram: BbDiagramComponent;
    @ViewChild('grid') grid: BbGridComponent;

    urlParamConsumed: boolean;
    loadPanel: ComponentRef<DxLoadPanelComponent>;
    propertyPanelVisible: boolean = true;
    propertiesDrawerButtonIcon: string;
    propertiesDrawerButtonIcons: string[] = [ 'chevronright', 'chevronleft' ];
    diagramWidth: string = '60%';
    diagramWidthPrev: string;
    diagramWidthMax: number;
    ddArgs: DisplayDataArguments;

    showCoreBotPopup = false;
    isUsingCoreBotBeta = false;
    isRuleBotVisible = false;
    userHasBotAccess = false;
    isAiRule: boolean = false;
    aiGearTypes: any[] = [
        GearCode.AIContextGearCode,
        GearCode.AIFilterGearCode,
        GearCode.AIFileRetrievalGearCode,
        GearCode.AITextGenerationGearCode,
        GearCode.AIScoreGearCode,
        GearCode.AISummaryGearCode
    ];

    constructor(private route: ActivatedRoute,
        private router: Router,
        private buildingBlockHelper: BuildingBlockHelperService,
        private buildingBlocksService: BuildingBlocksService,
        private helperService: HelperService,
        private viewContainerRef: ViewContainerRef,
        private appElementService: AppElementsService,
        private sellerService: SellerService,
        private settingService: SettingService,
        private permissionService: PermissionService,
        private cd: ChangeDetectorRef) {
        const previousArgs = sessionStorage.getItem('displayDataArguments');
        const isPageAccessedByReload = window.performance.navigation && window.performance.navigation.type === 1;
        if(previousArgs && previousArgs !== 'undefined' && !window['displayDataArguments'] && isPageAccessedByReload){
            this.ddArgs = JSON.parse(previousArgs);
        } else {
            this.ddArgs = window['displayDataArguments'];
            if(window['displayDataArguments']){
                sessionStorage.setItem('displayDataArguments', JSON.stringify(this.ddArgs));
            } else {
                sessionStorage.removeItem('displayDataArguments');
            }
        }
}

    ngOnInit() {
        this.router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe((e) => {
            if(!e['url']?.includes('/building-blocks')){
                this.buildingBlockHelper.setEditLock(false);
                this.buildingBlockHelper.setShowAdvancedView(false);
                this.buildingBlockHelper.setScopeContainer(null);
                this.buildingBlockHelper.setFocusedObject(null);
                this.buildingBlockHelper.setFocusedObjectChanges(null);
                this.buildingBlockHelper.clearScopeStack();
            }
        });
        this.propertiesDrawerButtonIcon = this.propertiesDrawerButtonIcons[0];
        this.urlParamConsumed = false;
        this.loadPanel = this.helperService.createLoadPanel({ of: '.dx-scrollview-content' });
        this.helperService.injectComponent(this.viewContainerRef, this.loadPanel);
        this.buildingBlockHelper.getShowLoadPanel().subscribe(showPanel => {
            if (showPanel) {
                this.loadPanel = this.helperService.createLoadPanel({ of: '.dx-scrollview-content' });
                this.helperService.injectComponent(this.viewContainerRef, this.loadPanel);
            } else if (showPanel === false) {
                this.loadPanel.destroy();
            }
        });

        this.buildingBlockHelper.getScopeContainer().subscribe(scopeContainer => {
            if(scopeContainer){
                if (this.ddArgs) {
                    this.buildingBlockHelper.setOriginWindow(window.opener);
                    const filterCriteria: AuditGridFilterCriteria = this.ddArgs.requiredProperties[0].propertyValue;
                    if(filterCriteria.type !== AuditType.EveryPathDownstream){
                        this.buildingBlockHelper.setFocusedObject(this.buildingBlockHelper.getObjectById(JSON.parse(scopeContainer.objectJson)['headProcessId']));
                    } else {
                        filterCriteria.auditedObjToRowPath[0] = scopeContainer.id;
                        this.buildingBlockHelper.setFocusedObject(scopeContainer);
                    }
                    this.buildingBlockHelper.setPeriodId(filterCriteria.rowKeyColumns['period_id']);
                    this.buildingBlockHelper.setSeriesId(this.ddArgs.popupArgs.seriesId);
                    setTimeout(() => this.grid.diagramAuditFromProcessing(filterCriteria, this.buildingBlockHelper.getObjectById(filterCriteria.auditedObjToRowPath[1])), 500);
                    this.openCloseProperties();
                } else {
                    this.buildingBlockHelper.setOriginWindow(null);
                    this.buildingBlockHelper.setFocusedObject(scopeContainer);
                }

                if (scopeContainer.typeId === containerTypeIds.AIRule){
                    this.isAiRule = true;
                }

                if (scopeContainer.hasOwnProperty('blockType')){
                    if (this.aiGearTypes.includes((scopeContainer as Gear).gearTypeCode)){
                        this.isAiRule = true;
                    }
                }
            }
        });

        this.buildingBlockHelper.getObjectStore().subscribe(res => {
            if (res !== null) {
                this.loadPanel.destroy();

                if (!this.urlParamConsumed) {
                    const containerId = this.route.snapshot.params.containerID;
                    const container: Container = this.buildingBlockHelper.getObjectById(containerId) as Container;
                    if (container) {
                        let recurrenceContainer: Container = container;
                        while (recurrenceContainer.id.startsWith('Co') && !recurrenceContainer.recurrenceId && recurrenceContainer.parentId) {
                            recurrenceContainer = this.buildingBlockHelper.getObjectById(recurrenceContainer.parentId) as Container;
                        }
                        this.buildingBlockHelper.setRecurrenceId(recurrenceContainer.recurrenceId);
                        this.buildingBlockHelper.setScopeContainer(container);
                    }
                    this.urlParamConsumed = true;
                }
            }

        });

        this.userHasBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString());
        this.buildingBlockHelper.getPropertyPanelWidthRequest().subscribe(result => this.resizeDiagramForPropertyPanelWidth(result));
    }

    ngAfterViewInit(): void {
        this.sellerService.getMe().subscribe(seller => {
            this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting).subscribe(res => {
                this.isUsingCoreBotBeta = res;
                if ((seller.userGroupId === EnumUserGroup.Implementer|| (this.isUsingCoreBotBeta === true && this.userHasBotAccess))
                        && window.location.href.match(/Sg\d+/) === null){
                    this.isRuleBotVisible = true;
                }
            });
        });
    }

    openCloseProperties(): void {
        this.propertyPanelVisible = !this.propertyPanelVisible;
        const diagramWidth = this.propertyPanelVisible ? this.diagramWidthPrev : '100%';

        this.diagramWidthPrev = this.diagramWidth;
        this.diagramWidth = diagramWidth;

        this.propertiesDrawerButtonIcon = this.propertiesDrawerButtonIcons[this.propertyPanelVisible ? 0 : 1];
        setTimeout(() => {
            this.diagram.repaint(false);
        });
    }

    onDiagramResizeStart(): void {
        this.diagramWidthMax = window.innerWidth - (this.propertyPanelVisible ? 400 : 0) - (this.appElementService.sideNavOuterToolbar.menuOpened ? 250 : 70);
        this.cd.detectChanges();
    }

    onDiagramResizeEnd(): void {
        this.diagramWidth = 100 * (+this.diagramWidth / document.getElementsByClassName('main-content')[0].clientWidth) + '%';
    }

    resizeDiagramForPropertyPanelWidth(newPropertyPanelWidth: number) {
        const propertyPanelNewWidthRatio = newPropertyPanelWidth/(window.innerWidth - (this.appElementService.sideNavOuterToolbar.menuOpened ? 250 : 70));
        this.diagramWidth = 100*(1-propertyPanelNewWidthRatio) + '%';
        this.cd.detectChanges();
        this.diagram.repaint();
    }

    onGearInsert(gear: any): void {
        this.diagram.insertGear(gear.type);
    }

    refreshCache(): void {
        this.buildingBlocksService.cacheAndLocalBuildingBlockRefresh();
    }

    displayCoreBotPopup(e: any){
        this.showCoreBotPopup = true;
    }

    onContentReady(e){
        events.on(e.component.content(), 'dxmousewheel', null, (ev) => {
            ev.stopPropagation();
        });
    }

    onPopupStateChanged(e: any){
        this.showCoreBotPopup = false;
    }
}

export class ReportLayout {
    reportParId: number;
    name: string;
    xml: string;
    isPayment: boolean;
    recordSource: number;
    recurrenceId: number;
    defaultUiViewId: number;
    contextCode: number;
    functionString: string;
    ruleString: string;
    fieldString: string;
    watermark: number;
    showNotes: boolean;
    showFormula: boolean;
    includeZeroes: boolean;
    defaultSeriesId: number;
    payeesOnly: boolean;
    attributes: string;
    publishedSellerIds: number[];
    exportAttributes: string;
    isPublic: boolean;
    showInactiveSegments: boolean;

    constructor() {
        this.reportParId = 0;
        this.showInactiveSegments = false;
    }
}

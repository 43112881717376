import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BucketContext } from 'src/app/shared/models/contexts/bucket-context';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToastrService } from 'ngx-toastr';
import { EnumBucketClass, EnumBucketType, coreResponseCodes } from '../../constants/enums';
import { BucketService } from '../../services/bucket.service';
import { Bucket } from '../../models/bucket';

@Component({
  selector: 'app-home-default-bulk',
  templateUrl: './home-default-bulk.component.html',
  styleUrls: ['./home-default-bulk.component.scss'],
  providers: []
})
export class HomeDefaultBulkComponent implements OnChanges {
    @Input() isPopupOpening!: boolean;
    @Input() itemId: number;
    @Input() type: EnumBucketType;
    @Output() isPopupOpeningChange = new EventEmitter<boolean>();

    attributeStr: string = '[0]';
    isAttributesPopupOpen: boolean = false;
    isConfirmationPopupVisible = false;

	constructor(
        private helperService: HelperService,
        private bucketService: BucketService,
        private toast: ToastrService) {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isPopupOpening?.currentValue) {
            this.isPopupOpening = false;
            this.isPopupOpeningChange.emit(false);
            this.isAttributesPopupOpen = true;
        }
    }

    onClickBulkHomeDefaultPermissionsOk = () => {
        this.isConfirmationPopupVisible = true;
    };

    onClickBulkHomeDefaultConfirmationYes = () => {
        this.setBulkHomeDefault(true);
    };

    onClickBulkHomeDefaultConfirmationNo = () => {
        this.setBulkHomeDefault(false);
    };

    closeBulkHomeDefaultConfirmation = () => {
        this.isConfirmationPopupVisible = false;
    };

    setBulkHomeDefault(overwrite: boolean): void {
        const bucketContext = new BucketContext();
        bucketContext.bucket = new Bucket({ itemId: this.itemId, type: this.type, bucketClassId: EnumBucketClass.DefaultHome});
        bucketContext.attributeIds = this.helperService.parseBracketedIdString(this.attributeStr);
        bucketContext.overwrite = overwrite;

        this.bucketService.addDefaultBucketBulk(bucketContext).subscribe(response => {
            if (response.responseCode === coreResponseCodes.Error) {
                this.toast.error(response.message);
            } else {
                this.toast.success('Home page updated for ' + response.result + ' users.');
            }
        });
        this.closeBulkHomeDefaultConfirmation();
    }
}

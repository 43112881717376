export class CoreEventArguments {
    event: any;
    functionName: string;
    component: any;

    constructor(event: any, functionName: string, component?: any) {
        this.event = event;
        this.functionName = functionName;
        this.component = component;
    }
}

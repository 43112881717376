import ArrayStore from 'devextreme/data/array_store';

export class ArrayStoreDictionary<TKey, TValue> {
    private store: ArrayStore;
    private keyMemberName: string;

    public constructor(valueArray: TValue[] = [], keyMemberName: string = 'id', onInserted?: (value: TValue, key: TKey) => any) {
        this.store = new ArrayStore({ key: keyMemberName, data: valueArray, onInserted });
    }

    public async keyExists(key: TKey): Promise<boolean> {
        try {
            await this.store.byKey(key);
            return true;
        } catch (ex) {
            return false;
        }
    }

    public async insert(value: TValue): Promise<void> {
        const key: TKey = value[this.keyMemberName];
        if (await this.keyExists(key)) {
            throw new Error(`Duplicate key: ${key}`);
        }

        return this.store.insert(value);
    }

    public async byKey(key: TKey): Promise<TValue> {
        if (!await this.keyExists(key)) {
            throw new Error(`Key not found: ${key}`);
        }

        return this.store.byKey(key);
    }

    public byFilter(predicate: (value: TValue) => boolean): TValue[] {
        return this.store.createQuery().filter(predicate).toArray();
    }

    public allValues(): TValue[] {
        return this.byFilter(value => true);
    }

    public async remove(key: TKey): Promise<void> {
        this.store.remove(key);
    }

    public clear(): void {
        this.store.clear();
    }

    public getStore(): ArrayStore {
       return this.store;
    }
}

export class Dictionary<TKey extends number | string | symbol, TValue> {
    private store: Record<TKey, TValue> = {} as Record<TKey, TValue>;
    private keyMemberName: string;

    public constructor(valueArray: TValue[] = [], keyMemberName: string = 'id', onInserted?: (value: TValue, key: TKey) => any) {
        this.keyMemberName = keyMemberName;
        for (const value of valueArray) {
            this.insert(value);
        }
    }

    public keyExists(key: TKey): boolean {
        return this.store[key] !== undefined;
    }

    public insert(value: TValue): void {
        const key: TKey = value[this.keyMemberName];
        if (this.keyExists(key)) {
            throw new Error(`Duplicate key: ${String(key)}`);
        }

        this.store[value[this.keyMemberName]] = value;
    }

    public byKey(key: TKey): TValue {
        if (!this.keyExists(key)) {
            throw new Error(`Key not found: ${String(key)}`);
        }

        return this.store[key];
    }

    public byFilter(predicate: (value: TValue) => boolean): TValue[] {
        const result: TValue[] = [];
        for (const key in this.store) {
            if (this.store[key] !== undefined && predicate(this.store[key])) {
                result.push(this.store[key]);
            }
        }
        return result;
    }

    public allValues(): TValue[] {
        return this.byFilter(value => true);
    }

    public remove(key: TKey): void {
        this.store[key] = undefined;
    }

    public clear(): void {
        this.store = {} as Record<TKey, TValue>;
    }

    public getStore(): TValue[] {
        return this.allValues();
    }
}

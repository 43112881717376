import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Recurrence } from '../models/recurrence';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class RecurrenceService {
    DEFAULT_ID: number = -1;
    defaultRecurrence: Recurrence = new Recurrence(this.DEFAULT_ID, 'All', null, null, null, null);

    private url: string = environment.apiEndpoint + '/Recurrence';

    constructor(private http: HttpClient) {
    }

    getRecurrenceById(recurrenceId: number): Observable<Recurrence> {
        return this.http.get<Recurrence>(this.url + '/' + recurrenceId);
    }

    getAllPeriodsReccurenceId(): Observable<number> {
        return this.http.get<number>(this.url + '/allPeriods');
    }

    GetAllPublishedRecurrences(): Observable<Recurrence[]> {
        return this.http.get<Recurrence[]>(this.url + '/publishedRecurrences');
    }

    GetAllRecurrences(): Observable<Recurrence[]> {
        return this.http.get<Recurrence[]>(this.url + '/allRecurrences');
    }

    insertRecurrence(recurrence: Recurrence): Observable<Recurrence> {
        return this.http.post<Recurrence>(this.url, recurrence);
    }

    updateRecurrence(recurrence: Recurrence): Observable<number> {
        return this.http.put<number>(this.url, recurrence);
    }

    deleteRecurrence(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.url + '/' + id);
    }
}

import { Component, ViewChild } from '@angular/core';
import { ColumnApi, GridApi, IAfterGuiAttachedParams, IStatusPanelParams } from 'ag-grid-community';
import { DxTextBoxComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { CoreFeature, coreResponseCodes } from 'src/app/shared/constants/enums';
import { CoreDropdownProperties } from 'src/app/shared/models/core-dropdown-properties';
import { UiView } from 'src/app/shared/models/ui-view';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { UiViewService } from 'src/app/shared/services/ui-view.service';

@Component({
  selector: 'app-core-custom-statusbar',
  templateUrl: './core-custom-statusbar.component.html',
  styleUrls: ['./core-custom-statusbar.component.scss']
})
export class CoreCustomStatusbarComponent{

	@ViewChild('searchInput') searchInputBox: DxTextBoxComponent;

	columnApi: ColumnApi;
	gridApi: GridApi;
	params: IStatusPanelParams;
	layoutProps: CoreDropdownProperties = new CoreDropdownProperties();
	selectedLayout: number = null;
	layouts: UiView[] = [];
	currentLayoutId: number;
	layoutSourceId: number;
	layoutSourceField: string;
	isMultipleSources: boolean;
	contextCode: number;
	isProcessedOutput: boolean;
	refreshInsertGridCells: () => void;
	handleLayoutFilterModelChangeOnComponent: (filterModel: any) => void;
	filterBuilderValueChanged: (value, fromLayoutChange) => any;
	layoutChanged: () => any;
	quickSearchValue: string = '';
	storedDefaultLayoutId: number;
	filterBuilderValue: any = [];

	permissionDeleteLayout: boolean;
	permissionAddLayout: boolean;
	permissionEditLayout: boolean;

	private _defaultLayoutId: number;
	get defaultLayoutId(): number{
		return this._defaultLayoutId;
	}
	set defaultLayoutId(newVal: number) {
		this._defaultLayoutId = newVal;
		this.selectedLayout = newVal;
		this.onLayoutChanged(newVal);
	}

  constructor(private uiViewService: UiViewService,
		private toaster: ToastrService,
		private authService: AuthService,
		private permissionService: PermissionService){}

	async agInit(params): Promise<void> {
		await this.permissionService.initializePermissions().then(x => {
			this.permissionDeleteLayout = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteLayouts.toString());
			this.permissionAddLayout = this.permissionService.checkCurrentUserPermission(CoreFeature.CreateLayouts.toString());
			this.permissionEditLayout = this.permissionService.checkCurrentUserPermission(CoreFeature.EditLayouts.toString());
		}).then(x => {

			this.layoutProps  = new CoreDropdownProperties()
				.createAll(true, 'id', 'name', !this.permissionEditLayout,!this.permissionAddLayout,!this.permissionEditLayout, !this.permissionDeleteLayout, true, false, 150);
			this.params = params;
			this.columnApi = this.params.columnApi;
			this.gridApi = this.params.api;
			this.layoutSourceField = params.layoutSourceField;
			this.layoutSourceId = params.layoutSourceId;
			this.isMultipleSources = params.isMultipleSources;
			this.contextCode = params.contextCode;
			this.isProcessedOutput = params.isProcessedOutput;
			this.refreshInsertGridCells = params.refreshInsertGridCells;
			this.handleLayoutFilterModelChangeOnComponent = params.handleLayoutFilterModelChangeOnComponent;
			this.filterBuilderValueChanged = params.filterBuilderValueChanged;
			this.layoutChanged = params.layoutChanged;
			this.uiViewService.getUiViewsByContextCode(this.contextCode).subscribe(layouts => {
				if (this.isMultipleSources && this.isProcessedOutput) {
					this.layouts = layouts.filter(l => l.functionId === null && l.functionGroupId === null);
				} else if (this.isMultipleSources && !this.isProcessedOutput) {
					this.layouts = layouts.filter(l => l.datasourceId === null);
				} else {
					this.layouts = layouts.filter(l => l[this.layoutSourceField] === this.layoutSourceId);
				}
				if (this.currentLayoutId) {
					this.onLayoutChanged(this.currentLayoutId);
				}
			});
		});
		this.storedDefaultLayoutId = this.uiViewService.getDefaultRecordLayout(this.authService.getTenant(), this.layoutSourceField, this.layoutSourceId);
	}

	focusSearchInputBox() {
		this.searchInputBox.instance.focus();
	}

  	sendQuickSearch(): void {
		this.gridApi.refreshServerSide({ route: [], purge: true });
		this.gridApi.refreshCells();
		this.refreshInsertGridCells();
	}

	clearFilters() {
		this.filterBuilderValueChanged([], true);
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
	}

	clearGroups() {
		this.columnApi.resetColumnState();
		this.columnApi.resetColumnGroupState();
	}

  	onLayoutChanged(layoutId: number) {
		this.currentLayoutId = layoutId;
		if (this.layouts.length > 0) {
			const layoutToApply = this.layouts.find(l => l.id === layoutId);
			const [columnStateJSON, filterStateJSON] = layoutToApply.layoutString1.split('~');
			const columnState = JSON.parse(columnStateJSON);
			this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
			this.layoutChanged();
			const [filterModelJSON, filterBuilderValueJSON] = filterStateJSON.split('≈');
			if (filterModelJSON && filterModelJSON !== '{}') {
				this.gridApi.setFilterModel(null);
				setTimeout(() => {
					const filterModel = JSON.parse(filterModelJSON);
					this.gridApi.setFilterModel(filterModel);
					this.handleLayoutFilterModelChangeOnComponent(filterModel);
					this.gridApi.onFilterChanged();
				});
			}
			if (filterBuilderValueJSON && filterBuilderValueJSON !== '[]') {
				const filterBuilderValue = JSON.parse(filterBuilderValueJSON);
				this.filterBuilderValueChanged(filterBuilderValue, true);
			} else {
				this.filterBuilderValueChanged([], true);
			}
		}
	}

	onLayoutCheckBoxClick(e) {
		if (!e.value) {
			this.columnApi.resetColumnState();
			this.clearFilters();
		} else {
			this.onLayoutChanged(this.currentLayoutId);
		}
	}

	addLayout(name: string) {
		let newLayout = new UiView();
		newLayout.name = name;
		newLayout.layoutString1 = JSON.stringify(this.columnApi.getColumnState()) + '~' + JSON.stringify(this.gridApi.getFilterModel())
			+ '≈' + JSON.stringify(this.filterBuilderValue);
		newLayout.contextCode = this.contextCode;
		if (!this.isMultipleSources) {
			newLayout[this.layoutSourceField] = this.layoutSourceId;
		}
		this.uiViewService.insertUiView(newLayout).subscribe(res => {
			newLayout = res;
			this.layouts.push(newLayout);
			this.selectedLayout = newLayout.id;
			this.currentLayoutId = newLayout.id;
		});
	}

	deleteLayout(_: any) {
		this.uiViewService.deleteUiView(this.currentLayoutId).subscribe(response => {
			if (response.responseCode === coreResponseCodes.Success) {
				this.columnApi.resetColumnState();
				this.clearFilters();
				this.selectedLayout = undefined;
				this.layouts = this.layouts.filter(l => l.id !== this.currentLayoutId);
				this.currentLayoutId = null;
			} else {
				this.toaster.error('Failed to delete layout. Close window and try again.');
			}});
		this.columnApi.resetColumnState();
	}

	renameLayout(newName: string) {
		const uiView = this.layouts.find(l => l.id === this.currentLayoutId);
		uiView.name = newName;
		this.uiViewService.updateUiView(uiView).subscribe(_ => {
			this.selectedLayout = this.currentLayoutId;
			this.toaster.success('Layout updated');
		},
			(err) => {
				this.toaster.error('Failed to rename Layout. Close window and try again.');
			});
	}

	saveLayout(e) {
		const uiView = this.layouts.find(l => l.id === this.currentLayoutId);
		uiView.layoutString1 = JSON.stringify(this.columnApi.getColumnState()) + '~' + JSON.stringify(this.gridApi.getFilterModel())
			+ '≈' + JSON.stringify(this.filterBuilderValue);
		this.uiViewService.updateUiView(uiView).subscribe(_ => {
			this.toaster.success('Layout updated successfully.');
		},
			(err) => {
				this.toaster.error('Layout failed to update.');
			});
	}

	setDefaultLayout(e) {
		if (this.storedDefaultLayoutId === this.currentLayoutId) {
			this.uiViewService.removeDefaultRecordLayout(this.authService.getTenant(), this.layoutSourceField, this.layoutSourceId);
			this.storedDefaultLayoutId = null;
		} else {
			this.uiViewService.setDefaultRecordLayout(this.authService.getTenant(), this.layoutSourceField, this.layoutSourceId, this.currentLayoutId);
			this.storedDefaultLayoutId = this.currentLayoutId;
		}
	}
}

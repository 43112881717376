import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { DistributionList } from '../models/distribution-list';
import { EnumBucketType } from '../constants/enums';
import { DistributionListDefault } from '../models/distribution-list-default';

@Injectable()
export class DistributionListService {
    private distributionListURL = environment.apiEndpoint + '/distributionlist/';

    constructor(private http: HttpClient) {
    }

    getDistributionLists(userId: number): Observable<DistributionList[]> {
        return this.http.get<DistributionList[]>(this.distributionListURL + userId.toString());
    }

    getGlobalDistributionLists(): Observable<DistributionList[]> {
        return this.http.get<DistributionList[]>(this.distributionListURL + 'global');
    }

    getDefaultDistributionList(applyDefaults: boolean, bucketType: EnumBucketType, id: number): Observable<DistributionList> {
        if (applyDefaults && id) {
            return this.http.get<DistributionList>(this.distributionListURL + 'default/' + bucketType.toString() + '/' + id.toString());
        } else {
            return of(null);
        }
    }

    getDistributionListDefaults(distributionListId: number): Observable<DistributionListDefault[]> {
        return this.http.get<DistributionListDefault[]>(this.distributionListURL + 'defaults/' + distributionListId.toString());
    }

    insertDistributionList(distributionList: DistributionList): Observable<DistributionList> {
        return this.http.post<DistributionList>(this.distributionListURL + 'insert', distributionList);
    }

    updateDistributionList(distributionList: DistributionList): Observable<number> {
        return this.http.put<number>(this.distributionListURL + 'update', distributionList);
    }

    deleteDistributionList(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.distributionListURL + 'distributionList/' + id.toString());
    }
}

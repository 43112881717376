<dx-popup class="core-bot-popup"
    [wrapperAttr]="{class:'core-bot-popup'}"
    [width]="600"
    [height]="725"
    [showTitle]="true"
    title="CoreBot AI Assistant"
    [shading]="true"
    [dragEnabled]="showToolbarItems"
    container=".dx-viewport"
    [showCloseButton]="showToolbarItems"
    [(visible)]="showPopup"
    (onHiding)="onHiding($event)"
    (onContentReady)="onContentReady($event)"
    (onShown)="scrollMessagesToBottom($event)">
    <dxi-toolbar-item
      [visible]="showToolbarItems"
      location="after"
      widget="dxButton"
      toolbar="top"
      locateInMenu="never"
      [options]="{
        icon: 'unselectall',
        hint: 'Open CoreBot popout window',
        onClick: openCoreBotPopout
      }"
    >
    </dxi-toolbar-item>
        <dx-scroll-view width="100%" height="100%" useNative="true">
            <div class="chatbot-disclaimer">
                <div id="chatbot-disclaimer-title" class="disclaimer-message">
                    Click to View AI Technology Disclaimer
                </div>
                <dx-tooltip
                    target="#chatbot-disclaimer-title"
                    showEvent="click"
                    [hideOnOutsideClick]="true"
                    width="300px"
                >
                    <div *dxTemplate="let data = data; of: 'content'"> CoreBot is an AI solution in testing. While CoreBot is very
                        powerful, it does not always produce accurate results. Core Commissions does not make any guarantee of
                        the accuracy of CoreBot's results. If you have any doubt about CoreBot's output, please reach out to the 
                        Core Commissions implementation team.
                    </div>
                </dx-tooltip>
                </div>
            <div class="nb-theme-default">
                <corebot-popup-content #chatContent
                class="chatbot-theme"
                [messages]="messages" 
                [sellerName]="sellerName"
                size="medium"
                (confirmRedirect)="redirect($event)"
                (confirmAction)="getFollowUpResponse($event)"
                (denyAction)="resetChat($event)"
                (confirmChat)="getChatBotResponse($event)">

                </corebot-popup-content>
                <div class="chatbot-chat-form" #chatBotChat id="chatBotChat">
                    <div class="chatbot-message-row">
                        <input #chatBotInput nbInput fullWidth
                        id="chatBotInput"
                        class="chatbot-input"
                        [(ngModel)]="message" 
                        (ngModelChange)="onModelChange($event)"
                        (keyup.enter)="sendMessageChatBot($event)"
                        resize="none"
                        autocomplete="off"
                        [placeholder]="samplePrompt"/>
                        <button
                            nbButton
                            #sendButton
                            (click)="sendMessageChatBot($event)"
                            class="send-button"
                            status="primary"
                        >
                            <nb-icon [icon]="buttonIcon" pack="nebular-essentials"></nb-icon>
                        </button> 
                    </div>
                </div>
            </div>
        </dx-scroll-view>
    <dxi-toolbar-item #newConversationButton
        widget="dxButton"
        toolbar="bottom"
        [options]="{
            text: 'New Conversation',
            stylingMode: 'outlined',
            type: 'normal',
            onClick: clearCoreBotConversation
        }">
    </dxi-toolbar-item>
    <dxi-toolbar-item #contactSupportButton
        widget="dxButton"
        toolbar="bottom"
        [visible]="isSupportVisible"
        [options]="{
            text: 'Contact Core Support',
            visible: isSupportVisible,
            stylingMode: 'outlined',
            type: 'normal',
            onClick: showTicketWidget
        }">
    </dxi-toolbar-item>
</dx-popup>

<dx-popup
    [width]="500"
    [height]="650"
    [showTitle]="true"
    title="Nav Folder Settings"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="false"
    [(visible)]="isPopupVisible"
    [showCloseButton]="false"
    titleTemplate="titleTemplate"
    class="nav-bucket-folders-popup">
    <div *dxTemplate="let data of 'titleTemplate'">
        <div class="dx-toolbar dx-widget dx-visibility-change-handler dx-collection" role="toolbar">
            <div class="dx-toolbar-items-container">
                <div class="dx-toolbar-before">
                    <div class="dx-item dx-toolbar-item dx-toolbar-label">
                        <div class="dx-item-content dx-toolbar-item-content">
                            <div class="popup-title-text">Nav Folder Settings</div>
                            <div id="popupTitleTooltip" class="popup-title-info-icon">
                                <i class="dx-icon-info"></i>
                            </div>
                            <dx-tooltip target="#popupTitleTooltip" position="right" showEvent="dxhoverstart" hideEvent="dxhoverend">
                                <div *dxTemplate="let data of 'content'" class="popup-title-tooltip">
                                    <span>Use these settings to define the folder structure (if any) in which you would like 
                                        the {{itemTypeText}}s to display in the left nav of the site for all users.</span>
                                </div>
                            </dx-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <dx-responsive-box>
        <dxi-row [ratio]="5"></dxi-row>
        <dxi-row [ratio]="3"></dxi-row>
        <dxi-row [ratio]="10"></dxi-row>
        <dxi-row [ratio]="1"></dxi-row>

        <dxi-col [ratio]="9"></dxi-col>
        <dxi-col [ratio]="1"></dxi-col>
        <dxi-col [ratio]="10"></dxi-col>

        <dxi-item>
            <dxi-location [row]="0" [col]="0"></dxi-location>
            <dx-radio-group
                [items]="radioOptions"
                [(value)]="radioSelectedValue"
                (onValueChanged)="onChange()"
                displayExpr="name"
                valueExpr="id"
                class="nav-folder-options">
            </dx-radio-group>
        </dxi-item>

        <dxi-item>
            <dxi-location [row]="1" [col]="0"></dxi-location>
            <ng-container *ngIf="radioSelectedValue === radioValueNoFolder">
                <span class="no-folder-explanation">This {{itemTypeText}} will display as a child of the '{{itemTypeNavItemName}}' nav item.</span>
            </ng-container>
            <ng-container *ngIf="radioSelectedValue === radioValueExisting">
                <span class="bucket-folder-label">Folder:</span>
                <dx-select-box
                    [dataSource]="existingBucketClasses"
                    [(value)]="selectedBucketClassId"
                    valueExpr="id"
                    displayExpr="name"
                    (onSelectionChanged)="onChange()">
                </dx-select-box>
            </ng-container>
            <ng-container *ngIf="radioSelectedValue === radioValueCreateNew">
                <span class="bucket-folder-label">Enter Folder Name:</span>
                <dx-text-box
                    [(value)]="createNewFolderName"
                    (input)="createNewFolderName = $event.target.value;"
                    (onKeyUp)="onChange()">
                </dx-text-box>
                <div class="validation-message">
                    <span [hidden]="!bucketClassNameExists">
                        <span> 
                            Folder '{{createNewFolderName}}' already exists.
                        </span>
                    </span>
                </div>
            </ng-container>
        </dxi-item>        
        
        <dxi-item>
            <dxi-location [row]="2" [col]="0"></dxi-location>
            <div class="sort-order-setting">
                <span>Set Item Order:</span>
                <dx-button class="nav-folder-ordering-button" icon="chevronup" text="Move Up" (click)="moveItem(true)" [disabled]="upButtonDisabled"></dx-button>
                <dx-button class="nav-folder-ordering-button" icon="chevrondown" text="Move Down" (click)="moveItem(false)" [disabled]="downButtonDisabled"></dx-button>
            </div>
        </dxi-item>

        <dxi-item>
            <dxi-location [row]="0" [col]="2" [rowspan]="3"></dxi-location>
            <div class="nav-folder-preview">
                <div class="nav-folder-preview-label">
                    <span>Preview:</span>
                </div>
                <div class="nav-folder-preview-content">
                    <app-side-navigation-menu
                        [items]="menuItems"
                        [compactMode]="false"
                        [selectedItem]="null"
                        [isPreview]="true"
                        [class]="siteThemeConfig.addlClassName">
                    </app-side-navigation-menu>
                </div>
            </div>
        </dxi-item>

        <dxi-item>
            <dxi-location [row]="3" [col]="0" [colspan]="2"></dxi-location>
            <dx-button class="popup-button dx-button-default" [disabled]="saveButtonDisabled" (click)="saveClick()">Save</dx-button>
        </dxi-item>
        <dxi-item>
            <dxi-location [row]="3" [col]="2"></dxi-location>
            <dx-button class="popup-button" (click)="cancelClick()">Cancel</dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>

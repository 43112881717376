import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreTenant } from '../models/core-tenant';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppInfoService {

    // TODO:  should test be in a json file?
    readonly coreLogo: string = 'assets/images/CoreLogo.png';
    readonly coreTitle: string = 'Core Commissions';
    readonly coreHomeImage: string = 'assets/images/CoreLogo.png';
    readonly coreAppVersion: string = environment.version;

    public showCompanyIcon: boolean = false;
    public showCompanyLabel: boolean = false;

    private _title: string = '';
    public get title() {
        return this._title;
    }

    private _logo: string = '';
    public get logo() {
        return this._logo;
    }

    public get homeImage() {
        return this.coreHomeImage;
    }

    public get appVersion() {
        return this.coreAppVersion;
    }

    constructor(private httpClient: HttpClient) {
        this.initialize();
    }

    async initialize() {
        const subdomain = this.getSubdomain(window.location.hostname);
        if (subdomain) {
            await this.setLogo(subdomain)
                .then(() => {
                    if (this.logo === '') {
                        this._logo = this.coreLogo;
                    }
                    if (this.title === '') {
                        this._title = this.coreTitle;
                    }
                });
        } else {
            this._logo = this.coreLogo;
            this._title = this.coreTitle;
            this.showCompanyLabel = true;
            this.showCompanyIcon = true;
        }
    }

    getSubdomain(domain) {
        if (domain.indexOf('.') < 0 ||
            domain.split('.')[0] === '127' || domain.split('.')[0] === 'dev' || domain.split('.')[0] === 'www') {
            return '';
        } else {
            return domain.split('.')[0];
        }
    }

    async setLogo(tenantName) {
        let url = environment.webEndpoint;
        if (tenantName) {
            url = url.replace('www.', tenantName + '.');
        }
        url = `${url}/assets/clients/${tenantName}/rptlogo.png`;
        await Promise.resolve(this.getImage(url))
            .then(resolve => {
                if (resolve instanceof Blob) {
                    this._logo = url;
                }
            }, (e) => {
                this._logo = '';
                  this.showCompanyIcon = false;
            });
    }

    async getImage(url) {
        // TODO:  this could be easier at some point to pull from corecust
        // let headers: new Headers({ 'Access-Control- Allow-Origin: *' });

        return await new Promise<Blob>((resolve, reject) => {
            this.httpClient.get(url, { responseType: 'blob' }).subscribe(response => resolve(response), (e) => reject(e));
        });
    }

    async getTenant(tenantName): Promise<CoreTenant> {
        if (tenantName) {
            return await new Promise<CoreTenant>((resolve, reject) => {
                this.httpClient.get<CoreTenant>(`${environment.apiEndpoint}/tenant/${tenantName}`).subscribe(tenant => {
                    if (tenant != null) {
                        return resolve(tenant);
                    }
                }, () => reject(null));
            });
        }
    }
}

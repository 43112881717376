<ng-container *ngIf="sourceFieldDropDownDatasource && sourceFieldDropDownDatasource.dataSource.length > 0">
    <dx-data-grid
        [(dataSource)]="internalDatasource"
        (onCellClick)="checkForChanges()"
        (onRowInserted)="emitValue()"
        (onRowUpdated)="emitValue()"
        (onRowRemoved)="emitValue()"
        (onToolbarPreparing)="filterToolbarItems($event)">
        <dxi-column dataField="toField" caption="Copy To" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="sourceFieldDropDownDatasource['dataSource']"
                [displayExpr]="sourceFieldDropDownDatasource['displayExpr']"
                [valueExpr]="sourceFieldDropDownDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="fromField" caption="Copy From" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="sourceFieldDropDownDatasource['dataSource']"
                [displayExpr]="sourceFieldDropDownDatasource['displayExpr']"
                [valueExpr]="sourceFieldDropDownDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="updateType" caption="Update Type" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="updateOptions['dataSource']"
                [displayExpr]="updateOptions['displayExpr']"
                [valueExpr]="updateOptions['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
    </dx-data-grid>
</ng-container>

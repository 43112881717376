<dx-drop-down-box
    #coreTableDropDownDropDown
    [dropDownOptions]="dropDownOptions"
    [dataSource]="parentColumn.columnType.lookupData"
    [(value)]="selectedValue"
    [displayExpr]="parentColumn.columnType.lookupDisplayValue"
    [valueExpr]="parentColumn.columnType.lookupValue"
    contentTemplate="contentTemplate">
    <div *dxTemplate="let e of 'contentTemplate'">
        <core-data-grid
            #coreTableDropDownGrid
            [dataSource]="parentColumn.columnType.lookupData"
            [columns]="columns"
            [props]="props"
            [focusedRowKey]="selectedValue"
            [parentTemplate]="e">
        </core-data-grid>
    </div>
</dx-drop-down-box>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { FileSource } from '../models/file-source';

@Injectable()
export class FileSourceService {
    private baseUrl = environment.apiEndpoint + '/FileSource';

    constructor(private http: HttpClient) {
    }

    getAllFileSources(): Observable<CoreResponse<FileSource>> {
        return this.http.get<CoreResponse<FileSource>>(this.baseUrl);
    }

    getFileSourceById(fileSourceId: number): Observable<CoreResponse<FileSource>> {
        return this.http.get<CoreResponse<FileSource>>(this.baseUrl + '/' + fileSourceId);
    }

    insertFileSource(fileSource: FileSource): Observable<CoreResponse<FileSource>> {
        return this.http.post<CoreResponse<FileSource>>(this.baseUrl, fileSource);
    }

    updateFileSource(fileSource: FileSource): Observable<CoreResponse<number>> {
        return this.http.put<CoreResponse<number>>(this.baseUrl, fileSource);
    }

    deleteFileSource(id: number): Observable<CoreResponse<CoreResponse<number>>> {
        return this.http.delete<CoreResponse<CoreResponse<number>>>(this.baseUrl + '/' + id);
    }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy, ViewChildren, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { CoreFeature, EmailTestResult, EnumUserGroup, settingClassIds, singletonClassIds } from 'src/app/shared/constants/enums';
import { Router, NavigationEnd } from '@angular/router';
import {
    DxButtonGroupModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTextBoxModule,
    DxTreeListModule, DxDataGridModule, DxSwitchModule, DxLoadPanelModule, DxContextMenuModule, DxTextAreaModule, DxTextAreaComponent, DxPopoverModule, DxPopoverComponent, DxTooltipModule
} from 'devextreme-angular';
import { DxButtonComponent, DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarComponent, DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { AppInfoService } from '../../services/app-info.service';
import { AuthService } from '../../services/auth.service';
import { SellerService } from '../../services/seller.service';
import { PeriodService } from '../../services/period.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { UserPanelComponent, UserPanelModule } from '../user-panel/user-panel.component';
import { DxBoxModule } from 'devextreme-angular/ui/box';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { PollValue } from '../../models/poll-value';
import { ProcessingService } from '../../services/processing.service';
import { SettingService } from '../../services/setting.service';
import { EnumSettingClassId } from '../../constants/enums';
import { CoreComponentModule } from '../core-component.module';
import { ProcessLogService } from '../../services/process-log.service';
import { CorePopupProperties } from '../../models/core-popup-properties';
import { CorePopupStep } from '../../models/core-popup-step';
import { ProcessLogForAdminPopup } from '../../models/process-log-for-admin-popup';
import { CoreColumn } from '../../models/core-column';
import { GridProps } from '../../models/core-data-grid-properties';
import { ProcessLogIdTransactionId } from '../../models/process-log-id-transaction-id';
import { CoreDropdownProperties } from '../../models/core-dropdown-properties';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TrelloService } from 'src/app/shared/services/trello.service';
import { User } from '../../models/user';
import { Seller } from '../../models/seller';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../services/helper.service';
import { ChatBotChatModule } from '../corebot/chatbot-chat/chatbot-chat.module';
import { EmailService } from '../../services/email.service';
import { FieldService } from '../../services/field.service';
import { Singleton } from '../../models/singleton';
import { CoreBotWidgetComponent, CoreBotWidgetModule } from '../corebot/corebot-shared/corebot-widget/corebot-widget.component';
import { ChatBotChatComponent } from '../corebot/chatbot-chat/chatbot-chat.component';
import { CoreBotInteractionService } from '../../services/core-bot-interaction-service';
import { stringAlignmentValues } from 'devexpress-reporting/scopes/reporting-chart-internal';
import { first } from 'rxjs/operators';
import { SiteThemeService } from '../../services/site-theme.service';
import { SiteThemeDefaultDark } from '../../../shared/constants/theme-constants';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('coreHeader', { static: false, read: ElementRef }) headerElement: ElementRef;
    @ViewChild(UserPanelComponent, { static: false}) userMenuComponent: UserPanelComponent;
    @ViewChild('chatBot', {static: false}) chatBotComponent: ChatBotChatComponent;
    @ViewChild('botWidget', {static: false}) chatBotWidget: CoreBotWidgetComponent;


    @Input() menuToggleEnabled = false;
    @Input() title: string;
    @Input() themeClass: string;
    @Input() isBotMenuItemVisible: boolean = false;

    @Output() menuToggle = new EventEmitter<boolean>();
    @Output() parameterSelectionChanged = new EventEmitter();

    beginDate: Date;
    endDate: Date;
    pickerPopupVisible: boolean = false;
    hasAdminView: boolean = true;
    helpPopupVisible: boolean = false;
    agreementPopupVisible: boolean = false;
    isInactivePopupVisible: boolean = false;
    hasDates: boolean = true;
    pageDestroyed: boolean = false;
    isProcessingInProgress: boolean = false;
    showCompanyIcon: boolean = false;
    showCompanyLabel: boolean = false;
    awaitingLogout: boolean = false;
    unsavedChanges: boolean = false;
    isReportParametersEnabled: boolean = false;
    reportParametersEnabledRoutes: string[] = ['/pages/report', '/pages/dashboard', '/pages/analytic', '/bingham/what-if', '/home'];
    explainDocPath: SafeResourceUrl;
    agreementDocPath: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl('../../../assets/documents/CoreAgreement.pdf#toolbar=0');
    activeUser: Seller;
    msInMinute: number = 60000;
    companyHeader: string;

    isInteractionClicked: boolean = false;
    botPrompt: string;
    showCoreBotPopup: boolean = false;
    isDarkMode: boolean = false;
    isBITheme: boolean = false;

    userMenuItems = [
        {
            text: 'Explain the Screen',
            icon: 'info',
            onClick: () => {
                this.showHelp();
            }
        },
        {
            text: 'Change Password',
            icon: 'key',
            onClick: () => {
                this.router.navigate(['/change-password']);
            },
            disabled: false
        },
        {
            text: 'Log Out',
            icon: 'runner',
            onClick: () => {
                this.onLogout();
            }
        }];

    agreementMenuItem = {
        text: 'View Agreement',
        icon: 'paste',
        onClick: () => {
            this.showAgreement();
        }
    };

    switchModeMenuItem = {
        text: 'Switch to dark mode',
        icon: 'isblank',
        onClick: () => {
            this.toggleLightDarkMode();
        }
    };

    hasAliasPermission: boolean = false;
    aliasMenuItem = {
        text: 'Alias as User',
        icon: 'user',
        onClick: () => {
            this.aliasPopupVisible = true;
        }
    };
    aliasPopupVisible: boolean = false;
    aliasUsers: any[] = [];
    aliasSellerImports: any[] = [];
    aliasDropdownColumns = [
        new CoreColumn('name', 'Name', true),
        new CoreColumn('loginName', 'Login Name', true),
        new CoreColumn('importName', 'Import Name', true, 'string', null, false, null, true),
    ];
    aliasDropdownProps = new CoreDropdownProperties()
        .createAll(true, 'id', 'name', true, true, true, true, false, false, 200)
        .convertToTable(new GridProps('aliasUsersGrid', null, true, true, false, false).setToSingleRowSelection(), 500, 400)
        .setProp('placeholder', 'Select a user...');
    aliasSelectedId: number;
    isAliasGridDataLoaded: boolean = false;

    username: string;
    sellerId: number;
    isLogViewPopupEnabled: boolean = false;

    popupDefaultHeight: string = '550';
    popupDefaultWidth: string = '850';
    headerPopupProps: CorePopupProperties = new CorePopupProperties().createMessageOnly(this.popupDefaultWidth, this.popupDefaultHeight, true, 'Current Logs');
    headerPopupSteps: CorePopupStep[] = [];
    processLogData: ProcessLogForAdminPopup[] = [];
    processLogGridProps: GridProps = new GridProps('processLogGrid', null, false, true);
    processLogColumns: CoreColumn[] = [
        new CoreColumn('processLogId', '', false),
        new CoreColumn('transactionId', '', false),
        new CoreColumn('processLogRecordTypeId', '', false),
        new CoreColumn('processName', 'Process', true, 'string'),
        new CoreColumn('log', 'Log', true, 'string'),
        new CoreColumn('date', 'Log Date', true, 'datetime', null, false, 'MM/dd/yyyy hh:mm:ss a').sortAsc(),
        new CoreColumn('userName', 'User Name', true, 'string')
    ];

    isEmailAutomateTestExpired: boolean;
    isEmailIssueIndicatorVisible: boolean = false;
    isEmailIssuePopupVisible: boolean = false;
    emailIssueMessage: string = 'There may be a problem with your email connection';
    emailTest: Singleton;

    constructor(private authService: AuthService,
        private sellerService: SellerService,
        private periodService: PeriodService,
        private router: Router,
        public appInfo: AppInfoService,
        private appElementService: AppElementsService,
        private permissionService: PermissionService,
        private processingService: ProcessingService,
        private settingService: SettingService,
        private processLogService: ProcessLogService,
        private trelloService: TrelloService,
        private helperService: HelperService,
        private emailService: EmailService,
        private fieldService: FieldService,
        private interactionService: CoreBotInteractionService,
        private siteThemeService: SiteThemeService,
        public sanitizer: DomSanitizer,
        private toast: ToastrService,) {
    }

    ngOnInit() {
        this.appInfo.getTenant(this.authService.getTenant()).then(res => {
            if(res){
                this.companyHeader = res.customerName;
            }
            else {
                this.companyHeader = this.appInfo.coreTitle;
            }
        });

        this.appElementService.headerComponent = this;

        this.settingService.getBoolSetting(EnumSettingClassId.ShowCompanyIcon).subscribe(iconEnabled => {
            this.showCompanyIcon = iconEnabled;
        });
        this.settingService.getBoolSetting(EnumSettingClassId.ShowCompanyLabel).subscribe(labelEnabled => {
            this.showCompanyLabel = labelEnabled;
        });

        this.isDarkMode = this.siteThemeService.getIsDarkMode();
        this.setModeMenuItemContent();
        this.userMenuItems.splice(2, 0, this.switchModeMenuItem);

        this.settingService.getBoolSetting(EnumSettingClassId.EnableBITheme).subscribe(isBITheme => {
            this.isBITheme = isBITheme;
        });

        this.sellerService.getSellerWithSubordinateSetting(this.authService.getUserFromToken()).subscribe(seller => {
            this.username = seller.name;
            this.sellerId = seller.id;

            if (seller.userGroupId !== EnumUserGroup.Implementer && seller.userGroupId !== EnumUserGroup.Administrator) {
                this.hasAdminView = false;
            }

            if (this.hasAdminView) {
                this.checkEmailSettings();
            }

            if (this.permissionService.checkCurrentUserPermission(CoreFeature.ViewServicesAgreement.toString())) {
                this.userMenuItems.splice(2, 0, this.agreementMenuItem);
            }

            const isSubsOnly = this.permissionService.checkCurrentUserPermission(CoreFeature.ImpersonateSubordinates.toString());
            if (this.permissionService.checkCurrentUserPermission(CoreFeature.ImpersonateAllUsers.toString()) ||
             this.permissionService.checkCurrentUserPermission(CoreFeature.ImpersonateNonAdmins.toString()) || isSubsOnly) {
                this.userMenuItems.unshift(this.aliasMenuItem);
                const getAliasUsers = isSubsOnly ?
                    this.sellerService.getSubordinatesWithCurrentHistory(this.sellerId, false) :
                    this.sellerService.getSellersShallow(false);

                forkJoin([
                    getAliasUsers,
                    this.sellerService.getSellerNames()
                ]).subscribe(([sellers, names]) => {
                    this.aliasUsers = sellers.filter(x => x.loginName && x.id !== this.sellerId).sort((a, b) => a.name.localeCompare(b.name));
                    this.aliasUsers.forEach(x => Object.assign(x, names.find(y => y.id === x.id)));
                    this.aliasDropdownColumns.push(...this.helperService.getAlternateImportNameColumns(this.aliasUsers));
                    this.isAliasGridDataLoaded = true;
                });
            };

            this.periodService.getPeriodEvents().subscribe(() => {
                this.periodService.setStorageDates(seller.id, seller?.subordinates?.map(x => x?.id), this.username, this.hasAdminView).then(() => {
                    this.getDates();
                });
            });

            // TODO: replace admin check with this.permissionService.checkCurrentUserPermission(CoreFeature.AccessHelp.toString()) and move below once user_id groups are re-evaluated -JH
            if (seller.userGroupId === EnumUserGroup.Administrator || seller.userGroupId === EnumUserGroup.Implementer) {
                this.isLogViewPopupEnabled = true;

                this.userMenuItems.splice(1, 0,
                    {
                        text: 'Freshdesk',
                        icon: 'tips',
                        onClick: () => {
                            window.open('https://corecommissions.freshdesk.com/support/home', '_blank');
                        }
                    }
                );
                this.userMenuItems.splice(1, 0,
                    {
                        text: 'Tickets',
                        icon:'help',
                        onClick: () => {
                            this.showFreshdeskModule();
                        }
                    }
                );
                this.settingService.getStringSetting(EnumSettingClassId.DropboxLinkURL).subscribe(res => {
                    if (!this.helperService.isNullOrEmpty(res)) {
                        this.userMenuItems.splice(1, 0,
                            {
                                text: 'Dropbox',
                                icon: 'box',
                                onClick: () => {
                                    window.open(res, '_blank');
                                }
                            }
                        );
                    }
                });
            }

            this.populateFreshdeskFields();
            this.hideFreshdeskModuleOnDemand();

            this.updateHelpPath(this.router.url);

            // TODO: Remove once we have a better solution for helpDesk articles
            if (this.authService.getUserFromToken().toLocaleLowerCase().includes('accessone')) {
                this.userMenuItems.splice(1, 0, {
                    text: 'Help & Support',
                    icon: 'overflow',
                    onClick: () => {
                        window.open('https://accessoneinc.atlassian.net/l/c/4bfkXmRe', '_blank');
                    }
                });
            }
        });

        this.userMenuItems.push({
            text: 'Version ' + this.appInfo.appVersion,
            icon: 'indeterminatestate',
            disabled: true,
            onClick: () => { }
        });

        const poll = new BehaviorSubject(new PollValue<boolean>(false));
        poll.subscribe(lastValue => {
            const waitTime: number = lastValue.value ? 6000 : 15000;

            setTimeout(() => {
                if (!this.pageDestroyed) {
                    try {
                        const timeRemaining = this.authService.getTokenExpirationDate(this.authService.getToken()).valueOf() - Date.now().valueOf();
                        if ((timeRemaining / this.msInMinute) < 5) {
                            this.isInactivePopupVisible = true;
                        }
                        if (document.hasFocus()) {
                            this.processingService.getProcessingInProgress().subscribe(inProgress => {
                                this.isProcessingInProgress = inProgress;
                                poll.next(new PollValue(inProgress));
                            });
                        } else {
                            poll.next(new PollValue(true));
                        }
                    } catch {
                        poll.next(new PollValue(false));
                    }
                } else {
                    poll.unsubscribe();
                }
            }, lastValue.getTimeout(waitTime));
        });

        this.setReportParametersEnabled(this.router.url);
        this.router.events.subscribe(val => {
            if (val instanceof NavigationEnd) {
                this.setReportParametersEnabled(val.urlAfterRedirects);
                this.updateHelpPath(this.router.url);
            }
        });
    }

    ngAfterViewInit() {
        this.appElementService.provideHeaderElement(this.headerElement.nativeElement);
    }

    ngOnDestroy() {
        this.pageDestroyed = true;
    }

    updateHelpPath(routerUrl: string) {
        if (routerUrl.startsWith('/account-management')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/Accounts.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/pages/analytic') && this.hasAdminView) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/AnalyticsAdmin.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/pages/analytic')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/AnalyticsEverybody.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/building-blocks')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/RuleEditor.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/email')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/Email.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/etl')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/ETL.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/fields')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/Fields.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/plans')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/PlansRules.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/process-log-viewer')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/Logs.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/processing')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/Processing.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/tag-groups')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/TagGroups.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/setup-rules')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/SetupRules.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/pages/report') && this.hasAdminView) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/ReportsAdmin.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/pages/report')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/ReportsEverybody.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/pages/dashboard') && this.hasAdminView) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/DashboardsAdmin.pdf#toolbar=0');
        }
        else if (routerUrl.startsWith('/pages/dashboard')) {
            this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/DashboardsEverybody.pdf#toolbar=0');
        }
        else {
            if (this.hasAdminView) {
                this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/HomepageHelpAdmin.pdf#toolbar=0');
            }
            else {
                this.explainDocPath = this.sanitizer.bypassSecurityTrustResourceUrl('../../../../assets/documents/HomepageHelpBasic.pdf#toolbar=0');
            }
        }
    }

    toggleMenu = () => {
        this.menuToggle.emit();
    };

    onSettingsButtonClick(e) {
        this.userMenuComponent.toggleShowMenu();
    }

    showPicker() {
        this.pickerPopupVisible = true;
    }

    showHelp() {
        this.helpPopupVisible = true;
    }

    showAgreement() {
        this.agreementPopupVisible = true;
    }

    generateClicked() {
        this.getDates();
        this.parameterSelectionChanged.emit();
    }

    openLogViewPopup(): void {
        if (this.isLogViewPopupEnabled) {
            this.headerPopupSteps = [];
            this.processLogData = [];

            const logStep = new CorePopupStep(null, null, null, null, 'Close');
            this.headerPopupSteps.push(logStep);
            this.headerPopupProps.visible = true;
            logStep.enableDataGrid(this.processLogData, this.processLogColumns, this.processLogGridProps);

            this.processLogService.getActiveProcessLogsByTransactionIdLastId(null).subscribe(logs => {
                [].concat(...logs.results).forEach(log => {
                    this.processLogData.push(log);
                });

                const poll = new BehaviorSubject(new PollValue<boolean>(false));
                poll.subscribe(lastValue => {
                    setTimeout(() => {
                        if (!this.pageDestroyed && this.headerPopupProps.visible) {
                            try {
                                this.processLogService.getActiveProcessLogsByTransactionIdLastId(this.createTransactionLogRequest()).subscribe(newLogs => {
                                    [].concat(...newLogs.results).forEach(newLog => {
                                        this.processLogData.push(newLog);
                                    });

                                    poll.next(new PollValue(true));
                                });
                            } catch {
                                poll.next(new PollValue(false));
                            }
                        } else {
                            poll.unsubscribe();
                        }
                    }, lastValue.getTimeout(3000));
                });
            });
        }
    }

    createTransactionLogRequest(): ProcessLogIdTransactionId[] {
        const maxTransactionlog: { [transactionId: string]: number } = {};
        const results: ProcessLogIdTransactionId[] = [];
        for (const i in this.processLogData) {
            if (maxTransactionlog[this.processLogData[i].transactionId] === null || maxTransactionlog[this.processLogData[i].transactionId] === undefined) {
                maxTransactionlog[this.processLogData[i].transactionId] = this.processLogData[i].processLogId;
            } else if (maxTransactionlog[this.processLogData[i].transactionId] < this.processLogData[i].processLogId) {
                maxTransactionlog[this.processLogData[i].transactionId] = this.processLogData[i].processLogId;
            }
        }

        Object.keys(maxTransactionlog).forEach(transactionId => {
            results.push({ lastProcessLogId: maxTransactionlog[transactionId], transactionId });
        });

        return results;
    }

    getDates() {
        if (localStorage.getItem('beginDate')) {
            this.beginDate = new Date(localStorage.getItem('beginDate'));
            this.endDate = new Date(localStorage.getItem('endDate'));
            this.hasDates = true;
        } else {
            this.hasDates = false;
        }
    }

    onResize(event: any) {
        this.appElementService.provideHeaderElement(this.headerElement.nativeElement);
    }

    onLogout(): void {
        this.hideFreshdeskPopup();
        if (this.unsavedChanges) {
            this.awaitingLogout = true;
            this.router.navigate(['/login-form']);
        } else {
            this.authService.logOut();
        }
    }

    onCoreBotClicked(e: any): void {
        this.interactionService.getAnimationState().pipe(first()).subscribe(res => {
            if (res === true){
                this.interactionService.getCurrentCoreBotInteraction().pipe(first()).subscribe(interaction => {
                    this.botPrompt = interaction.botPrompt;
                    this.chatBotComponent.message = interaction.botPrompt;
                    this.chatBotComponent.onModelChange(this.botPrompt);
                    this.chatBotWidget.onCloseButtonClicked(e);
                });
                this.isInteractionClicked = true;
            }
            else {
                this.isInteractionClicked = false;
            }
            this.showCoreBotPopup = true;
        });
    }

    onPopupStateChanged(e: any){
        this.showCoreBotPopup = false;
    }

    setReportParametersEnabled(currentRoute: string) {
        this.isReportParametersEnabled = this.reportParametersEnabledRoutes.some(route => currentRoute.startsWith(route));
    }

    onAliasUserSelected(value) {
        this.aliasSelectedId = value;
    }

    aliasUser = () => {
        this.hideFreshdeskPopup();
        this.sellerService.alias(this.aliasSelectedId).subscribe(token => {
            if (token === null) {
                this.toast.error('Unable to alias this user');
            } else {
                localStorage.setItem(`${this.username}.selectedSellers`, '[' + this.aliasSelectedId + ']');
                this.authService.logOut();
                this.authService.setToken(token);
                this.authService.setLocalStorageAfterLogin();
                setTimeout(() => this.router.navigate(['/home']), 1000);
            }
        });
    };

    refreshUserToken = () => {
        this.isInactivePopupVisible = false;
        this.sellerService.refreshUserToken().subscribe(newToken => {
            if (newToken === null) {
                this.toast.error('Unable to keep you logged in');
            } else {
                this.authService.setToken(newToken);
            }
        });
    };

    populateFreshdeskFields() {
        this.sellerService.getMe().subscribe(seller => {
            const body = document.getElementsByTagName('body')[0];
            const script1 = document.createElement('script');
            script1.async = true;
            script1.innerHTML = 'FreshworksWidget(\'identify\', \'ticketForm\', {name: \'' + seller.name + '\',email: \'' + seller.emailAddress + '\',' +
             'subject: \'Core Commissions Issue (' + window.location.href + ') \',' +
             'description: \'Provide as much detail as possible when describing your issue. The more detail provided, the quicker we can address the problem.\'});';
            body.appendChild(script1);
            body.removeChild(script1);
        });
    }

    showFreshdeskModule() {
        const body = document.getElementsByTagName('body')[0];
        const script1 = document.createElement('script');
        this.populateFreshdeskFields();
        script1.async = true;
        script1.innerHTML = 'FreshworksWidget(\'open\');';
        body.appendChild(script1);
    }

    hideFreshdeskModuleOnDemand() {
        const body = document.getElementsByTagName('body')[0];
        const script2 = document.createElement('script');
        script2.innerHTML = 'FreshworksWidget(\'hide\', \'launcher\');';
        body.appendChild(script2);
    }

    hideFreshdeskPopup() {
        const body = document.getElementsByTagName('body')[0];
        const script2 = document.createElement('script');
        script2.innerHTML = 'FreshworksWidget(\'hide\');';
        body.appendChild(script2);
    }

    linkToTrello() {
        this.trelloService.getTrelloUrl().subscribe(board => {
            window.open(board, '_blank', 'noreferrer');
        });
    }

    checkEmailSettings = () => {
        forkJoin([
            this.settingService.getStringSetting(settingClassIds.EmailSmtpServerHost),
            this.settingService.getBoolSetting(EnumSettingClassId.EmailAutomateTestEnabled)
        ]).subscribe(([emailHost, emailAutoTestEnabled]) => {
            if (!this.helperService.isNullOrEmpty(emailHost) && emailAutoTestEnabled) {
                this.testEmailStatus();
            }
        });
    };

    testEmailStatus = () => {
        this.fieldService.getSingletonsByClassId(singletonClassIds.EmailTest).subscribe(singletons => {
            this.emailTest = singletons[0];
            const weekInMs = 7 * 24 * 60 * 60 * 1000;
            const isTestStale = !this.emailTest || new Date(Date.parse(this.emailTest.value) + weekInMs) < new Date();

            if (isTestStale) {
                this.emailService.testEmailSetup().subscribe(result => {
                    const didTestPass = result.startsWith('Test email sent');
                    this.emailTest ??= new Singleton();
                    this.emailTest.singletonClassId = singletonClassIds.EmailTest;
                    this.emailTest.value = this.helperService.getDateString(new Date());
                    this.emailTest.format = didTestPass ? EmailTestResult.Pass : EmailTestResult.Fail;

                    if (this.emailTest.id) {
                        this.fieldService.updateSingleton(this.emailTest).subscribe();
                    } else {
                        this.fieldService.insertSingleton(this.emailTest).subscribe();
                    }

                    this.isEmailIssueIndicatorVisible = this.emailTest.format === EmailTestResult.Fail;
                });
            } else {
                this.isEmailIssueIndicatorVisible = this.emailTest.format === EmailTestResult.Fail;
            }
        });
    };

    openEmailIssuePopup = () => {
        this.isEmailIssuePopupVisible = true;
    };

    goToEmail = () => {
        this.router.navigate(['email']);
        this.isEmailIssuePopupVisible = false;
    };

    dismissEmailIssueIndicator = () => {
        this.emailTest.format = EmailTestResult.Dismissed;
        this.fieldService.updateSingleton(this.emailTest).subscribe(() => {
            this.isEmailIssueIndicatorVisible = false;
            this.isEmailIssuePopupVisible = false;
        });
    };

    setModeMenuItemContent(): void {
        if (!this.isDarkMode) {
            this.switchModeMenuItem.text = 'Switch to dark mode';
            this.switchModeMenuItem.icon = 'isblank';
        } else {
            this.switchModeMenuItem.text = 'Switch to light mode';
            this.switchModeMenuItem.icon = 'isnotblank';
        }
    }

    toggleLightDarkMode(): void {
        this.isDarkMode = !this.isDarkMode;
        this.siteThemeService.setIsDarkMode(this.isDarkMode);
        const siteThemeConfig = this.siteThemeService.getSiteThemeConfig(this.isBITheme);
        this.siteThemeService.applySiteTheme(document, siteThemeConfig);
        this.setModeMenuItemContent();
        this.appElementService.provideDarkModeState(this.isDarkMode);
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxButtonModule,
        UserPanelModule,
        DxToolbarModule,
        DxButtonGroupModule, DxTreeListModule, DxSelectBoxModule, DxTextBoxModule, DxPopupModule, DxScrollViewModule, DxBoxModule, DxDataGridModule,
        DxContextMenuModule, DxTextAreaModule,
        DxSwitchModule,
        CoreComponentModule,
        ChatBotChatModule,
        CoreBotWidgetModule
    ],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class HeaderModule { }

import { PopupArguments } from './popup-arguments';
import { AgGridServerSideRowsRequest } from './ag-grid-server-side-rows-request';
import { RDVRequestsEnum } from '../constants/enums';

export class GetFlatXactionForProcessing {
    processingDataViewerFunction: number;
    periodIds: number[];
    seriesId: number;
    datasourceIds: number[];
    planId: string;
    ruleIds: string[];
    visibleColumns: string[];
    isInit: boolean;
    hasRowContentChanged: boolean;
    hasNumRowsChanged: boolean;
    hasFilterChanged: boolean;
    request: AgGridServerSideRowsRequest;

    constructor(processingDataViewerFunction: number, popupArgs: PopupArguments,
        isInit: boolean = false, request: AgGridServerSideRowsRequest = null, requestTypes: Array<RDVRequestsEnum> = null) {
        this.processingDataViewerFunction = processingDataViewerFunction;
        this.periodIds = popupArgs.periodIds;
        this.seriesId = popupArgs.seriesId;
        this.datasourceIds = popupArgs.datasourceIds;
        this.planId = popupArgs.planIds && popupArgs.planIds[0] ? popupArgs.planIds[0] : '';
        this.ruleIds = popupArgs.ruleIds;
        this.visibleColumns = [];
        this.isInit = isInit;
        this.request = request;
        if(!requestTypes){
            this.hasRowContentChanged = true;
            this.hasNumRowsChanged = true;
            this.hasFilterChanged = true;
        } else {
            this.hasRowContentChanged = requestTypes.includes(RDVRequestsEnum.ROWS);
            this.hasNumRowsChanged = requestTypes.includes(RDVRequestsEnum.NUMROWS);
            this.hasFilterChanged = requestTypes.includes(RDVRequestsEnum.FILTER);
        }
    }
}

import { ColumnType } from './core-column-type';

export class DevExpressColumn {
    dataField: string;
    columnHeader: string;
    isEditable: boolean;
    isCurrency: boolean;
    isRequired: boolean;
    isVisible: boolean;
    format: string;
    cssClass: string;
    // dataType values: 'string', 'number', 'date', 'boolean', 'object', 'datetime'
    dataType: string;
    lookupData: any[];
    lookupDisplayValue: string;
    lookupValue: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    calculation: Function;
    width: string;
    minWidth: any;
    columnType: ColumnType;
    isSortingAllowed: boolean = true;
    isFilteringAllowed: boolean = true;
    isShownInColumnChooser: boolean = false;
    trueText: string;
    falseText: string;
    allowReordering: boolean = true;
    allowHiding: boolean = true;

    constructor(dataField: string, columnHeader: string, isVisible: boolean, dataType?: string, columnType?: ColumnType, trueText?: string, falseText?: string) {
        this.dataField = dataField;
        this.columnHeader = columnHeader;
        this.isVisible = isVisible;
        this.dataType = dataType ? dataType : 'string';
        this.columnType = columnType;
        this.trueText = trueText ? trueText : 'true';
        this.falseText = falseText ? falseText : 'false';
    }
}

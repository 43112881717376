import { HelperService } from '../services/helper.service';

export class Period {
    id: number;
    beginDate: Date;
    endDate: Date;
    workflowDirty: boolean;
    recurrenceId: number;
    isLocked: boolean;
    isDeleted: boolean;
    igDirty: boolean;
    isPublished: boolean;
    attributes: string;
    sellerString: string;

    createFromPublishPeriod(publishedPeriod: PublishPeriod): Period {
        this.id = publishedPeriod.id;
        this.beginDate = publishedPeriod.beginDate;
        this.endDate = publishedPeriod.endDate;
        this.workflowDirty = publishedPeriod.workflowDirty;
        this.recurrenceId = publishedPeriod.recurrenceId;
        this.isLocked = publishedPeriod.isLocked;
        this.isDeleted = publishedPeriod.isDeleted;
        this.igDirty = publishedPeriod.igDirty;
        this.isPublished = publishedPeriod.isPublished;
        this.attributes = publishedPeriod.attributes.length > 0 ? '' : null;
        this.sellerString = publishedPeriod.sellerString;
        publishedPeriod.attributes.slice(1, publishedPeriod.attributes.length - 1).split('][').map(x => parseInt(x, 10)).sort((a , b) => a > b ? 1 : (a < b ? -1 : 0)).forEach(x => {
            this.attributes = this.attributes + '[' + x + ']';
        });

        return this;
    }
}

export class PublishPeriod extends Period {
    dateRange: string;

    constructor(period: Period) {
        super();
        const helperService = new HelperService();
        this.id = period.id;
        this.beginDate = period.beginDate;
        this.endDate = period.endDate;
        this.workflowDirty = period.workflowDirty;
        this.recurrenceId = period.recurrenceId;
        this.isLocked = period.isLocked;
        this.isDeleted = period.isDeleted;
        this.igDirty = period.igDirty;
        this.isPublished = period.isPublished;
        this.attributes = period.attributes;
        this.dateRange = period['dateRange'];
    }
}

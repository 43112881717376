import { CoreLongBase } from './core-long-base';
import { AttributeClass } from './attribute-class';
import { FunctionAttribute } from './function-attribute';
import { HistSellerAttribute } from './hist-seller-attribute';
import { AccountFactor } from './account-factor';

export class Attribute extends CoreLongBase {
    attributeClassId: number;
    name: string;
    orderIndex: number;
    isSystem: boolean;
    attributeClass: AttributeClass;
    functionAttribute: FunctionAttribute[] = [];
    histSellerAttribute: HistSellerAttribute[] = [];
    // jobTitleRedir: JobTitleRedir[] = [];
    accountFactors: AccountFactor[] = [];

    // Constructor for text attributes
    public static CreateNewTextAttribute(attributeClassId: number, text: string): Attribute {
        const newAttribute = new Attribute();
        newAttribute.attributeClassId = attributeClassId;
        newAttribute.name = text;
        newAttribute.orderIndex = 0;
        newAttribute.isSystem = false;

        return newAttribute;
    }
}

<div style="height: 100%;" class="resizable-area" (window:resize)="onWindowResize()">
    <dx-resizable
        [elementAttr]="{ class: 'resizable-panel' }"
        [(height)]="mainPanelHeight"
        handles="none">
        <ag-grid-angular
            (gridReady)="onGridReady($event)"
            class="main-grid"
            [ngClass]="gridTheme"
            rowModelType="serverSide"
            [cacheBlockSize]="rapidLoadMode ? 100 : 500000"
            serverSideInfiniteScroll="true"
            [getRowId]="getRowId"
            [columnDefs]="columns"
            [defaultColDef]="defaultColDef"
            [rowGroupPanelShow]="rowGroupPanelShow"
            [suppressDragLeaveHidesColumns]="true"
            [suppressMakeColumnVisibleAfterUnGroup]="false"
            [suppressRowGroupHidesColumns]="false"
            [suppressCellFocus]="true"
            [suppressAggFuncInHeader]="true"
            [suppressColumnVirtualisation]="true"
            [columnTypes]="columnTypes"
            [sideBar]="sideBar"
            groupDisplayType="multipleColumns"
            [autoGroupColumnDef]="autoGroupColumnDef"
            [animateRows]="true"
            [statusBar]="statusBar"
            [enableRangeSelection]="enableRangeSelection"
            [processCellForClipboard]="processCellForClipboard"
            [rowSelection]="rowSelection"
            [enableCharts]="true"
            [components]="frameworkComponents"
            [rowClassRules]="rowClassRules"
            [getRowStyle]="getRowStyle"
            [loadingOverlayComponent]="loadingOverlayComponent"
            [loadingOverlayComponentParams]="loadingOverlayComponentParams"
            [copyHeadersToClipboard]="true"
            [getMainMenuItems]="getMainMenuItems"
            [context]="this"
            [maintainColumnOrder]="true"
            (firstDataRendered)="onFirstDataRendered($event)"
            (filterChanged)="onFilterChanged($event)"
            (filterOpened)="onFilterOpened($event)"
            (cellEditingStarted)="mainGridEditingStarted()"
            [getChildCount]="getChildCount"
            [stopEditingWhenCellsLoseFocus]="true"
            (gridColumnsChanged)="gridColumnsChanged($event)"
            (selectionChanged)="selectionChanged($event)"
            (columnVisible)="columnVisible($event)"
            (columnPinned)="alignGridColumns()"
            (columnResized)="alignGridColumns()"
            (columnMoved)="alignGridColumns()"
            (columnRowGroupChanged)="columnRowGroupChanged($event)"
            (columnPivotModeChanged)="pivotModeChanged($event)"
            (rowGroupOpened)="rowGroupOpened($event)">
        </ag-grid-angular>
    </dx-resizable>
    <dx-resizable
        [elementAttr]="{ class: 'resizable-panel bottom-resizable-panel' }"
        [(height)]="bottomPanelHeight"
        [minHeight]="(insertGridVisible ? bottomPanelMinHeight : 0)"
        [maxHeight]="(insertGridVisible ? getPanelHeightTotal() - mainPanelMinHeight : 0)"
        [handles]="insertGridVisible ? 'top' : 'none'"
        (onResize)="onResizeBottomPanel($event)">
        <ag-grid-angular
            class="insert-grid"
            [ngClass]="gridTheme"
            [rowData]="insertRecords"
            rowModelType="clientSide"
            [columnDefs]="insertGridColumns"
            [defaultColDef]="defaultColDef"
            [columnTypes]="columnTypes"
            [animateRows]="true"
            [rowClassRules]="rowClassRules"
            [sideBar]="insertGridSideBar"
            [rowSelection]="rowSelection"
            [components]="frameworkComponents"
            [stopEditingWhenCellsLoseFocus]="true"
            (gridReady)="onInsertGridReady($event)"
            (selectionChanged)="selectionChanged($event, true)"
            (cellEditingStarted)="insertGridEditingStarted()">
        </ag-grid-angular>
    </dx-resizable>
</div>

<dx-popup
    [width]="400"
    [height]="300"
    [showTitle]="true"
    title="Bulk Edit"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="bulkEditPopupVisible"
    [shading]="false">
    <div>
        <dx-select-box
            id="bulk-edit-select-field"
            [(value)]="bulkEditFieldUnmapped"
            (onValueChanged)="bulkEditFieldSelected($event)"
            (onFocusIn)="onBulkEditFieldFocusIn($event)"
            [searchEnabled]="true"
            [items]="columnValues"
            stylingMode="underlined">
        </dx-select-box>
        <form (submit)="submitBulkEdit($event)">
            <dx-form validationGroup="bulkEditData">
                <dxi-item
                    *ngIf="bulkEditEditorType !== null"
                    dataField="New Value"
                    [editorType]="bulkEditEditorType"
                    [editorOptions]="bulkEditEditorOptions">
                    <!--Adding empty validation rule, actually this rule has no requirements. I am adding this just because we need at least one rule for form validation to work.-->
                    <dxi-validation-rule type="stringLength" ignoreEmptyValue="true" min="0"></dxi-validation-rule>
                </dxi-item>
                <dxi-item
                    itemType="button"
                    horizontalAlignment="left"
                    [disabled]="!$isGridEditable.value"
                    [buttonOptions]="bulkEditSubmitButtonOptions">
                </dxi-item>
            </dx-form>
        </form>
    </div>
</dx-popup>

<dx-popup
    [width]="400"
    [height]="220"
    [showTitle]="true"
    title="Period Range Selector"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="periodFilterVisible"
    [shading]="false"
    [resizeEnabled]="false">
    <div id="period-range-popup">
        <h4 class="no-margin">Period:</h4>
        <dx-select-box
            width="175"
            class="center"
            text="Period:"
            [(value)]="startPeriodRange"
            [displayExpr]="periodFilterDisplayExpr"
            (onValueChanged)="onPeriodRangeFilterStartChanged($event)"
            [searchEnabled]="true"
            [items]="periodRangeOptions"
            stylingMode="underlined">
        </dx-select-box>
        <h4 class="no-margin">to</h4>
        <dx-select-box
            width="175"
            class="center"
            text="to"
            [(value)]="endPeriodRange"
            [displayExpr]="periodFilterDisplayExpr"
            (onValueChanged)="onPeriodRangeFilterEndChanged($event)"
            [searchEnabled]="true"
            [items]="periodRangeOptions"
            stylingMode="underlined">
        </dx-select-box>
        <div style="width: 100px; margin-top: 10px" class="center">
            <dx-button width="100" (onClick)="submitPeriodRangeFilter()" text="submit"></dx-button>
        </div>
    </div>
</dx-popup>

<dx-popup
    width="1000"
    height="600"
    [showTitle]="true"
    title="Trace Output"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="traceOutputVisible"
    [shading]="false"
    [resizeEnabled]="true">
    <app-trace-output [calcSetId]="traceOutputId"></app-trace-output>
</dx-popup>

<dx-popup
    [width]="330"
    [height]="160"
    [showTitle]="true"
    title="Choose Export Type"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="exportOutputVisible"
    [shading]="false"
    [resizeEnabled]="false">
    <div>
        <dx-select-box
            #exportSelectBox
            width="175"
            class="center"
            [value]="'csv'"
            text="to"
            displayExpr="name"
            valueExpr="id"
            [items]="exportItems"
            stylingMode="underlined">
        </dx-select-box>
        <div style="margin-top: 10px;" class="center">
            <i *ngIf="exportWarningVisible">CSV is preferred when exporting over 50,000 rows.</i>
        </div>
        <div style="width: 100px; margin-top: 10px; position: absolute; bottom: 10px; left: 115px;" class="center">
            <dx-button width="100" (onClick)="exportAll(exportSelectBox.value)" text="Export"></dx-button>
        </div>
    </div>
</dx-popup>

<dx-popup
    [width]="330"
    [height]="210"
    [showTitle]="true"
    title="Choose Rule to Audit"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="auditPopupVisible"
    [shading]="false"
    [resizeEnabled]="false">
    <div>
        <h6 class="no-margin">Rule:</h6>
        <dx-select-box
            #ruleSelectBox
            width="175"
            class="center"
            [value]=""
            displayExpr="name"
            valueExpr="id"
            [items]="auditableRules"
            stylingMode="underlined"
            [useItemTextAsTitle]="true">
        </dx-select-box>
        <h6 class="no-margin">Period:</h6>
        <dx-select-box
            #periodSelectBox
            width="175"
            class="center"
            [(value)]="auditPeriodValue"
            displayExpr="name"
            valueExpr="id"
            [items]="periodRangeLabels"
            stylingMode="underlined"
            [useItemTextAsTitle]="true">
        </dx-select-box>
        <div style="width: 100px; margin-top: 10px; position: absolute; bottom: 10px; left: 115px;" class="center">
            <dx-button width="100" (onClick)="openDiagramAudit(ruleSelectBox.value, periodSelectBox.value)" text="Audit"></dx-button>
        </div>
    </div>
</dx-popup>

<dx-popup
    [width]="280"
    [height]="180"
    [title]="validationErrorPopupHeader"
    [dragEnabled]="false"
    [hideOnOutsideClick]="false"
    [showCloseButton]="false"
    [(visible)]="validationErrorPopupVisible"
    [shading]="true"
    [resizeEnabled]="false">
    <div>
        <span>{{validationErrorPopupMessage}}</span>
        <div style="width: 100px; margin-top: 10px; position: absolute; bottom: 10px; left: 86px;" class="center">
            <dx-button width="100" (onClick)="validationErrorPopupVisible = false" text="OK"></dx-button>
        </div>
    </div>
</dx-popup>

<!--This hides all window content, avoids displaying incorrect theme for a split second-->
<div *ngIf="showLoadingThemeOverlay" class="loading-theme-overlay"></div>

export class CoreDataGridChange<T> {
    type: 'insert' | 'update' | 'remove';
    key: any;
    data: Partial<T>;

    constructor(type: 'insert' | 'update' | 'remove', key: any, data: any) {
        this.type = type;
        this.key = key;
        this.data = data;
    }
}

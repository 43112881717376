import { Component, OnInit, Input, EventEmitter, Output, NgModule } from '@angular/core';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { CoreFeature, settingClassIds, integrationClassIds, singletonClassIds, EmailTestResult } from 'src/app/shared/constants/enums';
import { SettingService } from 'src/app/shared/services/setting.service';
import { IdLongValueString } from 'src/app/shared/models/id-long-value-string';
import { CommonModule } from '@angular/common';
import { DxButtonModule, DxFormModule, DxNumberBoxModule, DxPopupModule, DxScrollViewModule } from 'devextreme-angular';
import { EmailService } from '../../services/email.service';
import { alert } from 'devextreme/ui/dialog';
import { OauthService } from '../../services/oauth.service';
import { IntegrationClassService } from '../../services/integration-class.service';
import { AppElementsService } from '../../services/app-element.service';
import { FieldService } from '../../services/field.service';
import { forkJoin } from 'rxjs';
import { HelperService } from '../../services/helper.service';

@Component({
    selector: 'app-email-settings',
    templateUrl: './email-settings.component.html',
    styleUrls: ['./email-settings.component.scss']
})

export class EmailSettingsComponent implements OnInit {
    @Input() isPopupVisible!: boolean;
    @Output() isPopupVisibleChange = new EventEmitter<boolean>();
    isSettingsEditingRestricted: boolean = true;
    emailSettingClassIds: string[];
    emailSettingsForm: any = {};

    constructor(private permissionService: PermissionService,
        private settingService: SettingService,
        private emailService: EmailService,
        private oauthService: OauthService,
        private integrationService: IntegrationClassService,
        private fieldService: FieldService,
        private helperService: HelperService,
        private appElementsService: AppElementsService) {
    }

    ngOnInit() {
        this.isSettingsEditingRestricted = !this.permissionService.checkCurrentUserPermission(CoreFeature.EditSystemSettings.toString());
    }

    getEmailSettings() {
        this.settingService.getEmailSettings().subscribe(emailSettingsDict => {
            this.emailSettingClassIds = Object.keys(emailSettingsDict);
            this.emailSettingClassIds.forEach(key => {
                let formValue: any = emailSettingsDict[key];
                formValue = formValue === 'True' ? true : formValue === 'False' ? false : formValue;
                this.emailSettingsForm[this.getSimpleEmailSettingNameFromClassId(+key)] = formValue;
            });
        });
    }

    getSimpleEmailSettingNameFromClassId(classId: number) {
        const name = settingClassIds[classId].toString().replace(/Email|SmtpServer|SmtpSender/g, '');
        return name.charAt(0).toLowerCase() + name.slice(1);
    }

    closeEmailSettings() {
        this.isPopupVisible = false;
        this.settingService.getBoolSetting(settingClassIds.MicrosoftOAuthClient).subscribe(enabled => {
            if (this.emailSettingsForm.host === 'smtp.office365.com' && enabled === true){
                this.integrationService.getIntegrationClassById(integrationClassIds.MicrosoftEmail).subscribe(intClass => {
                    this.oauthService.getRedirectUrl('Microsoft', 'production').subscribe(result => {
                        localStorage.setItem('integrationEnvironment', 'production');
                        localStorage.setItem('activeIntegration', JSON.stringify(intClass));
                        window.location.href = result;
                    });
                });
            }
        });
    }

    onHidden() {
        this.isPopupVisibleChange.emit();
    }

    getSettingsFromForm(): IdLongValueString[] {
        return this.emailSettingClassIds.map(id => {
            let value = this.emailSettingsForm[this.getSimpleEmailSettingNameFromClassId(+id)];
            value = value === true ? 'True' : value === false ? 'False' : value.toString();
            return new IdLongValueString(+id, value);
        });
    }

    updateEmailSettings() {
        this.settingService.updateSettingsByClassIdBulk(this.getSettingsFromForm()).subscribe();
        this.closeEmailSettings();
    }

    testEmail() {
        this.settingService.updateSettingsByClassIdBulk(this.getSettingsFromForm()).subscribe(updated => {
            forkJoin([
                this.emailService.testEmailSetup(),
                this.fieldService.getSingletonsByClassId(singletonClassIds.EmailTest),
            ]).subscribe(([result, priorTests]: any[]) => {
                const didTestPass = result.startsWith('Test email sent');
                const priorTest = priorTests[0];
                if (didTestPass && priorTest?.format === EmailTestResult.Fail) {
                    priorTest.format = EmailTestResult.Pass;
                    priorTest.value = this.helperService.getDateString(new Date());
                    this.appElementsService.headerComponent.emailTest = priorTest;
                    this.appElementsService.headerComponent.isEmailIssueIndicatorVisible = false;
                    this.fieldService.updateSingleton(priorTest).subscribe();
                }
                alert(result, 'Email Setup');
            });
        });
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxPopupModule,
        DxScrollViewModule,
        DxFormModule,
        DxNumberBoxModule,
        DxButtonModule,
    ],
    declarations: [
        EmailSettingsComponent
    ],
    exports: [
        EmailSettingsComponent
    ]
})
export class EmailSettingsModule { }

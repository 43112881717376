import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RetrievessoRoutingModule } from './retrievesso-routing.module';
import { RetrievessoComponent } from './retrievesso.component';
import { SingleCardModule } from '../../layouts/single-card/single-card.component';

@NgModule({
    imports: [
        CommonModule,
        RetrievessoRoutingModule,
        SingleCardModule
    ],
    declarations: [RetrievessoComponent],
    exports: [RetrievessoComponent]
})
export class RetrievessoModule { }

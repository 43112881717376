import { OldStringNewString } from './old-string-new-string';

export class ColumnHeaderValidation {
    unusedHeaders: string[];
    unmappedHeaders: string[];
    headerMappings: OldStringNewString[];

    constructor() {
        this.unusedHeaders = null;
        this.unmappedHeaders = null;
        this.headerMappings = null;
    }
}

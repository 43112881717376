import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import dxNumberBox from 'devextreme/ui/number_box';
import { off } from 'devextreme/events';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor() { }
    ngOnInit() {
        dxNumberBox.defaultOptions({
            options: {
                onInitialized: (e) => {
                    setTimeout(() => {
                        // Disable mouse wheel events in number boxes site-wide so that the value won't change when user scrolls
                        off(e.element.querySelector('.dx-texteditor-input'), 'dxmousewheel');
                    });
                }
            }
        });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Attribute } from '../models/attribute';
import { CoreResponse } from '../models/core-response';
import { AttributePermissionItem } from '../models/attribute-permission-item';

@Injectable({
    providedIn: 'root'
})

export class AccountAttributeService {
    importURL: string;
    private baseurl: string = environment.apiEndpoint;
    private attributeURL = this.baseurl + '/attribute';
    private attributeClassURL = this.baseurl + '/attributeclass';

    constructor(private http: HttpClient) {
        this.importURL = `${this.attributeURL}/import`;
    }

    getAllAttributes(): Observable<Attribute[]> {
        return this.http.get<Attribute[]>(this.attributeURL);
    }

    getAttributePermissionItems(attributeId: number): Observable<AttributePermissionItem[]> {
        return this.http.get<AttributePermissionItem[]>(this.attributeURL + '/permissionitems?attributeId=' + attributeId.toString());
    }

    insertAttribute(attribute: Attribute): Observable<Attribute> {
        return this.http.post<Attribute>(this.attributeURL, attribute);
    }

    updateAttribute(attribute: Attribute): Observable<any> {
        return this.http.put<Attribute>(this.attributeURL, attribute);
    }

    updateAttributePermissionItems(attributeId: number, attributePermissionItems: AttributePermissionItem[]): Observable<number> {
        return this.http.put<number>(this.attributeURL + '/permissionitems?attributeId=' + attributeId.toString(), attributePermissionItems);
    }

    deleteAttribute(id: number): Observable<CoreResponse<any>> {
        return this.http.delete<CoreResponse<any>>(this.attributeURL + '/' + id);
    }
}

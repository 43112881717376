import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormulaBuilderTreeItem } from '../models/formula-builder-tree-item';
import { EnumFormulaBuilderDataSet } from '../constants/enums';

@Injectable()
export class FormulaBuilderService {
    private baseUrl = environment.apiEndpoint + '/formulabuilder/';

    constructor(private http: HttpClient) {
    }

    getFormulaBuilderData(dataSet: EnumFormulaBuilderDataSet, recurrenceId: number = 0,
        gearId: string = '', sourceId = '', ignoreOutOfDateRules: boolean = true): Observable<FormulaBuilderTreeItem[]> {
        recurrenceId ??= 0;
        return this.http.get<FormulaBuilderTreeItem[]>(`${this.baseUrl}?dataSet=${dataSet}&recurrenceId=${recurrenceId}
            &gearId=${gearId}&sourceId=${sourceId}&ignoreOutOfDateRules=${ignoreOutOfDateRules}`);
    }
}

import { CoreBotResponse } from '../core-bot-response';
import { ChatMessage } from './chat-message';

export class QueryBotMessage extends ChatMessage {
    customMessageData: any;

    constructor(response: CoreBotResponse, table: any){
        super();
        this.type = 'querybot-response';
        this.customMessageData = {data: table, id: response.logId};
        this.user = {
            name: 'QueryBot',
            avatar: '../../../assets/images/query-bot.png'
        };
    }
}

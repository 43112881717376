import { CoreFormFieldProperties } from './core-form-field-properties';

export class CoreDropdownPopupProperties {
    buttonType: string;
    height: number;
    width: number;
    bodyMessage: string;
    preventionWord: string;
    coreFormFieldProperties: CoreFormFieldProperties[];
    formColCount: number;
    defaultFormValues: any;
    popupTitleText: string;
    popupValidationFunction: () => string;

    setBodyMessage(buttonType: string, bodyMessage: string, height?: number, width?: number, preventionWord?: string): CoreDropdownPopupProperties {
        this.buttonType = buttonType;
        this.bodyMessage = bodyMessage;
        this.height = height;
        this.width = width;
        this.preventionWord = preventionWord;

        return this;
    }

    createWithForm(buttonType: string, coreFormFieldProperties: CoreFormFieldProperties[], formColCount?: number, height?: number, width?: number): CoreDropdownPopupProperties {
        this.buttonType = buttonType;
        this.coreFormFieldProperties = coreFormFieldProperties;
        this.formColCount = formColCount;
        this.height = height;
        this.width = width;

        return this;
    }

    addValidationFunction(parentThis: any, popupValidationFunction: () => string): CoreDropdownPopupProperties {
        if (parentThis && popupValidationFunction) {
            this.popupValidationFunction = popupValidationFunction.bind(parentThis);
        }

        return this;
    }

    addPopupTitleText(popupTitleText: string): CoreDropdownPopupProperties {
        this.popupTitleText = popupTitleText;

        return this;
    }
}

<dx-number-box
    [(value)]="value"
    [showSpinButtons]="props.showSpinButton"
    [format]="props.format"
    [readOnly]="props.readOnly"
    [disabled]="props.disabled"
    [min]="props.minValue"
    [max]="props.maxValue"
    [step]="props.step"
    stylingMode="underlined"
    (onChange)="numericOnChange($event)"
    (onValueChanged)="handleValueChange($event)">
</dx-number-box>

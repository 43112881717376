import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InitiatessoRoutingModule } from './initiatesso-routing.module';
import { InitiatessoComponent } from './initiatesso.component';
import { SingleCardModule } from '../../layouts/single-card/single-card.component';

@NgModule({
    imports: [
        CommonModule,
        InitiatessoRoutingModule,
        SingleCardModule
    ],
    declarations: [InitiatessoComponent],
    exports: [InitiatessoComponent]
})
export class InitiatessoModule { }

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerSideDataProcessingResponse } from 'src/app/shared/models/server-side-data-processing-response';

@Injectable()
export class ServerSideDataProcessingService {

    constructor(private http: HttpClient) {
    }

    getServerSideData(url: string, params: any): Observable<ServerSideDataProcessingResponse> {
        return this.http.post<ServerSideDataProcessingResponse>(url, params);
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { CoreInputEditorType } from '../../constants/dev-extreme-enums';
import { Container } from '../../models/building-blocks';
import { CoreEditorOptions } from '../../models/core-editor-options';
import { CoreFormFieldProperties } from '../../models/core-form-field-properties';
import { CorePopupProperties } from '../../models/core-popup-properties';
import { CorePopupStep } from '../../models/core-popup-step';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { SavedColumn } from '../../models/saved-column';
import { BuildingBlocksService } from '../../services/building-blocks.service';
import { HelperService } from '../../services/helper.service';
import { SavedColumnBinding } from '../../models/saved-column-binding';

@Component({
  selector: 'app-bb-saved-columns',
  providers: [ {provide: SaveableBbProperty, useExisting: BbSavedColumnsComponent }],
  templateUrl: './bb-saved-columns.component.html',
  styleUrls: ['./bb-saved-columns.component.scss']
})
export class BbSavedColumnsComponent extends SaveableBbProperty implements OnChanges {
  @Input() savedColumnBindings: SavedColumnBinding[];
  @Input() savedColumnsEditDetails: Record<string, SavedColumn> = {};
  @Input() savedColumnsAllDetails: Record<string, SavedColumn> = {};
  @Input() enabled: boolean;
  @Output() savedColumnBindingsChange: EventEmitter<any[]> = new EventEmitter<SavedColumnBinding[]>();
  @Output() savedColumnsEditDetailsChange: EventEmitter<Record<string, SavedColumn>> = new EventEmitter<Record<string, SavedColumn>>();
  @Output() savedColumnsAllDetailsChange: EventEmitter<Record<string, SavedColumn>> = new EventEmitter<Record<string, SavedColumn>>();
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  gearPropertyMappings: Record<string, any>;
  columnsDatasource: { dataSource: any[], valueExpr: string, displayExpr: string };
  reassignValue: string = '';
  popupSteps: CorePopupStep[] = [];
  popupDefaultHeight: string = '500';
  popupDefaultWidth: string = '510';
  popupProps: CorePopupProperties =
      new CorePopupProperties().createMessageOnly(
          this.popupDefaultHeight,
          this.popupDefaultWidth,
          true,
          ''
      );
  gridDatasource: any[] = [];
  isValid: boolean = true;
  constructor(private gearPropertyService: GearPropertyService,
    private bbHelper: BuildingBlockHelperService,
    private helperService: HelperService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['savedColumnBindings']){
      if(JSON.stringify(changes['savedColumnBindings'].currentValue) === JSON.stringify(changes['savedColumnBindings'].previousValue)){
        this.dataGrid.instance.cancelEditData();
        return;
      }
    }
    this.updateSavedColumns();
  }

  updateSavedColumns(): void {
    this.gearPropertyService.getMappings().subscribe(res => {
			this.gearPropertyMappings = res;
      this.columnsDatasource = this.gearPropertyMappings['savedColumns'];
      if (this.columnsDatasource?.dataSource && this.columnsDatasource.dataSource.length > 0) {
        this.columnsDatasource.dataSource = this.columnsDatasource.dataSource.sort((a, b) => a.value.localeCompare(b.value));
      }
    });

    this.gearPropertyService.updateMappings(['savedColumns']);
    this.gridDatasource = [];
    if(this.helperService.isNullOrUndefined(this.savedColumnBindings)){
      this.savedColumnBindings = [];
    } else {
      this.bbHelper.getAllSavedColumns().then(allSavedColumns => {
        this.savedColumnBindings.forEach(binding => {
          const boundSavedColumn = allSavedColumns.find(savedColumn => savedColumn.id === binding.SavedColumnId);
          this.gridDatasource.push({
            columnName: binding.LocalSystemName,
            friendlyName: boundSavedColumn?.name,
            isPayment: boundSavedColumn?.isPayment,
            format: boundSavedColumn?.format,
            savedColumnId: boundSavedColumn?.id
          });
        });
      });
    }
  }

  saveInternalData(): Promise<void> {
    const returnValue = this.dataGrid?.instance.saveEditData();
    if(this.isValid) {
      this.savedColumnBindingsChange.emit(this.gridDatasource.map(entry => {
        if(!entry.friendlyName || entry.friendlyName === ''){
          entry.friendlyName = this.columnsDatasource.dataSource.find(col => col.refName === entry.columnName).value;
        }
        return new SavedColumnBinding(entry['savedColumnId'], entry['columnName']);
      }));

      this.savedColumnsAllDetails = {};
      this.gridDatasource.forEach(entry => {
        if(!entry.friendlyName){
          entry.friendlyName = this.columnsDatasource.dataSource.find(col => col.refName === entry.columnName).value;
        }
        this.savedColumnsAllDetails[entry.columnName] = new SavedColumn(entry['savedColumnId'], entry['friendlyName'], '', entry['isPayment'], entry['format'] ?? '');
      });
      this.savedColumnsAllDetailsChange.emit(this.savedColumnsAllDetails);
    }
    return returnValue;
  }

  checkForChanges(){
    if(this.dataGrid.instance.hasEditData()){
      this.bbHelper.setGridPropertyAsUnsaved('savedColumns');
    } else {
      this.isValid = true;
      this.bbHelper.removeGridPropertyFromUnsaved('savedColumns');
    }
  }

  detectDuplicates = (e) => {
    const matchingColsFromDatasource = (this.dataGrid.dataSource as any[]).filter(col => col.columnName === e.value).length;
    const matchingColsFromEdits = this.dataGrid.editing.changes.filter(change => change.type !== 'remove' && change.data['columnName'] === e.value && e.value !== change.key['columnName']).length;
    return (matchingColsFromDatasource + matchingColsFromEdits) < 2;
  };

  // TODO: Change out this method for a simpler one when updating to DevEx 21.2 or higher
  filterToolbarItems(e){
    e.toolbarOptions.items = e.toolbarOptions.items.filter(item => ['addRowButton', 'revertButton'].includes(item.name) );
  }

  setDefaultPaymentValue(e){
    e.data.isPayment = false;
  }

  setFormatByColumnReference(this: any, newData: any, value: string, currentRowData: any) {
    newData.format = this.lookup.items.find(x => x.refName === value)?.format;
    this.defaultSetCellValue(newData, value, currentRowData);
  }

  rowValidating(e): void {
    this.isValid = e.isValid;
  }

  recordEdits(e: any): void {
    this.savedColumnsEditDetails = {};
    e.changes.forEach(change => {
      const data = {...change.key, ...change.data};
      const newFriendlyName: string = this.columnsDatasource.dataSource.find(col => col['refName'] === data.columnName)?.value;
      const savedColumn = new SavedColumn(0, data['friendlyName'] ?? newFriendlyName, '', data['isPayment'], data['format']);
      if(change.type === 'update' && data.columnName != null){
        savedColumn.id = data['savedColumnId'];
      } else if(change.type === 'insert'){
        savedColumn.id = -1;
      } else if(change.type === 'remove'){
        savedColumn.id = -2;
      }
      this.savedColumnsEditDetails[data.columnName] = savedColumn;
    });
    this.savedColumnsEditDetailsChange.emit(this.savedColumnsEditDetails);
  }

  sortSavedColumns = (data) => this.columnsDatasource.dataSource.find(col => col.refName === data.columnName)?.value ?? 'zzz';
}

import { CoreEventArguments } from './core-event-arguments';

export class CoreValueListTemplateProps {
    keyExpr: string;
    valueExpr: string;
    deleteEnabledExpr: string;

    createForValueList(keyExpr: string, valueExpr: string, deleteEnabledExpr: string = null): CoreValueListTemplateProps {

        this.keyExpr = keyExpr;
        this.valueExpr = valueExpr;
        this.deleteEnabledExpr = deleteEnabledExpr;

        return this;
    }
}

import { AfterViewInit, Component, Input, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DxFilterBuilderComponent, DxTagBoxComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { GearPropertyValue } from '../../models/building-blocks';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { DataColumn } from 'src/app/shared/models/building-blocks';
import { filter } from 'rxjs/operators';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-segment-filter-builder',
  templateUrl: './segment-filter-builder.component.html',
  styleUrls: ['./segment-filter-builder.component.scss']
})
export class SegmentFilterBuilderComponent implements OnInit {
    @Input() focusedObjectChanges: any;
    @Input() propertyValue: GearPropertyValue;
	@ViewChildren('filterBuilder') filterBuilders!: QueryList<DxFilterBuilderComponent>;

    idProps = {fields: [], value: [], groupDescription: {and: 'Tag & Text'}};
    qtyProps = {fields: [], value: [], groupDescription: {and: 'Quantity'}};
    dateProps = {fields: [], value: [], groupDescription: {and: 'Date'}};
    acctProps = {fields: [], value: [], groupDescription: {and: 'Account'}};

	customOperations = [
        {
            name: 'in',
            icon: 'check',
            editorTemplate: 'tagBoxTemplate',
            customizeText: (fieldInfo) => {
                const lookup = fieldInfo.field.lookup;
                const dataSource = lookup.dataSource.some(x => x['items']) ?
                    [].concat(...(lookup.dataSource.map(group => group['items']))) :
                    lookup.dataSource;
                return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
            }
        },
        {
            name: 'not in',
            icon: 'close',
            editorTemplate: 'tagBoxTemplate',
            customizeText: (fieldInfo) => {
                const lookup = fieldInfo.field.lookup;
                const dataSource = ['product_id', 'customer_id'].includes(fieldInfo.field.dataField) ?
                    [].concat(...(lookup.dataSource.map(group => group['items']))) :
                    lookup.dataSource;
                return dataSource.find(x => x[lookup.valueExpr] === fieldInfo.value)[lookup.displayExpr];
            }
        },
        {
            name: 'period',
            caption: 'In period',
            icon: 'event',
            hasValue: false,
        }
    ];

	constructor(private bbHelper: BuildingBlockHelperService) {
        this.removeConflictingOps = this.removeConflictingOps.bind(this);
    }

	ngOnInit() {
        this.bbHelper.getDataColumns().pipe(filter(x => x !== null)).subscribe(dataColumns => {
            this.getFields(dataColumns);
            const criteriaFilterString = this.focusedObjectChanges['propertyValues'].find(x => x.property.systemName === 'criteriaFilterString')?.value ?? '';
            const conditions = this.getConditions(criteriaFilterString);
            if (this.propertyValue.property.friendlyName === 'Criteria Filter') {
                this.idProps.value = conditions.filter(c => this.idProps.fields.find(f => f.dataField === c[0]));
                this.qtyProps.value = conditions.filter(c => this.qtyProps.fields.find(f => f.dataField === c[0]));
                this.dateProps.value = conditions.filter(c => this.dateProps.fields.find(f => f.dataField === c[0]));
            } else if (this.propertyValue.property.friendlyName === 'Account Filter') {
                this.acctProps.value = conditions.filter(c => this.acctProps.fields.find(f => f.dataField === c[0]));
            }
        });

	}

    getFields(dataColumns: DataColumn[]) {
        this.idProps.fields = dataColumns
            .filter(x => x.systemName.match(/^(tag_|product_id|customer_id)/) && !x.systemName.endsWith('_name'))
            .sort((a, b) => (a.friendlyName > b.friendlyName) ? 1 : -1)
            .map(dataColumn => ({
                caption: dataColumn.friendlyName,
                dataField: dataColumn.systemName,
                dataType: 'number',
                filterOperations: ['product_id', 'customer_id'].includes(dataColumn.systemName) ? ['in', 'not in'] : ['=', '<>', 'in', 'not in'],
                lookup: this.bbHelper.getLookupByDatatype(dataColumn.datatype, true),
            }));

        this.qtyProps.fields = dataColumns
            .filter(x => x.systemName.match(/^qty_/))
            .sort((a, b) => (a.friendlyName > b.friendlyName) ? 1 : -1)
            .map(dataColumn => ({
                caption: dataColumn.friendlyName,
                dataField: dataColumn.systemName,
                dataType: 'decimal',
                filterOperations: ['=', '>=', '<=', 'between']
            }));

        this.dateProps.fields = dataColumns
            .filter(x => x.systemName.match(/^date_/))
            .sort((a, b) => (a.friendlyName > b.friendlyName) ? 1 : -1)
            .map(dataColumn => ({
                caption: dataColumn.friendlyName,
                dataField: dataColumn.systemName,
                dataType: 'date',
                filterOperations: ['=', '>=', '<=', 'between', 'period']
            }));

        this.acctProps.fields = dataColumns
            .filter(x => x.systemName.match(/^(attrib_|seller.*[^\d]$|region_|job_|boss_)/) && !x.systemName.endsWith('_name'))
            .sort((a, b) => (a.friendlyName > b.friendlyName) ? 1 : -1)
            .map(dataColumn => ({
                caption: dataColumn.friendlyName,
                dataField: dataColumn.systemName,
                dataType: 'number',
                filterOperations: ['=', '<>', 'in', 'not in'],
                lookup: this.bbHelper.getLookupByDatatype(dataColumn.datatype),
            }));
    }

    isFieldGrouped(condition: any): boolean {
        return ['product_id', 'customer_id'].includes(condition.field.dataField);
    }

    removeConflictingOps(e) {
        let conditions = e.value;
        if (!conditions?.length || typeof conditions[0] === 'string') {
            return;
        }

        const filterBuilder = this.filterBuilders.find(x => x.value === conditions);
        conditions = conditions.filter(c => typeof c !== 'string' && filterBuilder.fields.find(x => x.dataField === c[0]).lookup);
        conditions.forEach(c => c[2] = Array.isArray(c[2]) ? c[2] : [c[2]]);

        try {
            for (let i = 0; i < conditions.length; i++) {
                const c = conditions[i];
                if (['<>', 'not in'].includes(c[1]) && conditions.find(x => x[0] === c[0] && ['=', 'in'].includes(x[1]))) {
                    throw new Error('Unable to add condition with conflicting operation.');
                } else {
                    for (let j = i + 1; j < conditions.length; j++) {
                        const c2 = conditions[j];
                        if (c[2].some(id => c2[2].includes(id))) {
                            throw new Error('Unable to add condition with overlapping values.');
                        }
                    }
                }
            }
        } catch(error) {
            filterBuilder.value = [];
            filterBuilder.value = e.previousValue;
            notify(error, 'warning');
            return true;
        }
        return false;
    }

    getConditions(filterStr: string): any[] {
        const conditions: any[] = [];

        let re = /([^ ]*)( NOT)? IN \(([^\)]*)\)/g;
        let match;
        while((match = re.exec(filterStr))) {
            const ids = match[3].split(', ').map(x => +x);
            conditions.push(this.identityCondition(match[1], !!match[2], ids), 'and');
        }

        re = /(date_\d+) BETWEEN period_begin_date AND period_end_date/g;
        while((match = re.exec(filterStr))) {
            conditions.push([match[1], 'period'], 'and');
        }

        re = /(date_\d+) BETWEEN CONVERT\(datetime, '([^']*)', 126\) AND CONVERT\(datetime, '([^']*)', 126\)/g;
        while((match = re.exec(filterStr))) {
            const beginDate = new Date(match[2]).getFullYear() < 1800 ? null : new Date(match[2]);
            const endDate = new Date(match[3]).getFullYear() > 3000 ? null : new Date(match[3]);
            conditions.push(this.rangeCondition(match[1], beginDate, endDate), 'and');
        }

        re = /(qty_\d+) BETWEEN (-?\d*\.?\d*) AND (-?\d*\.?\d*)/g;
        while((match = re.exec(filterStr))) {
            const beginQty = match[2] === '-100000000000' ? null : Number.parseFloat(match[2]);
            const endQty = match[3] === '99999999999' ? null : Number.parseFloat(match[3]);
            conditions.push(this.rangeCondition(match[1], beginQty, endQty), 'and');
        }

        conditions.pop();
        return conditions;
    }

    normalizeConditions(conditions: any[]): any[] {
        conditions ??= [];
        conditions = typeof conditions[0] === 'string' ? [conditions] : conditions.filter(x => x !== 'and');
        conditions.forEach(c => {
            if (c[0].match(/^qty|^date/)) {
                if (c[1] === '=') {
                    c[1] = 'between';
                    c[2] = [c[2], c[2]];
                } else if (c[1] === '<=') {
                    c[1] = 'between';
                    c[2] = [null, c[2]];
                } else if (c[1] === '>=') {
                    c[1] = 'between';
                    c[2] = [c[2], null];
                }
            } else {
                if (c[1] === '=') {
                    c[1] = 'in';
                    c[2] = [c[2]];
                } else if (c[1] === '<>') {
                    c[1] = 'not in';
                    c[2] = [c[2]];
                }
            }
        });
        return conditions;
    }

    normalizeConditionsWithGroups(conditions: any[]): any[] {
        conditions = this.normalizeConditions(conditions);
        const products = this.idProps.fields.find(x => x.dataField === 'product_id').lookup.dataSource.map(x => x.items).flat();
        const customers = this.idProps.fields.find(x => x.dataField === 'customer_id').lookup.dataSource.map(x => x.items).flat();
        conditions.forEach(c => {
            if (c[0] === 'product_id') {
                conditions.push(['product_group_id', c[1],  [...new Set(c[2].map(id => products.find(x => x.id === id).productGroupId))]]);
            } else if (c[0] === 'customer_id') {
                conditions.push(['customer_group_id', c[1],  [...new Set(c[2].map(id => customers.find(x => x.id === id).customerGroupId))]]);
            }
        });
        return conditions;
    }

    convertConditionToSql(condition: any[]): string {
        const fieldName: string = condition[0];
        const opCode: string = condition[1];
        const value: any = condition[2];
        let opSql: string;

        switch (opCode) {
            case 'between':
                opSql = 'BETWEEN ' + (fieldName.startsWith('qty') ?
                    this.beginQtyNullableSql(value[0]) + ' AND ' + this.endQtyNullableSql(value[1]) :
                    this.beginDateNullableSql(value[0]) + ' AND ' + this.endDateNullableSql(value[1]));
                break;
            case 'period':
                opSql = 'BETWEEN period_begin_date AND period_end_date';
                break;
            case 'in':
                opSql = 'IN (' + value.join(', ') + ')';
                break;
            case 'not in':
                opSql = 'NOT IN (' + value.join(', ') + ')';
                break;
            default:
                throw new Error('Bad segment operation code: ' + opCode);
        }
        return opSql;
    }

    identityCondition(field: string, negated: boolean, values: string[]) {
        return values.length === 1 && !['product_id', 'customer_id'].includes(field) ?
            [field, negated ? '<>' : '=', values[0]] :
            [field, negated ? 'not in' : 'in', values];
    }

    rangeCondition(field: string, a: any, b: any): any[] {
        return a === null ? [field, '<=', b] :
            b === null ? [field, '>=', a] :
            a === b || (field.startsWith('date') && a.getDate() === b.getDate()) ? [field, '=', a] :
            [field, 'between', [a, b]];
    }

    beginDateNullableSql(date: Date): string {
        return 'CONVERT(datetime, \'' + formatDate(date  ?? '1753-01-01', 'yyyy-MM-dd', 'en-US') + 'T00:00:00.000\', 126)';
    }

    endDateNullableSql(date: Date): string {
        return 'CONVERT(datetime, \'' + formatDate(date ?? '9999-12-31', 'yyyy-MM-dd', 'en-US') + 'T23:59:59.990\', 126)';
    }

    beginQtyNullableSql(qty: number): string {
        return '' + (qty ?? '-100000000000');
    }

    endQtyNullableSql(qty: number): string {
        return '' + (qty ?? '99999999999');
    }
}

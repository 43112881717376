export class NavMenuItem {
    text: string;
    path: string;
    icon: string;
    parent: NavMenuItem;
    topLevel: boolean;
    items: NavMenuItem[];
    disabled: boolean;
    selected: boolean;

    constructor(text: string,
        path: string,
        icon: string = '',
        parent: NavMenuItem = null,
        disabled: boolean = false) {

        this.text = text;
        this.path = path;
        this.icon = icon;
        this.parent = parent;
        this.topLevel = parent === null;
        this.items = [];
        this.disabled = disabled;
        this.selected = false;
    }
}

import { CoreInputEditorType } from '../constants/dev-extreme-enums';
import { CoreEditorOptions } from './core-editor-options';
import { CoreValidationRule } from './core-validation-rule';

export class CoreFormFieldProperties {
    label: string;
    displayLabel: string;
    editorType: CoreInputEditorType;
    colSpan: number;
    dataset: any;
    isRequired: boolean;
    validationRules: CoreValidationRule[];
    displayExpr: string;
    valueExpr: string;
    selectedValue: any;
    addButtonOptions: any;
    editorOptions: CoreEditorOptions;
    prompt: string;

    createAll(
        label: string,
        editorType: CoreInputEditorType,
        isRequired: boolean,
        colSpan?: number,
        dataset?: any,
        displayExpr?: string,
        valueExpr?: string,
        selectedValue?: any
    ): CoreFormFieldProperties {
        this.label = label;
        this.editorType = editorType;
        this.isRequired = isRequired;
        this.colSpan = colSpan;
        this.dataset = dataset;
        this.displayExpr = displayExpr;
        this.valueExpr = valueExpr;
        this.selectedValue = selectedValue;
        this.validationRules = new Array();

        if (isRequired) {
            this.validationRules = [new CoreValidationRule().createRequired()];
        }

        return this;
    }

    createWithEditorOptions(
        label: string,
        editorType: CoreInputEditorType,
        isRequired: boolean,
        editorOptions: CoreEditorOptions,
        colSpan?: number,
        selectedValue?: any,
        message?: string,
        displayLabel?: string
    ) {
        this.displayLabel = displayLabel;
        this.label = label;
        this.editorType = editorType;
        this.isRequired = isRequired;
        this.colSpan = colSpan;
        this.selectedValue = selectedValue;
        this.editorOptions = editorOptions;
        this.prompt = message;

        if (isRequired) {
            this.validationRules = [new CoreValidationRule().createRequired()];
        }

        return this;
    }

    createAllWithCustomRules(
        label: string,
        editorType: CoreInputEditorType,
        isRequired: boolean,
        customRules: [
            {
                callbackFunction: (arg: any) => boolean,
                message?: string,
                isReevaluated?: boolean
            }
        ],
        colSpan?: number,
        dataset?: any
    ): CoreFormFieldProperties {
        this.createAll(label, editorType, isRequired, colSpan, dataset);

        customRules.forEach((rule) => {
            this.validationRules.push(
                new CoreValidationRule().createCustom(
                    rule.callbackFunction,
                    rule.message,
                    rule.isReevaluated
                )
            );
        });

        return this;
    }
}

<div class="core-dropdown-container">
    <dx-button
        [id]="renameButtonId"
        icon="edit"
        [visible]="!props.renameHidden"
        [disabled]="selectedItem === null || selectedItem === undefined || selectedItem.renameDisabled || props.renameDisabled"
        (click)="onRenameClick()"
        class="core-dropdown-floating-element">
    </dx-button>
    <dx-tooltip [target]="renameButtonTarget" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data of 'content'">
            <span>Rename</span>
        </div>
    </dx-tooltip>
    <dx-select-box
        *ngIf="!props.useTableDropDown"
        #coreDropDownBox
        class="core-dropdown-floating-element"
        [dataSource]="items"
        [value]="selectedValue"
        [hint]="selectedItem?.displayValue ?? ''"
        valueExpr="value"
        displayExpr="displayValue"
        [width]="props.width"
        [visible]="!props.dropdownHidden"
        [searchEnabled]="props.searchEnabled"
        [useItemTextAsTitle]="true"
        [dropDownOptions]="{width: 'auto', minWidth: props.width, maxWidth: '250'}"
        stylingMode="underlined"
        (onValueChanged)="onSelectItem($event)"
        (onItemClick)="onItemClick($event)">
    </dx-select-box>
    <dx-drop-down-box
        *ngIf="props.useTableDropDown && !props.searchEnabled"
        #coreDropDownTable
        class="core-dropdown-floating-element"
        [dropDownOptions]="props.tableGridDropDownOptions"
        [dataSource]="items"
        [value]="selectedValue"
        valueExpr="value"
        displayExpr="displayValue"
        [placeholder]="props.placeholder"
        [width]="props.width"
        [visible]="!props.dropdownHidden"
        contentTemplate="contentTemplate"
        stylingMode="underlined"
        (onValueChanged)="onSelectItem($event)"
        (onItemClick)="onItemClick($event)">
        <div *dxTemplate="let e of 'contentTemplate'">
            <core-data-grid
                #coreDropDownTableGrid
                [dataSource]="items"
                [columns]="_columns"
                [props]="props.gridProps"
                [focusedRowKey]="selectedValue"
                [parentTemplate]="{
                    cellInfo: coreDropDownTable,
                    parentTemplate: e,
                    callbackArrowFunction: onDropDownGridItemSelected
                }">
            </core-data-grid>
        </div>
    </dx-drop-down-box>
    <core-table-select-cell-editor
        *ngIf="props.useTableDropDown && props.searchEnabled"
        [editorProps]="tableSelectProps"
        [cellInfo]="_selectedCellValue"
        (onValueChanged)="onSelectItem($event)">
    </core-table-select-cell-editor>
    <dx-button
        [id]="addButtonId"
        icon="add"
        [visible]="!props.addNewHidden"
        [disabled]="props.addNewDisabled"
        (click)="onAddClick()"
        class="core-dropdown-floating-element">
    </dx-button>
    <dx-tooltip [target]="addButtonTarget" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data of 'content'">
            <span>Add New</span>
        </div>
    </dx-tooltip>
    <dx-button
        [id]="saveButtonId"
        icon="save"
        (click)="onSaveClick()"
        [visible]="!props.saveHidden"
        [disabled]="selectedItem === null || selectedItem === undefined || selectedItem.saveDisabled || props.saveDisabled"
        class="core-dropdown-floating-element">
    </dx-button>
    <dx-tooltip [target]="saveButtonTarget" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data of 'content'">
            <span>Save</span>
        </div>
    </dx-tooltip>
    <dx-button
        [id]="deleteButtonId"
        icon="trash"
        (click)="onDeleteClick()"
        [visible]="!props.deleteHidden"
        [disabled]="selectedItem === null || selectedItem === undefined || selectedItem.deleteDisabled || props.deleteDisabled"
        class="core-dropdown-floating-element">
    </dx-button>
    <dx-tooltip [target]="deleteButtonTarget" showEvent="dxhoverstart" hideEvent="dxhoverend">
        <div *dxTemplate="let data of 'content'">
            <span>Delete</span>
        </div>
    </dx-tooltip>
    <ng-container *ngFor="let button of this.props.dynamicButtons">
        <dx-button [id]="button.id" [icon]="button.icon" (click)="button.onClick()" [disabled]="!selectedItem" class="core-dropdown-floating-element"></dx-button>
        <dx-tooltip [target]="'#'+button.id" showEvent="dxhoverstart" hideEvent="dxhoverend">
            <div *dxTemplate="let data of 'content'">
                <span>{{button.hint}}</span>
            </div>
        </dx-tooltip>
    </ng-container>
    <dx-check-box
        #dropDownCheckBox
        [id]="checkBoxId"
        [value]="props.isCheckBoxChecked"
        [visible]="props.checkBoxVisible"
        [hint]="props.checkBoxHint"
        class="core-dropdown-floating-element"
        (onValueChanged)="onCheckBoxClick($event)">
    </dx-check-box>
</div>

<dx-popup
    #renamePopup
    [height]="renamePopupProps && renamePopupProps.height ? renamePopupProps.height : 170"
    [width]="renamePopupProps && renamePopupProps.width ? renamePopupProps.width : 400"
    [showTitle]="true"
    [title]="renamePopupProps && renamePopupProps.popupTitleText ? renamePopupProps.popupTitleText : 'Rename ' + props.itemDescription"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="renamePopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 180'
    }"
    class="core-dropdown-popup">
    <dx-scroll-view width="100%" height="100%">
        <ng-container *ngIf="renamePopupProps && renamePopupProps.coreFormFieldProperties">
            <form (submit)="onRenameFormSubmit($event)">
                <dx-form
                    #renameForm
                    [formData]="renamePopupProps.defaultFormValues"
                    [colCount]="renamePopupProps.formColCount"
                    (onFieldDataChanged)="onRenameFormFieldDataChanged($event)"
                    validationGroup="renameData">
                    <ng-container *ngFor="let data of renamePopupProps.coreFormFieldProperties">
                        <dxi-item
                            [dataField]="data.label"
                            [editorType]="data.editorType"
                            [colSpan]="data.colSpan"
                            [editorOptions]="{items: data.dataset}">
                            <ng-container *ngFor="let rule of data.validationRules">
                                <dxi-validation-rule
                                    [type]="rule.type"
                                    [reevaluate]="rule.isReevaluated"
                                    [message]="rule.message"
                                    [validationCallback]="rule.validationCallbackFunction">
                                </dxi-validation-rule>
                            </ng-container>
                        </dxi-item>
                    </ng-container>
                    <dxi-item cssClass="form-yes-button" itemType="button" [buttonOptions]="renamePopupSubmitButtonOptions"></dxi-item>
                    <dxi-item cssClass="form-cancel-button" itemType="button" [buttonOptions]="renamePopupCancelButtonOptions"></dxi-item>
                </dx-form>
            </form>
        </ng-container>
        <dx-responsive-box>
            <ng-container *ngIf="!renamePopupProps || !renamePopupProps.coreFormFieldProperties">
                <dxi-row [ratio]="5"></dxi-row>
                <dxi-row [ratio]="1"></dxi-row>
                <dxi-row [ratio]="3"></dxi-row>

                <dxi-col [ratio]="2"></dxi-col>
                <dxi-col [ratio]="4"></dxi-col>
                <dxi-col [ratio]="3"></dxi-col>
                <dxi-col [ratio]="3"></dxi-col>

                <dxi-item class="message">
                    <dxi-location [row]="0" [col]="0" [colspan]="4"></dxi-location>

                    <dx-text-box class="rename-textbox" [(value)]="renameValue" (input)="renameValue = $event.target.value;" stylingMode="underlined"></dx-text-box>
                </dxi-item>
                <dxi-item class="validation-message">
                    <dxi-location [row]="1" [col]="0" [colspan]="4"></dxi-location>

                    <span [hidden]="!displayValueExists(renameValue) || (displayValueExists(renameValue) && selectedItem !== undefined && renameValue === selectedItem.displayValue)">
                        <span class="item-description">{{props.itemDescription | titlecase}}</span>
                        <span> '{{renameValue}}' already exists.</span>
                    </span>
                </dxi-item>
                <dxi-item class="yes-button">
                    <dxi-location [row]="2" [col]="0" [colspan]="2"></dxi-location>

                    <dx-button id="renameOkButton" (click)="rename()" class="dx-button-default" [disabled]="!renameValue?.trim() || displayValueExists(renameValue)">OK</dx-button>
                </dxi-item>
                <dxi-item class="cancel-button">
                    <dxi-location [row]="2" [col]="2" [colspan]="2"></dxi-location>

                    <dx-button (click)="renamePopupVisible = false">Cancel</dx-button>
                </dxi-item>
            </ng-container>
        </dx-responsive-box>
    </dx-scroll-view>
</dx-popup>

<dx-popup
    #addPopup
    [height]="addPopupProps && addPopupProps.height ? addPopupProps.height : 170"
    [width]="addPopupProps && addPopupProps.width ? addPopupProps.width : 400"
    [showTitle]="true"
    title="Add New {{props.itemDescription | titlecase}}"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [(visible)]="addPopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 180'
    }"
    class="core-dropdown-popup">
    <dx-scroll-view width="100%" height="100%">
        <ng-container *ngIf="addPopupProps && addPopupProps.coreFormFieldProperties">
            <form (submit)="onAddFormSubmit($event)">
                <dx-form
                    #addForm
                    [formData]="addPopupProps.defaultFormValues"
                    [colCount]="addPopupProps.formColCount"
                    (onFieldDataChanged)="onAddFormFieldDataChanged($event)"
                    validationGroup="addData">
                    <ng-container *ngFor="let data of addPopupProps.coreFormFieldProperties">
                        <dxi-item
                            [dataField]="data.label"
                            [editorType]="data.editorType"
                            [colSpan]="data.colSpan"
                            [editorOptions]="{items: data.dataset}">
                            <ng-container *ngFor="let rule of data.validationRules">
                                <dxi-validation-rule
                                    [type]="rule.type"
                                    [reevaluate]="rule.isReevaluated"
                                    [message]="rule.message"
                                    [validationCallback]="rule.validationCallbackFunction">
                                </dxi-validation-rule>
                            </ng-container>
                        </dxi-item>
                    </ng-container>
                    <dxi-item cssClass="form-yes-button" itemType="button" [buttonOptions]="addPopupSubmitButtonOptions"></dxi-item>
                    <dxi-item cssClass="form-cancel-button" itemType="button" [buttonOptions]="addPopupCancelButtonOptions"></dxi-item>
                </dx-form>
            </form>
        </ng-container>
        <dx-responsive-box>
            <ng-container *ngIf="!addPopupProps || !addPopupProps.coreFormFieldProperties">
                <dxi-row [ratio]="5"></dxi-row>
                <dxi-row [ratio]="1"></dxi-row>
                <dxi-row [ratio]="3"></dxi-row>

                <dxi-col [ratio]="2"></dxi-col>
                <dxi-col [ratio]="4"></dxi-col>
                <dxi-col [ratio]="3"></dxi-col>
                <dxi-col [ratio]="3"></dxi-col>

                <dxi-item class="message">
                    <dxi-location [row]="0" [col]="0" [colspan]="4"></dxi-location>

                    <dx-text-box class="add-textbox" [(value)]="addValue" (input)="addValue = $event.target.value;" stylingMode="underlined"></dx-text-box>
                    <ng-container *ngIf="props.addMessage !== null && props.addMessage !== undefined">
                        <div [innerHTML]="props.addMessage"></div>
                    </ng-container>
                    <span class="custom-validation-message" [innerHtml]="customAddValidationMessage"></span>
                </dxi-item>
                <dxi-item class="validation-message">
                    <dxi-location [row]="1" [col]="0" [colspan]="4"></dxi-location>

                    <span [hidden]="!displayValueExists(addValue)">
                        <span class="item-description">
                            {{props.itemDescription | titlecase}}
                        </span>
                        <span> 
                            '{{addValue}}' already exists.
                        </span>
                    </span>
                </dxi-item>
                <dxi-item class="yes-button">
                    <dxi-location [row]="2" [col]="0" [colspan]="2"></dxi-location>

                    <dx-button (click)="add()" class="dx-button-default" [disabled]="isAddDisabled()">OK</dx-button>
                </dxi-item>
                <dxi-item class="cancel-button">
                    <dxi-location [row]="2" [col]="2" [colspan]="2"></dxi-location>

                    <dx-button (click)="resetAndHideAddPopup()">Cancel</dx-button>
                </dxi-item>
            </ng-container>
        </dx-responsive-box>
    </dx-scroll-view>
</dx-popup>

<dx-popup
    #deletePopup
    [height]="deletePopupProps && deletePopupProps.height ? deletePopupProps.height : 200"
    [width]="deletePopupProps && deletePopupProps.width ? deletePopupProps.width : 400"
    [showTitle]="true"
    title="Delete {{props.itemDescription | titlecase}}"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [(visible)]="deletePopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 180'
    }"
    class="core-dropdown-popup"
    (onHiding)="deletePopupHiding($event)">
    <dx-responsive-box>
        <dxi-row [ratio]="3"></dxi-row>
        <dxi-row [ratio]="2"></dxi-row>

        <dxi-col [ratio]="2"></dxi-col>
        <dxi-col [ratio]="4"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>

        <dxi-item class="icon">
            <dxi-location [row]="0" [col]="0"></dxi-location>
            <i class="dx-icon dx-icon-help"></i>
        </dxi-item>

        <dxi-item class="message">
            <dxi-location [row]="0" [col]="1" [colspan]="3"></dxi-location>

            <span>Delete {{props.itemDescription}} '{{deleteDisplayValue}}'?</span>
            <div *ngIf="deletePopupProps && deletePopupProps.bodyMessage" [innerHTML]="deletePopupProps.bodyMessage"></div>
            <div *ngIf="deletePopupProps && deletePopupProps.preventionWord">
                <dx-text-box #deleteTextBox [value]="defaultPassword" valueChangeEvent="keyup" (onValueChanged)="deletePopupValueChanged($event)" stylingMode="underlined"></dx-text-box>
            </div>
        </dxi-item>

        <dxi-item class="yes-button">
            <dxi-location [row]="1" [col]="0" [colspan]="2"></dxi-location>

            <dx-button id="deleteYesButton" #deleteYesButton (click)="delete($event)" class="dx-button-default">Yes</dx-button>
        </dxi-item>

        <dxi-item class="cancel-button">
            <dxi-location [row]="1" [col]="2" [colspan]="2"></dxi-location>

            <dx-button id="deleteCancelButton" #deleteCancelButton (click)="deletePopupVisible = false">No</dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>

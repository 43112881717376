import { CoreStringBase } from './core-string-base';

export class User extends CoreStringBase {
    sellerId: number;
    username: string;
    normalizedusername: string;
    email: string;
    normalizedemail: string;
    emailconfirmed: boolean;
    passwordhash: string;
    securitystamp: string;
    concurrencystamp: string;
    phonenumber: string;
    phonenumberconfirmed: boolean;
    twofactorenabled: boolean;
    lockoutenabled: boolean;
    accessfailedcount: number;
    tenantName: string;
}

<ng-container *ngIf="periodDropDownDatasource !== null">
    <dx-select-box
        [dataSource]="periodDropDownDatasource.dataSource"
        [displayExpr]="periodDropDownDatasource.displayExpr"
        [valueExpr]="periodDropDownDatasource.valueExpr"
        [useItemTextAsTitle]="true"
        stylingMode="underlined"
        [(value)]="dropDownValue"
        (onValueChanged)="emitNewValue($event)">
    </dx-select-box>
    <div [ngSwitch]="true">
        <ng-container *ngSwitchCase="dropDownValue === this.periodType.Standard || dropDownValue === this.periodType.Forward"></ng-container>
        <ng-container *ngSwitchCase="dropDownValue === this.periodType.Mtd">
            <div class="dx-field-label">Day</div>
            <div class="dx-field-value">
                <dx-number-box
                    (onValueChanged)="emitNewValue($event)"
                    min="1"
                    max="28"
                    [(value)]="optionalNumericInput1"
                    [showSpinButtons]="true"
                    stylingMode="underlined">
                </dx-number-box>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="dropDownValue === this.periodType.Rolling">
            <div class="dx-field">
                <div class="dx-field-label">Previous # Periods</div>
                <div class="dx-field-value">
                    <dx-number-box
                        (onValueChanged)="emitNewValue($event)"
                        min="0"
                        max="120"
                        [(value)]="optionalNumericInput1"
                        [showSpinButtons]="true"
                        stylingMode="underlined">
                    </dx-number-box>
                </div>
            </div>
            <div class="dx-field">
                <dx-check-box (onValueChanged)="emitNewValue($event)" text="Exclude Current" [(value)]="optionalCheckBox"></dx-check-box>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="dropDownValue === this.periodType.Static">
            <div class="dx-field">
                <div class="dx-field-label">From Period</div>
                <app-period-dropdown
                    #periodDropdown
                    [dropdownProps]="periodDropdownProps"
                    [(selectedPeriodId)]="selectedPeriodId"
                    [(selectedRecurrenceId)]="selectedRecurrenceId"
                    [(selectedSeriesId)]="selectedSeriesId">
                </app-period-dropdown>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Previous # Periods</div>
                <div class="dx-field-value">
                    <dx-number-box
                        (onValueChanged)="emitNewValue($event)"
                        min="0"
                        max="120"
                        [(value)]="optionalNumericInput1"
                        [showSpinButtons]="true"
                        stylingMode="underlined">
                    </dx-number-box>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="dx-field">
                <div class="dx-field-label">Day</div>
                <div class="dx-field-value">
                    <dx-number-box
                        (onValueChanged)="emitNewValue($event)"
                        min="1"
                        max="28"
                        [(value)]="optionalNumericInput1"
                        [showSpinButtons]="true"
                        stylingMode="underlined">
                    </dx-number-box>
                </div>
            </div>
            <div class="dx-field">
                <div class="dx-field-label">Month</div>
                <div class="dx-field-value">
                    <dx-number-box
                        (onValueChanged)="emitNewValue($event)"
                        min="1"
                        max="12"
                        [(value)]="optionalNumericInput2"
                        [showSpinButtons]="true"
                        stylingMode="underlined">
                    </dx-number-box>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

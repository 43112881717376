import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TransactionTagGroup } from '../models/transaction-tag-group';
import { TagGroupItem } from '../models/tag-group-item';
import { TagGroup } from '../models/tag-group';
import { TagGroupContext } from '../models/contexts/tag-group-context';
import { CoreResponse } from '../models/core-response';

@Injectable()
export class TagGroupService {
    importURL: string;
    private tagGroupURL = environment.apiEndpoint + '/taggroup/';

    constructor(private http: HttpClient) {
        this.importURL = `${this.tagGroupURL}import`;
    }

    getTransactionTagGroups(): Observable<TransactionTagGroup[]> {
        return this.http.get<TransactionTagGroup[]>(this.tagGroupURL);
    }

    getTagGroupItems(key: string): Observable<TagGroupItem[]> {
        return this.http.get<TagGroupItem[]>(this.tagGroupURL + 'items/' + key);
    }

    getTagGroups(key: string): Observable<TagGroup[]> {
        return this.http.get<TagGroup[]>(this.tagGroupURL + 'groups/' + key);
    }

    updateTagGroupItem(context: TagGroupContext): Observable<number> {
        return this.http.put<number>(this.tagGroupURL + 'updateitem', context);
    }

    updateTagGroup(context: TagGroupContext): Observable<number> {
        return this.http.put<number>(this.tagGroupURL + 'updategroup', context);
    }

    renameTransactionTagGroup(key, value): Observable<number> {
        return this.http.put<number>(this.tagGroupURL + 'rename/' + key + '/' + value, null);
    }

    insertTagGroupItem(context: TagGroupContext): Observable<TagGroupItem> {
        return this.http.post<TagGroupItem>(this.tagGroupURL + 'insertitem', context);
    }

    insertTagGroup(context: TagGroupContext): Observable<TagGroup> {
        return this.http.post<TagGroup>(this.tagGroupURL + 'insertgroup', context);
    }

    deleteTagGroupItem(key: string, id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.tagGroupURL + 'deleteitem/' + key + '/' + id.toString());
    }

    deleteTagGroup(key: string, id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.tagGroupURL + 'deletegroup/' + key + '/' + id.toString());
    }

    getTagGroupItemDeleteConstraints(key: string, id: number): Observable<any> {
        return this.http.get<any>(this.tagGroupURL + 'deleteitem-constraints/' + key + '/' + id.toString());
    }

    getTagGroupDeleteConstraints(key: string, id: number): Observable<any> {
        return this.http.get<any>(this.tagGroupURL + 'deletegroup-constraints/' + key + '/' + id.toString());
    }

    getTransactionTagGroupDeleteConstraints(key: string): Observable<any> {
        return this.http.get<any>(this.tagGroupURL + 'deletetransactiontaggroup-constraints/' + key);
    }

    getTagGroupRenameConstraints(name: string): Observable<any> {
        return this.http.get<any>(this.tagGroupURL + 'rename-constraints/' + encodeURIComponent(name));
    }
}

<div class="date-box-wrapper" *ngIf="permissionViewProcessLog">
    <div class="control-label">Start Date</div>
    <div class="control-wrapper">
        <dx-date-box class="range-controls" [(max)]="endDate" [(value)]="startDate" type="datetime"></dx-date-box>
    </div>
    <div class="control-label">End Date</div>
    <div class="control-wrapper">
        <dx-date-box class="range-controls" [(min)]="startDate" [(value)]="endDate" type="datetime"></dx-date-box>
    </div>
    <dx-button class="apply-button" text="Apply Date Filters" (onClick)="applyDateFilters()"></dx-button>
</div>
<ng-container *ngIf="permissionViewProcessLog">
    <app-process-log-grid [dateRange]="dateRange"></app-process-log-grid>
</ng-container>
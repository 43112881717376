import { BreakpointObserver } from '@angular/cdk/layout';
import { SafeHtml } from '@angular/platform-browser';
import { CoreImportColumnType } from '../constants/enums';
import { ColumnHeaderValidation } from './column-header-validation';
import { CorePopupComponent } from '../components/core-popup/core-popup.component';
import { CoreColumn } from './core-column';
import { GridProps } from './core-data-grid-properties';
import { CoreDropdownPopupProperties } from './core-dropdown-popup-properties';
import { CoreDropdownProperties } from './core-dropdown-properties';
import { CoreDynamicCellEditorProps } from './core-dynamic-cell-editor-props';
import { CoreDynamicInputProperties } from './core-dynamic-input-properties';
import { CoreEventArguments } from './core-event-arguments';
import { CoreFileUpload } from './core-file-upload';
import { CoreFormFieldProperties } from './core-form-field-properties';
import { DevExpressDMLFunctions } from './dev-express-dml-functions';
import { CoreImportCleaningRecord } from './core-import-cleaning-record';
import { ImportCleaningSourceData } from './import-cleaning-source-data';
import { RequiredProperty } from './required-property';

export class CorePopupStep {
    message: string | SafeHtml;
    okClickFunction: (e: CorePopupStep) => void;
    cancelClickFunction: (e: CorePopupStep, popup: CorePopupComponent) => void;
    parentThis: any;
    isOkClickFunction: boolean;
    isCancelClickFunction: boolean;
    isOkClickEnabled: boolean = true;
    password: string;
    okClickText: string;
    cancelClickText: string;
    doesCancelProceed: boolean;
    backNavigationEnabled: boolean;
    uploadProps: CoreFileUpload;
    itemsProps: CoreDynamicInputProperties[] = [];
    areAllPropsOnOneLine: boolean = false;
    cancelDisabled: boolean = false;
    okVisible: boolean = true;
    cancelVisible: boolean = true;
    attributes: any;
    isPreformatted: boolean = false;
    dataGrid: {
        dataSource: any[],
        columns: CoreColumn[],
        props: GridProps
    };
    coreDropdown: {
        dropdownName: string,
        selectedValue: number,
        dataSource: any[],
        popupProps: CoreDropdownPopupProperties[],
        props: CoreDropdownProperties,
        onSelectionChangedFunction: (event: any) => void,
        onItemClickFunction: (event: any) => void,
        onDeleteFunction: (event: any) => void,
        onAddFunction: (event: any) => void,
        onRenameFunction: (event: any) => void,
        onSaveFunction: (event: any) => void,
        onCheckUncheckFunction: (event: any) => void
    };
    formProps: {
        coreFormFieldProperties: CoreFormFieldProperties[],
        formColCount: number,
        defaultFormValues: any,
        message: string,
        repeatable: boolean,
        addButtonProps: any
    };

    constructor(message: string | SafeHtml, okClickFunction: (e: CorePopupStep) => void, cancelClickFunction: (e: CorePopupStep, popup: CorePopupComponent) => void, parentThis: any,
        okClickText: string = 'OK', cancelClickText: string = 'Cancel', backNavigationEnabled: boolean = false,
        doesCancelProceed: boolean = false, areAllPropsOnOneLine = false, cancelDisabled: boolean = false, isOkClickEnabled: boolean = true) {
        this.message = message;
        this.okClickFunction = okClickFunction;
        this.cancelClickFunction = cancelClickFunction;
        this.parentThis = parentThis;
        this.okClickText = okClickText;
        this.cancelClickText = cancelClickText;
        this.backNavigationEnabled = backNavigationEnabled;
        this.doesCancelProceed = doesCancelProceed;
        this.isOkClickFunction = false;
        this.isCancelClickFunction = false;
        this.areAllPropsOnOneLine = areAllPropsOnOneLine;
        this.cancelDisabled = cancelDisabled;
        this.isOkClickEnabled = isOkClickEnabled;

        if (okClickFunction !== undefined && okClickFunction !== null) {
            this.isOkClickFunction = true;
            if (parentThis !== null) {
                this.okClickFunction = this.okClickFunction.bind(parentThis);
            }
        }

        if (cancelClickFunction !== undefined && cancelClickFunction !== null) {
            this.isCancelClickFunction = true;
            if (parentThis !== null) {
                this.cancelClickFunction = this.cancelClickFunction.bind(parentThis);
            }
        }
    }

    setCancelButtonText(cancelText: string, enableBackNavigation: boolean = null): CorePopupStep {
        this.cancelClickText = cancelText;
        if (enableBackNavigation != null) {
            this.backNavigationEnabled = enableBackNavigation;
        }

        return this;
    }

    appendItemProp(itemProp: CoreDynamicInputProperties): CorePopupStep {
        this.itemsProps.push(itemProp);

        return this;
    }

    createPasswordProtectedStep(password: string, passwordBoxPrefill?: string,
        parentThis?: any, onSelectionChangedFunction?: (e: CoreEventArguments, index?: number) => void,
        passwordLabel?: string): CorePopupStep {

        this.password = password;
        if (password !== passwordBoxPrefill) {
            this.isOkClickEnabled = false;
        }

        let boundOnSelectionChangedFunction: (e: CoreEventArguments, index?: number) => void;
        if (onSelectionChangedFunction && parentThis) {
            boundOnSelectionChangedFunction = onSelectionChangedFunction.bind(parentThis);
        }

        const newOnSelectChangedFunction = (e: CoreEventArguments, index?: number): void => {
            if (e.event.value === this.password) {
                this.isOkClickEnabled = true;
            } else {
                this.isOkClickEnabled = false;
            }

            if (onSelectionChangedFunction) {
                boundOnSelectionChangedFunction(e, index);
            }
        };

        this.appendItemProp(new CoreDynamicInputProperties((passwordLabel ? passwordLabel : null), true, null, null, null, passwordBoxPrefill, (passwordLabel ? true : false))
            .createForTextBox(this, newOnSelectChangedFunction));

        return this;
    }

    enableFileUpload(okClickFunctionFile: (e: any, value: CorePopupStep) => void, fileImportButtonText: string,
        fileImportLabel: string, allowedFileExtensions: string[], uploadMode: string, fileTypeId: number,
        allowMultiple: boolean = false, requiredProperties: RequiredProperty[] = []): CorePopupStep {
        this.uploadProps = new CoreFileUpload();
        this.uploadProps.visible = true;
        this.uploadProps.okClickFunctionFile = okClickFunctionFile;
        this.uploadProps.fileImportButtonText = fileImportButtonText;
        this.uploadProps.fileImportLabel = fileImportLabel;
        this.uploadProps.allowedFileExtensions = allowedFileExtensions;
        this.uploadProps.uploadMode = uploadMode;
        this.uploadProps.allowMultiple = allowMultiple;
        this.uploadProps.fileTypeId = fileTypeId;
        this.uploadProps.importedFileIds = [];
        this.uploadProps.requiredproperties = requiredProperties;

        if (okClickFunctionFile !== undefined && okClickFunctionFile !== null) {
            this.uploadProps.okClickPassesFile = true;
            if (this.parentThis !== null) {
                this.uploadProps.okClickFunctionFile = this.uploadProps.okClickFunctionFile.bind(this.parentThis);
            }
        }

        return this;
    }

    enableFormProps(formColCount: number, defaultFormValues: any, message?: string, repeatable?: boolean, addButtonProps?: any): CorePopupStep {
        this.formProps = {
            coreFormFieldProperties: [],
            formColCount,
            defaultFormValues,
            message,
            repeatable,
            addButtonProps
        };

        return this;
    }

    addFormField(coreFormFieldProperty: CoreFormFieldProperties): CorePopupStep {
        if (this.formProps?.coreFormFieldProperties) {
            this.formProps.coreFormFieldProperties.push(coreFormFieldProperty);
        }

        return this;
    }

    enableDataGrid(dataSource: any[], columns: CoreColumn[], props: GridProps): void {
        this.dataGrid = {
            dataSource,
            columns,
            props
        };
    }

    enableImportCleaningGrid(cleaningRecords: CoreImportCleaningRecord[], importCleaningSourceData: ImportCleaningSourceData): void {
        const cleaningGridColumns: CoreColumn[] = [
            new CoreColumn('excelColumnName', 'Column/Field', true),
            new CoreColumn('rowNumbersString', 'Affected Row Numbers', true),
            new CoreColumn('text', 'Unrecognized Text', true),
            new CoreColumn('replaceWith', 'Replace With', true, 'string', null, true)
                .addDynamicCellEditorTemplate(this, this.getImportCleaningGridCellEditorProps, null, importCleaningSourceData)
        ];

        const cleaningGridProps: GridProps = new GridProps('cleaningRecordsGrid', null, false, true)
            .addDMLFunctions(new DevExpressDMLFunctions(null, null, null, false, true, false))
            .setColumnResizingAllowed(true);

        cleaningRecords.map(x => {
            x.rowNumbersString = x.rowNumbers.join(', ');
            x.text = x.text === '' ? '[expected value missing]' : x.text;
        });

        this.enableDataGrid(cleaningRecords, cleaningGridColumns, cleaningGridProps);
    }

    getImportCleaningGridCellEditorProps(cellInfo: any, coreColumn: CoreColumn, gridData: any[], importCleaningSourceData: ImportCleaningSourceData): CoreDynamicCellEditorProps {
        const result = new CoreDynamicCellEditorProps();
        const importColumnType: CoreImportColumnType = cellInfo.data.importColumnType;

        switch (importColumnType) {
            case CoreImportColumnType.Seller:
                const sellerColumns: CoreColumn[] = [
                    new CoreColumn('id', '', false),
                    new CoreColumn('name', 'Name', true).sortAsc(),
                    new CoreColumn('importName', 'Import Name', true)
                ];
                // Allow user to search for all import names
                for (let i = 2; i <= 8; i++) {
                    sellerColumns.push(new CoreColumn(`importName${i}`, `importName${i}`, false));
                }
                result.createForCoreTableSelectTemplate(importCleaningSourceData.sellers, sellerColumns, 'importName', 'importName', 300, 600);
                break;
            case CoreImportColumnType.Date:
                result.createForDateTimePicker('M/d/yyyy');
                break;
            case CoreImportColumnType.AttributeClass:
            case CoreImportColumnType.Variable: // Account Factors
                const attributeClass = importCleaningSourceData.attributeClasses.find(x =>
                    (importColumnType === CoreImportColumnType.Variable && x.name === 'variable') || x.friendlyName === cellInfo.data.excelColumnName);
                if (attributeClass) {
                    result.createForSelectBox(attributeClass.attributes, 'name', 'name');
                } else {
                    result.createForTextBox(); // This shouldn't happen
                }
                break;
            default:
                result.createForTextBox();
                break;
        }

        return result;
    }

    enableCoreDropDown(selectedValue: number, dropdownName: string, props: CoreDropdownProperties,
        popupProps: CoreDropdownPopupProperties[], dataSource: any[], parentThis: any = null,
        onSelectionChangedFunction: (event: any) => void = null, onItemClickFunction: (event: any) => void = null,
        onDeleteFunction: (event: any) => void = null, onAddFunction: (event: any) => void = null,
        onRenameFunction: (event: any) => void = null, onSaveFunction: (event: any) => void = null,
        onCheckUncheckFunction: (event: any) => void = null): void {

        this.coreDropdown = {
            selectedValue,
            dropdownName,
            props,
            popupProps,
            dataSource,
            onSelectionChangedFunction,
            onItemClickFunction,
            onDeleteFunction,
            onAddFunction,
            onRenameFunction,
            onSaveFunction,
            onCheckUncheckFunction
        };

        if (onSelectionChangedFunction && parentThis) {
            this.coreDropdown.onSelectionChangedFunction = onSelectionChangedFunction.bind(parentThis);
        } else {
            this.coreDropdown.onSelectionChangedFunction = () => {};
        }

        if (onItemClickFunction && parentThis) {
            this.coreDropdown.onItemClickFunction = onItemClickFunction.bind(parentThis);
        } else {
            this.coreDropdown.onItemClickFunction = () => {};
        }

        if (onDeleteFunction && parentThis) {
            this.coreDropdown.onDeleteFunction = onDeleteFunction.bind(parentThis);
        } else {
            this.coreDropdown.onDeleteFunction = () => {};
        }

        if (onAddFunction && parentThis) {
            this.coreDropdown.onAddFunction = onAddFunction.bind(parentThis);
        } else {
            this.coreDropdown.onAddFunction = () => {};
        }

        if (onRenameFunction && parentThis) {
            this.coreDropdown.onRenameFunction = onRenameFunction.bind(parentThis);
        } else {
            this.coreDropdown.onRenameFunction = () => {};
        }

        if (onSaveFunction && parentThis) {
            this.coreDropdown.onSaveFunction = onSaveFunction.bind(parentThis);
        } else {
            this.coreDropdown.onSaveFunction = () => {};
        }

        if (onCheckUncheckFunction && parentThis) {
            this.coreDropdown.onCheckUncheckFunction = onCheckUncheckFunction.bind(parentThis);
        } else {
            this.coreDropdown.onCheckUncheckFunction = () => {};
        }
    }
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, NgModule, ViewChildren, Output, EventEmitter, ViewChild} from '@angular/core';
import { NbButtonModule, NbCardModule, NbChatComponent, NbChatModule, NbIconModule, NbInputModule } from '@nebular/theme';
import { DxResponsiveBoxModule, DxTextBoxModule, DxTextAreaModule, DxButtonModule,
    DxLoadPanelModule, DxPopupModule, DxDropDownButtonModule, DxDataGridModule, DxTooltipModule, DxScrollViewModule, DxPopoverComponent, DxPopoverModule } from 'devextreme-angular';
import { DxiToolbarItemModule } from 'devextreme-angular/ui/nested';
import { ToastrService } from 'ngx-toastr';
import { CustomerFeedback } from 'src/app/shared/models/customer-feedback';
import { CustomerFeedbackService } from 'src/app/shared/services/customer-feedback.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { SellerService } from 'src/app/shared/services/seller.service';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { CoreComponentModule } from '../../../core-component.module';

@Component({
    selector: 'corebot-popup-content',
    templateUrl: './corebot-popup-content.component.html',
    styleUrls: ['./corebot-popup-content.component.scss']
})

export class CoreBotPopupContentComponent implements OnInit {
    @ViewChild('nebularChat') nebularChat: NbChatComponent;
    @ViewChildren('likeFeedback') likeFeedbackPopovers: DxPopoverComponent[];
    @ViewChildren('dislikeFeedback') dislikeFeedbackPopovers: DxPopoverComponent[];

    @Input() messages: any;
    @Input() sellerName: string;
    @Input() size: string;
    @Output() confirmAction: EventEmitter<string> = new EventEmitter();
    @Output() confirmChat: EventEmitter<string> = new EventEmitter();
    @Output() confirmRedirect: EventEmitter<string> = new EventEmitter();
    @Output() denyAction: EventEmitter<string> = new EventEmitter();
    @Output() confirmNextSetupStepRedirect: EventEmitter<string> = new EventEmitter();

    feedbackContent: string = '';
    isFeedbackPositive: boolean;
    extraFeedbackSubmitted: boolean;

    constructor(
        private sellerService: SellerService,
        private feedbackService: CustomerFeedbackService,
        private helperService: HelperService,
        private toast: ToastrService
    ) {}

    ngOnInit() {

    }

    showPopover(e: any){
        if (e.currentTarget.id.includes('dislike')){
            this.isFeedbackPositive = false;
        }
        else{
            this.isFeedbackPositive = true;
        }
    }

    submitFeedback(e: any, msgId: number){
        this.extraFeedbackSubmitted = true;
        const feedback: CustomerFeedback = {
            coreUser: this.sellerName,
            content: this.feedbackContent,
            date: this.helperService.currentDateTimeAsUTC(),
            type: 'CoreBot',
            isPositive: this.isFeedbackPositive,
            itemRef: msgId
        };

        this.feedbackService.submitFeedback(feedback).subscribe(response => {
            this.feedbackContent = '';
            if (feedback.isPositive){
                this.likeFeedbackPopovers.find(x => x.instance.element().id === 'like-feedback-popover' + feedback.itemRef).visible = false;
            }
            else {
                this.dislikeFeedbackPopovers.find(x => x.instance.element().id === 'dislike-feedback-popover' + feedback.itemRef).visible = false;
            }
            this.toast.info('Feedback submitted! Thank you for letting us know your thoughts.');
        });
    }

    submitSimpleFeedback(e: any, msgId: number){
        if (!this.extraFeedbackSubmitted){
            const feedback: CustomerFeedback = {
                coreUser: this.sellerName,
                isPositive: this.isFeedbackPositive,
                content: this.feedbackContent,
                date: this.helperService.currentDateTimeAsUTC(),
                type: 'CoreBot',
                itemRef: msgId
            };

            this.feedbackService.submitFeedback(feedback).subscribe(response => {
                if (feedback.isPositive){
                    this.likeFeedbackPopovers.find(x => x.instance.element().id === 'like-feedback-popover' + feedback.itemRef).visible = false;
                }
                else {
                    this.dislikeFeedbackPopovers.find(x => x.instance.element().id === 'dislike-feedback-popover' + feedback.itemRef).visible = false;
                }
                this.toast.info('Feedback submitted! Thank you for letting us know your thoughts.');
            });
        }
        this.extraFeedbackSubmitted = false;
    }

    confirmBotAction(e: any){
        this.confirmAction.emit('confirm');
    }

    denyBotAction(e: any){
        this.denyAction.emit('deny');
    }

    confirmUserRedirect(e: any){
        this.confirmRedirect.emit('confirm');
    }

    confirmRuleRedirect(e: any){
        this.confirmRedirect.emit(e);
    }

    confirmNextStepRedirect(e: any){
        this.confirmNextSetupStepRedirect.emit(e);
    }

    confirmChatMessage(e: any){
        this.confirmChat.emit('confirm');
    }

    onExporting(e, msgId: number) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Results');
        const msgIndex = this.messages.findIndex(x => x.customMessageData && x.customMessageData.id === msgId);
        const name = this.messages[msgIndex - 1].text.replace(/['"\\\/]/g, '');

        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), name + '.xlsx');
          });
        });
    }
}

@NgModule({
    imports: [
        CommonModule,
        DxTextBoxModule,
        DxButtonModule,
        DxDataGridModule,
        DxTooltipModule,
        NbChatModule,
        NbInputModule,
        NbIconModule,
        NbCardModule,
        NbButtonModule,
        DxiToolbarItemModule,
        DxTooltipModule,
        DxScrollViewModule,
        DxPopoverModule,
        CoreComponentModule
    ],
    declarations: [CoreBotPopupContentComponent],
    exports: [CoreBotPopupContentComponent]
})
export class CoreBotPopupContentModule{ }

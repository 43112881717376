import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { BuildingBlockHelperService } from 'src/app/pages/building-blocks/building-block-helper.service';
import { SaveableBbProperty } from '../../models/saveable-bb-property';
import { HelperService } from '../../services/helper.service';
import { BuildingBlocksService } from '../../services/building-blocks.service';
import { BbObject, Gear, GearCode, Container } from '../../models/building-blocks';
import { EnumContainerType, coreResponseCodes } from '../../constants/enums';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bb-circular-sources-popup',
  templateUrl: './bb-circular-sources-popup.component.html',
  styleUrls: ['./bb-circular-sources-popup.component.scss']
})
export class BbCircularSourcesPopupComponent implements OnInit {
  circularSources: BbObject[] = [];
  container: Container = null;
  loopContainer: Container = null;
  show: boolean;
  constructor(private buildingBlocksService: BuildingBlocksService, private buildingBlockHelper: BuildingBlockHelperService, private toast: ToastrService){}

  ngOnInit(): void {
    this.buildingBlockHelper.getScopeContainer().subscribe(container => {
      if(container){
        this.container = container;
        this.checkForCircularDeps(container.id);
      }
    });
  }

  checkForCircularDeps(processId: string): void {
    this.buildingBlocksService.getCircularSources(processId).subscribe(res => {
        this.circularSources = res.results.map(objId => this.buildingBlockHelper.getObjectById(objId));
        if(this.circularSources.length > 0){
          this.circularSources = this.circularSources.filter(src => src.id !== processId && src.parentId === processId);
          this.loopContainer = this.buildingBlockHelper.getObjectById(processId) as Container;
          this.show = true;
        }
    });
  }

  clearSourceProcess(processId: string): void {
    const gear = this.buildingBlockHelper.getObjectById(processId) as Gear;
    gear.propertyValues.find(prop => prop.property.systemName === 'sourceProcessId').value = '';
    const dbGear = this.buildingBlockHelper.GearToDbGear(gear);
    this.buildingBlocksService.updateDbGear(dbGear).subscribe(res => {
        res.objects.forEach(obj => {
          this.buildingBlockHelper.replaceObjectInStore(obj);
        });
        this.toast.success('Circular dependency removed.');
        this.show = false;
        this.buildingBlockHelper.forceRefreshDiagram();
      }, err => {
        this.toast.error(err.message);
    });
  }

  getGearTypeStringFromGearTypeCode(typeCode: string){
    const gearNameString: string = Object.keys(GearCode).find(key => GearCode[key] === typeCode);
    return gearNameString ? gearNameString.replace('GearCode', '') : 'Gearbox';
  }

  getLoopContainerTitle(){
    if(this.loopContainer){
      return (this.loopContainer.typeId === EnumContainerType.ProductionRule ? 'Rule: ': 'Gearbox: ') + this.loopContainer.name;
    }
    return '';
  }
}


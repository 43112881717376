export class SellerCreationInfo {
    name: string;
    importName: string;
    beginDate: Date;

    constructor(name: string, importName: string, beginDate: Date) {
        this.name = name;
        this.importName = importName;
        this.beginDate = beginDate;
    }
}

import { ColumnHeaderValidation } from './column-header-validation';
import { OldStringNewString } from './old-string-new-string';
import { XactionFileImportCleaningRecord } from './xaction-file-import-cleaning-record';
import { XactionFileImportPacket } from './xaction-file-import-packet';

export class XactionFileImportRequest {
    fileId: string;
    seriesId: number;
    periodId: number;
    datasourceId: number;
    datasourceMappingId: number;
    areEmptyValuesValidated: boolean;
    isDeleteAndReplace: boolean;
    importPacket: XactionFileImportPacket;

    constructor(fileId: string, seriesId: number, periodId: number, datasourceId: number, datasourceMappingId: number,
        areEmptyValuesValidated: boolean, isDeleteAndReplace: boolean = false) {
        this.fileId = fileId;
        this.seriesId = seriesId;
        this.periodId = periodId;
        this.datasourceId = datasourceId;
        this.datasourceMappingId = datasourceMappingId;
        this.areEmptyValuesValidated = areEmptyValuesValidated;
        this.isDeleteAndReplace = isDeleteAndReplace;
    }

    addCleanedRecords(cleanedRecords: XactionFileImportCleaningRecord[]) {
        this.createImportPacket();
        this.importPacket.cleanedRecords = cleanedRecords;
        this.importPacket.headers = null;
        this.importPacket.cleanedRecords.map(record => {
            if (record.replaceWith !== null && record.replaceWith !== undefined) {
                record.replaceWith = record.replaceWith.toString();
            }
        });

        return this;
    }

    addCleanedColumns(cleanedColumns: OldStringNewString[]) {
        this.createImportPacket();
        this.appendCleanedColumns(cleanedColumns);
        this.importPacket.cleanedRecords = null;

        return this;
    }

    appendCleanedColumns(cleanedColumns: OldStringNewString[]) {
        if (!this.importPacket.headers) {
            this.importPacket.headers = new ColumnHeaderValidation();
        }

        this.importPacket.headers.headerMappings = cleanedColumns;

        return this;
    }

    createImportPacket() {
        if (!this.importPacket) {
            this.importPacket = new XactionFileImportPacket();
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { ProcessLog } from '../models/process-log';
import { ProcessLogIdTransactionId } from '../models/process-log-id-transaction-id';
import { CoreRequest } from '../models/core-request';
import { ProcessLogForAdminPopup } from '../models/process-log-for-admin-popup';
import { DateRange } from '../models/date-range';
import { ProcessLogForLogViewer } from '../models/process-log-for-log-viewer';

@Injectable({
    providedIn: 'root'
})

export class ProcessLogService {
    private baseurl: string = environment.apiEndpoint;
    private processLogURL = this.baseurl + '/ProcessLog';

    constructor(private http: HttpClient) { }

    getNewTransactionIdForProcessLog(): Observable<CoreResponse<string>> {
        return this.http.get<CoreResponse<string>>(this.processLogURL + '/getNewTransactionIdForProcessLog');
    }

    getProcessLogsByTransactionId(transactionId: string, lastProcessLogId: number): Observable<CoreResponse<ProcessLog>> {
        return this.http.get<CoreResponse<ProcessLog>>(this.processLogURL + '/getProcessLogsByTransactionId/' + transactionId + '/' + lastProcessLogId);
    }

    getActiveProcessLogsByTransactionIdLastId(lastValues: ProcessLogIdTransactionId[]): Observable<CoreResponse<ProcessLogForAdminPopup>> {
        if (lastValues?.length < 1) {
            lastValues = [];
        }

        const request = new CoreRequest<ProcessLogIdTransactionId>(null, lastValues);
        return this.http.post<CoreResponse<ProcessLogForAdminPopup>>(this.processLogURL + '/getActiveProcessLogsByTransactionIdLastId', request);
    }

    getProcessLogsByDateRange(dateRange: DateRange): Observable<CoreResponse<ProcessLogForLogViewer[]>> {
        const request = new CoreRequest<DateRange>(dateRange, null);
        return this.http.post<CoreResponse<ProcessLogForLogViewer[]>>(this.processLogURL + '/getProcessLogsByDateRange', request);
    }

    getLevelsStale(dateStr: string): Observable<boolean> {
        return this.http.get<boolean>(this.processLogURL + '/getLevelsStale/' + dateStr);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DxButtonModule, DxTextBoxModule, DxTextAreaModule, DxPopupModule, DxResponsiveBoxModule,
    DxTreeViewModule, DxTooltipModule, DxDataGridModule
} from 'devextreme-angular';
import { FormulaBuilderComponent } from './formula-builder.component';
import { CoreComponentModule } from '../core-component.module';

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxPopupModule,
    DxResponsiveBoxModule,
    DxTreeViewModule,
    DxTooltipModule,
    DxDataGridModule,
    CoreComponentModule,
  ],
    declarations: [FormulaBuilderComponent],
    exports: [FormulaBuilderComponent]
})
export class FormulaBuilderModule { }

<div class="core-filter-builder-tool-panel">

    <div class="filter-builder-message">
        <span>
            Add a custom filter string to filter records to what you're looking for.
        </span>
        <span *ngIf="!filterBuilderValue || filterBuilderValue.length === 0" class="status-message">
            No filter applied. Click below to add.
        </span>
        <span *ngIf="filterBuilderValue?.length > 0" class="status-message">
            A filter is active. Click below to edit.
        </span>
    </div>
    
    <div class="filter-builder-buttons">
        <dx-button
            [text]="(filterBuilderValue?.length > 0 ? 'Edit Filter' : 'Add Filter')"
            (onClick)="openFilterBuilderPopup()">
        </dx-button>
    </div>

    <dx-popup 
        [showTitle]="true"
        title="Filter Builder"
        [dragEnabled]="true"
        container=".dx-viewport"
        [resizeEnabled]="true"
        [showCloseButton]="true"
        [hideOnOutsideClick]="false"
        [(visible)]="popupVisible"
        height="500"
        width="800"
        minHeight="200"
        minWidth="400"
        [position]="{
            my: 'top',
            at: 'top',
            offset: { y: 30 }
        }"
        class="filter-builder-popup">

        <dx-scroll-view class="scrollable-container-vertical" direction="vertical">
            <dx-scroll-view class="scrollable-container-horizontal" direction="horizontal">
                <dx-filter-builder
                    #filterBuilder
                    [allowHierarchicalFields]="true"
                    [fields]="filterBuilderFields"
                    [(value)]="filterBuilderValue"
                    [groupOperations]="['and', 'or']"
                    width="auto"
                    [customOperations]="customOperations"
                    (onValueChanged)="onValueChanged()"
                    (onContentReady)="onContentReady($event)">
                    <dxo-filter-operation-descriptions
                        isBlank="is (Blank)"
                        isNotBlank="is not (Blank)">
                    </dxo-filter-operation-descriptions>
                    <dx-tag-box
                        *dxTemplate="let condition of 'tagBoxTemplate'"
                        [value]="condition.value"
                        [dataSource]="condition.field.lookup.dataSource"
                        [maxDisplayedTags]="9"
                        (onValueChanged)="condition.setValue($event.value)"
                        [valueExpr]="condition.field.lookup.valueExpr"
                        [displayExpr]="condition.field.lookup.displayExpr"
                        width="auto"
                        showSelectionControls="true"
                        [searchEnabled]="true"
                        stylingMode="underlined">
                    </dx-tag-box>
                </dx-filter-builder>
            </dx-scroll-view>
        </dx-scroll-view>

        <dxi-toolbar-item toolbar="bottom" location="center" template="bottomToolbarTemplate">
            <div *dxTemplate="let data of 'bottomToolbarTemplate'">
                <div class="filter-builder-popup-buttons">
                    <dx-button
                        text="Close"
                        (onClick)="closePopup()">
                    </dx-button>
                </div>
            </div>
        </dxi-toolbar-item>

    </dx-popup>

</div>

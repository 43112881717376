import { Component, OnInit } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
  selector: 'app-core-custom-loading-indicator',
  templateUrl: './core-custom-loading-indicator.component.html',
  styleUrls: ['./core-custom-loading-indicator.component.scss']
})
export class CoreCustomLoadingIndicatorComponent implements ILoadingOverlayAngularComp {
  params: ILoadingOverlayParams;
  indicatorUrl: string = '../../../../assets/images/spinning-gears.gif';
  constructor() { }

  agInit(params: ILoadingOverlayParams): void {
    this.params = params;
  }

}

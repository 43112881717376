<dx-data-grid
    [(dataSource)]="internalDatasource"
    [allowColumnResizing]="true"
    [columnMinWidth]="40"
    (onContentReady)="checkForChanges()"
    (onRowInserted)="emitValue()"
    (onRowUpdated)="emitValue()"
    (onRowRemoved)="emitValue()"
    (onToolbarPreparing)="filterToolbarItems($event)">
    <dxi-column dataField="id" caption="Name">
        <dxo-lookup
            [dataSource]="ruleLookup"
            displayExpr="name"
            valueExpr="id">
        </dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column type="buttons" [width]="40"></dxi-column>
    <dxo-editing mode="batch" [allowUpdating]="true" [allowDeleting]="true" [allowAdding]="true"></dxo-editing>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>
</dx-data-grid>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnumBucketClass, EnumBucketType } from '../constants/enums';
import { Bucket } from '../models/bucket';
import { BucketClass } from '../models/bucket-class';
import { BucketContext } from '../models/contexts/bucket-context';
import { CoreResponse } from '../models/core-response';
import { BucketSequenceContext } from '../models/contexts/bucket-sequence-context';

@Injectable()
export class BucketService {
    private bucketURL = environment.apiEndpoint + '/bucket/';
    private bucketClassURL = environment.apiEndpoint + '/bucketclass/';
    private favoriteEvent = new Subject<void>();

    constructor(private http: HttpClient) {
    }

    getFavoriteEvent(): Observable<any> {
        return this.favoriteEvent.asObservable();
    }

    getBucketClass(name: string): Observable<BucketClass> {
        return this.http.get<BucketClass>(this.bucketClassURL + 'class/' + name);
    }

    getBucketClasses(): Observable<BucketClass[]> {
        return this.http.get<BucketClass[]>(this.bucketClassURL + 'classes');
    }

    getBucketClassesBySeller(sellerId: number): Observable<BucketClass[]> {
        return this.http.get<BucketClass[]>(this.bucketClassURL + 'classesbysellerid/' + sellerId);
    }

    getBucketClassesByFolderType(folderType: EnumBucketType): Observable<BucketClass[]> {
        return this.http.get<BucketClass[]>(this.bucketClassURL + 'classesbyfoldertype/' + folderType);
    }

    insertBucketClass(bucketClass: BucketClass): Observable<number> {
        return this.http.post<number>(this.bucketClassURL, bucketClass);
    }

    getBuckets(): Observable<Bucket[]> {
        return this.http.get<Bucket[]>(this.bucketURL);
    }

    getDefaultBucket(itemId, type): Observable<Bucket> {
        return this.http.get<Bucket>(this.bucketURL + 'default/' + itemId + '/' + type);
    }

    getFavoriteBucket(itemId: number, type: number): Observable<Bucket> {
        return this.http.get<Bucket>(this.bucketURL + 'favorite/' + itemId + '/' + type);
    }

    getFolderBucket(itemId: number, type: number): Observable<Bucket> {
        return this.http.get<Bucket>(this.bucketURL + 'folder/' + itemId + '/' + type);
    }

    getAllFolderBuckets(): Observable<Bucket[]> {
        return this.http.get<Bucket[]>(this.bucketURL + 'folders');
    }

    insertBucket(bucket: Bucket): Observable<Bucket> {
        return this.http.post<Bucket>(this.bucketURL + 'insert', bucket);
    }

    addDefaultBucket(bucket: Bucket): Observable<Bucket> {
        return this.http.post<Bucket>(this.bucketURL + 'adddefault', bucket);
    }

    addDefaultBucketBulk(bucketContext: BucketContext): Observable<CoreResponse<number>> {
        return this.http.post<CoreResponse<number>>(this.bucketURL + 'bulkadddefaults', bucketContext);
    }

    addFavoriteBucket(itemId: number, type: number, sellerId: number): Observable<Bucket> {
        const newBucket = new Bucket({ itemId, type, sellerId, bucketClassId: EnumBucketClass.Favorites});
        return this.insertBucket(newBucket).pipe(tap(() => this.favoriteEvent.next()));
    }

    updateBucket(bucket: Bucket): Observable<number> {
        return this.http.put<number>(this.bucketURL + 'update', bucket);
    }

    updateBucketSequences(context: BucketSequenceContext): Observable<number> {
        return this.http.post<number>(this.bucketURL + 'updatebucketsequences', context);
    }

    deleteBucket(bucket: Bucket): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.bucketURL + 'delete/' + bucket.id);
    }

    deleteDefaultBucketForSeller(): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.bucketURL + 'deletedefault');
    }

    deleteFavoriteBucket(bucket: Bucket): Observable<CoreResponse<number>> {
        return this.deleteBucket(bucket).pipe(tap(() => this.favoriteEvent.next()));
    }

    useDefaultBucket() {
        return this.http.get<boolean>(this.bucketURL + 'usedefault');
    }
}

<ng-container *ngIf="fieldDropDownDatasource && fieldDropDownDatasource.dataSource.length > 0">
    <dx-data-grid
        [(dataSource)]="gridDatasource"
        (onContentReady)="checkForChanges()"
        (onRowInserted)="emitValue()"
        (onRowRemoved)="emitValue()"
        (onRowUpdated)="emitValue()"
        (onToolbarPreparing)="filterToolbarItems($event)">
        <dxi-column dataField="systemName" [calculateSortValue]="sortColumn" [editorOptions]="{ useItemTextAsTitle: true }">
            <dxo-lookup
                [dataSource]="fieldDropDownDatasource['dataSource']"
                [displayExpr]="fieldDropDownDatasource['displayExpr']"
                [valueExpr]="fieldDropDownDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxi-column dataField="aggregateFunction" [calculateSortValue]="sortColumn">
            <dxo-lookup
                [dataSource]="aggregateFunctionDropDownDatasource['dataSource']"
                [displayExpr]="aggregateFunctionDropDownDatasource['displayExpr']"
                [valueExpr]="aggregateFunctionDropDownDatasource['valueExpr']">
            </dxo-lookup>
        </dxi-column>
        <dxo-editing mode="batch" [allowUpdating]="isEditable" [allowDeleting]="isEditable" [allowAdding]="isEditable"></dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
    </dx-data-grid>
</ng-container>

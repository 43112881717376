import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DatasourceProcess,
    TransformProcess,
    FilterProcess,
    CalculateProcess,
    AggregateProcess,
    Group,
    ProcessDataColumn,
    DataColumn,
    Gear,
    BbObject,
    GearType,
    SegmentGearType,
    GearPropertyValue,
    AssignGear,
    PeriodFilterGear,
    AccountNormalizeGear,
    EarnDateGear,
    CounterGear,
    ContextGear,
    FormulaGear,
    FilterGear,
    JoinGear,
    ConsolidateGear,
    HierarchyGear,
    UnionGear,
    UnionProcess,
    UpdateXactionGear,
    AIProcess,
    AIContextGear,
    AIFilterGear,
    AIFileRetrievalGear,
    AIScoreGear,
    AITextGenerationGear,
    AISummaryGear
} from '../models/building-blocks';
import { catchError, map, switchMap, takeUntil, timeout } from 'rxjs/operators';
import { ContainerRuleContext } from '../models/contexts/container-rule-context';
import { Container } from '../models/building-blocks';
import { GearboxContext } from '../models/contexts/gearbox-template-context';
import { GearboxInstanceContext } from '../models/contexts/gearbox-instance-context';
import { CoreResponse } from '../models/core-response';
import { DisplayDataColumnsResponse } from '../models/display-data-columns-response';
import { coreResponseCodes } from '../constants/enums';
import { ContainerSegmentContext } from '../models/contexts/container-segment-context';
import { ProcessLogService } from './process-log.service';
import { HelperService } from './helper.service';
import { RuleVersionBatchChanges } from '../models/rule-version-batch-changes';
import { DbGear } from '../models/db-gear';
import { RuleVersionAuditResults } from '../models/rule-version-audit-results';
import { SavedColumn } from '../models/saved-column';
import { AuditGridFilterCriteria } from '../models/audit-grid-filter-criteria';
import { AuditDetail } from '../models/audit-detail';

@Injectable()
export class BuildingBlocksService {
    public gearTypeRecord: Record<string, GearType>;
    public dataColumnRequest = new Subject<any>();
    public dataRecordsRequest = new Subject<any>();
    public gridDestroyed = new Subject<void>();
    public dataRecordResponse = this.dataRecordsRequest
        .pipe(switchMap((params: { scopeProcessId: string, processId: string, seriesId: number, periodId: number, filterObject: AuditGridFilterCriteria, rowCount: number}) =>
            this.getDisplayDataRecordsByProcessId(params['scopeProcessId'], params['processId'], params['seriesId'], params['periodId'], params['filterObject'], params['rowCount'])
            .pipe(catchError(err => this.dataRecordErrorHandler), takeUntil(this.gridDestroyed))));
    public dataColumnResponse = this.dataColumnRequest
        .pipe(switchMap((params: { scopeProcessId: string, processId: string} ) => this.getProcessDataColumnsByProcessId(params['scopeProcessId'], params['processId'], params['periodId'])
            .pipe(catchError(err => this.dataColumnErrorHandler), takeUntil(this.gridDestroyed))));
    public dataColumnError = new Subject<any>();
    public dataRecordError = new Subject<any>();
    private baseUrl = environment.apiEndpoint + '/building-blocks';
    private dataColumnErrorHandler = new Observable(err => {
        this.dataColumnError.next(err);
    });
    private dataRecordErrorHandler = new Observable(err => {
        this.dataRecordError.next(err);
    });
    private created = new Date();

    constructor(private http: HttpClient,
        private processLogService: ProcessLogService,
        private helperService: HelperService) {
        this.gearTypeRecord = {};
        for (const gearType of this.getGearTypeArray()) {
            this.gearTypeRecord[gearType.getGearTypeCode()] = gearType;
        }
    }

    getAllContainers(): Observable<CoreResponse<Container>> {
        return this.http.get<CoreResponse<Container>>(this.baseUrl + '/container');
    }

    getContainerById(containerId: string): Observable<Container> {
        return this.http.get<Container>(this.baseUrl + '/container/' + containerId).pipe<Container>(map(obj => this.objToBbObject(obj) as Group));
    }

    getSiblingContainers(containerId: string): Observable<Container[]> {
        return this.http.get<Container[]>(this.baseUrl + '/siblingcontainers/' + containerId);
    }

    getGearTypeByCode(gearTypeCode: string): GearType {
        return this.gearTypeRecord[gearTypeCode];
    }

    getAllObjects(): Observable<BbObject[]> {
        return this.http.get<any[]>(this.baseUrl + '/object').pipe<BbObject[]>(map(arr => arr.map(obj =>
            this.objToBbObject(obj)
        )));
    }

    getAllRuleDependencies(): Observable<any> {
        return this.http.get<any>(this.baseUrl + '/rule-dependencies');
    }

    getAllRuleDependenciesGenerate(periodId: number): Observable<any> {
        return this.calculateRuleLevels(periodId).pipe(
            switchMap(response => response.responseCode === coreResponseCodes.Success ? this.getAllRuleDependencies() : of(response))
        );
    }

    resetBuildingBlockService(): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + '/service');
    }

    cacheAndLocalBuildingBlockRefresh(){
        this.resetBuildingBlockService().subscribe(res => {
            window.location.reload();
        });
    }

    objToBbObject(obj: any) {
        let result: BbObject;
        switch (true) {
            case DatasourceProcess.getObjectTypeCode() === obj.objectTypeCode:
                result = new DatasourceProcess(obj.id, obj.name, obj.parentId, obj.description, obj.sourceType, obj.columnString);
                break;
            case TransformProcess.getObjectTypeCode() === obj.objectTypeCode :
                result = new TransformProcess(obj.id, obj.name, obj.parentId, obj.description, obj.mainSourceProcessId, obj.auxSourceProcesses, obj.writtenColumns);
                break;
            case FilterProcess.getObjectTypeCode() === obj.objectTypeCode:
                result = new FilterProcess(obj.id, obj.name, obj.parentId, obj.description, obj.sourceProcessId, obj.filterString);
                break;
            case CalculateProcess.getObjectTypeCode() === obj.objectTypeCode:
                result = new CalculateProcess(obj.id, obj.name, obj.parentId, obj.description, obj.sourceProcessId, obj.displayFormulaString, obj.sqlFormulaString);
                break;
            case AggregateProcess.getObjectTypeCode() === obj.objectTypeCode:
                result = new AggregateProcess(obj.id, obj.name, obj.parentId, obj.description, obj.sourceProcessId, obj.keyColumns, obj.inputValueColumnSystemName, obj.functionCode);
                break;
            case UnionProcess.getObjectTypeCode() === obj.objectTypeCode:
                result = new UnionProcess(obj.id, obj.name, obj.parentId, obj.description, obj.sourceProcessId, obj.auxProcessId, obj.unionAll);
                break;
            case AIProcess.getObjectTypeCode() === obj.objectTypeCode:
                result = new AIProcess(obj.id, obj.name, obj.parentId, obj.description, obj.sourceProcessId);
                break;
            case Group.getObjectTypeCode() === obj.objectTypeCode:
                result = new Group(obj.id, obj.name, obj.parentId, obj.description, obj.typeId, obj.objectJson, obj.recurrenceId, obj.lastModified,
                    obj.locked, obj.headProcessId, obj.isActive, obj.periodBeginId, obj.periodEndId);
                break;
            case Gear.getObjectTypeCode() === obj.objectTypeCode:
                {
                    const gearType = this.getGearTypeByCode(obj.gearTypeCode);
                    const propertyValues = gearType.getProperties()
                        .map(property => {
                            let value = obj[property.systemName];
                            if (property.systemName === 'redir') {
                                value = {code: obj['redirCode'], field: obj['redirFieldName'], seller: JSON.parse(obj['redirSeller']) ?? [], jobTitle: JSON.parse(obj['redirJobTitle']) ?? []};
                            }
                            return new GearPropertyValue(property, value);
                        });
                    result = new Gear(obj.id, obj.name, obj.parentId, obj.description, gearType, obj.headProcessId, propertyValues, obj.isPayment, obj.isActive);
                }
                break;
        }
        return result;

    }

    getAllDataColumns(): Observable<DataColumn[]> {
        return this.http.get<DataColumn[]>(this.baseUrl + '/data-column');
    }

    getAllSavedColumns(): Observable<CoreResponse<SavedColumn>> {
        return this.http.get<CoreResponse<SavedColumn>>(this.baseUrl + '/saved-columns');
    }

    getProcessDataColumnsByProcessId(scopeProcessId: string, processId: string, periodId: number): Observable<ProcessDataColumn[]> {
        return this.http.get<ProcessDataColumn[]>(this.baseUrl + `/process-data-column/${scopeProcessId}/${processId}/${periodId}`);
    }

    getDataRecordsByProcessId(scopeProcessId: string, processId: string, seriesId: number, periodId: number, filterString: string, rowCount: number): Observable<any[]> {
        return this.http.post<any[]>(this.baseUrl + `/data-record/${scopeProcessId}/${processId}/${seriesId}/${periodId}/${rowCount}`, JSON.stringify(filterString))
            .pipe(map(arr => arr.map(obj => {
                const newObj = {};
                for (const propertyName in obj) {
                    if ((propertyName.endsWith('_date') || propertyName.startsWith('date_')) && obj[propertyName] !== null) {
                        const localDate = new Date(obj[propertyName]);
                        newObj[propertyName] = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
                    } else {
                        newObj[propertyName] = obj[propertyName];
                    }
                }
                return newObj;
            })));
    }

    getCircularSources(processId: string): Observable<CoreResponse<string>> {
        return this.http.get<CoreResponse<string>>(this.baseUrl + `/circular-sources/${processId}`);
    }

    getDisplayDataRecordsByProcessId(scopeProcessId: string, processId: string, seriesId: number, periodId: number, filter: AuditGridFilterCriteria, rowCount: number):
        Observable<DisplayDataColumnsResponse> {
        return this.http.post<CoreResponse<DisplayDataColumnsResponse>>(this.baseUrl + `/display-data-record/${scopeProcessId}/${processId}/${seriesId}/${periodId}/${rowCount}`, filter)
            .pipe(map(res => {
                if(res.responseCode === coreResponseCodes.Success){
                    const dataColumnsResponse = res.result;
                    dataColumnsResponse.records.map(obj => {
                        const newObj = {};
                        for (const propertyName in obj) {
                            if ((propertyName.endsWith('_date') || propertyName.startsWith('date_')) && obj[propertyName] !== null) {
                                const localDate = new Date(obj[propertyName]);
                                newObj[propertyName] = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
                            } else {
                                newObj[propertyName] = obj[propertyName];
                            }
                        }
                        return newObj;
                    });
                    return dataColumnsResponse;
                } else {
                    throw new Error(res.message);
                }

            }));
    }

    getAllDiagramCustomShapes(): any[] {
        // NOTE: A shape's connection points should be ordered clockwise from the leftmost point on the top border.
        // Otherwise the layout algorithms tend to choke.
        // See https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDiagram/Configuration/edges/#toPointIndexExpr. -DH
        return [
            {
                category: 'Core.General',
                type: 'Core.DatasourceProcess',
                title: 'Datasource',
                template: 'datasourceProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.TransformProcess',
                title: 'Transform',
                template: 'transformProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.FilterProcess',
                title: 'Filter',
                template: 'filterProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.CalculateProcess',
                title: 'Calculate',
                template: 'calculateProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.AggregateProcess',
                title: 'Aggregate',
                template: 'aggregateProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.UnionProcess',
                title: 'Union',
                template: 'unionProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.Group',
                title: 'Group',
                template: 'groupShapeTemplate',
                textTop: 0.20
            },
            {
                category: 'Core.General',
                type: 'Core.Gearbox',
                title: 'Gearbox',
                template: 'gearboxShapeTemplate',
                textTop: 0.48
            },
            {
                category: 'Core.Segment',
                type: 'Core.Gear',
                title: 'Segment',
                template: 'gearShapeTemplate',
            },
            {
                category: 'Core.Gears',
                type: 'Core.Assign.Gear',
                title: 'Assign',
                template: 'assignGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.NonToolboxGears',
                type: 'Core.PeriodGear.Gear',
                title: 'Period Filter',
                template: 'periodGearShapeTemplate',
            },
            {
                category: 'Core.NonToolboxGears',
                type: 'Core.AccountNormalize.Gear',
                title: 'Account Normalize',
                template: 'accountNormalizeGearShapeTemplate',
            },
            {
                category: 'Core.NonToolboxGears',
                type: 'Core.EarnDate.Gear',
                title: 'Earn Date',
                template: 'earnDateGearShapeTemplate',
            },
            {
                category: 'Core.Gears',
                type: 'Core.Counter.Gear',
                title: 'Group',
                template: 'counterGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.Gears',
                type: 'Core.Formula.Gear',
                title: 'Formula',
                template: 'formulaGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.Gears',
                type: 'Core.Filter.Gear',
                title: 'Filter',
                template: 'filterGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.Gears',
                type: 'Core.Join.Gear',
                title: 'Join',
                template: 'joinGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.NonToolboxGears',
                type: 'Core.Consolidate.Gear',
                title: 'Consolidate',
                template: 'consolidateGearShapeTemplate',
            },
            {
                category: 'Core.Gears',
                type: 'Core.Hierarchy.Gear',
                title: 'Hierarchy',
                template: 'hierarchyGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.Gears',
                type: 'Core.Datasource.Gear',
                title: 'Datasource',
                template: 'datasourceGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.Gears',
                type: 'Core.Union.Gear',
                title: 'Union',
                template: 'unionGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.Gears',
                type: 'Core.Update.Gear',
                title: 'Update Xaction',
                template: 'updateXactionGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.AIGears',
                type: 'Core.AIContext.AIGear',
                title: 'AI Datasource',
                template: 'contextGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.AIGears',
                type: 'Core.TextGeneration.AIGear',
                title: 'Generate Text',
                template: 'textGenerationGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.AIGears',
                type: 'Core.Score.AIGear',
                title: 'Score',
                template: 'scoreGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.AIGears',
                type: 'Core.AIFilter.AIGear',
                title: 'AI Filter',
                template: 'aiFilterGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.AIGears',
                type: 'Core.Summary.AIGear',
                title: 'Summarize',
                template: 'summaryGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.AIGears',
                type: 'Core.FileRetrieval.AIGear',
                title: 'File Retrieval',
                template: 'fileRetrievalGearShapeTemplate',
                textTop: 0.42
            },
            {
                category: 'Core.General',
                type: 'Core.AIProcess',
                title: 'AI Process',
                template: 'transformProcessShapeTemplate',
                textTop: 0.55,
            },
            {
                category: 'Core.General',
                type: 'Core.FileSource',
                title: 'File Source',
                template: 'datasourceProcessShapeTemplate',
                textTop: 0.55,
            }
        ].map(customShape => ({
            ...customShape,
            allowEditText: false,
        }));
    }

    getDiagramCustomShapesByCategory(category: string): any[] {
        return this.getAllDiagramCustomShapes().filter(x => x.category === category);
    }

    createGear(parentId: string, gearTypeCode: string): Observable<CoreResponse<BbObject>> {
        return this.http.post<CoreResponse<BbObject>>(this.baseUrl + `/gear/${parentId}/${gearTypeCode}`, null).pipe(map(res => {
            if(res.responseCode === coreResponseCodes.Success){
                res.results = res.results.map(obj =>this.objToBbObject(obj));
            }
            return res;
        }));
    }

    updateGear(gear: Gear): Observable<CoreResponse<BbObject>> {
        return this.http.put<CoreResponse<BbObject>>(this.baseUrl + '/segment-gear', gear).pipe(map(res => {
                if(res.responseCode === coreResponseCodes.Success){
                    res.results = res.results.map(obj =>this.objToBbObject(obj));
                }
                return res;
            }
        ));
    }

    validateUpdateDbGear(gear: DbGear): Observable<any> {
        return this.http.post<any>(this.baseUrl + '/validate-update-gear/', gear);
    }

    updateDbGear(gear: DbGear): Observable<{objects: BbObject[], columns: any}> {
        return this.http.patch<any>(this.baseUrl + '/gear/', gear).pipe(map(res => {
            if(res.responseCode === coreResponseCodes.Success){
                return {
                    objects: res.result.objects.map(obj => this.objToBbObject(obj)),
                    columns: res.result.dataColumns
                };
            } else {
                throw new Error(res.message);
            }
        }));
    }

    createGearboxTemplate(context: GearboxContext): Observable<CoreResponse<boolean>> {
        return this.http.post<CoreResponse<boolean>>(this.baseUrl + '/gearbox-template', context);
    }

    createGearboxInstance(context: GearboxInstanceContext): Observable<CoreResponse<any>> {
        return this.http.post<CoreResponse<boolean>>(this.baseUrl + '/gearbox-instance', context);
    }

    insertContainer(group: Group): Observable<CoreResponse<Group>> {
        return this.http.post<CoreResponse<Group>>(this.baseUrl + '/container', group);
    }

    insertRule(context: ContainerRuleContext): Observable<CoreResponse<Group>> {
        return this.http.post<CoreResponse<Group>>(this.baseUrl + '/rule', context);
    }

    createSegmentRule(context: ContainerSegmentContext): Observable<CoreResponse<BbObject>> {
        return this.http.post<CoreResponse<BbObject>>(this.baseUrl + '/segment-rule', context).pipe(map(resp => {
            if(resp.responseCode === coreResponseCodes.Success){
                resp.results  = resp.results.map(obj =>this.objToBbObject(obj));
            }
            return resp;
            }
        ));
    }

    updateSegmentRule(context: ContainerSegmentContext): Observable<CoreResponse<number>> {
        return this.http.put<CoreResponse<number>>(this.baseUrl + '/segment-rule', context);
    }

    updateContainer(container: Container): Observable<CoreResponse<number>> {
        return this.http.put<CoreResponse<number>>(this.baseUrl + '/container', container);
    }

    updateRule(context: ContainerRuleContext): Observable<CoreResponse<Container>> {
        return this.http.put<CoreResponse<Container>>(this.baseUrl + '/rule', context);
    }

    updateRuleVersion(context: ContainerRuleContext): Observable<CoreResponse<Container>> {
        return this.http.put<CoreResponse<Container>>(this.baseUrl + '/rule-version', context);
    }

    updateSetupRule(context: ContainerRuleContext): Observable<CoreResponse<Container>> {
        return this.http.put<CoreResponse<Container>>(this.baseUrl + '/setup-rule', context);
    }

    calculateRuleLevels(periodId: number): Observable<CoreResponse<boolean>> {
        return this.http.get<CoreResponse<boolean>>(this.baseUrl + '/calculate-levels/' + periodId);
    }

    deleteContainer(id: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/container/' + id);
    }

    deleteRule(id: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/rule/' + id);
    }

    deleteSegment(id: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/segment/' + id);
    }

    validateDeleteObject(id: string): Observable<CoreResponse<void>> {
        return this.http.get<CoreResponse<void>>(this.baseUrl + '/validate-delete-object/' + id);
    }

    deleteGear(id: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/gear/' + id);
    }

    decomposeGearbox(id: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/gearbox/' + id);
    }

    processRule(ruleId, seriesId, periodId): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/${ruleId}/${seriesId}/${periodId}`);
    }

    persistRowUpdate(processId: string, seriesId, periodId): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/persist/${processId}/${seriesId}/${periodId}`);
    }

    getSqlFormulaString(displayFormulaString: string, containerId: string, convertIntegersToFloat: boolean): Observable<string> {
        return this.http.post(this.baseUrl + '/formula-string',
            JSON.stringify({ displayFormulaString, containerId, convertIntegersToFloat }),
            {
                responseType: 'text'
            }
        );
    }

    getFriendlyFormulaString(sqlFormulaString: string, containerId: string): Observable<string> {
        return this.http.post(this.baseUrl + '/friendly-string',
            JSON.stringify({ sqlFormulaString, containerId }),
            {
                responseType: 'text'
            }
        );
    }

    getAuditSQL(scopeProcessId: string, processId: string, seriesId: number, periodId: number, rowCount: number, filter: AuditGridFilterCriteria): Observable<CoreResponse<Record<string, string>>> {
        return this.http.post<CoreResponse<Record<string, string>>>(this.baseUrl + `/audit-sql/${scopeProcessId}/${processId}/${seriesId}/${periodId}/${rowCount}`, filter);
    }

    getAuditDataRecords(scopeProcessId: string, processId: string, seriesId: number, periodId: number, filter: AuditGridFilterCriteria): Observable<CoreResponse<AuditDetail>> {
        return this.http.post<any>(this.baseUrl + `/audit-data-record/${scopeProcessId}/${processId}/${seriesId}/${periodId}`, JSON.stringify(filter));
    }

    cancelAudit(): Observable<CoreResponse<boolean>>{
        return this.http.post<CoreResponse<boolean>>(this.baseUrl + `/audit-cancel`, null);
    }

    batchChangeVersions(ruleVersions: RuleVersionBatchChanges): Observable<CoreResponse<RuleVersionAuditResults>> {
        return this.http.post<any>(this.baseUrl + '/batch-changes', JSON.stringify(ruleVersions));
    }

    getDownstreamRules(ruleId: string, periodId: number){
        return this.http.get<CoreResponse<Container[]>>(this.baseUrl + `/downstream-rules/${ruleId}/${periodId}`).pipe(timeout(60000));
    }

    convertSegment(functionId: number){
        return this.http.get<CoreResponse<Container>>(this.baseUrl + `/convert-segment/${functionId}`);
    }

    refreshCacheIfLevelsStaleFromProcessing(): void {
        this.processLogService.getLevelsStale(this.helperService.dateToUTCISOString(this.created)).subscribe(isStale => {
            if (isStale) {
                this.cacheAndLocalBuildingBlockRefresh();
            }
        });
    }

    getGearTypeArray(): GearType[] {
        return [
            new SegmentGearType(),
            new AssignGear(),
            new PeriodFilterGear(),
            new AccountNormalizeGear(),
            new EarnDateGear(),
            new CounterGear(),
            new ContextGear(),
            new FormulaGear(),
            new FilterGear(),
            new JoinGear(),
            new ConsolidateGear(),
            new HierarchyGear(),
            new UnionGear(),
            new UpdateXactionGear(),
            new AIContextGear(),
            new AITextGenerationGear(),
            new AIScoreGear(),
            new AIFilterGear(),
            new AISummaryGear(),
            new AIFileRetrievalGear(),
        ];
    }
}

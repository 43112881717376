import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { Datasource } from '../models/datasource';

@Injectable()
export class DatasourceService {
    private baseUrl = environment.apiEndpoint + '/datasource';

    constructor(private http: HttpClient) {
    }

    getAllDatasources(): Observable<Datasource[]> {
        return this.http.get<Datasource[]>(this.baseUrl);
    }

    getDataSourceMappingId(dataSourceId: number, mappingName: string): Observable<number> {
        return this.http.get<number>(this.baseUrl + '/' + dataSourceId + '/' + mappingName);
    }

    getDefaultDataSourceMappingId(dataSourceId: number): Observable<number> {
        return this.http.get<number>(this.baseUrl + '/' + dataSourceId);
    }

    getDataSourceByName(dataSourceName: string): Observable<number> {
        return this.http.get<number>(this.baseUrl + '/ByName/' + dataSourceName);
    }

    checkDataSourceDependants(datasourceId: number, periodId: number, seriesId: number): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/dependants/${datasourceId}/${periodId}/${seriesId}`);
    }

    insertDatasource(datasource: Datasource): Observable<Datasource> {
        return this.http.post<Datasource>(this.baseUrl, datasource);
    }

    updateDatasource(datasource: Datasource): Observable<number> {
        return this.http.put<number>(this.baseUrl, datasource);
    }

    deleteDatasource(id: number): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/' + id);
    }

    checkDeleteDatasource(id: number): Observable<string[]> {
        return this.http.get<string[]>(this.baseUrl + '/' + id + '/rule-dependents');
    }
}

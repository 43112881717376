import { HelperService } from '../../services/helper.service';

export class EmailContext {
    token: string;
    id: number;
    selectedSellers: string;
    subject: string;
    body: string;
    dashboardId: number;
    reportId: number;
    beginDate: Date;
    endDate: Date;
    includeSubs: number;
    attachmentName: string;
    sendZeroes: boolean;

    constructor(selectedSellers: string, subject: string, body: string, dashboardId?: number, reportId?: number, beginDate?: Date, endDate?: Date,
         includeSubs?: number, attachmentName?: string, sendZeroes?: boolean) {
        const helperService: HelperService = new HelperService();

        this.selectedSellers = selectedSellers;
        this.subject = subject;
        this.body = body;
        this.dashboardId = dashboardId;
        this.reportId = reportId;
        this.beginDate = beginDate ? helperService.getUTCMidnight(beginDate) : null;
        this.endDate = endDate ? helperService.getUTCEndOfDay(endDate) : null;
        this.includeSubs = includeSubs;
        this.attachmentName = attachmentName;
        this.sendZeroes = sendZeroes;
    }
}

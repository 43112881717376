import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxListModule } from 'devextreme-angular/ui/list';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-panel',
  templateUrl: 'user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent {
  @Output() menuToggle = new EventEmitter<boolean>();

  @Input() menuItems: any;

  @Input() menuMode: string;

  showMenu: boolean = false;

  constructor(private authService: AuthService) { }

  toggleMenu = () => {
      this.menuToggle.emit();
  };

  toggleShowMenu() {
    this.showMenu = !this.showMenu;
  }

  onHidden($event) {
    this.showMenu = false;
  }
}

@NgModule({
  imports: [
    DxListModule,
    DxContextMenuModule,
    CommonModule
  ],
  declarations: [ UserPanelComponent ],
  exports: [ UserPanelComponent ]
})
export class UserPanelModule { }

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, NavigationEnd, Router } from '@angular/router';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { forkJoin, of } from 'rxjs';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { navigation } from '../../app-navigation';
import { HeaderModule } from '../../shared/components/header/header.component';
import { SideNavigationMenuModule } from '../../shared/components/side-navigation-menu/side-navigation-menu.component';
import { menuItemNames } from '../../shared/constants/constants';
import { CoreBotInteractionTriggerType, CoreFeature, EnumBucketClass, EnumBucketType, EnumSettingClassId, EnumUserGroup } from '../../shared/constants/enums';
import { Bucket } from '../../shared/models/bucket';
import { BucketClass } from 'src/app/shared/models/bucket-class';
import { AuthService } from '../../shared/services/auth.service';
import { BucketService } from '../../shared/services/bucket.service';
import { DashboardService } from '../../shared/services/dashboard.service';
import { PeriodService } from '../../shared/services/period.service';
import { ReportService } from '../../shared/services/report.service';
import { ScreenService } from '../../shared/services/screen.service';
import { SellerService } from '../../shared/services/seller.service';
import { SettingService } from '../../shared/services/setting.service';
import { CustomPageService } from '../../shared/services/custom-page.service';
import { HelperService } from '../../shared/services/helper.service';
import { AnalyticService } from '../../shared/services/analytic.service';
import { PermissionService } from '../../shared/services/permission.service';
import { Seller } from 'src/app/shared/models/seller';
import { SideNavigationMenuComponent } from '../../shared/components/side-navigation-menu/side-navigation-menu.component';
import { catchError } from 'rxjs/operators';
import { CoreBotWidgetModule } from 'src/app/shared/components/corebot/corebot-shared/corebot-widget/corebot-widget.component';
import { CoreBotInteractionService } from 'src/app/shared/services/core-bot-interaction-service';

@Component({
    selector: 'app-side-nav-outer-toolbar',
    templateUrl: './side-nav-outer-toolbar.component.html',
    styleUrls: ['./side-nav-outer-toolbar.component.scss'],
    providers: [ReportService, DashboardService, AuthService, SellerService, BucketService]
})

export class SideNavOuterToolbarComponent implements OnInit {
    @ViewChild(SideNavigationMenuComponent, { static: false })
    sideNavigationMenu: SideNavigationMenuComponent;

    @Input() title: string;
    @Input() siteThemeConfig: any;

    @Output() parameterSelectionChanged = new EventEmitter();

    menuItems = navigation;
    buckets: Bucket[];
    selectedRoute = '';
    selfSeller: Seller;

    menuOpened: boolean;

    menuMode = 'shrink';
    menuRevealMode = 'expand';
    minMenuSize = 0;
    shaderEnabled = false;
    isImplementer = false;
    isAdministrator = false;
    animationDuration: number = 400;
    hasShaderBeenEnabled: boolean = false;
    hasAdminView: boolean = false;
    nonDatedMenuLoaded: boolean = false;
    datedMenuLoaded: boolean = false;

    isUsingCoreBotBeta = false;
    isBotMenuItemVisible = false;
    currentUrl: string = '';

    tabOrder = [
        menuItemNames.home,
        menuItemNames.favorites,
        menuItemNames.accountManagement,
        menuItemNames.processing,
        menuItemNames.fileProcessing,
        menuItemNames.reports,
        menuItemNames.dashboards,
        menuItemNames.analytics,
        menuItemNames.email,
        menuItemNames.disputeManagement,
        menuItemNames.workflow,
        menuItemNames.plans,
        menuItemNames.setup,
    ];

    constructor(private screen: ScreenService,
        private router: Router,
        private reportService: ReportService,
        private dashboardService: DashboardService,
        private analyticService: AnalyticService,
        private authService: AuthService,
        private sellerService: SellerService,
        private periodService: PeriodService,
        private bucketService: BucketService,
        private settingService: SettingService,
        private appElementsService: AppElementsService,
        private customPageService: CustomPageService,
        private helperService: HelperService,
        private botInteractionService: CoreBotInteractionService,
        private permissionService: PermissionService) {

    }

    ngOnInit() {
        this.appElementsService.sideNavOuterToolbar = this;

        const selectedPath = sessionStorage.getItem('selectedMenuItem');
        if (this.router.url !== selectedPath) {
            sessionStorage.setItem('selectedMenuItem', this.router.url);
        }

        this.sellerService.getHasAdminView().subscribe(hasAdminView => {
            this.hasAdminView = hasAdminView;
            this.setNavigationNonDatedItems();
            this.setNavigationDatedItems();
        });

        this.menuOpened = this.screen.sizes['screen-large'];

        this.router.events.subscribe(val => {
            if (val instanceof NavigationStart && val.navigationTrigger === 'popstate') {
                // This will keep the selected item updated in the left nav on back/forward browser actions
                const selectedId = val.url.startsWith('/') ? val.url.substring(1) : val.url;
                this.sideNavigationMenu.updateSelectionById(selectedId);
                let selectedMenuItem = null;
                this.menuItems.forEach(x => {
                    if (x.path === selectedId) {
                        selectedMenuItem = x;
                    } else {
                        x.items.forEach(y => {
                            if (y.path === selectedId) {
                                selectedMenuItem = y;
                            }
                        });
                    }
                });
                if (selectedMenuItem !== null) {
                    this.setMenuItemFavoriteIcon(selectedMenuItem);
                }
            }
            if (val instanceof NavigationEnd) {
                const curUrl = val.urlAfterRedirects.split('?')[0];
                const doesTabExist = this.menuItems.some(tab => (tab.items.some(child => (this.helperService.isPathEqual(curUrl, child.path))
                  || child.items?.some(grandchild => (this.helperService.isPathEqual(curUrl, grandchild.path)))) || this.helperService.isPathEqual(curUrl, tab.path)));
                this.selectedRoute = doesTabExist ? curUrl : null;
                if (val.url !== '/login-form' && val.url !== '/forgot-username' && val.url !== '/forgot-password' && val.url !== this.currentUrl){
                    this.currentUrl = val.url;
                    if (this.isBotMenuItemVisible){
                        this.botInteractionService.checkForCoreBotInteractions(window.location.pathname);
                    }
                }
            }
        });

        this.bucketService.getFavoriteEvent().subscribe(() => {
            this.setNavigationDatedItems();
        });

        this.screen.changed.subscribe(() => this.updateDrawer());
        this.updateDrawer();

        this.sellerService.getSellerWithSubordinateSetting(this.authService.getUserFromToken()).subscribe(seller => {
            this.settingService.getBoolSetting(EnumSettingClassId.CoreBotBetaTesting).subscribe(res => {
                this.isUsingCoreBotBeta = res;
                const hasBotAccess = this.permissionService.checkCurrentUserPermission(CoreFeature.UseEverybodyCoreBotTools.toString());
                if (seller.userGroupId === EnumUserGroup.Implementer
                        || this.authService.getUserFromToken().toLocaleLowerCase().includes('corehome')
                        || (this.isUsingCoreBotBeta === true && hasBotAccess)){
                    this.isBotMenuItemVisible = true;
                    const val = {url: window.location.pathname};
                    this.botInteractionService.checkForCoreBotInteractions(val.url);
                }
            });
        });
    }

    updateDrawer() {
        const isXSmall = this.screen.sizes['screen-x-small'];
        const isLarge = this.screen.sizes['screen-large'];
        this.menuMode = isLarge ? 'shrink' : 'overlap';
        this.menuRevealMode = isXSmall ? 'slide' : 'expand';
        this.minMenuSize = isXSmall ? 0 : 60;
        this.shaderEnabled = !isLarge;
        if (this.shaderEnabled) {
            this.hasShaderBeenEnabled = true;
        }
    }

    navigationChanged(event) {
        const path = event.itemData.path;
        const pointerEvent = event.event;

        if (path) {
            if (event.node.selected) {
                pointerEvent.preventDefault();
            } else {
                this.router.navigate([path]);
            }
        } else {
            if (!this.menuOpened && event.node.expanded && event.node.items?.length > 0) {
                this.menuOpened = true;
                this.appElementsService.provideSideNavOpenState(this.menuOpened);
            }
            pointerEvent.preventDefault();
        }
        if (event.itemData != null) {
            this.setMenuItemFavoriteIcon(event.itemData);
        }
    }

    setMenuItemFavoriteIcon(menuItem) { // this is hacky
        if (menuItem?.icon != null) {
            if (!menuItem.path) {
                return;
            }

            const flatArray = this.menuItems
                .reduce((accumulator, value) => accumulator.concat(value.items), [])
                .concat(this.menuItems.filter(m => m.topLevel));

            // Remove blue from items not in folders
            flatArray
                .filter(m => (!m.topLevel && m.icon.startsWith('blue'))
                    || (m.topLevel && m.icon.startsWith('blue')))
                .forEach(m => m.icon = m.icon.replace('blue', ''));
            // Remove blue from items in folders
            flatArray
                .filter(m => m.icon === 'folder')
                .forEach(m => {
                    m.items.filter(n => n.icon.startsWith('blue'))
                        .forEach(n => n.icon = n.icon.replace('blue', ''));
                });

            if (menuItem.parent != null && menuItem.parent.text === EnumBucketClass[EnumBucketClass.Favorites]) {
                if (!menuItem.parent.icon.startsWith('blue')) {
                    menuItem.parent.icon = 'blue' + menuItem.parent.icon;
                    menuItem.icon = 'blue' + menuItem.icon;
                }
            } else {
                if ((menuItem.icon.endsWith('star') || menuItem.icon.endsWith('staroutline'))) {
                    menuItem.icon = 'blue' + menuItem.icon;
                }
            }
        }
    }

    onFavoriteMouseEnter(data) {
        if (data.icon?.includes('star')) {
            this.switchStarIcon(data, true);
        }
    }

    onFavoriteMouseLeave(data) {
        if (data.icon?.includes('star')) {
            this.switchStarIcon(data, false);
        }
    }

    onFavoriteClicked(path: string) {
        const pathElements = path.split('/');
        const id = pathElements[pathElements.length - 1];
        if (id) {
            const existingBucket = this.buckets.filter(bucket =>
                bucket.itemId === parseInt(pathElements[2], 10) && bucket.bucketClassId === 1
                && ((pathElements[1] === EnumBucketType[EnumBucketType.Report].toLowerCase()
                    && bucket.type === EnumBucketType.Report)
                    || (pathElements[1] === EnumBucketType[EnumBucketType.Dashboard].toLowerCase()
                        && bucket.type === EnumBucketType.Dashboard)
                    || (pathElements[1] === EnumBucketType[EnumBucketType.Analytic].toLowerCase()
                        && bucket.type === EnumBucketType.Analytic)))[0];

            if (existingBucket) {
                this.disableMenuItem(path, true);
                this.bucketService.deleteFavoriteBucket(existingBucket).subscribe(() => {
                    this.removeFavorite(path);
                    const index = this.buckets.indexOf(existingBucket);
                    if (index >= 0) {
                        this.buckets.splice(index, 1);
                    }
                    this.disableMenuItem(path, false);
                });
            } else {
                const newBucket = new Bucket();
                if (pathElements[1] === EnumBucketType[EnumBucketType.Report].toLowerCase()) {
                    newBucket.type = EnumBucketType.Report;
                } else if (pathElements[1] === EnumBucketType[EnumBucketType.Dashboard].toLowerCase()) {
                    newBucket.type = EnumBucketType.Dashboard;
                } else if (pathElements[1] === EnumBucketType[EnumBucketType.Analytic].toLowerCase()) {
                    newBucket.type = EnumBucketType.Analytic;
                }
                if (newBucket.type) {
                    newBucket.itemId = parseInt(pathElements[2], 10);
                    newBucket.bucketClassId = EnumBucketClass.Favorites;
                    newBucket.sellerId = this.selfSeller.id;

                    this.disableMenuItem(path, true);
                    this.bucketService.insertBucket(newBucket).subscribe(bucket => {
                        this.addFavorite(path);
                        this.buckets.push(bucket);
                        this.disableMenuItem(path, false);
                    });
                }
            }
        }
    }

    disableMenuItem(path: string, enabled: boolean) {
        this.menuItems.forEach(m => {
            const items = m.items.filter(i => i.path === path);
            if (items != null) {
                items.forEach(i => i.disabled = enabled);
                return;
            }
        });
    }

    onParameterSelectionChanged() {
        this.parameterSelectionChanged.emit();
        if (!this.hasAdminView) {
            this.setNavigationDatedItems();
        }
    }

    setMenuSortOrder(): void {
        this.menuItems.forEach(item => {
            item.sortOrder = this.tabOrder.indexOf(item.text) < 0 ? this.tabOrder.length : this.tabOrder.indexOf(item.text);
        });
    }

    setNavigationNonDatedItems() {
        this.menuItems = [this.menuItems[0]];
        // Will remove this and coreHome checks once these pages are live for all admins
        this.sellerService.getSeller(this.authService.getUserFromToken()).subscribe(seller => {
            if (seller.userGroupId === EnumUserGroup.Administrator) {
                this.isAdministrator = true;
            }
        });
        forkJoin([
            this.settingService.getUISettings(),
            this.customPageService.getCustomPageNavItems(),
            this.permissionService.getIsImplementer()
        ]).subscribe(([settings, customNavItems, isImplementer]) => {
            this.isImplementer = isImplementer;
            const activePath = sessionStorage.getItem('selectedMenuItem');
            const urlSegments = activePath.split('/');

            if (settings[EnumSettingClassId.ReportsTabEnabled] === 'True'
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessReports.toString())) {
                const reportMenu = {
                    text: menuItemNames.reports,
                    path: this.hasAdminView ? '/pages/report' : '',
                    icon: 'file',
                    parent: null,
                    items: [],
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    expanded: (urlSegments.length > 3 && urlSegments[1] === 'pages'
                        && urlSegments[2] === EnumBucketType[EnumBucketType.Report].toLowerCase()
                        && !urlSegments[3].endsWith('f'))
                };
                this.menuItems.push(reportMenu);
            }
            if (settings[EnumSettingClassId.DashboardTabEnabled] === 'True'
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessDashboards.toString())) {
                const dashboardMenu = {
                    text: menuItemNames.dashboards,
                    path: this.hasAdminView ? '/pages/dashboard' : '',
                    icon: 'chart',
                    items: [],
                    parent: null,
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    expanded: (urlSegments.length > 3 && urlSegments[1] === 'pages'
                        && urlSegments[2] === EnumBucketType[EnumBucketType.Dashboard].toLowerCase()
                        && !urlSegments[3].endsWith('f'))
                };
                this.menuItems.push(dashboardMenu);
            }
            if ((this.isImplementer || settings[EnumSettingClassId.AnalyticsPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessAnalytics.toString())) {
                const analyticMenu = {
                    text: menuItemNames.analytics,
                    path: this.hasAdminView ? '/pages/analytic' : '',
                    icon: 'mergecells',
                    items: [],
                    parent: null,
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    expanded: (urlSegments.length > 3 && urlSegments[1] === 'pages'
                        && urlSegments[2] === EnumBucketType[EnumBucketType.Analytic].toLowerCase()
                        && !urlSegments[3].endsWith('f'))
                };
                this.menuItems.push(analyticMenu);
            }
            if (settings[EnumSettingClassId.WorkFlowEnabled] === 'True') {
                const bucketMenu = {
                    text: menuItemNames.workflow,
                    path: '/pages/workflow',
                    parent: null,
                    icon: 'selectall',
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                this.menuItems.push(bucketMenu);
            }
            if (settings[EnumSettingClassId.WhatIfEnabled] === 'True') {
                const whatIfMenu = {
                    text: menuItemNames.whatIf,
                    path: '/bingham/what-if',
                    icon: 'edit',
                    parent: null,
                    items: [],
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0
                };
                this.menuItems.push(whatIfMenu);
            }
            if (settings[EnumSettingClassId.DisputeManagementTabEnabled] === 'True') {
                const disputeMenu = {
                    text: menuItemNames.disputeManagement,
                    path: '/dispute-management',
                    parent: null,
                    icon: 'rename',
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []

                };
                this.menuItems.push(disputeMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.EmailPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessEmail.toString())) {
                const emailMenu = {
                    text: menuItemNames.email,
                    path: '/email',
                    parent: null,
                    icon: 'email',
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []

                };
                this.menuItems.push(emailMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.ProcessingPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessProcessing.toString())) {
                const processingMenu = {
                    text: menuItemNames.processing,
                    path: '/processing',
                    parent: null,
                    icon: 'money',
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                this.menuItems.push(processingMenu);
            }

            if ((settings[EnumSettingClassId.EnableAIRules] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.UseAdminCoreBotTools.toString())){
                    const fileManager = {
                        text: menuItemNames.fileProcessing,
                        path: '/file-processing',
                        parent: null,
                        icon: 'activefolder',
                        disabled: false,
                        topLevel: true,
                        bucketFolder: false,
                        sortOrder: 0,
                        items: []
                    };
                    this.menuItems.push(fileManager);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.AccountManagementPageEnabled] === 'True')
               && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessAccounts.toString())) {
               const accountMenu = {
                   text: menuItemNames.accountManagement,
                   path: '/account-management',
                   parent: null,
                   icon: 'group',
                   disabled: false,
                   topLevel: true,
                   bucketFolder: false,
                   sortOrder: 0,
                   items: []
               };
               this.menuItems.push(accountMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.PlansPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessSegments.toString())) {
                const plansMenu = {
                    text: menuItemNames.plans,
                    path: '/plans',
                    parent: null,
                    icon: 'comment',
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                this.menuItems.push(plansMenu);
            }

            const setupSection = {
                text: menuItemNames.setup,
                path: '',
                parent: null,
                icon: 'preferences',
                disabled: false,
                topLevel: true,
                bucketFolder: false,
                sortOrder: 0,
                items: []
            };

            if ((this.isImplementer || settings[EnumSettingClassId.AccountAttributesPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessAccountAttributes.toString())) {
                const attributesMenu = {
                    text: menuItemNames.accountAttributes,
                    path: '/account-attributes',
                    parent: setupSection,
                    icon: 'card',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(attributesMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.EtlPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessEtl.toString())) {
                const etlMenu = {
                    text: menuItemNames.etl,
                    path: '/etl',
                    parent: setupSection,
                    icon: 'card',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(etlMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.FieldsPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessFields.toString())) {
                const fieldsMenu = {
                    text: menuItemNames.fields,
                    path: '/fields',
                    parent: setupSection,
                    icon: 'fields',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(fieldsMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.TagGroupsPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessTagGroups.toString())) {
                const tagGroupsMenu = {
                    text: menuItemNames.tagGroups,
                    path: '/tag-groups',
                    parent: setupSection,
                    icon: 'splitcells',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(tagGroupsMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.ProcessLogViewerPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.ViewLogs.toString())) {
                const processLogMenu = {
                    text: menuItemNames.processLogViewer,
                    path: '/process-log-viewer',
                    parent: setupSection,
                    icon: 'find',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(processLogMenu);
            }

            if ((this.isImplementer || settings[EnumSettingClassId.SystemPageEnabled] === 'True')
                && this.permissionService.checkCurrentUserPermission(CoreFeature.AccessSystemSettings.toString())) {
                const systemMenu = {
                    text: menuItemNames.system,
                    path: '/system',
                    parent: setupSection,
                    icon: 'more',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(systemMenu);
            }

            if (settings[EnumSettingClassId.BuildComparisonDatabase]
                && this.permissionService.checkCurrentUserPermission(CoreFeature.ViewBuildDiffChanges.toString())) {
                const buildDiff = {
                    text: menuItemNames.buildDiff,
                    path: '/build-diff',
                    parent: setupSection,
                    icon: 'copy',
                    disabled: false,
                    topLevel: false,
                    bucketFolder: false,
                    sortOrder: 0,
                    items: []
                };
                setupSection.items.push(buildDiff);
            }

            if (setupSection.items.length > 0) {
                this.menuItems.push(setupSection);
            }

            this.menuItems.push(...customNavItems);
            this.setMenuSortOrder();
            this.nonDatedMenuLoaded = true;
        });
    }

    setNavigationDatedItems() {
        const username = this.authService.getUserFromToken();
        if (!username) {
            return;
        }

        this.sellerService.getSellerWithSubordinateSetting(username).subscribe(seller => {
            this.selfSeller = seller;
            this.periodService.setStorageDates(seller.id, seller?.subordinates?.map(x => x?.id), username, this.hasAdminView).then(() => {
                const beginDate = new Date(localStorage.getItem('beginDate'));
                const endDate = new Date(localStorage.getItem('endDate'));

                let items = [];
                items = this.menuItems;

                forkJoin([
                    this.reportService.getReportMappingBySeller(seller.id, beginDate, endDate).pipe(catchError(err => of([]))),
                    this.dashboardService.getDashboardMappingBySeller(seller.id, beginDate, endDate),
                    this.analyticService.getAnalyticMappingBySeller(seller.id, beginDate, endDate),
                    this.bucketService.getBucketClassesBySeller(seller.id),
                    this.bucketService.getAllFolderBuckets(),
                    this.settingService.getBoolSetting(EnumSettingClassId.EnableNavBucketFolders)
                ]).subscribe(([reports, dashboards, analytics, bucketClasses, folderBuckets, isNavBucketFoldersEnabled]) => {
                    // TODO: remove filter if more classes than favorites...will need to filter out 'HomePage' (f.name != 'HomePage'))
                    const bucketClass = bucketClasses.filter(f => f.id === EnumBucketClass.Favorites)[0];

                    let selectedMenuItem;
                    const selectedPath = sessionStorage.getItem('selectedMenuItem');
                    const urlSegments = selectedPath.split('/');

                    reports.sort((a, b) => a.name.localeCompare(b.name));
                    dashboards.sort((a, b) => a.name.localeCompare(b.name));
                    analytics.sort((a, b) => a.name.localeCompare(b.name));

                    if (bucketClass) {
                        this.buckets = bucketClass.buckets;
                        let addFavorite = false;
                        let classItem = items
                            .filter(i => i.text === EnumBucketClass[EnumBucketClass.Favorites])[0];

                        if (classItem) {
                            classItem.items = [];
                        } else {
                            classItem = {
                                text: bucketClass.name,
                                path: '',
                                icon: 'star',
                                parent: null,
                                items: [],
                                disabled: false,
                                topLevel: true,
                                sortOrder: 0,
                                expanded: (urlSegments.length > 3 && urlSegments[1] === 'pages' && urlSegments[3].endsWith('f'))
                            };
                            addFavorite = true;
                        }

                        for (const bucket of this.buckets) {
                            const path = this.getNavigationPath(bucket.type);
                            let fullPath;
                            let text;
                            if (bucket.type === EnumBucketType.Report) {
                                const report = reports.filter(r => r.id === bucket.itemId)[0];
                                if (report) {
                                    fullPath = path + report.id + 'f';
                                    text = report.name;
                                }
                            } else if (bucket.type === EnumBucketType.Dashboard) {
                                const dashboard = dashboards.filter(d => d.id === bucket.itemId)[0];
                                if (dashboard) {
                                    fullPath = path + dashboard.id + 'f';
                                    text = dashboard.name;
                                }
                            } else if (bucket.type === EnumBucketType.Analytic) {
                                const analytic = analytics.filter(d => d.id === bucket.itemId)[0];
                                if (analytic) {
                                    fullPath = path + analytic.id + 'f';
                                    text = analytic.name;
                                }
                            }

                            if (fullPath && text) {
                                const item = {
                                    text,
                                    path: fullPath,
                                    icon: 'star',
                                    parent: classItem,
                                    disabled: false,
                                    topLevel: false,
                                    sortOrder: 0
                                };
                                classItem.items.push(item);

                                if (`/${fullPath}` === selectedPath) {
                                    selectedMenuItem = item;
                                }
                            }
                        }

                        if (classItem.items.length > 0) {
                            if (addFavorite) {
                                classItem.items.sort((a, b) => a.text.localeCompare(b.text));
                                items.splice(1, 0, classItem);
                            }
                        } else {
                            if (!addFavorite) {
                                const index = items.indexOf(classItem);
                                if (index > 0) {
                                    items.splice(index, 1);
                                }
                            }
                        }
                    }

                    if (!this.hasAdminView) {
                        const reportMenu = items.filter(m => m.text === menuItemNames.reports)[0];
                        if (reportMenu) {
                            reportMenu.items = [];

                            if (isNavBucketFoldersEnabled) {
                                this.getBucketFolderMenuItems(reportMenu, EnumBucketType.Report, EnumBucketClass.ReportRootFolder, bucketClasses, folderBuckets,
                                    reports, urlSegments);
                            } else {
                                for (const report of reports) {
                                    const item = this.createNavigationItem(EnumBucketType.Report, report.id, report.name, reportMenu);
                                    reportMenu.items.push(item);
                                }
                            }
                            selectedMenuItem = this.checkForSelectedMenuItem(reportMenu.items, selectedPath, selectedMenuItem);

                            // Create new report link
                            if (this.isImplementer) {
                                const createReportItem = {
                                    text: 'Create Report',
                                    path: this.getNavigationPath(EnumBucketType.Report) + 'new',
                                    icon: 'plus',
                                    parent: { reportMenu },
                                    disabled: false,
                                    topLevel: false,
                                    sortOrder: 0
                                };
                                reportMenu.items.push(createReportItem);
                            }
                        }

                        const dashboardMenu = items.filter(m => m.text === menuItemNames.dashboards)[0];
                        if (dashboardMenu) {
                            dashboardMenu.items = [];

                            if (isNavBucketFoldersEnabled) {
                                this.getBucketFolderMenuItems(dashboardMenu, EnumBucketType.Dashboard, EnumBucketClass.DashboardRootFolder, bucketClasses, folderBuckets,
                                    dashboards, urlSegments);
                            } else {
                                for (const dashboard of dashboards) {
                                    const item = this.createNavigationItem(EnumBucketType.Dashboard, dashboard.id, dashboard.name, dashboardMenu);
                                    dashboardMenu.items.push(item);
                                }
                            }
                            selectedMenuItem = this.checkForSelectedMenuItem(dashboardMenu.items, selectedPath, selectedMenuItem);

                            // Create new dashboard link - never occurs with admin view addition
                            if (this.isImplementer) {
                                const createDashboardItem = {
                                    text: menuItemNames.createDashboard,
                                    path: this.getNavigationPath(EnumBucketType.Dashboard) + 'new',
                                    icon: 'plus',
                                    parent: { dashboardMenu },
                                    disabled: false,
                                    topLevel: false,
                                    sortOrder: 0
                                };
                                dashboardMenu.items.push(createDashboardItem);
                            }
                        }

                        const analyticMenu = items.filter(m => m.text === menuItemNames.analytics)[0];
                        if (analyticMenu) {
                            analyticMenu.items = [];

                            if (isNavBucketFoldersEnabled) {
                                this.getBucketFolderMenuItems(analyticMenu, EnumBucketType.Analytic, EnumBucketClass.AnalyticRootFolder, bucketClasses, folderBuckets,
                                    analytics, urlSegments);
                            } else {
                                for (const analytic of analytics) {
                                    const item = this.createNavigationItem(EnumBucketType.Analytic, analytic.id, analytic.name, analyticMenu);
                                    analyticMenu.items.push(item);
                                }
                            }
                            selectedMenuItem = this.checkForSelectedMenuItem(analyticMenu.items, selectedPath, selectedMenuItem);

                        }
                    }

                    this.menuItems = []; // force refresh

                    items.forEach(item => {
                        this.menuItems.push(item);
                    });

                    this.setMenuSortOrder();
                    this.datedMenuLoaded = true;

                    this.initItemSelection();

                    if (selectedMenuItem !== undefined) {
                        this.setMenuItemFavoriteIcon(selectedMenuItem);
                    }
                });
            });
        });
    }

    createNavigationItem(type: EnumBucketType, id: number, name: string, parent: any, sortOrder: number = 0, bucketFolder: boolean = false): any {

        const fullPath = this.getNavigationPath(type) + id;
        const bucket = this.buckets.filter(b => b.itemId === id && b.type === type)[0];
        const icon = bucket ? 'star' : 'staroutline';

        return {
            text: name,
            path: fullPath,
            icon,
            parent: { parent },
            disabled: false,
            topLevel: false,
            bucketFolder,
            sortOrder
        };
    }

    createNavigationFolderItem(name: string, parent: any, sortOrder: number): any {
        return {
            text: name,
            path: '',
            icon: 'folder',
            items: [],
            parent,
            disabled: false,
            topLevel: true,
            bucketFolder: true,
            sortOrder,
            expanded: false
        };
    }

    getBucketFolderMenuItems(menuItem: any, bucketType: EnumBucketType, bucketClassId: EnumBucketClass, bucketClasses: BucketClass[], folderBuckets: Bucket[],
        items: any[], urlSegments: any[]): void {
        let folderSortOrder = 1;
        bucketClasses
          .filter(bc => bc.id === bucketClassId || bc.folderType === bucketType)
          .sort((a, b) => a.name.localeCompare(b.name))
          .forEach(bc => {
            const isRoot = bc.id === bucketClassId;
            let parent;
            if (!isRoot) {
                parent = this.createNavigationFolderItem(bc.name, menuItem, folderSortOrder++);
            } else {
                parent = menuItem;
            }
            folderBuckets.filter(x => x.type === bucketType && x.bucketClassId === bc.id).forEach(b => {
                const item = items.find(x => x.id === b.itemId);
                if (item) {
                    const navItem = this.createNavigationItem(bucketType, item.id, item.name, parent, b.sequenceNo + 100, !isRoot);
                    if (this.isFolderItemSelected(parent, urlSegments, item.id)) {
                        parent.expanded = true;
                    }
                    parent.items.push(navItem);
                }
            });
            if (isRoot) {
                for (const item of items) {
                    if (!folderBuckets.find(fb => fb.type === bucketType && fb.itemId === item.id)) {
                        const navItem = this.createNavigationItem(bucketType, item.id, item.name, menuItem, 200);
                        menuItem.items.push(navItem);
                    }
                }
            }
            if (!isRoot && parent.items.length > 0) {
                menuItem.items.push(parent);
            }
        });
    }

    isFolderItemSelected(parent: any, urlSegments: any[], itemId: number): boolean {
        if (parent?.parent?.expanded === true) {
            const selectedItemId = parseInt(urlSegments[3], 10);
            if (selectedItemId === itemId) {
                return true;
            }
        }
        return false;
    }

    checkForSelectedMenuItem(items: any[], selectedPath: string, selectedMenuItem: any): any {
        items.forEach(rootItem => {
            if (`/${rootItem.path}` === selectedPath) {
                selectedMenuItem = rootItem;
            }
            if (rootItem.items && rootItem.items.length > 0) {
                rootItem.items.forEach(childItem => {
                    if (`/${childItem.path}` === selectedPath) {
                        selectedMenuItem = childItem;
                    }
                });
            }
        });
        return selectedMenuItem;
    }

    insertNavigationItem(type: EnumBucketType, id: number, name: string, isSelected: boolean): void {
        const newMenuItems = [];
        this.menuItems.forEach(x => newMenuItems.push(x));

        const menuItemName = this.getMenuItemName(type);
        const parent = newMenuItems.filter(x => x.text === menuItemName)[0];
        const newNavigationItem = this.createNavigationItem(type, id, name, parent);

        if (isSelected) {
            sessionStorage.setItem('selectedMenuItem', newNavigationItem.path);
        }

        parent.items.push(newNavigationItem);
        parent.items = this.sortNavigationItems(parent.items);

        this.menuItems = newMenuItems;

        this.initItemSelection();
        this.setMenuItemFavoriteIcon(newNavigationItem);
    }

    renameNavigationItem(type: EnumBucketType, id: number, newName: string): void {
        const newMenuItems = [];
        this.menuItems.forEach(x => newMenuItems.push(x));

        const path = this.getNavigationPath(type);

        for (let f = 0; f <= 1; f++) {
            const menuItemName = f ? menuItemNames.favorites : this.getMenuItemName(type);
            const fullPath = `${path}${id}${f ? 'f' : ''}`;

            const parentNavigationItem = newMenuItems.filter(x => x.text === menuItemName)[0];
            if (!parentNavigationItem) {
                continue;
            }
            const navigationItem = parentNavigationItem.items.filter(x => x.path === fullPath)[0];
            if (navigationItem) {
                navigationItem.text = newName;
            }

            parentNavigationItem.items = this.sortNavigationItems(parentNavigationItem.items);
        }

        this.menuItems = newMenuItems;
    }

    removeNavigationItem(type: EnumBucketType, id: number): void {
        const newMenuItems = [];
        this.menuItems.forEach(x => newMenuItems.push(x));

        const path = this.getNavigationPath(type);

        for (let f = 0; f <= 1; f++) {
            const menuItemName = f ? menuItemNames.favorites :  this.getMenuItemName(type);
            const fullPath = `${path}${id}${f ? 'f' : ''}`;

            const parentNavigationItem = newMenuItems.filter(x => x.text === menuItemName)[0];
            if (parentNavigationItem) {
                parentNavigationItem.items = parentNavigationItem.items.filter(x => x.path !== fullPath);
            }
        }

        this.menuItems = newMenuItems;
    }

    removeCustomPageByDisputeId(disputeId: number): void {
        const newMenuItems = this.menuItems.filter(x => x.path !== `custom/${disputeId}`);
        this.menuItems = newMenuItems;
    }

    setActiveMenuItem(type: EnumBucketType, id: string): void {
        const newMenuItems = [];
        this.menuItems.forEach(x => newMenuItems.push(x));

        const menuItemName = this.getMenuItemName(type);
        const path = this.getNavigationPath(type);
        const fullPath = `${path}${id}`;

        sessionStorage.setItem('selectedMenuItem', fullPath);

        const parentNavigationItem = newMenuItems.filter(x => x.text === menuItemName)[0];
        let navigationItem;
        parentNavigationItem.items.forEach(x => {
            if (x.path === fullPath) {
                navigationItem = x;
            } else if (x.items && x.items.length > 0) {
                const childItem = x.items.find(y => y.path === fullPath);
                if (childItem) {
                    navigationItem = childItem;
                }
            }
        });

        this.menuItems = newMenuItems;

        this.initItemSelection();
        this.setMenuItemFavoriteIcon(navigationItem);
    }

    sortNavigationItems(items: any[]): any[] {
        const createNewItem = items.filter(x => x.path.endsWith('/new'))[0];
        const sortedItems = items.filter(x => !x.path.endsWith('/new'));
        sortedItems.sort((a, b) => a.text.localeCompare(b.text));
        if (createNewItem !== undefined) {
            sortedItems.push(createNewItem);
        }
        return sortedItems;
    }

    getMenuItemName(type: EnumBucketType): string {

        let menuItemName: string;

        switch (type) {
            case EnumBucketType.Report:
                menuItemName = menuItemNames.reports;
                break;
            case EnumBucketType.Dashboard:
                menuItemName = menuItemNames.dashboards;
                break;
            case EnumBucketType.Analytic:
                menuItemName = menuItemNames.analytics;
                break;
        }

        return menuItemName;
    }

    getBucketTypeFromMenuItemName(menuItemName: string): EnumBucketType {

        let bucketType: EnumBucketType;

        switch (menuItemName) {
            case menuItemNames.reports:
                bucketType = EnumBucketType.Report;
                break;
            case menuItemNames.dashboards:
                bucketType = EnumBucketType.Dashboard;
                break;
            case menuItemNames.analytics:
                bucketType = EnumBucketType.Analytic;
                break;
        }

        return bucketType;
    }

    getNavigationPath(type: EnumBucketType): string {

        const reportPath = 'pages/report/';
        const dashboardPath = 'pages/dashboard/';
        const analyticPath = 'pages/analytic/';

        let navigationPath: string;

        switch (type) {
            case EnumBucketType.Report:
                navigationPath = reportPath;
                break;
            case EnumBucketType.Dashboard:
                navigationPath = dashboardPath;
                break;
            case EnumBucketType.Analytic:
                navigationPath = analyticPath;
                break;
        }

        return navigationPath;
    }

    initItemSelection() {
        const selectedPath = sessionStorage.getItem('selectedMenuItem');

        for (const topLevelItem of this.menuItems) {
            topLevelItem['selected'] = this.helperService.isPathMatch(selectedPath, topLevelItem.path);
            for (const subItem of topLevelItem.items) {
                subItem['selected'] = this.helperService.isPathMatch(selectedPath, subItem.path);
                if (subItem.items && subItem.items.length > 0) {
                    for (const folderSubItem of subItem.items) {
                        folderSubItem['selected'] = this.helperService.isPathMatch(selectedPath, folderSubItem.path);
                    }
                }
            }
        }
    }

    toggleMenu(): void {
        this.menuOpened = !this.menuOpened;
        this.appElementsService.provideSideNavOpenState(this.menuOpened);
    }

    private switchStarIcon(data, isHovering) {
        let isFavorite: boolean;
        if (data.parent.text === menuItemNames.favorites) {
            isFavorite = true;
        } else {
            if (data.parent === undefined || data.parent.parent === undefined) {
                // this avoids console errors for when the menu is not loaded
                return;
            }
            const type = this.getBucketTypeFromMenuItemName(data?.parent?.parent?.text);
            const id = parseInt(data.path.split('/')[2], 10);
            isFavorite = this.buckets.filter(b => b.itemId === id && b.type === type).length > 0;
        }

        let icon = '';
        if (data.icon.includes('blue')) {
            icon += 'blue';
        }
        icon += 'star';
        const iconOutline = icon + 'outline';

        if ((isFavorite && !isHovering) || (!isFavorite && isHovering)) {
            data.icon = icon;
        } else {
            data.icon = iconOutline;
        }
    }

    private removeFavorite(path: string) {
        const items = [];
        this.menuItems.forEach(parent => {
            if (parent.text === EnumBucketClass[EnumBucketClass.Favorites]) {
                const item = parent.items.filter(f => f.path === (path.endsWith('f') ? path : path + 'f'))[0];
                if (item) {
                    const index = parent.items.indexOf(item);
                    if (index >= 0) {
                        parent.items.splice(index, 1);
                        if (parent.items.length > 0) {
                            items.push(parent);
                        }
                    }
                }
            } else {
                const item = parent.items.filter(f => f.path === (path.endsWith('f') ? path.slice(0, -1) : path))[0];
                if (item) {
                    item.icon = 'staroutline';
                }
                items.push(parent);
            }
        });
        this.menuItems = items;
    }

    private addFavoriteBucket() {
        const favorites = this.menuItems.filter(m => m.text === EnumBucketClass[EnumBucketClass.Favorites])[0];
        if (!favorites) {
            this.menuItems.splice(1, 0,
                {
                    text: EnumBucketClass[EnumBucketClass.Favorites],
                    path: '',
                    icon: 'star',
                    parent: null,
                    items: [],
                    disabled: false,
                    topLevel: true,
                    bucketFolder: false,
                    sortOrder: 0
                });
        }
    }

    private addFavorite(path: string) {
        let parentType: string;
        switch (path.split('/')[1]) {
            case EnumBucketType[EnumBucketType.Report].toLowerCase():
                parentType = menuItemNames.reports;
                break;
            case EnumBucketType[EnumBucketType.Dashboard].toLowerCase():
                parentType = menuItemNames.dashboards;
                break;
            case EnumBucketType[EnumBucketType.Analytic].toLowerCase():
                parentType = menuItemNames.analytics;
                break;
        }

        if (parentType) {
            const items = [];
            this.addFavoriteBucket();
            this.menuItems.forEach(parent => {
                if (parent.text === EnumBucketClass[EnumBucketClass.Favorites]) {
                    const selparent = this.menuItems.filter(m => m.text === parentType)[0];
                    if (selparent) {
                        const item = selparent.items.filter(f => f.path === path)[0];
                        if (item) {
                            item.icon = 'star';
                            parent.items.push({
                                text: item.text,
                                path: path + 'f',
                                icon: item.icon,
                                parent,
                                selected: false,
                                disabled: false,
                                topLevel: false,
                                sortOrder: 0
                            });
                        }
                    }
                }
                items.push(parent);
            });
            this.menuItems = items;
        }
    }
}

@NgModule({
    imports: [
        SideNavigationMenuModule,
        DxDrawerModule,
        HeaderModule,
        DxScrollViewModule,
        CommonModule,
        CoreBotWidgetModule
    ],
    exports: [SideNavOuterToolbarComponent],
    declarations: [SideNavOuterToolbarComponent]
})
export class SideNavOuterToolbarModule { }

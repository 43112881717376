import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ColumnSortDirections, CoreDataGridEditTemplates, CoreDataGridCellTemplates } from '../constants/dev-extreme-enums';
import { CoreColumnTemplateProps } from './core-column-template-props';
import { ColumnType } from './core-column-type';
import { GridProps } from './core-data-grid-properties';
import { CoreDynamicCellEditorProps } from './core-dynamic-cell-editor-props';
import { CoreEditorOptions } from './core-editor-options';
import { CoreValidationRule } from './core-validation-rule';
import { CoreValueListTemplateProps } from './core-value-list-template-props';
import { DevExpressColumn } from './dev-express-column';
import { DevExpressDropdownOptions } from './dev-express-dropdown-options';

export class CoreColumn extends DevExpressColumn {
    isEditable: boolean = false;
    isCurrency: boolean = false;
    isRequired: boolean = false;
    isInPopup: boolean = true;
    format: string = '';
    cssClass: string = '';
    summaryType: string;
    summaryTypeValueFormat: string;
    summaryTypeDisplayFormat: string;
    editCellTemplateName: string;
    groupCellTemplateName: string = null;
    customizeGroupCellTextFunction: (data: any, coreColumn: CoreColumn) => string;
    columnTemplateProps: CoreColumnTemplateProps;
    valueListTemplateProps: CoreValueListTemplateProps;
    sortOrder: string;
    validationRules: CoreValidationRule[];
    getCustomText: (cellInfo: any) => string;
    dataFieldDisplay: string | ((rowInfo: any) => string);
    getEditorPropsForCellFunction: (cellInfo: any, coreColumn: CoreColumn, gridData?: any[], additionalColumnData?: any) => CoreDynamicCellEditorProps;
    isDataSourceReturnedToCellEditor: boolean = false;
    editorOptions: CoreEditorOptions;
    isFixed: boolean = false;
    additionalColumnData: any;
    calculateSortValue: (data: any) => any;
    sortIndex: number;
    includeInCopyToClipboard: boolean = true;

    constructor(dataField: string,
        columnHeader: string,
        isVisible: boolean,
        dataType?: string,
        columnType?: ColumnType,
        isEditable?: boolean,
        format?: string,
        isShownInColumnChooser?: boolean,
        dataFieldDisplay?: string,
        summaryType?: string,
        summaryTypeValueFormat?: string,
        summaryTypeDisplayFormat?: string,
        isRequired: boolean = false,
        isInPopup: boolean = true,
        cssClass: string = '',
        trueText: string = 'true',
        falseText: string = 'false',
        noDataText?: string) {

        super(dataField, columnHeader, isVisible, dataType, columnType, trueText, falseText);
        this.dataFieldDisplay = dataFieldDisplay;
        this.isEditable = isEditable ? isEditable : false;
        this.cssClass = cssClass;
        if (this.columnType && this.columnType.columnType === 'buttons' && !this.cssClass.includes('dx-command-edit')) {
            this.cssClass += ' core-grid-button';
        }
        if (this.columnType && this.columnType.cellTemplateName === CoreDataGridCellTemplates.DropDownButtonCellTemplate) {
            this.cssClass += ' core-data-dropdown-button';
        }

        this.format = format;
        this.isShownInColumnChooser = (isVisible || isShownInColumnChooser) ? true : false;
        this.summaryType = summaryType;
        this.summaryTypeValueFormat = summaryTypeValueFormat;
        this.summaryTypeDisplayFormat = summaryTypeDisplayFormat;
        this.validationRules = new Array();
        this.isRequired = isRequired;
        this.isInPopup = isInPopup;

        if (this.isRequired) {
            this.validationRules = [
                new CoreValidationRule().createRequired('Required Field')
            ];
        }

        if (this.dataType === 'datetime') {
            this.addTimeZoneConversionAsCalculation();
        }
    }

    sortAsc(calculateSortValue: (data: any) => any = null, sortIndex: number = 0): CoreColumn {
        this.sortOrder = ColumnSortDirections.Ascending;
        this.calculateSortValue = calculateSortValue;
        this.sortIndex = sortIndex;

        return this;
    }

    sortDesc(sortIndex: number = 0): CoreColumn {
        this.sortOrder = ColumnSortDirections.Descending;
        this.sortIndex = sortIndex;

        return this;
    }

    addTableDropDownTemplateToLookupColumn(columns: CoreColumn[], gridProps: GridProps, height, width: string | number | (() => string | number)): CoreColumn {
        this.editCellTemplateName = CoreDataGridEditTemplates.DropDownTableTemplate;
        const dropdownProps = new DevExpressDropdownOptions(width);
        gridProps.height = height;

        this.columnTemplateProps = new CoreColumnTemplateProps().createForTableDropDown(columns, gridProps, dropdownProps);

        return this;
    }

    addValueListColumn(keyExpr: string, valueExpr: string, deleteEnabledExpr: string): CoreColumn {

        this.editCellTemplateName = CoreDataGridEditTemplates.ValueListTemplate;
        this.valueListTemplateProps = new CoreValueListTemplateProps().createForValueList(keyExpr, valueExpr, deleteEnabledExpr);
        return this;
    }

    addDynamicCellEditorTemplate(parentThis: any, getEditorPropsForCellFunction: (cellInfo: any, coreColumn: CoreColumn,
        gridData?: any[], additionalColumnData?: any) => CoreDynamicCellEditorProps, isDataSourceReturnedToCellEditor: boolean = false,
        additionalColumnData: any = null): CoreColumn {

        this.editCellTemplateName = CoreDataGridEditTemplates.DynamicCellEditorTemplate;
        if (additionalColumnData !== undefined && additionalColumnData !== null) {
            this.additionalColumnData = additionalColumnData;
        }
        this.getEditorPropsForCellFunction = getEditorPropsForCellFunction.bind(parentThis);
        this.isDataSourceReturnedToCellEditor = isDataSourceReturnedToCellEditor;

        return this;
    }

    addCustomizeText(parentThis: any, getCustomText: (cellInfo: any) => string): CoreColumn {
        this.getCustomText = getCustomText.bind(parentThis);

        return this;
    }

    addCalculateDisplayValue(parentThis: any, calculateDisplayValue: (rowInfo: any) => string): CoreColumn {
        this.dataFieldDisplay = calculateDisplayValue.bind(parentThis);

        return this;
    }

    shade(): CoreColumn {
        if (this.cssClass && this.cssClass.length > 0) {
            this.cssClass += ' ';
        }

        this.cssClass += 'shaded';

        return this;
    }

    addEditorOptions(editorOptions: CoreEditorOptions): CoreColumn {
        this.editorOptions = editorOptions;
        return this;
    }

    setWidth(width: number): CoreColumn {
        this.width = width.toString();
        return this;
    }

    setColumnHeaderOptions(isSortingAllowed: boolean, isFilteringAllowed: boolean, isShownInColumnChooser: boolean): CoreColumn {
        this.isSortingAllowed = isSortingAllowed;
        this.isFilteringAllowed = isFilteringAllowed;
        this.isShownInColumnChooser = isShownInColumnChooser;
        return this;
    }

    setIsFixed(isFixed: boolean): CoreColumn {
        this.isFixed = isFixed;
        return this;
    }

    setMinWidth(minWidth: any): CoreColumn {
        this.minWidth = minWidth;
        return this;
    }

    setAllowHiding(allowHiding: boolean): CoreColumn {
        // Setting this to false prevents the user from dragging the column into the column chooser popup
        this.allowHiding = allowHiding;
        return this;
    }

    setAllowReordering(allowReordering: boolean): CoreColumn {
        this.allowReordering = allowReordering;
        return this;
    }

    addValidationRule(validationRule: CoreValidationRule): CoreColumn {
        if (!this.validationRules) {
            this.validationRules = [];
        }
        this.validationRules.push(validationRule);
        return this;
    }

    addCustomizeGroupCellTextFunction(parentThis: any, customizeGroupCellTextFunction: (data: any, coreColumn: CoreColumn) => string): CoreColumn {
        this.customizeGroupCellTextFunction = customizeGroupCellTextFunction.bind(parentThis);
        this.groupCellTemplateName = CoreDataGridCellTemplates.GroupCellTemplate;

        return this;
    }

    addTimeZoneConversionAsCalculation(): void {
        // Need to convert between time zones
        this.calculation = (rowData: any): Date => {
            if (rowData[this.dataField] === undefined || rowData[this.dataField] === null) {
                return rowData[this.dataField];
            } else {
                const utcValue = new Date(rowData[this.dataField]);

                // Determine if DST is being observed
                const jan = new Date(utcValue.getFullYear(), 0, 1).getTimezoneOffset();
                const jul = new Date(utcValue.getFullYear(), 6, 1).getTimezoneOffset();
                const isDST = Math.max(jan, jul) > utcValue.getTimezoneOffset();

                // Converting from Pacific Standard Time (PST)
                const pstOffsetConstant = isDST ? 7 : 8;
                const offset = pstOffsetConstant - (new Date().getTimezoneOffset() / 60);
                if (offset === 0) {
                    // No time zone conversion needed
                    return utcValue;
                } else {
                    // Perform time zone conversion
                    const dateTimeValue = utcValue;
                    dateTimeValue.setHours(dateTimeValue.getHours() + offset);
                    return new Date(dateTimeValue);
                }
            }
        };
    }
}


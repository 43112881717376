<app-header
    class="layout-header"
    [menuToggleEnabled]="true"
    (menuToggle)="toggleMenu()"
    (parameterSelectionChanged)="onParameterSelectionChanged()"
    [isBotMenuItemVisible]="isBotMenuItemVisible"
    [title]="title"
    [themeClass]="siteThemeConfig.addlClassName">
</app-header>

<dx-drawer
    class="layout-body"
    position="before"
    [animationDuration]="animationDuration"
    [closeOnOutsideClick]="shaderEnabled"
    [openedStateMode]="menuMode"
    [revealMode]="menuRevealMode"
    [minSize]="minMenuSize"
    [shading]="shaderEnabled"
    [(opened)]="menuOpened">
    <app-side-navigation-menu
        [items]="datedMenuLoaded && nonDatedMenuLoaded ? menuItems : []"
        [compactMode]="!menuOpened"
        [selectedItem]="selectedRoute"
        [isPreview]="false"
        [class]="siteThemeConfig.addlClassName"
        *dxTemplate="let dataMenu of 'panel'"
        (selectedItemChanged)="navigationChanged($event)"
        (favoriteClicked)="onFavoriteClicked($event)"
        (favoriteMouseEnter)="onFavoriteMouseEnter($event)"
        (favoriteMouseLeave)="onFavoriteMouseLeave($event)">
    </app-side-navigation-menu>

    <dx-scroll-view class="full-height-scrollable main-scroll-view" [useNative]="true">
        <div class="content">
            <ng-content></ng-content>
        </div>
    </dx-scroll-view>
</dx-drawer>

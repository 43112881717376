import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { EnumSingletonClassId, EnumUserGroup } from '../constants/enums';
import { PermissionService } from './permission.service';
import { SingletonService } from './singleton.service';

@Injectable()
export class AgreementGuard  {

    isAdmin: boolean;
    isAgreementSigned: boolean;

    constructor(
        private router: Router,
        private singletonService: SingletonService,
        private permissionService: PermissionService) { }

    async canActivate() {
        this.isAdmin = this.permissionService.getUserGroupId() === EnumUserGroup.Administrator;
        await this.singletonService.getSingletonById(EnumSingletonClassId.KickoutTime)
            .toPromise()
            .then(x => this.isAgreementSigned = x[0].value != null);
        if (this.isAdmin && !this.isAgreementSigned) {
            this.router.navigate(['/home']);
            return false;
        }
        return true;
    }

    canActivateChild() {
        return this.canActivate();
    }
}

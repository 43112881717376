<div class="header-item">
    <h5 class="content-block">{{hasAdminView ? '' : analytic?.name}}</h5>
</div>
<div class="header-item buttons">
    <core-dropdown [selectedValue]="analytic?.id"
        [dataSource]="analytics !== undefined && analytics.length > 0 
            ? analytics 
            : [{ id: analytic?.id, name: analytic?.name }]"
        dropdownName="analytics"
        [props]="analyticDropdownProps"
        (onRename)="onRename($event)"
        (onSave)="onSave()"
        (onAdd)="onAdd($event)"
        (onDelete)="onDelete()"
        (selectionChanged)="onSelect($event)">
    </core-dropdown>
</div>
<dx-button *ngIf="useDefaultBucket"
    [hidden]="analytic === undefined && !hasAdminView"
    id="home-default-button"
    class="core-dropdown-floating-element"
    icon="home"
    hint="Show as default on home page"
    (onClick)="homeDefaultValueChanged()">
</dx-button>

<div class="content-block header-item-right">
    <dx-button text="Expand All"
        icon="plus"
        (onClick)="expandAll()">
    </dx-button>
    <dx-button text="Collapse All"
        icon="minus"
        (onClick)="collapseAll()">
    </dx-button>    
    <dx-button *ngIf="canEditAnalyticPermissions && analyticViewer?.analytic?.id"
        text="Permissions"
        (onClick)="onClickPermissions()">
    </dx-button>
    <dx-button *ngIf="hasAdminView"
        icon="share"
        text="Home"
        hint="Add to Home Page for Users"
        (onClick)="onClickBulkHomeDefault()">
    </dx-button>
    <div id="analyticBotButton">
        <dx-button *ngIf="isAnalyticBotVisible"
            text="Generate Analytic"
            hint="Use AnalyticBot to generate an analytic"
            (onClick)="showAnalyticBotPopup($event)"
            [disabled]="isAnalyticBotDisabled">
        </dx-button>
    </div>
    <dx-tooltip #tooltip 
        target="#analyticBotButton"
        hideEvent="mouseleave">
        <div *dxTemplate="let data = data; of: 'content'">The Default Payments analytic cannot be modified. Please choose another analytic to use AnalyticBot</div>
    </dx-tooltip>
</div>

<dx-responsive-box>
    <dxi-row [ratio]="1"></dxi-row>
    <dxi-row [ratio]="1" screen="xs sm md"></dxi-row>
    <dxi-col [ratio]="0"></dxi-col>
    <dxi-col [ratio]="3" screen="lg"></dxi-col>
    <dxi-item>
        <dxi-location [row]="0" [col]="1" screen="lg"></dxi-location>
        <dxi-location [row]="1" [col]="0" screen="xs sm md"></dxi-location>
        <app-analytic-viewer [sellerId]="sellerId"
            [showChartControls]="true"
            [showLoadingPanel]="true"
            (analyticLoaded)="onAnalyticLoaded($event)"
            (analyticCreated)="onAnalyticCreated()">
        </app-analytic-viewer>
    </dxi-item>
</dx-responsive-box>

<app-attribute-permissions *ngIf="canEditAnalyticPermissions && analyticViewer?.analytic?.id"
    [(attributeStr)]="analyticViewer.analytic.attributes"
    [(selectedSellerIds)]="publishedSellerIds"
    uniqueId="analytics-permissions"
    [(isPopupVisible)]="isAttributesPopupVisible"
    (onSubmit)="onSavePermissions()">
</app-attribute-permissions>

<app-home-default-bulk *ngIf="hasAdminView"
    [itemId]="analyticId"
    [type]="analyticBucketType"
    [(isPopupOpening)]="openBulkHomeDefaultPopup">
</app-home-default-bulk>

<app-analyticbot-chat 
    [showPopup]="isAnalyticBotPopupVisible" 
    (showPopupChanged)="onPopupStateChanged($event)" 
    [analytic]="analytic" 
    [analyticViewer]="analyticViewer">
</app-analyticbot-chat>

<app-nav-bucket-folders *ngIf="hasAdminView"
    [(isPopupVisible)]="isNavBucketFoldersPopupVisible"
    [folderType]="bucketFolderType"
    [itemId]="analyticId"
    [existingItemMappings]="analytics">
</app-nav-bucket-folders>

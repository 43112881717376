import { CoreLongBase } from './core-long-base';
import { Seller } from './seller';

export class SellerImport extends CoreLongBase {
    sellerId: number;
    name: string;
    seller: Seller;
    sellers: Seller[];
    isDefault: boolean;

    // Optional - only exists on front-end
    deleteEnabled: boolean;

    constructor(id: number, name: string, sellerId: number, isDefault: boolean, deleteEnabled: boolean) {
        super();
        this.id = id;
        this.name = name;
        this.sellerId = sellerId;
        this.isDefault = isDefault;
        this.deleteEnabled = deleteEnabled;
    }
}

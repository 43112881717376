import { ChatMessage } from './chat-message';

export class ConfirmButtomMessage extends ChatMessage {
    customMessageData: any;
    text: string;

    constructor(message: string, bot2: string){
        super();
        this.type = 'confirmButton';
        this.text = message;
        this.customMessageData = {positive:'Yes', negative: 'No', bot: bot2};
        this.user = {
            name: 'BossBot',
            avatar: '../../../assets/images/boss-bot.png'
        };
    }
}

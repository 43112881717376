<div class="core-data-grid">
    <div *ngIf="props.isGridLabelVisible" [ngClass]="{ 'display-none': !props.isGridLabelVisible }" class="grid-label">
        {{ props.gridLabel }}
    </div>
    <dx-data-grid
        #grid
        [id]="props.gridId"
        class="core-component"
        [ngClass]="{'contains-footer': props.footerToolbarItems && props.footerToolbarItems.length > 0}"
        [dataSource]="dataStore"
        [showColumnLines]="props.showColumnLines"
        [showColumnHeaders]="props.showColumnHeaders"
        [showBorders]="props.showBorders"
        [columnAutoWidth]="props.columnAutoWidth"
        [wordWrapEnabled]="props.wordWrapEnabled"
        [height]="props.height"
        [allowColumnReordering]="props.isColumnReorderAllowed"
        [allowColumnResizing]="props.isColumnResizingAllowed"
        [focusedRowEnabled]="props.isFocusedRowEnabled"
        [autoNavigateToFocusedRow]="props.isFocusedRowEnabled"
        [focusedRowKey]="focusedRowKey"
        [(selectedRowKeys)]="selectedRowKeys"
        [noDataText]="props.noDataText"
        [keyExpr]="props.keyColumn"
        [remoteOperations]="props.remoteOperations"
        (onSaving)="onSaving($event)"
        (onOptionChanged)="onOptionChanged($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onSelectionChanged)="onSelectionChanged($event)"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        (onKeyDown)="onKeyDown($event)"
        (onRowValidating)="onRowValidating($event)"
        (onInitNewRow)="onInitNewRow($event)"
        (onEditingStart)="onEditingStart($event)"
        (onEditCanceled)="onEditCanceled($event)"
        (onRowPrepared)="onRowPrepared($event)"
        (onRowInserting)="onRowInserting($event)"
        (onRowInserted)="onRowInserted($event)"
        (onRowUpdating)="onRowUpdating($event)"
        (onRowUpdated)="onRowUpdated($event)"
        (onRowRemoving)="onRowRemoving($event)"
        (onRowRemoved)="onRowRemoved($event)"
        (onContentReady)="onContentReady($event)"
        (onCellPrepared)="onCellPrepared($event)"
        (onExporting)="onExporting($event)"
        (onInitialized)="onInitialized($event)">
        <dxo-export [enabled]="props.isExportEnabled"></dxo-export>
        <dxo-grouping [contextMenuEnabled]="true" [autoExpandAll]="false"></dxo-grouping>
        <dxo-group-panel [visible]="props.isColumnGroupingEnabled" [emptyPanelText]="props.emptyPanelText"></dxo-group-panel>
        <dxo-state-storing [enabled]="true" type="custom" [customSave]="saveState" [customLoad]="loadState"></dxo-state-storing>
        <dxo-search-panel [visible]="props.isSearchEnabled"></dxo-search-panel>
        <dxo-filter-panel [visible]="props.isFilterPanelEnabled"></dxo-filter-panel>
        <dxo-column-chooser [enabled]="props.isColumnChooserEnabled" mode="dragAndDrop" sortOrder="asc">
            <dxo-search [enabled]="props.isColumnChooserSearchEnabled"></dxo-search>
        </dxo-column-chooser>
        <dxo-paging [pageSize]="props.pageSize" [enabled]="props.isPagingEnabled"></dxo-paging>
        <ng-container *ngIf="props.isPagingEnabled">
            <dxo-pager
                        [showPageSizeSelector]="props.isPagingEnabled && props.isPageSizeSelectorVisible"
                        [allowedPageSizes]="props.isPagingEnabled && props.allowedPageSizes"
                        [showNavigationButtons]="props.isPagingEnabled && props.isPageNavigationVisible">
            </dxo-pager>
        </ng-container>
        <ng-container *ngIf="(props.isScrollingEnabled && !props.isPagingEnabled) || props.isServerSideDataProcessingEnabled">
            <dxo-scrolling [mode]="props.scrollingMode" [rowRenderingMode]="props.rowRenderingMode" [preloadEnabled]="true" [useNative]="false"></dxo-scrolling>
        </ng-container>
        <dxo-editing
            [mode]="props.editMode"
            [allowAdding]="props.isAddingAllowed"
            [allowUpdating]="props.isUpdatingAllowed"
            [allowDeleting]="props.isDeletingAllowed"
            [(changes)]="props.gridChanges">
            <dxo-popup
                *ngIf="props.editMode === 'popup'"
                title="Employee Info"
                [showTitle]="true"
                [width]="700"
                [height]="525"
                [position]="{
                    my: 'top',
                    at: 'top',
                    of: 'window'
                }">
            </dxo-popup>
            <dxo-form *ngIf="props.editMode === 'popup'">
                <ng-container *ngFor="let c of getPopupFields()">
                    <dxi-item *ngIf="c.isInPopup" [dataField]="c.dataField"></dxi-item>
                </ng-container>
            </dxo-form>
        </dxo-editing>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-header-filter [visible]="true">
            <dxo-search [enabled]="true"></dxo-search>
        </dxo-header-filter>
        <dxo-selection
            *ngIf="props.isRowSelectionEnabled"
            [selectAllMode]="props.rowSelectionSelectAllMode"
            [showCheckBoxesMode]="props.rowSelectionCheckBoxesMode"
            [allowSelectAll]="props.isRowSelectionSelectAllEnabled"
            [mode]="props.rowSelectionMode">
        </dxo-selection>
        <dxi-column
            *ngFor="let c of columns"
            [dataField]="c.dataField"
            [calculateDisplayValue]="c.dataFieldDisplay"
            [dataType]="c.dataType"
            [caption]="c.columnHeader"
            [type]="c.columnType && c.columnType.isCommandColumn ? c.columnType.columnType : null"
            [allowEditing]="c.isEditable"
            [format]="c.format"
            [width]="c.width"
            [minWidth]="c.minWidth"
            [visible]="c.isVisible"
            [cssClass]="c.cssClass"
            [calculateCellValue]="c.calculation"
            [allowSorting]="c.isSortingAllowed"
            [allowFiltering]="c.isFilteringAllowed"
            [allowReordering]="c.allowReordering"
            [allowHiding]="c.allowHiding"
            [showInColumnChooser]="c.isShownInColumnChooser"
            [cellTemplate]="c.columnType ? c.columnType.cellTemplateName : null"
            [headerCellTemplate]="c.columnType?.headerCellTemplateName ? c.columnType.headerCellTemplateName: null"
            [editCellTemplate]="c.editCellTemplateName"
            [groupCellTemplate]="c.groupCellTemplateName"
            [sortOrder]="c.sortOrder"
            [customizeText]="c.getCustomText"
            [editorOptions]="c.editorOptions"
            [calculateSortValue]="c.calculateSortValue"
            [trueText]="c.trueText"
            [falseText]="c.falseText"
            [fixed]="c.isFixed"
            [sortIndex]="c.sortIndex">
            <ng-container *ngFor="let rule of c.validationRules">
                <dxi-validation-rule [type]="rule.type" [message]="rule.message" [validationCallback]="rule.validationCallbackFunction"></dxi-validation-rule>
            </ng-container>
            <dxi-button
                *ngIf="c.columnType && c.columnType.columnType === 'buttons' && (c.columnType.columnTypeText || c.columnType.buttonIcon)"
                [text]="c.columnType.columnTypeText"
                [hint]="c.columnType.hintText"
                [icon]="c.columnType.buttonIcon"
                cssClass="core-button {{c.columnType.buttonClass}}"
                [onClick]="onCoreGridButtonClick">
            </dxi-button>
            <dxi-button *ngFor="let defaultButton of c.columnType?.defaultButtons" [name]="defaultButton"></dxi-button>
            <dxo-lookup
                *ngIf="c.columnType && c.columnType.columnType === 'lookup'"
                [dataSource]="c.columnType.lookupData"
                [allowClearing]="c.columnType.allowClearing"
                [valueExpr]="c.columnType.lookupValue"
                [displayExpr]="c.columnType.lookupDisplayValue">
            </dxo-lookup>
        </dxi-column>
        <!-- BEGIN Cell Templates -->
        <div *dxTemplate="let data of 'dropDownButtonCellTemplate'">
            <ng-container *ngIf="data && data.column && data.column.dataField">
                <ng-container *ngVar="getColumnByDataField(data.column.dataField, data.data) as c">
                    <ng-container *ngVar="getDataForCellDropDownButton(c, data.data) as dropDownData">
                        <dx-drop-down-button
                            [hint]="c.columnType.hintText"
                            [text]="c.columnType.isTextFromRecord ? data.data[c.columnType.recordTextProp]: c.columnType.columnTypeText"
                            [splitButton]="true"
                            [dataSource]="dropDownData"
                            [keyExpr]="c.columnType.lookupValue"
                            [displayExpr]="c.columnType.lookupDisplayValue"
                            [dropDownOptions]="{width: 'auto', maxWidth: '250'}"
                            [noDataText]="c.columnType.noDataText"
                            (onButtonClick)="onCoreGridButtonClick($event, data)"
                            (onItemClick)="onCoreGridButtonItemClick($event, data)">
                        </dx-drop-down-button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'plainButtonCellTemplate'">
            <ng-container *ngIf="data && data.column && data.column.dataField">
                <ng-container *ngVar="getColumnByDataField(data.column.dataField, data.data) as c">
                    <dx-button
                        class=".core-grid-button"
                        [hint]="c.columnType.hintText"
                        [text]="c.columnType.isTextFromRecord ? data.data[c.columnType.recordTextProp]: c.columnType.columnTypeText"
                        (onClick)="c.columnType.clickFunction($event)">
                    </dx-button>
                </ng-container>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'customButtonCellTemplate'">
            <ng-container *ngIf="data && data.column && data.column.dataField">
                <ng-container *ngVar="getColumnByDataField(data.column.dataField, data.data) as c">
                    <dx-button
                        [class]="c.columnType.buttonClass"
                        [hint]="c.columnType.hintText"
                        [text]="c.columnType.isTextFromRecord ? data.data[c.columnType.recordTextProp]: c.columnType.columnTypeText"
                        [icon]="c.columnType.buttonIcon"
                        [disabled]="c.columnType.buttonDisabled"
                        (onClick)="c.columnType.clickFunction($event, data)">
                    </dx-button>
                </ng-container>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'textWithIconFlagTemplate'">
            <ng-container *ngVar="getColumnByDataField(data.column.dataField, data.data) as c">
                <div class="text-with-icon-flag">
                    <div>{{data.text}}</div>
                    <ng-container *ngIf="data.data[c.columnType.showIconDataField]">
                        <i id="custom-column-icon-{{data.column.dataField}}-{{data.data.id}}" class="dx-icon dx-icon-{{c.columnType.icon}}"></i>
                        <dx-tooltip 
                            target="#custom-column-icon-{{data.column.dataField}}-{{data.data.id}}"
                            position="right"
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            [animation]="hoverTooltipAnimation">
                            <div *dxTemplate="let data of 'content'">
                                <span>{{c.columnType.iconHoverText}}</span>
                            </div>
                        </dx-tooltip>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div *dxTemplate="let data of 'groupCellTemplate'">
            <div>{{getGroupCellText(data)}}</div>
        </div>
        <!-- END Cell Templates -->
        <!-- BEGIN Header Cell Templates -->
        <div *dxTemplate="let data of 'undraggableHeaderCellTemplate'">
            <div class="undraggable-header-cell"></div>
        </div>
        <!-- END Header Cell Templates -->
        <!-- BEGIN Edit Cell Templates -->
        <div *dxTemplate="let cellInfo of 'dropDownTableTemplate'">
            <ng-container *ngVar="getColumnByDataField(cellInfo.column.dataField) as coreColumn">
                <core-table-drop-down
                    [columns]="coreColumn.columnTemplateProps.columns"
                    [props]="coreColumn.columnTemplateProps.gridProps"
                    [parentColumn]="coreColumn"
                    [selectedValue]="cellInfo.value"
                    [parentTemplate]="cellInfo"
                    [dropDownOptions]="coreColumn.columnTemplateProps.templateOptions">
                </core-table-drop-down>
            </ng-container>
        </div>
        <div *dxTemplate="let cellInfo of 'valueListTemplate'">
            <ng-container *ngVar="getColumnByDataField(cellInfo.column.dataField) as coreColumn">
                <core-value-list
                    [(dataSource)]="cellInfo.value"
                    [keyExpr]="coreColumn.valueListTemplateProps.keyExpr"
                    [valueExpr]="coreColumn.valueListTemplateProps.valueExpr"
                    [deleteEnabledExpr]="coreColumn.valueListTemplateProps.deleteEnabledExpr"
                    [parentTemplate]="cellInfo">
                </core-value-list>
            </ng-container>
        </div>
        <div *dxTemplate="let cellInfo of 'dynamicCellEditorTemplate'">
            <ng-container *ngVar="getColumnEditorProps(cellInfo) as editorProps">
                <ng-container *ngIf="editorProps.template === 'dropDownTableTemplate'">
                    <dx-drop-down-box
                        [dropDownOptions]="editorProps.mockCoreColumn.columnTemplateProps.templateOptions"
                        [(value)]="cellInfo.value"
                        [displayExpr]="editorProps.mockCoreColumn.columnType.lookupDisplayValue"
                        [valueExpr]="editorProps.mockCoreColumn.columnType.lookupValue"
                        [showClearButton]="true"
                        contentTemplate="contentTemplate"
                        fieldTemplate="fieldTemplate"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                        <div *dxTemplate="let e of 'contentTemplate'">
                            <core-data-grid
                                [dataSource]="editorProps.mockCoreColumn.columnType.lookupData"
                                [columns]="editorProps.mockCoreColumn.columnTemplateProps.columns"
                                [props]="editorProps.mockCoreColumn.columnTemplateProps.gridProps"
                                [focusedRowKey]="cellInfo.value"
                                [parentTemplate]="{
                                    cellInfo: cellInfo,
                                    parentTemplate: e,
                                    editorProps: editorProps
                                }">
                            </core-data-grid>
                        </div>
                        <div *dxTemplate="let e of 'fieldTemplate'">
                            <dx-select-box
                                [hint]="cellInfo.value"
                                [dataSource]="editorProps.mockCoreColumn.columnType.lookupData"
                                [value]="cellInfo.value"
                                [displayExpr]="editorProps.mockCoreColumn.columnType.lookupDisplayValue"
                                [valueExpr]="editorProps.mockCoreColumn.columnType.lookupValue"
                                placeholder="Select..."
                                [showClearButton]="false"
                                [openOnFieldClick]="false"
                                [showDropDownButton]="false"
                                [searchEnabled]="true"
                                stylingMode="underlined">
                            </dx-select-box>
                        </div>
                    </dx-drop-down-box>
                </ng-container>
                <ng-container *ngIf="editorProps.template === 'coreTableSelectTemplate'">
                    <core-table-select-cell-editor
                        [editorProps]="editorProps"
                        [cellInfo]="cellInfo"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                    </core-table-select-cell-editor>
                </ng-container>
                <ng-container *ngIf="editorProps.template === 'datetimeTemplate'">
                    <dx-date-box
                        [type]="editorProps.mockCoreColumn.dataType"
                        [value]="cellInfo.value"
                        [displayFormat]="editorProps.mockCoreColumn.format"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                    </dx-date-box>
                </ng-container>
                <ng-container *ngIf="editorProps.template === 'selectBoxTemplate'">
                    <dx-select-box
                        [dataSource]="editorProps.mockCoreColumn.lookupData"
                        [value]="cellInfo.value"
                        [valueExpr]="editorProps.mockCoreColumn.dataField"
                        [displayExpr]="editorProps.mockCoreColumn.dataFieldDisplay"
                        placeholder="Select..."
                        [showClearButton]="true"
                        [searchEnabled]="true"
                        stylingMode="underlined"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                    </dx-select-box>
                </ng-container>
                <ng-container *ngIf="editorProps.template === 'lookupTemplate'">
                    <dx-lookup
                        [dataSource]="editorProps.mockCoreColumn.lookupData"
                        [value]="cellInfo.value"
                        [valueExpr]="editorProps.mockCoreColumn.dataField"
                        [displayExpr]="editorProps.mockCoreColumn.dataFieldDisplay"
                        placeholder="Select..."
                        [showClearButton]="true"
                        [searchEnabled]="true"
                        stylingMode="underlined"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                    </dx-lookup>
                </ng-container>
                <ng-container *ngIf="editorProps.template === 'numberBoxTemplate'">
                    <dx-number-box
                        [value]="cellInfo.value"
                        [format]="editorProps.mockCoreColumn.format"
                        [showClearButton]="true"
                        stylingMode="underlined"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                    </dx-number-box>
                </ng-container>
                <ng-container *ngIf="editorProps.template === 'textBoxTemplate'">
                    <dx-text-box
                        [value]="cellInfo.value"
                        [showClearButton]="true"
                        (onValueChanged)="dynamicCellEditorValueChange($event, cellInfo, editorProps)">
                    </dx-text-box>
                </ng-container>
            </ng-container>
        </div>
        <!-- END Edit Cell Templates -->
        <dxo-summary>
            <ng-container *ngFor="let c of columns">
                <dxi-total-item
                    *ngIf="c.summaryType"
                    cssClass="summary-item"
                    [column]="c.dataField"
                    [summaryType]="c.summaryType"
                    [valueFormat]="c.summaryTypeValueFormat"
                    [displayFormat]="c.summaryTypeDisplayFormat">
                </dxi-total-item>
                <dxi-group-item
                    *ngIf="c.summaryType"
                    cssClass="summary-item"
                    [alignByColumn]="true"
                    [column]="c.dataField"
                    [summaryType]="c.summaryType"
                    [valueFormat]="c.summaryTypeValueFormat"
                    [displayFormat]="c.summaryTypeDisplayFormat">
                </dxi-group-item>
            </ng-container>
        </dxo-summary>
    </dx-data-grid>
    <dx-toolbar
        #footerToolbar
        class="footer-toolbar"
        [visible]="props.footerToolbarItems && props.footerToolbarItems.length > 0"
        [items]="props.footerToolbarItems">
    </dx-toolbar>
    <dx-load-panel
        #loadPanel
        [wrapperAttr]="{ class: 'spinning-gears-load-panel' }"
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '#'+props.gridId }"
        [container]="'#'+props.gridId"
        [(visible)]="props.loadingVisible"
        [indicatorSrc]="indicatorUrl"
        [showIndicator]="true"
        [showPane]="false"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>
    <core-popup #coreDataGridPopup [props]="dataGridPopupProps" [steps]="dataGridPopupSteps"></core-popup>
</div>

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { CoreSimplePopupProps } from '../../models/core-simple-popup-props';

@Component({
    selector: 'core-simple-popup',
    templateUrl: './core-simple-popup.component.html',
    styleUrls: ['./core-simple-popup.component.scss'],
})
export class CoreSimplePopupComponent implements OnInit, AfterViewInit {
    @ViewChild('popup', { static: false }) popup: DxPopupComponent;

    props: CoreSimplePopupProps;
    okClick: () => void;
    cancelClick: () => void;
    okClickText: string;
    cancelClickText: string;

    ngOnInit() {
        this.setDefaultButtonView();
    }

    ngAfterViewInit() {
        Object.keys(this.props).forEach((key) => {
            if (typeof this.popup[key] !== undefined) {
                this.popup[key] = this.props[key];
            }
        });
    }

    onHidden(e) {
        this.popup.instance.dispose();
    }

    setDefaultButtonView() {
        this.okClickText = this.props.okButtonText ?? 'Ok';
        this.cancelClickText = this.props.cancelButtonText ?? 'Cancel';

        if (typeof this.props.showOkButton === 'undefined') {
            this.props.showOkButton = true;
        }
        if (typeof this.props.showCancelButton === 'undefined') {
            this.props.showCancelButton = true;
        }
        this.okClick = this.props.onOkClick
            ? this.props.onOkClick
            : () => (this.popup.visible = false);
        this.cancelClick = this.props.onCancelClick
            ? this.props.onCancelClick
            : () => (this.popup.visible = false);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class SAMLService {
    private baseUrl = environment.samlEndpoint;

    constructor(private http: HttpClient) {
    }

    getIsUsingSPSSO(tenantName: string): Observable<boolean> {
        return this.http.get<boolean>(this.baseUrl + '/GetIsUsingSPSSO?tenantName=' + tenantName);
    }

    logOutSsoUser(): Observable<void>{
        return this.http.get<void>(this.baseUrl + '/LogOutSSOUser');
    }
}

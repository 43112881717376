import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {

    constructor(
        private router: Router,
        private authService: AuthService) { }

    canActivate() {
        if (!this.authService.isTokenExpired()) {
            return true;
        }

        this.router.navigate(['/login-form']);
        return false;
    }

    canActivateChild() {
        return this.canActivate();
    }
}

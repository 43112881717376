import { CorePopupStep } from './core-popup-step';
import { RequiredProperty } from './required-property';

export class CoreFileUpload {
    okClickFunctionFile: (e: any, value: CorePopupStep) => void;
    visible: boolean = false;
    okClickPassesFile: boolean = false;
    fileImportButtonText: string;
    fileImportLabel: string;
    allowedFileExtensions: string[];
    uploadMode: string;
    allowMultiple: boolean;
    importedFileIds: string[];
    fileTypeId: number;
    requiredproperties: RequiredProperty[];
    // 1 byte less than 2GB which is the limit of what we can upload to the database
    maxFileSize: number = 2147483647;
}

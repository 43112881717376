import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AttributeClass } from '../models/attribute-class';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';

@Injectable({
    providedIn: 'root'
})

export class AccountAttributeClassService {
    private baseurl: string = environment.apiEndpoint;
    private attributeClassURL = this.baseurl + '/attributeclass';

    constructor(private http: HttpClient) { }

    getAttributes(): Observable<AttributeClass[]> {
        return this.http.get<AttributeClass[]>(this.attributeClassURL + '/classattrs/');
    }

    getAttributeClasses(systemOnly: boolean = false): Observable<AttributeClass[]> {
        return this.http.get<AttributeClass[]>(this.attributeClassURL + `/classes?systemOnly=${systemOnly}`);
    }

    getFatAttributeClassesByTextExcluded(isText: boolean, isExcluded: boolean): Observable<AttributeClass[]> {
        return this.http.get<AttributeClass[]>(this.attributeClassURL + `/getFatAttributeClassesByTextExcluded/${isText}/${isExcluded}`);
    }

    insertAttributeClass(attributeClass: AttributeClass): Observable<AttributeClass> {
        return this.http.post<AttributeClass>(this.attributeClassURL + '/class/', attributeClass);
    }

    updateAttributeClass(attributeClass: AttributeClass): Observable<any> {
        return this.http.put<AttributeClass>(this.attributeClassURL + '/class/', attributeClass);
    }

    deleteAttributeClass(id: number): Observable<CoreResponse<any>> {
        return this.http.delete<CoreResponse<any>>(this.attributeClassURL + '/class/' + id);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreResponse } from '../models/core-response';
import { IntegrationClass, IntegrationValue } from '../models/integration';
import { Oauth } from '../models/oauth';

@Injectable()
export class OauthService {
    private baseUrl = environment.apiEndpoint + '/oauth';

    constructor(private http: HttpClient) {
    }

    getRedirectUrl(integrationName: string, environmnt: string): Observable<string> {
        return this.http.get<string>(this.baseUrl + '/redirect/' + integrationName, { params: { environmnt }});
    }

    updateCompanyId(params: Oauth, companyName: string): Observable<number> {
        return this.http.put<number>(this.baseUrl + '/' + companyName, params);
    }

    revokeCredentials(companyName: string): Observable<CoreResponse<number>> {
        return this.http.delete<CoreResponse<number>>(this.baseUrl + '/' + companyName);
    }
}

<app-attribute-permissions
    uniqueId="bulk-home-default"
    title="Select Applicable Users"
    submitLabel="Submit"
    [(attributeStr)]="attributeStr"
    [(isPopupVisible)]="isAttributesPopupOpen"
    (onSubmit)="onClickBulkHomeDefaultPermissionsOk()">
</app-attribute-permissions>
<dx-popup 
    [width]="350"
    [height]="150"
    [showTitle]="true"
    title="Add to Home Page for Users"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="isConfirmationPopupVisible">
    Replace existing home item set for users matching selected attributes if found?

    <dxi-toolbar-item
        widget="dxButton"
        toolbar="bottom"
        [options]="{
            text: 'Yes',
            stylingMode: 'contained',
            onClick: onClickBulkHomeDefaultConfirmationYes
        }">
    </dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        toolbar="bottom"
        [options]="{
            text: 'No',
            stylingMode: 'contained',
            onClick: onClickBulkHomeDefaultConfirmationNo
        }">
    </dxi-toolbar-item>
    <dxi-toolbar-item
        widget="dxButton"
        toolbar="bottom"
        [options]="{
            text: 'Cancel',
            stylingMode: 'outlined',
            onClick: closeBulkHomeDefaultConfirmation
        }">
    </dxi-toolbar-item>
</dx-popup>

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SellerService } from '../../services/seller.service';
import { SellerHierarchyItem } from '../../models/seller-hierarchy-item';
import { CoreTreeListComponent } from '../core-tree-list/core-tree-list.component';
import { CoreColumn } from '../../models/core-column';
import { TreeListProps } from '../../models/core-tree-list-properties';
import { AccountAttributeClassService } from '../../services/account-attributeClass.service';
import { AttributeClass } from '../../models/attribute-class';

@Component({
    selector: 'app-hierarchy',
    templateUrl: './hierarchy.component.html',
    styleUrls: ['./hierarchy.component.scss'],
    providers: []
})
export class HierarchyComponent implements OnInit {

    @ViewChild('coreTreeList', { static: false }) coreTreeList: CoreTreeListComponent;

    public displayHierarchyData: SellerHierarchyItem[];
    public popupVisible: boolean;
    public viewActivePayeesOnly = true;

    public treeListProperties: TreeListProps = new TreeListProps('seller-hierarchy', 'treeKey', 'treeParent', null, false, false);
    public treeListColumns: CoreColumn[];
    public staticTreeListColumns: CoreColumn[] = [
        new CoreColumn('treeKey', '', false),
        new CoreColumn('treeParent', '', false),
        new CoreColumn('name', 'Name', true).setMinWidth(80),
        new CoreColumn('isPayee', 'Payee', true, 'boolean').setWidth(90)
    ];

    private sourceHierarchyData: SellerHierarchyItem[];

    constructor(private sellerService: SellerService,
        private attributeClassService: AccountAttributeClassService,
        private toast: ToastrService) {

    }

    ngOnInit() {
        this.treeListProperties.loadingVisible = true;
        this.treeListProperties.isScrollingEnabled = true;
        this.treeListProperties.height = 400;
        this.treeListProperties.isColumnResizingAllowed = true;
        this.getHierarchy();
    }

    launch() {
        this.popupVisible = true;
    }

    getHierarchy() {
        forkJoin([
            this.sellerService.getSellerHierarchy(),
            this.attributeClassService.getAttributeClasses()
        ]).subscribe(([hierarchyData, attributeClasses]) => {
            this.sourceHierarchyData = hierarchyData;
            this.setAttributeValues(this.sourceHierarchyData, attributeClasses);
            this.filterHierarchyData(this.viewActivePayeesOnly);
            this.treeListProperties.loadingVisible = false;
        });
    }

    setAttributeValues(sellerHierarchyItems: SellerHierarchyItem[], attributeClasses: AttributeClass[]) {
        sellerHierarchyItems.forEach(sellerHierarchyItem => {
            sellerHierarchyItem.attributes.forEach(attribute => {
                const obj: { [k: string]: any } = {};
                if (attribute.attributeClass.isText) {
                    obj[attribute.attributeClass.name] = attribute.name;
                } else {
                    obj[attribute.attributeClass.name] = attribute.name;
                }
                Object.assign(sellerHierarchyItem, obj);
            });
        });

        this.treeListColumns = [];
        this.staticTreeListColumns.forEach(x => this.treeListColumns.push(x));
        attributeClasses.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));
        attributeClasses.sort((a, b) => b.isText.toString().localeCompare(a.isText.toString()));
        attributeClasses.filter(x => !x.isExcluded).forEach(attributeClass => {
            this.treeListColumns.push(new CoreColumn(attributeClass.name, attributeClass.friendlyName, true));
        });
    }

    filterHierarchyData(viewActivePayeesOnly: boolean) {
        this.displayHierarchyData = this.sourceHierarchyData.filter(x => !viewActivePayeesOnly || (x.isPayee && x.endDate === null));
    }

    onViewActivePayeesOnlyValueChanged(e) {
        this.filterHierarchyData(e.value);
    }

    onResize(e) {
        this.treeListProperties.height = e.height - 155;
    }

    expandAll() {
        this.coreTreeList.expandAll();
    }

    collapseAll() {
        this.coreTreeList.collapseAll();
    }

    copyToClipboard(e) {
        const clipboardRows: string[][] = [];

        // determine how many levels there are
        let maxLevel = 0;
        this.coreTreeList.treeList.instance.forEachNode(node => {
            if (node.level > maxLevel) {
                maxLevel = node.level;
            }
        });

        // construct header row
        const headers: string[] = [];
        const treeListColumns = this.treeListColumns;
        for (let i = 0; i <= maxLevel; i++) {
            headers.push(`Staff Level ${(i + 1).toString()}`);
        }
        treeListColumns.forEach(column => {
            if (column.isVisible && column.dataField !== 'name') {
                headers.push(column.columnHeader);
            }
        });
        clipboardRows.push(headers);

        // construct data rows
        this.coreTreeList.treeList.instance.forEachNode(node => {
            const rowData: string[] = [];
            for (let i = 0; i <= maxLevel; i++) {
                if (i === node.level) {
                    rowData.push(node.data.name);
                } else {
                    rowData.push('');
                }
            }
            const hierarchyDataItem = this.displayHierarchyData.filter(x => x.treeKey === node.data.treeKey)[0];
            treeListColumns.forEach(column => {
                if (column.isVisible && column.dataField !== 'name') {
                    if (!hierarchyDataItem[column.dataField]) {
                        rowData.push('');
                    } else {
                        rowData.push(hierarchyDataItem[column.dataField].toString());
                    }
                }
            });
            clipboardRows.push(rowData);
        });

        // write to string
        const clipboardRowStrings: string[] = [];
        clipboardRows.forEach(clipboardRow => {
            clipboardRowStrings.push(clipboardRow.join('\t'));
        });
        const copyValue = clipboardRowStrings.join('\n');

        // copy to clipboard
        navigator.clipboard.writeText(copyValue).then(() => {
            this.toast.success('Account Hierarchy successfully copied to clipboard');
        }, error => {
            this.toast.error('An error occurred while attempting to copy to clipboard');
        });
    }
}

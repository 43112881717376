import { CoreProperty } from './core-property';
import { Customer } from './customer';
import { DatasourceMapping } from './datasource-mapping';
import { Period } from './period';
import { Product } from './product';

export class Xaction {
    id: number;
    datasourceId: number;
    seriesId: number;
    periodId: number;
    productId: number;
    customerId: number;
    notes: string;
    datasourceMappingId: number;
    sellerIdFields: CoreProperty[];
    dateFields: CoreProperty[];
    tagFields: CoreProperty[];
    textFields: CoreProperty[];
    qtyFields: CoreProperty[];
    calcQtyFields: CoreProperty[];
    periodRange: string;
    period: Period;
    customer: Customer;
    product: Product;
    datasourceMapping: DatasourceMapping;
    customerName: string;
    customerImportName: string;
    customerGroupName: string;
    productName: string;
    productImportName: string;
    productGroupName: string;
    color?: string;

    constructor(id?: number, datasourceId?: number, datasourceMappingId?: number, seriesId?: number, periodId?: number, productId?: number, customerId?: number) {
        this.id = id;
        this.datasourceId = datasourceId;
        this.datasourceMappingId = datasourceMappingId;
        this.seriesId = seriesId;
        this.periodId = periodId;
        this.productId = productId;
        this.customerId = customerId;
        this.sellerIdFields = [];
        this.dateFields = [];
        this.tagFields = [];
        this.textFields = [];
        this.qtyFields = [];
    }

    createXaction(row: any): Xaction {
        if (row) {
            if (row.hasOwnProperty('id')) {
                this.id = row.id;
            }
            if (row.hasOwnProperty('datasourceId')) {
                this.datasourceId = row.datasourceId;
            }
            if (row.hasOwnProperty('seriesId')) {
                this.seriesId = row.seriesId;
            }
            if (row.hasOwnProperty('periodId')) {
                this.periodId = row.periodId;
            }
            if (row.hasOwnProperty('productId')) {
                this.productId = row.productId;
            }
            if (row.hasOwnProperty('customerId')) {
                this.customerId = row.customerId;
            }
            if (row.hasOwnProperty('notes')) {
                this.notes = row.notes;
            }
            if (row.hasOwnProperty('datasourceMappingId')) {
                this.datasourceMappingId = row.datasourceMappingId;
            }

            return this;
        }
    }
}

<dx-popup
    [wrapperAttr]="{ id: wrapperId }"
    [width]="width"
    [height]="isDateOnly ? 430 : 600"
    [showTitle]="true"
    [title]="(isDateOnly ? 'Date' : isAccountOnly ? 'Account' : 'Date/Account') + ' Selection'"
    [dragEnabled]="true"
    container=".dx-viewport"
    [hideOnOutsideClick]="true"
    [shading]="false"
    [(visible)]="isPopupVisible"
    [resizeEnabled]="true"
    [showCloseButton]="true"
    titleTemplate="titleTemplate"
    (onHidden)="onHidden($event)"
    (onShowing)="onShowing()"
    (onShown)="onShowing()">
    <div *dxTemplate="let data of 'titleTemplate'" class="reporting-parameters-title">
        <dx-toolbar>
            <dxi-item location="before">
                <div class="reporting-parameters-title-text before-inline-item">
                    Date/Account Selection
                </div>
                <div *ngIf="!isSelectAllByDefaultEnabled" class="before-inline-item">
                    <span title="Pick up where you left off" class="title-toggle-control">
                        <span class="pick-up-left-off-control">
                            <dx-switch [(value)]="persistDateAccountSelection" (onValueChanged)="onTogglePersistDateAccountSelection()"></dx-switch>
                        </span>
                        <span class="pick-up-left-off-label">Pick up where you left off</span>
                    </span>
                </div>
            </dxi-item>
            <dxi-item location="after">
                <dx-button icon="close" (onClick)="closePopup()"></dx-button>
            </dxi-item>
        </dx-toolbar>
    </div>
    <dx-box *dxTemplate="let data of 'content'" direction="col" height="100%" width="100%">
        <dxi-item [baseSize]="560" [ratio]="1" [shrink]="1">
            <dx-scroll-view #scrollView [disabled]="distributionListsLoading || sellersLoading">
                <div class="date-section" [class.hidden]="isAccountOnly">
                    <dx-button-group
                        [items]="quickDateOptions"
                        keyExpr="text"
                        stylingMode="outlined"
                        [(selectedItemKeys)]="quickDateSelection"
                        (onSelectionChanged)="quickDateOnSelectionChanged($event)">
                    </dx-button-group>
                    <div *ngIf="showRecurrence">
                        <p>Recurrence:</p>
                        <dx-select-box
                            [dataSource]="recurrenceList"
                            valueExpr="id"
                            displayExpr="name"
                            [(value)]="selectedRecurrence"
                            (onValueChanged)="onRecurrenceChange()"
                            fieldTemplate="field"
                            stylingMode="underlined">
                            <div *dxTemplate="let data of 'field'">
                                <dx-text-box [value]="data && data.name" [readOnly]="true" stylingMode="underlined"></dx-text-box>
                            </div>
                            <div *dxTemplate="let data of 'item'">{{data.name}}</div>
                        </dx-select-box>
                    </div>
                    <p>Start Date:</p>
                    <dx-select-box
                        [dataSource]="periodsBeginSorted"
                        valueExpr="beginDate"
                        displayExpr="beginDate"
                        [(value)]="context.beginDate"
                        (onValueChanged)="dateOnValueChanged($event)"
                        fieldTemplate="field"
                        stylingMode="underlined">
                        <div *dxTemplate="let data of 'field'">
                            <dx-text-box [value]="data && data.beginDate | date" [readOnly]="true" stylingMode="underlined"></dx-text-box>
                        </div>
                        <div *dxTemplate="let data of 'item'">{{data.beginDate | date}}</div>
                    </dx-select-box>
                    <p>End Date:</p>
                    <dx-select-box
                        [dataSource]="periodsEndSorted"
                        valueExpr="endDate"
                        displayExpr="endDate"
                        [(value)]="context.endDate"
                        (onValueChanged)="dateOnValueChanged($event)"
                        fieldTemplate="field"
                        stylingMode="underlined">
                        <div *dxTemplate="let data of 'field'">
                            <dx-text-box [value]="data && data.endDate | date" [readOnly]="true" stylingMode="underlined"></dx-text-box>
                        </div>
                        <div *dxTemplate="let data of 'item'">{{data.endDate | date}}</div>
                    </dx-select-box>

                    <div *ngIf="hasAdminView">
                        <p>Series:</p>
                        <dx-drop-down-box
                            [dataSource]="series"
                            valueExpr="id"
                            displayExpr="name"
                            [(value)]="selectedSeriesIds">
                            <dx-list
                                [dataSource]="series"
                                keyExpr="id"
                                displayExpr="name"
                                [selectionMode]="seriesMode"
                                [showSelectionControls]="true"
                                [(selectedItemKeys)]="selectedSeriesIds">
                            </dx-list>
                        </dx-drop-down-box>
                    </div>
                </div>

                <dx-load-panel
                    [visible]="distributionListsLoading || sellersLoading"
                    shadingColor="rgba(0,0,0,0.4)"
                    [shading]="false"
                    [showIndicator]="true"
                    [showPane]="true"
                    [hideOnOutsideClick]="false"
                    [position]="{ of: '.account-section' }">
                </dx-load-panel>
                <div class="account-section" [class.hidden]="isDateOnly">
                    <div *ngIf="distributionListsLoading || sellersLoading" class="account-section-loading-overlay"></div>
                    <dx-box class="topmarginbutton" width="100%" direction="row" align="right">
                        <dxi-item [ratio]="9" class="layout-area">
                            <span>Layout: </span>
                            <core-dropdown
                                #distributionListDropdown
                                [(selectedValue)]="distributionListId"
                                [dataSource]="distributionLists"
                                [popupProps]="layoutPopupProps"
                                [props]="reportingDropdownProps"
                                (selectionChanged)="onSelectDistributionList($event)"
                                (onAdd)="onAddDistributionList($event)"
                                (onRename)="onEditDistributionList($event)"
                                (onSave)="onSaveDistributionList($event)"
                                (onDelete)="onDeleteDistributionList($event)">
                            </core-dropdown>
                        </dxi-item>
                        <dxi-item [ratio]="4" class="expand-toggle-area">
                            <span *ngIf="hasGlobalDistributionList" title="Apply Default Layouts" class="toggle-control">
                                <span>Apply Defaults</span>
                                <span>
                                    <dx-switch [(value)]="applyDefaults" (onValueChanged)="applyDefaultsValueChanged($event)"></dx-switch>
                                </span>
                            </span>
                            <span title="Expand View To Include Account Attributes" class="toggle-control">
                                <span>Details</span>
                                <span>
                                    <dx-switch [(value)]="this.flatVisible" (onValueChanged)="switchValueChanged($event)"></dx-switch>
                                </span>
                            </span>
                        </dxi-item>
                    </dx-box>

                    <div id="accounts-label-header">
                        <span>Accounts:</span>
                        <span title="Include Inactive Accounts" class="toggle-control">
                            <span>Show Inactive</span>
                            <span>
                                <dx-switch [(value)]="this.includeInactive" (onValueChanged)="onIncludeInactiveToggle()"></dx-switch>
                            </span>
                        </span>
                    </div>
                    <dx-tree-list
                        #accountTree
                        [visible]="!flatVisible"
                        [dataSource]="sellers"
                        keyExpr="id"
                        [(selectedRowKeys)]="selectedSellerIDs"
                        (onRowDblClick)="sellerOnDoubleClickEvent($event)"
                        (onEditorPrepared)="onEditorPrepared($event)"
                        (onToolbarPreparing)="onToolbarPreparing($event)"
                        (onContentReady)="onTreeContentReady($event)"
                        parentIdExpr="parentId">
                        <dxo-selection mode="multiple" [allowSelectAll]="false"></dxo-selection>
                        <dxo-scrolling mode="standard"></dxo-scrolling>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxo-header-filter [visible]="true"></dxo-header-filter>
                        <dxi-column dataField="name" caption="Account Name" sortOrder="asc" [calculateSortValue]="activeThenNameSort"></dxi-column>
                    </dx-tree-list>
                    <dx-data-grid *ngIf="flatVisible"
                        #accountGrid
                        [dataSource]="sellers"
                        keyExpr="id"
                        (onContentReady)="onGridContentReady($event)"
                        (onInitialized)="onGridInitialized($event)"
                        [(selectedRowKeys)]="selectedSellerIDs">
                        <dxo-selection mode="multiple"></dxo-selection>
                        <dxo-group-panel [visible]="true" emptyPanelText="Drag columns here to group."></dxo-group-panel>
                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-scrolling mode="standard" [useNative]="true"></dxo-scrolling>
                        <dxo-filter-row [visible]="true"></dxo-filter-row>
                        <dxo-header-filter [visible]="true"></dxo-header-filter>
                        <dxo-sorting mode="multiple"></dxo-sorting>
                        <dxi-column dataField="name" caption="Account Name" sortOrder="asc"></dxi-column>
                        <dxi-column dataField="primaryImportName" caption="Primary Import Name"></dxi-column>
                        <dxi-column dataField="histSellers[0].bossSellerId" caption="Manager" [calculateSortValue]="calculateSortValue">
                            <dxo-lookup [dataSource]="managers" valueExpr="id" displayExpr="name"></dxo-lookup>
                        </dxi-column>
                        <dxi-column dataField="histSellers[0].parentSellerId" caption="Parent" [calculateSortValue]="calculateSortValue">
                            <dxo-lookup [dataSource]="parents" valueExpr="id" displayExpr="name"></dxo-lookup>
                        </dxi-column>
                        <dxi-column *ngIf="includeInactive" dataField="isActive" caption="Active" [groupIndex]="0"  sortOrder="desc"></dxi-column>
                        <dxi-column *ngFor="let class of attributeClasses" [caption]="class.friendlyName" [dataField]="class.name" [headerFilter]="attributeClassHeaderFilters[class.id]"></dxi-column>
                        <dxo-column-chooser [enabled]="true" mode="dragAndDrop">
                            <dxo-search [enabled]="true"></dxo-search>
                        </dxo-column-chooser>
                    </dx-data-grid>
                </div>
            </dx-scroll-view>
        </dxi-item>
        <dxi-item [baseSize]="40" [ratio]="0" [shrink]="0">
            <dx-button
                class="topmarginbutton"
                type="default"
                stylingMode="contained"
                [text]="isDateOnly || isAccountOnly ? 'Apply' : 'Generate'"
                (onClick)="buttonPressedEvent(context)">
            </dx-button>
        </dxi-item>
    </dx-box>
</dx-popup>

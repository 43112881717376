export class PollValue<T> {
    date: Date;
    value: T;

    constructor(value: T) {
        this.date = new Date();
        this.value = value;
    }

    getTimeout(minTimeMS: number) {
        const timeElapse = new Date().getTime() - this.date.getTime();
        return timeElapse < minTimeMS ? minTimeMS - timeElapse : 0;
    }
}

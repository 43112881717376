import { Node } from './node';

export class Edge {
    readonly id: string;
    readonly sourceNode: Node;
    readonly destNode: Node;
    readonly name: string;

    constructor(sourceNode: Node, destNode: Node, name: string) {
        this.sourceNode = sourceNode;
        this.destNode = destNode;
        this.name = name;
        this.id = Edge.getId(sourceNode, destNode);
    }

    get sourceNodeId(): string {
        return this.sourceNode.id;
    }
    set sourceNodeId(x) { }

    get destNodeId(): string {
        return this.destNode.id;
    }
    set destNodeId(x) { }

    static getId(sourceNode: Node, destNode: Node): string {
        return `${destNode.id}<${sourceNode.id}`;
    }
}

import { BaseInputProperties } from './core-base-input-properties';

export class BaseStringInputProperties<T> extends BaseInputProperties<T> {
    maxLength: number;
    valueChangeEvent: string;
    constructor(maxLength = 50,
                readOnly?: boolean,
                disabled?: boolean,
                valueChangeEvent = 'keyup') {

        super(readOnly, disabled);

        this.maxLength = maxLength;
        this.valueChangeEvent = valueChangeEvent;
    }
}

export class ReadOnlyStringInputProperties<T> extends BaseStringInputProperties<T> {
    constructor() {
        super();
        this.readOnly = true;
    }
}

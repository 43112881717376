import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { DxTextBoxModule } from 'devextreme-angular';
import { BaseStringInputProperties } from '../../models/core-string-input-properties';

@Component({
    selector: 'app-core-string-input',
    templateUrl: './core-string-input.component.html',
    styleUrls: ['./core-string-input.component.scss']
})
export class CoreStringInputComponent implements OnInit {

    @Input() props: BaseStringInputProperties<CoreStringInputComponent>;
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();

    constructor() { }

    ngOnInit() {
    }

    handleValueChange(event: any) {
        this.valueChange.emit(this.value);
    }

}

@NgModule({
    imports: [
        DxTextBoxModule,
        CommonModule
    ],
    declarations: [CoreStringInputComponent],
    exports: [CoreStringInputComponent]
})
export class CoreStringInputModule { }

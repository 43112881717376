<div>
    <div [@bubbleZoom]="bubbleAnimationState" class="corebot-chat-bubble-wrapper" *ngIf="showInteraction">
        <div class="image-wrapper" (click)="onWidgetClicked($event)">
            <img class="speech-bubble" src="../../../../../assets/images/chat-bubble.svg">
            <div class="bubble-contents">
                <span [@textFade]="textState" class="speech-bubble-text">{{displayedMessage}}</span>
            </div>
        </div>
        <div class="close-corebot" (click)="onCloseButtonClicked($event)"><span class="close-text">X</span></div>
    </div>
</div>

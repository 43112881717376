import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { DxButtonModule, DxDateBoxModule, DxFormModule, DxLoadPanelModule, DxNumberBoxModule, DxPopupModule, DxSelectBoxModule,
  DxSwitchModule, DxTextBoxModule, DxResizableModule, DxDropDownButtonModule, DxTooltipModule, DxFilterBuilderModule, DxTagBoxModule,
  DxScrollViewModule } from 'devextreme-angular';
import { CoreCustomToolPanelComponent } from './core-custom-tool-panel/core-custom-tool-panel.component';
import { CoreFilterBuilderToolPanelComponent } from './core-filter-builder-tool-panel/core-filter-builder-tool-panel.component';
import { RecordDataViewerRoutingModule } from './record-data-viewer-routing.module';
import { RecordDataViewerComponent } from './record-data-viewer.component';
import { CoreCustomEditorQtyComponent } from './core-custom-editor-qty/core-custom-editor-qty.component';
import { CoreCustomEditorSelectComponent } from './core-custom-editor-select/core-custom-editor-select.component';
import { CoreCustomEditorDateBoxComponent } from './core-custom-editor-date-box/core-custom-editor-date-box.component';
import { CoreCustomLoadingIndicatorComponent } from './core-custom-loading-indicator/core-custom-loading-indicator.component';
import { CoreComponentModule } from 'src/app/shared/components/core-component.module';
import { CoreCustomStatusbarComponent } from './core-custom-statusbar/core-custom-statusbar.component';
import { CoreCustomLeftStatusbarComponent } from './core-custom-left-statusbar/core-custom-left-statusbar.component';

@NgModule({
  declarations: [
    RecordDataViewerComponent,
    CoreCustomToolPanelComponent,
    CoreFilterBuilderToolPanelComponent,
    CoreCustomEditorQtyComponent,
    CoreCustomEditorSelectComponent,
    CoreCustomEditorDateBoxComponent,
    CoreCustomLoadingIndicatorComponent,
    CoreCustomStatusbarComponent,
    CoreCustomLeftStatusbarComponent
  ],
  imports: [
    RecordDataViewerRoutingModule,
    CommonModule,
    AgGridModule,
    DxButtonModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxFormModule,
    DxLoadPanelModule,
    CoreComponentModule,
    DxSwitchModule,
    DxTooltipModule,
    DxPopupModule,
    DxResizableModule,
    DxDropDownButtonModule,
    DxFilterBuilderModule,
    DxTagBoxModule,
    DxScrollViewModule
  ],
  exports: [
    RecordDataViewerComponent
  ]
})
export class RecordDataViewerModule { }

<dx-date-box #input 
    [(value)]="editorValue" 
    type="datetime" 
    [displayFormat]="format"
    class="ag-custom-component-popup inline-cell-editor-box"
    (onValueChanged)="onValueChanged($event)"
    (onOpened)="onOpened()"
    (onClosed)="onClosed($event)"
    [openOnFieldClick]="true">
    <div *dxTemplate="let data of 'dropDownButton'">
        <dx-button icon="event" class="calendar-icon-button"></dx-button>
        <i class="calendar-icon dx-icon dx-icon-event"></i>
    </div>
</dx-date-box>

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { equal } from '@calmdownval/slow-deep-equal';

@Directive({
    selector: '[ngVar]',
})

export class VarDirective {
    @Input() set ngVar(context: any) {
        // using equal function from @calmdownval/slow-deep-equal to prevent circular structure errors
        // equality check evaluates properties and not just references to prevent rerendering when a function is used for variable assignment
        if (!equal(this.context.ngVar, context, true)) {
            this.context.$implicit = this.context.ngVar = context;
            this.updateView();
        }
    }

    context: any = {};

    constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {

    }

    updateView() {
        this.vcRef.clear();
        this.vcRef.createEmbeddedView(this.templateRef, this.context);
    }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { GridProps } from '../../models/core-data-grid-properties';
import { CoreColumn } from '../../models/core-column';
import { CoreEventArguments } from '../../models/core-event-arguments';
import { CoreGridComponent } from '../core-data-grid/core-data-grid.component';

@Component({
    selector: 'core-table-drop-down',
    templateUrl: './core-table-drop-down.component.html',
    styleUrls: ['./core-table-drop-down.component.scss']
})

export class CoreTableDropDownComponent implements OnInit {
    @Input() columns: CoreColumn[];
    @Input() props: GridProps = new GridProps();
    @Input() parentColumn: CoreColumn;
    @Input() selectedValue: any;
    @Input() dropDownOptions: any;

    // Non-required inputs
    @Input() parentTemplate: any;

    @ViewChild('coreTableDropDownGrid', { static: false }) coreTableDropDownGrid: CoreGridComponent;
    @ViewChild('coreTableDropDownDropDown', { static: false }) coreTableDropDownDropDown: DxDropDownBoxComponent;

    propsSelectionChangedFunctionHolder: (e: CoreEventArguments) => void;

    constructor() {

    }

    ngOnInit() {
        this.propsSelectionChangedFunctionHolder = this.props.onSelectionChangedFunction;
        this.props.onSelectionChangedFunction = this.componentSelectionChangedFunction.bind(this);
    }

    componentSelectionChangedFunction(e: CoreEventArguments) {
        if (e.event.selectedRowKeys) {
            this.selectedValue = e.event.selectedRowKeys[0];

            if (this.parentTemplate && this.parentTemplate.setValue) {
                this.parentTemplate.setValue(this.selectedValue);
            }
        }

        if (this.parentColumn.columnTemplateProps.isClosedOnSelection && this.coreTableDropDownGrid && this.coreTableDropDownGrid.parentTemplate
            && this.coreTableDropDownGrid.parentTemplate.component && this.coreTableDropDownGrid.parentTemplate.component.close) {

            this.coreTableDropDownGrid.parentTemplate.component.close();
        }

        if (this.propsSelectionChangedFunctionHolder) {
            this.propsSelectionChangedFunctionHolder(new CoreEventArguments(e, null, this));
        }
    }
}

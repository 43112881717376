import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GearPropertyService } from 'src/app/pages/building-blocks/gear-property.service';
import { PeriodTypes } from '../../constants/enums';
import { CoreColumn } from '../../models/core-column';
import { GridProps } from '../../models/core-data-grid-properties';
import { CoreDropdownPopupProperties } from '../../models/core-dropdown-popup-properties';
import { CoreDropdownProperties } from '../../models/core-dropdown-properties';
import { Period } from '../../models/period';
import { HelperService } from '../../services/helper.service';
import { PeriodService } from '../../services/period.service';

@Component({
    selector: 'app-bb-period-range-input',
      templateUrl: './bb-period-range-input.component.html',
      styleUrls: ['./bb-period-range-input.component.scss']
})
export class BbPeriodRangeInputComponent implements OnInit {
    @Input() selectedRecurrenceId: number;
    @Input() actualValue: string;
    @Output() actualValueChange: EventEmitter<string> = new EventEmitter<string>();
    periodType = PeriodTypes;
    gearPropertyMappings: Record<string, any>;
    periodDropDownDatasource: any = null;
    dropDownValue: string;
    optionalNumericInput1: number = 0;
    optionalNumericInput2: number = 0;
    optionalCheckBox: boolean = false;
    optionalStaticPeriod: any;

    periodDropdownProps: CoreDropdownProperties[] = [];
    selectedPeriodId: number = 0;
    selectedSeriesId: number = 1;

    constructor(private gearPropertyService: GearPropertyService, private helperService: HelperService, private toast: ToastrService) {
        this.periodDropdownProps['period'] = new CoreDropdownProperties().createPeriodDropdownDefaults('dateRange', false, false, false, 'Period');
    }

    ngOnInit(): void {
        this.parsePeriodFilterValue();
        this.gearPropertyService.getMappings().subscribe(res => {
            this.gearPropertyMappings = res;
            this.periodDropDownDatasource = this.gearPropertyMappings['periodFilterString'];
        });
    }

    parsePeriodFilterValue() {
        this.actualValue ??= '.';
        if(this.actualValue === 'Non-recurring period and current period') {
            this.dropDownValue = '.';
            return;
        }
        const actualValueSplit = this.actualValue.split('-');
        this.dropDownValue = actualValueSplit[0];

        this.optionalNumericInput1 = actualValueSplit[1] ? parseInt(actualValueSplit[1], 10) : this.optionalNumericInput1;
        this.optionalNumericInput2 = actualValueSplit[2] ? parseInt(actualValueSplit[2], 10) : this.optionalNumericInput2;
        this.optionalCheckBox = this.optionalNumericInput2 > 0;

        if (!Object.values(PeriodTypes).includes(this.dropDownValue as PeriodTypes)) {
            this.toast.error('Error: Invalid Period Filter Type');
        }
    }

    emitNewValue(e) {
        if (Object.values(PeriodTypes).includes(e.previousValue)) {
            this.optionalNumericInput1 = this.dropDownValue === this.periodType.Rolling || this.dropDownValue === this.periodType.Static ? 0 : 1;
            this.optionalNumericInput2 = 1;
        }

        switch (true) {
            case this.dropDownValue === this.periodType.Standard:
                this.actualValueChange.emit(this.dropDownValue);
                break;
            case this.dropDownValue === this.periodType.Mtd:
                this.actualValueChange.emit(`${this.dropDownValue}-${this.optionalNumericInput1}`);
                break;
            case this.dropDownValue === this.periodType.Qtd:
            case this.dropDownValue === this.periodType.Ttd:
            case this.dropDownValue === this.periodType.Std:
            case this.dropDownValue === this.periodType.Ytd:
            case this.dropDownValue === this.periodType.PriorMtd:
            case this.dropDownValue === this.periodType.PriorQtd:
            case this.dropDownValue === this.periodType.PriorTtd:
            case this.dropDownValue === this.periodType.PriorStd:
            case this.dropDownValue === this.periodType.PriorYtd:
            case this.dropDownValue === this.periodType.PriorYearMtd:
            case this.dropDownValue === this.periodType.PriorYearQtd:
                this.actualValueChange.emit(`${this.dropDownValue}-${this.optionalNumericInput1}-${this.optionalNumericInput2}`);
                break;
            case this.dropDownValue === this.periodType.Forward:
                this.actualValueChange.emit(this.dropDownValue);
                break;
            case this.dropDownValue === this.periodType.Rolling:
                this.actualValueChange.emit(`${this.dropDownValue}-${this.optionalNumericInput1}-${this.optionalCheckBox ? 1 : 0}`);
                break;
            case this.dropDownValue === this.periodType.Static:
                this.actualValueChange.emit(`${this.dropDownValue}-${this.selectedPeriodId}-${this.optionalNumericInput1}`);
                break;
            default:
                this.toast.error('Error: Invalid Period Filter Type');
        }
    }
}

import { Injectable } from '@angular/core';
import { SiteThemeConfig } from '../models/site-theme-config';
import { HelperService } from './helper.service';
import { SiteThemeDefault, SiteThemeDefaultDark, SiteThemeBI, SiteThemeBIDark } from '../../shared/constants/theme-constants';
import { BooleanInput } from '@angular/cdk/coercion';

@Injectable()
export class SiteThemeService {
    private darkModeLocalStorageKey: string = 'CORE_ISDARKMODE';

    constructor(private helperService: HelperService) {

    }

    applySiteTheme(doc: any, siteThemeConfig: SiteThemeConfig): void {
        // Remove existing site theme classes
        Array.from(doc.body.classList).forEach(className => {
            if (className.toString().startsWith('dx-swatch') || className === 'core-dark-mode') {
                doc.body.classList.remove(className);
            }
        });

        // Add site theme classes
        if (!this.helperService.isNullOrEmpty(siteThemeConfig.baseClassName)) {
            siteThemeConfig.baseClassName.split(' ').forEach(className => {
                doc.body.classList.add(className);
            });
        }

        // These variables can be used in the CSS and SCSS files throughout the site
        // Assign CSS variables - these variables can be used in the CSS and SCSS files throughout the site
        Object.keys(siteThemeConfig.colorValues).forEach(colorValueName => {
            doc.documentElement.style.setProperty(`--${colorValueName}`, siteThemeConfig.colorValues[colorValueName]);
        });
    }

    getSiteThemeConfig(isBITheme: BooleanInput, isDarkMode: boolean = null): SiteThemeConfig {
        if (isDarkMode === null) {
            isDarkMode = this.getIsDarkMode();
        }

        if (!isBITheme) {
            if (!isDarkMode) {
                return SiteThemeDefault;
            } else {
                return SiteThemeDefaultDark;
            }
        } else {
            if (!isDarkMode) {
                return SiteThemeBI;
            } else {
                return SiteThemeBIDark;
            }
        }
    }

    getIsDarkMode(): boolean {
        return localStorage.getItem(this.darkModeLocalStorageKey) === true.toString();
    }

    setIsDarkMode(isDarkMode: boolean): void {
        localStorage.setItem(this.darkModeLocalStorageKey, isDarkMode.toString());
    }
}

<dx-popup
    title="Email Settings"
    [showTitle]="true"
    [height]="580"
    [width]="450"
    [hideOnOutsideClick]="false"
    [showCloseButton]="true"
    [(visible)]="isPopupVisible"
    (onShowing)="getEmailSettings()"
    (onHidden)="onHidden()">
    <dx-scroll-view [height]="450">
        <dx-form labelLocation="left" [readOnly]="isSettingsEditingRestricted" [(formData)]="emailSettingsForm">
            <dxi-item dataField="reportFormat" editorType="dxSelectBox" [editorOptions]="{ dataSource: ['PDF','EXCEL','XLXS','HTML']}"></dxi-item>
            <dxi-item dataField="bccSender" caption="BCC Sender" editorType="dxCheckBox"></dxi-item>

            <dxi-item itemType="group" caption="SMTP Server">
                <dxi-item dataField="host"></dxi-item>
                <dxi-item dataField="port"></dxi-item>
                <dxi-item dataField="useSsl" caption="Use SSL" editorType="dxCheckBox"></dxi-item>
                <dxi-item dataField="user"></dxi-item>
                <dxi-item dataField="password" [editorOptions]="{ mode: 'password' }"></dxi-item>
                <dxi-item
                    dataField="timeout"
                    editorType="dxNumberBox"
                    [editorOptions]="{
                        min: 0,
                        step: 60,
                        mode: 'text',
                        showSpinButtons: true
                    }">
                </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" caption="SMTP Sender">
                <dxi-item dataField="address"></dxi-item>
                <dxi-item dataField="displayName"></dxi-item>
                <dxi-item dataField="displayAddress"></dxi-item>
            </dxi-item>
        </dx-form>
    </dx-scroll-view>

    <ng-container *ngIf="isSettingsEditingRestricted else updateButtons">
        <dx-button text="Close" (onClick)="closeEmailSettings()"></dx-button>
    </ng-container>
    <ng-template #updateButtons>
        <div id="email-settings-controls">
            <dx-button text="Test Email" (onClick)="testEmail()"></dx-button>
            <div>
                <dx-button text="Save" (onClick)="updateEmailSettings()"></dx-button>
                <dx-button text="Cancel" (onClick)="closeEmailSettings()"></dx-button>
            </div>
        </div>
    </ng-template>
</dx-popup>

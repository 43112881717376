<div class="core-dashboard-viewer">
    <div [hidden]="mode === 'Viewer'">
        <dx-button text="Preview" icon="video" (onClick)="onClickPreview()" class="dashboard-setting"></dx-button>
        <dx-check-box [(value)]="isPublic" text="Public" class="dashboard-setting"></dx-check-box>
        <dx-check-box [(value)]="includeZeroes" text="Include Zeroes" class="dashboard-setting"></dx-check-box>
        <div class="dashboard-setting">
            <span class="dashboard-setting-label">Default Priority:</span>
            <dx-number-box [(value)]="defaultPriority" [min]="1" [max]="99" [showSpinButtons]="true" class="dashboard-setting-numberbox" stylingMode="underlined"></dx-number-box>
        </div>
        <div class="dashboard-setting">
            <span class="dashboard-setting-label">Recurrence:</span>
            <dx-select-box
                [(value)]="recurrenceId"
                [dataSource]="recurrences"
                (onValueChanged)="onRecurrenceChanged()"
                valueExpr="id"
                displayExpr="name"
                class="dashboard-setting-selectbox"
                stylingMode="underlined">
            </dx-select-box>
        </div>
        <div class="dashboard-setting">
            <span class="dashboard-setting-label">Default Layout:</span>
            <dx-select-box
                [(value)]="defaultUiViewId"
                [dataSource]="globalDistributionLists"
                valueExpr="id"
                displayExpr="name"
                class="dashboard-setting-selectbox dashboard-setting-selectbox-large"
                stylingMode="underlined">
            </dx-select-box>
        </div>
        <dx-check-box
            [(value)]="showInactiveSegments"
            text="Show Inactive Segments"
            class="dashboard-setting"
            (onValueChanged)="onShowInactiveSegmentsChange($event)">
        </dx-check-box>
    </div>
    <dx-dashboard-control
        #dashboardControl
        [ngClass]="{'admin-viewer': hasAdminView && mode === MODE_VIEWER, 'admin-designer': hasAdminView && mode === MODE_DESIGNER}"
        [endpoint]="endpoint"
        [workingMode]="mode"
        [ajaxRemoteService]="ajaxRemoteServiceOptions"
        [extensions]="EXTENSION_OPTIONS"
        [loadDefaultDashboard]="false"
        (onBeforeRender)="onBeforeRender($event)"
        (onDashboardBeginUpdate)="onDashboardBeginUpdate($event)"
        (onDashboardInitialized)="onDashboardInitialized($event)">
        <dxo-extensions>
            <dxo-viewer-api [onItemWidgetOptionsPrepared]="itemWidgetOptionsPrepared"></dxo-viewer-api>
        </dxo-extensions>
    </dx-dashboard-control>
</div>
<dx-load-panel
    #loadPanel
    [wrapperAttr]="{ class: 'spinning-gears-load-panel' }"
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '.core-dashboard-viewer' }"
    [visible]="loadingVisible && showLoadingPanel"
    (visibleChange)="loadingVisible && (showLoadingPanel = $event)"
    [indicatorSrc]="indicatorUrl"
    [showIndicator]="true"
    [showPane]="false"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>

<dx-popup
    [height]="600"
    [width]="1000"
    [minWidth]="900"
    [minHeight]="500"
    [showTitle]="true"
    title="Dashboard Viewer"
    [dragEnabled]="true"
    container=".dx-viewport"
    [resizeEnabled]="true"
    (onResize)="onResize($event)"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="previewPopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 60'
    }"
    class="preview-popup">
    <div class="preview-dashboard-settings-container">
        <div class="preview-dashboard-settings">
            <div class="dashboard-setting">
                <span class="dashboard-setting-label">Series:</span>
                <dx-drop-down-box
                    [(value)]="previewSeriesIds"
                    [dataSource]="series"
                    (onValueChanged)="previewSettingsChanged()"
                    valueExpr="id"
                    displayExpr="name"
                    class="dashboard-setting-dropdownbox">
                    <div *dxTemplate="let data of 'content'">
                        <dx-tree-list
                            [(selectedRowKeys)]="previewSeriesIds"
                            [dataSource]="series"
                            keyExpr="id"
                            [showRowLines]="false"
                            [showBorders]="false"
                            [showColumnHeaders]="false"
                            class="dashboard-setting-series-list">
                            <dxo-selection mode="multiple"></dxo-selection>
                            <dxo-sorting mode="multiple"></dxo-sorting>
                            <dxi-column dataField="id" [visible]="false"></dxi-column>
                            <dxi-column dataField="name"></dxi-column>
                            <dxi-column dataField="isDeleted" [visible]="false"></dxi-column>
                            <dxi-column dataField="isIdentity" [visible]="false"></dxi-column>
                        </dx-tree-list>
                    </div>
                    <dx-validator>
                        <dxi-validation-rule type="required" message="Series is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-drop-down-box>
            </div>
            <div class="dashboard-setting">
                <span class="dashboard-setting-label">Date:</span>
                <dx-select-box
                    [dataSource]="periodsBeginSorted"
                    valueExpr="beginDate"
                    displayExpr="beginDate"
                    [(value)]="previewBeginDate"
                    (onValueChanged)="previewSettingsChanged(true, false)"
                    fieldTemplate="field"
                    class="dashboard-setting-dropdownbox"
                    stylingMode="underlined">
                    <div *dxTemplate="let data of 'field'">
                        <dx-text-box [value]="data && data.beginDate | date" [readOnly]="true" stylingMode="underlined"></dx-text-box>
                    </div>
                    <div *dxTemplate="let data of 'item'">
                        {{ data.beginDate | date }}
                    </div>
                </dx-select-box>
                <span class="dashboard-setting-label dashboard-setting-label-to">to</span>
                <dx-select-box
                    [dataSource]="periodsEndSorted"
                    valueExpr="endDate"
                    displayExpr="endDate"
                    [(value)]="previewEndDate"
                    (onValueChanged)="previewSettingsChanged(false, true)"
                    fieldTemplate="field"
                    class="dashboard-setting-dropdownbox"
                    stylingMode="underlined">
                    <div *dxTemplate="let data of 'field'">
                        <dx-text-box [value]="data && data.endDate | date" [readOnly]="true" stylingMode="underlined"></dx-text-box>
                    </div>
                    <div *dxTemplate="let data of 'item'">
                        {{ data.endDate | date }}
                    </div>
                </dx-select-box>
            </div>
            <div class="dashboard-setting">
                <dx-button
                    text="Account Filter"
                    icon="detailslayout"
                    (click)="onClickAccountFilter()"
                    [visible]="!accountFilterVisible"
                    class="dashboard-setting-button">
                </dx-button>
                <dx-button
                    text="Back to Dashboard"
                    icon="arrowleft"
                    (click)="onClickBackToDashboard()"
                    [visible]="accountFilterVisible"
                    class="dashboard-setting-button dx-button-default">
                </dx-button>
            </div>
        </div>
    </div>

    <div class="preview-dashboard-container" [style.display]="!accountFilterVisible ? '' : 'none'">
        <dx-dashboard-control
            #previewDashboardControl
            [endpoint]="endpoint"
            workingMode="Viewer"
            [ajaxRemoteService]="ajaxRemoteServiceOptions"
            [loadDefaultDashboard]="false">
        </dx-dashboard-control>
    </div>

    <div [style.display]="accountFilterVisible ? '' : 'none'">
        <dx-data-grid
            [dataSource]="sellers"
            keyExpr="id"
            [(selectedRowKeys)]="previewSelectedSellerIds"
            [height]="430"
            class="preview-dashboard-sellers">
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-group-panel [visible]="true" emptyPanelText="Drag columns here to group."></dxo-group-panel>
            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple"></dxo-sorting>
            <dxi-column dataField="name" caption="Account Name" sortOrder="asc"></dxi-column>
            <dxi-column *ngFor="let class of attributeClasses" [caption]="class.friendlyName" [dataField]="class.name"></dxi-column>
            <dxi-column dataField="isActive" caption="Active?" dataType="boolean"></dxi-column>
            <dxi-column dataField="isPayee" caption="Payee?" dataType="boolean"></dxi-column>
        </dx-data-grid>

        <div class="preview-dashboard-settings-footer">
            <div class="dashboard-setting">
                <span class="dashboard-setting-label">Viewable Accounts:</span>
                <dx-select-box [items]="VIEWABLE_ACCOUNTS_OPTIONS" [(value)]="previewViewableAccounts" class="dashboard-setting-dropdownbox" stylingMode="underlined"></dx-select-box>
            </div>
        </div>
    </div>

    <dx-load-panel
        #previewLoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: '#previewDashboardControl' }"
        [(visible)]="previewLoadingVisible"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>
</dx-popup>

<dx-popup
    #deletePopup
    height="200"
    width="400"
    [showTitle]="true"
    title="Delete Dashboard"
    [dragEnabled]="false"
    [hideOnOutsideClick]="true"
    [showCloseButton]="true"
    [(visible)]="deletePopupVisible"
    [position]="{
        my: 'top center',
        at: 'top center',
        of: 'window',
        offset: '0 180'
    }"
    class="delete-dashboard-popup">
    <dx-responsive-box>
        <dxi-row [ratio]="3"></dxi-row>
        <dxi-row [ratio]="2"></dxi-row>

        <dxi-col [ratio]="2"></dxi-col>
        <dxi-col [ratio]="4"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>
        <dxi-col [ratio]="3"></dxi-col>

        <dxi-item class="icon">
            <dxi-location [row]="0" [col]="0"></dxi-location>
            <i class="dx-icon dx-icon-help"></i>
        </dxi-item>
        <dxi-item class="message">
            <dxi-location [row]="0" [col]="1" [colspan]="3"></dxi-location>
            <span>Delete dashboard '{{ dashboardName }}'?</span>
        </dxi-item>
        <dxi-item class="yes-button">
            <dxi-location [row]="1" [col]="0" [colspan]="2"></dxi-location>
            <dx-button #deleteYesButton (click)="deleteDashboard()" class="dx-button-default">Yes</dx-button>
        </dxi-item>
        <dxi-item class="cancel-button">
            <dxi-location [row]="1" [col]="2" [colspan]="2"></dxi-location>
            <dx-button (click)="deletePopupVisible = false">No</dx-button>
        </dxi-item>
    </dx-responsive-box>
</dx-popup>

<dx-popup
    title="Error: Dashboard not available"
    [(visible)]="this.isErrorPopupVisable"
    width="300"
    height="200"
    [showCloseButton]="false"
    [hideOnOutsideClick]="false"
    (onHidden)="handleDashboardRedirect()">
    <div style="margin-left: auto; margin-right: auto">
        <p>Dashboard no longer available. Please contact your administrator for any further questions.</p>
        <dx-button text="Ok" (onClick)="handleDashboardRedirect()"> </dx-button>
    </div>
</dx-popup>

<app-reporting-parameters
    #selector
    (generate)="generateDashboard(this.dashboardId)"
    seriesMode="multi"
    [(isPopupVisible)]="isReportParamsVisible">
</app-reporting-parameters>

<div id="bar-container">
    <div id="quick-search">
        <dx-text-box #searchInput placeholder="Quick Search" (onValueChanged)="sendQuickSearch()" [(value)]="quickSearchValue" stylingMode="underlined"></dx-text-box>
    </div>
    <dx-button 
        [icon]="currentLayoutId === storedDefaultLayoutId ? 'pin' : 'unpin'"
        [disabled]="!currentLayoutId"
        [hint]="(currentLayoutId === storedDefaultLayoutId ? 'Remove' : 'Set as') + ' default layout for this data source'"
        (onClick)="setDefaultLayout($event)"
        class="core-dropdown-floating-element">
    </dx-button>
    <core-dropdown
        [props]="layoutProps"
        [(selectedValue)]="selectedLayout"
        [dataSource]="layouts"
        (selectionChanged)="onLayoutChanged($event)"
        (onDelete)="deleteLayout($event)"
        (onAdd)="addLayout($event)"
        (onRename)="renameLayout($event)"
        (onSave)="saveLayout($event)"
        (onCheckUncheck)="onLayoutCheckBoxClick($event)">
    </core-dropdown>
</div>

import { SellerImport } from './seller-import';
import { User } from './user';
import { IgCache } from './ig-cache';
import { HistSeller } from './hist-seller';
import { AccountFactor } from './account-factor';
import { Permission } from './permission';

export class Seller {
    id: number;
    name: string;
    emailAddress: string;
    isPayee: boolean;
    isActive: boolean | ((seller: Seller) => boolean);
    loginName: string;
    password: string;
    passsalt: string;
    passwordExpiration: Date;
    externalId: string;
    billingCode: string;
    descr: string;
    userGroupId: number;
    importNameId: number;
    userId: string;
    startDate: Date;

    user: User;
    permission: Permission;
    sellerImports: SellerImport[];
    importName: string;
    importNamesString: string;
    subordinates: Seller[];
    // bossSellerCriterions: BossSellerCriterion[]
    // calculations: Calculation[]
    histSellerBosses: HistSeller[];
    histSellerParents: HistSeller[];
    histSellers: HistSeller[];
    igCaches: IgCache[];
    accountFactors: AccountFactor[];
    // sellerCriterions: SellerCriterion[];
    // sellerRedirs: SellerRedir[];
    // settings: Setting[];
    // taskExecution:s: TaskExecution[];
    // workflowTasks WorkflowTask[];
    // xactions: Xaction[];

    parentId: number;
    hasPassword: boolean;
}

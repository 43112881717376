<dx-popup class="core-bot-popup"
    [wrapperAttr]="{class:'core-bot-popup'}"
    [width]="555"
    [height]="650"
    [showTitle]="true"
    [title]="chatTitle"
    [shading]="false"
    [dragEnabled]="true"
    container=".dx-viewport"
    [showCloseButton]="true"
    [(visible)]="showPopup"
    [position]="position"
    (onHiding)="onHiding($event)"
    (onContentReady)="onContentReady($event)"
    (onShown)="scrollMessagesToBottom($event)">
        <dx-scroll-view width="100%" height="100%" useNative="true">
            <div class="rulebot-disclaimer">
                <div id="rulebot-disclaimer-title" class="disclaimer-message">
                    Click to View AI Technology Disclaimer
                </div>
                <dx-tooltip
                    target="#rulebot-disclaimer-title"
                    showEvent="click"
                    [hideOnOutsideClick]="true"
                    width="300px"
                >
                    <div *dxTemplate="let data = data; of: 'content'"> AuditBot and RuleBot are AI solutions in testing. While both tools are very
                        powerful, they do not always produce accurate results. Core Commissions does not make any guarantee of
                        the accuracy of RuleBot's or AuditBot's results. If you have any doubt about these tools' output, please reach out to the 
                        Core Commissions implementation team.
                    </div>
                </dx-tooltip>
            </div>
            <div class="nb-theme-default rulebot-chat">
                <corebot-popup-content #chatContent
                    class="rulebot-theme"
                    [messages]="messages" 
                    [sellerName]="sellerName"
                    size="small"
                    (confirmAction)="getFollowUpResponse($event)"
                    (confirmRedirect)="redirectUser($event)"
                    (denyAction)="resetChat($event)">

                </corebot-popup-content>
                <div class="rulebot-chat-form" #ruleBotChat id="ruleBotChat">
                    <div class="rulebot-message-row">
                        <textarea #ruleBotTextArea nbInput fullWidth placeholder="Textarea" 
                            id="ruleBotTextArea"
                            class="rulebot-textarea"
                            [(ngModel)]="message" 
                            (ngModelChange)="onModelChange($event)"
                            (keydown.enter)="submitText($event)"
                            resize="none"
                            autocomplete="off"
                            [placeholder]="samplePrompt"></textarea>
                        <button
                            nbButton
                            #sendButton
                            (click)="submitText($event)"
                            class="send-button"
                            status="primary"
                        >
                            <nb-icon [icon]="buttonIcon" pack="nebular-essentials"></nb-icon>
                        </button> 
                    </div>
                </div>
            </div>
        </dx-scroll-view>
    <dxi-toolbar-item #newConversationButton
        widget="dxButton"
        toolbar="bottom"
        [options]="{
            text: 'New Conversation',
            stylingMode: 'outlined',
            type: 'normal',
            onClick: clearCoreBotConversation
        }">
    </dxi-toolbar-item>
    <dxi-toolbar-item #contactSupportButton
        widget="dxButton"
        toolbar="bottom"
        [visible]="isSupportVisible"
        [options]="{
            text: 'Contact Core Support',
            visible: isSupportVisible,
            stylingMode: 'outlined',
            type: 'normal',
            onClick: showTicketWidget
        }">
    </dxi-toolbar-item>
</dx-popup>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreRequest } from '../models/core-request';
import { CoreResponse } from '../models/core-response';
import { HistSeller } from '../models/hist-seller';
import { HistSellerBatchChanges } from '../models/hist-seller-batch-changes';
import { HistSellerCreationInfo } from '../models/hist-seller-creation-info';
import { HistSellerAuditResults } from '../models/hist-seller-audit-results';

@Injectable()
export class HistSellerService {
    importURL: string;
    private baseurl: string = environment.apiEndpoint;
    private histSellersURL = this.baseurl + '/histseller/';

    constructor(private http: HttpClient) {
        this.importURL = `${this.histSellersURL}import`;
    }

    getHistSellers(id: number): Observable<HistSeller[]> {
        return this.http.get<HistSeller[]>(this.histSellersURL + id);
    }

    getMultipleHistSellers(sellerIds: number[]): Observable<HistSeller[]> {
        return this.http.post<HistSeller[]>(this.histSellersURL + 'getmultiple', sellerIds);
    }

    insertHistSeller(histSeller: HistSeller): Observable<HistSeller> {
        return this.http.post<HistSeller>(this.histSellersURL + 'insert', histSeller);
    }

    insertHistSellers(histSellers: HistSeller[]): Observable<HistSeller[]> {
        return this.http.post<HistSeller[]>(this.histSellersURL + 'insertmany', histSellers);
    }

    createHistSeller(histSellerInfo: HistSellerCreationInfo): Observable<CoreResponse<HistSeller>> {
        const request = new CoreRequest<HistSellerCreationInfo>(histSellerInfo, null);
        return this.http.post<CoreResponse<HistSeller>>(this.histSellersURL + 'create', request);
    }

    updateHistSeller(histSeller: HistSeller): Observable<HistSeller> {
        return this.http.put<HistSeller>(this.histSellersURL + 'update', histSeller);
    }

    updateHistSellers(histSellers: HistSeller[]): Observable<HistSeller[]> {
        return this.http.post<HistSeller[]>(this.histSellersURL + 'updatemany', histSellers);
    }

    deleteHistSeller(histSeller: HistSeller): Observable<CoreResponse<number>> {
        return this.http.post<CoreResponse<number>>(this.histSellersURL + 'delete', histSeller);
    }

    deleteHistSellers(histSellers: HistSeller[]): Observable<number> {
        return this.http.post<number>(this.histSellersURL + 'deletemany', histSellers);
    }

    batchChangeHistSellers(changes: HistSellerBatchChanges): Observable<CoreResponse<HistSellerAuditResults>> {
        return this.http.post<CoreResponse<HistSellerAuditResults>>(this.histSellersURL + 'batchchanges', changes);
    }

    getCurrentHistSeller(histSellers: HistSeller[]): HistSeller {
        return histSellers.find(hist => new Date(hist.beginDate) < new Date() && (hist.endDate === null || new Date(hist.endDate) > new Date()));
    }

    getCheckManagers(): Observable<CoreResponse<HistSellerAuditResults>> {
        return this.http.get<CoreResponse<HistSellerAuditResults>>(this.histSellersURL + 'checkmanagersvalid');
    }
}


import { AfterViewInit, Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Seller } from '../../shared/models/seller';
import { SellerService } from '../../shared/services/seller.service';
import { EmailService } from '../../shared/services/email.service';
import { HistSellerService } from '../../shared/services/hist-seller.service';
import { SellerImportService } from '../../shared/services/seller-import.service';
import { AccountAttributeClassService } from '../../shared/services/account-attributeClass.service';
import { AccountAttributeService } from '../../shared/services/account-attribute.service';
import { HelperService } from '../../shared/services/helper.service';
import { CoreColumn } from '../../shared/models/core-column';
import { GridProps } from 'src/app/shared/models/core-data-grid-properties';
import { DevExpressDMLFunctions } from 'src/app/shared/models/dev-express-dml-functions';
import { Attribute } from '../../shared/models/attribute';
import { ColumnType } from '../../shared/models/core-column-type';
import { HistSeller } from '../../shared/models/hist-seller';
import { SellerCreationInfo } from '../../shared/models/seller-creation-info';
import { HistSellerCreationInfo } from '../../shared/models/hist-seller-creation-info';
import { AccountFactor } from '../../shared/models/account-factor';
import { BatchChanges } from '../../shared/models/batch-changes';
import { forkJoin, Subscription, BehaviorSubject, of, Observable } from 'rxjs';
import { CoreEventArguments } from 'src/app/shared/models/core-event-arguments';
import { AccountFactorService } from 'src/app/shared/services/account-factor.service';
import { CoreValidationRule } from 'src/app/shared/models/core-validation-rule';
import { CorePopupProperties } from 'src/app/shared/models/core-popup-properties';
import { CorePopupStep } from 'src/app/shared/models/core-popup-step';
import { CorePopupComponent } from 'src/app/shared/components/core-popup/core-popup.component';
import { EmailComponent } from '../email/email.component';
import { EmailContext } from 'src/app/shared/models/contexts/email-context';
import { SendEmailResult } from 'src/app/shared/models/send-email-result';
import { GridColumn } from 'devexpress-dashboard/model';
import { SellerImport } from 'src/app/shared/models/seller-import';
import { CoreDataGridCellTemplates, devExtremeDMLStrings, EditModes, RowRenderingModes, ScrollingModes } from 'src/app/shared/constants/dev-extreme-enums';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreResponse } from 'src/app/shared/models/core-response';
import { CoreGridComponent } from 'src/app/shared/components/core-data-grid/core-data-grid.component';
import { HistSellerBatchChanges } from 'src/app/shared/models/hist-seller-batch-changes';
import { AccountFactorBatchChanges } from 'src/app/shared/models/account-factor-batch-changes';
import { HistSellerAttribute } from 'src/app/shared/models/hist-seller-attribute';
import { CoreDynamicInputProperties } from 'src/app/shared/models/core-dynamic-input-properties';
import { HierarchyComponent } from 'src/app/shared/components/hierarchy/hierarchy.component';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { AttributeClass } from 'src/app/shared/models/attribute-class';
import { CoreEditorOptions } from 'src/app/shared/models/core-editor-options';
import { UiView } from 'src/app/shared/models/ui-view';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Permission } from 'src/app/shared/models/permission';
import { confirm } from 'devextreme/ui/dialog';
import { CoreDataGridChange } from 'src/app/shared/models/core-data-grid-change';
import { CoreFeature, coreResponseCodes, EnumUserGroup, uiViewContextCodes, uiViewSystemCodes } from 'src/app/shared/constants/enums';
import { SellerBatchChangesContext } from 'src/app/shared/models/contexts/seller-batch-changes-context';
import { HistSellerAuditResults } from 'src/app/shared/models/hist-seller-audit-results';
import { SellerBatchChanges } from 'src/app/shared/models/seller-batch-changes';
import { FileProcessingService } from 'src/app/shared/services/file-processing.service';
import DataSource from 'devextreme/data/data_source';
import { CoreRequest } from 'src/app/shared/models/core-request';
import { ProcessLogService } from 'src/app/shared/services/process-log.service';
import { ImportCleaningSourceData } from 'src/app/shared/models/import-cleaning-source-data';
import { SellerName } from 'src/app/shared/models/seller-name';
import { User } from 'src/app/shared/models/user';
import { AppElementsService } from 'src/app/shared/services/app-element.service';
import { PollValue } from 'src/app/shared/models/poll-value';
import { CoreImportComponent } from 'src/app/shared/components/core-import/core-import.component';
import { InactiveManager } from 'src/app/shared/models/inactive-manager';
import { PeriodService } from 'src/app/shared/services/period.service';
import { Period } from 'src/app/shared/models/period';

@Component({
    selector: 'app-account-management',
    templateUrl: './account-management.component.html',
    styleUrls: ['./account-management.component.scss']
})

export class AccountManagementComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('passwordConfirmationPopup', {static: false})
    passwordConfirmationPopup: CorePopupComponent;
    @ViewChild('sellerGrid', {static: false})
    sellerGrid: CoreGridComponent;
    @ViewChild('histSellerGrid', {static: false})
    histSellerGrid: CoreGridComponent;
    @ViewChild('factorGrid', {static: false})
    factorGrid: CoreGridComponent;
    @ViewChild('popupImportGrid', {static: false})
    popupImportGrid: CoreGridComponent;
    @ViewChild('addSellerForm', {static: false})
    addSellerForm: DxFormComponent;
    @ViewChild('editSellerForm', {static: false})
    editSellerForm: DxFormComponent;
    @ViewChild('coreImport', { static: false })
    coreImport: CoreImportComponent;

    sellers: Seller[];
    sellerNames: SellerName[];
    sellerMappings: any[];
    attributeClasses: AttributeClass[];
    attributes: Attribute[];
    attributeLookups: any;
    permissions: Permission[];
    availablePermissions: Permission[];
    sellerColumns: CoreColumn[];
    sellerGridProps: GridProps;
    sellerGridSelected: Seller;
    sellerGridSelectedMultiple: Seller[];
    sendEmailResults: SendEmailResult[];
    payeeCount: number = 0;
    isPayeeCountButtonReduced: boolean = false;
    activeSellerIds: number[] = [];
    addSellerPopupVisible: boolean = false;
    addSellerNameOptions: any;
    addSellerPopupSaveButtonOptions: any;
    addSellerPopupCancelButtonOptions: any;
    newSeller: SellerCreationInfo = new SellerCreationInfo('', '', null);
    sellerDeleting: boolean = false;
    sellerGridLayoutId: number = 0;
    hasUnsavedGridChanges: boolean = false;
    columnsLoaded: boolean = false;
    isLoadingVisible: boolean = false;
    passwordGenerated: boolean = false;

    leftPanelMinWidth: number = 320;
    rightPanelMinWidth: number = 450;
    leftPanelMaxWidth: number;
    leftPanelWidth: number;
    rightPanelWidth: number;
    leftPanelWidthPercentage: number = 0.33;  // default
    rightPanelWidthPercentage: number = 0.67; // default
    pageDestroyed: boolean = false;

    permissionAddSellerField: boolean = false;
    permissionDeleteSeller: boolean = false;
    permissionEditSeller: boolean = false;
    permissionEditAccountHistory: boolean = false;
    permissionDeleteAccountHistory: boolean = false;
    permissionAddAccountHistory: boolean = false;
    permissionEditAccountSecurity: boolean = false;
    permissionAddFactor: boolean = false;
    permissionEditFactor: boolean = false;
    permissionDeleteFactor: boolean = false;
    permissionViewAuditButton: boolean = false;
    canOpenSellerPopup: boolean = false;
    isImplementer: boolean = false;

    editSellerPopupVisible: boolean = false;
    openSeller: Seller = new Seller();
    editSellerPopupSaveButtonOptions: any;
    editSellerPopupCancelButtonOptions: any;
    editSellerPopupDeleteButtonOptions: any;
    editSellerUnsavedChanges: boolean;
    existingSellerValues: Seller;

    histSellers: HistSeller[];
    retainedHistSellers: HistSeller[] = [];
    histColumns: CoreColumn[];
    histGridProps: GridProps;
    histSellerGridState: any;
    histSellerGridStateRetrieved: boolean = false;
    histSellerGridStateLoaded: boolean = false;

    accountFactors: AccountFactor[];
    retainedAccountFactors: AccountFactor[] = [];
    factorColumns: CoreColumn[];
    factorGridProps: GridProps;

    popupImportColumns: CoreColumn[];
    popupImportProps: GridProps;
    importURL: string;
    importDescription: string;
    importHistoryDescription: string = 'History';
    importFactorsDescription: string = 'Factors';
    importNamesDescription: string = 'Names';

    passwordConfirmationProps: CorePopupProperties;
    passwordConfirmationSteps: CorePopupStep[];
    passwordPopupDefaultHeight: string = '250';
    passwordPopupDefaultWidth: string = '510';
    generatePasswordDisabled: boolean = false;

    importCleaningSourceData: ImportCleaningSourceData;

    issueMessage: string = '';
    isIssuePopupVisible: boolean = false;
    factorIssueMessage: string = '';
    isFactorIssuePopupVisible: boolean = false;
    hierarchyPopup: ComponentRef<HierarchyComponent>;
    permissionViewHierarchy: boolean;
    sellerIdsWithPendingHistIssues: number[];
    includeHistoryInSellerGrid: boolean = true;
    includeHistoryToolbarItemName: string = 'includeHistoryInformation';
    periods: Period[];

    beginDateEditorOptions: any = {
        displayFormat: {
            parser: (value) => this.helperService.parseDate(value),
            formatter: (date) => this.helperService.formatDateDefault(date)
        }
    };
    endDateEditorOptions: any = {
        showClearButton: true,
        displayFormat: {
            parser: (value) => this.helperService.parseDate(value),
            formatter: (date) => this.helperService.formatDateDefault(date)
        }
    };

    constructor(private sellerService: SellerService,
        private accountAttributeClassService: AccountAttributeClassService,
        private accountAttributeService: AccountAttributeService,
        private toast: ToastrService,
        private sellerImportService: SellerImportService,
        private histSellerService: HistSellerService,
        private helperService: HelperService,
        private accountFactorService: AccountFactorService,
        private emailService: EmailService,
        private appElementsService: AppElementsService,
        private permissionService: PermissionService,
        private periodService: PeriodService,
        private authService: AuthService,
        private viewContainerRef: ViewContainerRef) {
        this.hierarchyPopup = this.helperService.createComponent(HierarchyComponent);
        this.helperService.injectComponent(this.viewContainerRef, this.hierarchyPopup);
    }

    ngOnInit() {
        this.sellerIdsWithPendingHistIssues = [];

        this.sellerGridSelected = null;
        this.sellerGridSelectedMultiple = [];

        this.passwordConfirmationProps = new CorePopupProperties().createMessageOnly(this.passwordPopupDefaultWidth, this.passwordPopupDefaultHeight, true, 'Generate Passwords');
        this.passwordConfirmationSteps = [];

        this.permissionAddSellerField = this.permissionService.checkCurrentUserPermission(CoreFeature.AddAccounts.toString());
        this.permissionViewHierarchy = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewAccountHierarchy.toString());
        this.permissionDeleteSeller = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteAccounts.toString());
        this.permissionEditSeller = this.permissionService.checkCurrentUserPermission(CoreFeature.EditAccounts.toString());
        this.permissionAddAccountHistory = this.permissionService.checkCurrentUserPermission(CoreFeature.AddAccountHistory.toString());
        this.permissionEditAccountHistory = this.permissionService.checkCurrentUserPermission(CoreFeature.EditAccountHistory.toString());
        this.permissionDeleteAccountHistory = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteAccountHistory.toString());
        this.permissionEditAccountSecurity = this.permissionService.checkCurrentUserPermission(CoreFeature.EditAccountSecurity.toString());
        this.permissionAddFactor = this.permissionService.checkCurrentUserPermission(CoreFeature.AddAccountFactors.toString());
        this.permissionEditFactor = this.permissionService.checkCurrentUserPermission(CoreFeature.EditAccountFactors.toString());
        this.permissionDeleteFactor = this.permissionService.checkCurrentUserPermission(CoreFeature.DeleteAccountFactors.toString());
        this.canOpenSellerPopup = this.permissionDeleteSeller || this.permissionEditAccountSecurity ||
            this.permissionAddAccountHistory || this.permissionDeleteAccountHistory || this.permissionEditAccountHistory ||
            this.permissionAddFactor || this.permissionEditFactor || this.permissionDeleteFactor;
        this.permissionViewAuditButton = this.permissionService.checkCurrentUserPermission(CoreFeature.ViewAuditButton.toString());

        this.getAccountData();

        this.periodService.getPeriods().subscribe(result => this.periods = result);

        // Create Items For Seller Toolbar
        const sellerToolBarItems = new Array();

        const viewSellerHierarchy = {
            location: 'after',
            widget: 'dxButton',
            name: 'openAccountHierarchy',
            locateInMenu: 'auto',
            showText: 'inMenu',
            options: {
                icon: 'hierarchy',
                text: 'Hierarchy',
                hint: 'Hierarchy',
                onClick: () => {
                    this.hierarchyPopup.instance.expandAll();
                    this.hierarchyPopup.instance.popupVisible = true;
                }
            }
        };

        const generatePasswords = {
            location: 'after',
            widget: 'dxButton',
            name: 'generatePasswords',
            locateInMenu: 'auto',
            showText: 'inMenu',
            options: {
                icon: 'key',
                text: 'Generate Passwords',
                hint: 'Generate Passwords',
                onClick: () => {
                    this.showPasswordConfirmationPopup();
                }
            }
        };

        const addSeller = {
            location: 'after',
            widget: 'dxButton',
            name: 'addSeller',
            locateInMenu: 'auto',
            showText: 'inMenu',
            options: {
                icon: 'plus',
                text: 'Add',
                hint: 'Add',
                onClick: () => {
                    this.openAddSellerPopup();
                }
            }
        };

        if (this.permissionViewHierarchy) {
            sellerToolBarItems.push(viewSellerHierarchy);
        }

        if (this.permissionEditAccountSecurity) {
            sellerToolBarItems.push(generatePasswords);
        }

        if (this.permissionAddSellerField) {
            sellerToolBarItems.push(addSeller);
        }

        this.sellerGridProps = new GridProps('sellerGridId', 'Accounts', this.includeHistoryInSellerGrid, true, true, true, null, null, sellerToolBarItems,
            [
                {
                    location: 'left',
                    widget: 'dxDropDownButton',
                    name: 'import',
                    locateInMenu: 'never',
                    cssClass: 'custom-toolbar-text-button imports-button',
                    options: {
                        text: 'Import',
                        items: [
                            this.getImportCaption(this.importHistoryDescription),
                            this.getImportCaption(this.importFactorsDescription),
                            this.getImportCaption(this.importNamesDescription)
                        ],
                        dropDownOptions: { width: 110 },
                        onItemClick: this.onImportDropdownItemClick.bind(this)
                    }
                },
                {
                    location: 'center',
                    // Need to use something other than a label here, since widget: 'dxLabel' is not a valid value
                    widget: 'dxButton',
                    name: 'payeeCount',
                    locateInMenu: 'never',
                    cssClass: 'payees-count',
                    options: {
                        text: this.setPayeeCountButtonText(),
                        disabled: false,
                        visible: true,
                        onClick: () => this.sellerGrid.setGridLayout(this.sellerGrid.uiViews.find(x => x.systemCode === uiViewSystemCodes.ActivePayeesLayout))
                    }
                }
        ]).setKeyColumn('id').setStateSaveLocal();
        this.sellerGridProps.isColumnResizingAllowed = true;
        this.sellerGridProps.columnAutoWidth = false;
        this.sellerGridProps.isRowSelectionEnabled = true;
        this.sellerGridProps.rowSelectionCheckBoxesMode = 'none';
        this.sellerGridProps.isRowSelectionVisible = false;
        this.sellerGridProps.emptyPanelText = 'Group';
        this.sellerGridProps.isLayoutsEnabled = true;
        this.sellerGridProps.displayLayoutsInFooter = true;
        this.sellerGridProps.isGridLabelVisible = true;
        this.sellerGridProps.pageSize = 20;
        const sellerGridDML = new DevExpressDMLFunctions(this.dataChanged, this, [], false, this.permissionEditSeller, this.permissionDeleteSeller);
        this.sellerGridProps.setGridToScrollingWithDMLForPopup('Accounts', true, ScrollingModes.Virtual, true, sellerGridDML);
        this.sellerGridProps.addOnSelectionChangedFunction(this, this.sellerSelectionChanged);
        this.sellerGridProps.addOnContentReadyFunction(this, this.onSellerGridContentReady);
        this.sellerGridProps.addOnOptionChangedFunction(this, this.onOptionChanged);
        this.sellerGridProps.addOnEditorPreparingFunction(this, this.editorPreparing);
        this.sellerGridProps.addOnRowPreparedFunction(this, this.sellerRowPrepared);
        this.sellerGridProps.addOnInitializedFunction(this, this.onSellerGridInitialized);
        this.sellerGridProps.addOnInitNewRowFunction(this, this.showSellerAddFields);
        this.sellerGridProps.addOnEditingStartFunction(this, this.showSellerEditFields);
        this.sellerGridProps.addModifyServerDataFunction(this, this.modifySellerServerData);
        this.sellerGridProps.isDeletingAllowed = false;
        this.sellerGridProps.isUpdatingAllowed = this.permissionEditSeller;
        this.sellerGridProps.serverSideDataProcessingUrl = this.sellerService.ssdpURL;
        this.sellerGridProps.isServerSideDataProcessingEnabled = this.includeHistoryInSellerGrid;
        this.sellerGridProps.setUiView(this.sellerGridLayoutId, uiViewContextCodes.AccountManagementSellerGrid,
            [{ propertyName: 'contextCode', propertyValue: uiViewContextCodes.AccountManagementSellerGrid}], [], this,
            this.onSellerGridLayoutSave, this.onSellerGridLayoutAdded, this.onSellerGridLayoutRenamed, this.onSellerGridLayoutDeleted);
        this.sellerGridProps.setGridStateFunctions(this, this.sellerGridSaveState, this.sellerGridLoadState);

        this.histGridProps = new GridProps('histSellerGridId', 'History', true, true).setKeyColumn('id').setStateSaveLocal();
        this.histGridProps.isColumnResizingAllowed = true;
        this.histGridProps.columnAutoWidth = false;
        this.histGridProps.isGridLabelVisible = true;
        this.histGridProps.isRowSelectionEnabled = true;
        this.histGridProps.rowSelectionCheckBoxesMode = 'none';
        this.histGridProps.isAddingAllowed = this.permissionAddAccountHistory;
        this.histGridProps.isRowSelectionVisible = false;
        this.histGridProps.emptyPanelText = 'Group';
        this.histGridProps.isBulkEditEnabled = this.permissionEditAccountHistory;
        const histGridDML =  new DevExpressDMLFunctions(this.histDataChanged, this, [], false, this.permissionEditAccountHistory, this.permissionDeleteAccountHistory);
        this.histGridProps.setGridToScrollingWithDMLForPopup('History', true, ScrollingModes.Standard, true, histGridDML, 'batch');
        this.histGridProps.rowRenderingMode = RowRenderingModes.Standard;
        this.histGridProps.addOnInitNewRowFunction(this, this.onInitNewRowHistSeller);
        this.histGridProps.addOnOptionChangedFunction(this, this.onHistoryGridOptionChanged);
        this.histGridProps.addOnEditCanceledFunction(this, this.onEditCanceled);
        this.histGridProps.setGridStateFunctions(this, null, this.histSellerGridLoadState);

        this.factorGridProps = new GridProps('factorGridId', 'Account Factors', true, true).setKeyColumn('id').setStateSaveLocal();
        this.factorGridProps.isColumnResizingAllowed = true;
        this.factorGridProps.columnAutoWidth = false;
        this.factorGridProps.isGridLabelVisible = true;
        this.factorGridProps.isRowSelectionEnabled = true;
        this.factorGridProps.rowSelectionCheckBoxesMode = 'none';
        this.factorGridProps.isRowSelectionVisible = false;
        this.factorGridProps.emptyPanelText = 'Group';
        this.factorGridProps.isBulkEditEnabled = this.permissionEditFactor;
        this.factorGridProps.isAddingAllowed = this.permissionAddFactor;
        this.factorGridProps.isUpdatingAllowed = this.permissionEditFactor;
        this.factorGridProps.isDeletingAllowed = this.permissionDeleteFactor;
        const factorGridDML = new DevExpressDMLFunctions(this.factorDataChanged, this, [], false, this.permissionEditFactor, this.permissionDeleteFactor);
        this.factorGridProps.setGridToScrollingWithDMLForPopup('Factors', true, ScrollingModes.Standard, true, factorGridDML, 'batch');
        this.factorGridProps.rowRenderingMode = RowRenderingModes.Standard;
        this.factorGridProps.addOnInitNewRowFunction(this, this.onInitNewRowFactor);
        this.factorGridProps.addOnOptionChangedFunction(this, this.onOptionChanged);

        this.popupImportColumns = [
            new CoreColumn('name', 'Import Name', true, 'string', null, true),
            new CoreColumn('isDefault', 'Default', true, 'boolean', null, true).setColumnHeaderOptions(false, false, false)
        ];
        this.popupImportProps = new GridProps('popupImportGridId', 'Seller Imports', false, true, false, false, false, false);
        this.popupImportProps.addOnInitNewRowFunction(this, this.onInitNewSellerImport);
        this.popupImportProps.addOnEditorPreparingFunction(this, this.onImportGridEditorPreparing);
        this.popupImportProps.isAddingAllowed = this.permissionEditSeller;
        this.popupImportProps.isUpdatingAllowed = this.permissionEditSeller;
        this.popupImportProps.isDeletingAllowed = this.permissionEditSeller;
        this.popupImportProps.hideSaveButton = true;
        this.popupImportProps.deleteEnabledColumn = 'deleteEnabled';
        this.popupImportProps.editMode = EditModes.Batch;
        this.popupImportProps.isSearchEnabled = false;
        this.popupImportProps.isGridLabelVisible = true;

        this.permissionService.getIsImplementer().subscribe(result => {this.isImplementer = result;});
        this.sellerGridProps.footerToolbarItems.push(
            {
                location: 'right',
                widget: 'dxButton',
                name: 'auditButton',
                locateInMenu: 'never',
                options: {
                    text: `Audit`,
                    disabled: false,
                    visible: this.permissionViewAuditButton,
                    onClick: () => { this.onAuditRecordsClick(); }
                }});
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setPanelWidths(this.getPanelWidthTotal());
        });
    }

    getAccountData(isAfterImport: boolean = false): void {
        this.isLoadingVisible = true;
        forkJoin([
            this.getClientSideSellerData(),
            this.sellerService.getActiveSellerIds(),
            this.sellerService.getSellerNames(),
            this.accountAttributeService.getAllAttributes(),
            this.accountAttributeClassService.getAttributeClasses(),
            this.permissionService.getPermissions()
        ]).subscribe(([sellers, activeSellerIds, sellerNames, attributes, attributeClasses, permissions]) => {

            this.sellers = sellers.sort((a, b) => a.name.localeCompare(b.name));
            this.sellerMappings = this.sellers;
            this.sellerMappings.unshift({ id: -1, name: '<None>'});
            this.updateActiveSellers();
            this.activeSellerIds = activeSellerIds;
            this.sellerNames = sellerNames;
            this.attributeClasses = attributeClasses;
            this.permissions = permissions;

            this.updatePayeeCount();

            this.attributes = attributes;
            if (this.columnsLoaded) {
                if (isAfterImport) {
                    const selectedSellers: Seller[] = [];
                    if (!this.includeHistoryInSellerGrid) {
                        const selectedSellerRowKeys = this.sellerGrid.grid.instance.getSelectedRowKeys();
                        selectedSellerRowKeys.forEach(x => {
                            selectedSellers.push(this.sellers.find(y => y.id === x));
                        });
                        this.sellerGridSelectedMultiple = selectedSellers;
                    }
                }

                if (!this.helperService.isNullOrUndefined(this.sellerMappings) && this.sellerMappings.length > 0) {
                    this.histColumns.find(x => x.dataField === 'bossSellerId').columnType.lookupData = this.helperService.createDatasourceFromStaticArray(this.sellerMappings, 'id');
                    this.histColumns.find(x => x.dataField === 'parentSellerId').columnType.lookupData = this.helperService.createDatasourceFromStaticArray(this.sellerMappings, 'id');
                }

                this.getChildGridData();
            } else {
                this.getChildGridData();
                this.sellerColumns = [];
                this.sellerColumns.push(new CoreColumn('name', 'Name', true, null, null, false, null, false, null, null, null, null, true, true).sortAsc().setIsFixed(true));
                this.sellerColumns.push(new CoreColumn('loginName', 'Login Name', false, 'string', null, false, null, true, null, null, null, null, false, true));
                this.sellerColumns.push(new CoreColumn('emailAddress', 'Email', true, null, null, false, null, null, null, null, null, null, false));
                this.sellerColumns.push(new CoreColumn('isPayee', 'Payee', false, 'boolean', null, false, null, true, null, null, null, null, null, null, '', 'Payee', 'Non-Payee').setWidth(90));
                this.sellerColumns.push(new CoreColumn('hasPassword', 'Password?', false, 'boolean', null, false, null, true, null, null, null, null, null, null, '',
                    'Has Password', 'No Password').setWidth(120));
                this.sellerColumns.push(new CoreColumn('isActive', 'Active', true, 'boolean', null, false, null, true, null, null, null, null, null, null, '', 'Active',
                    'Inactive').setWidth(90));
                this.sellerColumns.push(new CoreColumn('userGroupId', 'User Group', true, 'string',
                    new ColumnType().createLookup(this.permissions, 'id', 'name', null, false), false, null, false, null, null, null, null, null, true));
                this.sellerColumns.push(new CoreColumn('importName', 'Import Name', false, 'string', null, false, null, true));
                this.sellerColumns.push(new CoreColumn('importNamesString', 'Import Names', false, 'string', null, false, null, false));
                this.sellerColumns.push(new CoreColumn('manager', 'Manager', false, null, null, false, null, true));
                this.sellerColumns.push(new CoreColumn('parent', 'Parent', false, null, null, false, null, true));
                if (this.canOpenSellerPopup){
                    this.sellerColumns.push(new CoreColumn('customButton', '', true, 'buttons', new ColumnType().createCustomButtonColumn('Edit Account', null,
                        'edit', 'edit-seller-button', false, false, null, null, null, this, this.openEditSellerPopup).createUndraggableHeaderCell(), null, null, false)
                        .setColumnHeaderOptions(false, false, false)
                        .setWidth(55)
                        .setAllowReordering(false)
                        .setAllowHiding(false)
                    );
                }

                this.histColumns = [];
                this.histColumns.push(new CoreColumn('seller.name', 'Name', true, 'string', null, false, null, true, null, null, null, null, null, false).sortAsc());
                this.histColumns.push(new CoreColumn('beginDate', 'Start Date', true, 'date', null, true, 'MM-dd-yyyy', true, null, null, null, null, true)
                    .addEditorOptions(this.beginDateEditorOptions)
                    .addValidationRule(new CoreValidationRule().createCustom(this.helperService.isDateValid, 'Date values prior to 01-01-1800 are not permitted.', true)));
                this.histColumns.push(new CoreColumn('endDate', 'End Date', true, 'date', null, true, 'MM-dd-yyyy')
                    .addCustomizeText(this, this.replaceNullDates)
                    .addEditorOptions(this.endDateEditorOptions)
                    .addValidationRule(new CoreValidationRule().createCustom(this.helperService.isDateValid, 'Date values prior to 01-01-1800 are not permitted.', true)));
                this.histColumns.push(new CoreColumn('bossSellerId', 'Manager', true, 'string',
                    new ColumnType().createLookup(this.helperService.createDatasourceFromStaticArray(this.sellerMappings, 'id'), 'id', 'name', null, true, 'id', true), true));
                this.histColumns.push(new CoreColumn('parentSellerId', 'Parent', false, 'string',
                    new ColumnType().createLookup(this.helperService.createDatasourceFromStaticArray(this.sellerMappings, 'id'), 'id', 'name', null, true, 'id', true), true, null, true));
                this.histColumns.push(new CoreColumn('seller.importNamesString', 'Import Names', false, 'string', null, false, null, false));
                this.histColumns.push(new CoreColumn('bossSeller.importNamesString', 'Manager Import Names', false, 'string', null, false, null, false));
                this.histColumns.push(new CoreColumn('parentSeller.importNamesString', 'Parent Import Names', false, 'string', null, false, null, false));

                this.attributeLookups = {};

                attributeClasses.forEach(attributeClass => {
                    if (!attributeClass.isExcluded) {
                        this.sellerColumns.push(new CoreColumn('attr_' + attributeClass.id, attributeClass.friendlyName, false, 'string',
                        null, false, null, true, null, null, null, null, null, false)
                        .addCustomizeGroupCellTextFunction(this, this.customizeGroupCellText));
                    }

                    if (attributeClass.isText) {
                        this.histColumns.push(new CoreColumn('attr_' + attributeClass.id, attributeClass.friendlyName, true, 'string',
                            null, true, null, true, null, null, null, null, null, true));
                    } else {
                        this.attributeLookups[attributeClass.id.toString()] = this.attributes.filter(attribute =>
                            attribute.attributeClassId === attributeClass.id
                        );
                        this.attributeLookups[attributeClass.id.toString()].sort((a, b) => a.name.localeCompare(b.name));
                        if (!attributeClass.isExcluded) {
                            this.histColumns.push(new CoreColumn('attr_' + attributeClass.id, attributeClass.friendlyName, true, 'string',
                                new ColumnType().createLookup(this.attributeLookups[attributeClass.id], 'id', 'name', null, false), true, null, true, null, null, null, null, true));
                        }
                    }
                });

                const factorAttributeClassId: number = attributeClasses.find(attributeClass =>
                    attributeClass.name === 'variable'
                ).id;

                this.attributeLookups[factorAttributeClassId].sort((a, b) => a.name.localeCompare(b.name));

                this.factorColumns = [];
                this.factorColumns.push(new CoreColumn('seller.name', 'Name', true, 'string', null).sortAsc());
                this.factorColumns.push(new CoreColumn('variableId', 'Account Factor', true, 'string',
                    new ColumnType().createLookup(this.attributeLookups[factorAttributeClassId], 'id', 'name'), true, null, true, null, null, null, null, true).sortAsc(this.calculateSortValue));
                this.factorColumns.push(new CoreColumn('beginDate', 'Start Date', true, 'date', null, true, 'MM-dd-yyyy', true, null, null, null, null, true)
                    .addEditorOptions(this.beginDateEditorOptions)
                    .addValidationRule(new CoreValidationRule().createCustom(this.helperService.isDateValid, 'Date values prior to 01-01-1800 are not permitted.', true)));
                this.factorColumns.push(new CoreColumn('endDate', 'End Date', true, 'date', null, true, 'MM-dd-yyyy')
                    .addCustomizeText(this, this.replaceNullDates)
                    .addEditorOptions(this.endDateEditorOptions)
                    .addValidationRule(new CoreValidationRule().createCustom(this.helperService.isDateValid, 'Date values prior to 01-01-1800 are not permitted.', true)));
                this.factorColumns.push(new CoreColumn('value', 'Value', true, 'number', null, true, null, true, null, null, null, null, true));
                this.factorColumns.push(new CoreColumn('seller.importNamesString', 'Import Names', false, 'string', null, false, null, false));

                this.columnsLoaded = true;
            }

            const sideNavChanges: Subscription = this.appElementsService.getSideNavOpenChanges().subscribe(x => {
                if (!this.pageDestroyed) {
                    // Resize continuously as the animation is happening, for a smooth transition
                    for (let i = 0; i < 1000; i++) {
                        setTimeout(() => {
                            this.setPanelWidths(this.getPanelWidthTotal());
                        }, i);
                    }
                } else {
                    sideNavChanges.unsubscribe();
                }
            });

            this.attributeClasses.forEach(x => {
                x.attributes = this.attributes.filter(y => y.attributeClassId === x.id);
            });
            this.importCleaningSourceData = new ImportCleaningSourceData(this.sellerNames, this.attributeClasses);

            this.isLoadingVisible = false;
        });
    }

    getHierarchyData(): void {
        this.hierarchyPopup?.instance?.getHierarchy();
    }

    setAttributeValues(sellers: Seller[]): void {
        sellers.forEach(seller => {
            let maxHistSeller: HistSeller = seller.histSellers[0];

            seller.histSellers.forEach(histSeller => {
                if (histSeller.endDate == null || histSeller.endDate > maxHistSeller.endDate) {
                    maxHistSeller = histSeller;
                }

                this.setAttributeValuesHistSeller(histSeller);

            });

            maxHistSeller?.attributes
                .forEach(attribute => {
                    const obj: { [k: string]: any } = {};
                    obj['attr_' + attribute.attributeClass.id] = attribute.name;
                    Object.assign(seller, obj);
                });

            this.updateSellerImportProperties(seller);

        });
    }

    setAttributeValuesHistSeller(histSeller: HistSeller): void  {
        histSeller.attributes
            .forEach(attribute => {
                const obj: { [k: string]: any } = {};
                if (attribute.attributeClass.isText) {
                    obj['attr_' + attribute.attributeClass.id] = attribute.name;
                } else {
                    obj['attr_' + attribute.attributeClass.id] = attribute.id;
                }
                Object.assign(histSeller, obj);
            });
    }

    setChildGridDatasources(selectedSellers: Seller[]): void  {
        let newAccountFactors: AccountFactor[] = [];

        for (const selectedSeller of selectedSellers) {
            newAccountFactors = newAccountFactors.concat(selectedSeller.accountFactors);
        }

        this.accountFactors = newAccountFactors;
    }

    getChildGridData(): void {
        if (this.sellerGridSelectedMultiple !== undefined && this.sellerGridSelectedMultiple.length > 0) {
            forkJoin([
                this.histSellerService.getMultipleHistSellers(this.sellerGridSelectedMultiple.map(x => x.id)),
                this.accountFactorService.getMultipleAccountFactors(this.sellerGridSelectedMultiple.map(x => x.id))
            ]).subscribe(([histSellers, accountFactors]) => {
                const histSellerEditingChanges = this.histSellerGrid.grid.editing.changes;
                const accountFactorEditingChanges = this.factorGrid.grid.editing.changes;

                this.retainModifiedChildGridRecords(this.retainedHistSellers, this.histSellers, histSellerEditingChanges);
                this.retainModifiedChildGridRecords(this.retainedAccountFactors, this.accountFactors, accountFactorEditingChanges);

                this.histSellers = histSellers;
                this.accountFactors = accountFactors;

                this.histSellers.forEach(histSeller => {
                    this.setAttributeValuesHistSeller(histSeller);
                });

                setTimeout(() => {
                    this.histSellerGrid.grid.editing.changes = histSellerEditingChanges;
                    this.factorGrid.grid.editing.changes = accountFactorEditingChanges;
                });
            });
        } else {
            this.histSellers = [];
            this.accountFactors = [];
        }
    }

    retainModifiedChildGridRecords(retainedRecords: any[], records: any[], changes: any[]): void {
        changes.forEach(x => {
            const record = records.find(y => y.id === x.key);
            if (!retainedRecords.find(y => y.id === x.key) && record) {
                retainedRecords.push(record);
            }
        });
    }

    updateChildGridSelections(coreGrid: CoreGridComponent): void {
        const childGridKeysToDeselect = [];
        coreGrid.grid.instance.getSelectedRowsData().forEach(x => {
            if (this.sellerGridSelectedMultiple.filter(y => y.id === x.sellerId).length === 0) {
                childGridKeysToDeselect.push(x.id);
            }
        });
        if (childGridKeysToDeselect.length > 0) {
            coreGrid.grid.instance.deselectRows(childGridKeysToDeselect);
        }
    }

    sellerSelectionChanged(e: any): void {
        this.sellerGridSelectedMultiple = e['event']['selectedRowsData'];
        this.sellerGridSelected = e['event']['selectedRowsData'].length > 0 ? e['event']['selectedRowsData'][0] : null;

        this.getChildGridData();

        this.histGridProps.isAddingAllowed = (this.sellerGridSelected !== null && this.permissionAddAccountHistory);
        this.factorGridProps.isAddingAllowed = (this.sellerGridSelected !== null && this.permissionAddFactor);

        const editedHistSellerIds: number[] = [];
        for (const change of this.histSellerGrid.grid.editing.changes) {
            editedHistSellerIds.push(change.key);
        }
    }

    editorPreparing(e): void  {
        if (e.event.dataField === 'beginDate') {
            e.event.editorOptions.readOnly = !e.event.row.isNewRow;
        }
    }

    replaceNullDates(cellInfo: any): string {
        if (cellInfo.value === null) {
            return 'In Effect';
        }

        return cellInfo.valueText;
    }

    dataChanged(e: CoreEventArguments): void  {
        const changes: SellerBatchChanges = this.gatherSellerChanges(e.event.changes);

        e.event.promise = this.sellerService.sellerBatchChanges(changes).toPromise().then(response => {
            if (response.responseCode === 1) {
                this.toast.success('Changes saved.');
            } else {
                // Cancel save and re-queue changes.
                e.event.cancel = true;
                this.helperService.displayErrorToast(this.toast, response, 'An error occurred when attempting to save account changes');
            }
        });
    }

    gatherSellerChanges(gridChanges: CoreDataGridChange<Seller>[]): SellerBatchChanges {
        const changes = new SellerBatchChanges();
        changes.inserts = [];
        changes.updates = [];
        changes.deletes = [];

        for (const c of gridChanges) {
            if (c.type === devExtremeDMLStrings.Insert) {
                // There should be no inserts - those are done through custom popup
            } else if (c.type === devExtremeDMLStrings.Update) {
                const existingSeller: Seller = this.sellers.find(seller => seller.id === c.key);
                const updatedSeller = this.helperService.deepCopyTwoPointO(existingSeller);
                updatedSeller.name = c.data.name;
                changes.updates.push(updatedSeller);
            } else if (c.type === devExtremeDMLStrings.Remove) {
                // Deletes are handled in this.sellerRemoving()
            }
        }

        return changes;
    }

    createSeller(sellerInfo: SellerCreationInfo): void {
        const insertSellerInfo: SellerCreationInfo = {
            name: sellerInfo.name,
            importName: sellerInfo.importName,
            beginDate: this.helperService.treatDateStringAsUTC(this.helperService.cleanDate(sellerInfo.beginDate))
        };

        this.sellerService.createSeller(insertSellerInfo).subscribe(sellerResponse => {
            if (sellerResponse.responseCode === coreResponseCodes.Success) {
                this.toast.success('Account Created');
                this.getHierarchyData();
                if (!this.includeHistoryInSellerGrid) {
                    const index: number = this.sellers.findIndex(row => typeof row['id'] === 'string');
                    this.setAttributeValues([sellerResponse.result]);
                    sellerResponse.result.importName = sellerInfo.importName;
                    sellerResponse.result.importNamesString = sellerInfo.importName;
                    if (index > -1) {
                        this.sellers[index] = sellerResponse.result;
                    } else {
                        this.sellers.push(sellerResponse.result);
                    }
                    if (!this.includeHistoryInSellerGrid) {
                        this.updateActiveSellers();
                    }
                    this.updatePayeeCount();
                } else {
                    this.sellerGrid.refreshSSDP();
                }
                this.updateSellerMappings();
                this.addSellerPopupVisible = false;
            } else if (sellerResponse.responseCode === coreResponseCodes.Error) {
                this.helperService.displayErrorToast(this.toast, sellerResponse, 'An error occurred while attempting to create seller');
            }
        });
    }

    histDataChanged(e: CoreEventArguments): void  {
        this.isLoadingVisible = true;
        const changes = this.gatherHistSellerChanges(e.event.changes);

        e.event.promise = this.histSellerService.batchChangeHistSellers(changes).toPromise().then(response => {
            this.sellerIdsWithPendingHistIssues = [];

            if (response.responseCode === 1) {
                for (const createdHistSeller of response.result.createdHistSellers) {
                    this.setAttributeValuesHistSeller(createdHistSeller);
                    const insertChange = e.event.changes.find(change => change.type === devExtremeDMLStrings.Insert && change.data.id === undefined);
                    insertChange.data = createdHistSeller;
                }
                this.getAccountData();

                if (this.histSellerGrid.grid.instance.hasEditData()) {
                    // Can't use .cancelEditData() here because we are retaining hist seller changes that aren't visible
                    this.histSellerGrid.grid.editing.changes = [];
                }
                this.retainedHistSellers = [];

                this.toast.success('Changes saved.');
            } else {
                // Cancel save and re-queue changes.
                this.isLoadingVisible = false;
                e.event.cancel = true;
                this.displaySellersWithErrors(response.result);
                this.sellerGrid.repaint();
                this.showHistIssuePopup(response.result);
            }
        });
    }

    gatherHistSellerChanges(gridChanges: CoreDataGridChange<HistSeller>[]): HistSellerBatchChanges {
        // Avoid the use of variable name editedAttributeClassId.  It messes with the bindings of the hist_seller table somehow.

        if (!this.editSellerPopupVisible) {
            this.retainModifiedChildGridRecords(this.retainedHistSellers, this.histSellers, gridChanges);
        }

        const histSellers: HistSeller[] = this.editSellerPopupVisible ? this.openSeller.histSellers : this.retainedHistSellers;
        const changes = new HistSellerBatchChanges();
        changes.inserts = [];
        changes.updates = [];
        changes.deletes = [];

        for (const c of gridChanges) {
            if (c.type === devExtremeDMLStrings.Insert) {
                c.data.attributes = [];
                Object.keys(c.data).forEach(prop => {
                    if (prop.startsWith('attr_')) {
                        const changedAttributeClassId: number = parseInt(prop.replace('attr_', ''), 10);
                        const editedAttributeClass: AttributeClass = this.attributeClasses.find(attributeClass => attributeClass.id === changedAttributeClassId);

                        if (editedAttributeClass.isText) {
                            c.data.attributes.push(Attribute.CreateNewTextAttribute(changedAttributeClassId, c.data[prop]));
                        } else {
                            c.data.attributes.push(this.attributes.find(attribute => attribute.id === c.data[prop]));
                        }
                    }
                });

                const insertedRow: HistSeller = HistSeller.copy(c.data);
                insertedRow.beginDate = this.helperService.treatDateStringAsUTC(c.data.beginDate);
                if (c.data.endDate) {
                    insertedRow.endDate = this.helperService.treatDateStringAsUTC(c.data.endDate);
                }

                changes.inserts.push(insertedRow);
            } else if (c.type === devExtremeDMLStrings.Update) {
                const updatedRow: HistSeller = HistSeller.copy(histSellers.find(changedHist =>
                    changedHist.id === c.key
                ));

                Object.keys(c.data).forEach(prop => {
                    if (prop.startsWith('attr_')) {
                        const changedAttributeClassId: number = parseInt(prop.substr(5), 10);
                        const editedAttributeClass: AttributeClass = this.attributeClasses.find(attributeClass => attributeClass.id === changedAttributeClassId);
                        const editedIndex: number = updatedRow.attributes.findIndex(attribute =>
                            attribute.attributeClassId === changedAttributeClassId
                        );
                        if (updatedRow.histSellerAttributesAuto) {
                            updatedRow.histSellerAttributesAuto.find(histSellerAttribute =>
                                // TODO: Base this off attribute class instead?
                                 histSellerAttribute.attributeId === updatedRow.attributes[editedIndex].id
                            ).attributeId = c.data[prop];
                        }
                        if (updatedRow.attributes) {
                            if (editedAttributeClass.isText) {
                                if (editedIndex === -1) {
                                    updatedRow.attributes.push(Attribute.CreateNewTextAttribute(changedAttributeClassId, c.data[prop]));
                                } else if (c.data[prop] === '')
                                {
                                    updatedRow.attributes.splice(editedIndex, 1);
                                } else
                                {
                                    updatedRow.attributes[editedIndex] = Attribute.CreateNewTextAttribute(changedAttributeClassId, c.data[prop]);
                                }
                            } else {
                                updatedRow.attributes[editedIndex] = this.attributes.find(attribute => attribute.id === c.data[prop]);
                            }
                        }
                    }
                    updatedRow[prop] = c.data[prop];
                });

                updatedRow.beginDate = this.helperService.treatDateStringAsUTC(updatedRow.beginDate);
                if (updatedRow.endDate) {
                    updatedRow.endDate = this.helperService.treatDateStringAsUTC(updatedRow.endDate);
                }

                changes.updates.push(updatedRow);
            } else if (c.type === devExtremeDMLStrings.Remove) {
                const removedHist: HistSeller = histSellers.find(histSeller => histSeller.id === c.key);
                changes.deletes.push(removedHist.id);
            }
        }

        return changes;
    }

    factorDataChanged(e: CoreEventArguments): void  {
        const changes = this.gatherAccountFactorChanges(e.event.changes);

        e.event.promise = this.accountFactorService.batchUpdateAccountFactors(changes).toPromise().then(response => {
            if (response.responseCode === 1) {
                this.getChildGridData();
                if (this.factorGrid.grid.instance.hasEditData()) {
                    this.factorGrid.grid.instance.cancelEditData();
                }
                this.retainedAccountFactors = [];
                this.toast.success('Changes saved.');
            } else if (response.result.sellerIdsWithNonsequentialAccountFactors?.length > 0) {
                e.event.cancel = true;
                this.showFactorIssuePopup(response.result.sellerIdsWithNonsequentialAccountFactors);
            } else {
                // Cancel save and re-queue changes.
                e.event.cancel = true;
                this.helperService.displayErrorToast(this.toast, response, 'An error occurred when attempting to save account factor changes');
            }
        });
    }

    gatherAccountFactorChanges(gridChanges: CoreDataGridChange<AccountFactor>[]): AccountFactorBatchChanges {
        if (!this.editSellerPopupVisible) {
            this.retainModifiedChildGridRecords(this.retainedAccountFactors, this.accountFactors, gridChanges);
        }

        const accountFactors: AccountFactor[] = this.editSellerPopupVisible ? this.openSeller.accountFactors : this.retainedAccountFactors;
        const changes = new AccountFactorBatchChanges();
        changes.inserts = [];
        changes.updates = [];
        changes.deletes = [];

        for (const c of gridChanges) {
            if (c.type === devExtremeDMLStrings.Insert) {
                const newAccountFactor: AccountFactor = AccountFactor.copy(c.data);
                changes.inserts.push(newAccountFactor);
            } else if (c.type === devExtremeDMLStrings.Update) {
                const existingAccountFactor: AccountFactor = accountFactors.find(accountFactor => accountFactor.id === c.key);
                const updatedAccountFactor = this.helperService.deepCopyTwoPointO(existingAccountFactor);
                Object.keys(c.data).forEach(prop => {
                    updatedAccountFactor[prop] = c.data[prop];
                });
                changes.updates.push(updatedAccountFactor);
            } else if (c.type === devExtremeDMLStrings.Remove) {
                const factorToRemove: AccountFactor = accountFactors.find(accountFactor => accountFactor.id === c.key);
                if (factorToRemove) {
                    changes.deletes.push(factorToRemove.id);
                }
            }
        }

        return changes;
    }

    sellerRowPrepared(e: CoreEventArguments): void {
        if (this.sellerIdsWithPendingHistIssues.findIndex(x => x === e.event.data?.id) !== -1) {
            e.event.rowElement.classList.add('hist-seller-issue');
        }
    }

    showSellerAddFields(e: CoreEventArguments): void  {
        const columns: CoreColumn[] = e.component.columns;
        for (const column of columns) {
            if (column.dataField === 'beginDate' || column.dataField === 'sellerImports[0].name') {
                const itemConfig = e.event.component.columnOption(column.dataField);
                itemConfig.visible = true;
                e.event.component.columnOption(column.dataField, 'formItem', itemConfig);
            }

            if (column.dataField === 'emailAddress' || column.dataField === 'loginName') {
                const itemConfig = e.event.component.columnOption(column.dataField);
                itemConfig.visible = false;
                e.event.component.columnOption(column.dataField, 'formItem', itemConfig);
            }
        }
    }

    showSellerEditFields(e: CoreEventArguments): void  {
        const columns: CoreColumn[] = e.component.columns;
        for (const column of columns) {
            if (column.dataField === 'beginDate') {
                const itemConfig = e.event.component.columnOption(column.dataField);
                itemConfig.visible = false;
                e.event.component.columnOption(column.dataField, 'formItem', itemConfig);
            }

            if (column.dataField === 'emailAddress' || column.dataField === 'loginName' || column.dataField === 'sellerImports[0].name') {
                const itemConfig = e.event.component.columnOption(column.dataField);
                itemConfig.visible = true;
                e.event.component.columnOption(column.dataField, 'formItem', itemConfig);
            }
        }
    }

    onInitNewRowHistSeller(e: CoreEventArguments): void  {
        const editedSellerId: number = this.editSellerPopupVisible ? this.openSeller.id : this.sellerGridSelected.id;;
        e.event.data.sellerId = editedSellerId;

        const seller = this.sellerMappings.find(x => x.id === editedSellerId);
        if (seller) {
            e.event.data.seller = seller;
        }

        const latestHistSeller = this.histSellers.filter(histSeller => histSeller.sellerId === editedSellerId)
            .reduce((previousHist, currentHist) => currentHist.endDate === null || currentHist.endDate > previousHist.endDate ? currentHist : previousHist );

        e.event.data.bossSellerId = latestHistSeller.bossSellerId;

        for (const column of this.histColumns) {
            if (column.dataField.startsWith('attr_')) {
                e.event.data[column.dataField] = latestHistSeller[column.dataField];
            }
        }
    }

    onEditCanceled(e: CoreEventArguments): void {
        this.sellerIdsWithPendingHistIssues = [];
        this.sellerGrid.repaint();
    }

    onInitNewRowFactor(e: CoreEventArguments): void {
        e.event.data.sellerId = this.sellerGridSelected.id;

        const seller = this.sellerMappings.find(x => x.id === e.event.data.sellerId);
        if (seller) {
            e.event.data.seller = seller;
        }
    }

    showPasswordConfirmationPopup(): void {
        let popupMessage = 'Email a newly generated password to ';
        if (this.editSellerPopupVisible) {
            popupMessage += `${this.openSeller.name}?`;
        } else {
            popupMessage += 'the ' + this.sellerGridSelectedMultiple.length + ' selected accounts?';
        }
        this.passwordConfirmationSteps = [];
        this.passwordConfirmationSteps.push(new CorePopupStep(popupMessage, this.passwordSendConfirmed, null, this));
        this.passwordConfirmationProps.visible = true;
        this.passwordConfirmationProps.closeOnOutsideClick = true;
    }

    displaySellersWithErrors(histSellerAuditResults: HistSellerAuditResults): void {
        // Get all seller IDs that have hist seller issues
        let sellerIds = [
            ...histSellerAuditResults.inactiveManagers.map(x => x.managerId),
            ...histSellerAuditResults.inactiveManagers.map(x => x.activeDirectSub),
            ...histSellerAuditResults.selfParentedHistSellers,
            ...histSellerAuditResults.sellerIdsWithoutHistory,
            ...histSellerAuditResults.sellerIdsWithOverlaps,
            ...histSellerAuditResults.sellerIdsWithNonsequentialHistSellers,
            ...histSellerAuditResults.periodIdsWithDeletedHistSellers
        ];
        histSellerAuditResults.loops.forEach(loop => {
            sellerIds = sellerIds.concat(loop.sellerIds);
        });

        // Trim down to only distinct values
        this.sellerIdsWithPendingHistIssues = [];
        sellerIds.forEach(x => {
            if (!this.sellerIdsWithPendingHistIssues.includes(x)) {
                this.sellerIdsWithPendingHistIssues.push(x);
            }
        });
    }

    showHistIssuePopup(histSellerAuditResults: HistSellerAuditResults): void {
        let issueString = '';
        for (const inactiveManager of histSellerAuditResults.inactiveManagers) {
            if (inactiveManager.managerId !== 0) {
                issueString += `${this.sellerMappings.find(seller => seller.id === inactiveManager.managerId).name} is an inactive manager ` +
                    `of ${this.sellerMappings.find(seller => seller.id === inactiveManager.activeDirectSub).name} on ` +
                    `${this.helperService.getDateString(inactiveManager.startDate)}.\n`;
            } else {
                issueString += `${this.sellerMappings.find(seller => seller.id === inactiveManager.activeDirectSub).name} has an inactive manager on ` +
                `${this.helperService.getDateString(inactiveManager.startDate)}.\n`;
            }
        }
        issueString += '\n';
        for (const managerLoop of histSellerAuditResults.loops) {
            issueString += `There is a loop of managers on ${this.helperService.getDateString(managerLoop.startDate)}:\n`;
            for (const sellerId of managerLoop.sellerIds) {
                issueString += this.sellerMappings.find(seller => seller.id === sellerId).name + '\n';
            }
            issueString += '\n';
        }
        if (histSellerAuditResults.selfParentedHistSellers?.length > 0) {
            issueString += 'Cannot set an account as its own parent for:\n';
            for (const selfParentedHistSellerId of histSellerAuditResults.selfParentedHistSellers) {
                issueString += this.sellerMappings.find(seller => seller.id === selfParentedHistSellerId).name + '\n';
            }
            issueString += '\n';
        }
        if (histSellerAuditResults.sellerIdsWithoutHistory.length > 0) {
            issueString += 'Cannot delete all history records for: \n';
            for (const sellerIdMissingHistory of histSellerAuditResults.sellerIdsWithoutHistory) {
                issueString += this.sellerMappings.find(seller => seller.id === sellerIdMissingHistory).name + '\n';
            }
            issueString += '\n';
        }
        if (histSellerAuditResults.sellerIdsWithOverlaps.length > 0) {
            issueString += 'Overlapping history records detected for:\n';
            for (const sellerId of histSellerAuditResults.sellerIdsWithOverlaps) {
                issueString += this.sellerMappings.find(seller => seller.id === sellerId).name + '\n';
            }
            issueString += '\n';
        }
        if (histSellerAuditResults.sellerIdsWithNonsequentialHistSellers.length > 0) {
            issueString += 'The End Date must be after the Begin Date, or left blank for:\n';
            for (const sellerId of histSellerAuditResults.sellerIdsWithNonsequentialHistSellers) {
                issueString += this.sellerMappings.find(seller => seller.id === sellerId).name + '\n';
            }
            issueString += '\n';
        }
        if (histSellerAuditResults.periodIdsWithDeletedHistSellers.length > 0) {
            issueString += 'Cannot delete history records currently tied to calculations in periods:\n';
            const cutoff = 5;
            let periodCount = 0;
            for (const periodId of histSellerAuditResults.periodIdsWithDeletedHistSellers) {
                const period = this.periods.find(p => p.id === periodId);
                if (periodCount++ <= cutoff) {
                    issueString += '    ' + this.helperService.createDateRangeString(period.beginDate, period.endDate) + '\n';
                } else {
                    issueString += '... and ' + (histSellerAuditResults.periodIdsWithDeletedHistSellers.length - cutoff) + ' more\n';
                    break;
                }
            }
        }

        this.issueMessage = issueString;
        this.isIssuePopupVisible = true;
    }

    showFactorIssuePopup(sellerIdsWithNonsequentialAccountFactors: number[]): void {
        let factorIssueString = '';
        if (sellerIdsWithNonsequentialAccountFactors.length > 0) {
            factorIssueString += 'The End Date must be after the Begin Date, or left blank for:\n';
            for (const sellerId of sellerIdsWithNonsequentialAccountFactors) {
                factorIssueString += this.sellerMappings.find(seller => seller.id === sellerId).name + '\n';
            }
            factorIssueString += '\n';
        }

        this.factorIssueMessage = factorIssueString;
        this.isFactorIssuePopupVisible = true;
    }

    passwordSendConfirmed(e): void {
        this.passwordGenerated = true;
        let sellerIds = '';
        if (this.editSellerPopupVisible) {
            sellerIds = `[${this.openSeller.id}]`;
        } else {
            const selectedSellerIds: number[] = this.sellerGridSelectedMultiple.map(seller => seller.id);
            sellerIds = `[${selectedSellerIds.join('][')}]`;
        }
        const context = new EmailContext(sellerIds, 'New Core Password', 'Here is your new Core Password');
        this.emailService.sendCredentials(context).subscribe(response => {
            this.passwordConfirmationSteps = [];
            this.passwordConfirmationSteps.push(new CorePopupStep(response, null, null, this));
            this.passwordConfirmationProps.visible = true;
        });
    }

    onSellerGridContentReady(): void {
        this.updatePayeeCount();
        setTimeout(() => {
            this.sellerGrid.populateLayouts();
        });
    }

    updatePayeeCount(): void {
        this.sellerService.getActivePayeeCount().subscribe(payeeCount => {
            this.payeeCount = payeeCount;
            this.sellerGridProps.footerToolbarItems.forEach(x => {
                if (x.name === 'payeeCount') {
                    x.options.text = this.setPayeeCountButtonText();
                }
            });
            if (this.sellerGrid) {
                if(!this.pageDestroyed) {
                    this.sellerGrid.repaintFooterToolbar();
                }
            }
            this.updateActiveSellers();
        });
    }

    setPayeeCountButtonText(): string {
        return `${(this.isPayeeCountButtonReduced ? '' : 'Active Payees: ')}${this.payeeCount}`;
    }

    updateActiveSellers(): void {
        this.sellerService.getActiveSellerIds().subscribe(activeSellerIds => {
            this.activeSellerIds = activeSellerIds;
            this.sellers?.forEach(seller => {
                seller['beginDate'] = null;
                seller.isActive = this.isSellerActive(seller);
            });
        });
    }

    isSellerActive(seller: Seller): boolean {
        return this.activeSellerIds.filter(x => x === seller.id).length > 0;
    }

    openAddSellerPopup(): void {
        this.addSellerForm.instance.resetValues();
        this.newSeller = new SellerCreationInfo('', '', null);
        this.addSellerPopupVisible = true;

        const self = this;
        this.addSellerNameOptions = {
            onKeyUp: (e) => {
                self.onNameKeyUp(e);
            }
        };

        this.addSellerPopupSaveButtonOptions = {
            text: 'Save',
            onClick: () => {
                if (self.addSellerForm.instance.validate().isValid) {
                    self.createSeller(self.newSeller);
                };
            }
        };

        this.addSellerPopupCancelButtonOptions = {
            text: 'Cancel',
            onClick: () => {
                this.addSellerPopupVisible = false;
            }
        };

        setTimeout(() => {
            const doc = document as any;
            doc.querySelector('.nameTextbox input.dx-texteditor-input').focus();
        }, 800);
    }

    editSellerChange(e): void {
        if (e.dataField === 'name' || e.dataField === 'loginName' || e.dataField === 'emailAddress' || e.dataField === 'userGroupId') {
            this.editSellerUnsavedChanges = true;
        }

        this.updateGeneratePasswordDisabled();
    }

    openEditSellerPopup(event: any, data: any): void {
        this.passwordGenerated = false;
        this.openSeller = data.data;
        this.existingSellerValues = this.helperService.deepCopyTwoPointO(this.openSeller);
        forkJoin([
            this.histSellerService.getMultipleHistSellers([this.openSeller.id]),
            this.accountFactorService.getMultipleAccountFactors([this.openSeller.id]),
            this.sellerImportService.getSellerImports(this.openSeller.id)
        ]).subscribe(([histSellers, accountFactors, sellerImports]) => {
            this.openSeller.histSellers = histSellers;
            this.openSeller.accountFactors = accountFactors;
            this.openSeller.sellerImports = sellerImports;

            this.openSeller.histSellers.forEach(histSeller => {
                this.setAttributeValuesHistSeller(histSeller);
            });

            this.editSellerPopupVisible = true;
            this.existingSellerValues = this.helperService.deepCopyTwoPointO(this.openSeller);
            this.updateSellerImportProperties(this.openSeller);
            this.validateEditSellerPopup(sellerImports);

            const currentUserGroupId = this.permissionService.getUserGroupId();
            if (this.openSeller.userGroupId === EnumUserGroup.Administrator && currentUserGroupId === EnumUserGroup.Processor) {
                this.permissionEditAccountSecurity = false;
            } else {
                this.permissionEditAccountSecurity = this.permissionService.checkCurrentUserPermission(CoreFeature.EditAccountSecurity.toString());
            }
            this.availablePermissions = this.permissions.filter(
                x => this.openSeller.userGroupId === EnumUserGroup.Implementer || x.id !== EnumUserGroup.Implementer).filter(
                    y => this.openSeller.userGroupId === EnumUserGroup.Administrator || y.id !== EnumUserGroup.Administrator ||
                        currentUserGroupId === EnumUserGroup.Administrator || currentUserGroupId === EnumUserGroup.Implementer
                );

            const self = this;
            this.editSellerPopupSaveButtonOptions = {
                text: 'Save',
                onClick: () => {
                    this.saveEditSellerChanges();
                }
            };

            this.editSellerPopupCancelButtonOptions = {
                text: 'Close',
                onClick: () => {
                    const hasUnsavedChanges = this.editSellerUnsavedChanges || this.popupImportGrid.grid.instance.hasEditData();

                    if (hasUnsavedChanges) {
                        const msg = 'All unsaved changes will be lost. Are you sure you want to cancel?';
                        confirm(msg, 'Warning').then((dialogResult) => {
                            if (dialogResult) {
                                this.openSeller.name = this.existingSellerValues.name;
                                this.openSeller.loginName = this.existingSellerValues.loginName;
                                this.openSeller.emailAddress = this.existingSellerValues.emailAddress;
                                this.openSeller.userGroupId = this.existingSellerValues.userGroupId;
                                this.openSeller.sellerImports = this.existingSellerValues.sellerImports;
                                this.popupImportGrid.grid.instance.cancelEditData();
                                this.editSellerPopupVisible = false;
                            }
                        });
                    } else {
                        this.editSellerPopupVisible = false;
                    }

                    if (this.passwordGenerated) {
                        this.passwordGenerated = false;
                        this.getAccountData();
                        this.sellerGrid.toggleDataSourceType(this.includeHistoryInSellerGrid);
                        this.updateSellerMappings();
                    }
                }
            };

            this.editSellerPopupDeleteButtonOptions = {
                text: 'Delete Account',
                visible: this.permissionDeleteSeller,
                elementAttr: {
                    class: 'dx-button-danger'
                },
                onClick: () => {
                    const confirmDeleteMessage = 'Are you sure you want to delete this account? This cannot be undone.';
                    confirm(confirmDeleteMessage, `Delete ${this.openSeller.name}?`).then((dialogResult) => {
                        if (dialogResult) {
                            this.sellerDeleting = true;
                            this.sellerService.deleteSeller(this.openSeller).toPromise().then(response => {
                                this.sellerDeleting = false;
                                if (response.responseCode === coreResponseCodes.Success) {
                                    this.toast.success('Seller Deleted');
                                    if (!this.includeHistoryInSellerGrid) {
                                        this.sellers = this.sellers.filter(x => x.id !== this.openSeller.id);
                                        this.histSellers = this.histSellers.filter(x => x.sellerId !== this.openSeller.id);
                                        this.updatePayeeCount();
                                        this.getHierarchyData();
                                    } else {
                                        this.sellerGrid.refreshSSDP();
                                    }
                                    this.updateSellerMappings();
                                    this.editSellerPopupVisible = false;
                                    this.sellerDeleting = false;
                                } else if (response.responseCode === coreResponseCodes.Error) {
                                    this.helperService.displayErrorToast(this.toast, response, 'An error occurred while attempting to delete seller.');
                                }
                            });
                        }
                    });
                }
            };

            setTimeout(() => {
                this.editSellerUnsavedChanges = false;
                const doc = document as any;
                doc.querySelector('.editNameTextbox input.dx-texteditor-input').focus();
            }, 800);

        });

    }

    constructModifiedSellerImports(gridChanges: CoreDataGridChange<SellerImport>[]): SellerImport[] {
        if (this.popupImportGrid.grid.instance.hasEditData()) {
            const sellerImports: SellerImport[] = [];
            this.openSeller.sellerImports.forEach(originalSellerImport => {
                // Only proceed if seller import wasn't deleted
                if (!gridChanges.find(x => x.type === 'remove' && x.key.id === originalSellerImport.id)) {
                    const sellerImport: SellerImport = this.helperService.deepCopyTwoPointO(originalSellerImport);
                    const update = gridChanges.find(x => x.type === 'update' && x.key.id === originalSellerImport.id);
                    if (update) {
                        Object.keys(update.data).forEach(prop => {
                            sellerImport[prop] = update.data[prop];
                        });
                    }
                    sellerImports.push(sellerImport);
                }
            });
            // Add the new seller imports
            gridChanges.filter(x => x.type === 'insert').forEach(newSellerImport => {
                sellerImports.push(new SellerImport(
                    0,
                    newSellerImport.data.name,
                    this.openSeller.id,
                    newSellerImport.data.isDefault,
                    null)
                );
            });
            return sellerImports;
        } else {
            return this.openSeller.sellerImports;
        }
    }

    validateEditSellerPopup(sellerImports: SellerImport[]): boolean {
        let isValid: boolean = true;

        isValid = isValid && this.editSellerForm.instance.validate().isValid;

        // Check for blank or duplicate seller import names and check that there is one set as default
        if (sellerImports) {
            let hasBlankImportName = false;
            let duplicateImportName = null;
            let defaultSellerImportCount = 0;
            sellerImports.forEach(x => {
                if (!x.name || x.name.trim() === '') {
                    hasBlankImportName = true;
                    return;
                } else if (sellerImports.filter(y => x.name.trim().toUpperCase() === y.name.trim().toUpperCase()).length > 1) {
                    duplicateImportName = x.name;
                    return;
                }

                if (x.isDefault === true) {
                    defaultSellerImportCount++;
                }
            });
            if (defaultSellerImportCount !== 1) {
                this.toast.error('There needs to be one seller import that is marked as \'Default\'', 'Default Seller Import Required');
                isValid = false;
            }
            if (hasBlankImportName) {
                this.toast.error('Cannot have blank import name. Please remove it to continue.', 'Blank Import Name');
                isValid = false;
            }
            if (duplicateImportName) {
                this.toast.error(`Import name '${duplicateImportName}' exists more than once`, 'Duplicate Import Name');
                isValid = false;
            }
        }

        const doc = document as any;
        const invalidCells = doc.querySelectorAll('.edit-seller-popup .dx-datagrid-invalid');
        isValid = isValid && invalidCells.length === 0;

        return isValid;
    }

    saveEditSellerChanges(): void {
        const sellerImports = this.constructModifiedSellerImports(this.popupImportProps.gridChanges);

        // Check front-end validation for errors
        if (this.validateEditSellerPopup(sellerImports)) {
            this.isLoadingVisible = true;

            if ((this.openSeller.loginName) && (this.openSeller.user === undefined || this.openSeller.user === null)) {
                this.openSeller.user = new User();
            }

            if (this.openSeller.loginName) {
                const tenantString: string = this.authService.getUserFromToken().split('\\')[0];
                this.openSeller.user.username = tenantString + '\\' + this.openSeller.loginName;
                this.openSeller.user.normalizedusername = this.openSeller.user.username?.toUpperCase();
            } else {
                this.openSeller.loginName = null;
                if (this.openSeller.user) {
                    this.openSeller.user.username = null;
                    this.openSeller.user.normalizedusername = null;
                }
            }

            this.openSeller.emailAddress = this.openSeller.emailAddress ? this.openSeller.emailAddress : null;
            if (this.openSeller.user) {
                this.openSeller.user.email = this.openSeller.emailAddress ? this.openSeller.emailAddress : null;
                this.openSeller.user.normalizedemail = this.openSeller.emailAddress ? this.openSeller.emailAddress.toUpperCase() : null;
            }

            const context = new SellerBatchChangesContext(
                this.openSeller,
                this.popupImportGrid.grid.instance.hasEditData() ? sellerImports : null
            );

            this.sellerService.batchUpdateSeller(context).subscribe(response => {
                if (response.responseCode === coreResponseCodes.Success) {
                    if (response.result.sellerImports) {
                        this.popupImportGrid.grid.instance.cancelEditData();
                        this.openSeller.sellerImports = response.result.sellerImports;
                        this.openSeller.sellerImports.forEach(x => {
                            if (x.id === response.result.sellerImportNameId) {
                                x.isDefault = true;
                                x.deleteEnabled = false;
                            }
                        });
                    }
                    if (response.result.sellerImportNameId !== null) {
                        this.openSeller.importNameId = response.result.sellerImportNameId;
                    }
                    if (response.result.histSellers) {
                        this.histSellers = this.histSellers.filter(x => x.sellerId !== this.openSeller.id);
                        response.result.histSellers.forEach(histSeller => {
                            this.setAttributeValuesHistSeller(histSeller);
                            this.histSellers.push(histSeller);
                        });
                    }
                    if (response.result.accountFactors) {
                        this.openSeller.accountFactors = response.result.accountFactors;
                    }
                    this.existingSellerValues = this.helperService.deepCopyTwoPointO(this.openSeller);
                    this.editSellerUnsavedChanges = false;
                    this.updateGeneratePasswordDisabled();
                    this.getAccountData();
                    this.sellerGrid.toggleDataSourceType(this.includeHistoryInSellerGrid);

                    this.updateSellerMappings();
                    this.toast.success('Account changes have been saved successfully');
                    this.editSellerPopupVisible = false;
                } else if (response.message !== null && response.message.length > 0) {
                    this.isLoadingVisible = false;
                    this.toast.error(response.message, response.messageHeader);
                } else if (response.result.histSellerAuditResults !== null) {
                    this.isLoadingVisible = false;
                    this.showHistIssuePopup(response.result.histSellerAuditResults);
                } else {
                    this.isLoadingVisible = false;
                    this.toast.error('An error has occurred while trying to save seller changes');
                }
            }, error => {
                this.isLoadingVisible = false;
                this.toast.error('An error has occurred while trying to save seller changes');
            });
        }
    }

    onOptionChanged(e: CoreEventArguments): void {
        this.checkForUnsavedChanges();
    }

    onHistoryGridOptionChanged(e: CoreEventArguments): void {
        // When the user selects '<None>' option in the Manager or Parent field, we want it to behave the same as clearing the value
        let hasValueChanged = false;
        if (e.event?.name === 'editing' && Array.isArray(e.event.value) && e.event.value?.length > 0) {
            e.event.value.forEach(value => {
                if (value.data.bossSellerId === -1) {
                    value.data.bossSellerId = null;
                    hasValueChanged = true;
                } else if (value.data.parentSellerId === -1) {
                    value.data.parentSellerId = null;
                    hasValueChanged = true;
                }
            });
        }

        this.onOptionChanged(e);
    }

    checkForUnsavedChanges(): void {
        const hasUnsavedGridChanges = this.sellerGrid.grid.instance.hasEditData() || this.histSellerGrid.grid.instance.hasEditData()
            || this.factorGrid.grid.instance.hasEditData();
        if (this.hasUnsavedGridChanges !== hasUnsavedGridChanges) {
            this.hasUnsavedGridChanges = hasUnsavedGridChanges;

            this.sellerColumns.find(x => x.dataField === 'customButton').columnType.buttonDisabled = this.hasUnsavedGridChanges;
            this.sellerGridProps.checkboxesDisabled = this.hasUnsavedGridChanges;
            this.sellerGrid.grid.instance.refresh();
        }
    }

    updateSellerImportProperties(seller: Seller): void {
        seller.sellerImports?.forEach(sellerImport => {
            sellerImport.isDefault = (seller.importNameId === sellerImport.id);
            sellerImport.deleteEnabled = !sellerImport.isDefault;
        });
        this.popupImportGrid.grid.instance.refresh();
    }

    onImportGridEditorPreparing(e: CoreEventArguments): void {
        if (e.event.parentType === 'dataRow' && e.event.dataField === 'isDefault') {
            const priorOnValueChanged = e.event.editorOptions.onValueChanged;
            const rowData = e.event.row.data;
            e.event.editorOptions.onValueChanged = (onChangedEvent: any) => {
                priorOnValueChanged(onChangedEvent);
                if (onChangedEvent.value === true) {
                    this.openSeller.sellerImports.forEach(sellerImport => {
                        if (sellerImport.id !== rowData.id) {
                            const existingImportChanges = this.popupImportProps.gridChanges.find(x => x.key.id === sellerImport.id);
                            if (existingImportChanges?.data?.isDefault !== undefined) {
                                existingImportChanges.data.isDefault = false;
                            } else if (sellerImport.isDefault === true) {
                                this.popupImportProps.gridChanges.push(new CoreDataGridChange('update', sellerImport, { isDefault: false }));
                            }
                        }
                    });
                }
            };
        }
    }

    onInitNewSellerImport(e: CoreEventArguments): void {
        e.event.data.isDefault = false;
    }

    onNameKeyUp(e): void {
        const doc = document as any;
        const nameTextbox = doc.querySelector('.nameTextbox input.dx-texteditor-input');
        const importNameTextbox = doc.querySelector('.importNameTextbox input.dx-texteditor-input');
        importNameTextbox.value = nameTextbox.value;
        this.newSeller.importName = nameTextbox.value;
    }

    updateGeneratePasswordDisabled(): void {
        this.generatePasswordDisabled = !this.openSeller.emailAddress || this.openSeller.emailAddress === ''
            || this.openSeller.emailAddress !== this.existingSellerValues.emailAddress
            || !this.openSeller.loginName || this.openSeller.loginName === ''
            || this.openSeller.loginName !== this.existingSellerValues.loginName
            || !this.permissionEditAccountSecurity;
    }

    getPanelWidthTotal(): number {
        if (this.pageDestroyed) {
            return 0;
        }
        const doc = document as any;
        const viewPane = doc.querySelector('app-account-management .page-contents .resizable-area');
        return viewPane.offsetWidth - 20;
    }

    setPanelWidths(totalWidth: number): void {
        this.leftPanelMaxWidth = totalWidth - this.rightPanelMinWidth;
        let leftPanelWidth = Math.floor(totalWidth * this.leftPanelWidthPercentage);
        let rightPanelWidth = Math.floor(totalWidth * this.rightPanelWidthPercentage);
        let hasPercentagesChanged: boolean = false;
        if (leftPanelWidth < this.leftPanelMinWidth) {
            leftPanelWidth = this.leftPanelMinWidth;
            rightPanelWidth = totalWidth - leftPanelWidth;
            hasPercentagesChanged = true;
        } else if (rightPanelWidth < this.rightPanelMinWidth) {
            rightPanelWidth = this.rightPanelMinWidth;
            leftPanelWidth = totalWidth - rightPanelWidth;
            hasPercentagesChanged = true;
        }

        this.leftPanelWidth = leftPanelWidth;
        this.rightPanelWidth = rightPanelWidth;
        if (hasPercentagesChanged) {
            this.setPanelSizeRatio(totalWidth, this.leftPanelWidth, this.rightPanelWidth);
        }

        this.setPayeeCountButtonMode(this.leftPanelWidth <= 420);
    }

    setPanelSizeRatio(totalWidth: number, leftPanelWidth: number, rightPanelWidth: number) {
        this.leftPanelWidthPercentage = Math.round(leftPanelWidth / totalWidth * 100) / 100;
        this.rightPanelWidthPercentage = 1 - this.leftPanelWidthPercentage;
        this.sellerGrid.triggerSaveState();
    }

    resizePanels(maintainRatio: boolean, leftPanelWidth: any = null): void {
        const totalWidth = this.getPanelWidthTotal();
        if (maintainRatio) {
            this.setPanelWidths(totalWidth);
        } else if (leftPanelWidth !== null) {
            this.rightPanelWidth = (totalWidth - leftPanelWidth);
            this.setPanelSizeRatio(totalWidth, leftPanelWidth, this.rightPanelWidth);
        }

        this.sellerGrid.grid.instance.repaint();
        this.histSellerGrid.grid.instance.repaint();
        this.factorGrid.grid.instance.repaint();

        this.setPayeeCountButtonMode(this.leftPanelWidth <= 420);
    }

    setPayeeCountButtonMode(isReducedSize: boolean): void {
        if (this.isPayeeCountButtonReduced !== isReducedSize) {
            this.isPayeeCountButtonReduced = isReducedSize;
            const toolbarItem = this.sellerGridProps.footerToolbarItems.find(x => x.name === 'payeeCount');
            toolbarItem.options.text = this.setPayeeCountButtonText();
        }
    }

    onResizeLeftPanel(e): void {
        this.resizePanels(false, e.width);
    }

    onWindowResize(): void {
        this.resizePanels(true);
        for (let i = 0; i <= 5; i++) {
            setTimeout(() => {
                this.checkHeightOfPanelArea();
            }, (i + 1) * 1000);
        }
    }

    checkHeightOfPanelArea(): void {
        // Check to see if right panel is stuck on second line
        const doc = document as any;
        const resizableArea = doc.querySelector('app-account-management .page-contents .resizable-area');
        if (resizableArea.offsetHeight > window.innerHeight) {
            this.resizePanels(true);
        }
    }

    onImportDropdownItemClick(e: any): void {
        switch (e.itemData) {
            case this.getImportCaption(this.importHistoryDescription):
                this.importHistoryClick();
                break;
            case this.getImportCaption(this.importFactorsDescription):
                this.importFactorsClick();
                break;
            case this.getImportCaption(this.importNamesDescription):
                this.importNamesClick();
                break;
        }
    }

    onAuditRecordsClick(): void {
        this.histSellerService.getCheckManagers().subscribe(response => {
            if (response.responseCode === 1) {
                this.toast.success('No invalid history detected');
            } else {
                this.showHistIssuePopup(response.result);
            }
        });
    }

    getImportCaption(description: string): string {
        return `Import ${description}`;
    }

    importHistoryClick(): void {
        this.importURL = this.histSellerService.importURL;
        this.importDescription = this.importHistoryDescription;
        this.coreImport.open();
    }

    importFactorsClick(): void {
        this.importURL = this.accountFactorService.importURL;
        this.importDescription = this.importFactorsDescription;
        this.coreImport.open();
    }

    importNamesClick(): void {
        this.importURL = this.sellerImportService.importURL;
        this.importDescription = this.importNamesDescription;
        this.coreImport.open();
    }

    calculateSortValue(data: any): any {
        const column = this as any;
        const value = column.calculateCellValue(data);
        return column.lookup.calculateCellValue(value);
    }

    onSellerGridLayoutSave(uiView: UiView): void {
        this.toast.success(`Accounts Grid layout '${uiView.name}' has been saved successfully.`);
    }

    onSellerGridLayoutAdded(uiView: UiView): void {
        this.toast.success(`Accounts Grid layout '${uiView.name}' has been added successfully.`);
    }

    onSellerGridLayoutRenamed(uiView: UiView): void {
        this.toast.success(`Accounts Grid layout '${uiView.name}' has been renamed successfully.`);
    }

    onSellerGridLayoutDeleted(uiView: UiView): void {
        this.toast.success(`Accounts Grid layout '${uiView.name}' has been deleted successfully.`);
    }

    sellerGridSaveState(state: any): any {
        state.leftPanelWidthPercentage = this.leftPanelWidthPercentage;
        state.rightPanelWidthPercentage = this.rightPanelWidthPercentage;
        state.histSellerGridState = this.histSellerGrid.grid.instance.state();
        state.factorGridState = this.factorGrid.grid.instance.state();
        return state;
    }

    sellerGridLoadState(state: any): void {
        this.leftPanelWidthPercentage = state.leftPanelWidthPercentage;
        this.rightPanelWidthPercentage = state.rightPanelWidthPercentage;
        if (this.leftPanelWidthPercentage === undefined) {
            this.leftPanelWidthPercentage = 0.33; // default
        }
        if (this.rightPanelWidthPercentage === undefined) {
            this.rightPanelWidthPercentage = 0.67; // default
        }
        this.setPanelWidths(this.getPanelWidthTotal());
        if (!this.helperService.isNullOrUndefined(state.histSellerGridState)) {
            this.histSellerGrid.setGridLayoutState(state.histSellerGridState);
        }
        if (!this.helperService.isNullOrUndefined(state.factorGridState)) {
            this.factorGrid.setGridLayoutState(state.factorGridState);
        }
    }

    histSellerGridLoadState(state: any): void {
        if (!this.histSellerGridStateRetrieved && state.selectedRowKeys !== undefined && state.selectedRowKeys.length > 0) {
            this.histSellerGridState = state;
        }
        this.histSellerGridStateRetrieved = true;
    }

    onSellerGridInitialized(e: CoreEventArguments): void {
        // this is needed in order to load visible columns from saved layouts
        e.event.component.option('stateStoring', { ignoreColumnOptionNames: [] });
    }

    getClientSideSellerData(): Observable<Seller[]> {
        if (this.includeHistoryInSellerGrid) {
            return this.sellerService.getAllSellersLookup(true);
        } else {
            return this.sellerService.thinGetAllSellers(this.includeHistoryInSellerGrid);
        }
    }

    updateSellerMappings(): void {
        this.getClientSideSellerData().subscribe(sellerMappings => {
            this.sellerMappings = sellerMappings.sort((a, b) => a.name.localeCompare(b.name));;
            this.histColumns.find(x => x.dataField === 'bossSellerId').columnType.lookupData = this.helperService.createDatasourceFromStaticArray(this.sellerMappings, 'id');
        });
    }

    modifySellerServerData(data: any[]): any[] {
        this.setAttributeValues(data);
        return data;
    }

    customizeGroupCellText(data: any, coreColumn: CoreColumn): string {
        const attributeClassId = parseInt(coreColumn.dataField.replace('attr_', ''), 10);
        const attributeClass = this.attributeClasses.find(x => x.id === attributeClassId);
        const attribute = this.attributes.find(x => x.attributeClassId === attributeClassId && x.name.toUpperCase() === data.value.toUpperCase());
        return `${attributeClass.friendlyName}: ${attribute.name}`;
    }

    ngOnDestroy() {
        this.pageDestroyed = true;
    }
}

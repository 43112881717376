import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SettingService } from './setting.service';

@Injectable()
export class SettingGuard implements CanActivate {
    constructor(private router: Router, private settingService: SettingService) { }

    canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise<boolean>((res) => {
            this.settingService.getBoolSetting(next.data['settingId']).subscribe(result => {
                if (result === true){
                    res(true);
                }
                else{
                    this.router.navigate(['/home']);
                    res(false);
                }
            });
        });
    }
}

export class ProcessingContext {
    periodId: number;
    seriesId: number;
    planName: string;
    sequenceNo: number;
    sequenceNos: number[];

    constructor(periodId: number = null, seriesId: number = null, planName: string = null, sequenceNo: number = null) {
        this.periodId = periodId;
        this.seriesId = seriesId;
        this.planName = planName;
        this.sequenceNo = sequenceNo;
    }

    createAll(periodId: number, seriesId: number, planName: string, sequenceNo?: number): ProcessingContext {
        this.periodId = periodId;
        this.seriesId = seriesId;
        this.planName = planName;
        this.sequenceNo = sequenceNo;

        return this;
    }
}

<dx-button
    id="formula-builder-button-{{id}}"
    [text]="text"
    [icon]="icon"
    (click)="launch()"
    class="formula-builder-button"
    [visible]="launchElement === 'button'">
</dx-button>
<dx-tooltip target="#formula-builder-button-{{id}}" showEvent="dxhoverstart" hideEvent="dxhoverend">
    <div *dxTemplate="let data of 'content'">
        <span>{{this.popupTitle}}</span>
    </div>
</dx-tooltip>

<a
    id="formula-builder-link-{{id}}"
    href="javascript:;"
    (click)="launch()"
    class="dx-link dx-link-icon dx-icon-{{icon}}"
    *ngIf="launchElement === 'link'">
    {{text}}
</a>
<dx-tooltip target="#formula-builder-link-{{id}}" showEvent="dxhoverstart" hideEvent="dxhoverend">
    <div *dxTemplate="let data of 'content'">
        <span>{{this.popupTitle}}</span>
    </div>
</dx-tooltip>

<app-formula-builder-popup
    [popupTitle]="popupTitle"
    [(visible)]="this.popupVisible"
    [(value)]="this.value"
    (valueChange)="formulaValueChanged($event)"
    [data]="this.data"
    [id]="this.id">
</app-formula-builder-popup>

import { CoreImportCleaningRecord } from 'src/app/shared/models/core-import-cleaning-record';

export class CoreImportPacket {
    fileId: string;
    importKey: string;
    cleaningRecords: CoreImportCleaningRecord[];

    constructor(fileId: string, cleaningRecords: CoreImportCleaningRecord[], importKey: string = '') {
        this.fileId = fileId;
        this.cleaningRecords = cleaningRecords;
        this.importKey = importKey;
    }

    addCleanedRecords(cleanedRecords: CoreImportCleaningRecord[]) {
        this.cleaningRecords = cleanedRecords;
    }
}

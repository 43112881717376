import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IntegrationClass } from '../models/integration';

@Injectable()
export class IntegrationClassService {
    private baseUrl = environment.apiEndpoint + '/integrationclass';

    constructor(private http: HttpClient) {
    }

    getAllIntegrationClasses(): Observable<IntegrationClass[]> {
        return this.http.get<IntegrationClass[]>(this.baseUrl);
    }

    getIntegrationClassById(id: number): Observable<IntegrationClass>{
        return this.http.get<IntegrationClass>(this.baseUrl + '/' + id);
    }

    updateIntegrationClass(integrationClass: IntegrationClass): Observable<IntegrationClass> {
        return this.http.put<IntegrationClass>(this.baseUrl, integrationClass);
    }
}

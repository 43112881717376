export class UiView {
    id: number;
    name: string;
    contextCode: number;
    layoutString1: string;
    layoutString2: any[];
    chartOrientation: number;
    showPointLabels: boolean;
    numBackPeriods: number;
    chartTypeName: string;
    datasourceId: number;
    functionId: number;
    functionGroupId: number;
    ruleId: number;
    showChart: boolean;
    datasources: string;
    plans: string;
    recurrenceId: number;
    attributes: string;
    assignmentId: number;
    includeInactive: boolean;
    systemCode: number;

    createDummy(): UiView {
        this.id = 0;
        this.name = '<none>';
        this.layoutString1 = null;
        this['deleteDisabled'] = true;

        return this;
    }

    createForProcessingLayout(name: string, datasources: string, plans: string, contextCode: number, recurrenceId: number): UiView {
        this.name = name;
        this.datasources = datasources;
        this.plans = plans;
        this.contextCode = contextCode;
        this.recurrenceId = recurrenceId;

        return this;
    }
}

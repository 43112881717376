import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class TrelloService {
    private baseUrl = environment.apiEndpoint + '/trello';

    constructor(private http: HttpClient) {
    }

    getTrelloUrl(): Observable<string> {
        return this.http.get<string>(this.baseUrl);
    }
}

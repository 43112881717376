<div class="core-file-manager">
    <div class="file-manager-wrapper">
        <dx-file-manager #fileManager
            [fileSystemProvider]="fileSystemProvider"
            (onFocusedItemChanged)="onItemSelected($event)"
            (onContentReady)="setCurrentPath($event)"
            (onInitialized)="onLoaded($event);"
            selectionMode="single"
            [selectedItemKeys]="[value]"
            rootFolderName="Retrieval Documents">
            <dxo-permissions
                [create]="false"
                [copy]="false"
                [move]="false"
                [delete]="false"
                [rename]="false"
                [upload]="false"
                [download]="true">
            </dxo-permissions>
            <dxo-item-view [showParentFolder]="false">
                <dxo-details mode="details">
                    <dxi-column dataField="thumbnail"></dxi-column>
                    <dxi-column dataField="name"></dxi-column>
                    <dxi-column dataField="dateModified"></dxi-column>
                    <dxi-column dataField="size"></dxi-column>
        
                </dxo-details>
            </dxo-item-view>
        </dx-file-manager>
    </div>
</div>
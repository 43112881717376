import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CoreRequest } from '../models/core-request';
import { IdLongValueString } from '../models/id-long-value-string';
import { Setting } from '../models/setting';
import { SettingClassTreeItem } from '../models/site-setting-item';

@Injectable({
    providedIn: 'root'
})
export class SettingService {
    private baseurl: string = environment.apiEndpoint;
    private settingURL = this.baseurl + '/setting/';
    constructor(private http: HttpClient) {

    }

    getBoolSetting(settingId: number): Observable<boolean> {
        return this.http.get<boolean>(this.settingURL + 'bool/' + settingId);
    }

    getStringSetting(settingId: number): Observable<string> {
        return this.http.get(this.settingURL + 'string/' + settingId, {responseType: 'text'});
    }

    getUISettings(): Observable<any> {
        return this.http.get<any>(this.settingURL + 'UI');
    }

    getEmailSettings(): Observable<any> {
        return this.http.get<any>(this.settingURL + 'email');
    }

    getLongSetting(settingId: number): Observable<number> {
        return this.http.get<number>(this.settingURL + 'long/' + settingId);
    }

    getSettingsByClassIds(settingIds: number[]) {
        const coreRequest = new CoreRequest<number>(null, settingIds);
        return this.http.post<Setting[]>(this.settingURL + 'getSettingsByClassId', coreRequest);
    }

    getPasswordStrengthDescription(): Observable<Setting> {
        return this.http.get<Setting>(this.settingURL + 'getPasswordStrengthDescription');
    }

    GetIsPasswordMaskStrong(): Observable<boolean> {
        return this.http.get<boolean>(this.settingURL + 'maskStrength/');
    }

    getSettingClassTreeItems(): Observable<SettingClassTreeItem[]> {
        return this.http.get<SettingClassTreeItem[]>(this.settingURL + 'settingclasstree');
    }

    getSettingLookupData(): Observable<string[][]> {
        return this.http.get<string[][]>(this.settingURL + 'lookupdata');
    }

    updateSettingsByClassIdBulk(newSettingValues: IdLongValueString[]) {
        const coreRequest = new CoreRequest<IdLongValueString>(null, newSettingValues);
        return this.http.put<number>(this.settingURL + 'updateSettingsByClassIdBulk', coreRequest);
    }

    upsertUserSettingsByClassIdBulk(settingValues: IdLongValueString[]) {
        const coreRequest = new CoreRequest<IdLongValueString>(null, settingValues);
        return this.http.put<number>(this.settingURL + 'upsertUserSettingsByClassIdBulk', coreRequest);
    }

    updateSiteSetting(value: IdLongValueString): Observable<number> {
        return this.http.put<number>(this.settingURL + 'updateSiteSetting', value);
    }
}

import { Bucket } from './bucket';

export class BucketClass {
    id: number;
    name: string;
    folderType: number;
    buckets: Bucket[];

    constructor(b: Partial<BucketClass> = {}) {
        Object.assign(this, b);
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecordDataViewerComponent } from './record-data-viewer.component';

const routes: Routes = [
    {
        path: '',
        component: RecordDataViewerComponent
    }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RecordDataViewerRoutingModule { }

import { DynamicCellEditorTemplates } from '../constants/dev-extreme-enums';
import { CoreColumn } from './core-column';
import { ColumnType } from './core-column-type';
import { GridProps } from './core-data-grid-properties';

export class CoreDynamicCellEditorProps {
    template: string;
    mockCoreColumn: CoreColumn = new CoreColumn(null, null, null);
    isRequired: boolean = false;
    validationGroup: string;

    createForDropDownTableTemplate(dataSet: any[], dropDownGridColumns: CoreColumn[], valueField: string, displayValueField: string,
        gridHeight: string | number | (() => string | number), gridWidth: number): CoreDynamicCellEditorProps {

        const gridProps = new GridProps('dynamicCellDropDownGridPropsId', null, false, true, false, false).setToSingleRowSelection();
        const gridColumnType = new ColumnType().createLookup(dataSet, valueField, displayValueField, true, true, valueField, true);
        gridProps.keyColumn = valueField;

        this.template = DynamicCellEditorTemplates.DropDownTableTemplate;
        this.mockCoreColumn = new CoreColumn(valueField, '', true, 'string', gridColumnType, true, null, false, null, null, null, null, true)
            .addTableDropDownTemplateToLookupColumn(dropDownGridColumns, gridProps, gridHeight, gridWidth);

        return this;
    }

    createForCoreTableSelectTemplate(dataSet: any[], dropDownGridColumns: CoreColumn[], valueField: string, displayValueField: string,
        gridHeight, gridWidth: string | number | (() => string | number), isRequired: boolean = false): CoreDynamicCellEditorProps {

        const gridProps = new GridProps('dynamicCellDropDownGridPropsId', null, false, true, false, false).setToSingleRowSelection().setSearchEnabled(false)
            .addIsTableSelectCellEditorPopup(true);
        const gridColumnType = new ColumnType().createLookup(dataSet, valueField, displayValueField, true, true, valueField, true);
        gridProps.keyColumn = valueField;

        this.template = DynamicCellEditorTemplates.CoreTableSelectTemplate;
        this.mockCoreColumn = new CoreColumn(valueField, '', true, 'string', gridColumnType, true, null, false, null, null, null, null, true)
            .addTableDropDownTemplateToLookupColumn(dropDownGridColumns, gridProps, gridHeight, gridWidth);

        this.isRequired = isRequired;
        return this;
    }

    createForDateTimePicker(formatString?: string): CoreDynamicCellEditorProps {
        this.template = DynamicCellEditorTemplates.DatetimeTemplate;
        this.mockCoreColumn.format = formatString;

        // add OR conditions to this if statement as needed for time selection formats
        if (formatString.indexOf('hh') > 0 || formatString.indexOf('mm') > 0) {
            this.mockCoreColumn.dataType = 'datetime';
        } else {
            this.mockCoreColumn.dataType = 'date';
        }

        return this;
    }

    createForSelectBox(dataSet: any[], valueField: string, displayField: string): CoreDynamicCellEditorProps {
        this.template = DynamicCellEditorTemplates.SelectBoxTemplate;
        this.mockCoreColumn.lookupData = dataSet;
        this.mockCoreColumn.dataField = valueField;
        this.mockCoreColumn.dataFieldDisplay = displayField;

        return this;
    }

    createForLookup(dataSet: any[], valueField: string, displayField: string): CoreDynamicCellEditorProps {
        this.template = DynamicCellEditorTemplates.LookupTemplate;
        this.mockCoreColumn.lookupData = dataSet;
        this.mockCoreColumn.dataField = valueField;
        this.mockCoreColumn.dataFieldDisplay = displayField;

        return this;
    }

    createForNumberBox(formatString: string): CoreDynamicCellEditorProps {
        this.template = DynamicCellEditorTemplates.NumberBoxTemplate;
        if (formatString === 'percent') {
            formatString = '#,##0.######%'; // This is needed, otherwise dx-number-box doesn't allow user to key a period for a decimal value
        }
        this.mockCoreColumn.format = formatString;

        return this;
    }

    createForTextBox(): CoreDynamicCellEditorProps {
        this.template = DynamicCellEditorTemplates.TextBoxTemplate;

        return this;
    }

    addValidationGroup(validationGroup: string): CoreDynamicCellEditorProps {
        this.validationGroup = validationGroup;

        return this;
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CallbackComponent } from './callback.component';
import { SingleCardComponent } from '../../layouts/single-card/single-card.component';

const routes: Routes = [
    {
        path: '',
        component: SingleCardComponent,
        children: [
            {
                path: '',
                component: CallbackComponent
            }]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CallbackRoutingModule { }
